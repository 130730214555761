import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  searchLabel: {
    defaultMessage: 'Search',
    id: 'xmcVZ0',
  },
  totalViews: {
    defaultMessage: 'Total Views',
    id: 'rw+8eY',
  },
  uniqueViewers: {
    defaultMessage: 'Unique Viewers',
    id: 'q0mgSt',
  },
  announcementInsightsModalTitle: {
    defaultMessage: 'Announcement Insights',
    id: 'o1KsBL',
  },
  announcementInsightsModalDescription: {
    defaultMessage: 'See who has viewed your announcement and who hasn’t.',
    id: 'uXC8QN',
  },
  uniqueViewersTooltipText: {
    defaultMessage:
      'This is the number of people in your audience who have viewed this announcement at least once.',
    id: 'PV0TiO',
  },
  totalViewersTooltipText: {
    defaultMessage:
      'This is the number of times that this announcement has been viewed in total.',
    id: 'iTQKmX',
  },
  noResultsFound: {
    defaultMessage: 'No results, please try another search.',
    id: 'vrQErH',
  },
  seenFilterNoResultsFound: {
    defaultMessage:
      'No one has seen this announcement yet. When they do, they will appear here.',
    id: 'xAtPaf',
  },
  unseenFilterNoResultsFound: {
    defaultMessage: 'Everyone has seen your announcement, congratulations!',
    id: 'dn6K1W',
  },
  cannotViewAnymore: {
    defaultMessage: 'Can’t view anymore',
    id: 'x4oqkn',
  },
  seen: {
    defaultMessage: 'Seen',
    id: 'd/fRcX',
  },
  unseen: {
    defaultMessage: 'Unseen',
    id: 'nPxl7v',
  },
});
