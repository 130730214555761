import { useComposedRefs } from '@radix-ui/react-compose-refs';
import {
  Children,
  createContext,
  forwardRef,
  type HTMLAttributes,
  type ReactNode,
  useContext,
  useEffect,
  useId,
  useRef,
  useState,
} from 'react';
import { defineMessages, useIntl } from 'react-intl';
import { twJoin, twMerge } from 'tailwind-merge';

import { TextStyle } from '../../../DesignSystem/Feedback/TextStyle';
import { Tooltip } from '../../../DesignSystem/Feedback/Tooltip';
import { ToggleSwitch } from '../../../DesignSystem/Inputs/ToggleSwitch';
import { useTouchDevice } from '../../hooks/useTouchDevice';
import { EditButton as EditButtonImpl } from './EditButton';
import { Loader as LoaderImpl } from './Loader';

type Children = {
  children: ReactNode;
};

type ChildrenWithClassName = Children & {
  className?: string;
};

type RowContainerProps = {
  addBlockMenu?: ReactNode;
  leftStack?: ReactNode;
  rightStack?: ReactNode;
  highlightRow?: boolean;
} & HTMLAttributes<HTMLDivElement>;

type RowProps = ChildrenWithClassName &
  Pick<RowContainerProps, 'highlightRow'> &
  RowContainerProps;

type QuestionCellLayoutProps = {
  blockIndicator?: ReactNode;
  blockSummary?: ReactNode;
  overflowMenu?: ReactNode;
  requiredToggle: ReactNode;
  textArea: ReactNode;
  blockConfigurationError?: ReactNode;
};

const RowTypeContext = createContext<'body' | 'header' | 'footer' | undefined>(
  undefined
);
const useRowTypeContext = () => {
  return useContext(RowTypeContext);
};

const messages = defineMessages({
  edit: {
    defaultMessage: 'Edit',
    id: 'wEQDC6',
  },
  question: {
    defaultMessage: 'Question',
    id: 'kgOBET',
  },
  type: {
    defaultMessage: 'Type',
    id: '+U6ozc',
  },
  required: {
    defaultMessage: 'Required',
    id: 'Seanpx',
  },
  totalQuestions: {
    defaultMessage:
      '{total, plural, one {{total, number} question total} other {{total, number} questions total}}',
    id: '7Vb4+i',
  },
  editing: {
    defaultMessage: 'Edit question',
    id: 'lCUub/',
  },
  doneEditing: {
    defaultMessage: 'Done editing',
    id: 'a/4SZM',
  },
});

function Root({ children }: Children) {
  return (
    <div className="w-full @container/table">
      <div
        className="grid grid-cols-[44px_1fr] gap-y-4 @[600px]/table:grid-cols-[44px_5fr_minmax(158px,1fr)]"
        role="table"
      >
        {children}
      </div>
    </div>
  );
}

function Header() {
  const { formatMessage } = useIntl();

  return (
    <RowGroup className="rounded-lg bg-gray-4">
      <Row className="[&>span:last-of-type]:pr-1 [&>span:nth-of-type(1)]:pr-1">
        <HeaderCell>{formatMessage(messages.edit)}</HeaderCell>
        <HeaderCell>{formatMessage(messages.question)}</HeaderCell>
        <HeaderCell className="hidden @[600px]/table:inline">
          {formatMessage(messages.type)}
        </HeaderCell>
      </Row>
    </RowGroup>
  );
}

function Body({ children }: Children) {
  return (
    <RowTypeContext.Provider value="body">
      <RowGroup>{children}</RowGroup>
    </RowTypeContext.Provider>
  );
}

function Footer({ left, right }: { left: ReactNode; right: ReactNode }) {
  return (
    <RowTypeContext.Provider value="footer">
      <RowGroup>
        <Row className="flex items-center justify-between gap-2 px-3 py-2 [&_[role=cell]]:flex-shrink-0">
          <span role="cell">{left}</span>
          <span role="cell" className="hidden @[600px]/table:inline">
            {right}
          </span>
        </Row>
      </RowGroup>
    </RowTypeContext.Provider>
  );
}

const RowContainer = forwardRef<HTMLDivElement, RowContainerProps>(
  function RowContainer(
    {
      addBlockMenu,
      children,
      className,
      highlightRow,
      leftStack,
      rightStack,
      ...tail
    },
    ref
  ) {
    const type = useRowTypeContext() ?? 'header';

    const [showContextOptions, setShowContextOptions] = useState(false);
    const [isTabbing, setIsTabbing] = useState(false);

    const internalRef = useRef<HTMLDivElement>(null);
    const mergedRef = useComposedRefs(internalRef, ref);

    const touchDevice = useTouchDevice();

    useEffect(() => {
      if (!internalRef.current) {
        return;
      }
      const ref = internalRef.current;

      function handleKeyDown(event: KeyboardEvent) {
        if (event.key === 'Tab' && showContextOptions) {
          setIsTabbing(
            !(ref !== event.target && !ref.contains(event.target as Node))
          );
        }
      }
      function handleCaptureFocusEvent() {
        setIsTabbing(false);
      }
      function handleClearFocusEvent() {
        setIsTabbing(true);
      }

      ref.addEventListener('keydown', handleKeyDown);
      document.addEventListener(
        'capture-actions-focus',
        handleCaptureFocusEvent
      );
      document.addEventListener('clear-actions-focus', handleClearFocusEvent);

      return () => {
        ref.removeEventListener('keydown', handleKeyDown);
        document.removeEventListener(
          'capture-actions-focus',
          handleCaptureFocusEvent
        );
        document.removeEventListener(
          'clear-actions-focus',
          handleClearFocusEvent
        );
      };
    }, [setIsTabbing, showContextOptions]);

    const canShowActions = type === 'body' && !touchDevice;
    const commonContainerClasses =
      'absolute top-0 hidden h-20 w-6 flex-col justify-center gap-1 @[600px]/table:flex';

    return (
      <div
        {...tail}
        ref={mergedRef}
        className={twJoin(
          'col-span-full',
          type !== 'footer' &&
            'relative grid grid-cols-[44px_1fr] rounded-lg @[600px]/table:grid-cols-[44px_5fr_minmax(158px,1fr)]',
          canShowActions && !highlightRow && 'hover:bg-primary-1',
          type === 'body' && 'bg-gray-1',
          className
        )}
        {...(canShowActions && {
          tabIndex: 0,
          onFocus: (e) => {
            tail.onFocus?.(e);
            setShowContextOptions(true);
          },
          onPointerEnter: (e) => {
            tail.onPointerEnter?.(e);
            setShowContextOptions(true);
          },
          onPointerLeave: (e) => {
            tail.onPointerLeave?.(e);
            setShowContextOptions(false);
          },
        })}
        {...(isTabbing &&
          showContextOptions && {
            onBlur: (e) => {
              tail.onBlur?.(e);
              if (
                internalRef.current &&
                !internalRef.current.contains(e.relatedTarget as Node)
              ) {
                setShowContextOptions(false);
              }
            },
          })}
        role="row"
      >
        {Boolean(canShowActions) && (
          <>
            {/* eslint-disable-next-line jsx-a11y/no-static-element-interactions */}
            <div
              className="absolute -left-9 -right-9 bottom-0 top-0 -z-10 hidden bg-transparent @[600px]/table:block"
              onMouseMove={() => setShowContextOptions(true)}
            />
          </>
        )}
        {Boolean(leftStack && showContextOptions) && (
          <div className={twJoin('-left-7', commonContainerClasses)}>
            {leftStack}
          </div>
        )}
        {Boolean(addBlockMenu) && (
          <div className="absolute bottom-0 left-1/2 -translate-x-1/2 translate-y-1/2">
            {addBlockMenu}
          </div>
        )}
        {children}
        {Boolean(rightStack && showContextOptions) && (
          <div className={twJoin('-right-7', commonContainerClasses)}>
            {rightStack}
          </div>
        )}
      </div>
    );
  }
);

const Row = forwardRef<HTMLDivElement, RowProps>(function Row(
  {
    addBlockMenu,
    children,
    className,
    highlightRow,
    leftStack,
    rightStack,
    ...tail
  },
  ref
) {
  const type = useRowTypeContext() ?? 'header';

  return (
    <RowContainer
      {...tail}
      ref={ref}
      addBlockMenu={addBlockMenu}
      className={className}
      leftStack={leftStack}
      rightStack={rightStack}
      highlightRow={highlightRow}
    >
      {type === 'header' || type === 'footer'
        ? children
        : Children.map(children, (child, i) =>
            child ? (
              <span
                role="cell"
                className={twJoin(
                  'min-h-[72px] [&>*]:h-full',
                  highlightRow && i < 3 && 'bg-primary-1',
                  i === 2 && 'hidden @[600px]/table:inline',
                  i === 3 &&
                    'col-span-full row-start-2 shadow-[0px_1px_6px_0px_rgba(0,0,0,0.10)_inset]'
                )}
              >
                {child}
              </span>
            ) : null
          )}
    </RowContainer>
  );
});

function RowGroup({ children, className }: ChildrenWithClassName) {
  const type = useRowTypeContext() ?? 'header';

  return (
    <div
      className={twJoin(
        'col-span-full grid grid-cols-[44px_1fr] gap-y-4 @[600px]/table:grid-cols-[44px_5fr_minmax(158px,1fr)]',
        type === 'body' &&
          '[&_div[role=row]]:border [&_div[role=row]]:border-gray-5',
        type === 'footer' && 'rounded-lg bg-gray-4',
        className
      )}
      role="rowgroup"
    >
      {children}
    </div>
  );
}

function HeaderCell({ children, className }: ChildrenWithClassName) {
  return (
    <TextStyle
      as="span"
      role="columnheader"
      variant="sm-medium"
      className={twJoin('px-3 py-2', className)}
    >
      {children}
    </TextStyle>
  );
}

function AlignCenter({ children, className }: ChildrenWithClassName) {
  return (
    <div className={twMerge('flex items-center justify-center', className)}>
      {children}
    </div>
  );
}

function QuestionCellLayout({
  blockConfigurationError,
  blockIndicator,
  blockSummary,
  overflowMenu,
  requiredToggle,
  textArea,
}: QuestionCellLayoutProps) {
  return (
    <div className="flex items-center">
      <div className="flex flex-1 items-center gap-1 py-5 pl-3 pr-2 @[600px]/table:[&>[aria-haspopup=menu]]:hidden [&>div:first-child]:self-start [&>div:first-child]:pt-[9px]">
        {blockIndicator}
        <div className="flex flex-1 flex-col gap-1">
          {textArea}
          {blockSummary}
          {blockConfigurationError}
        </div>
        {overflowMenu}
      </div>
      <div className="hidden items-center justify-center gap-1 p-2 @[600px]/table:flex">
        {requiredToggle}
      </div>
    </div>
  );
}

function RequiredToggle({
  tooltipText,
  ...tail
}: {
  checked: boolean;
  onCheckedChange: (checked: boolean) => void;
  disabled?: boolean;
  tooltipText?: ReactNode;
}) {
  const id = useId();
  const { formatMessage } = useIntl();

  return (
    <>
      <TextStyle id={id} as="span" variant="xs-regular" subdued>
        {formatMessage(messages.required)}
      </TextStyle>
      <Tooltip tooltipText={tooltipText}>
        <span>
          <ToggleSwitch aria-describedby={id} {...tail} />
        </span>
      </Tooltip>
    </>
  );
}

function TotalQuestions({ total }: { total: number }) {
  const { formatMessage } = useIntl();

  return (
    <TextStyle variant="sm-regular" subdued>
      {formatMessage(messages.totalQuestions, { total })}
    </TextStyle>
  );
}

function EditButton({
  disabled,
  pressed,
  onPressedChange,
}: {
  disabled?: boolean;
  pressed: boolean;
  onPressedChange: (pressed: boolean) => void;
}) {
  const { formatMessage } = useIntl();

  const label = pressed
    ? formatMessage(messages.doneEditing)
    : formatMessage(messages.editing);

  return (
    <AlignCenter className="py-2 pl-2 pr-1">
      <Tooltip tooltipText={label}>
        <span>
          <EditButtonImpl
            aria-label={label}
            disabled={disabled}
            pressed={pressed}
            onPressedChange={onPressedChange}
          />
        </span>
      </Tooltip>
    </AlignCenter>
  );
}

function BlockTypeContainer({ children }: Children) {
  return <AlignCenter className="justify-start p-1">{children}</AlignCenter>;
}

function ExpansionContainer({ children }: Children) {
  return <div className="flex flex-col gap-4 px-6 pb-6 pt-3">{children}</div>;
}

function Loader() {
  return (
    <Row className="h-[72px]">
      <AlignCenter className="py-2 pl-2 pr-1">
        <LoaderImpl className="h-8 w-8" />
      </AlignCenter>
      <QuestionCellLayout
        blockIndicator={<LoaderImpl className="h-8 w-8 !pt-0" />}
        requiredToggle={<LoaderImpl className="h-6 w-[96px]" />}
        textArea={
          <div className="px-3 py-1.5">
            <LoaderImpl className="h-6" />
          </div>
        }
      />
      <BlockTypeContainer>
        <LoaderImpl className="h-10 w-28" />
      </BlockTypeContainer>
    </Row>
  );
}

export const BlocksTable = {
  AlignCenter,
  BlockTypeContainer,
  Body,
  EditButton,
  ExpansionContainer,
  Footer,
  Header,
  Loader,
  QuestionCellLayout,
  RequiredToggle,
  Root,
  Row,
  TotalQuestions,
};
