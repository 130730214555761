import type { ReactNode } from 'react';

import { classNames } from '../Utils/classNames';

type HorizontalRuleProps = {
  children?: ReactNode;
  className?: string;
};

export function HorizontalRule({ children, className }: HorizontalRuleProps) {
  return (
    <section className={classNames('flex items-center', className)}>
      <div className="flex-grow border-t border-gray-5"></div>
      {Boolean(children) && (
        <span className="mx-4 flex-shrink text-sm text-neutral-secondary">
          {children}
        </span>
      )}
      <div className="flex-grow border-t border-gray-5"></div>
    </section>
  );
}
