import type { CollectionColorEnum } from '@assembly-web/services';

export function mapColorToCssClass(color: CollectionColorEnum) {
  switch (color) {
    case 'blue':
      return 'bg-primary-3';
    case 'red':
      return 'bg-error-3';
    case 'yellow':
      return 'bg-upgrade-3';
    case 'green':
      return 'bg-success-3';
    case 'pink':
      return 'bg-magenta-3';
    case 'purple':
      return 'bg-purple-3';
    case 'cyan':
      return 'bg-cyan-3';
    case 'orange':
      return 'bg-warning-3';
    case 'grey':
    default:
      return 'bg-gray-4';
  }
}
