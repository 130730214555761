import {
  type BlockResponseDetails,
  checkValidUrl,
  type FileUploadBlockResponseDetails,
  type FlowPostResponse,
  type OpenEndedBlockResponseDetails,
  type ScaleBlockLabels,
} from '@assembly-web/services';
import {
  AssemblyCurrencyType,
  BlockResponseState,
  BlockTypes,
  MemberState,
  ScaleBlockLabelType,
} from '@assembly-web/services';
import { ChevronDownIcon } from '@heroicons/react/20/solid';
import { useState } from 'react';
import { defineMessages, useIntl } from 'react-intl';
import { twMerge } from 'tailwind-merge';

import { getFormattedMessage } from '../../..';
import { Avatar, AvatarSize } from '../../../DesignSystem/Feedback/Avatar';
import { TextStyle } from '../../../DesignSystem/Feedback/TextStyle';
import type { ToolbarItem } from '../../../DesignSystem/Feedback/Toolbar/Toolbar';
import type { BoostOptions } from '../../Web/Editors/RepliesEditor/RepliesEditor';
import { ProfileViewer } from '../../Web/ProfileViewer/ProfileViewer';
import { FileBlock } from './FileBlock';
import type { PostCardProps } from './PostCard';
import { PostCardActions } from './PostCard';
import { mapHexCodeToEmoticon } from './PostCardHeader';

const messages = defineMessages({
  noAnswer: {
    defaultMessage: 'No answer',
    id: 'Gw6A+4',
  },
  attachment: {
    defaultMessage: `{count, plural, =0 {} one {attachment} other {attachments}}`,
    id: 'pk+Fyy',
  },
  filesDeleted: {
    defaultMessage: 'Files(s) deleted',
    id: 'RA/vS9',
  },
  edited: {
    defaultMessage: 'edited',
    id: 'Zx1w1e',
  },
  deactivatedUser: {
    defaultMessage: '{firstName} {lastName} (deactivated)',
    id: 'tR9Liu',
  },
  cantFindTask: {
    defaultMessage: `Can’t find task`,
    id: 'MVp6ju',
  },
});

function GifBlock({ gifURL }: { gifURL: string }) {
  return (
    <div
      data-chromatic="ignore"
      className="relative z-0 h-screen max-h-[244px] overflow-hidden rounded border border-gray-5 text-gray-9"
    >
      <div
        style={{
          backgroundImage: checkValidUrl(gifURL) ? `url('${gifURL}')` : 'none',
        }}
        className="absolute z-[-1] h-full w-full bg-cover bg-no-repeat blur-[23px]"
        data-chromatic="ignore"
      />
      <img
        className="inset-x-0 top-0 mx-auto my-0 h-full object-contain"
        src={checkValidUrl(gifURL) ? gifURL : ''}
        alt=""
        data-chromatic="ignore"
      />
    </div>
  );
}

function NoAnswerBlock() {
  const { formatMessage } = useIntl();

  return (
    <div className="text-gray-8">
      <span>{formatMessage(messages.noAnswer)}</span>
    </div>
  );
}

function ScaleBlock({
  min,
  max,
  value,
  labels,
  blockId,
  showInCarousel,
}: {
  min: number;
  max: number;
  value: number;
  labels?: ScaleBlockLabels;
  blockId: string;
  showInCarousel?: boolean;
}) {
  return (
    <>
      <div
        className={twMerge(
          'flex w-full grow',
          showInCarousel ? 'gap-1' : 'gap-2'
        )}
      >
        {Array.from(
          { length: max + 1 - min },
          (_, scaleIterable) => scaleIterable + min
        ).map((scaleIterable) => (
          <span
            className={twMerge(
              'flex-[1] rounded py-1 text-center',
              scaleIterable === value
                ? 'bg-primary-2 text-primary-6'
                : 'bg-gray-2 text-gray-9'
            )}
            key={`scale-block-label-${blockId}-${scaleIterable}`}
          >
            {scaleIterable}
          </span>
        ))}
      </div>
      <div className="flex w-full grow gap-1">
        {Object.values(ScaleBlockLabelType).map((labelName) => {
          return (
            <span
              className={twMerge(
                'flex-[1] rounded py-1 text-gray-9',

                labelName === ScaleBlockLabelType.Low && 'text-left',
                labelName === ScaleBlockLabelType.Middle && 'text-center',
                labelName === ScaleBlockLabelType.High && 'text-end'
              )}
              key={labelName}
            >
              {labels ? (labels[labelName] ?? '') : ''}
            </span>
          );
        })}
      </div>
    </>
  );
}

function CollapsibleFileBlock({
  responseBlock,
  onFileClick,
  onToolbarItemClick,
  showInCarousel,
  isOpenEndedBlockCarousel,
}: {
  responseBlock: FileUploadBlockResponseDetails | OpenEndedBlockResponseDetails;
  onFileClick: (responseBlock: { blockId: string; fileName: string }) => void;
  onToolbarItemClick?:
    | (({ args, fileName }: { args: ToolbarItem; fileName: string }) => void)
    | undefined;
  showInCarousel?: boolean;
  isOpenEndedBlockCarousel?: boolean;
}) {
  const { formatMessage } = useIntl();

  const [isOpen, setIsOpen] = useState(true);
  const handleFileClick = (fileName: string) => {
    onFileClick({ blockId: responseBlock.blockId, fileName });
  };

  return responseBlock.response.files ? (
    <div>
      {!showInCarousel && (
        <button
          onClick={() => setIsOpen(!isOpen)}
          className="flex items-center pb-2 text-xs text-gray-8"
        >
          {isOpen ? 'Hide' : 'Show'} {responseBlock.response.files.length}{' '}
          {formatMessage(messages.attachment, {
            count: responseBlock.response.files.length,
          })}
          <ChevronDownIcon
            className={twMerge(
              'ml-1 inline h-4 w-4',
              isOpen && 'rotate-180 transform'
            )}
          />
        </button>
      )}
      <div
        className={twMerge(
          'grid text-gray-9 transition-all',
          isOpen ? 'grid-rows-[1fr]' : 'grid-rows-[0fr]'
        )}
      >
        <FileBlock
          files={responseBlock.response.files}
          onFileClick={handleFileClick}
          onToolbarItemClick={onToolbarItemClick}
          showInCarousel={showInCarousel ?? isOpenEndedBlockCarousel}
        />
      </div>
    </div>
  ) : null;
}

function BlockContent({
  responseBlock,
  responseId,
  onPostCardBodyClick,
  currency,
  onFileClick,
  showEdit,
  onToolbarItemClick,
  showInCarousel,
  onMemberClick,
  boostOptions,
}: {
  boostOptions?: BoostOptions;
  responseBlock: BlockResponseDetails;
  responseId: string;
  onPostCardBodyClick: PostCardProps['onPostCardBodyClick'];
  currency: PostCardProps['currency'];
  onFileClick: (responseBlock: { blockId: string; fileName: string }) => void;
  showEdit: boolean;
  onToolbarItemClick?: (responseBlock: {
    blockId: string;
    fileName: string;
    itemId: string;
  }) => void;
  showInCarousel?: boolean;
  onMemberClick: (memberId: string) => void;
}) {
  const { formatMessage } = useIntl();

  const handleToolbarItemClick = ({
    args,
    fileName,
  }: {
    args: ToolbarItem;
    fileName: string;
  }) => {
    onToolbarItemClick?.({
      blockId: responseBlock.blockId,
      fileName,
      itemId: args.id,
    });
  };

  const editTag = showEdit ? (
    <TextStyle as="span" variant="sm-regular" subdued>
      ({formatMessage(messages.edited)})
    </TextStyle>
  ) : null;

  if (responseBlock.state === BlockResponseState.Skipped) {
    if (
      responseBlock.type === BlockTypes.FileUpload &&
      showEdit &&
      responseBlock.edited
    ) {
      return (
        <TextStyle className="text-gray-8">
          {formatMessage(messages.filesDeleted)}
          {editTag}
        </TextStyle>
      );
    }
    return <NoAnswerBlock />;
  }

  switch (responseBlock.type) {
    case BlockTypes.MultiChoice:
    case BlockTypes.Dropdown:
      return (
        <div className="text-gray-9">
          {responseBlock.response.value.map((i) => (
            <p key={`${responseId}-${responseBlock.blockId}-${i.id}`}>
              {' '}
              {i.value} {Boolean(responseBlock.edited) && editTag}
            </p>
          ))}
        </div>
      );

    case BlockTypes.Gif:
      return (
        <>
          <GifBlock gifURL={responseBlock.response.value} />
          {Boolean(responseBlock.edited) && editTag}
        </>
      );

    case BlockTypes.FileUpload:
      return responseBlock.response.files.length > 0 ? (
        <>
          <CollapsibleFileBlock
            onFileClick={onFileClick}
            responseBlock={responseBlock}
            onToolbarItemClick={handleToolbarItemClick}
            showInCarousel={showInCarousel}
          />
          {Boolean(responseBlock.edited) && editTag}
        </>
      ) : (
        <NoAnswerBlock />
      );

    case BlockTypes.GivePointsStack: {
      const currencyIcon =
        currency.type === AssemblyCurrencyType.Custom ? (
          <img
            alt={currency.name}
            className="mr-0.5 h-3 w-3"
            src={currency.value}
          />
        ) : (
          mapHexCodeToEmoticon(currency.value)
        );

      return (
        <div className="flex flex-row items-center text-gray-9">
          {currencyIcon} {responseBlock.response.value}
        </div>
      );
    }

    case BlockTypes.OpenEnded:
      return (
        <div className="flex flex-col gap-2 text-gray-9">
          <div
            className={twMerge(
              'gap-1 [overflow-wrap:anywhere]',
              showInCarousel ? 'leading-[22px]' : 'my-1 leading-6'
            )}
          >
            {getFormattedMessage(
              responseBlock.response,
              onPostCardBodyClick,
              {},
              onMemberClick,
              boostOptions
            )}{' '}
            {Boolean(responseBlock.edited) && editTag}
          </div>
          {Array.isArray(responseBlock.response.files) &&
          responseBlock.response.files.length > 0 ? (
            <CollapsibleFileBlock
              onFileClick={onFileClick}
              responseBlock={responseBlock}
              onToolbarItemClick={handleToolbarItemClick}
              isOpenEndedBlockCarousel={showInCarousel}
            />
          ) : null}
          {responseBlock.response.gifUrl ? (
            <GifBlock gifURL={responseBlock.response.gifUrl} />
          ) : null}
        </div>
      );

    case BlockTypes.PersonSelector:
      return (
        <>
          <div className="flex flex-wrap gap-2 text-gray-9">
            {responseBlock.response.persons.map((person) => {
              if (person.memberState === MemberState.Deactivated) {
                return (
                  <div
                    key={`${responseId}-${responseBlock.blockId}-${person.memberId}`}
                    className="mt-1 flex w-fit items-center gap-2 rounded-2xl bg-gray-2 p-1 pr-4"
                  >
                    <Avatar
                      className="grayscale"
                      image={person.image}
                      memberID={person.memberId}
                      name={person.name.firstName.toString()}
                      size={AvatarSize.Small}
                    />
                    <TextStyle variant="base-regular" className="text-gray-8">
                      {formatMessage(messages.deactivatedUser, {
                        firstName: person.name.firstName,
                        lastName: person.name.lastName,
                      })}
                    </TextStyle>
                  </div>
                );
              } else {
                return (
                  <ProfileViewer
                    boostOptions={boostOptions}
                    onViewProfileClick={() => {
                      onMemberClick(person.memberId);
                    }}
                    userDetails={{
                      ...person,
                      memberID: person.memberId,
                      firstName: person.name.firstName,
                      lastName: person.name.lastName ?? '',
                    }}
                    key={`${responseId}-${responseBlock.blockId}-${person.memberId}`}
                  >
                    <button
                      key={`${responseId}-${responseBlock.blockId}-${person.memberId}`}
                      onClick={() => {
                        onPostCardBodyClick(
                          PostCardActions.PersonClicked,
                          person.memberId
                        );
                      }}
                      className="mt-1 flex w-fit items-center gap-2 rounded-2xl bg-gray-3 py-1 pl-1 pr-2 text-gray-9 hover:bg-gray-4"
                    >
                      <Avatar
                        image={person.image}
                        memberID={person.memberId}
                        name={person.name.firstName.toString()}
                        size={AvatarSize.Small}
                      />
                      {`${person.name.firstName.toString()} ${(
                        person.name.lastName ?? ''
                      ).toString()}`}
                    </button>
                  </ProfileViewer>
                );
              }
            })}
          </div>
          {Boolean(responseBlock.edited) && editTag}
        </>
      );

    case BlockTypes.Scale:
      return (
        <>
          <ScaleBlock
            min={responseBlock.content.min}
            max={responseBlock.content.max}
            value={responseBlock.response.value}
            labels={responseBlock.content.labels}
            blockId={responseBlock.blockId}
            showInCarousel={showInCarousel}
          />
          {Boolean(responseBlock.edited) && editTag}
        </>
      );

    default:
      return null;
  }
}

export function PostCardBody({
  flowResponse,
  onPostCardBodyClick,
  currency,
  onFileClick,
  onBlockToolbarItemClick,
  showInCarousel = false,
  contentClassName,
  className,
  onMemberClick,
  boostOptions,
}: {
  boostOptions?: BoostOptions;
  flowResponse: FlowPostResponse;
  onPostCardBodyClick: PostCardProps['onPostCardBodyClick'];
  currency: PostCardProps['currency'];
  onFileClick: (responseBlock: { blockId: string; fileName: string }) => void;
  onBlockToolbarItemClick?: (responseBlock: {
    blockId: string;
    fileName: string;
    itemId: string;
  }) => void;
  showInCarousel?: boolean;
  contentClassName?: string;
  className?: string;
  onMemberClick: (memberId: string) => void;
}) {
  return (
    <section className={twMerge('mb-2 @container/image', className)}>
      {flowResponse.responses.map((responseBlock) => {
        const showBlock = !(
          responseBlock.type === BlockTypes.GivePointsStack &&
          responseBlock.content.rules.hidePoints
        );
        return (
          <div
            className={twMerge(
              'text-left',
              showInCarousel ? 'pt-2' : 'my-4',
              contentClassName
            )}
            key={`${responseBlock.blockId}-${responseBlock.content.title}`}
          >
            {showBlock ? (
              <>
                <div className="text-sm text-gray-8">
                  {responseBlock.content.title}
                </div>
                <BlockContent
                  boostOptions={boostOptions}
                  onMemberClick={onMemberClick}
                  responseId={flowResponse.responseId}
                  responseBlock={responseBlock}
                  onPostCardBodyClick={onPostCardBodyClick}
                  currency={currency}
                  onFileClick={onFileClick}
                  showEdit={flowResponse.flow.flowId !== 'recognition'}
                  onToolbarItemClick={onBlockToolbarItemClick}
                  showInCarousel={showInCarousel}
                />
              </>
            ) : null}
          </div>
        );
      })}
    </section>
  );
}
