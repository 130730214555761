/*
  Flatten request object to pass as query params
  @param obj: object to flatten
  @param prefix: prefix for keys, if no prefix is provided, takes the root key of the object
  Example: {filter:{state:'PENDING}} -> {filter.state:'PENDING'}
 */
export function flattenObject<T extends object>(
  obj: T,
  prefix = ''
): Record<string, string | null | undefined> {
  return Object.entries(obj).reduce((acc, [key, value]) => {
    const newKey = prefix ? `${prefix}.${key}` : key;
    if (value !== null && typeof value === 'object' && !Array.isArray(value)) {
      return { ...acc, ...flattenObject(value, newKey) };
    }
    return { ...acc, [newKey]: value };
  }, {});
}

/*
  Convert object to query params string to be used in PUT requests
  @param params: object to convert
  Example: {filter.state:'PENDING',filter.name:'John'} -> filter.state=PENDING&filter.name=John
 */
export function toQueryParams(
  params: Record<string, string | undefined | null>
) {
  const searchParams = new URLSearchParams();

  Object.entries(params).forEach(([key, value]) => {
    if (value !== undefined && value !== null) {
      searchParams.append(key, value);
    }
  });

  return searchParams.toString();
}
