// TODO: fix types so that we don't need the no-unnecessary-condition rule to be disabled
/* eslint-disable @eslint-community/eslint-comments/disable-enable-pair */
/* eslint-disable @typescript-eslint/no-unnecessary-condition */
import isEmpty from 'lodash/isEmpty';

import { collectionColor } from './collectionItemUtils';
import { getFileTypeForFile } from './fileUtils';
import { mapHexCodeToEmoticon } from './flowUtils';
import { getMinMaxInAdminRange } from './rewardsUtils';
import type { Icon } from './types/collections';
import type { Member } from './types/domain';
import { type MemberDetails, MemberRole, MemberState } from './types/flowTypes';
import {
  type ChallengeCommentSearchResult,
  type ChallengeGroupedCommentSearchResult,
  type ChallengeSearchResult,
  checkIfCommentKindMatches,
  type CollectionSearchResult,
  type ExternalFileSearchResult,
  type FileSearchResult,
  type FlowSearchResult,
  type LinkSearchResult,
  type MemberSearchResult,
  type PostCommentSearchResult,
  type PostGroupedCommentSearchResult,
  type PostSearchResult,
  type RecognitionSearchResult,
  type ResponseCommentSearchResult,
  type ResponseGroupedCommentSearchResult,
  type ResponseSearchResult,
  type RewardSearchResult,
  type SearchIndexResult,
  type TaskSearchResult,
  type UrlSearchResult,
} from './types/searchIndex';

function addClassToEmTags(text: string | undefined) {
  return text?.replace(/<em>/g, `<em class="font-bold not-italic">`);
}

function formatFromMember(fromMember: MemberDetails) {
  return {
    firstName: fromMember.firstName,
    lastName: fromMember.lastName,
    profileStatus: fromMember.memberState,
    entityId: fromMember.memberID,
    profileImageUrl: fromMember.image,
    fullName:
      fromMember.name ?? `${fromMember.firstName} ${fromMember.lastName}`,
    pronouns: fromMember?.pronouns,
    email: fromMember?.email,
    department: fromMember?.department,
    jobTitle: fromMember?.jobTitle,
  };
}

function getExternalFileCardDetails(file: ExternalFileSearchResult) {
  const {
    integrationName: plainIntegrationName,
    name: plainFileName,
    remoteLocation,
    type: fileType,
    entityId: fileId,
  } = file._meta;

  const formattedIntegrationName =
    addClassToEmTags(file.highlight['_meta.integrationName']?.[0]) ??
    plainIntegrationName;

  const formattedFileName =
    addClassToEmTags(file.highlight['_meta.name']?.[0]) ?? plainFileName;

  return {
    fileId,
    fileType,
    formattedIntegrationName,
    formattedFileName,
    integrationIcon: file._meta.integrationIcon,
    plainFileName,
    plainIntegrationName,
    remoteLocation,
  };
}

function getFileCardDetails(file: FileSearchResult) {
  const flowEmoji = mapHexCodeToEmoticon(file._meta.flowDetails.emoji);
  const flowName =
    flowEmoji +
    ' ' +
    (addClassToEmTags(file.highlight['_meta.flowDetails.name']?.[0]) ??
      file._meta.flowDetails.name);

  const creatorName =
    addClassToEmTags(
      file.highlight['_meta.responseDetails.respondent.fullName']?.[0]
    ) ?? file._meta.responseDetails.respondent.fullName;

  const iconLabel = getFileTypeForFile(file._meta.type);

  const formattedOriginalFileName =
    addClassToEmTags(file.highlight['_meta.originalName']?.[0]) ??
    file._meta.originalName ??
    file.name;

  const uploadedAt = new Date(file._meta.uploadedAt);
  const fileContent = file.highlight['deepSearchableContent.content']
    ? file.highlight['deepSearchableContent.content'].reduce(
        (acc, value) => acc + addClassToEmTags(value),
        ''
      )
    : null;

  return {
    blockId: file._meta.blockId,
    fileId: file._meta.entityId,
    responseId: file._meta.responseId,
    flowId: file._meta.flowDetails.flowId,
    internalFileName: file._meta.name,
    claimId: file._meta.claimId,
    creatorName,
    formattedOriginalFileName,
    flowName,
    iconLabel,
    uploadedAt,
    fileContent,
    originalFileName: file._meta.originalName,
  };
}

function getLinkCardDetails(link: LinkSearchResult) {
  const { details, entityId } = link._meta;

  const title =
    addClassToEmTags(link.highlight['_meta.details.title']?.[0]) ??
    details.title;
  const url =
    addClassToEmTags(link.highlight['_meta.details.url']?.[0]) ?? details.url;

  const isRecognitionFlow = details.url.endsWith(`/flows/recognition`);

  return {
    entityId,
    title,
    url,
    plainUrl: details.url,
    isRecognitionFlow,
  };
}

function getMemberCardDetails(member: MemberSearchResult) {
  const {
    department,
    email,
    entityId: memberID,
    fullName,
    jobTitle,
    location,
    profileImageUrl: iconUrl,
  } = member._meta;

  const description = [
    addClassToEmTags(member.highlight['_meta.jobTitle']?.[0]) ?? jobTitle,
    addClassToEmTags(member.highlight['_meta.department']?.[0]) ?? department,
    addClassToEmTags(member.highlight['_meta.email']?.[0]) ?? email,
    addClassToEmTags(member.highlight['_meta.location']?.[0]) ?? location,
  ]
    .filter(Boolean)
    .join(' | ');

  const memberName =
    addClassToEmTags(member.highlight['_meta.fullName']?.[0]) ?? fullName;

  return {
    fullName,
    description,
    iconUrl,
    urlSlug: `/user/${memberID}`,
    memberName,
    memberID,
  };
}

function getTaskCardDetails(task: TaskSearchResult) {
  const taskId = task._meta.entityId;

  const taskTitle =
    addClassToEmTags(task.highlight['_meta.title']?.[0]) ?? task._meta.title;

  const description =
    addClassToEmTags(task.highlight['_meta.description']?.[0]) ??
    task._meta.description;

  return {
    taskId,
    taskTitle,
    plainTaskTitle: task._meta.title,
    description,
    state: task._meta.state,
    assignedTo: task._meta.assignedTo.id,
    transactionId: task._meta.transactionId,
    urlSlug: `/notebook/board?taskId=${taskId}`,
  };
}

function getFlowCardDetails(flow: FlowSearchResult | RecognitionSearchResult) {
  const { description: flowDescription, icon, name, entityId } = flow._meta;

  const ownerId = 'ownerId' in flow._meta ? flow._meta.ownerId : '';

  const flowStatus = 'state' in flow ? flow.state : 'ACTIVE';

  const emoji = mapHexCodeToEmoticon(icon);

  const flowName = addClassToEmTags(flow.highlight['_meta.name']?.[0]) ?? name;

  const plainFlow = `${mapHexCodeToEmoticon(icon)} ${name}`;

  const description =
    addClassToEmTags(flow.highlight['_meta.description']?.[0]) ??
    flowDescription;

  return {
    flowName,
    plainFlowName: name,
    description,
    flowId: entityId,
    urlSlug: `/flows/${entityId}`,
    ownerId,
    emoji,
    plainFlow,
    flowStatus,
  };
}

const RECOGNITION_FLOW = '🎉 Give Recognition';

function getCommentCardDetails(
  comment: PostCommentSearchResult | ResponseCommentSearchResult
) {
  const { entityId: commentId, gifURL } = comment._meta;

  let flow;
  let post;
  let urlSlug;
  let currentPostId = '';
  let currentFlowId = '';
  let currentResponseId = '';
  let postOwner;
  let kind = '';
  let message = '';
  let commentOwner;
  let postCreatedAt = '';
  let collectionTitle = '';

  if (!isEmpty(comment.cardDetails)) {
    if ('version' in comment.cardDetails && comment.cardDetails.version === 2) {
      message = comment.cardDetails.messageHtml;
    } else {
      if (comment.cardDetails.taggedUsers.length) {
        message = comment.cardDetails.message;
        collectionTitle = comment._meta.message;
      } else {
        message = comment._meta.message;
      }
    }
  } else {
    message = comment._meta.message;
  }

  if (checkIfCommentKindMatches(comment, 'post')) {
    const { postId } = comment._meta;

    const postDetails = comment.cardDetails.post.post;
    const postType = postDetails.type;

    currentPostId = postId;

    if (postDetails.from?.firstName) {
      postOwner = formatFromMember(postDetails.from);
    } else {
      // deleted user
      postOwner = {
        fullName: postDetails.from?.name,
        profileStatus: MemberState.Deactivated,
        entityId: postDetails.from?.memberID,
      };
    }

    switch (postType) {
      case 'anniversary':
      case 'birthday': {
        const recipient =
          addClassToEmTags(
            comment.highlight['_meta.postDetails.to.fullName']?.join()
          ) ?? postDetails.to[0].name;

        const article = postType.startsWith('a') ? 'an' : 'a';

        const capitalizedPostType = addClassToEmTags(
          (
            comment.highlight['_meta.postDetails.type']?.[0] ?? postType
          ).replace(
            /^(<em>)?(.)/,
            (_, tag, letter) => `${tag ? tag : ''}${letter.toUpperCase()}`
          )
        );

        post = `${article} ${capitalizedPostType} Post for ${recipient}`;
        postOwner = {
          fullName:
            postType === 'anniversary' ? 'Happy Anniversary' : 'Happy Birthday',
          profileStatus: MemberState.Active,
          entityId: '',
          type: postType,
        };

        break;
      }
      case 'recognition': {
        const authorOfRecognitionPost =
          addClassToEmTags(
            comment.highlight['_meta.postDetails.from.fullName']?.[0]
          ) ?? postDetails.from.name;

        post = `${authorOfRecognitionPost}'s post`;

        break;
      }
    }

    flow = RECOGNITION_FLOW;
    kind = 'recognition';

    urlSlug = `/post/flow/recognition?postId=${postId}&commentId=${commentId}&type=recognition&showReplies=true`;
    postCreatedAt = postDetails.createdAt;
  } else {
    const {
      _meta: {
        flowDetails: { icon: flowIcon },
        flowId,
        responseId,
      },
    } = comment;
    currentFlowId = flowId;
    currentResponseId = responseId;

    const responseDetails = comment.cardDetails.response;
    const respondentDetails = responseDetails.respondent;
    const responseKind = responseDetails.kind;

    const flowEmoji = mapHexCodeToEmoticon(flowIcon);
    const flowName =
      addClassToEmTags(comment.highlight['_meta.flowDetails.name']?.[0]) ??
      comment._meta.flowDetails.name;

    flow = `${flowEmoji} ${flowName}`;

    if (responseKind.includes('ANONYMOUS')) {
      post = 'an Anonymous post';
      postOwner = {
        profileStatus: MemberState.Active,
        entityId: '',
        fullName: 'Anonymous User',
        type: 'anonymous',
      };
    } else {
      const authorOfResponse =
        addClassToEmTags(
          comment.highlight['_meta.responseDetails.from.fullName']?.[0]
        ) ?? respondentDetails.name;

      post = `${authorOfResponse}'s post`;
      if (respondentDetails.firstName) {
        postOwner = formatFromMember(respondentDetails);
      } else {
        // deleted user
        postOwner = {
          profileStatus: MemberState.Deactivated,
          entityId: respondentDetails.memberID,
          fullName: respondentDetails.name,
        };
      }
    }

    urlSlug = `/post/flow/flows?flowId=${flowId}&responseId=${responseId}&commentId=${commentId}&type=flow&showReplies=true`;
    kind = 'response';
    postCreatedAt = responseDetails.createdAt;
  }

  const author =
    addClassToEmTags(comment.highlight['_meta.from.fullName']?.join()) ??
    comment._meta.from.fullName;

  const authorId = comment._meta.from.id;

  if (comment.cardDetails?.type?.includes('ANONYMOUS')) {
    commentOwner = {
      memberID: '',
      email: '',
      firstName: 'Anonymous User',
      lastName: '',
      name: 'Anonymous User',
      username: 'anonymous',
      pointsGiven: 0,
      totalPointsGiven: 0,
      memberState: MemberState.Active,
      role: [MemberRole.Admin],
    };
  } else {
    commentOwner = comment.cardDetails && comment.cardDetails.fromMember;
  }

  return {
    content: message,
    taggedUsers: comment.cardDetails.taggedUsers,
    boostedUsers: comment.cardDetails.boost,
    urlSlug,
    author,
    // TODO: post should be formulated in the commentCard component using formattedMessage
    post: post ?? '',
    flow,
    gifURL,
    commentId,
    authorId,
    postId: currentPostId,
    flowId: kind === 'recognition' ? 'recognition' : currentFlowId,
    responseId: kind === 'recognition' ? currentPostId : currentResponseId,
    createdAt: new Date(
      !isEmpty(comment.createdAt)
        ? comment.createdAt
        : comment.cardDetails.createdAt
    ),
    postCreatedAt,
    postOwner,
    commentOwner: commentOwner,
    showPostInteractionSettings: comment.cardDetails.showTurnOffSettings,
    editReplySettings: {
      isV3Reply: comment.cardDetails.version === 2,
      isAnonymousReplyEditable: comment.cardDetails.canEdit,
    },
    pointsEach: comment.cardDetails.pointsEach,
    kind,
    reactions: comment.cardDetails.reactions,
    isEdited: Boolean(comment.cardDetails.editedAt),
    collectionTitle: collectionTitle,
  };
}

function getChallengeCommentCardDetails(
  comment: ChallengeCommentSearchResult | ChallengeGroupedCommentSearchResult
) {
  const challengeDetails = comment.cardDetails.challenge;
  return {
    challengeId: challengeDetails.challengeId,
    title: challengeDetails.title,
    isEdited: Boolean(comment.cardDetails.editedAt),
    replyUrlSlug: '',
    content: comment.cardDetails?.messageHtml,
    commentId: comment.cardDetails?.commentID ?? comment._meta.entityId,
    commentOwner: comment.cardDetails?.fromMember,
    challengeOwner: formatFromMember(challengeDetails.createdBy),
    commentCreatedAt: comment.createdAt,
    reactions: comment.cardDetails?.reactions,
    boostedUsers: comment.cardDetails?.boost,
    commentCount:
      comment.type === 'groupedComment' ? comment._meta.commentsCount : 0,
  };
}

function getGroupedCommentCardDetails(
  comment: PostGroupedCommentSearchResult | ResponseGroupedCommentSearchResult
) {
  const { entityId: commentId, gifURL } = comment._meta;

  let content = '';
  let collectionTitle = '';
  if (comment.cardDetails.version === 2) {
    content = comment.cardDetails.messageHtml;
  } else {
    if (comment.cardDetails.taggedUsers.length) {
      content = comment.cardDetails.message;
      collectionTitle = comment._meta.message;
    } else {
      content = comment._meta.message;
    }
  }
  let flow;
  let replyUrlSlug = '';
  let postOwner;
  let currentFlowId = '';
  let currentPostId = '';
  let currentResponseId = '';
  let kind = '';
  let commentOwner;
  let postCreatedAt = '';

  if (checkIfCommentKindMatches(comment, 'post')) {
    const postDetails = comment.cardDetails.post.post;

    const { postId } = comment._meta;
    currentPostId = postId;
    kind = 'recognition';
    flow = RECOGNITION_FLOW;
    replyUrlSlug = `/post/flow/recognition?postId=${postId}&commentId=${commentId}&type=recognition&showReplies=true`;

    if (postDetails.from?.name) {
      postOwner = formatFromMember(postDetails.from);
    } else if (['anniversary', 'birthday'].includes(postDetails.type)) {
      postOwner = {
        fullName:
          postDetails.type === 'anniversary'
            ? 'Happy Anniversary'
            : 'Happy Birthday',
        profileStatus: MemberState.Active,
        entityId: '',
        type: postDetails.type,
      };
    } else {
      // deleted user
      postOwner = {
        fullName: postDetails.from.name,
        profileStatus: MemberState.Deactivated,
        entityId: postDetails.from.memberID,
      };
    }

    postCreatedAt = postDetails.createdAt;
  } else {
    const {
      _meta: {
        flowDetails: { icon: flowIcon },
        flowId,
        responseId,
      },
    } = comment;
    currentFlowId = flowId;
    currentResponseId = responseId;
    kind = 'response';

    const responseDetails = comment.cardDetails.response;
    const respondentDetails = responseDetails.respondent;
    const responseKind = responseDetails.kind;

    const flowEmoji = mapHexCodeToEmoticon(flowIcon);
    const flowName = comment._meta.flowDetails.name;

    flow = `${flowEmoji} ${flowName}`;

    replyUrlSlug = `/post/flow/flows?flowId=${flowId}&responseId=${responseId}&commentId=${commentId}&type=flow&showReplies=true`;
    if (responseKind.includes('ANONYMOUS')) {
      postOwner = {
        profileStatus: MemberState.Active,
        entityId: '',
        fullName: 'Anonymous User',
        type: 'anonymous',
      };
    } else {
      if (respondentDetails.name) {
        postOwner = formatFromMember(respondentDetails);
      } else {
        // deleted user
        postOwner = {
          profileStatus: MemberState.Deactivated,
          entityId: respondentDetails.memberID,
          fullName: respondentDetails.name,
        };
      }
    }
    postCreatedAt = responseDetails.createdAt;
  }

  if (comment.cardDetails.type?.includes('ANONYMOUS')) {
    commentOwner = {
      memberID: '',
      email: '',
      firstName: 'Anonymous User',
      lastName: '',
      name: 'Anonymous User',
      username: 'anonymous',
      pointsGiven: 0,
      totalPointsGiven: 0,
      memberState: MemberState.Active,
      role: [MemberRole.Admin],
    };
  } else {
    commentOwner = comment.cardDetails.fromMember;
  }
  return {
    content,
    taggedUsers: comment.cardDetails.taggedUsers,
    boostedUsers: comment.cardDetails.boost,
    replyUrlSlug,
    kind,
    flowId: kind === 'recognition' ? 'recognition' : currentFlowId,
    postId: currentPostId,
    responseId: kind === 'recognition' ? currentPostId : currentResponseId,
    flow,
    gifURL,
    postOwner,
    commentCount: comment._meta.commentsCount,
    commentOwner: commentOwner,
    commentId,
    commentCreatedAt: comment.createdAt,
    postCreatedAt,
    showPostInteractionSettings: comment.cardDetails.showTurnOffSettings,
    hideReactions: comment.cardDetails.hideReactions,
    hideReplies: comment.cardDetails.hideReplies,
    editReplySettings: {
      isV3Reply: comment.cardDetails.version === 2,
      isAnonymousReplyEditable: comment.cardDetails.canEdit,
    },
    reactions: comment.cardDetails.reactions,
    pointsEach: comment.cardDetails && comment.cardDetails.pointsEach,
    isEdited: Boolean(comment.cardDetails.editedAt),
    collectionTitle: collectionTitle,
  };
}

function getUrlCardDetails(details: UrlSearchResult) {
  const { url, pageDescription, pageTitle, type } = details._meta;

  return {
    url,
    type,
    title: pageTitle,
    description: pageDescription,
  };
}

function getPostCardDetails(post: PostSearchResult) {
  const { entityId: postId, message: postMessage, type: postType } = post._meta;

  const description =
    addClassToEmTags(post.highlight['_meta.message']?.join('...')) ??
    postMessage;

  let title;
  let plainTitle;

  const author =
    addClassToEmTags(post.highlight['_meta.from.fullName']?.join()) ??
    post._meta.from.fullName;

  const authorId = post._meta.from.id;

  let celebratedTeammate = '';
  let celebratedTeammateId = '';

  if (postType === 'birthday' || postType === 'anniversary') {
    const capitalizedPostType = addClassToEmTags(
      (post.highlight['_meta.type']?.[0] ?? postType).replace(
        /^(<em>)?(.)/,
        (_, tag, letter) => `${tag ? tag : ''}${letter.toUpperCase()}`
      )
    );

    celebratedTeammate =
      addClassToEmTags(post.highlight['_meta.to.fullName']?.[0]) ??
      post._meta.to[0].fullName;
    celebratedTeammateId = post._meta.to[0].id;

    title = `${capitalizedPostType} Post for ${celebratedTeammate}`;
    plainTitle = `${postType.toUpperCase()} Post for ${
      post._meta.to[0].fullName
    }`;
  } else {
    const author =
      addClassToEmTags(post.highlight['_meta.from.fullName']?.[0]) ??
      post._meta.from.fullName;

    title = `Post by ${author}`;
    plainTitle = `Post by ${post._meta.from.fullName}`;
  }

  const hasReplyDraft = Boolean(post.cardDetails?.hasReplyDraft);

  return {
    title,
    plainTitle,
    postId,
    author,
    plainAuthor: post._meta.from.fullName,
    postType,
    authorId,
    description,
    hasReplyDraft,
    celebratedTeammate,
    plainCelebratedTeammate: post._meta.to[0].fullName,
    celebratedTeammateId,
    flow: RECOGNITION_FLOW,
    createdAt: new Date(post.createdAt),
    urlSlug: `/post/flow/recognition?postId=${postId}&showReplies=true&type=recognition`,
  };
}

function getResponseCardDetails(response: ResponseSearchResult) {
  const { createdAt } = response;
  const {
    entityId: responseId,
    flowId,
    icon,
    name,
    responseKind,
    responseBlocks,
  } = response._meta;

  const author = responseKind.includes('ANONYMOUS')
    ? 'Anonymous'
    : (addClassToEmTags(
        response.highlight['_meta.respondent.fullName']?.join()
      ) ?? response._meta.respondent.fullName);

  const plainAuthor = responseKind.includes('ANONYMOUS')
    ? 'Anonymous'
    : response._meta.respondent.fullName;

  const authorId = response._meta.respondent.id;

  const flowEmoji = mapHexCodeToEmoticon(icon);
  const flowName =
    addClassToEmTags(response.highlight['_meta.name']?.[0]) ?? name;
  const flow = `${flowEmoji} ${flowName}`;

  const description =
    addClassToEmTags(response.highlight.searchableContent?.join('...')) ??
    responseBlocks.join(' ');

  const [
    hideReplies,
    hideReactions,
    hasReplyDraft,
    showPostInteractionSettings,
  ] = [
    response.cardDetails?.hideReplies ?? false,
    response.cardDetails?.hideReactions ?? false,
    response.cardDetails?.hasReplyDraft ?? false,
    response.cardDetails?.showTurnOffSettings ?? false,
  ];

  return {
    flowId,
    responseId,
    createdAt: new Date(createdAt),
    description,
    urlSlug: `/post/flow/flows?flowId=${flowId}&responseId=${responseId}&showReplies=true&type=flow`,
    author,
    plainAuthor,
    flow,
    plainFlow: `${flowEmoji} ${name}`,
    authorId,
    hideReplies,
    hideReactions,
    hasReplyDraft,
    showPostInteractionSettings,
  };
}

function getCollectionCardDetails(collection: CollectionSearchResult) {
  const {
    _meta: {
      details: { description, icon, name, color, isPinned },
      entityId,
      createdByRef,
    },
    cardDetails,
  } = collection;

  const isDefault = name === 'Welcome to Collections' && icon === '1f680';

  return {
    collectionId: entityId,
    color: collectionColor.hexToColorMap[color],
    description: description ?? '',
    icon: {
      value: icon,
      kind: 'HEX_CODE',
    } as Icon,
    isDefault,
    isPinned,
    isShared: Boolean(cardDetails?.isShared),
    collectionName: name,
    createdBy: {
      memberId: createdByRef.entityId,
      name: createdByRef.fullName,
      memberID: createdByRef.entityId,
    } satisfies Partial<Member>,
    isSoftPinChecked: cardDetails?.isSoftPinned,
    allowedEditing: cardDetails?.allowedEditing,
  };
}

function getCurrencySpecificRange(
  originalCost: { min: number; max: number },
  currencyExchangeRate: number,
  exchangeRate: number
) {
  return {
    min: Math.ceil(
      ((originalCost.min as number) * exchangeRate) / currencyExchangeRate
    ).toLocaleString(),
    max: Math.ceil(
      ((originalCost.max as number) * exchangeRate) / currencyExchangeRate
    ).toLocaleString(),
  };
}

function getRewardCardDetails(
  reward: RewardSearchResult,
  workplaceSlugPath?: string,
  exchangeRate?: number,
  exchangeRates?: Record<string, number>,
  denominationRange?: {
    min: number | null;
    max: number | null;
  }
) {
  const { type, name } = reward;
  const result = { title: name, type };
  if (type === 'tangoReward') {
    let { items, imageUrls, description, brandKey, denominations } =
      reward._meta.cardDetails;

    const { tags } = reward;
    const isCharity = tags.includes('donation');

    let costBeforeCurrencyExchange;
    //Get denominations within admin set range
    if (exchangeRates) {
      costBeforeCurrencyExchange = getMinMaxInAdminRange(
        exchangeRates,
        isCharity,
        denominations,
        denominationRange
      );
    }

    if (!costBeforeCurrencyExchange)
      return {
        ...result,
        image: imageUrls ? imageUrls['278w-326ppi'] : '',
        description,
        id: brandKey,
        cost: null,
        currencyCode: items[0].currencyCode,
      };

    //Final cost has to be a string, to show commas. Example: 3,122
    let cost;
    const currencyCode = denominations?.[0].currencyCode;
    if (currencyCode) {
      const currencyExchangeRate =
        currencyCode === 'USD' ? 1 : exchangeRates?.[currencyCode];
      if (currencyExchangeRate && exchangeRate) {
        cost = getCurrencySpecificRange(
          costBeforeCurrencyExchange,
          currencyExchangeRate,
          exchangeRate
        );
      }
    }

    return {
      ...result,
      image: imageUrls ? imageUrls['278w-326ppi'] : '',
      description:
        addClassToEmTags(
          reward.highlight['_meta.cardDetails.description']?.join(' ')
        ) ?? description,
      id: brandKey,
      cost,
      currencyCode: items[0].currencyCode,
    };
  } else if (type === 'swag') {
    const { image, points, uid, desc } = reward._meta.details;
    return {
      ...result,
      image,
      description:
        addClassToEmTags(reward.highlight['_meta.details.desc']?.join(' ')) ??
        desc,
      id: uid,
      cost: points[0].toLocaleString(),
    };
  } else {
    const { image, description, pointsToRedeem, entityId } = reward._meta;
    return {
      ...result,
      image: image?.original.relativeUrl,
      description:
        addClassToEmTags(reward.highlight['_meta.description']?.join(' ')) ??
        description,
      id: entityId,
      cost: pointsToRedeem.toLocaleString(),
    };
  }
}

function getChallengeCardDetails(challenge: ChallengeSearchResult) {
  const {
    cardDetails,
    _meta: {
      createdByRef: { isManager, managerIds },
    },
    createdAt,
  } = challenge;

  return { ...cardDetails, isManager, managerIds, createdAt };
}

export function formatAssemblySearchResult(
  searchResult: PostCommentSearchResult | ResponseCommentSearchResult
): ReturnType<typeof getCommentCardDetails>;

export function formatAssemblySearchResult(
  searchResult:
    | PostGroupedCommentSearchResult
    | ResponseGroupedCommentSearchResult
): ReturnType<typeof getGroupedCommentCardDetails>;

export function formatAssemblySearchResult(
  searchResult:
    | ChallengeCommentSearchResult
    | ChallengeGroupedCommentSearchResult
): ReturnType<typeof getChallengeCommentCardDetails>;

export function formatAssemblySearchResult(
  searchResult: UrlSearchResult
): ReturnType<typeof getUrlCardDetails>;

export function formatAssemblySearchResult(
  searchResult: ExternalFileSearchResult
): ReturnType<typeof getExternalFileCardDetails>;

export function formatAssemblySearchResult(
  searchResult: FileSearchResult
): ReturnType<typeof getFileCardDetails>;

export function formatAssemblySearchResult(
  searchResult: FlowSearchResult | RecognitionSearchResult
): ReturnType<typeof getFlowCardDetails>;

export function formatAssemblySearchResult(
  searchResult: LinkSearchResult,
  workspaceSlugPath: string
): ReturnType<typeof getLinkCardDetails>;

export function formatAssemblySearchResult(
  searchResult: MemberSearchResult
): ReturnType<typeof getMemberCardDetails>;

export function formatAssemblySearchResult(
  searchResult: PostSearchResult
): ReturnType<typeof getPostCardDetails>;

export function formatAssemblySearchResult(
  searchResult: ResponseSearchResult
): ReturnType<typeof getResponseCardDetails>;

export function formatAssemblySearchResult(
  searchResult: TaskSearchResult
): ReturnType<typeof getTaskCardDetails>;

export function formatAssemblySearchResult(
  searchResult: CollectionSearchResult
): ReturnType<typeof getCollectionCardDetails>;

export function formatAssemblySearchResult(
  searchResult: RewardSearchResult,
  workplaceSlugPath?: string,
  exchangeRate?: number,
  exchangeRates?: Record<string, number>,
  denominationRange?: {
    min: number | null;
    max: number | null;
  }
): ReturnType<typeof getRewardCardDetails>;

export function formatAssemblySearchResult(
  searchResult: ChallengeSearchResult
): ReturnType<typeof getChallengeCardDetails>;

export function formatAssemblySearchResult(
  searchResult: SearchIndexResult,
  workplaceSlugPath?: string,
  exchangeRate?: number,
  exchangeRates?: Record<string, number>,
  denominationRange?: {
    min: number | null;
    max: number | null;
  }
) {
  switch (searchResult.type) {
    case 'url':
      return getUrlCardDetails(searchResult);
    case 'comment':
      if (checkIfCommentKindMatches(searchResult, 'challenge')) {
        return getChallengeCommentCardDetails(searchResult);
      }
      return getCommentCardDetails(searchResult);
    case 'groupedComment':
      if (checkIfCommentKindMatches(searchResult, 'challenge')) {
        return getChallengeCommentCardDetails(searchResult);
      }
      return getGroupedCommentCardDetails(searchResult);
    case 'file':
      if (searchResult.source !== 'assembly') {
        return getExternalFileCardDetails(searchResult);
      }
      return getFileCardDetails(searchResult);
    case 'flow':
    case 'recognition':
      return getFlowCardDetails(searchResult);
    case 'weblink':
      return getLinkCardDetails(searchResult);
    case 'member':
      return getMemberCardDetails(searchResult);
    case 'post':
      return getPostCardDetails(searchResult);
    case 'response':
      return getResponseCardDetails(searchResult);
    case 'task':
      return getTaskCardDetails(searchResult);
    case 'collection':
      return getCollectionCardDetails(searchResult);
    case 'tangoReward':
      return getRewardCardDetails(
        searchResult,
        workplaceSlugPath,
        exchangeRate,
        exchangeRates,
        denominationRange
      );
    case 'swag':
      return getRewardCardDetails(searchResult);
    case 'customReward':
      return getRewardCardDetails(searchResult);
    case 'challenge':
      return getChallengeCardDetails(searchResult);
    default:
      return;
  }
}
