import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import {
  COMMAND_PRIORITY_CRITICAL,
  COMMAND_PRIORITY_EDITOR,
  KEY_MODIFIER_COMMAND,
  SELECTION_CHANGE_COMMAND,
} from 'lexical';
import { useEffect, useMemo, useState } from 'react';

import { $formatHeading, $formatParagraph } from '../utils/formatNode';

export const FormatPlugin = () => {
  const [editor] = useLexicalComposerContext();
  const [activeEditor, setActiveEditor] = useState(editor);

  const formatParagraph = useMemo(
    () => $formatParagraph(activeEditor),
    [activeEditor]
  );

  const formatHeading = useMemo(
    () => $formatHeading(activeEditor),
    [activeEditor]
  );

  useEffect(() => {
    return editor.registerCommand(
      SELECTION_CHANGE_COMMAND,
      (_payload, newEditor) => {
        setActiveEditor(newEditor);
        return false;
      },
      COMMAND_PRIORITY_CRITICAL
    );
  }, [editor]);

  useEffect(() => {
    return activeEditor.registerCommand(
      KEY_MODIFIER_COMMAND,
      (payload) => {
        const event: KeyboardEvent = payload;
        const { code, metaKey, altKey } = event;

        if (metaKey && altKey) {
          if (code === 'Digit1') {
            formatHeading('h1');
          } else if (code === 'Digit2') {
            formatHeading('h2');
          } else if (code === 'Digit3') {
            formatHeading('h3');
          } else if (code === 'Digit4') {
            formatHeading('h4');
          } else if (code === 'Digit5') {
            formatHeading('h5');
          } else if (code === 'Digit6') {
            formatHeading('h6');
          } else if (code === 'Digit0') {
            formatParagraph();
          }
        }

        return false;
      },
      COMMAND_PRIORITY_EDITOR
    );
  }, [activeEditor, formatHeading, formatParagraph]);

  return null;
};
