import type { Except } from 'type-fest';

import type { BlockResponseDetails } from './blockTypes';
import type { TriggerType } from './flowBuilder';
import type { ReactionTypes } from './reactions';
import type { RepliesResponse } from './repliesTypes';

export enum BlockTypes {
  Dropdown = 'DROPDOWN',
  FileUpload = 'FILE_UPLOAD',
  OpenEnded = 'OPEN_ENDED',
  Scale = 'SCALE',
  MultiChoice = 'MULTI_CHOICE',
  Gif = 'GIF',
  PersonSelector = 'PERSON_SELECTOR',
  GivePointsStack = 'GIVE_POINTS_STACK',
}

export enum MemberState {
  New = 'NEW',
  Active = 'ACTIVE',
  Deactivated = 'DEACTIVATED',
  Pending = 'PENDING',
  Dummy = 'DUMMY',
}

export enum MemberRole {
  Admin = 'Admin',
  Employee = 'Employee',
  Owner = 'Owner',
  SuperAdmin = 'SuperAdmin',
}

export enum BlockResponseState {
  NotResponded = 'NOT_RESPONDED',
  Responded = 'RESPONDED',
  Skipped = 'SKIPPED',
}

export enum VisibilityTypes {
  Everyone = 'everyone',
  Custom = 'custom',
  OnlyOwners = 'onlyOwners',
  OnlyParticipants = 'onlyParticipants',
  Private = 'private',
}

export enum FlowsActivityStates {
  Active = 'ACTIVE',
  Muted = 'MUTED',
}

export enum CreationSource {
  Member = 'MEMBER',
  Preload = 'PRELOAD',
  OneClickFlow = 'ONE_CLICK',
}

export enum FlowAccessRoles {
  Owner = 'OWNER',
  Viewer = 'VIEWER',
  Participant = 'PARTICIPANT',
  Collaborator = 'COLLABORATOR',
}

export type FlowKind = TriggerType | 'ONCE' | 'RECURRING';

export type VisibilityType =
  | 'everyone'
  | 'onlyParticipants'
  | 'custom'
  | 'private'
  | 'onlyOwners';

export type FlowCollaborator = {
  memberID: string;
  name: string;
  image: string;
  state: MemberState;
  email: string;
};

export enum AnonymityStates {
  Enabled = 'ENABLED',
  Disabled = 'DISABLED',
  Optional = 'OPTIONAL',
}

export enum LinkAccessType {
  Internal = 'INTERNAL',
  External = 'EXTERNAL',
  Restricted = 'RESTRICTED',
}

export enum AccessRole {
  Viewer = 'VIEWER',
  Participant = 'PARTICIPANT',
}

export type MemberDetails = {
  name: string;
  firstName: string;
  lastName: string;
  username: string;
  email?: string;
  memberID: string;
  image?: string;
  pointsGiven: number;
  totalPointsGiven: number;
  memberState: MemberState;
  role: MemberRole[];
  pronouns?: string;
  status?: string;
  jobTitle?: string;
  department?: string;
  location?: string;
  workLocation?: string;
};

export type ReactionDetails = {
  name: string;
  value: string;
  type: ReactionTypes;
  displayName: string;
  members: MemberDetails[];
};

export type FlowData = {
  name: string;
  flowId: string;
  version?: number;
  kind: string;
  icon: {
    kind: string;
    value: string;
  };
};

export type AnnouncementFeedDetails = {
  title: string;
  color: string;
  viewsCount: number;
  description: string;
  showInsights: boolean;
  announcementId: string;
  notificationSettings: {
    email: boolean;
    push: boolean;
  };
};

export type BaseFlowPostResponse = {
  responseId: string;
  instanceId: string;
  responses: BlockResponseDetails[];
  flow: FlowData;
  reactions: ReactionDetails[];
  visibility: VisibilityTypes;
  isPrivate: boolean;
  commentsCount: number;
  createdAt: string;
  canDelete: boolean;
  canEdit: boolean;
  kind: string;
  isMuted?: boolean;
  hideReactions?: boolean;
  hideReplies?: boolean;
  replySummary?: RepliesResponse;
  showTurnOffSettings?: boolean;
  hasReplyDraft?: boolean;
  isBoostAllowed: boolean;
  activeAnnouncement?: AnnouncementFeedDetails;
};

type NonAnonymousFlowPostResponse = BaseFlowPostResponse & {
  respondent: MemberDetails;
};

type AnonymousFlowPostResponse = Except<BaseFlowPostResponse, 'instanceId'> & {
  respondent: null;
};

export type FlowPostResponse =
  | NonAnonymousFlowPostResponse
  | AnonymousFlowPostResponse;

export type FileDownloadRequest = {
  flowId: string;
  blockId: string;
  fileName: string;
  instanceId: string;
  responseId: string;
};

export type DownloadSecureFileResponse = {
  url: string;
  expiresOn: Date;
};

export type ChannelInfo = {
  id: string;
  name: string;
  isPrivate: boolean;
};

export type Connection = {
  provider: string;
  connectionId: string;
  connectionStatus: 'CONNECTED' | 'ARCHIVED' | 'CONNECTION_BROKEN';
  metaData: {
    channelInfo: ChannelInfo[];
  };
  workspaceName: string;
  connectionMessage: string;
};

export type Integrations = {
  viewingCriteria: Connection[];
  responseNotification: Connection[];
  participationCriteria: Connection[];
  participationNotification: Connection[];
};

export type EmoticonResponse = {
  kind: string;
  value: string;
};

type ActiveOccurrence = {
  occurrenceId: string;
  startTime: string;
  endTime: string;
  hasResponded: boolean;
  timeZone?: string;
  participantsNotRespondedCount: number;
};

type LastOccurrence = {
  occurrenceId: string;
  startTime: string;
  endTime: string;
};

export type Occurrence = {
  activeOccurrence?: ActiveOccurrence;
  lastOccurrence?: LastOccurrence;
};

export type CriteriaCondition = 'and' | 'or';
export type CriteriaField =
  | 'slack'
  | 'everyone'
  | 'department'
  | 'email'
  | 'member'
  | 'manager'
  | 'directReport'
  | 'memberIsManager'
  | 'memberIsNotManager'
  | 'workLocation'
  | 'homeLocation'
  | 'jobTitle';
export type CriteriaOperator = 'is' | 'isNot' | 'of';

export type Rule =
  | {
      value: string[];
      field: CriteriaField;
      operator: CriteriaOperator;
    }
  | {
      value: [];
      field: 'everyone';
      operator: 'is';
    };

export type GroupRule = {
  rules: Rule[];
  condition: CriteriaCondition;
};

export type CustomCriteriaResponse = {
  rules: GroupRule[];
  condition: CriteriaCondition;
};

export type CriteriaResponse = {
  criteria: {
    onlyOwnersAndCollaborators?: boolean;
    everyone?: boolean;
    onlyParticipants?: boolean;
    custom?: CustomCriteriaResponse;
  };
  isNewMembersAdded?: boolean;
};

export type FlowStatusToDisplay = 'archive' | 'unarchive';

export type FlowFeedParams = {
  filter: {
    blockIds?: string[];
    endDate?: string;
    isAnonymous?: boolean;
    respondedBy?: string[];
    startDate?: string;
  };
  limit: number;
  sortBy?: 'asc' | 'desc';
};

export type FlowTemplatePayload = {
  color: string;
  emoticon: string;
  templateId: string;
  title: string;
  filterApplied?: string;
  searchQuery?: string;
};
