export enum FileType {
  Document = 'document',
  Image = 'image',
  Presentation = 'presentation',
  Pdf = 'pdf',
  Spreadsheet = 'spreadsheet',
  Audio = 'audio',
  Video = 'video',
  Zip = 'zip',
  GenericFile = 'genericFile',
}

export type MediaType =
  | 'documents'
  | 'images'
  | 'audio'
  | 'video'
  | 'imagesWithoutGif';

export const supportedUploadFileTypes = [
  'jpg',
  'jpeg',
  'png',
  'svg',
  'gif',
  'pdf',
  'doc',
  'docx',
  'ppt',
  'pptx',
  'pps',
  'ppsx',
  'xls',
  'xlsx',
  'csv',
  'txt',
  'odt',
  'zip',
  'mp3',
  'm4a',
  'ogg',
  'wav',
  'mp4',
  'm4v',
  'mov',
  'wmv',
  'avi',
  'mpg',
  'ogv',
  '3gp',
  '3g2',
] as const;

// The mappings are copied from the web app.
// https://github.com/joinassembly/assembly_frontend/blob/332531ba9e3330252d95e9b75ec51f0e101cd3f8/src/atomic/molecules/FileCard/utils.ts#L29-L115
export function getFileTypeForFile(file: string) {
  switch (file) {
    case 'doc':
    case 'docx':
      return FileType.Document;

    case 'bmp':
    case 'gif':
    case 'jpeg':
    case 'jpg':
    case 'png':
    case 'svg':
      return FileType.Image;

    case 'pps':
    case 'ppsx':
    case 'ppt':
    case 'pptx':
      return FileType.Presentation;

    case 'pdf':
      return FileType.Pdf;

    case 'csv':
    case 'xls':
    case 'xlsx':
      return FileType.Spreadsheet;

    case 'ogg':
    case 'm4a':
    case 'mp3':
    case 'wav':
      return FileType.Audio;

    case '3d2':
    case '3gp':
    case 'avi':
    case 'mov':
    case 'mp4':
    case 'mpg':
    case 'ogv':
      return FileType.Video;

    case 'zip':
      return FileType.Zip;

    default:
      return FileType.GenericFile;
  }
}

export function getFileColorForFileCard(file: string) {
  switch (file) {
    case 'doc':
    case 'docx':
      return 'blue-7';

    case 'bmp':
    case 'gif':
    case 'jpeg':
    case 'jpg':
    case 'png':
    case 'svg':
      return 'primary-8';

    case 'pps':
    case 'ppsx':
    case 'ppt':
    case 'pptx':
      return 'brand-6';

    case 'pdf':
      return 'error-6';

    case 'csv':
    case 'xls':
    case 'xlsx':
      return 'success-7';

    case 'ogg':
    case 'm4a':
    case 'mp3':
    case 'wav':
      return 'warning-6';

    case '3d2':
    case '3gp':
    case 'avi':
    case 'mov':
    case 'mp4':
    case 'mpg':
    case 'ogv':
      return 'error-7';

    case 'zip':
      return 'gray-8';

    default:
      return 'gray-6';
  }
}

export function formatFileSize(bytes: number) {
  if (bytes === 0) {
    return '0 Bytes';
  }

  const k = 1024;
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
  const i = Math.floor(Math.log(bytes) / Math.log(k));
  return `${parseFloat((bytes / Math.pow(k, i)).toFixed(2))} ${sizes[i]}`;
}

export function truncateFilename(filename: string, maxLength = 20) {
  if (filename.length <= maxLength) {
    return filename;
  }

  const extension = filename.split('.').pop() ?? '';
  const nameWithoutExtension = filename.substring(0, filename.lastIndexOf('.'));
  const truncatedName = nameWithoutExtension.substring(
    0,
    maxLength - 3 - extension.length
  );
  return `${truncatedName}...${extension}`;
}

export function getFileExtensionsForMediaTypes(types?: MediaType[]) {
  const extensionMap: Record<MediaType, string[]> = {
    audio: ['.mp3', '.wav', '.ogg', '.m4a', '.aac'],
    images: ['.jpg', '.jpeg', '.png', '.gif', '.bmp'],
    imagesWithoutGif: ['.jpg', '.jpeg', '.png', '.bmp'],
    video: ['.mp4', '.avi', '.mov', '.wmv', '.flv', '.webm'],
    documents: ['.pdf', '.doc', '.docx', '.txt', '.rtf', '.odt'],
  };

  if (!types || types.length === 0) {
    return undefined;
  }

  return types.flatMap((category) => extensionMap[category]);
}
