import type { ChallengeState } from '@assembly-web/services';
import parse from 'html-react-parser';
import { type ReactNode, useEffect, useRef, useState } from 'react';
import { twMerge } from 'tailwind-merge';

import { TextStyle } from '../../../../DesignSystem/Feedback/TextStyle';
import { Tooltip } from '../../../../DesignSystem/Feedback/Tooltip';
import { useDeviceInfo } from '../../../hooks/useDeviceInfo';
import { useChallengeStates } from '../useChallenge';
import { ChallengeCardButtons } from './ChallengeCardButton';

type ChallengeCardHeaderProps = {
  title: string;
  description: string;
  challengeState: ChallengeState;
  showClaimBadge?: boolean;
  claimButtonText: string;
  toolbar: ReactNode;
  isAllApproved?: boolean;
  claimCount?: number;
  isClaimButtonDisabled?: boolean;
  showClaimsButton?: boolean;
  showViewClaimsButton: boolean;
  onClaim?: () => void;
  isClaimButtonLoading?: boolean;
  isNotParticipant: boolean;
  variant?: 'preview' | 'default' | 'drawer' | 'carousal';
  onViewClaims?: () => void;
};

export const ChallengeCardHeader = (props: ChallengeCardHeaderProps) => {
  const {
    title,
    showClaimBadge,
    claimButtonText,
    challengeState,
    toolbar,
    isAllApproved,
    claimCount,
    isClaimButtonDisabled,
    showClaimsButton,
    showViewClaimsButton,
    onClaim,
    description,
    isClaimButtonLoading,
    variant,
    onViewClaims,
    isNotParticipant,
  } = props;
  const isMobileView = useDeviceInfo().deviceType === 'mobile';
  const [titleOverflow, setTitleOverflow] = useState(false);
  const titleRef = useRef<HTMLParagraphElement>(null);

  const { isChallengeArchived } = useChallengeStates({ challengeState });

  useEffect(() => {
    const onResize = () => {
      const el = titleRef.current;
      if (el) {
        setTitleOverflow(el.scrollWidth > el.clientWidth);
      }
    };

    window.addEventListener('resize', onResize);
    onResize();

    return () => window.removeEventListener('resize', onResize);
  }, [title.length]);

  return (
    <div
      className={twMerge(
        'flex flex-col',
        variant !== 'drawer' &&
          '@md/challenge-card:min-h-[32px] @md/challenge-card:flex-row @md/challenge-card:items-center'
      )}
    >
      {variant === 'drawer' && (
        <>
          <Tooltip
            align={'start'}
            tooltipText={Boolean(titleOverflow) && title}
          >
            <div className="w-full text-left">
              <TextStyle
                ref={titleRef}
                variant="base-medium"
                className={twMerge(
                  'truncate',
                  isChallengeArchived && 'line-through'
                )}
                data-testid="challenge-card-title"
              >
                {title}
              </TextStyle>
            </div>
          </Tooltip>
          {Boolean(description) && (
            <TextStyle
              variant="sm-regular"
              className="line-clamp-2 break-words py-1 text-gray-8"
            >
              {parse(description)}
            </TextStyle>
          )}
          {/* // TODO: check if not variant-carousal for below  */}
          {Boolean(!isMobileView) && (
            <div className="flex flex-grow items-center justify-end gap-2">
              <ChallengeCardButtons
                variant={variant}
                isClaimButtonDisabled={isClaimButtonDisabled}
                showViewClaimsButton={showViewClaimsButton}
                showClaimButton={showClaimsButton}
                claimButtonText={claimButtonText}
                onClaim={onClaim}
                onViewClaims={onViewClaims}
                isClaimButtonLoading={isClaimButtonLoading}
                showClaimBadge={showClaimBadge}
                isAllApproved={isAllApproved}
                claimCount={claimCount}
                isNotParticipant={isNotParticipant}
                layout="mobile"
              />
            </div>
          )}
        </>
      )}
      {variant !== 'drawer' && (
        <>
          <Tooltip
            align={'start'}
            tooltipText={Boolean(titleOverflow) && title}
          >
            <div className="@md/challenge-card:flex-shrink-1 mb-1 w-full p-1 text-center @md/challenge-card:m-0 @md/challenge-card:w-8 @md/challenge-card:basis-full @md/challenge-card:p-0 @md/challenge-card:text-left">
              <TextStyle
                variant="base-medium"
                className={twMerge(
                  'truncate',
                  isChallengeArchived && 'line-through'
                )}
                data-testid="challenge-card-title"
                ref={titleRef}
              >
                {title}
              </TextStyle>
            </div>
          </Tooltip>
          {Boolean(!isMobileView && variant !== 'carousal') && (
            <div className="flex flex-grow items-center justify-end gap-2">
              {toolbar}
              <ChallengeCardButtons
                isClaimButtonDisabled={isClaimButtonDisabled}
                showViewClaimsButton={showViewClaimsButton}
                showClaimButton={showClaimsButton}
                claimButtonText={claimButtonText}
                onClaim={onClaim}
                onViewClaims={onViewClaims}
                isClaimButtonLoading={isClaimButtonLoading}
                showClaimBadge={showClaimBadge}
                isAllApproved={isAllApproved}
                claimCount={claimCount}
                isNotParticipant={isNotParticipant}
                layout="header"
              />
            </div>
          )}
        </>
      )}
    </div>
  );
};
