import { InformationCircleIcon } from '@heroicons/react/24/outline';
import type { ReactNode } from 'react';
import { twMerge } from 'tailwind-merge';

import { TextStyle } from '../../../DesignSystem/Feedback/TextStyle';
import { Tooltip } from '../../../DesignSystem/Feedback/Tooltip';

type StatBoxProps = {
  title: string;
  value: number;
  tooltipText?: ReactNode;
  className?: string;
  isLoading: boolean;
};

export function StatBox({
  title,
  value,
  isLoading,
  className,
  tooltipText,
}: StatBoxProps) {
  return (
    <div
      className={twMerge(
        'inline-flex flex-1 flex-col items-start justify-start rounded-lg border border-gray-5 px-4 py-3',
        className
      )}
    >
      <div className="flex items-center gap-1">
        <TextStyle variant="sm-regular" className="text-gray-9">
          {title}
        </TextStyle>
        {Boolean(tooltipText) && (
          <Tooltip
            side="bottom"
            tooltipText={tooltipText}
            contentClassName="px-3 z-20 w-[210px]"
          >
            <InformationCircleIcon className="h-4 w-4 text-gray-9" />
          </Tooltip>
        )}
      </div>
      {isLoading ? (
        <div role="status" className="flex animate-pulse">
          <div className="mb-4 h-[38px] w-[44px] rounded-[8px] bg-gray-5"></div>
        </div>
      ) : (
        <TextStyle variant="2xl-medium" className="text-gray-9">
          {value}
        </TextStyle>
      )}
    </div>
  );
}
