import { useState } from 'react';
import { twMerge } from 'tailwind-merge';

export type StarRatingProps = {
  totalStars: number;
  defaultStar?: number;
  setSelectedStar: (val: number) => void;
  selectedStar: number;
};

function Star({ filled }: { filled: boolean }) {
  return (
    <svg
      className={twMerge(
        'h-6 w-6 stroke-primary-6',
        filled && 'fill-primary-6'
      )}
      viewBox="0 0 24 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g>
        <path d="M21.2836 8.77559L15.3328 7.91075L12.6726 2.51778C12.6 2.37013 12.4805 2.25059 12.3328 2.17794C11.9625 1.99513 11.5125 2.14747 11.3273 2.51778L8.66717 7.91075L2.71639 8.77559C2.55233 8.79903 2.40233 8.87638 2.28748 8.99356C2.14864 9.13627 2.07214 9.32825 2.07477 9.52733C2.07741 9.72642 2.15897 9.91631 2.30155 10.0553L6.60701 14.2529L5.58983 20.1803C5.56597 20.3182 5.58123 20.46 5.63387 20.5896C5.68651 20.7193 5.77442 20.8316 5.88764 20.9138C6.00086 20.996 6.13486 21.0449 6.27444 21.0549C6.41401 21.0648 6.55358 21.0355 6.67733 20.9701L12 18.1717L17.3226 20.9701C17.468 21.0475 17.6367 21.0732 17.7984 21.0451C18.2062 20.9748 18.4805 20.5881 18.4101 20.1803L17.393 14.2529L21.6984 10.0553C21.8156 9.94044 21.893 9.79044 21.9164 9.62638C21.9797 9.21622 21.6937 8.83653 21.2836 8.77559Z" />
      </g>
    </svg>
  );
}

export function StarRating({
  totalStars,
  selectedStar,
  setSelectedStar,
}: StarRatingProps) {
  const [hoveredStar, setHoveredStar] = useState<number>(0);

  const handleStarHover = (starIndex: number) => {
    setHoveredStar(starIndex + 1);
  };

  const handleStarLeave = () => {
    setHoveredStar(0);
  };

  const handleStarClick = (starIndex: number) => {
    setSelectedStar(starIndex + 1);
  };

  return (
    <div className="flex gap-1">
      {[...Array(totalStars)].map((_, index) => (
        <span
          className="cursor-pointer"
          key={index}
          role="presentation"
          onMouseEnter={() => handleStarHover(index)}
          onMouseLeave={handleStarLeave}
          onClick={() => handleStarClick(index)}
        >
          <Star filled={index < hoveredStar || index < selectedStar} />
        </span>
      ))}
    </div>
  );
}
