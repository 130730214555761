import { ChevronDownIcon } from '@heroicons/react/24/outline';
import {
  Content,
  DropdownMenu,
  Portal,
  Root,
  Trigger,
} from '@radix-ui/react-dropdown-menu';
import dayjs from 'dayjs';
import { useState } from 'react';
import { useIntl } from 'react-intl';
import { twJoin } from 'tailwind-merge';

import { useDropdownHandler } from '../../UI/hooks/useDropdownState';
import type { DateRangePickerProps } from '../DateRangePicker/DateRangePicker';
import { DateRangePicker, options } from '../DateRangePicker/DateRangePicker';
import { TextStyle } from '../Feedback/TextStyle';

export type DateRangeDropdownProps = DateRangePickerProps & {
  label: string;
};

export function DateRangeDropdown({
  label,
  onChange,
  onClearAllClick,
  ...props
}: DateRangeDropdownProps) {
  const { formatMessage } = useIntl();
  const { getContentProps, getTriggerProps } = useDropdownHandler();
  const [isOpen, setIsOpen] = useState(false);

  const selectedItemLabel = options.find(
    (option) => option.id === props.selectedOption?.id
  )?.label;

  return (
    <Root>
      <DropdownMenu modal={false} open={isOpen}>
        <Trigger
          className="group focus-visible:rounded-lg"
          {...getTriggerProps({
            onClick: () => {
              setIsOpen((prev) => !prev);
            },
          })}
        >
          <div
            className={twJoin(
              'pointer-events-none flex items-center gap-2 rounded-lg border border-gray-5 bg-gray-1 px-2 py-1 align-middle hover:bg-gray-3 focus:bg-gray-3',
              props.selectedOption?.id &&
                'bg-gray-3 hover:bg-gray-4 focus:bg-gray-4'
            )}
          >
            <div className="flex items-center truncate">
              <TextStyle
                as="p"
                className="truncate text-gray-9"
                variant={selectedItemLabel ? 'sm-medium' : 'sm-regular'}
              >
                {label}
                {selectedItemLabel
                  ? `: ${
                      props.selectedOption?.id === 'custom'
                        ? props.selectedOption.value?.start &&
                          `${dayjs(props.selectedOption.value.start).format(
                            'MM-DD-YYYY'
                          )} - ${dayjs(props.selectedOption.value.end).format(
                            'MM-DD-YYYY'
                          )}`
                        : formatMessage(selectedItemLabel)
                    }`
                  : ''}
              </TextStyle>
            </div>
            <ChevronDownIcon
              color="gray-9"
              className="h-4 w-4 text-gray-9 group-aria-expanded:rotate-180"
            />
          </div>
        </Trigger>
        <Portal>
          <Content
            align="start"
            sideOffset={5}
            alignOffset={-3}
            className="rounded-lg border border-gray-5 bg-gray-1 shadow-md-down"
            {...getContentProps({
              onInteractOutside: () => {
                setIsOpen(false);
              },
            })}
          >
            <DateRangePicker
              {...props}
              onChange={(e) => {
                onChange?.(e);
                setIsOpen(false);
              }}
              onClearAllClick={onClearAllClick}
            />
          </Content>
        </Portal>
      </DropdownMenu>
    </Root>
  );
}
