export function RepliesLoader() {
  return (
    <div className="mt-4 flex w-full animate-pulse items-start gap-2">
      <div className="my-2 h-8 w-8 flex-shrink-0 rounded-full bg-gray-5"></div>
      <div className="relative flex w-full max-w-[422px] flex-col items-start gap-2 rounded-lg border border-gray-5 bg-gray-2 px-4 pb-4 pt-3">
        <div className="h-[16px] w-[120px] rounded-full bg-gray-5"></div>
        <div className="h-[16px] w-full rounded-full bg-gray-5"></div>
        <div className="h-[16px] w-[162px] rounded-full bg-gray-5"></div>
      </div>
    </div>
  );
}
