import type { ChallengeState, ClaimState } from '../../types/challenges';
import { type MemberDetails, MemberState } from '../../types/flowTypes';

export const dummyMemberDetails: MemberDetails = {
  name: '',
  firstName: '',
  lastName: '',
  username: '',
  memberID: '',
  pointsGiven: 0,
  totalPointsGiven: 0,
  memberState: MemberState.Active,
  role: [],
};

export const initProofClaimData = {
  title: '',
  id: '',
  participantName: '',
  points: 0,
  icon: <div></div>,
  currencyName: '',
  currencyPluralName: '',
  claimedAt: '',
  participant: dummyMemberDetails,
  handleApprove: () => {},
  handleReject: () => {},
  challengeId: '',
  proof: {
    text: {
      plainText: '',
      messageTokens: '',
      messageHtml: '',
    },
    files: [],
  },
  challengeState: 'ACTIVE' as ChallengeState,
  onHeaderClick: () => {},
  promptTexts: undefined,
  claimStatus: 'PENDING' as ClaimState,
  currentUserId: '',
};
