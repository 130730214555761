import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import { useEffect, useState } from 'react';
import { defineMessages, useIntl } from 'react-intl';

import {
  Avatar,
  AvatarSize,
} from '../../../../../DesignSystem/Feedback/Avatar';
import { TextStyle } from '../../../../../DesignSystem/Feedback/TextStyle';
import {
  DropdownMenu,
  type DropdownOption,
} from '../../../../../DesignSystem/Inputs/DropdownMenu';
import { AnonymousIcon } from '../../../../../Icons';
import { REPLY_TYPE_CHANGE_COMMAND } from '../../base/plugins/MentionsPlugin';
import { $getMentionNodes } from '../../base/utils';

type AnonymousHeaderPluginProps = {
  currentUserDetails: {
    id: string;
    name: string;
    image?: string;
  };
  isVisible: boolean;
  replyType: 'anonymous' | 'user';
  disableAnonymousReplyOption?: boolean;
  onReplyTypeChange?: (option: DropdownOption) => void;
};

const messages = defineMessages({
  replyingLabel: {
    defaultMessage: 'You are replying',
    id: 'QbN5o6',
  },
  anonymouslyLabel: {
    defaultMessage: 'anonymously',
    id: 'tThkSn',
  },
  asNameLabel: {
    defaultMessage: 'as {name} (yourself)',
    id: 'A+qiZU',
  },
  cannotGiveBoostAnonymously: {
    defaultMessage: 'You cannot give a boost anonymously',
    id: 'eWcjxh',
  },
});

function AnonymousHeaderPluginImpl({
  replyType,
  onReplyTypeChange,
  currentUserDetails,
  disableAnonymousReplyOption,
}: AnonymousHeaderPluginProps) {
  const { formatMessage } = useIntl();

  const [isAnonymousChangeDisabled, setIsAnonymousChangeDisabled] =
    useState(false);
  const [editor] = useLexicalComposerContext();

  const dropDownOptions = [
    {
      value: 'anonymous',
      label: formatMessage(messages.anonymouslyLabel),
    },
    {
      value: 'user',
      label: formatMessage(messages.asNameLabel, {
        name: currentUserDetails.name,
      }),
    },
  ];

  useEffect(() => {
    return editor.registerUpdateListener(({ editorState }) => {
      editorState.read(() => {
        const mentionNodes = $getMentionNodes();
        const mentionedUsers = mentionNodes.map(
          (node) => node.exportJSON().mentionedMember
        );
        if (mentionedUsers.length > 0 && mentionedUsers[0].points) {
          setIsAnonymousChangeDisabled(true);
        } else {
          setIsAnonymousChangeDisabled(false);
        }
      });
    });
  }, [editor]);

  useEffect(() => {
    editor.dispatchCommand(REPLY_TYPE_CHANGE_COMMAND, { replyType });
  }, [editor, replyType]);

  return (
    <div className="flex h-[40px] items-center gap-1 rounded-t-lg border-b border-gray-5 bg-gray-3 px-2">
      {replyType === 'anonymous' ? (
        <AnonymousIcon />
      ) : (
        <Avatar
          size={AvatarSize.ExtraSmall}
          name={currentUserDetails.name}
          memberID={currentUserDetails.id}
          image={currentUserDetails.image}
        />
      )}
      <TextStyle className="text-gray-9" variant="sm-regular">
        {formatMessage(messages.replyingLabel)}
      </TextStyle>
      {disableAnonymousReplyOption ? (
        <TextStyle className="text-gray-9" variant="sm-medium">
          {formatMessage(messages.asNameLabel, {
            name: currentUserDetails.name,
          })}
        </TextStyle>
      ) : (
        <DropdownMenu
          variant="default"
          value={replyType}
          options={dropDownOptions}
          className="-ml-[8px] w-auto bg-transparent hover:bg-transparent"
          contentClassName="relative z-50"
          triggerClassName="hover:bg-transparent"
          onSelected={(value) => {
            onReplyTypeChange?.(value);
          }}
          labelRenderer={(label) => (
            <TextStyle className="text-gray-9" variant="sm-medium">
              {label}
            </TextStyle>
          )}
          disabled={isAnonymousChangeDisabled}
        />
      )}
    </div>
  );
}

export function AnonymousHeaderPlugin(props: AnonymousHeaderPluginProps) {
  if (!props.isVisible) {
    return null;
  }

  return <AnonymousHeaderPluginImpl {...props} />;
}
