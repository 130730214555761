import {
  formatToLocalDateTime,
  type MemberDetails,
  MemberState,
  type ProofModalHeaderAction,
} from '@assembly-web/services';
import dayjs from 'dayjs';
import { defineMessages, useIntl } from 'react-intl';
import { twJoin, twMerge } from 'tailwind-merge';

import { Avatar, AvatarSize } from '../../../../DesignSystem/Feedback/Avatar';
import { OverflowText } from '../../../../DesignSystem/Feedback/OverflowText';
import { Tooltip } from '../../../../DesignSystem/Feedback/Tooltip';
import { AuthorButton } from '../../../Shared/AuthorButton';

const messages = defineMessages({
  claimed: {
    defaultMessage: 'claimed',
    id: 'joOlUw',
  },
  ago: {
    defaultMessage: '{timeCreatedAt}',
    id: 'NhSH4V',
  },
  anonymous: {
    defaultMessage: 'Anonymous',
    id: 'LXxsbk',
  },
  deactivatedUser: {
    defaultMessage: '(deactivated)',
    id: 'osOx6w',
  },
});

export type ProofModalHeaderProps = {
  participant: MemberDetails;
  challengeName: string;
  claimedAt: string;
  challengeId: string;
  contentClassName?: string;
  onHeaderClick?: (action: ProofModalHeaderAction, id: string) => void;
};

export function ProofModalHeader({
  participant,
  contentClassName,
  challengeName,
  claimedAt,
  challengeId,
  onHeaderClick,
}: ProofModalHeaderProps) {
  const { formatMessage } = useIntl();

  return (
    <header className="flex justify-between">
      <div className="flex">
        <Avatar
          size={AvatarSize.Large}
          image={participant.image}
          name={participant.firstName}
          memberID={participant.memberID}
          className={twJoin(
            participant.memberState === MemberState.Deactivated && 'grayscale'
          )}
        />
        <div className="flex flex-col justify-between pl-2">
          <div className="grid grid-cols-[auto_auto_1fr] align-baseline text-sm font-normal">
            <AuthorButton
              respondent={participant}
              onClick={() => {
                if (participant.memberID !== '') {
                  onHeaderClick?.('PersonClicked', participant.memberID);
                }
              }}
              className={twMerge(
                'w-max max-w-[160px] truncate',
                contentClassName
              )}
            />
            {formatMessage(messages.claimed)}
            <button
              onClick={() => {
                if (challengeId !== '') {
                  onHeaderClick?.('ChallengeClicked', challengeId);
                }
              }}
              className="mx-1 flex text-primary-6 hover:text-primary-5"
            >
              <OverflowText
                variant="sm-medium"
                tooltipText={challengeName}
                className="!block w-max max-w-[160px] truncate"
                tooltipClassname="h-auto break-all overflow-hidden"
              >
                {challengeName}
              </OverflowText>
            </button>
          </div>
          <div className="flex items-center gap-1 align-middle text-sm text-gray-8">
            <Tooltip tooltipText={formatToLocalDateTime(claimedAt)}>
              <span className="cursor-pointer">
                {formatMessage(messages.ago, {
                  timeCreatedAt: dayjs(claimedAt).fromNow(),
                })}
              </span>
            </Tooltip>
          </div>
        </div>
      </div>
    </header>
  );
}
