export enum PermissionType {
  Collaborator = 'collaborator',
  Viewer = 'viewer',
  Excluded = 'excluded',
  RemoveRule = 'removeRule',
  Owner = 'owner',
  Participant = 'participant',
  Approver = 'approver',
  ParticipantApprover = 'participant-approver',
  TransferOwnership = 'transferOwnership',
  Custom = 'custom',
}
