import type { Claim } from '@assembly-web/services';
import { type ReactNode, useCallback } from 'react';
import { defineMessages, useIntl } from 'react-intl';

import { TextStyle } from '../../../../DesignSystem/Feedback/TextStyle';

export type ClaimInfoBannerProps = {
  icon: ReactNode;
  points: number;
  totalEarnedPoints?: number;
  totalCount?: number;
  isAllApproved?: boolean;
  approvedClaims?: Claim[];
  text?: string | ReactNode;
  textClassName?: string;
};

const messages = defineMessages({
  default: {
    defaultMessage: 'Earn {icon} {points}',
    id: 'BP0crw',
  },
  claimedButNotAll: {
    defaultMessage:
      'Earn {icon} {points}. You have claimed {claimed}/{total} times.',
    id: 'KvZzjj',
  },
  claimedAll: {
    defaultMessage:
      'You earned a total of {icon} {points}! \u2022 You have claimed {claimed}/{total} times',
    id: '4keDZr',
  },
});

// points with no challenge successfully claimed - Earn 500
// points with a challenge successfully claimed with remaining individual claims - Earn 500.You have claimed 1/3 times.
// points with a challenge successfully claimed with no remaining individual claims - You earned a total of 1,500. You have claimed 3/3 times
export const ClaimInfoBanner = (props: ClaimInfoBannerProps) => {
  const { formatMessage } = useIntl();
  const {
    icon,
    points,
    isAllApproved,
    approvedClaims,
    totalCount = 0,
    totalEarnedPoints,
    text,
    textClassName,
  } = props;

  const getBannerMessage = useCallback(() => {
    const claimCount = approvedClaims?.length ?? 0;
    const parsedIcon = icon;

    if (!approvedClaims?.length) {
      return formatMessage(messages.default, {
        icon: parsedIcon,
        points,
      });
    }
    if (
      (claimCount < totalCount && !isAllApproved) ||
      (claimCount >= totalCount && !isAllApproved)
    ) {
      return formatMessage(messages.claimedButNotAll, {
        icon: parsedIcon,
        points,
        claimed: claimCount,
        total: totalCount,
      });
    }
    if (totalCount === claimCount && isAllApproved) {
      return formatMessage(messages.claimedAll, {
        icon: parsedIcon,
        points: totalEarnedPoints,
        claimed: claimCount,
        total: totalCount,
      });
    }
    return '';
  }, [
    approvedClaims?.length,
    formatMessage,
    icon,
    isAllApproved,
    points,
    totalCount,
    totalEarnedPoints,
  ]);

  return (
    <div className="flex items-center justify-center rounded-lg bg-upgrade-2 p-1">
      <TextStyle variant="sm-medium" className={textClassName}>
        {text ? text : getBannerMessage()}
      </TextStyle>
    </div>
  );
};
