import {
  ExclamationTriangleIcon,
  InformationCircleIcon,
  XMarkIcon,
} from '@heroicons/react/24/outline';
import { ExclamationCircleIcon } from '@heroicons/react/24/solid';
import type { MouseEventHandler, ReactNode } from 'react';
import { twMerge } from 'tailwind-merge';
import type { RequireAllOrNone } from 'type-fest';

import { TextStyle } from './TextStyle';
import { Tooltip } from './Tooltip';

export const BannerVariations = [
  'error',
  'warn',
  'success',
  'critical',
  'info',
  'alert',
] as const;

export type BannerProps = {
  status: (typeof BannerVariations)[number];
  title?: string;
  children: ReactNode;
  className?: string;
} & RequireAllOrNone<
  {
    onDismiss: MouseEventHandler<HTMLButtonElement>;
    dismissText: string;
  },
  'dismissText' | 'onDismiss'
>;

export function Banner({
  status,
  title,
  children,
  className,
  dismissText,
  onDismiss,
}: BannerProps) {
  const isError = status === 'error';
  const isWarning = status === 'warn';
  const isInfo = status === 'info';
  const isAlert = status === 'alert';

  return (
    <section
      className={twMerge(
        'flex items-center space-x-2 rounded-sm p-2',
        isError && 'bg-error-2',
        isInfo && 'bg-primary-2',
        isAlert && 'bg-warning-2',
        className
      )}
    >
      {Boolean(isError) && (
        <ExclamationTriangleIcon
          data-testid="bannerTextError"
          className="h-5 w-5 shrink-0 text-xs text-error-6"
        />
      )}

      {Boolean(isWarning) && (
        <ExclamationCircleIcon
          data-testid="bannerTextWarning"
          className="h-5 w-5 shrink-0 text-warning-6"
        />
      )}

      {Boolean(isInfo) && (
        <InformationCircleIcon
          data-testid="bannerTextInfo"
          className="h-5 w-5 shrink-0 text-xs text-primary-6"
        />
      )}
      {Boolean(isAlert) && (
        <svg
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          data-testid="bannerTextAlert"
        >
          <path
            d="M7.9998 2.01953C7.62434 2.01953 7.24904 2.19449 7.03886 2.54427L1.17428 12.3008C0.742121 13.0195 1.29504 14 2.13391 14H13.8644C14.703 14 15.2575 13.0195 14.8253 12.3008L8.96074 2.54427C8.75055 2.19449 8.37526 2.01953 7.9998 2.01953ZM7.9998 3.53255L13.4907 12.6667H2.50891L7.9998 3.53255ZM7.9998 6C7.63161 6 7.33313 6.29848 7.33313 6.66667V8.66667C7.33313 9.03486 7.63161 9.33333 7.9998 9.33333C8.36799 9.33333 8.66646 9.03486 8.66646 8.66667V6.66667C8.66646 6.29848 8.36799 6 7.9998 6ZM7.9998 10.6667C7.63161 10.6667 7.33313 10.9651 7.33313 11.3333C7.33313 11.7015 7.63161 12 7.9998 12C8.36799 12 8.66646 11.7015 8.66646 11.3333C8.66646 10.9651 8.36799 10.6667 7.9998 10.6667Z"
            fill="#FA8C16"
          />
        </svg>
      )}

      {title ? (
        <div>
          <TextStyle variant="base-medium">{title}</TextStyle>
          <p data-testid="bannerText" className="text-sm">
            {children}
          </p>
        </div>
      ) : (
        <p data-testid="bannerText" className="text-sm">
          {children}
        </p>
      )}
      {Boolean(onDismiss && dismissText) && (
        <Tooltip tooltipText={dismissText}>
          <button
            aria-label={dismissText}
            onClick={onDismiss}
            className="absolute right-1 border-none p-1"
          >
            <XMarkIcon className="h-3 w-3" />
          </button>
        </Tooltip>
      )}
    </section>
  );
}
