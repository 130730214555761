import type { MemberRole, MemberState } from '@assembly-web/services';
import {
  InformationCircleIcon,
  LockClosedIcon,
} from '@heroicons/react/24/outline';
import type { ReactNode } from 'react';
import { defineMessages, useIntl } from 'react-intl';
import { twMerge } from 'tailwind-merge';
import type { MergeExclusive } from 'type-fest';

import {
  Avatar,
  type AvatarProps,
} from '../../../DesignSystem/Feedback/Avatar';
import { TextStyle } from '../../../DesignSystem/Feedback/TextStyle';
import { Tooltip } from '../../../DesignSystem/Feedback/Tooltip';
import { Button } from '../../../DesignSystem/Inputs/Button';
import type {
  GroupedDropdownOptions,
  PermissionOption,
} from './PermissionDropdown';
import { PermissionDropdown } from './PermissionDropdown';

export type CriteriaItemPermission =
  | {
      isLocked: true;
      tooltipText: string;
    }
  | {
      isLocked: false;
      dropdownOptions: GroupedDropdownOptions;
    };

export type CriteriaItemProps = MergeExclusive<
  {
    avatar: AvatarProps;
  },
  {
    customIcon: {
      icon: ReactNode;
      iconBackgroundColor?: string;
      iconColor?: string;
    };
  }
> & {
  id: string;
  title: string;
  role?: MemberRole[];
  subtitle?: string;
  state?: MemberState;
  permission?: PermissionOption;
  permissionOptions?: CriteriaItemPermission;
  isDisabled?: boolean;
  tooltipText?: string;
  disabledTooltipText?: string;
  onPermissionChange?: (value: PermissionOption, id: string) => void;
  metaData?: Record<string, string>;
  ownerDetails?: {
    memberState: MemberState;
    memberID?: string;
  };
};

const messages = defineMessages({
  owner: {
    defaultMessage: 'Owner',
    id: 'zINlao',
  },
});
export function CriteriaItem({
  id,
  subtitle,
  title,
  permission,
  permissionOptions,
  avatar,
  customIcon,
  isDisabled,
  tooltipText,
  onPermissionChange,
  ownerDetails,
  metaData,
  disabledTooltipText,
}: CriteriaItemProps) {
  const { formatMessage } = useIntl();
  return (
    <div
      id={id}
      data-testid={`criteriaItem-${id}`}
      className={twMerge(
        'inline-flex h-[52px] w-full flex-shrink-0 items-center justify-between gap-2 overflow-hidden rounded py-1 pl-2 pr-1',
        metaData?.value === ownerDetails?.memberID && 'h-[60px]'
      )}
    >
      <div className="min-w-fit">
        {avatar ? (
          <Avatar className={twMerge(isDisabled && 'opacity-50')} {...avatar} />
        ) : null}
        {customIcon ? (
          <div
            className={twMerge(
              'inline-flex h-11 w-11 items-center justify-center rounded-[65px] p-2.5',
              !customIcon.iconBackgroundColor && 'bg-gray-9',
              isDisabled && 'opacity-50',
              customIcon.iconBackgroundColor &&
                `${customIcon.iconBackgroundColor}`
            )}
          >
            {customIcon.icon}
          </div>
        ) : null}
      </div>

      <div
        className={twMerge(
          'flex h-full min-w-0 flex-1 flex-col justify-center text-start',
          !permissionOptions && 'items-start'
        )}
      >
        <TextStyle
          as="span"
          className="w-full truncate text-gray-9"
          variant="sm-medium"
        >
          {title}
        </TextStyle>
        {Boolean(subtitle) && (
          <TextStyle
            as="span"
            className="w-full truncate text-gray-8"
            variant="sm-regular"
          >
            {subtitle}
          </TextStyle>
        )}
      </div>
      <div className="inline-flex min-w-fit flex-row items-center justify-start gap-2.5 rounded-lg">
        {Boolean(tooltipText) && (
          <Tooltip
            tooltipText={tooltipText}
            contentClassName="relative z-20"
            side="bottom"
          >
            <InformationCircleIcon className="h-4 w-4 text-gray-8" />
          </Tooltip>
        )}
        {permission && permissionOptions ? (
          permissionOptions.isLocked ? (
            <Tooltip
              tooltipText={permissionOptions.tooltipText}
              contentClassName="relative z-50"
              side="bottom"
            >
              <Button size="small" variation="tertiaryLite" disabled>
                {permission.label}
                <LockClosedIcon className="h-4 w-4" />
              </Button>
            </Tooltip>
          ) : (
            <div className="inline-flex flex-col items-center justify-start">
              {Boolean(ownerDetails) &&
                metaData?.value === ownerDetails?.memberID && (
                  <TextStyle
                    variant="xs-regular"
                    className="text-grey-8 w-full pr-8 text-end"
                  >
                    {formatMessage(messages.owner)}
                  </TextStyle>
                )}
              <PermissionDropdown
                disabled={isDisabled}
                tooltipText={disabledTooltipText}
                groupedDropdownOptions={permissionOptions.dropdownOptions}
                selectedOption={permission}
                onSelected={(option: PermissionOption) =>
                  onPermissionChange?.(option, id)
                }
                contentClassName="min-w-[202px]"
              />
            </div>
          )
        ) : null}
      </div>
    </div>
  );
}

export function CriteriaItemLoader({
  showDropdownLoader = true,
}: {
  showDropdownLoader?: boolean;
}) {
  return (
    <div className="inline-flex h-[52px] w-[576px] items-center justify-between rounded py-1 pl-2 pr-1">
      <div className="flex items-start justify-start gap-2">
        <div className="h-11 w-11 flex-shrink-0 animate-pulse rounded-[65px] bg-gray-3" />
        <div className="flex animate-pulse flex-col items-start justify-start">
          <div className="h-4 w-28 animate-pulse rounded bg-gray-3" />
          <div className="mt-1 h-4 w-52 animate-pulse rounded bg-gray-3" />
        </div>
      </div>
      {Boolean(showDropdownLoader) && (
        <div className="inline-flex flex-row items-center justify-start gap-2.5 rounded-lg">
          <div className="h-5 w-24 animate-pulse rounded bg-gray-3" />
        </div>
      )}
    </div>
  );
}
