import { twMerge } from 'tailwind-merge';

function Block({ pattern }: { pattern: string }) {
  return (
    <div
      className={twMerge(
        'relative mx-[10px] mb-[10px] mt-[37px] inline-block h-[20px] w-[20px]',
        pattern
      )}
    />
  );
}

export function GifLoader() {
  return (
    <div className="flex h-[320px] items-center justify-center">
      <div className="my-0 flex h-[37px] animate-[pulse_.8s_infinite_ease-in-out_0_alternate_backwards] items-center justify-center text-center">
        <Block pattern="animate-[bounce_1s_infinite_ease-in-out_100ms] bg-success-6" />
        <Block pattern="animate-[bounce_1s_infinite_ease-in-out_200ms] bg-primary-5" />
        <Block pattern="animate-[bounce_1s_infinite_ease-in-out_300ms] bg-purple-6" />
        <Block pattern="animate-[bounce_1s_infinite_ease-in-out_400ms] bg-error-4" />
        <Block pattern="animate-[bounce_1s_infinite_ease-in-out_500ms] bg-warning-4" />
      </div>
    </div>
  );
}
