import ContentLoader from 'react-content-loader';

export function RedemptionDetailsModalSkeletonLoader() {
  const totalHeight = 300 + 2 * 20 + 3 * 40;

  return (
    <div className="mt-1 max-w-3xl rounded-lg bg-gray-1 p-4">
      <ContentLoader
        speed={2}
        width="100%"
        height={totalHeight}
        viewBox={`0 0 400 ${totalHeight}`}
        backgroundColor="#f3f3f3"
        foregroundColor="#ecebeb"
      >
        <defs>
          <linearGradient id="gradient-bg" x1="0%" y1="0%" x2="100%" y2="0%">
            <stop offset="0%" stopColor="#f3f3f3" />
            <stop offset="50%" stopColor="#ecebeb" />
            <stop offset="100%" stopColor="#f3f3f3" />
          </linearGradient>
        </defs>

        {/* Large centered rectangle at the top */}
        <rect
          x="100"
          y="0"
          rx="3"
          ry="3"
          width="200"
          height="200"
          fill="url(#gradient-bg)"
        />

        {/* Three small rectangles below the large one */}
        <rect
          x="90"
          y="210"
          rx="3"
          ry="3"
          width="220"
          height="20"
          fill="url(#gradient-bg)"
        />
        <rect
          x="90"
          y="240"
          rx="3"
          ry="3"
          width="220"
          height="20"
          fill="url(#gradient-bg)"
        />

        {/* Existing rectangles */}

        <rect
          x="0"
          y="280"
          rx="3"
          ry="3"
          width="100%"
          height="40"
          fill="url(#gradient-bg)"
        />
        <rect
          x="0"
          y="340"
          rx="3"
          ry="3"
          width="100%"
          height="40"
          fill="url(#gradient-bg)"
        />
        <rect
          x="0"
          y="400"
          rx="3"
          ry="3"
          width="100%"
          height="40"
          fill="url(#gradient-bg)"
        />
      </ContentLoader>
    </div>
  );
}
