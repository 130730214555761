import type {
  BirthdayPost,
  BlockResponseDetails,
  FlowData,
  FlowPostResponse,
  LegacyPostTypesUnion,
  MemberDetails,
  RecognitionPost,
  WorkAnniversaryPost,
} from '@assembly-web/services';
import {
  BlockResponseState,
  BlockTypes,
  LegacyPostTypes,
  MemberRole,
  MemberState,
  PersonSelectorSelectType,
  VisibilityTypes,
} from '@assembly-web/services';

import {
  AnniversaryIcon,
  AnonymousAvatarIcon,
  audioFileIcon,
  BirthdayIcon,
  csvFileIcon,
  docFileIcon,
  genericFileIcon,
  imageFileIcon,
  pdfFileIcon,
  presentationFileIcon,
  videoFileIcon,
  zipFileIcon,
} from '../../assets/icons';

const GiveRecognitionFlowData: FlowData = {
  name: 'Give recognition',
  flowId: 'recognition',
  kind: '',
  icon: { kind: 'HEX_CODE', value: '1F389' },
};

const LegacyPostMap = {
  [LegacyPostTypes.Birthday]: 'Happy Birthday',
  [LegacyPostTypes.Anniversary]: 'Happy Anniversary',
};

export const getRespondentForLegacyFlowPost = (
  type: LegacyPostTypes.Birthday | LegacyPostTypes.Anniversary
): MemberDetails => ({
  memberID: '',
  email: '',
  firstName: LegacyPostMap[type],
  lastName: '',
  name: LegacyPostMap[type],
  username: '',
  pointsGiven: 0,
  totalPointsGiven: 0,
  memberState: MemberState.Active,
  role: [MemberRole.Admin],
  image: type === LegacyPostTypes.Birthday ? BirthdayIcon : AnniversaryIcon,
});

export const getRespondentForAnonymousFlowPost = (): MemberDetails => ({
  memberID: '',
  email: '',
  firstName: 'Anonymous User',
  lastName: '',
  name: 'Anonymous User',
  username: 'anonymous',
  pointsGiven: 0,
  totalPointsGiven: 0,
  memberState: MemberState.Active,
  role: [MemberRole.Admin],
  image: AnonymousAvatarIcon,
});

const mapLegacyPostDataToPostBlocks = (
  legacyPost:
    | RecognitionPost['post']
    | BirthdayPost['post']
    | WorkAnniversaryPost['post']
) => {
  const blocks: BlockResponseDetails[] = [];
  const {
    coreValue,
    gifURL,
    message,
    pointsEach,
    taggedUsers,
    to,
    editedAt,
    currencyName,
  } = legacyPost;

  if (to.length) {
    const celebrateBlock: BlockResponseDetails = {
      blockId: '1',
      state: BlockResponseState.Responded,
      type: BlockTypes.PersonSelector,
      content: {
        title: 'Who would you like to celebrate?',
        type: BlockTypes.PersonSelector,
        selectType: PersonSelectorSelectType.SinglePerson,
        rules: { required: false, select: 'EVERYONE' },
      },
      edited: editedAt,
      response: {
        persons: to.map((member) => ({
          ...member,
          memberID: member.memberID,
          memberId: member.memberID,
          name: {
            firstName: member.firstName,
            lastName: member.lastName,
          },
          image: member.image ?? undefined,
        })),
      },
    };
    blocks.push(celebrateBlock);
  }

  if (coreValue) {
    const coreValueBlock: BlockResponseDetails = {
      blockId: '3',
      type: BlockTypes.Dropdown,
      state: BlockResponseState.Responded,
      edited: editedAt,
      content: {
        title: 'Choose a core value.',
        type: BlockTypes.Dropdown,
        options: [],
        rules: { required: false, limit: { exact: 1 } },
      },
      response: {
        value: [
          {
            value: coreValue,
            id: coreValue,
          },
        ],
      },
    };

    blocks.push(coreValueBlock);
  }

  if (message) {
    const openEndedBlock: BlockResponseDetails = {
      blockId: '3',
      type: BlockTypes.OpenEnded,
      state: BlockResponseState.Responded,
      edited: editedAt,
      content: {
        title: 'What do you want to say?',
        type: BlockTypes.OpenEnded,
        rules: {
          required: false,
          allowedMedia: [],
          fileType: [],
        },
      },
      response: {
        value: message,
        files: [],
        gifUrl: gifURL,
        mentions: taggedUsers.map((member) => ({
          ...member,
          memberID: member.memberID,
          memberId: member.memberID,
          name: {
            firstName: member.firstName,
            lastName: member.lastName,
          },
          image: member.image ?? undefined,
        })),
      },
    };

    blocks.push(openEndedBlock);
  }

  if (pointsEach) {
    const coreValueBlock: BlockResponseDetails = {
      blockId: '3',
      type: BlockTypes.GivePointsStack,
      state: BlockResponseState.Responded,
      edited: editedAt,
      content: {
        title: `Lastly, boost your celebration with ${currencyName}!`,
        type: BlockTypes.GivePointsStack,
        rules: {
          required: false,
          hidePoints: false,
          points: { noLimit: true },
        },
      },
      response: {
        value: pointsEach,
      },
    };

    blocks.push(coreValueBlock);
  }

  return blocks;
};

export function convertRecognitionPostToFlowPostFormat(
  recognitionPost: RecognitionPost['post']
): FlowPostResponse {
  const {
    kind,
    from,
    postID,
    createdAt,
    isPrivate,
    reactions,
    commentsCount,
    hasReplyDraft,
    isMuted,
  } = recognitionPost;

  return {
    hasReplyDraft,
    respondent: from,
    reactions,
    responseId: postID,
    instanceId: '',
    commentsCount: commentsCount,
    createdAt,
    kind,
    isPrivate,
    responses: mapLegacyPostDataToPostBlocks(recognitionPost),
    flow: GiveRecognitionFlowData,
    visibility: VisibilityTypes.Everyone,
    canDelete: false,
    canEdit: false,
    isBoostAllowed: true,
    ...(isMuted && { isMuted }),
  };
}

export function convertPostDataToFlowPostFormat(
  legacyPost: LegacyPostTypesUnion['post']
): FlowPostResponse {
  if (legacyPost.type === LegacyPostTypes.Birthday) {
    return convertBirthdayAndAnniversaryPostToFlowPostFormat(legacyPost);
  }

  if (legacyPost.type === LegacyPostTypes.Anniversary) {
    return convertBirthdayAndAnniversaryPostToFlowPostFormat(legacyPost);
  }

  return convertRecognitionPostToFlowPostFormat(legacyPost);
}

export function convertBirthdayAndAnniversaryPostToFlowPostFormat(
  legacyPost: BirthdayPost['post'] | WorkAnniversaryPost['post']
): FlowPostResponse {
  const {
    postID,
    commentsCount,
    createdAt,
    kind,
    isPrivate,
    type,
    reactions,
    hasReplyDraft,
    isMuted,
  } = legacyPost;

  return {
    hasReplyDraft,
    respondent: getRespondentForLegacyFlowPost(type),
    reactions,
    responseId: postID,
    instanceId: '',
    commentsCount,
    createdAt,
    kind,
    isPrivate,
    responses: mapLegacyPostDataToPostBlocks(legacyPost),
    flow: GiveRecognitionFlowData,
    visibility: VisibilityTypes.Everyone,
    canDelete: false,
    canEdit: false,
    isBoostAllowed: true,
    ...(isMuted && { isMuted }),
  };
}

export function getFileIconForFileCard(file: string) {
  switch (file) {
    case 'doc':
    case 'docx':
      return docFileIcon;

    case 'bmp':
    case 'gif':
    case 'jpeg':
    case 'jpg':
    case 'png':
    case 'svg':
      return imageFileIcon;

    case 'pps':
    case 'ppsx':
    case 'ppt':
    case 'pptx':
      return presentationFileIcon;

    case 'pdf':
      return pdfFileIcon;

    case 'csv':
    case 'xls':
    case 'xlsx':
      return csvFileIcon;

    case 'ogg':
    case 'm4a':
    case 'mp3':
    case 'wav':
      return audioFileIcon;

    case '3d2':
    case '3gp':
    case 'avi':
    case 'mov':
    case 'mp4':
    case 'mpg':
    case 'ogv':
      return videoFileIcon;

    case 'zip':
      return zipFileIcon;

    default:
      return genericFileIcon;
  }
}
