import type { CollectionColorEnum } from '@assembly-web/services';
import {
  type ChangeEvent,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import { twMerge } from 'tailwind-merge';

import { ColorRadioOption } from './ColorRadioOption';
import { EmojiPicker } from './EmojiPicker';
import { mapColorToCssClass } from './utils';

const colorOptions: CollectionColorEnum[] = [
  'grey',
  'blue',
  'red',
  'yellow',
  'green',
  'pink',
  'purple',
  'cyan',
  'orange',
];

export function EmojiColorPicker({
  color,
  emoji,
  onColorChange,
  onEmojiChange,
}: {
  color: CollectionColorEnum;
  emoji: string;
  onColorChange: (ev: ChangeEvent<HTMLInputElement>) => void;
  onEmojiChange: (emoji: string) => void;
}) {
  const [overflow, setOverflow] = useState({
    leftOverflow: false,
    rightOverflow: false,
  });
  const containerRef = useRef<HTMLDivElement>(null);

  const handleHorizontalScroll = useCallback((el: HTMLElement) => {
    const { scrollLeft, scrollWidth, clientWidth } = el;

    setOverflow({
      leftOverflow: scrollLeft > 0,
      rightOverflow: scrollLeft + clientWidth < scrollWidth,
    });
  }, []);

  useEffect(() => {
    if (containerRef.current) {
      handleHorizontalScroll(containerRef.current);
    }
  }, [handleHorizontalScroll]);

  return (
    <div className="flex w-full flex-row gap-4">
      <EmojiPicker
        className="flex-shrink-0"
        emoji={emoji}
        onChange={onEmojiChange}
        buttonColor={mapColorToCssClass(color)}
      />
      <div
        className={twMerge(
          'relative w-full overflow-hidden before:absolute before:bottom-0 before:left-0 before:top-0 before:w-4 before:bg-white-gradient-2 before:transition-opacity before:ease-in after:absolute after:bottom-0 after:right-0 after:top-0 after:w-4 after:rotate-180 after:bg-white-gradient-2 after:transition-opacity after:ease-in',
          overflow.leftOverflow ? 'before:opacity-100' : 'before:opacity-0',
          overflow.rightOverflow ? 'after:opacity-100' : 'after:opacity-0'
        )}
      >
        <div
          ref={containerRef}
          onScroll={(e) => {
            handleHorizontalScroll(e.target as HTMLElement);
          }}
          className="flex h-full min-w-0 flex-1 items-center overflow-scroll"
        >
          {colorOptions.map((colorOption) => (
            <ColorRadioOption
              checked={color === colorOption}
              color={colorOption}
              key={colorOption}
              onChange={onColorChange}
            />
          ))}
        </div>
      </div>
    </div>
  );
}
