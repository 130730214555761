import type { MemberDetails, ReactionDetails } from './flowTypes';

export enum LegacyPostTypes {
  Recognition = 'recognition',
  Birthday = 'birthday',
  Anniversary = 'anniversary',
}

type LegacyPost = {
  message: string;
  postID: string;
  assemblyID: string;
  to: MemberDetails[];
  taggedUsers: MemberDetails[];
  reactions: ReactionDetails[];
  commentsCount: number;
  pointsEach: number;
  isPrivate: boolean;
  isDeleted: boolean;
  gifURL: string;
  imageURL: string;
  createdAt: string;
  updatedAt: string;
  kind: string;
  editedAt: boolean;
  currencyName: string;
  hasReplyDraft?: boolean;
  isMuted?: boolean;
};

export type RecognitionPost = {
  post: LegacyPost & {
    type: LegacyPostTypes.Recognition;
    from: MemberDetails;
    coreValue: string;
  };
  postDTO?: LegacyPost & {
    type: LegacyPostTypes.Recognition;
    from: MemberDetails;
    coreValue: string;
  };
};

export type BirthdayPost = {
  post: LegacyPost & {
    type: LegacyPostTypes.Birthday;
    from: null;
    coreValue: null;
  };
};

export type WorkAnniversaryPost = {
  post: LegacyPost & {
    type: LegacyPostTypes.Anniversary;
    from: null;
    coreValue: null;
  };
};

export type LegacyPostTypesUnion =
  | RecognitionPost
  | BirthdayPost
  | WorkAnniversaryPost;
