import type {
  MemberDetails,
  ReactionDetails,
  RepliesResponse,
} from '@assembly-web/services';

import { PostCardFooter } from '../../../Shared/PostCard/PostCardFooter';

type ChallengeCardFooterProps = {
  showDraft: boolean;
  hideReplies: boolean;
  hideReactions: boolean;
  reactions: ReactionDetails[];
  repliesData: RepliesResponse;
  onReplyButtonClick: () => void;
  currentUserDetails: MemberDetails;
  onReactionClick: (emoji: ReactionDetails) => void;
};
export const ChallengeCardFooter = (props: ChallengeCardFooterProps) => {
  const {
    showDraft,
    reactions,
    repliesData,
    hideReplies,
    hideReactions,
    onReactionClick,
    currentUserDetails,
    onReplyButtonClick,
  } = props;
  return (
    <div className="rounded-b-lg bg-gray-1 px-4 py-2">
      <PostCardFooter
        showDraft={showDraft}
        reactions={reactions}
        repliesData={repliesData}
        hideReplies={hideReplies}
        hideReactions={hideReactions}
        onReactionClick={onReactionClick}
        currentMember={currentUserDetails}
        onReplyButtonClick={onReplyButtonClick}
      />
    </div>
  );
};
