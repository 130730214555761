import {
  type CollectionColorEnum,
  type CollectionItem,
  type CommentSearchResult,
  type ExternalDocSources,
  type ExternalFileCollectionItem,
  type ExternalFileSearchResult,
  type FileCollectionItem,
  type FileSearchResult,
  type FlowCollectionItem,
  type FlowSearchResult,
  type ItemToAdd,
  type LinkCollectionItem,
  type LinkSearchResult,
  type MemberCollectionItem,
  type MemberSearchResult,
  MemberState,
  MemberStatus,
  type PostCollectionItem,
  type PostSearchResult,
  type ResponseCollectionItem,
  type ResponseSearchResult,
  type SearchIndexResult,
  type TaskCollectionItem,
  type TaskSearchResult,
} from '.';
import { inverseMap } from './objectUtils';
import type {
  ChallengeCollectionItem,
  ChallengeCommentCollectionItem,
  PostCommentCollectionItem,
  ResponseCommentCollectionItem,
} from './types/collections';
import {
  type ChallengeSearchResult,
  checkIfCommentKindMatchesForCollection,
} from './types/searchIndex';

const colorToHexMap: Record<CollectionColorEnum, string> = {
  grey: '#E8E8E8',
  blue: '#ADC6FF',
  red: '#FFA39E',
  yellow: '#FFE58F',
  green: '#B7EB8F',
  pink: '#FFADD2',
  purple: '#D3ADF7',
  cyan: '#87E8DE',
  orange: '#FFD591',
};

const hexToColorMap: Record<string, CollectionColorEnum> =
  inverseMap(colorToHexMap);

export const collectionColor = {
  colorToHexMap,
  hexToColorMap,
};

const baseSearchResult = {
  highlight: {},
  createdAt: '',
  score: 0,
  updatedAt: '',
};

function getFlow(item: FlowCollectionItem): FlowSearchResult {
  return {
    ...baseSearchResult,
    ...item,
    source: 'assembly',
    type: 'flow',
  };
}

export function getMixpanelCollectionItemType(
  items: SearchIndexResult[] | ItemToAdd[] | SearchIndexResult | ItemToAdd
): string[] | string {
  const types = new Set<string>();
  const getFormattedType = (item: SearchIndexResult | ItemToAdd) => {
    switch (item.type) {
      case 'file':
      case 'external_file':
        types.add('File');
        break;
      case 'flow':
        types.add('Flow');
        break;
      case 'weblink':
      case 'link':
        types.add('Link');
        break;
      case 'member':
        types.add('People');
        break;
      case 'comment':
        types.add('Reply');
        break;
      case 'post':
      case 'response':
        types.add('Post');
        break;
      case 'task':
        types.add('Task');
        break;
      default:
        return;
    }
  };
  if (!Array.isArray(items)) {
    getFormattedType(items);
    const result = types.keys().next().value;
    return result !== undefined ? result : [];
  }
  items.forEach(getFormattedType);
  return [...types];
}

function getLink(item: LinkCollectionItem): LinkSearchResult {
  return {
    ...baseSearchResult,
    _meta: {
      entityId: item._meta.entityId,
      details: {
        url: item._meta.url,
        title: item._meta.title,
      },
    },
    id: item.id,
    source: 'assembly',
    type: 'weblink',
  };
}

function getPost(item: PostCollectionItem): PostSearchResult {
  return {
    ...baseSearchResult,
    _meta: {
      entityId: item._meta.entityId,
      message: item._meta.message,
      fromRef: { entityId: item._meta.entityId },
      from: {
        id: item._meta.from?.id ?? 'assembly',
        fullName: item._meta.from?.fullName ?? 'Assembly',
      },
      to: [{ fullName: item._meta.to[0].fullName, id: item._meta.to[0].id }],
      type: 'recognition', // TODO: do not hardcode
    },
    id: item.id,
    source: 'assembly',
    type: 'post',
  };
}

function getResponse(item: ResponseCollectionItem): ResponseSearchResult {
  return {
    ...baseSearchResult,
    _meta: item._meta,
    id: item.id,
    source: 'assembly',
    type: 'response',
  };
}

function getFile(item: FileCollectionItem): FileSearchResult {
  return {
    ...baseSearchResult,
    _meta: {
      blockId: item._meta.blockId,
      entityId: item._meta.entityId,
      flowDetails: {
        emoji: item._meta.flowDetails.icon,
        flowId: item._meta.flowDetails.flowId,
        name: item._meta.flowDetails.name,
      },
      name: item._meta.name,
      originalName: item._meta.originalName,
      responseDetails: {
        respondent: {
          fullName: item._meta.responseDetails.respondent.fullName,
        },
      },
      responseId: item._meta.responseId,
      type: item._meta.type,
      uploadedAt: item._meta.uploadedAt,
    },
    id: item.id,
    source: 'assembly',
    type: 'file',
  };
}

function getComment(
  item: ResponseCommentCollectionItem | PostCommentCollectionItem
): CommentSearchResult {
  if (checkIfCommentKindMatchesForCollection(item, 'post')) {
    return {
      ...baseSearchResult,
      _meta: {
        kind: item._meta.kind,
        entityId: item._meta.entityId,
        from: { fullName: item._meta.from.fullName, id: item._meta.from.id },
        message: item._meta.message,
        postId: item._meta.postId,
        postDetails: item._meta.postDetails,
      },
      id: item.id,
      source: 'assembly',
      type: 'comment',
      cardDetails: item.cardDetails,
    };
  } else {
    return {
      ...baseSearchResult,
      _meta: {
        kind: item._meta.kind,
        entityId: item._meta.entityId,
        from: { fullName: item._meta.from.fullName, id: item._meta.from.id },
        message: item._meta.message,
        flowDetails: item._meta.flowDetails,
        responseDetails: item._meta.responseDetails,
        flowId: item._meta.flowId,
        responseId: item._meta.responseId,
      },
      id: item.id,
      source: 'assembly',
      type: 'comment',
      cardDetails: item.cardDetails,
    };
  }
}

function getChallengeComment(
  item: ChallengeCommentCollectionItem
): CommentSearchResult {
  const challengeOwner = item.cardDetails.challenge.createdBy;
  return {
    ...baseSearchResult,
    ...item,
    _meta: {
      ...item._meta,
      kind: 'challenge',
      commentsCount: 0,
      fromRef: {
        profileImageUrl: challengeOwner.image ?? '',
        jobTitle: challengeOwner.jobTitle ?? '',
        department: challengeOwner.department ?? '',
        firstName: challengeOwner.firstName,
        lastName: challengeOwner.lastName,
        aboutMe: '',
        collectionsRef: [],
        isManager: false,
        username: challengeOwner.username,
        state: challengeOwner.memberState,
        managerIds: [],
        roles: challengeOwner.role,
        location: '',
        fullName: challengeOwner.name,
        email: challengeOwner.email,
        profileStatus: challengeOwner.memberState,
        entityId: challengeOwner.memberID,
      },
    },
    score: 0,
    employeeId: '',
    name: item.name,
    content: item.name,
    tags: ['comment'],
    createdAt: item.cardDetails.createdAt,
    updatedAt: item.cardDetails.updatedAt,
    state: item.cardDetails.challenge.state,
    highlight: {},
    id: item.id,
    source: 'assembly',
    type: 'comment',
    cardDetails: {
      ...item.cardDetails,
      challengeId: item.cardDetails.challenge.challengeId,
    },
  };
}

function getMember(item: MemberCollectionItem): MemberSearchResult {
  return {
    ...baseSearchResult,
    _meta: {
      entityId: item._meta.entityId,
      department: item._meta.department,
      email: item._meta.email,
      fullName: item._meta.fullName,
      jobTitle: item._meta.jobTitle,
      location: item._meta.location,
      profileImageUrl: item._meta.profileImageUrl,
    },
    id: item.id,
    source: 'assembly',
    type: 'member',
  };
}

function getTask(item: TaskCollectionItem): TaskSearchResult {
  return {
    ...baseSearchResult,
    _meta: item._meta,
    state: item._meta.state,
    id: item.id,
    source: 'assembly',
    type: 'task',
  };
}

function getChallenge(item: ChallengeCollectionItem): ChallengeSearchResult {
  return {
    id: item.id,
    score: 0,
    createdAt: '',
    employerId: '',
    content: '',
    updatedAt: '',
    tags: ['challenges'],
    state: 'ACTIVE',
    cardDetails: {
      ...item._meta.cardDetails,
      title: item._meta.title,
      claimButtonText: 'Claim',
      challengeId: item._meta.entityId,
      points: item._meta.points,
      createdBy: item._meta.createdBy,
      replySummary: {
        count: 0,
        lastRepliedAt: '',
        respondentsCount: 0,
        initialRespondents: [],
      },
    },
    _meta: {
      claimButtonText: '',
      state: 'ACTIVE',
      title: item._meta.title,
      points: item._meta.points,
      entityId: item._meta.entityId,
      description: '',
      type: 'STANDALONE',
      createdByRef: {
        entityId: item._meta.entityId,
        department: '',
        fullName: '',
        jobTitle: '',
        location: '',
        managerIds: [],
        profileImageUrl: '',
        profileStatus: MemberStatus.Normal,
        roles: [],
        username: '',
        collectionsRef: [],
        isManager: false,
        aboutMe: '',
        email: '',
        firstName: '',
        lastName: '',
        state: MemberState.Active,
      },
    },
    source: 'assembly',
    type: 'challenge',
    highlight: {
      content: [],
      '_meta.title': [],
      searchableContent: [],
      '_meta.createdByRef:fullName': [],
    },
  } satisfies ChallengeSearchResult;
}

function getExternalFile(
  item: ExternalFileCollectionItem
): ExternalFileSearchResult {
  return {
    ...baseSearchResult,
    ...item,
    source: item._meta.integrationName as ExternalDocSources,
    type: 'file',
  };
}

export function formatCollectionItemToSearchResult(
  item: CollectionItem
): SearchIndexResult | undefined {
  switch (item.type) {
    //TODO: check for type groupedComment
    case 'comment':
      if (checkIfCommentKindMatchesForCollection(item, 'challenge')) {
        return getChallengeComment(item);
      }
      return getComment(item);
    case 'external_file':
      return getExternalFile(item);
    case 'file':
      return getFile(item);
    case 'flow':
      return getFlow(item);
    case 'link':
      return getLink(item);
    case 'member':
      return getMember(item);
    case 'post':
      return getPost(item);
    case 'response':
      return getResponse(item);
    case 'task':
      return getTask(item);
    case 'challenge':
      return getChallenge(item);
    default:
      return;
  }
}
