import type { ChallengeCardClaimState } from '@assembly-web/services';
import { CheckCircleIcon } from '@heroicons/react/20/solid';
import { defineMessages, useIntl } from 'react-intl';
import { twJoin } from 'tailwind-merge';

import { TextStyle } from '../../../DesignSystem/Feedback/TextStyle';

type ClaimStatusBadgeProps = {
  isAllApproved?: boolean;
  claimCount?: number;
};

const messages = defineMessages({
  Claimed: {
    defaultMessage: 'Claimed',
    id: 'A1y3T3',
  },
  Pending: {
    defaultMessage: 'Pending',
    id: 'eKEL/g',
  },
});

export const ClaimStatusBadge = (props: ClaimStatusBadgeProps) => {
  const { formatMessage } = useIntl();
  const { isAllApproved, claimCount = 0 } = props;

  const claimState: ChallengeCardClaimState = isAllApproved
    ? 'Claimed'
    : 'Pending';

  return (
    <div className="m-auto min-w-24 flex-none px-3 py-1">
      <TextStyle
        variant="sm-medium"
        className={twJoin(
          'flex items-center justify-center',
          claimState === 'Claimed' ? 'text-success-8' : 'text-primary-6'
        )}
      >
        <span className="mr-2">
          <CheckCircleIcon className="h-4 w-4" />
        </span>
        {Boolean(claimCount > 0) && Boolean(claimState !== 'Claimed') && (
          <span className="mr-1">{claimCount}</span>
        )}
        {formatMessage(messages[claimState])}
      </TextStyle>
    </div>
  );
};
