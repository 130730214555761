import { postIcon } from '@assembly-web/assets';
import {
  ActivityCardTypeEnum,
  type ActivityEntity,
  type FlowEntityActivityType,
} from '@assembly-web/services';
import { defineMessages, useIntl } from 'react-intl';

import type { ToolbarItem } from '../../..';
import { Button } from '../../..';
import { ActivityCard } from './ActivityCard';

const messages = defineMessages({
  viewFeed: {
    defaultMessage: 'View feed',
    id: 'zPji8t',
  },
  startPost: {
    defaultMessage: 'Start post',
    id: 'pCERTC',
  },
});

type ToolbarMenuClickEventArgs = ToolbarItem & {
  url?: string;
  cardId: string;
  card: ActivityEntity | null;
  cardPosition: number;
  directReport?: boolean;
};

type FlowDueCardProps = {
  titleText: string;
  subText: string;
  url?: string;
  cardId: string;
  cardPosition: number;
  onViewFeedClick: (e: React.MouseEvent) => void;
  handleOnStartPostClick: (e: React.MouseEvent) => void;
  onClick?: (cardId: string) => void;
  onToolbarItemClick?: (args: ToolbarMenuClickEventArgs) => void;
  primaryToolbarItems?: ToolbarItem[] | undefined;
  secondaryToolbarItems?: ToolbarItem[] | undefined;
  cardWrapper?: React.RefObject<HTMLAnchorElement>;
  preview?: React.ReactNode;
} & Omit<FlowEntityActivityType, 'type'>;

export function FlowDueCard({
  titleText,
  subText,
  entity,
  cardId,
  url,
  onClick,
  preview,
  cardWrapper,
  cardPosition,
  onViewFeedClick,
  onToolbarItemClick,
  primaryToolbarItems,
  secondaryToolbarItems,
  handleOnStartPostClick,
}: FlowDueCardProps) {
  const { formatMessage } = useIntl();

  return (
    <ActivityCard
      icon={<img src={postIcon} alt="" className="h-4 w-4 max-md:max-w-none" />}
      text={titleText}
      subText={subText}
      onToolbarItemClick={(args) => {
        onToolbarItemClick?.({
          ...args,
          cardId,
          card: { type: ActivityCardTypeEnum.FlowActivity, entity },
          cardPosition,
        });
      }}
      onClick={() => onClick?.(cardId)}
      url={url}
      connectedRight={
        <>
          <Button
            size="small"
            variation="secondaryLite"
            onClick={onViewFeedClick}
          >
            {formatMessage(messages.viewFeed)}
          </Button>
          {Boolean(entity.canPost) && (
            <Button
              size="small"
              className="ml-3"
              variation="secondaryEmphasized"
              onClick={handleOnStartPostClick}
            >
              {formatMessage(messages.startPost)}
            </Button>
          )}
        </>
      }
      primaryToolbarItems={primaryToolbarItems}
      secondaryToolbarItems={secondaryToolbarItems}
      wrapperRef={cardWrapper}
      preview={preview}
    />
  );
}
