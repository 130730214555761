import type {
  ChallengeProof,
  ChallengeState,
  Claim,
  FileDetails,
} from './challenges';
import type { CommentCollectionItem } from './collections';
import type { Member } from './domain';
import type {
  FlowPostResponse,
  MemberDetails,
  MemberState,
  ReactionDetails,
} from './flowTypes';
import type {
  LegacyPostTypes,
  LegacyPostTypesUnion,
} from './recognitionPostTypes';
import type { RepliesResponse } from './repliesTypes';
import type { Cursor } from './response';
import type { SwagCardStatuses } from './rewards';

export type SearchIndexDocumentType =
  | 'url'
  | 'comment'
  | 'file'
  | 'flow'
  | 'member'
  | 'post'
  | 'response'
  | 'recognition'
  | 'task'
  | 'weblink'
  | 'collection'
  | 'tangoReward'
  | 'swag'
  | 'customReward'
  | 'groupedComment'
  | 'challenge';

export type AssemblyResponseKind =
  | 'EXTERNAL_ANONYMOUS'
  | 'INTERNAL'
  | 'INTERNAL_ANONYMOUS';

export type BaseSearchResultCardDetails = {
  post?: LegacyPostTypesUnion['post'];
  replySummary?: RepliesResponse;
  hasReplyDraft?: boolean;

  // These are collection card details
  isPinned?: boolean;
  isShared?: boolean;
  // `isSoftPinned` flag only indicates if the softpin checkbox is checked or not
  isSoftPinned?: boolean;
  allowedEditing?: boolean;
  fromMember?: MemberDetails;
  commentID?: string;
  challengeId?: string;
};

export type BaseSearchResult = {
  id: string;
  type: string;
  score: number;
  updatedAt: string;
  createdAt: string;
  cardDetails?: (FlowPostResponse | undefined) & BaseSearchResultCardDetails;
};

type AssemblyBaseSearchResult = {
  source: 'assembly';
} & BaseSearchResult;

export type FileSearchResult = {
  _meta: {
    blockId: string;
    entityId: string;
    claimId?: string;
    flowDetails: {
      emoji: string;
      flowId: string;
      name: string;
    };
    name: string;
    originalName: string;
    responseDetails: {
      respondent: {
        fullName: string;
      };
    };
    responseId: string;
    type: string;
    uploadedAt: string;
  };
  highlight: {
    '_meta.name'?: string[];
    '_meta.flowDetails.name'?: string[];
    'deepSearchableContent.content'?: string[];
    '_meta.responseDetails.respondent.fullName'?: string[];
    '_meta.originalName'?: string[];
  };
  type: 'file';
  name?: string;
} & AssemblyBaseSearchResult;

export type FlowSearchResult = {
  _meta: {
    description: string;
    entityId: string;
    icon: string;
    name: string;
    ownerId: string;
  };
  highlight: {
    '_meta.description'?: string[];
    '_meta.name'?: string[];
  };
  type: 'flow';
  state?: FlowStatusType;
} & AssemblyBaseSearchResult;

export type RecognitionSearchResult = {
  _meta: {
    description: string;
    entityId: string;
    icon: string;
    name: string;
  };
  highlight: {
    '_meta.description'?: string[];
    '_meta.name'?: string[];
  };
  type: 'recognition';
} & AssemblyBaseSearchResult;

export type MemberSearchResult = {
  _meta: {
    entityId: string;
    department?: string;
    email: string;
    fullName: string;
    jobTitle?: string;
    location?: string;
    profileImageUrl?: string;
    state?: MemberState;
  };
  highlight: {
    '_meta.department'?: string[];
    '_meta.email'?: string[];
    '_meta.fullName'?: string[];
    '_meta.jobTitle'?: string[];
    '_meta.location'?: string[];
  };
  type: 'member';
  state?: FlowStatusType;
} & AssemblyBaseSearchResult;

type CommentBaseMeta = {
  entityId: string;
  from: { fullName: string; id: string };
  message: string;
  gifURL?: string;
};

export type V3Replies = {
  messageHtml: string;
  messageTokens: string;
  version: number;
};

export type BoostDetails = {
  points: number;
  member: MemberDetails;
};

export type CardDetails = {
  createdAt: string;
  fromMember: MemberDetails | null;
  gifURL: string;
  imageURL: string;
  isDeleted: boolean;
  kind: 'POST' | 'RESPONSE' | 'CHALLENGE';
  messageHtml: string;
  messageTokens: string;
  message: string;
  postID: string;
  reactions: ReactionDetails[];
  taggedUsers: MemberDetails[];
  boost: BoostDetails[];
  type?: string;
  unread: boolean;
  updatedAt: string;
  version: number;
  pointsEach: number;
  editedAt: string;
};

type CommentBaseSearchResult = {
  highlight: {
    '_meta.flowDetails.name'?: string[];
    '_meta.from.fullName'?: string[];
    '_meta.message'?: string[];
    '_meta.postDetails.from.fullName'?: string[];
    '_meta.postDetails.to.fullName'?: string[];
    '_meta.postDetails.type'?: string[];
    '_meta.responseDetails.from.fullName'?: string[];
    content?: string[];
  };
  type: 'comment';
  cardDetails: CardDetails & BaseSearchResultCardDetails;
} & AssemblyBaseSearchResult;

type GroupedCommentBaseSearchResult = AssemblyBaseSearchResult & {
  highlight: {
    '_meta.flowDetails.name'?: string[];
    '_meta.from.fullName'?: string[];
    '_meta.message'?: string[];
    '_meta.postDetails.from.fullName'?: string[];
    '_meta.postDetails.to.fullName'?: string[];
    '_meta.postDetails.type'?: string[];
    '_meta.responseDetails.from.fullName'?: string[];
  };
  type: 'groupedComment';
  cardDetails: CardDetails;
};

export type FromRefDetails = {
  profileImageUrl: string;
  jobTitle: string;
  department: string;
  firstName: string;
  lastName: string;
  aboutMe: string;
  collectionsRef: string[];
  isManager: boolean;
  username: string;
  state: string;
  managerIds: string[];
  roles: string[];
  location: string;
  fullName: string;
  email?: string;
  profileStatus: MemberState;
  entityId: string;
};

type PostDetails = {
  fromRef: { entityId: string };
  from: { fullName?: string; id: string };
  to: [{ fullName: string; id: string }];
  type: 'anniversary' | 'birthday' | 'recognition';
};

type PostDetailsComment = {
  fromRef?: FromRefDetails;
  from: { fullName: string; id: string; email: string };
  to: [{ fullName: string; id: string }];
  type: 'anniversary' | 'birthday' | 'recognition';
  createdAt: string;
};

export type PostCardDetails = {
  post: {
    post: {
      type: LegacyPostTypes;
      from: MemberDetails;
      to: MemberDetails[];
      createdAt: string;
    };
  };
};

export type PostCommentSearchResult = {
  _meta: {
    kind: 'post';
    postDetails: PostDetailsComment;
    postId: string;
  } & CommentBaseMeta;
} & {
  cardDetails: CommentBaseSearchResult['cardDetails'] & PostCardDetails;
} & Omit<CommentBaseSearchResult, 'cardDetails'>;

export type PostGroupedCommentSearchResult = {
  _meta: {
    kind: 'post';
    postDetails: PostDetailsComment;
    postId: string;
    commentsCount: number;
  } & CommentBaseMeta;
} & {
  cardDetails: GroupedCommentBaseSearchResult['cardDetails'] & PostCardDetails;
} & Omit<GroupedCommentBaseSearchResult, 'cardDetails'>;

export type ResponseCardDetails = {
  response: {
    respondent: MemberDetails;
    createdAt: string;
    kind: AssemblyResponseKind;
  };
};

export type ResponseCommentSearchResult = {
  _meta: {
    kind: 'response';
    flowDetails: {
      icon: string;
      name: string;
    };
    flowId: string;
    responseDetails: {
      fromRef?: FromRefDetails;
      from: { fullName: string; id: string; email: string };
      kind: AssemblyResponseKind;
      createdAt: string;
    };
    responseId: string;
  } & CommentBaseMeta;
} & {
  cardDetails: CommentBaseSearchResult['cardDetails'] & ResponseCardDetails;
} & Omit<CommentBaseSearchResult, 'cardDetails'>;

export type ResponseGroupedCommentSearchResult = {
  _meta: {
    kind: 'response';
    flowDetails: {
      icon: string;
      name: string;
    };
    flowId: string;
    responseDetails: {
      fromRef?: FromRefDetails;
      from: { fullName: string; id: string; email: string };
      kind: AssemblyResponseKind;
      createdAt: string;
    };
    responseId: string;
    commentsCount: number;
  } & CommentBaseMeta;
} & {
  cardDetails: GroupedCommentBaseSearchResult['cardDetails'] &
    ResponseCardDetails;
} & Omit<GroupedCommentBaseSearchResult, 'cardDetails'>;

type ChallengesCardDetails = {
  challenge: {
    createdBy: MemberDetails;
    challengeId: string;
    title: string;
    state: ChallengeState;
  };
};

type ChallengeReplyDetails = AssemblyBaseSearchResult & {
  id: string;
  score: number;
  employeeId: string;
  name: string;
  source: string;
  content: string;
  tags: 'groupedComment' | 'comment'[];
  createdAt: string;
  updatedAt: string;
  state: ChallengeState;
  highlight: unknown;
  cardDetails: {
    challengeId: string;
  } & CardDetails &
    BaseSearchResultCardDetails &
    ChallengesCardDetails;
};

export type ChallengeCommentSearchResult = {
  type: 'comment';
  _meta: {
    kind: 'challenge';
    commentsCount: number;
    challengeId: string;
    fromRef: FromRefDetails;
    challengeDetails: {
      title: string;
    };
  } & CommentBaseMeta;
} & ChallengeReplyDetails;

export type ChallengeGroupedCommentSearchResult = {
  type: 'groupedComment';
  _meta: {
    kind: 'challenge';
    commentsCount: number;
    challengeId: string;
    fromRef: FromRefDetails;
    challengeDetails: {
      title: string;
    };
  } & CommentBaseMeta;
} & ChallengeReplyDetails;

export type CommentSearchResult =
  | PostCommentSearchResult
  | ResponseCommentSearchResult
  | ChallengeCommentSearchResult;

export type GroupedCommentSearchResult =
  | PostGroupedCommentSearchResult
  | ResponseGroupedCommentSearchResult
  | ChallengeGroupedCommentSearchResult;

type GetTypeForKind<
  TKind,
  TResult = GroupedCommentSearchResult | CommentSearchResult,
> = TResult extends {
  _meta: { kind: TKind };
}
  ? TResult
  : never;

export const checkIfCommentKindMatches = <
  TKind extends
    | GroupedCommentSearchResult
    | CommentSearchResult['_meta']['kind'],
>(
  result: GroupedCommentSearchResult | CommentSearchResult,
  kind: TKind
): result is GetTypeForKind<TKind> => result._meta.kind === kind;

type GetTypeForKindCollection<
  TKind,
  TResult = CommentCollectionItem,
> = TResult extends {
  _meta: { kind: TKind };
}
  ? TResult
  : never;

export const checkIfCommentKindMatchesForCollection = <
  TKind extends CommentCollectionItem['_meta']['kind'],
>(
  result: CommentCollectionItem,
  kind: TKind
): result is GetTypeForKindCollection<TKind> => result._meta.kind === kind;

export type PostSearchResult = {
  _meta: {
    entityId: string;
    message: string;
  } & PostDetails;
  highlight: {
    '_meta.from.fullName'?: string[];
    '_meta.message'?: string[];
    '_meta.to.fullName'?: string[];
    '_meta.type'?: string[];
  };
  type: 'post';
} & AssemblyBaseSearchResult;

export type ResponseSearchResult = {
  _meta: {
    entityId: string;
    flowId: string;
    icon: string;
    name: string;
    respondent: { fullName: string; id: string };
    responseBlocks: string[];
    responseKind: AssemblyResponseKind;
    canShareAsAnnouncement?: boolean;
    canEndAnnouncement?: boolean;
    canEditAnnouncement?: boolean;
  };
  highlight: {
    '_meta.name'?: string[];
    '_meta.respondent.fullName'?: string[];
    '_meta.responseBlocks'?: string[];
    searchableContent?: string[];
    deepSearchableContent?: {
      content: string[];
    };
  };
  type: 'response';
} & AssemblyBaseSearchResult;

export type TaskSearchResult = {
  _meta: {
    description: string;
    entityId: string;
    title: string;
    state: 'COMPLETED' | 'ARCHIVED' | 'ACTIVE';
    transactionId: string;
    assignedTo: {
      id: string;
    };
  };
  highlight: {
    '_meta.description'?: string[];
    '_meta.title'?: string[];
  };
  state: string;
  type: 'task';
} & AssemblyBaseSearchResult;

export type UrlSearchResult = {
  _meta: {
    url: string;
    pageTitle: string;
    pageDescription: string;
    type: UrlSearchType;
  };
  type: 'url';
} & BaseSearchResult;

export type UrlSearchType =
  | 'admin'
  | 'people'
  | 'rewards'
  | 'notebook'
  | 'templates'
  | 'knowledgecenter';

export type CollectionSearchResult = {
  _meta: {
    details: {
      description?: string;
      icon: string;
      name: string;
      color: string;
      isPinned: boolean;
    };
    entityId: string;
    createdByRef: {
      department: Member['profile']['department'];
      entityId: Member['memberId'];
      fullName: Member['profile']['fullName'];
      jobTitle: Member['profile']['jobTitle'];
      location: Member['profile']['location'];
      managerIds: Member['managerIds'];
      profileImageUrl: Member['image'];
      profileStatus: Member['status'];
      roles: Member['role'];
      username: Member['profile']['username'];
    } & Pick<Member, 'email' | 'firstName' | 'lastName' | 'state'>;
  };
  type: 'collection';
} & BaseSearchResult;

export type LinkSearchResult = {
  _meta: {
    entityId: string;
    details: {
      url: string;
      title?: string;
    };
  };
  highlight: {
    '_meta.details.title'?: string[];
    '_meta.details.url'?: string[];
  };
  type: 'weblink';
} & AssemblyBaseSearchResult;

export type Denomination = {
  faceValue: number;
  _id: string;
  utid: string;
  currencyCode: string;
};

export type TangoRewardSearchResult = {
  _meta: {
    cardDetails: {
      brandKey: string;
      brandName: string;
      disclaimer: string;
      description: string;
      shortDescription: string;
      terms: string;
      imageUrls?: {
        '278w-326ppi': string;
      };
      status: string;
      items: [
        {
          maxValue: number;
          minValue: number;
          rewardType: string;
          rewardName: string;
          redemptionInstructions: string;
          currencyCode: string;
          valueType: 'FIXED_VALUE' | 'VARIABLE_VALUE';
          faceValue: number;
          countries: string[];
        },
      ];
      denominations?: Denomination[];
    };
  };
  type: 'tangoReward';
  tags: ('gift card' | 'cash equivalent' | 'reward link' | 'donation')[];
  name: string;
  highlight: {
    searchableContent: string[];
    name: string[];
    '_meta.cardDetails.brandName': string[];
    '_meta.cardDetails.items.rewardName': string[];
    '_meta.cardDetails.description'?: string[];
  };
};

export type SwagRewardSearchResult = {
  _meta: {
    id: string;
    details: {
      image: string;
      active: boolean;
      enabled: boolean;
      points: number[];
      uid: string;
      name: string;
      desc: string;
      colors: string[];
      sizes: string[];
      storeUID: string;
      status: SwagCardStatuses;
    };
  };
  type: 'swag';
  name: string;
  highlight: {
    searchableContent: string[];
    name: string[];
    '_meta.details.name': string[];
    '_meta.details.desc'?: string[];
    content: string[];
  };
};

export type CustomRewardSearchResult = {
  _meta: {
    entityId: string;
    name: string;
    image?: {
      original: {
        relativeUrl: string;
      };
    };
    description: string;
    quantity: number;
    pointsToRedeem: number;
    isLimit: boolean;
    maxLimit?: number;
  };
  type: 'customReward';
  name: string;
  highlight: {
    searchableContent: string[];
    name: string[];
    '_meta.name': string[];
    '_meta.description'?: string[];
  };
};

export type RewardSearchResult = (
  | TangoRewardSearchResult
  | SwagRewardSearchResult
  | CustomRewardSearchResult
) &
  AssemblyBaseSearchResult;

export type ChallengeCardDetails = {
  challengeId: string;
  title: string;
  description?: {
    messageHtml: string;
  };
  points: number;
  state: ChallengeState;
  type: 'STANDALONE' | 'FLOW_DEPENDENT';
  launchedAt: string;
  endedAt?: string;
  image?: FileDetails;
  createdBy: {
    email: Member['email'];
    memberID: Member['memberID']; // TODO ( Challenges) : Deprecated key let @Nikitha know
    image: Member['image']; // try to keep key consistent image,entityId etc
    department: Member['profile']['department'];
    name: Member['name'];
    pointsGiven: Member['pointsGiven'];
    totalPointsGiven: Member['totalPointsGiven'];
    memberState: Member['memberState']; // not consistent
    jobTitle: Member['profile']['jobTitle'];
    profileStatus: Member['status'];
    role: Member['role'];
    username: Member['profile']['username'];
    pronouns: Member['pronouns'];
  } & Pick<Member, 'firstName' | 'lastName'>;
  claimButtonText: string;
  interactionSettings: {
    hideReactions: boolean;
    hideReplies: boolean;
  };
  claimsRemaining: number;
  canEdit: boolean;
  reactions: ReactionDetails[];
  selfClaimDetails: {
    claimLimit: number;
    totalEarnedPoints: number;
    claims: Claim[];
  } | null;
  proof: ChallengeProof;
  replySummary: RepliesResponse;
  commentsCount?: number;
};

export type ChallengeSearchResult = {
  employerId: string;
  content: string;
  tags: 'challenges'[];
  state: ChallengeState;
  cardDetails: ChallengeCardDetails & BaseSearchResultCardDetails;
  _meta: {
    claimButtonText: string;
    collectionsRef?: string[];
    state: ChallengeState;
    entityId: string;
    title: string;
    points: number;
    description: string;
    type: 'STANDALONE' | 'FLOW_DEPENDENT';
    createdByRef: {
      department: Member['profile']['department'];
      entityId: Member['memberId'];
      fullName: Member['profile']['fullName'];
      jobTitle: Member['profile']['jobTitle'];
      location: Member['profile']['location'];
      managerIds: Member['managerIds'];
      profileImageUrl: Member['image'];
      profileStatus: Member['status'];
      roles: Member['role']; // not consistent
      username: Member['profile']['username'];
      collectionsRef: string[];
      isManager: Member['profile']['isManager'];
      aboutMe: string;
    } & Pick<Member, 'email' | 'firstName' | 'lastName' | 'state'>;
  };
  type: 'challenge';
  highlight: {
    '_meta.title': string[];
    '_meta.description'?: string[];
    '_meta.createdByRef:fullName': string[];
    content: string[];
    searchableContent: string[];
  };
} & AssemblyBaseSearchResult;

export type AssemblySearchResult =
  | CommentSearchResult
  | GroupedCommentSearchResult
  | FileSearchResult
  | FlowSearchResult
  | LinkSearchResult
  | MemberSearchResult
  | PostSearchResult
  | ResponseSearchResult
  | TaskSearchResult
  | UrlSearchResult
  | RecognitionSearchResult
  | CollectionSearchResult
  | RewardSearchResult
  | ChallengeSearchResult;

const documentSources = [
  'assembly',
  'box',
  'dropbox',
  'googleDrive',
  'oneDrive',
  'sharePoint',
] as const;

type assemblyDocumentSources = ['assembly'];

export type ExternalDocSources = Exclude<
  (typeof documentSources)[number],
  'assembly'
>;

export type ExternalFileSearchResult = {
  _meta: {
    entityId: string;
    integrationIcon: string;
    integrationName: string;
    name: string;
    remoteLocation: string;
    type: string;
  };
  highlight: {
    '_meta.name'?: string[];
    '_meta.integrationName'?: string[];
  };
  source: ExternalDocSources;
  type: 'file';
} & BaseSearchResult;

export type Metadata = {
  pagination: Cursor;
  aggregations: {
    type: SearchIndexDocumentType | 'all';
    count: number;
  }[];
};

export type AssemblySearchIndexResponse = {
  data: SearchIndexResult[];
  maxScore: number;
  total: number;
  metadata: Metadata;
};

export type SearchIndexResult = AssemblySearchResult | ExternalFileSearchResult;

export enum UserActivitySortType {
  AtoZ = 'atoz',
  Relevance = 'relevance',
  MostViewed = 'mostViewed',
  RecentlyViewed = 'recentlyViewed',
}

export type FlowStatusType = 'ACTIVE' | 'INACTIVE';

export type TangoFilter = {
  countries?: string[];
};

export type AssemblySearchIndexRequestBody = {
  cursor: Cursor;
  filters: {
    createdAt?: DateRange;
    source: typeof documentSources | assemblyDocumentSources;
    state?: FlowStatusType[];
    type?: SearchIndexDocumentType[];
    tags?: string[];
    'assembly.fromRef'?: string[];
    'assembly.entityIn'?: string[];
    'assembly.mentionedMemberIds'?: string[];
    'swag.categories'?: number[];
    'assembly.challenge.state'?: ChallengeState[];
  };
  tangoFilter: TangoFilter;
  indexName: 'search-assembly-v3-std';
  searchTerm?: string;
  sortOptions?: Record<string, string>[];
  userActivitySortType?: UserActivitySortType | null;
  populateCardDetails?: boolean;
};

export type DateRange = { lte?: string; gte?: string };
