import {
  Item as ToggleGroupItem,
  Root as ToggleGroup,
  type ToggleGroupItemProps,
  type ToggleGroupSingleProps,
} from '@radix-ui/react-toggle-group';
import type { RefAttributes } from 'react';
import { twMerge } from 'tailwind-merge';

export function TabsGroup(
  props: ToggleGroupSingleProps & RefAttributes<HTMLDivElement>
) {
  const { onValueChange, className, ...otherProps } = props;

  return (
    <ToggleGroup
      {...otherProps}
      className={twMerge('mb-1 flex text-base font-medium', className)}
      onValueChange={(value) => {
        // Toggle group allows us to deselect an item, so we need to prevent that
        if (value) {
          onValueChange?.(value);
        }
      }}
      orientation="horizontal"
      type="single"
    />
  );
}

export function TabGroupItem(
  props: ToggleGroupItemProps & RefAttributes<HTMLButtonElement>
) {
  const { className, ...otherProps } = props;

  return (
    <ToggleGroupItem
      {...otherProps}
      className={twMerge(
        'mx-1 rounded-lg border-[1px] px-3 py-1 text-gray-9 first:ml-0',
        'focus-visible:border-gray-9 focus-visible:outline-none focus-visible:ring focus-visible:ring-primary-6 focus-visible:ring-offset-1',
        'data-[state=off]:border-gray-5 data-[state=off]:hover:bg-gray-3',
        'data-[state=on]:border-gray-9 data-[state=on]:bg-gray-9 data-[state=on]:text-gray-1',
        className
      )}
    />
  );
}
