import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  ago: {
    defaultMessage: '{timeCreatedAt}',
    id: 'NhSH4V',
  },
  beFirstToReply: {
    defaultMessage: 'Be the first to reply',
    id: 'O6sTVk',
  },
  writeReplyBelow: {
    defaultMessage: 'Write {author} a reply below 👇',
    id: 'WWGutW',
  },
  repliesLoadingError: {
    defaultMessage: 'Whoops! We had trouble loading these replies',
    id: '6VWYMg',
  },
  postLoadingError: {
    defaultMessage: 'Whoops! We had trouble loading this post',
    id: 'iIPRbd',
  },
  refreshToTryAgain: {
    defaultMessage: 'Please refresh the page or wait a bit to try again.',
    id: 'j2AMOY',
  },
  addReaction: {
    defaultMessage: 'Add a reaction',
    id: 'DbEwQH',
  },
  moreLabel: {
    defaultMessage: 'more',
    id: 'gk3x/E',
  },
  editingLabel: {
    defaultMessage: 'editing...',
    id: 'a5hb32',
  },
  edited: {
    defaultMessage: 'edited',
    id: 'Zx1w1e',
  },
  viewMoreReplies: {
    defaultMessage: 'View more replies',
    id: 'vqFzqt',
  },
  replyCountStatus: {
    defaultMessage: '{fetchedReplyCount} of {totalReplyCount}',
    id: '+bRYFk',
  },
  pointsGiven: {
    defaultMessage: 'added',
    id: 'xJEaxW',
  },
  deactivated: {
    defaultMessage: 'deactivated',
    id: 'uuPhrD',
  },
});
