import {
  type AssemblyCurrency,
  AssemblyCurrencyType,
  type ChallengeState,
  type ClaimInsightsFilterType,
  defaultCurrencyIcon,
  type InsightsTableRow,
  mapHexCodeToEmoticon,
} from '@assembly-web/services';
import {
  ArrowDownTrayIcon,
  BellAlertIcon,
  FunnelIcon,
  InformationCircleIcon,
  MagnifyingGlassIcon,
} from '@heroicons/react/24/outline';
import type { ColumnDef } from '@tanstack/react-table';
import parse from 'html-react-parser';
import { useMemo, useState } from 'react';
import { defineMessages, useIntl } from 'react-intl';

import { LoadingSpinner } from '../../../../DesignSystem/Feedback/Icons/LoadingSpinner';
import { Modal } from '../../../../DesignSystem/Feedback/Modal';
import { TextStyle } from '../../../../DesignSystem/Feedback/TextStyle';
import { Button } from '../../../../DesignSystem/Inputs/Button';
import { DropdownMenu } from '../../../../DesignSystem/Inputs/DropdownMenu';
import { TextField } from '../../../../DesignSystem/Inputs/TextField';
import { InsightsTable } from '../../Challenges/InsightsTable';
import { TableLoader } from '../../Challenges/InsightsTable/TableLoader';
import { capitalizeString } from '../../utils';
import { StatBox } from '../StatBox';

type ChallengeInsightsModalProps = {
  title: string;
  isLoading: boolean;
  hasNextPage?: boolean;
  currency: AssemblyCurrency | undefined;
  claimCounts: {
    uniqueClaims: number | null;
    totalClaims: number | null;
    pendingClaims: number | null;
  };
  challengeState: ChallengeState;
  isFetchingNextPage?: boolean;
  onLoadMore: () => void;
  onClose: () => void;
  handleRemindAllParticipant: () => void;
  onParamChange: (props: {
    key: 'search' | 'filter';
    searchValue: string;
    filterType: ClaimInsightsFilterType;
  }) => void;
  tableData: InsightsTableRow[];
  columns: ColumnDef<InsightsTableRow>[];
  showRemindAll: boolean;
  isOpen: boolean;
  downloadOptions: {
    isDownloading: boolean;
    handleOnDownloadClick: () => void;
  };
};

const messages = defineMessages({
  description: {
    defaultMessage: 'Get insights for this challenge',
    id: 'HvEEzA',
  },
  uniqueClaims: {
    defaultMessage: 'Unique claims',
    id: 'A+o6v+',
  },
  totalClaims: {
    defaultMessage: 'Total claims',
    id: '+YnS+5',
  },
  pendingClaims: {
    defaultMessage: 'Pending claims',
    id: '8rb7IR',
  },
  uniqueClaimsTooltipText: {
    defaultMessage:
      'Unique Claims counts the number of people who have successfully claimed the challenge at least once.',
    id: 'nXeFMw',
  },
  totalClaimsTooltipText: {
    defaultMessage:
      'Total Claims counts the total number of times that submissions have been approved and {icon}{currencyPluralName} have been given out.',
    id: 'D/zUss',
  },
  pendingClaimsTooltipText: {
    defaultMessage:
      'Pending Claims counts the number of people who have submitted proof and are awaiting approval.',
    id: 'FIWFTs',
  },
  searchLabel: {
    defaultMessage: 'Search',
    id: 'xmcVZ0',
  },
  pending: {
    defaultMessage: 'Pending',
    id: 'eKEL/g',
  },
  claimed: {
    defaultMessage: 'Claimed',
    id: 'A1y3T3',
  },
  unClaimed: {
    defaultMessage: 'Unclaimed',
    id: 'jPGB2A',
  },
  remindAll: {
    defaultMessage: 'Remind all',
    id: 'NMQi40',
  },
  download: {
    defaultMessage: 'Download Data',
    id: '5jKtTm',
  },
  downloading: {
    defaultMessage: 'Downloading..',
    id: 'hDYxEg',
  },
  closeChallenge: {
    defaultMessage: 'This challenge is currently closed. ',
    id: 'Utei1Y',
  },
});

const emptyStateMessages = defineMessages({
  PENDING: {
    defaultMessage: 'You do not have any pending claims for this challenge',
    id: 'iB5U80',
  },
  APPROVED: {
    defaultMessage:
      'No one has claimed this challenge yet. When they do, it will appear here.',
    id: '5nIzC4',
  },
  UNCLAIMED: {
    defaultMessage: 'You do not have any unclaimed claims for this challenge.',
    id: 'JqkTEF',
  },
  noSearchResult: {
    defaultMessage: 'No results, please try another search.',
    id: 'vrQErH',
  },
});

export function ChallengeInsightsModal({
  claimCounts,
  isLoading,
  challengeState,
  title,
  onParamChange,
  handleRemindAllParticipant,
  downloadOptions: { handleOnDownloadClick, isDownloading = false },
  tableData,
  columns,
  onClose,
  hasNextPage,
  onLoadMore,
  showRemindAll,
  isFetchingNextPage,
  isOpen,
  currency,
}: ChallengeInsightsModalProps) {
  const { formatMessage } = useIntl();
  const [searchValue, setSearchValue] = useState('');
  const [filterType, setFilterType] = useState<{
    value: ClaimInsightsFilterType;
    label: string;
  }>({
    value: 'PENDING',
    label: formatMessage(messages.pending),
  });
  const filterOptions = [
    {
      value: 'PENDING',
      label: formatMessage(messages.pending),
    },
    {
      value: 'APPROVED',
      label: formatMessage(messages.claimed),
    },
    {
      value: 'UNCLAIMED',
      label: formatMessage(messages.unClaimed),
    },
  ];
  const isFilterType = (value: string): value is ClaimInsightsFilterType => {
    return value === 'PENDING' || value === 'APPROVED' || value === 'UNCLAIMED';
  };

  const icon = useMemo(() => {
    if (currency) {
      const { value, type, name } = currency;
      return type === AssemblyCurrencyType.Custom
        ? `<img alt=${name} class="mr-0.5 h-3 w-3 inline-block" src=${value} />`
        : mapHexCodeToEmoticon(value);
    }
    return mapHexCodeToEmoticon(defaultCurrencyIcon);
  }, [currency]);

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      className="flex h-[85vh] max-h-[610px] flex-col sm:max-w-[33rem]"
      headerClassName="px-6 pt-6 pb-0 capitalize"
      bodyClassName="flex flex-col gap-4 flex-1 px-6"
      title={title}
    >
      <section className="flex flex-col gap-4">
        <TextStyle variant="sm-regular" className="text-gray-9">
          {formatMessage(messages.description)}
        </TextStyle>
        {challengeState === 'CLOSED' && (
          <div className="flex items-center gap-1 rounded-lg bg-primary-2 px-2 py-[10px] text-primary-8">
            <InformationCircleIcon className="h-4 w-4" />
            <TextStyle variant="xs-regular">
              {formatMessage(messages.closeChallenge)}
            </TextStyle>
          </div>
        )}

        <div className="flex">
          <StatBox
            value={Number(claimCounts.uniqueClaims)}
            isLoading={claimCounts.uniqueClaims === null && isLoading}
            title={formatMessage(messages.uniqueClaims)}
            tooltipText={formatMessage(messages.uniqueClaimsTooltipText)}
          />
          <StatBox
            className="ml-3"
            value={Number(claimCounts.totalClaims)}
            isLoading={claimCounts.totalClaims === null && isLoading}
            title={formatMessage(messages.totalClaims)}
            tooltipText={formatMessage(messages.totalClaimsTooltipText, {
              icon: parse(icon),
              currencyPluralName: capitalizeString(currency?.pluralName ?? ''),
            })}
          />
          <StatBox
            className="ml-3"
            value={Number(claimCounts.pendingClaims)}
            isLoading={claimCounts.pendingClaims === null && isLoading}
            title={formatMessage(messages.pendingClaims)}
            tooltipText={formatMessage(messages.pendingClaimsTooltipText)}
          />
        </div>
      </section>
      <section className="flex flex-1 flex-col rounded-lg border border-gray-5">
        <div className="grid grid-cols-[1fr_auto] items-center gap-1 px-2 py-1">
          <TextField
            type="text"
            className="text-gray-8"
            value={searchValue}
            onChange={(e) => {
              setSearchValue(e.target.value);
              onParamChange({
                key: 'search',
                searchValue: e.target.value,
                filterType: filterType.value,
              });
            }}
            isInvalid={false}
            placeholder={formatMessage(messages.searchLabel)}
            connectedLeft={<MagnifyingGlassIcon className="h-4 w-4" />}
          />
          <DropdownMenu
            variant="bordered"
            value={filterType.value}
            options={filterOptions}
            contentClassName="w-32"
            connectedLeft={<FunnelIcon className="h-4 w-4 text-gray-9" />}
            onSelected={(selectedOption) => {
              if (isFilterType(selectedOption.value)) {
                const filterOption = {
                  value: selectedOption.value,
                  label: selectedOption.label,
                };
                setFilterType(filterOption);
                onParamChange({
                  key: 'filter',
                  searchValue: searchValue,
                  filterType: filterOption.value,
                });
              }
            }}
            disabled={isLoading}
            isPortalDisabled
          />
        </div>
        <section className="flex-1">
          <div className="flex h-full flex-col justify-between">
            {isLoading ? (
              <TableLoader
                filterType={filterType.value}
                challengeStatus={challengeState}
              />
            ) : (
              <>
                <InsightsTable
                  challengeStatus={challengeState}
                  columns={columns}
                  data={tableData}
                  claimStatus={filterType.value}
                  onLoadMore={onLoadMore}
                  tableId={filterType.value}
                  hasNextPage={hasNextPage}
                  isFetchingNextPage={isFetchingNextPage}
                  emptyStateText={formatMessage(
                    searchValue
                      ? emptyStateMessages.noSearchResult
                      : emptyStateMessages[filterType.value]
                  )}
                />
                {Boolean(showRemindAll) && !searchValue && (
                  <div className="w-inherit p-4">
                    <Button
                      variation="secondaryEmphasized"
                      className="w-full py-2"
                      onClick={handleRemindAllParticipant}
                    >
                      <BellAlertIcon className="h-4 w-4" />
                      {formatMessage(messages.remindAll)}
                    </Button>
                  </div>
                )}
              </>
            )}
          </div>
        </section>
      </section>
      <section>
        <div className="w-inherit">
          <Button
            variation="secondaryLite"
            className="w-full py-2"
            onClick={handleOnDownloadClick}
            disabled={isDownloading}
          >
            {isDownloading ? (
              <>
                <LoadingSpinner />
                {formatMessage(messages.downloading)}
              </>
            ) : (
              <>
                <ArrowDownTrayIcon className="h-4 w-4" />
                {formatMessage(messages.download)}
              </>
            )}
          </Button>
        </div>
      </section>
    </Modal>
  );
}
