import { ChevronDownIcon } from '@heroicons/react/24/outline';
import { AnimatePresence, motion } from 'framer-motion';
import { useState } from 'react';
import { defineMessages, useIntl } from 'react-intl';
import { twMerge } from 'tailwind-merge';

import { TextStyle } from '../../../DesignSystem/Feedback/TextStyle';
import {
  Toolbar,
  type ToolbarProps,
} from '../../../DesignSystem/Feedback/Toolbar/Toolbar';
import { useToolbarState } from '../../../DesignSystem/Feedback/Toolbar/useToolbarState';
import { IconButton } from '../../../DesignSystem/Inputs/IconButton';
import { HorizontalRule } from '../../../DesignSystem/Layout/HorizontalRule';
import type { PostCardProps } from '../../Shared/PostCard/PostCard';
import { PostCardBody } from '../../Shared/PostCard/PostCardBody';
import {
  PostCardHeader,
  type PostCardHeaderProps,
} from '../../Shared/PostCard/PostCardHeader';
import type { BoostOptions } from '../Editors/RepliesEditor/RepliesEditor';
import { ReactionsBar, type ReactionsBarProps } from './ReactionsBar';

export type PostProps = {
  postedAt: string;
  postHeaderProps: PostCardHeaderProps;
  postBodyProps: Pick<
    PostCardProps,
    | 'currency'
    | 'onFileClick'
    | 'flowResponse'
    | 'onPostCardBodyClick'
    | 'onBlockToolbarItemClick'
  > & { repliesCount: number };
  boostOptions?: BoostOptions;
  reactionsBarProps: ReactionsBarProps;
  onMemberClick: (memberId: string) => void;
  onPostToggleClick: (isExpanded: boolean) => void;
} & Pick<ToolbarProps, 'secondaryToolbarItems' | 'onMenuItemClick'>;

const messages = defineMessages({
  originalPost: {
    defaultMessage: 'Original post',
    id: 'p1pzyX',
  },
  postedAt: {
    defaultMessage: 'Posted {postedAt} ago',
    id: 'xwpf2a',
  },
  repliesTurnedOff: {
    defaultMessage: 'Replies have been turned off',
    id: 'h9h1y6',
  },
  replyCount: {
    defaultMessage:
      '{count, plural, =0 {No Replies} one {1 reply} other {{count} replies}}',
    id: 'Sn3666',
  },
  postedJustNow: {
    defaultMessage: 'Posted just now',
    id: '+0gpoj',
  },
});

export function Post(props: PostProps) {
  const { formatMessage } = useIntl();

  const [isPostExpanded, setPostExpanded] = useState(true);

  const {
    onMemberClick,
    postBodyProps,
    onMenuItemClick,
    postHeaderProps,
    onPostToggleClick,
    reactionsBarProps,
    secondaryToolbarItems,
    boostOptions,
  } = props;

  const { getToolbarProps } = useToolbarState({ hideToolbar: false });

  return (
    <div className="flex w-full flex-col items-center gap-2 bg-gray-1 pt-4">
      <div className="flex w-full justify-between gap-1">
        <PostCardHeader
          {...postHeaderProps}
          boostOptions={boostOptions}
          contentClassName="max-w-[180px] truncate"
        />
        <Toolbar
          {...getToolbarProps({ onMenuItemClick })}
          secondaryToolbarItems={secondaryToolbarItems}
          compact
          hideWhenDetached
        />
      </div>
      <div className="grid w-full grid-cols-[1fr_auto] justify-between">
        <IconButton
          variation="tertiaryLite"
          className="w-fit"
          onClick={() => {
            setPostExpanded(!isPostExpanded);
            onPostToggleClick(!isPostExpanded);
          }}
          size="small"
        >
          <TextStyle variant="sm-regular" className="text-gray-8">
            {formatMessage(messages.originalPost)}
          </TextStyle>
          <ChevronDownIcon
            className={twMerge(
              'h-4 w-4 text-gray-8',
              isPostExpanded && 'rotate-180'
            )}
          />
        </IconButton>
      </div>
      <AnimatePresence initial={false}>
        <motion.div
          animate={isPostExpanded ? 'open' : 'collapsed'}
          variants={{
            open: { opacity: 1, height: 'auto' },
            collapsed: { opacity: 0, height: 0, overflow: 'hidden' },
          }}
          transition={{ duration: 0.2, ease: 'easeInOut' }}
          className="flex w-full flex-col"
        >
          <PostCardBody
            className="mb-0"
            showInCarousel={false}
            contentClassName="mt-0"
            boostOptions={boostOptions}
            onMemberClick={onMemberClick}
            currency={postBodyProps.currency}
            onFileClick={postBodyProps.onFileClick}
            flowResponse={postBodyProps.flowResponse}
            onPostCardBodyClick={postBodyProps.onPostCardBodyClick}
            onBlockToolbarItemClick={postBodyProps.onBlockToolbarItemClick}
          />
          {!postBodyProps.flowResponse.hideReactions && (
            <ReactionsBar {...reactionsBarProps} />
          )}
        </motion.div>
      </AnimatePresence>
      <div className="grid w-full items-center gap-2">
        <HorizontalRule>
          <TextStyle variant="sm-regular" className="text-gray-8">
            {postBodyProps.flowResponse.hideReplies
              ? formatMessage(messages.repliesTurnedOff)
              : formatMessage(messages.replyCount, {
                  count: postBodyProps.repliesCount,
                })}
          </TextStyle>
        </HorizontalRule>
      </div>
    </div>
  );
}
