import { useId } from 'react';

import { TextStyle } from '../../../DesignSystem/Feedback/TextStyle';
import { ToggleSwitch } from '../../../DesignSystem/Inputs/ToggleSwitch';

export type BlockToggleSwitchProps = {
  checked: boolean;
  disabled?: boolean;
  onCheckedChange: (checked: boolean) => void;
  label: string;
};

export function BlockToggleSwitch({
  checked,
  disabled,
  label,
  onCheckedChange,
}: BlockToggleSwitchProps) {
  const elId = useId();

  return (
    <div className="flex items-center gap-2">
      <label htmlFor={elId}>
        <TextStyle as="span" variant="sm-regular">
          {label}
        </TextStyle>
      </label>
      <ToggleSwitch
        aria-label={label}
        checked={checked}
        onCheckedChange={onCheckedChange}
        id={elId}
        disabled={disabled}
      />
    </div>
  );
}
