import type { EditorThemeClasses } from 'lexical';

export const EditorTheme = {
  ltr: 'direction-ltr',
  rtl: 'direction-rtl',
  placeholder: 'text-gray-400 italic',
  quote:
    "relative pl-4 before:content-[''] before:absolute before:left-0 before:top-0 before:bottom-0 before:w-1 before:bg-gray-5 before:rounded-full",
  heading: {
    h1: 'text-4xl font-semibold mb-2',
    h2: 'text-3xl font-semibold mb-2',
    h3: 'text-2xl font-semibold mb-2',
    h4: 'text-xl font-semibold mb-2',
    h5: 'text-lg font-semibold mb-2',
    h6: 'text-md font-semibold mb-2',
  },
  list: {
    nested: {
      listitem: 'lexical-nested-list',
    },
    ol: 'lexical-ol list-inside',
    ul: 'lexical-ul list-inside',
    olDepth: [
      'lexical-ol-level-1',
      'lexical-ol-level-2',
      'lexical-ol-level-3',
      'lexical-ol-level-4',
      'lexical-ol-level-5',
    ],
    ulDepth: [
      'lexical-ul-level-1',
      'lexical-ul-level-2',
      'lexical-ul-level-3',
      'lexical-ul-level-4',
      'lexical-ul-level-5',
    ],
  },
  break: 'w-full h-0.5 bg-gray-200 my-4',
  image: 'max-w-full h-auto',
  link: 'text-primary-6 underline cursor-pointer',
  text: {
    bold: 'font-bold',
    italic: 'italic',
    overflowed: 'overflow-hidden text-ellipsis whitespace-nowrap',
    hashtag: 'text-blue-500',
    underline: 'underline',
    strikethrough: 'line-through',
    underlineStrikethrough: 'underline line-through',
    code: 'font-mono bg-gray-100 rounded px-1',
  },
  code: 'font-mono text-sm bg-gray-100 rounded p-2',
  codeHighlight: {
    atrule: 'text-teal-400',
    attr: 'text-purple-400',
    boolean: 'text-blue-500',
    builtin: 'text-red-500',
    cdata: 'text-gray-500',
    char: 'text-yellow-500',
    class: 'text-orange-400 font-semibold',
    'class-name': 'text-indigo-400 font-semibold',
    comment: 'text-gray-400 italic',
    constant: 'text-blue-500',
    deleted: 'text-red-500 line-through',
    doctype: 'text-gray-500',
    entity: 'text-red-500',
    function: 'text-green-500',
    important: 'text-red-500',
    inserted: 'text-green-500 underline',
    keyword: 'text-purple-500',
    namespace: 'text-gray-500',
    number: 'text-blue-500',
    operator: 'text-yellow-500',
    prolog: 'text-gray-500',
    property: 'text-green-500',
    punctuation: 'text-gray-400',
    regex: 'text-orange-400',
    selector: 'text-indigo-400',
    string: 'text-green-500',
    symbol: 'text-blue-500',
    tag: 'text-purple-500',
    url: 'text-red-500 underline',
    variable: 'text-teal-400',
  },
} satisfies EditorThemeClasses;
