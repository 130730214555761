import { useMemo } from 'react';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
import type { useDeviceInfo } from './useDeviceInfo';
import { useMedia } from './useMedia';

/**
 * @deprecated use {@link useDeviceInfo} instead
 */
export const useDevice = () => {
  const extraSmallDevice = useMedia('(max-width: 475px)');
  const smallDevice = useMedia('(min-width: 475px) and (max-width: 767px)');
  const mediumDevice = useMedia('(min-width: 768px) and (max-width: 1023px)');
  const largeDevice = useMedia('(min-width: 1024px) and (max-width: 1279px)');
  const extraLargeDevice = useMedia('(min-width: 1280px)');

  return useMemo(() => {
    if (extraSmallDevice) {
      return 'xs';
    }
    if (smallDevice) {
      return 'sm';
    }
    if (mediumDevice) {
      return 'md';
    }
    if (largeDevice) {
      return 'lg';
    }
    if (extraLargeDevice) {
      return 'xl';
    }
    return 'xs';
  }, [
    extraLargeDevice,
    extraSmallDevice,
    largeDevice,
    mediumDevice,
    smallDevice,
  ]);
};
