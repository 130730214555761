import type { FileDetails, Meta } from '@assembly-web/services';
import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/24/outline';
import type { UppyFile } from '@uppy/core';
import { useState } from 'react';
import { useHotkeys } from 'react-hotkeys-hook';

import { TextStyle } from '../../../DesignSystem/Feedback/TextStyle';
import { IconButton } from '../../../DesignSystem/Inputs/IconButton';
import { FilePreviewer } from './FilePreviewer';

export const Carousel = (
  props: {
    startFileIndex?: number;
    onClose: () => void;
  } & (
    | { files: FileDetails[] }
    | { uppyFiles: UppyFile<Meta, Record<string, never>>[] }
  )
) => {
  const { startFileIndex = 0, onClose } = props;
  const [currentFileIndex, setCurrentFileIndex] = useState(startFileIndex);

  useHotkeys('left', () => {
    previousFile();
  });

  useHotkeys('right', () => {
    nextFile();
  });

  const files = 'uppyFiles' in props ? props.uppyFiles : props.files;

  const nextFile = () => {
    if (currentFileIndex < files.length - 1) {
      setCurrentFileIndex(currentFileIndex + 1);
    } else if (currentFileIndex === files.length - 1) {
      setCurrentFileIndex(0);
    }
  };

  const previousFile = () => {
    if (currentFileIndex > 0) {
      setCurrentFileIndex(currentFileIndex - 1);
    } else if (currentFileIndex === 0) {
      setCurrentFileIndex(files.length - 1);
    }
  };

  return (
    <FilePreviewer
      isOpen
      onClose={onClose}
      {...('uppyFiles' in props
        ? { uppyFile: props.uppyFiles[currentFileIndex] }
        : { file: props.files[currentFileIndex] })}
      secondaryActions={
        <div className="flex w-32 rounded px-0.5 text-gray-9">
          <IconButton
            variation="tertiaryLite"
            onClick={previousFile}
            size="xSmall"
            className="bg-transparent"
          >
            <ChevronLeftIcon className="h-4 w-4 text-gray-9" />
          </IconButton>
          <TextStyle className="flex-1 text-center">
            {currentFileIndex + 1}/{files.length}{' '}
          </TextStyle>
          <IconButton
            variation="tertiaryLite"
            onClick={nextFile}
            size="xSmall"
            className="bg-transparent"
          >
            <ChevronRightIcon className="h-4 w-4 text-gray-9" />
          </IconButton>
        </div>
      }
    />
  );
};
