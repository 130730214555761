import { $generateHtmlFromNodes } from '@lexical/html';
import type { LinkNode } from '@lexical/link';
import type { ElementNode, LexicalEditor, LexicalNode } from 'lexical';
import { $getRoot, $isElementNode } from 'lexical';

import type { Boost } from '../../RepliesEditor/plugins/RepliesFooterPlugin';
import type { ImageNode } from '../nodes/ImageNode';
import type { MentionNode } from '../nodes/MentionNode';

export function $getAllNodes(root: ElementNode = $getRoot()): LexicalNode[] {
  const nodes: LexicalNode[] = [];

  // https://github.com/facebook/lexical/blob/ee83ac22d52f7beefefcea7c7514e6197b4edcfa/packages/lexical-react/src/LexicalTreeView.tsx#L459-L489
  const $visit = (node: ElementNode) => {
    const childrenNodes = node.getChildren();
    for (const child of childrenNodes) {
      nodes.push(child);
      if ($isElementNode(child)) {
        $visit(child);
      }
    }
  };
  $visit(root);

  return nodes;
}

export function $getMentionNodes(
  root: ElementNode = $getRoot()
): MentionNode[] {
  return $getAllNodes(root).filter(
    (node) => node.getType() === 'mention'
  ) as MentionNode[];
}

export function $getAllMentionIds() {
  return Array.from(new Set($getMentionNodes().map((mention) => mention.id)));
}

export function $getAllGifsUrls() {
  return (
    $getAllNodes().filter((node) => node.getType() === 'image') as ImageNode[]
  )
    .map((imageNode) => imageNode.src)
    .filter(Boolean);
}

export function $getAllLinkUrls() {
  return (
    $getAllNodes().filter((node) => node.getType() === 'link') as LinkNode[]
  )
    .map((linkNode) => linkNode.__url)
    .filter(Boolean);
}

export function $getBoostPoints() {
  const nodes = (
    $getAllNodes().filter(
      (node) => node.getType() === 'mention'
    ) as MentionNode[]
  )
    .filter((mention) => mention.mentionedMember.points)
    .map(
      (mention) =>
        ({
          memberId: mention.mentionedMember.memberID,
          points: mention.mentionedMember.points ?? 0,
        }) satisfies Boost
    );

  return Array.from(new Set(nodes.map((node) => node.memberId))).map(
    (memberId) => nodes.find((node) => node.memberId === memberId) as Boost
  );
}

export function getSerializedDataFromEditor(editor: LexicalEditor) {
  const editorState = editor.getEditorState();
  const editorStateJson = editorState.toJSON();

  const html = $generateHtmlFromNodes(editor, null);
  const json = JSON.stringify(editorStateJson);

  const gifUrls = $getAllGifsUrls();
  const linkUrls = $getAllLinkUrls();
  const mentionIds = $getAllMentionIds();
  const boostedUsers = $getBoostPoints();

  const plainText = editorState.read(() => $getRoot().getTextContent());

  return { html, json, plainText, gifUrls, linkUrls, mentionIds, boostedUsers };
}
