import {
  AllowanceCycle,
  AllowanceType,
  AssemblyCurrencyType,
  CreditState,
  GifRatingsProps,
  type MemberAPIResponse,
  MemberRole,
  MemberState,
  MemberStatus,
  PrivateMessageAndImpactLevelValues,
  ReactionTypes,
} from '@assembly-web/services';

export const dummyReactions = [
  {
    name: ':sweat_smile:',
    value: 'REGULAR',
    type: ReactionTypes.Regular,
    displayName: '',
    members: [
      {
        name: 'Arun',
        firstName: 'Arun',
        lastName: 'Vijay',
        username: 'arun13',
        email: 'arun+1@joinassembly.com',
        memberID: '63f62b246f711cf531cedf00',
        pointsGiven: 29,
        totalPointsGiven: 29,
        memberState: MemberState.Active,
        role: [MemberRole.Employee],
        department: '',
        status: 'normal',
      },
      {
        name: 'John',
        firstName: 'John',
        lastName: 'Jerome',
        username: 'John',
        email: 'John+1@joinassembly.com',
        memberID: '63f62b246f711cf531cedf02',
        pointsGiven: 29,
        totalPointsGiven: 29,
        memberState: MemberState.Active,
        role: [MemberRole.Employee],
        department: '',
        status: 'normal',
      },
      {
        name: 'Zac',
        firstName: 'Zac',
        lastName: 'Jerome',
        username: 'John',
        email: 'John+1@joinassembly.com',
        memberID: '63f62b246f711cf531cedf02',
        pointsGiven: 29,
        totalPointsGiven: 29,
        memberState: MemberState.Active,
        role: [MemberRole.Employee],
        department: '',
        status: 'normal',
      },
    ],
  },
  {
    name: ':rolling_on_the_floor_laughing:',
    value: 'REGULAR',
    type: ReactionTypes.Regular,
    displayName: '',
    members: [
      {
        name: 'Arun Selva Kumar',
        firstName: 'Arun',
        lastName: 'Selva Kumar',
        username: 'arunselvakumar',
        email: 'arun@joinassembly.com',
        memberID: '6050cee28d63ea0593615538',
        image:
          'https://dev-assets.infra.joinassembly.com/duploservices-dev02-assembly-profile-img-rsz-333387423585/5ae639b425ca241b3e2d4b07/6050cee28d63ea0593615538/brian-breeden-v3wdggffm8c-unsplash_100.jpg?Expires=1713262721&Key-Pair-Id=K1UJCHCTXH6IMI&Signature=PHTMm6Zt8P693AiQksBk1K7giQmSPBVJgQYKMHkmueu2MiHVXd9UxIr0YVyS0~8NtUYFzEYPPr8tK~HKVE613M2N71rnQkA8YtSrIMVEz4tj99qhmMFSrmbuEHAHn6qwJuHC7Nk4AORHg7h5APSh7bcHI7HV1aOeXN7ntSYVzWTxKN1fja7bRDB2spg-ciX8-FFi5h8j9068qoDD2weK0AcasSDMlYUM72JW4w6TBmxVbFK4cV0CUsm2HQMpKq30KXOko3niXVOkp~WhlMyt6b1ab1OSvs0afnK-BO~xgPmIBHy1rWCgS7u8EW1AyVnkkgG-nhaPilPMdTF~t6BbIA__',
        pointsGiven: 4978,
        totalPointsGiven: 6409,
        memberState: MemberState.Active,
        role: [MemberRole.Employee],
        pronouns: 'He/Him/His',
        jobTitle: 'Head of Engineering',
        department: 'Engineering',
        status: 'normal',
      },
    ],
  },
  {
    name: ':heart_eyes:',
    value: 'REGULAR',
    type: ReactionTypes.Regular,
    displayName: '',
    members: [
      {
        name: 'Arun Barath',
        firstName: 'Arun',
        lastName: 'Barath',
        username: 'arunselvakumar',
        email: 'arun@joinassembly.com',
        memberID: '6050cee28d63ea0593615538',
        image:
          'https://dev-assets.infra.joinassembly.com/duploservices-dev02-assembly-profile-img-rsz-333387423585/5ae639b425ca241b3e2d4b07/6050cee28d63ea0593615538/brian-breeden-v3wdggffm8c-unsplash_100.jpg?Expires=1713262721&Key-Pair-Id=K1UJCHCTXH6IMI&Signature=PHTMm6Zt8P693AiQksBk1K7giQmSPBVJgQYKMHkmueu2MiHVXd9UxIr0YVyS0~8NtUYFzEYPPr8tK~HKVE613M2N71rnQkA8YtSrIMVEz4tj99qhmMFSrmbuEHAHn6qwJuHC7Nk4AORHg7h5APSh7bcHI7HV1aOeXN7ntSYVzWTxKN1fja7bRDB2spg-ciX8-FFi5h8j9068qoDD2weK0AcasSDMlYUM72JW4w6TBmxVbFK4cV0CUsm2HQMpKq30KXOko3niXVOkp~WhlMyt6b1ab1OSvs0afnK-BO~xgPmIBHy1rWCgS7u8EW1AyVnkkgG-nhaPilPMdTF~t6BbIA__',
        pointsGiven: 4978,
        totalPointsGiven: 6409,
        memberState: MemberState.Active,
        role: [MemberRole.Employee],
        pronouns: 'He/Him/His',
        jobTitle: 'Head of Engineering',
        department: 'Engineering',
        status: 'normal',
      },
    ],
  },
];
export const dummyReplies = {
  count: 7,
  respondentsCount: 3,
  lastRepliedAt: '2024-04-08T12:14:59.808Z',
  initialRespondents: [
    {
      name: 'Arun Selva Kumar',
      memberID: '6050cee28d63ea0593615538',
      image:
        'https://dev-assets.infra.joinassembly.com/duploservices-dev02-assembly-profile-img-rsz-333387423585/5ae639b425ca241b3e2d4b07/6050cee28d63ea0593615538/brian-breeden-v3wdggffm8c-unsplash_100.jpg?Expires=1713262707&Key-Pair-Id=K1UJCHCTXH6IMI&Signature=R0yDJUpf9sQiOQGEmyiyC0vo7ty~Sk4JPFUt2MCwUl4FVxF7nT38P~nW5P~C3MsQuG1M0oI9rNZpTIx87jpMcnkAhHM60Q2OPpsOd38zfYEmjIfDIv6GFnV8rODhooMbWIDCjQ2Th0JqroWYH3okRQvzccWvqHJyEvAZURkYVciZssQXeAHj3F8GGTiQBFrwXD7Uop4o9CxiXjSnzx2jlHiV4XabAoTUzGRSxyeH2Gh45HNwOtFp5HnlBxPwVnYAwbDT0WXedgxSAVLUMhqpVnbDHFBpPiWtxHiS9iBpJVydAYRszqS85zqOKg8nMLGmDb~oLWuv1DFVkVrgUHviXw__',
    },
    {
      name: 'Leo Tronolone',
      memberID: '64004cec0f223ad7aa095101',
      image:
        'https://dev-assets.infra.joinassembly.com/duploservices-dev02-assembly-profile-img-rsz-333387423585/5ae639b425ca241b3e2d4b07/64004cec0f223ad7aa095101/4fb05ba5-6eb2-4565-a9f7-12e7bb3e3b0a_100.jpg?Expires=1713262707&Key-Pair-Id=K1UJCHCTXH6IMI&Signature=VekS0vDPSWkuXspH2-tK0Hnm~YYfLE8qHbTAfvggxOKfh90-UuDW7SmNle4kYyKwsJgEFlZXgteHl-oH3K5U4pvNerN7lrQp1yh6TLD5o7lOD6CaEJ2kjDUwJvnK02g~VsLqDGa2wmiB6Qoqpbo1tWjZapj9glkWgL2T-Q~8834Bzc6bBWJ8RP3LuZU4jFfAEhr2BhTnYBXe-u7OzH4ZVzl5y8ex-ly6hpINpaD4QK~mE6ePKTesMSZUbyFnDkvAX7WMTZ1P59fTrJ~SN5EfCXSLG43Ia7f8kkEnJs2PncEyxQQAv0-0AtXqVG3-fc1RJFSx-3PqLVoJw2Oje6~DoQ__',
    },
    {
      name: 'Victor 1',
      memberID: '63fd26a4af6291bf058a3b4a',
      image:
        'https://dev-assets.infra.joinassembly.com/duploservices-dev02-assembly-profile-img-rsz-333387423585/5ae639b425ca241b3e2d4b07/63fd26a4af6291bf058a3b4a/victor-sport_100.jpg?Expires=1713262707&Key-Pair-Id=K1UJCHCTXH6IMI&Signature=eqNbV0E1wDYrQroWTbcXn-MH3LII7yssBxWJS9wA3Xhlxc9NWM9E3l9IIED3Btbxy-vOBF4iMmNCFDmpWvoIqiIqwdNEwPVFg2m0VlvJ1xC3NQT8XHHGTseCZxzPlrjqisgJxTPUxS~X1Y6KvC5fLLxz7OlbGTLeBqD0-izoeSVQ-H59wr1mv5uKi83TRke7KTEzkzcs1p93BzSdZd7AIr~akvMYLO8r2QF2iU0MmQi77uPNun9PuCLhznicNjfLOo1ikWkVpg65siuj5uuV-yVTDP1pZI69ba7-VEM1w6carVc9kTHHVaYSz4fPdsesEqONLkgCa3FVwZbwjoj37w__',
    },
  ],
};

export const userDetails = {
  member: {
    memberId: '655afbea2ea1a21cec657676',
    email: 'shamli@joinassembly.com',
    status: MemberStatus.Normal,
    role: [MemberRole.Admin],
    state: MemberState.Active,
    profile: {
      firstName: 'ShamliKiruba',
      lastName: 'Dev',
      jobTitle: 'Senior Frontend Developer',
      department: 'Engineering',
      username: 'shamli',
      isManager: true,
      birthday: {
        day: 2,
        month: 6,
        isVisibleAndCelebrated: true,
      },
      hiredday: {
        day: 16,
        month: 1,
        year: 2017,
      },
      pronouns: 'She/Her/Hers',
      fullName: '',
      location: '',
      image: '',
    },
    allowance: {
      type: AllowanceType.Regular,
      points: 500,
      cycle: AllowanceCycle.Monthly,
    },
    pointsEarned: 5,
    totalPointsGiven: 6,
    pointsLeftThisCycle: 494,
    managerIds: [],
    reportIds: [],
    timeZone: 'Pacific/Tahiti',
    activatedAt: '2023-11-20T08:42:42.062Z',
    isFreeEmail: false,
    anonymousIdentifier: '-309085148',
    name: '',
    firstName: '',
    lastName: '',
    username: '',
    memberID: '',
    pointsGiven: 0,
    memberState: MemberState.New,
    permissions: {
      canPostAnnouncements: false,
      canCreateFlow: false,
      canInviteNewUsers: false,
      profanitySetting: {
        enabled: false,
        wordsToCheck: [],
      },
    },
  },
  assembly: {
    assemblyId: '5ae639b425ca241b3e2d4b07',
    name: 'Iron America',
    logo: '',
    domain: 'carrothr.com',
    exchangeRate: 100,
    timeZone: 'America/Los_Angeles',
    currency: {
      name: 'trophy',
      pluralName: 'trophies',
      type: AssemblyCurrencyType.Regular,
      value: '1f3c6',
    },
    accounts: {
      rewards: {
        isChargeable: true,
      },
      creditState: CreditState.Unverified,
    },
    workspaceSlug: {
      shortCode: 'dJJjrcD',
      name: 'iron-america',
    },
    whitelistedDomains: ['joinassembly.com'],
    createdAt: '2018-04-29T21:31:32.598Z',
    tangoDenominationRange: {
      min: 1,
      max: 100,
    },
    flow: {
      creation: {
        enabled: false,
        value: 'EVERYONE',
      },
      announcementPermission: {
        enabled: false,
        value: 'EVERYONE',
      },
    },
    settings: {
      userManagement: {
        approvalAccess: {
          enabled: false,
          value: [],
        },
        inviteAccess: {
          enabled: false,
          value: [],
        },
        canLoginUsingLink: {
          enabled: false,
        },
      },
      coreValues: {
        enabled: false,
        value: [],
      },
      postImpactLevel: {
        enabled: false,
        value: {
          customType: PrivateMessageAndImpactLevelValues.ManagerOnly,
          levels: [],
        },
      },
      privateMessage: {
        enabled: false,
        value: PrivateMessageAndImpactLevelValues.ManagerOnly,
      },
      minMessageChar: {
        enabled: false,
        value: 0,
      },
      gifAccessibility: {
        enabled: false,
        value: GifRatingsProps.G,
      },
      blockWordsMessage: {
        enabled: false,
        value: [],
      },
      canDeletePosts: {
        enabled: false,
        value: '',
      },
      activeSpecialMention: '',
      badgeAttachability: {
        enabled: false,
      },
      imageAttachability: {
        enabled: false,
      },
      theme: undefined,
      challenge: {
        creation: {
          enabled: false,
          value: [],
        },
      },
    },
  },
} satisfies MemberAPIResponse;
