import { useIntl } from 'react-intl';

import { TextStyle } from '../../../../../DesignSystem/Feedback/TextStyle';
import { messages } from '../messages';
import { PostInteractionSettingItem } from '../PostInteractionSettingItem';
import { useAnnouncementForm } from '../useAnnouncementForm';

type SettingsAndControlsTabContentProps = {
  disableResponseSettings: boolean;
};

export function SettingsAndControlsContent({
  disableResponseSettings,
}: SettingsAndControlsTabContentProps) {
  const { formValues, setFormValue } = useAnnouncementForm();
  const { formatMessage } = useIntl();

  return (
    <section>
      <TextStyle variant="base-medium" className="text-gray-9">
        {formatMessage(messages.postInteractionSettingsLabel)}
      </TextStyle>
      <TextStyle variant="xs-regular" className="text-gray-9">
        {formatMessage(messages.postInteractionSettingsDescriptionLabel)}
      </TextStyle>

      <PostInteractionSettingItem
        className="mt-2"
        disabled={disableResponseSettings}
        isChecked={formValues.allowPostReplies}
        onCheckedChange={(checked) => {
          setFormValue('allowPostReplies', checked);
        }}
        label={formatMessage(messages.allowRepliesLabel)}
      />

      <PostInteractionSettingItem
        className="my-2"
        disabled={disableResponseSettings}
        isChecked={formValues.allowPostReactions}
        onCheckedChange={(checked) => {
          setFormValue('allowPostReactions', checked);
        }}
        label={formatMessage(messages.allowReactionsLabel)}
      />
    </section>
  );
}
