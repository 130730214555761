export type Gradient = {
  name:
    | 'gray'
    | 'blue'
    | 'red'
    | 'yellow'
    | 'green'
    | 'pink'
    | 'purple'
    | 'cyan'
    | 'orange';
  className: string;
};

export const getAnnouncementGradients = (): Gradient[] => [
  {
    name: 'gray',
    className: 'bg-gray-gradient',
  },
  {
    name: 'blue',
    className: 'bg-blue-gradient',
  },
  {
    name: 'red',
    className: 'bg-red-gradient',
  },
  {
    name: 'yellow',
    className: 'bg-yellow-gradient',
  },
  {
    name: 'green',
    className: 'bg-green-gradient',
  },
  {
    name: 'pink',
    className: 'bg-pink-gradient',
  },
  {
    name: 'purple',
    className: 'bg-purple-gradient',
  },
  {
    name: 'cyan',
    className: 'bg-cyan-gradient',
  },
  {
    name: 'orange',
    className: 'bg-orange-gradient',
  },
];
