import * as Sentry from '@sentry/react';
import { useEffect } from 'react';
import {
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
} from 'react-router-dom';

import { config } from './config';

export async function initializeSentry() {
  if (config.env === 'local') {
    return;
  }

  Sentry.init({
    dsn: config.sentry.dsn,
    environment: config.env,
    integrations: [
      Sentry.browserTracingIntegration(),
      Sentry.extraErrorDataIntegration(),
      Sentry.reactRouterV6BrowserTracingIntegration({
        useEffect,
        useLocation,
        useNavigationType,
        createRoutesFromChildren,
        matchRoutes,
      }),
    ],
    attachStacktrace: true,
    normalizeDepth: 11,
    tracesSampleRate: 1.0,
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
    tracePropagationTargets: [/^\//, /^https:\/\/*\.joinassembly\.com/],
  });
}

export const registerUserForSentry = (email: string) => {
  Sentry.setUser({ email });
};

export const configSessionReplay = (isSentrySessionReplayEnabled: boolean) => {
  if (isSentrySessionReplayEnabled && config.env === 'prod') {
    Sentry.addIntegration(
      Sentry.replayIntegration({
        maskAllText: false,
      })
    );
  }
};
