import { mapHexCodeToEmoticon } from '@assembly-web/services';
import { ChevronDownIcon } from '@heroicons/react/24/outline';
import {
  Content,
  DropdownMenu,
  Portal,
  Root,
  Trigger,
} from '@radix-ui/react-dropdown-menu';
import { useState } from 'react';
import { twMerge } from 'tailwind-merge';

import type { SelectableOptionProps } from '../../..';
import { TextStyle } from '../../../DesignSystem/Feedback/TextStyle';
import { useDropdownHandler } from '../../hooks/useDropdownState';
import type {
  InFilterSelectableListProps,
  MenuItem,
} from './InFilterSelectableList';
import { InFilterSelectableList } from './InFilterSelectableList';

export type InFilterSelectableDropdownProps = {
  label: string;
  postFixLabel: string;
  menuWithSelectedOption: MenuItem;
} & InFilterSelectableListProps;

export function InFilterSelectableDropdown({
  onMenuSelected,
  onDoneClick,
  onClearAllClick,
  onLoadMore,
  collectionsOptions,
  flowsOptions,
  appsOptions,
  isLoading,
  onSearchChange,
  isFetchingNextPage,
  hasNextPage,
  label,
  postFixLabel,
  menuWithSelectedOption,
}: InFilterSelectableDropdownProps) {
  const [isOpen, setIsOpen] = useState(false);
  const { getContentProps, getTriggerProps } = useDropdownHandler();

  const onOpenChange = () => {
    if (isOpen) {
      onSearchChange('');
    }
    setIsOpen(!isOpen);
  };

  const handleDoneClick = (currentSelectedOptions: SelectableOptionProps[]) => {
    onDoneClick(currentSelectedOptions);
    setIsOpen(false);
  };

  const handleClearAllClick = () => {
    onClearAllClick();
    setIsOpen(false);
  };

  const selectedOptionsLength =
    flowsOptions.selectedOptions.length +
    appsOptions.selectedOptions.length +
    collectionsOptions.selectedOptions.length;

  const generateFilterLabel = () => {
    if (selectedOptionsLength === 0) {
      return label;
    } else if (selectedOptionsLength === 1) {
      const optionMap = {
        Flows: flowsOptions.selectedOptions.length
          ? flowsOptions.selectedOptions[0]
          : undefined,
        Apps: appsOptions.selectedOptions.length
          ? appsOptions.selectedOptions[0]
          : undefined,
        Collections: collectionsOptions.selectedOptions.length
          ? collectionsOptions.selectedOptions[0]
          : undefined,
      };
      const selectedOption = optionMap[menuWithSelectedOption];
      return selectedOption
        ? `${label}: ${
            'emoticon' in selectedOption
              ? `${mapHexCodeToEmoticon(selectedOption.emoticon as string)} ${
                  selectedOption.value
                }`
              : selectedOption.value
          }`
        : label;
    } else {
      return `${label}: ${selectedOptionsLength} ${postFixLabel}`;
    }
  };

  return (
    <Root>
      <DropdownMenu open={isOpen} modal={false}>
        <Trigger
          className="group focus-visible:rounded-lg"
          {...getTriggerProps({
            onClick: () => {
              onOpenChange();
            },
          })}
        >
          <div
            className={twMerge(
              'flex max-w-[280px] items-center gap-2 rounded-lg border border-gray-5 bg-gray-1 px-2 py-1 align-middle hover:bg-gray-3 focus:bg-gray-3',
              selectedOptionsLength &&
                'bg-gray-3 hover:bg-gray-4 focus:bg-gray-4'
            )}
          >
            <div className="flex items-center truncate">
              <TextStyle
                as="p"
                variant={selectedOptionsLength ? 'sm-medium' : 'sm-regular'}
                className="truncate text-gray-9"
              >
                {generateFilterLabel()}
              </TextStyle>
            </div>
            <ChevronDownIcon
              className="h-4 w-4 text-gray-9 group-aria-expanded:rotate-180"
              color="gray-9"
            />
          </div>
        </Trigger>
        <Portal>
          <Content
            align="start"
            sideOffset={5}
            alignOffset={-3}
            className="rounded-lg border border-gray-5 bg-gray-1 shadow-md-down"
            {...getContentProps({
              onInteractOutside: () => {
                onOpenChange();
              },
            })}
          >
            <InFilterSelectableList
              isLoading={isLoading}
              onLoadMore={onLoadMore}
              onSearchChange={onSearchChange}
              flowsOptions={flowsOptions}
              isFetchingNextPage={isFetchingNextPage}
              appsOptions={appsOptions}
              collectionsOptions={collectionsOptions}
              onMenuSelected={onMenuSelected}
              onDoneClick={handleDoneClick}
              hasNextPage={hasNextPage}
              onClearAllClick={handleClearAllClick}
              menuWithSelectedOption={menuWithSelectedOption}
            />
          </Content>
        </Portal>
      </DropdownMenu>
    </Root>
  );
}
