import { createContext, type FC, type ReactNode, useState } from 'react';

import type { ProfileCardContentProps } from '../UI/Web/ProfileCard/ProfileCard';

type PostDrawerContextProps = {
  selectedBoostMember: ProfileCardContentProps | null;
  setSelectedBoostMember: (member: ProfileCardContentProps | null) => void;
};

export const PostDrawerContext = createContext<PostDrawerContextProps | null>(
  null
);

export const PostDrawerContextProvider: FC<{ children: ReactNode }> = ({
  children,
}) => {
  const [selectedBoostMember, setSelectedBoostMember] =
    useState<ProfileCardContentProps | null>(null);

  return (
    <PostDrawerContext.Provider
      value={{ selectedBoostMember, setSelectedBoostMember }}
    >
      {children}
    </PostDrawerContext.Provider>
  );
};
