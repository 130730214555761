import type {
  AssemblyCurrency,
  ChallengeDetailsAPIResponse,
  FileDetails,
  MemberAPIResponse,
} from '@assembly-web/services';
import type { MessageDescriptor } from 'react-intl';
import { z } from 'zod';

import type { useFileUpload } from '../../../Shared/FileUpload/useFileUpload';
import { capitalizeString } from '../../utils';
import { MODES } from './ChallengeDefinitionModal';
import { messages } from './message';
import { ClaimsSettings } from './TabContent/ClaimsSettings';
import { CustomizeAndStyling } from './TabContent/CustomizeStyling';
import { Details } from './TabContent/Details';
import { Distribution } from './TabContent/Distribution';
import { ProofSettings } from './TabContent/ProofSettings';

export const getDetailsSchema = (
  formatMessage: (
    descriptor: MessageDescriptor,
    values?: Record<string, unknown>
  ) => string,
  currency?: AssemblyCurrency
) => {
  return z.object({
    title: z
      .string()
      .min(1, { message: formatMessage(messages.emptyTitle) })
      .max(45, { message: formatMessage(messages.titleTooLongLabel) }),
    description: z
      .object({
        plainText: z
          .string()
          .max(1000, { message: formatMessage(messages.descriptionTooLong) })
          .optional(),
        messageTokens: z.string(),
        messageHtml: z.string(),
      })
      .optional(),
    points: z.coerce
      .number()
      .int({
        message: formatMessage(messages.rewardDecimalError),
      })
      .min(1, {
        message: formatMessage(messages.emptyCurrency, {
          currency: currency && capitalizeString(currency.pluralName),
        }),
      })
      .max(10000, {
        message: formatMessage(messages.currencyMax, {
          currencyPlural: currency?.pluralName,
        }),
      }),
  });
};

export const getSettingAndControlSchema = (
  formatMessage: (
    descriptor: MessageDescriptor,
    values?: Record<string, unknown>
  ) => string
) => {
  return z.object({
    totalClaimLimit: z.coerce
      .number()
      .int({
        message: formatMessage(messages.totalLimitDecimalError),
      })
      .max(10000, {
        message: formatMessage(messages.totalLimitMaxError),
      }),
    individualClaimLimit: z.coerce
      .number()
      .int({
        message: formatMessage(messages.individualLimitDecimalError),
      })
      .min(1, {
        message: formatMessage(messages.limitMinError),
      })
      .max(500, {
        message: formatMessage(messages.limitMaxError),
      }),
    proof: z.object({
      isRequired: z.boolean(),
      content: z.array(
        z
          .object({
            type: z.string(),
            isChecked: z.boolean().optional(),
            promptText: z.string().max(100, {
              message: formatMessage(messages.promptValidation),
            }),
          })
          .refine(
            (schema) => {
              if (schema.isChecked) {
                return schema.promptText.length > 0;
              }
              return true;
            },
            {
              message: formatMessage(messages.emptyPromptValidation),
              path: ['promptText'],
            }
          )
      ),
    }),
  });
};

export const getCustomizeStylingSchema = (
  formatMessage: (
    descriptor: MessageDescriptor,
    values?: Record<string, unknown>
  ) => string
) => {
  return z.object({
    image: z
      .object({
        name: z.string(),
        size: z.number(),
        location: z.string(),
      })
      .optional(),
    imageUrl: z.string().nullable().optional(),
    claimButtonText: z
      .string()
      .max(20, { message: formatMessage(messages.claimButtonError) })
      .min(1, { message: formatMessage(messages.emptyClaimButton) }),
    interactionSettings: z.object({
      hideReplies: z.boolean().default(false),
      hideReactions: z.boolean().default(false),
    }),
  });
};

export const distributeSchema = z.object({
  notifyOnEmail: z.boolean(),
  notifyOnMobile: z.boolean(),
});

export const getSchema = (
  formatMessage: (
    descriptor: MessageDescriptor,
    values?: Record<string, unknown>
  ) => string,
  currency?: AssemblyCurrency
) => {
  return z
    .object({
      launchNow: z.boolean(),
    })
    .merge(getDetailsSchema(formatMessage, currency))
    .merge(getSettingAndControlSchema(formatMessage))
    .merge(getCustomizeStylingSchema(formatMessage))
    .merge(distributeSchema);
};

export const getTabData = ({
  formatMessage,
  userDetails,
  shareChallengeNode,
  tooltipText,
  mode,
  fileUploadOptions,
  image,
}: {
  formatMessage: (
    descriptor: MessageDescriptor,
    values?: Record<string, unknown>
  ) => string;
  userDetails: MemberAPIResponse;
  shareChallengeNode: JSX.Element | null;
  tooltipText?: string;
  mode: keyof typeof MODES;
  fileUploadOptions: ReturnType<typeof useFileUpload>;
  image?: FileDetails;
}) => {
  const disableClaimSettings =
    mode === MODES.EDIT_ACTIVE || mode === MODES.VIEW;
  const disableTooltipClaimSettings =
    mode === MODES.VIEW
      ? tooltipText
      : formatMessage(messages.activeChallengeLimitDisabledTooltip);

  const enabledTabNavigation =
    mode === MODES.EDIT_ACTIVE ||
    mode === MODES.EDIT_QUEUED ||
    mode === MODES.VIEW;

  const disabledDetails =
    mode === MODES.VIEW ? { disabled: true, tooltipText } : null;

  return [
    {
      value: 'STEP_1',
      label: formatMessage(messages.detailsLabel),
      component: (
        <Details
          currency={userDetails.assembly.currency}
          {...disabledDetails}
        />
      ),
      schema: getDetailsSchema(formatMessage, userDetails.assembly.currency),
      fields: ['title', 'description', 'points'],
      ...(enabledTabNavigation && {
        disabled: false,
      }),
    },
    {
      value: 'STEP_2',
      label: formatMessage(messages.settingsAndControlsLabel),
      component: (
        <>
          <ClaimsSettings
            disabled={disableClaimSettings}
            {...(disableClaimSettings && {
              tooltipText: disableTooltipClaimSettings,
            })}
          />
          <hr className="my-4 text-gray-5" />
          <ProofSettings {...disabledDetails} />
        </>
      ),
      schema: getSettingAndControlSchema(formatMessage),
      fields: ['totalClaimLimit', 'individualClaimLimit', 'proof'],
      ...(enabledTabNavigation && {
        disabled: false,
      }),
    },
    {
      value: 'STEP_3',
      label: formatMessage(messages.customizeStylingLabel),
      component: (
        <CustomizeAndStyling
          image={image}
          userDetails={userDetails}
          fileUploadOptions={fileUploadOptions}
          {...disabledDetails}
        />
      ),
      schema: getCustomizeStylingSchema(formatMessage),
      fields: ['claimButtonText'],
      ...(enabledTabNavigation && {
        disabled: false,
      }),
    },
    {
      value: 'STEP_4',
      label: formatMessage(messages.distributionLabel),
      component: (
        <Distribution
          shareChallengeNode={shareChallengeNode}
          {...disabledDetails}
        />
      ),
      schema: distributeSchema,
      fields: [],
      ...(enabledTabNavigation && {
        disabled: false,
      }),
    },
  ];
};

const parseChallengeDetails = (
  formatMessage: (
    descriptor: MessageDescriptor,
    values?: Record<string, unknown>
  ) => string,
  challengeDetails?: ChallengeDetailsAPIResponse
) => {
  const getProofContent = (
    type: 'TEXT' | 'FILE',
    defaultMessage: MessageDescriptor
  ) => {
    const contentItem = challengeDetails?.proof.content?.find(
      (x) => x.type === type
    );

    return {
      type,
      isChecked: Boolean(contentItem),
      promptText: contentItem?.promptText ?? formatMessage(defaultMessage),
    };
  };

  return {
    ...challengeDetails,
    proof: {
      isRequired: challengeDetails?.proof.isRequired,
      content: [
        getProofContent('TEXT', messages.textPlaceHolder),
        getProofContent(
          'FILE',
          messages.challengeFileUploadDefaultPromptMessage
        ),
      ],
    },
    launchNow: challengeDetails?.state === 'ACTIVE',
    notifyOnEmail:
      challengeDetails?.notificationSettings.channels.includes('email'),
    notifyOnMobile:
      challengeDetails?.notificationSettings.channels.includes('mobile'),
  };
};

export const getDefaultValues = (
  formatMessage: (
    descriptor: MessageDescriptor,
    values?: Record<string, unknown>
  ) => string,
  challengeDetails?: ChallengeDetailsAPIResponse
) => {
  return challengeDetails && Object.keys(challengeDetails).length
    ? parseChallengeDetails(formatMessage, challengeDetails)
    : {
        individualClaimLimit: 1,
        totalClaimLimit: -1,
        claimButtonText: 'Claim reward',
        interactionSettings: {
          hideReactions: false,
          hideReplies: false,
        },
        proof: {
          isRequired: true,
          content: [
            {
              type: 'TEXT',
              isChecked: true,
              promptText: formatMessage(messages.textPlaceHolder),
            },
            {
              type: 'FILE',
              isChecked: true,
              promptText: formatMessage(
                messages.challengeFileUploadDefaultPromptMessage
              ),
            },
          ],
        },
        notifyOnEmail: true,
        notifyOnMobile: true,
      };
};
