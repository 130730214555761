import { XMarkIcon } from '@heroicons/react/20/solid';
import { Content, Overlay, Portal, Root } from '@radix-ui/react-dialog';
import { type ReactElement, type ReactNode, useEffect, useState } from 'react';
import { defineMessages, useIntl } from 'react-intl';
import { twJoin, twMerge } from 'tailwind-merge';

import { TextStyle } from '../../../../DesignSystem/Feedback/TextStyle';
import { IconButton } from '../../../../DesignSystem/Inputs/IconButton';

export type ConfirmationModalProps = {
  description: ReactNode;
  label?: string;
  leftButton: ReactElement<HTMLButtonElement>;
  onClose: () => void;
  open: boolean;
  rightButton: ReactElement<HTMLButtonElement>;
  title: ReactNode;
  leftMostButton?: ReactElement<HTMLButtonElement>;
  isHeightFull?: boolean;
  descriptionType?: 'span' | 'p';
  descriptionClassName?: string;
};

const messages = defineMessages({
  close: {
    defaultMessage: 'Close',
    id: 'rbrahO',
  },
});

export function ConfirmationModal(props: ConfirmationModalProps) {
  const {
    description,
    label,
    leftButton,
    onClose,
    open,
    rightButton,
    title,
    leftMostButton,
    isHeightFull = false,
    descriptionType = 'p',
    descriptionClassName,
  } = props;

  const { formatMessage } = useIntl();

  const [isVisible, setIsVisible] = useState(open);
  const [isReady, setIsReady] = useState(false);

  useEffect(() => {
    setIsVisible(open);
  }, [open]);

  useEffect(() => {
    if (isVisible) {
      requestAnimationFrame(() => {
        setIsReady(true); // When lazy loading modal, ensure it is in the DOM before animating
      });
    } else {
      setIsReady(false);
    }
  }, [isVisible]);

  return (
    <Root onOpenChange={onClose} open={open}>
      <Portal>
        <Overlay
          className={twMerge(
            'fixed inset-0 z-50 !my-0 bg-neutral-secondary bg-opacity-75 transition-opacity',
            isReady && isVisible
              ? 'duration-300 ease-out'
              : 'opacity-0 duration-200 ease-in'
          )}
        />
        <div
          className={twMerge(
            'fixed inset-0 z-50 !my-0 flex transform items-center justify-center transition-all',
            open ? 'visible' : 'invisible',
            isVisible
              ? 'opacity-100 duration-300 ease-out sm:scale-100'
              : 'opacity-0 duration-200 ease-in sm:scale-95'
          )}
        >
          <Content
            aria-label={label}
            className={twJoin(
              'w-full max-w-lg overflow-hidden rounded-md bg-gray-1 p-6 text-left shadow-xl-down sm:w-screen',
              isHeightFull ? 'min-w-screen flex min-h-screen flex-col' : ''
            )}
          >
            <header className="flex items-start justify-between">
              <TextStyle
                as="span"
                className="text-gray-9"
                variant="base-medium"
              >
                {title}
              </TextStyle>
              <IconButton
                onClick={onClose}
                variation="tertiaryLite"
                size="xSmall"
                className="-mr-1 -mt-1 ml-3"
              >
                <span className="sr-only">{formatMessage(messages.close)}</span>
                <XMarkIcon className="h-4 w-4" aria-hidden="true" />
              </IconButton>
            </header>
            <TextStyle
              className={twMerge(
                'mt-2 text-gray-9',
                isHeightFull && 'flex-1',
                descriptionClassName
              )}
              variant="sm-regular"
              as={descriptionType}
            >
              {description}
            </TextStyle>
            {leftMostButton ? (
              <div className="mt-6 flex justify-between">
                <div>{leftMostButton}</div>
                <div className="flex gap-x-2">
                  {leftButton}
                  {rightButton}
                </div>
              </div>
            ) : (
              <div className="mt-6 flex gap-x-2">
                {leftButton}
                {rightButton}
              </div>
            )}
          </Content>
        </div>
      </Portal>
    </Root>
  );
}
