export enum AssemblyCurrencyType {
  Regular = 'REGULAR',
  Custom = 'CUSTOM',
}

export type AssemblyCurrency = {
  name: string;
  pluralName: string;
  type: AssemblyCurrencyType;
  value: string;
};
