import {
  ChartBarSquareIcon,
  ListBulletIcon,
  MegaphoneIcon,
  SparklesIcon,
  TrophyIcon,
} from '@heroicons/react/24/outline';
import type {
  ButtonHTMLAttributes,
  FC,
  HTMLAttributes,
  ReactNode,
} from 'react';
import { Children, forwardRef } from 'react';
import { FormattedMessage } from 'react-intl';
import { twMerge } from 'tailwind-merge';

import { TextStyle } from '../../../DesignSystem/Feedback/TextStyle';
import { ReactComponent as FlowIcon } from '../../assets/icons/flow.svg';
import { Carousel } from '../Carousel/Carousel';

type Children = { children: ReactNode };
type IconContainerProps = { children: ReactNode; className: string };

const IconContainer: FC<IconContainerProps> = ({ children, className }) => {
  return (
    <span
      className={`flex h-8 w-8 items-center justify-center rounded-md p-2 ${className}`}
    >
      {children}
    </span>
  );
};

const Button = forwardRef<
  HTMLButtonElement,
  ButtonHTMLAttributes<HTMLButtonElement>
>(function Button({ className, ...props }, ref) {
  return (
    <button
      ref={ref}
      className={twMerge(
        'active:bg-gray-3disabled:cursor-not-allowed flex min-w-max flex-1 cursor-pointer items-center gap-2 rounded-lg border border-gray-5 py-[3px] pl-[3px] pr-[11px] hover:bg-gray-3 focus:bg-gray-3 focus:outline-none active:bg-gray-3 disabled:bg-gray-3',
        className
      )}
      {...props}
    />
  );
});

const Text: FC<Children> = ({ children }) => (
  <TextStyle variant="base-medium" as="span">
    {children}
  </TextStyle>
);

const GiveRecognitionButton = forwardRef<
  HTMLButtonElement,
  ButtonHTMLAttributes<HTMLButtonElement>
>(function GiveRecognitionButton(props, ref) {
  return (
    <Button ref={ref} {...props}>
      <IconContainer className="bg-upgrade-2">
        <TrophyIcon className="stroke-2 text-upgrade-8" />
      </IconContainer>
      <Text>
        <FormattedMessage defaultMessage="Give recognition" id="7HU4UR" />
      </Text>
    </Button>
  );
});

const GenerateReportsButton = forwardRef<
  HTMLButtonElement,
  ButtonHTMLAttributes<HTMLButtonElement>
>(function GenerateReportsButton(props, ref) {
  return (
    <Button ref={ref} {...props}>
      <IconContainer className="bg-success-2">
        <ChartBarSquareIcon className="stroke-2 text-success-8" />
      </IconContainer>
      <Text>
        <FormattedMessage defaultMessage="Generate reports" id="iWv4lJ" />
      </Text>
    </Button>
  );
});

const PostAnnouncementButton = forwardRef<
  HTMLButtonElement,
  ButtonHTMLAttributes<HTMLButtonElement>
>(function WorkflowButton(props, ref) {
  return (
    <Button ref={ref} {...props}>
      <IconContainer className="bg-cyan-2">
        <MegaphoneIcon className="text-cyan-8" />
      </IconContainer>
      <Text>
        <FormattedMessage defaultMessage="Post an announcement" id="fPt8oU" />
      </Text>
    </Button>
  );
});

const WorkflowButton = forwardRef<
  HTMLButtonElement,
  ButtonHTMLAttributes<HTMLButtonElement>
>(function WorkflowButton(props, ref) {
  return (
    <Button ref={ref} {...props}>
      <IconContainer className="bg-primary-2">
        <FlowIcon className="text-primary-6" />
      </IconContainer>
      <Text>
        <FormattedMessage defaultMessage="Create a workflow" id="nObCIs" />
      </Text>
    </Button>
  );
});

const AskDoraButton = forwardRef<
  HTMLButtonElement,
  ButtonHTMLAttributes<HTMLButtonElement>
>(function AskDoraButton(props, ref) {
  return (
    <Button ref={ref} {...props}>
      <IconContainer className="bg-[linear-gradient(88deg,rgba(29,63,200,0.20)_-15.88%,rgba(83,29,171,0.20)_40.52%,rgba(163,9,202,0.20)_130.45%)]">
        <SparklesIcon className="stroke-2 text-purple-8" />
      </IconContainer>
      <Text>
        <FormattedMessage defaultMessage="Ask DoraAI" id="71+qWd" />
      </Text>
    </Button>
  );
});

const SummarizePostsButton = forwardRef<
  HTMLButtonElement,
  ButtonHTMLAttributes<HTMLButtonElement>
>(function SummarizePostsButton(props, ref) {
  return (
    <Button ref={ref} {...props}>
      <IconContainer className="bg-magenta-2">
        <ListBulletIcon className="stroke-2 text-magenta-8" />
      </IconContainer>
      <Text>
        <FormattedMessage defaultMessage="Summarize posts" id="ph8+0G" />
      </Text>
    </Button>
  );
});

const FooterContainer = forwardRef<
  HTMLDivElement,
  HTMLAttributes<HTMLDivElement>
>(function FooterContainer({ children, className, ...tail }) {
  return (
    <div className={twMerge('gap-2 px-3 pb-3', className)} {...tail}>
      {children}
    </div>
  );
});

const Footer: FC<Children> = ({ children }) => {
  const filteredChildren = Children.toArray(children).filter(Boolean);

  return (
    <footer>
      <FooterContainer className="flex flex-col @[600px]/root:hidden">
        {children}
      </FooterContainer>
      <FooterContainer className="hidden flex-row @[600px]/root:flex">
        {filteredChildren.length <= 3 ? (
          <>{children}</>
        ) : (
          <Carousel slidesToScroll={1}>{children}</Carousel>
        )}
      </FooterContainer>
    </footer>
  );
};

const ActionBarFooter = {
  Button,
  Footer,
  IconContainer,
  Text,
  AskDoraButton,
  GenerateReportsButton,
  GiveRecognitionButton,
  SummarizePostsButton,
  WorkflowButton,
  PostAnnouncementButton,
};

export { ActionBarFooter, type IconContainerProps };
