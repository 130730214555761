import {
  type ButtonHTMLAttributes,
  type FC,
  forwardRef,
  type ReactNode,
} from 'react';
import { twMerge } from 'tailwind-merge';

type Children = { children: ReactNode };

const ActionBarRoot: FC<Children> = ({ children }) => {
  return (
    <section className="flex flex-col gap-3 rounded-xl border border-gray-5 bg-gray-1 @container/root">
      {children}
    </section>
  );
};

const ActionBarHeader: FC<Children> = ({ children }) => {
  return (
    <div className="flex w-full items-stretch gap-2 px-3 pt-3">{children}</div>
  );
};

const ActionBarHeaderButton = forwardRef<
  HTMLButtonElement,
  ButtonHTMLAttributes<HTMLButtonElement>
>(function ActionBarHeaderButton({ className, ...props }, ref) {
  return (
    <button
      ref={ref}
      className={twMerge(
        'group flex w-full items-center justify-between gap-2 rounded-lg bg-gray-3 hover:bg-gray-4 active:bg-gray-4',
        className
      )}
      {...props}
    />
  );
});

const ActionBarDivide = () => (
  <div className="px-3">
    <hr className="w-full border-t border-gray-5" />
  </div>
);

export {
  ActionBarDivide,
  ActionBarHeader,
  ActionBarHeaderButton,
  ActionBarRoot,
};
