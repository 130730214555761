export function PostLoader() {
  return (
    <div className="mt-4 w-full animate-pulse items-start">
      <div className="flex justify-between">
        <div className="flex items-center gap-1">
          <div className="h-[12px] w-[79px] rounded-full bg-gray-5"></div>
          <div className="h-4 w-4 rounded bg-gray-5"></div>
        </div>
        <div className="flex items-center gap-1">
          <div className="h-[12px] w-[79px] rounded-full bg-gray-5"></div>
          <div className="h-4 w-4 rounded bg-gray-5"></div>
        </div>
      </div>
      <div className="mt-2 flex flex-col gap-2">
        <div className="h-[12px] w-[352px] flex-shrink-0 rounded bg-gray-5"></div>
        <div className="h-[16px] w-full max-w-[414px] flex-shrink-0 rounded bg-gray-5"></div>
        <div className="h-[24px] w-[208px] flex-shrink-0 rounded bg-gray-5"></div>
      </div>
    </div>
  );
}
