import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  goToAnnouncement: {
    defaultMessage: 'Go to announcement',
    id: 'Vu1CEU',
  },
  markAsSeen: {
    defaultMessage: 'Mark as seen',
    id: 'o/8JwO',
  },
  copyAnnouncementLink: {
    defaultMessage: 'Copy announcement link',
    id: '8XxGKP',
  },
  editAnnouncement: {
    defaultMessage: 'Edit announcement',
    id: 'piq7j8',
  },
  viewInsights: {
    defaultMessage: 'View insights',
    id: 'HXhsPF',
  },
  endAnnouncement: {
    defaultMessage: 'End announcement',
    id: 'bmZUYW',
  },
});
