import type { ComponentProps, ReactElement } from 'react';
import { twMerge } from 'tailwind-merge';

import { CategoryOption } from '.';

const Icon = (props: ComponentProps<typeof CategoryOption.Icon>) => {
  const { children, className } = props;

  return (
    <CategoryOption.Icon
      className={twMerge(
        'col-span-1 row-span-1 w-6 rounded bg-gradient-to-bl p-1',
        className
      )}
    >
      {children}
    </CategoryOption.Icon>
  );
};

const Root = (props: {
  className?: string;
  description: string;
  icon: ReactElement<typeof Icon>;
  title: string;
  dataCategoryId?: string;
  onClick?: () => void;
}) => {
  const { className, description, icon, title } = props;

  return (
    <CategoryOption.Root
      onClick={props.onClick}
      data-category-id={props.dataCategoryId}
      className={twMerge(
        'grid grid-cols-[1fr_minmax(0,max-content)] gap-x-2 rounded-lg bg-gradient-to-bl p-3',
        className
      )}
    >
      {icon}
      <CategoryOption.Title
        className="col-start-2 row-start-1"
        variant="base-bold"
      >
        {title}
      </CategoryOption.Title>
      <CategoryOption.Description
        className="col-start-2 row-start-2"
        variant="xs-regular"
      >
        {description}
      </CategoryOption.Description>
    </CategoryOption.Root>
  );
};

export const DropdownCategoryOption = {
  Icon,
  Root,
};
