import type { ReactNode } from 'react';
import { defineMessages, useIntl } from 'react-intl';

import { TextStyle } from '../../../DesignSystem/Feedback/TextStyle';
import { Button } from '../../../DesignSystem/Inputs/Button';
import {
  DatePicker,
  type DatePickerProps,
} from '../../../DesignSystem/Inputs/DatePicker';

const messages = defineMessages({
  clear: {
    defaultMessage: 'Clear all',
    id: 'QW+Q5N',
  },
  done: {
    defaultMessage: 'Done',
    id: 'JXdbo8',
  },
});

type IndividualDatePickerProps = Omit<DatePickerProps, 'popoverPlacement'>;

export type DoubleDatePickerProps = {
  /** Props for the first date picker shown on the left */
  firstDate: IndividualDatePickerProps;

  /** Help text shown below the date input boxes */
  helpText?: ReactNode;

  /** Whether the clear button is disabled */
  isClearButtonDisabled?: boolean;

  /** Whether the done button is disabled */
  isSubmitButtonDisabled?: boolean;

  /** Event handler that is called when the clear button is clicked */
  onClearClick?: () => void;

  /** Event handler that is called when the done button is clicked */
  onSubmitClick?: () => void;

  /** Props for the second date picker shown on the right */
  secondDate: IndividualDatePickerProps;

  /** Whether to show the footer with the clear and done buttons */
  showFooter?: boolean;
};

export function DoubleDatePicker({
  firstDate,
  helpText,
  isClearButtonDisabled,
  isSubmitButtonDisabled,
  onClearClick,
  onSubmitClick,
  secondDate,
  showFooter = true,
}: DoubleDatePickerProps) {
  const { formatMessage } = useIntl();

  return (
    <>
      <div className="mx-2">
        <div className="flex flex-row flex-wrap gap-x-2">
          <div className="flex-1">
            <DatePicker {...firstDate} popoverPlacement="bottom start" />
          </div>
          <div className="flex-1">
            <DatePicker {...secondDate} popoverPlacement="bottom end" />
          </div>
        </div>
        {Boolean(helpText) && (
          <TextStyle className="mt-1" subdued variant="xs-regular">
            {helpText}
          </TextStyle>
        )}
      </div>
      {showFooter ? (
        <div className="mt-3 flex gap-2">
          <Button
            className="flex-1"
            disabled={isSubmitButtonDisabled}
            onClick={onSubmitClick}
            size="small"
            variation="secondaryEmphasized"
          >
            {formatMessage(messages.done)}
          </Button>
          <Button
            disabled={isClearButtonDisabled}
            onClick={onClearClick}
            size="small"
            variation="secondaryLite"
          >
            {formatMessage(messages.clear)}
          </Button>
        </div>
      ) : null}
    </>
  );
}
