import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import {
  $getRoot,
  COMMAND_PRIORITY_CRITICAL,
  KEY_ENTER_COMMAND,
} from 'lexical';
import { useEffect, useRef } from 'react';

import { useDeviceInfo } from '../../../../hooks/useDeviceInfo';
import { useTouchDevice } from '../../../../hooks/useTouchDevice';

type HandleEnterKeyPressPluginProps = {
  disabled?: boolean;
  onPressEnter?: () => void;
};

export const HandleEnterKeyPressPlugin = ({
  disabled,
  onPressEnter,
}: HandleEnterKeyPressPluginProps) => {
  const [editor] = useLexicalComposerContext();

  const onPressEnterRef = useRef(onPressEnter);

  const isTouchDevice = useTouchDevice();
  const isMobile = useDeviceInfo().deviceType === 'mobile';
  const isTablet = useDeviceInfo().deviceType === 'tablet';

  useEffect(() => {
    onPressEnterRef.current = onPressEnter;
  }, [onPressEnter]);

  useEffect(() => {
    return editor.registerCommand(
      KEY_ENTER_COMMAND,
      (event) => {
        if (disabled) {
          return true;
        }

        const isEnterKeyPressed =
          event && !event.metaKey && !event.ctrlKey && event.key === 'Enter';

        if (
          editor.getEditorState().read(() => $getRoot().getTextContent() === '')
        ) {
          if (event?.shiftKey) {
            event.preventDefault();
          }
          return true;
        }

        if (isEnterKeyPressed && !isMobile && !(isTablet && isTouchDevice)) {
          event.preventDefault();
          onPressEnterRef.current?.();
          return true;
        }
        return false;
      },
      COMMAND_PRIORITY_CRITICAL
    );
  }, [disabled, editor, isMobile, isTablet, isTouchDevice, onPressEnter]);

  return null;
};
