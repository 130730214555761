import { SVGReactComponents } from '@assembly-web/assets';
import { useIntl } from 'react-intl';

import { TextStyle } from '../../../../DesignSystem/Feedback/TextStyle';
import { messages } from '../FilePreviewer';

export const PreviewNotAvailable = ({
  fileType,
  downloadEnabled = false,
}: {
  fileType: string;
  downloadEnabled?: boolean;
}) => {
  const { formatMessage } = useIntl();

  return (
    <div className="flex h-full w-full flex-col items-center justify-center text-center">
      <SVGReactComponents.UnableToPreview height="175" width="196" />
      <div>
        <TextStyle variant="lg-medium">
          {formatMessage(messages.previewNotAvailable, {
            fileType,
          })}
        </TextStyle>
      </div>
      <div>{formatMessage(messages.workingOnSupport, { downloadEnabled })}</div>
    </div>
  );
};
