import {
  type FileDetails,
  type Meta,
  SplitNames,
  useFeatureSplit,
} from '@assembly-web/services';
import * as Dialog from '@radix-ui/react-dialog';
import type { UppyFile } from '@uppy/core';
import { AnimatePresence, motion } from 'framer-motion';
import { defineMessages } from 'react-intl';

import { useDeviceInfo } from '../../hooks/useDeviceInfo';
import { Header } from './components/Header';
import { ImagePreviewer } from './components/ImagePreviewer';
import { PDFPreviewer } from './components/PDFPreviewer';
import { PreviewNotAvailable } from './components/PreviewNotAvailable';
import { VideoPreviewer } from './components/VideoPreviewer';
import { useFile } from './hooks/useFile';

export const messages = defineMessages({
  download: {
    defaultMessage: 'Download',
    id: '5q3qC0',
  },
  close: {
    defaultMessage: 'Close',
    id: 'rbrahO',
  },
  workingOnSupport: {
    defaultMessage:
      'We’re working on supporting all file types. {downloadEnabled, select, true {In the meantime, download this file.} other {}}',
    id: 'tiqbQh',
  },
  unableToPreview: {
    defaultMessage: 'Unable to preview file',
    id: '3dhdmd',
  },
  previewNotAvailable: {
    defaultMessage: 'Preview not available for .{fileType} files',
    id: 'bTEMQS',
  },
  videoNotSupported: {
    defaultMessage:
      'Unable to preview video. {downloadEnabled, select, true {Please download the file to view it.} other {}}',
    id: 'KghYCB',
  },
});

type FilePreviewerProps = (
  | {
      uppyFile: UppyFile<Meta, Record<string, never>>;
    }
  | {
      file: FileDetails;
    }
) & {
  isOpen: boolean;
  onClose: () => void;
  primaryActions?: React.ReactNode | React.ReactNode[];
  secondaryActions?: React.ReactNode | React.ReactNode[];
};

export const FilePreviewer = (props: FilePreviewerProps) => {
  const { isOpen, onClose } = props;
  const notMobile = useDeviceInfo().deviceType !== 'mobile';

  const { isTreatmentActive: isV3PreviewerActive } = useFeatureSplit(
    SplitNames.FilePreviewerV3
  );

  const { src, fileType, fileName, isImageUrl, setSrc, isVideo, isPDF } =
    useFile(
      'uppyFile' in props ? { uppyFile: props.uppyFile } : { file: props.file }
    );

  return (
    <Dialog.Root
      open={isOpen}
      onOpenChange={(open) => {
        if (!open) {
          setSrc(null);
        }
      }}
    >
      <AnimatePresence>
        {Boolean(isOpen) && (
          <Dialog.Portal forceMount>
            <Dialog.Overlay
              asChild
              forceMount
              className="fixed inset-0 z-50 bg-neutral-secondary bg-opacity-75"
            >
              <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{
                  opacity: 0,
                  transition: { duration: 0.2, ease: 'easeIn' },
                }}
                transition={{ duration: 0.3, ease: 'easeOut' }}
              />
            </Dialog.Overlay>
            <Dialog.Content asChild forceMount>
              <motion.div
                initial={{
                  opacity: 0,
                  x: '-50%',
                  y: '-50%',
                  ...(notMobile && { scale: 0.95 }),
                }}
                animate={{
                  opacity: 1,
                  x: '-50%',
                  y: '-50%',
                  ...(notMobile && { scale: 1 }),
                }}
                exit={{
                  opacity: 0,
                  x: '-50%',
                  y: '-50%',
                  ...(notMobile && { scale: 0.95 }),
                  transition: { duration: 0.2, ease: 'easeIn' },
                }}
                transition={{ duration: 0.3, ease: 'easeOut' }}
                className="fixed left-[50%] top-[50%] z-50 flex h-full w-full max-w-lg -translate-x-1/2 -translate-y-1/2 transform flex-col justify-between overflow-hidden rounded-md bg-gray-1 text-left shadow-xl-down focus:outline-none md:h-[95vh] md:max-h-[60rem] md:w-[95vw] md:max-w-[100rem]"
              >
                <Header
                  fileName={fileName ?? ''}
                  onClose={onClose}
                  file={'file' in props ? props.file : undefined}
                  primaryActions={props.primaryActions}
                />
                {isImageUrl ? (
                  <ImagePreviewer imageURL={src} isOpen />
                ) : isVideo ? (
                  <VideoPreviewer src={src} downloadEnabled={'file' in props} />
                ) : isV3PreviewerActive && isPDF ? (
                  <PDFPreviewer src={src} />
                ) : (
                  <PreviewNotAvailable
                    fileType={fileType}
                    downloadEnabled={'file' in props}
                  />
                )}
                {Boolean(props.secondaryActions) && (
                  <div className="flex h-20 w-full flex-shrink-0 items-center justify-center py-3">
                    {props.secondaryActions}
                  </div>
                )}
              </motion.div>
            </Dialog.Content>
          </Dialog.Portal>
        )}
      </AnimatePresence>
    </Dialog.Root>
  );
};
