import { twMerge } from 'tailwind-merge';

import { TextStyle } from './TextStyle';

export type StatusBadgeProps<T extends string> = {
  status: T;
  statusColors: Record<T, string>;
  textClassName?: string;
};

export const StatusBadge = <T extends string>({
  status,
  statusColors,
  textClassName,
}: StatusBadgeProps<T>) => {
  const badgeColor = statusColors[status] || 'bg-primary-7'; // Fallback color if status is not found
  return (
    <div
      className={twMerge(
        'flex h-6 w-full items-center justify-center rounded-lg text-primary-1',
        badgeColor
      )}
    >
      <TextStyle as="span" variant="xs-regular" className={textClassName}>
        {status}
      </TextStyle>
    </div>
  );
};
