import { CheckIcon, TrashIcon } from '@heroicons/react/24/outline';
import { useState } from 'react';
import { defineMessages, useIntl } from 'react-intl';

import { TextStyle } from '../../../DesignSystem/Feedback/TextStyle';
import { IconButton } from '../../../DesignSystem/Inputs/IconButton';
import { PermissionDropdown } from './PermissionDropdown';
import type { Option } from './SearchableDropdownWithChips';
import { SearchableDropdownWithChips } from './SearchableDropdownWithChips';
import type { CustomRule } from './SearchableRulesDropdown';

const messages = defineMessages({
  chooseARule: {
    defaultMessage: 'Choose a {rule}',
    id: 'DR5Iu/',
  },
  is: {
    defaultMessage: 'is',
    id: 'fXdkiI',
  },
  isNot: {
    defaultMessage: "isn't",
    id: 'ZDwklk',
  },
  isTrue: {
    defaultMessage: 'is true',
    id: '0rzFZS',
  },
  isFalse: {
    defaultMessage: 'is false',
    id: 'thJnXm',
  },
  isManager: {
    defaultMessage: 'is the manager of the participant',
    id: 'cZ9n80',
  },
  managerSubText: {
    defaultMessage: 'Let each manager approve their own direct reports’ claims',
    id: '9+QSFc',
  },
});

export type NonMemberRuleSelectorProps = {
  includeManagerOption?: boolean;
  ruleType: CustomRule;
  ruleOptions: Option[];
  onRuleConfirm: (args: {
    selectedRules?: Option[];
    ruleType: CustomRule;
    condition: string;
  }) => void;
  onRuleDelete: () => void;
  displayType: 'booleanDropdown' | 'searchableDropdown';
  disabled?: boolean;
  tooltipText?: string;
};

export function NonMemberRuleSelector({
  includeManagerOption,
  ruleType,
  ruleOptions,
  onRuleConfirm,
  onRuleDelete,
  displayType,
  disabled,
  tooltipText,
}: NonMemberRuleSelectorProps) {
  const { formatMessage } = useIntl();

  const isOption = {
    id: 'is',
    label: formatMessage(messages.is),
    displayLabel: formatMessage(messages.is),
  };

  const isNotOption = {
    id: 'isNot',
    label: formatMessage(messages.isNot),
    displayLabel: formatMessage(messages.isNot),
  };

  const conditionOptions = [isOption, isNotOption];

  const isTrueOption = {
    id: 'is',
    label: formatMessage(messages.isTrue),
    displayLabel: formatMessage(messages.isTrue),
  };

  const isFalseOption = {
    id: 'isNot',
    label: formatMessage(messages.isFalse),
    displayLabel: formatMessage(messages.isFalse),
  };

  const isManagerOption = {
    id: 'isManager',
    label: formatMessage(messages.isManager),
    displayLabel: formatMessage(messages.isManager),
    subText: formatMessage(messages.managerSubText),
  };

  const managerConditionOptions = [
    ...(includeManagerOption ? [isManagerOption] : []),
    isTrueOption,
    isFalseOption,
  ];

  const [selectedCondition, setSelectedCondition] = useState(
    displayType === 'booleanDropdown' ? isTrueOption : isOption
  );

  const [selectedOptions, setSelectedOptions] = useState<Option[]>([]);

  const [values, setValues] = useState<Option[]>([...ruleOptions]);

  const onRuleOptionsSelect = (selectedID: string) => {
    if (displayType === 'searchableDropdown') {
      const selectedOptionIndex = values.findIndex(
        ({ id }) => id === selectedID
      );
      if (selectedOptionIndex === -1) {
        return;
      }
      const newValues = values.map((option, index) => {
        if (index === selectedOptionIndex) {
          return { ...option, isSelected: true };
        }
        return option;
      });
      setValues(newValues);
      const updatedSelectionOptions = newValues.filter(
        ({ isSelected }) => isSelected
      );
      setSelectedOptions(updatedSelectionOptions);
    }
  };

  const onRuleOptionsDelete = (selectedKey: string) => {
    if (displayType === 'searchableDropdown') {
      const updatedSelectedOptions = selectedOptions.filter(
        ({ id }) => id !== selectedKey
      );
      setSelectedOptions(updatedSelectedOptions);
      const updatedValues = values.map((option) => {
        if (updatedSelectedOptions.some(({ id }) => id === option.id))
          return { ...option, isSelected: true };
        return { ...option, isSelected: false };
      });
      setValues(updatedValues);
    }
  };

  return (
    <div className="inline-flex w-[576px] items-end justify-start gap-2">
      <div className="bg-gray-0 flex min-w-fit items-center justify-start gap-2.5 rounded-lg border border-gray-5 px-3 py-[7px]">
        <div className="flex items-start justify-start gap-0.5">
          <TextStyle variant="base-medium">{ruleType.label}</TextStyle>
        </div>
      </div>
      {displayType === 'searchableDropdown' && (
        <>
          <div className="inline-flex w-[68px] flex-col items-center justify-start gap-2.5 rounded-lg">
            <PermissionDropdown
              disabled={disabled}
              tooltipText={tooltipText}
              triggerButtonVariant="large"
              triggerClassName="space-between"
              groupedDropdownOptions={[
                {
                  id: 'conditionOptions',
                  options: conditionOptions,
                },
              ]}
              selectedOption={selectedCondition}
              onSelected={() => {
                setSelectedCondition(
                  selectedCondition.id === isOption.id ? isNotOption : isOption
                );
              }}
              contentClassName="w-[var(--radix-dropdown-menu-trigger-width)]"
            />
          </div>
          <div className="bg-gray-0 inline-flex min-w-[276px] shrink grow basis-0 flex-col items-start justify-start gap-1">
            <SearchableDropdownWithChips
              className="w-full"
              options={values}
              onChange={onRuleOptionsSelect}
              onDelete={onRuleOptionsDelete}
              toggleButtonLabel={formatMessage(messages.chooseARule, {
                rule: ruleType.label,
              })}
              inputPlaceHolderText={formatMessage(messages.chooseARule, {
                rule: ruleType.label,
              })}
            />
          </div>
        </>
      )}
      {displayType === 'booleanDropdown' && (
        <div className="inline-flex grow flex-col items-center justify-start gap-2.5 rounded-lg">
          <PermissionDropdown
            disabled={disabled}
            tooltipText={tooltipText}
            triggerButtonVariant="large"
            triggerClassName="space-between"
            groupedDropdownOptions={[
              {
                id: 'managerConditionOptions',
                options: managerConditionOptions,
              },
            ]}
            selectedOption={selectedCondition}
            onSelected={setSelectedCondition}
            contentClassName="w-[var(--radix-dropdown-menu-trigger-width)]"
          />
        </div>
      )}
      <IconButton
        onClick={() => {
          onRuleConfirm({
            selectedRules: selectedOptions,
            ruleType,
            condition: selectedCondition.id,
          });
          setSelectedOptions([]);
        }}
        variation="primary"
        size="large"
        disabled={
          displayType !== 'booleanDropdown' && selectedOptions.length === 0
        }
        data-testid="nonMemberRuleSelectorConfirmButton"
      >
        <CheckIcon className="text-gray-0 h-6 w-6" />
      </IconButton>
      <IconButton
        onClick={() => {
          onRuleDelete();
          setSelectedOptions([]);
        }}
        variation="tertiaryLite"
        size="large"
      >
        <TrashIcon className="text-gray-0 h-5 w-5" />
      </IconButton>
    </div>
  );
}
