import { useEffect } from 'react';

import { SplitNames } from '../constants/SplitNames';
import { useFeatureSplit } from '../split/useFeatureSplit';

declare global {
  // eslint-disable-next-line @typescript-eslint/consistent-type-definitions
  interface Window {
    googleTranslateElementInit: () => void;
  }

  // eslint-disable-next-line @typescript-eslint/no-namespace
  namespace google {
    // eslint-disable-next-line @typescript-eslint/no-namespace
    namespace translate {
      // eslint-disable-next-line @typescript-eslint/no-extraneous-class
      class TranslateElement {
        constructor(
          options: TranslateElementOptions,
          container: string | HTMLElement
        );
      }

      type TranslateElementOptions = {
        layout?: number;
        pageLanguage: string;
        autoDisplay?: boolean;
        includedLanguages?: string;
      };
    }
  }
}

export function useGoogleTranslate() {
  const { isTreatmentActive: googleTranslateEnabled } = useFeatureSplit(
    SplitNames.GoogleTranslate
  );

  function googleTranslateElementInit() {
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    if (google.translate) {
      new google.translate.TranslateElement(
        {
          pageLanguage: 'en',
          includedLanguages: 'en,es,it,fr',
        },
        'google_translate_element'
      );
    }
  }

  useEffect(() => {
    const googleScript = document.getElementById('googleTranslate');
    if (!googleScript && Boolean(googleTranslateEnabled)) {
      const addScript = document.createElement('script');
      addScript.setAttribute(
        'src',
        '//translate.google.com/translate_a/element.js?cb=googleTranslateElementInit'
      );
      addScript.setAttribute('id', 'googleTranslate');
      document.body.appendChild(addScript);
      window.googleTranslateElementInit = googleTranslateElementInit;
    }
  }, [googleTranslateEnabled]);
}
