import type {
  AmazonRedemptionFormDetails,
  AmazonRewardDetailsForm,
  AmazonRewardDetailsOption,
  ShippingCountry,
  ShippingDetailsForm as ShippingDetailsFormType,
  ShippingState,
} from '@assembly-web/services';
import { useCallback, useState } from 'react';

import { AmazonRewardFormDetails } from './AmazonRewardDetailsForm';
import { ShippingDetailsForm } from './ShippingDetailsForm';

const getInitialShippingFormData = (selectedCountry: string) => ({
  firstName: '',
  lastName: '',
  country: {
    id: selectedCountry === 'US' ? 'US' : 'CA',
    name: selectedCountry === 'US' ? 'United States' : 'Canada',
    value: selectedCountry === 'US' ? 'US' : 'CA',
  },
  state: undefined,
  company: '',
  address: '',
  secondaryAddress: '',
  city: '',
  zip: '',
  phoneNumber: '',
});

export const AmazonRedemptionForm = ({
  formStep,
  amazonRewardDetails,
  onSubmitForm,
  shippableCountries,
  stateList,
  onCountryChange,
  onAmazonFormUpdate,
  onShippingFormUpdate,
  isStateListLoading,
  isDetailsLoading,
  selectedCountry,
  amazonFormData,
  selectedVariationId,
}: {
  formStep: string;
  amazonRewardDetails: AmazonRewardDetailsOption[];
  onSubmitForm: (shippingDetails: AmazonRedemptionFormDetails) => void;
  shippableCountries: ShippingCountry[] | undefined;
  stateList: ShippingState[] | undefined;
  onCountryChange: (country: string) => void;
  onAmazonFormUpdate: (
    info: AmazonRewardDetailsForm,
    hasErrors: boolean
  ) => void;
  onShippingFormUpdate: (
    data: ShippingDetailsFormType,
    hasErrors: boolean
  ) => void;
  isStateListLoading: boolean;
  isDetailsLoading: boolean;
  selectedCountry: string;
  amazonFormData: AmazonRewardDetailsForm;
  selectedVariationId: string | undefined;
}) => {
  const [shippingFormData, setShippingFormData] =
    useState<ShippingDetailsFormType>(
      getInitialShippingFormData(selectedCountry)
    );
  const [formErrors, setFormErrors] = useState({
    amazonRewardErrors: {},
    shippingErrors: {},
  });

  const handleAmazonFormUpdate = useCallback(
    (data: AmazonRewardDetailsForm, errors: Record<string, unknown>) => {
      setFormErrors((prevErrors) => ({
        ...prevErrors,
        amazonRewardErrors: errors,
      }));
      onAmazonFormUpdate(data, Object.keys(errors).length !== 0);
    },
    [onAmazonFormUpdate]
  );

  const handleShippingFormUpdate = useCallback(
    (
      data: ShippingDetailsFormType,
      errors: Record<string, unknown>,
      markFormInvalid = false
    ) => {
      setShippingFormData(data);
      setFormErrors((prevErrors) => ({
        ...prevErrors,
        shippingErrors: errors,
      }));
      const isFormInvalid = Object.keys(errors).length !== 0 || markFormInvalid;
      onShippingFormUpdate(data, isFormInvalid);
    },
    [onShippingFormUpdate]
  );

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    const combinedData: AmazonRedemptionFormDetails = {
      ...amazonFormData,
      ...shippingFormData,
    };
    onSubmitForm(combinedData);
  };

  const handleCountryChange = (country: ShippingCountry) => {
    onCountryChange(country.value as string);
    const { firstName, lastName } = shippingFormData;
    handleShippingFormUpdate(
      {
        ...getInitialShippingFormData(selectedCountry),
        firstName,
        lastName,
        country: {
          id: country.id,
          name: country.name,
          value: country.value,
        },
        state: {
          id: '',
          name: '',
          value: '',
        },
      },
      {},
      true
    );
  };

  return (
    <form
      onSubmit={handleSubmit}
      id="amazon-form"
      className="flex flex-col gap-4"
      data-testid="amazon-form-component"
    >
      {formStep === 'amazon-reward-details' && (
        <AmazonRewardFormDetails
          rewardDetails={amazonRewardDetails}
          onUpdate={handleAmazonFormUpdate}
          formErrors={formErrors.amazonRewardErrors}
          formData={amazonFormData}
          hasMatchingVariation={Boolean(selectedVariationId)}
          isDetailsLoading={isDetailsLoading}
        />
      )}
      {formStep === 'shipping-details' && (
        <ShippingDetailsForm
          shippableCountries={shippableCountries}
          stateList={stateList}
          onCountryChange={handleCountryChange}
          onUpdate={handleShippingFormUpdate}
          formErrors={formErrors.shippingErrors}
          formData={shippingFormData}
          isStateListLoading={isStateListLoading}
          isAmazonForm
        />
      )}
    </form>
  );
};
