import { type ChangeEvent, type FormEvent, useState } from 'react';
import { defineMessages, useIntl } from 'react-intl';

import { Button } from '../../../../../DesignSystem/Inputs/Button';
import { TextField } from '../../../../../DesignSystem/Inputs/TextField';
import { URL_REGEX } from '../utils/regexUtils';

const messages = defineMessages({
  text: {
    defaultMessage: 'Text',
    id: 'aA8bDw',
  },
  url: {
    defaultMessage: 'Link',
    id: 'JBWS0c',
  },
  textPlaceholder: {
    defaultMessage: 'Add text',
    id: '8hvuZ8',
  },
  urlPlaceholder: {
    defaultMessage: 'Type or paste a link starting with https://',
    id: '1G9sU9',
  },
  save: {
    defaultMessage: 'Save',
    id: 'jvo0vs',
  },
});

type AddLinkModalProps = {
  selectedText: string | undefined;
  selectedURL: string;
  onSubmit: (args: { url: string; title: string }) => void;
};

export function AddLinkModal({
  onSubmit,
  selectedText,
  selectedURL,
}: AddLinkModalProps) {
  const { formatMessage } = useIntl();
  const [url, setUrl] = useState(selectedURL);
  const [isUrlValid, setIsUrlValid] = useState(true);

  const handleURLChange = (e: ChangeEvent<HTMLInputElement>) => {
    setUrl(e.target.value);
    if (URL_REGEX.test(e.target.value) || e.target.value === '') {
      setIsUrlValid(true);
    } else {
      setIsUrlValid(false);
    }
  };

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    e.stopPropagation();

    const value = new FormData(e.target as HTMLFormElement);
    const title = value.get('link-title') ?? selectedText;
    const url = value.get('link-url');

    if (typeof url === 'string' && typeof title === 'string') {
      onSubmit({ url, title });
    }
  };

  return (
    <div
      className="mb-2 min-w-[320px] max-w-[392px]"
      data-floating-modal="link"
    >
      <form className="w-full" onSubmit={handleSubmit}>
        <TextField
          name="link-title"
          label={formatMessage(messages.text)}
          defaultValue={selectedText}
          placeholder={formatMessage(messages.textPlaceholder)}
          isInvalid={false}
          invalidText=""
          disabled={Boolean(selectedText)}
          inputClassName="mt-1 mb-4 placeholder:text-gray-8 text-sm"
          labelClassName="text-sm font-medium"
          id="link-title"
        />
        <div className="flex items-end gap-3">
          <TextField
            name="link-url"
            label={formatMessage(messages.url)}
            value={url}
            onChange={handleURLChange}
            placeholder={formatMessage(messages.urlPlaceholder)}
            isInvalid={!isUrlValid}
            invalidText=""
            inputClassName="flex-1 mt-1 placeholder:text-gray-8 text-sm"
            labelClassName="text-sm font-medium mb-3"
            required
            id="link-url"
          />
          <Button variation="primary" disabled={!isUrlValid}>
            {formatMessage(messages.save)}
          </Button>
        </div>
      </form>
    </div>
  );
}
