import type { ReactionTypes } from '@assembly-web/services';
import { Emoji } from 'emoji-mart';
import { twMerge } from 'tailwind-merge';

export type ReactionEmoji = {
  name: string;
  value: string;
  type: ReactionTypes;
  displayName?: string;
};

type ReactionsEmojiProps = {
  reaction: ReactionEmoji;
  size: 16 | 20;
  count: number;
  isSelected: boolean;
  className?: string;
};

export const ReactionEmoji = ({
  reaction,
  size,
  count,
  isSelected,
  className,
}: ReactionsEmojiProps) => {
  return (
    <div
      className="pointer-events-none flex h-fit select-none items-center justify-center gap-1"
      data-testid={reaction.name}
    >
      <span aria-hidden className="emoji-mart-emoji">
        <Emoji emoji={reaction.name} size={size} />
      </span>
      <span
        className={twMerge(
          'text-sm',
          isSelected ? 'text-primary-6' : 'text-gray-8',
          className
        )}
      >
        {count}
      </span>
    </div>
  );
};
