import {
  type DoraReportingCategory,
  withoutDefaultEventBehavior,
} from '@assembly-web/services';
import {
  ArrowDownTrayIcon,
  ArrowsPointingInIcon,
  BookmarkIcon,
  ChartBarSquareIcon,
  MagnifyingGlassMinusIcon,
  MagnifyingGlassPlusIcon,
  XMarkIcon,
} from '@heroicons/react/24/outline';
import * as Dialog from '@radix-ui/react-dialog';
import * as Slider from '@radix-ui/react-slider';
import { AnimatePresence, motion, useMotionValue } from 'framer-motion';
import type { WheelEventHandler } from 'react';
import { useEffect, useRef, useState } from 'react';
import { defineMessages, type IntlShape, useIntl } from 'react-intl';
import { twMerge } from 'tailwind-merge';

import { LoadingSpinner } from '../../../DesignSystem/Feedback/Icons/LoadingSpinner';
import { TextStyle } from '../../../DesignSystem/Feedback/TextStyle';
import { Tooltip } from '../../../DesignSystem/Feedback/Tooltip';
import { Button } from '../../../DesignSystem/Inputs/Button';
import { IconButton } from '../../../DesignSystem/Inputs/IconButton';
import { useDeviceInfo } from '../../hooks/useDeviceInfo';

const title = defineMessages({
  recognitionStats: {
    defaultMessage: 'Recognition stats chart',
    id: 'bi/wQV',
  },
  rewardRedemptions: {
    defaultMessage: 'Reward redemptions chart',
    id: 'qZG/a0',
  },
  flowEngagement: {
    defaultMessage: 'Flow engagement chart',
    id: 'H0rsN8',
  },
  milestones: {
    defaultMessage: 'Milestones chart',
    id: '+DECBh',
  },
  flowResponses: {
    defaultMessage: 'Flow responses chart',
    id: 'ouEL8O',
  },
});

const messages = defineMessages({
  downloadChartTooltip: {
    defaultMessage: 'Download chart',
    id: 'Jh4kBL',
  },
  close: {
    defaultMessage: 'Close',
    id: 'rbrahO',
  },
  adjustZoomLevel: {
    defaultMessage: 'Adjust zoom level',
    id: 'hEhE3v',
  },
  zoomLevel: {
    defaultMessage: 'Zoom level',
    id: '6GminY',
  },
  zoomIn: {
    defaultMessage: 'Zoom in',
    id: 'xbi38c',
  },
  zoomOut: {
    defaultMessage: 'Zoom out',
    id: '/UnJ3S',
  },
  resetZoom: {
    defaultMessage: 'Reset zoom',
    id: 'nQhPfW',
  },
  savePromptCTA: {
    defaultMessage: 'Save prompt',
    id: '/35ErQ',
  },
  savedPromptCTA: {
    defaultMessage: 'Saved',
    id: 'fsB/4p',
  },
  savingPromptCTA: {
    defaultMessage: 'Saving prompt',
    id: 'bjUHAT',
  },
});

type DoraReportChartPreviewerProps = {
  imageURL: string;
  reportCategory: DoraReportingCategory;
  isOpen: boolean;
  isSavingPrompt: boolean;
  isSavedPrompt: boolean;
  onSavePrompt: () => void;
  onClose: () => void;
  onDownloadChart: () => void;
};

// We pass formatMessage since we wont have the provider when rendering this using SSR
export function DoraReportChartTitle({
  reportCategory,
  formatMessage,
}: {
  reportCategory: DoraReportingCategory;
  formatMessage: IntlShape['formatMessage'];
}) {
  const isRecognition = reportCategory === 'recognition';
  const isFlows = reportCategory === 'flows';
  const isRewardRedemptions = reportCategory === 'reward_redemption';
  const isMilestone = reportCategory === 'users';

  return (
    <div className="inline-flex w-full items-center justify-between py-1 pl-1 pr-2">
      <div className="inline-flex items-center justify-center gap-1">
        <div
          className={twMerge(
            'inline-flex h-5 w-5 items-center justify-center rounded-sm',
            isRecognition
              ? 'bg-pink-gradient'
              : isFlows
                ? 'bg-blue-gradient'
                : isRewardRedemptions
                  ? 'bg-purple-gradient'
                  : 'bg-cyan-gradient'
          )}
        >
          <ChartBarSquareIcon
            className={twMerge(
              'h-4 w-4',
              isRecognition
                ? 'text-magenta-9'
                : isFlows
                  ? 'text-blue-9'
                  : isRewardRedemptions
                    ? 'text-purple-9'
                    : 'text-cyan-9'
            )}
          />
        </div>
        <TextStyle variant="xs-medium">
          {isRecognition
            ? formatMessage(title.recognitionStats)
            : isFlows
              ? formatMessage(title.flowEngagement)
              : isRewardRedemptions
                ? formatMessage(title.rewardRedemptions)
                : isMilestone
                  ? formatMessage(title.milestones)
                  : formatMessage(title.flowResponses)}
        </TextStyle>
      </div>
    </div>
  );
}

export function downloadDoraReportChart(
  imageURL: string,
  reportCategory: DoraReportingCategory
) {
  fetch(imageURL, {
    mode: 'cors',
    credentials: 'include',
  })
    .then((response) => response.blob())
    .then((blob) => {
      const url = URL.createObjectURL(blob);

      const link = document.createElement('a');
      link.href = url;
      link.download = `${reportCategory}.png`;

      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      URL.revokeObjectURL(url);
    })
    .catch(() => window.open(imageURL, '_blank', 'noopener,noreferrer'));
}

export function DoraReportChartPreviewer({
  imageURL,
  reportCategory,
  isOpen,
  onClose,
  isSavedPrompt,
  isSavingPrompt,
  onSavePrompt,
  onDownloadChart,
}: DoraReportChartPreviewerProps) {
  const { formatMessage } = useIntl();
  const notMobile = useDeviceInfo().deviceType !== 'mobile';

  const defaultButtonRef = useRef<HTMLButtonElement>(null);

  const scale = useMotionValue(1);
  const x = useMotionValue(0);
  const y = useMotionValue(0);

  const [zoomLevel, setZoomLevel] = useState(100);

  const zoomStep = 5;
  const minZoomLevel = 50;
  const maxZoomLevel = 200;

  useEffect(() => {
    scale.set(zoomLevel / 100);
  }, [zoomLevel, scale]);

  const handleZoomReset = () => {
    scale.set(1);
    x.set(0);
    y.set(0);
    setZoomLevel(100);
  };

  const handleZoomIn = () => {
    const newZoomLevel = zoomLevel + zoomStep;
    if (newZoomLevel <= maxZoomLevel) {
      setZoomLevel(newZoomLevel);
    }
  };

  const handleZoomOut = () => {
    const newZoomLevel = zoomLevel - zoomStep;
    if (newZoomLevel >= minZoomLevel) {
      setZoomLevel(newZoomLevel);
    }
  };

  const handleWheel: WheelEventHandler<HTMLImageElement> = (event) => {
    event.preventDefault();
    const newScale = scale.get() - event.deltaY * 0.001;
    if (newScale >= 1) {
      setZoomLevel(newScale * 100);
    }
  };

  useEffect(() => defaultButtonRef.current?.focus(), [isOpen]);

  return (
    <Dialog.Root
      open={isOpen}
      onOpenChange={(open) => {
        if (!open) {
          handleZoomReset();
          onClose();
        }
      }}
    >
      <AnimatePresence>
        {Boolean(isOpen) && (
          <Dialog.Portal forceMount>
            <Dialog.Overlay
              asChild
              forceMount
              className="fixed inset-0 z-50 bg-neutral-secondary bg-opacity-75"
            >
              <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{
                  opacity: 0,
                  transition: { duration: 0.2, ease: 'easeIn' },
                }}
                transition={{ duration: 0.3, ease: 'easeOut' }}
              />
            </Dialog.Overlay>
            <Dialog.Content asChild forceMount>
              <motion.div
                initial={{
                  opacity: 0,
                  x: '-50%',
                  y: '-50%',
                  ...(notMobile && { scale: 0.95 }),
                }}
                animate={{
                  opacity: 1,
                  x: '-50%',
                  y: '-50%',
                  ...(notMobile && { scale: 1 }),
                }}
                exit={{
                  opacity: 0,
                  x: '-50%',
                  y: '-50%',
                  ...(notMobile && { scale: 0.95 }),
                  transition: { duration: 0.2, ease: 'easeIn' },
                }}
                transition={{ duration: 0.3, ease: 'easeOut' }}
                className="fixed left-[50%] top-[50%] z-50 h-full w-full max-w-lg -translate-x-1/2 -translate-y-1/2 transform overflow-hidden rounded-md bg-gray-1 text-left shadow-xl-down focus:outline-none md:h-[95vh] md:max-h-[95vh] md:w-[95vw] md:max-w-7xl"
              >
                <header className="flex h-10 w-full items-center justify-between border-b border-gray-5 bg-gray-1 p-1">
                  <div>
                    <Dialog.Title asChild className="leading-6">
                      <DoraReportChartTitle
                        formatMessage={formatMessage}
                        reportCategory={reportCategory}
                      />
                    </Dialog.Title>
                  </div>
                  <div className="flex h-fit items-center justify-center gap-2">
                    {reportCategory !== 'flow_responses' && (
                      <Button
                        onClick={onSavePrompt}
                        variation={isSavedPrompt ? 'primary' : 'secondaryLite'}
                        className={twMerge(
                          'flex h-6 w-max items-center !gap-1 px-1 py-1',
                          isSavedPrompt &&
                            'disabled:!bg-primary-6 disabled:!text-gray-1'
                        )}
                        disabled={isSavedPrompt || isSavingPrompt}
                      >
                        {isSavingPrompt ? (
                          <LoadingSpinner className="h-3 w-3" />
                        ) : (
                          <BookmarkIcon
                            className={twMerge(
                              'h-4 w-4',
                              isSavedPrompt && 'fill-gray-1'
                            )}
                          />
                        )}
                        <TextStyle variant="sm-regular">
                          {formatMessage(
                            isSavingPrompt
                              ? messages.savingPromptCTA
                              : isSavedPrompt
                                ? messages.savedPromptCTA
                                : messages.savePromptCTA
                          )}
                        </TextStyle>
                      </Button>
                    )}
                    <Tooltip
                      tooltipText={formatMessage(messages.downloadChartTooltip)}
                    >
                      <button
                        onClick={withoutDefaultEventBehavior(() => {
                          onDownloadChart();
                          downloadDoraReportChart(imageURL, reportCategory);
                        })}
                        className="group cursor-pointer rounded-[8px] p-1 hover:bg-gray-3 focus:outline-none focus:ring-2 focus:ring-primary-6 focus:ring-offset-2 focus-visible:outline-none"
                      >
                        <ArrowDownTrayIcon className="h-4 w-4 text-gray-8 group-hover:bg-gray-3" />
                      </button>
                    </Tooltip>
                    <Dialog.Close asChild>
                      <IconButton
                        variation="tertiaryLite"
                        size="xSmall"
                        data-testid="modalCloseButton"
                        tooltip={formatMessage(messages.close)}
                      >
                        <span className="sr-only">
                          {formatMessage(messages.close)}
                        </span>
                        <XMarkIcon className="h-4 w-4" aria-hidden="true" />
                      </IconButton>
                    </Dialog.Close>
                  </div>
                </header>

                <div className="relative flex h-[calc(100%-2.5rem)] w-full justify-center bg-gray-3">
                  <motion.img
                    src={imageURL}
                    alt=""
                    className="absolute bottom-0 left-0 right-0 top-0 m-auto max-h-full max-w-full object-contain"
                    style={{ x, y, scale }}
                    drag
                    dragConstraints={{
                      left: -500,
                      right: 500,
                      top: -500,
                      bottom: 500,
                    }}
                    onWheel={handleWheel}
                  />
                  <div className="absolute bottom-2 flex h-8 w-[210px] items-center justify-center space-x-2 overflow-hidden rounded-lg border-gray-5 bg-gray-3 shadow-base-down">
                    <IconButton
                      variation="secondaryLite"
                      size="xSmall"
                      className="border-none bg-transparent p-2 hover:bg-gray-1 focus:bg-gray-1"
                      tooltip={formatMessage(messages.zoomOut)}
                      onClick={handleZoomOut}
                    >
                      <MagnifyingGlassMinusIcon className="h-4 w-4" />
                    </IconButton>

                    <Slider.Root
                      className="relative flex h-5 w-[100px] touch-none select-none items-center"
                      min={minZoomLevel}
                      max={maxZoomLevel}
                      step={zoomStep}
                      value={[zoomLevel]}
                      onValueChange={(values) => setZoomLevel(values[0])}
                    >
                      <Slider.Track className="relative h-[3px] grow rounded-full bg-gray-5">
                        <Slider.Range className="absolute h-full rounded-full bg-gray-1" />
                      </Slider.Track>
                      <Tooltip
                        tooltipText={formatMessage(messages.adjustZoomLevel)}
                      >
                        <Slider.Thumb
                          className="block h-4 w-4 rounded-full bg-gray-1 shadow-[0_1px_8px] shadow-gray-8 focus:shadow-[0_0_0_3px] focus:shadow-primary-5 focus:outline-none"
                          aria-label={formatMessage(messages.zoomLevel)}
                        />
                      </Tooltip>
                    </Slider.Root>

                    <IconButton
                      variation="secondaryLite"
                      size="xSmall"
                      className="border-none bg-transparent p-2 hover:bg-gray-1 focus:bg-gray-1"
                      tooltip={formatMessage(messages.zoomIn)}
                      onClick={handleZoomIn}
                      ref={defaultButtonRef}
                    >
                      <MagnifyingGlassPlusIcon className="h-4 w-4" />
                    </IconButton>

                    <div className="h-8 w-[1px] bg-gray-5 px-[0.5px]" />
                    <IconButton
                      variation="secondaryLite"
                      size="xSmall"
                      className="border-none bg-transparent p-2 hover:bg-gray-1 focus:bg-gray-1"
                      tooltip={formatMessage(messages.resetZoom)}
                      onClick={handleZoomReset}
                    >
                      <ArrowsPointingInIcon className="h-4 w-4" />
                    </IconButton>
                  </div>
                </div>
              </motion.div>
            </Dialog.Content>
          </Dialog.Portal>
        )}
      </AnimatePresence>
    </Dialog.Root>
  );
}
