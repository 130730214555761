import type { FC } from 'react';
import { defineMessages, useIntl } from 'react-intl';

import { TextStyle } from '../../../DesignSystem/Feedback/TextStyle';

export type GreetingProps = {
  firstName: string;
  hour: number;
};

const message = defineMessages({
  greeting: {
    defaultMessage:
      '{time, select, morning {☀️} afternoon {🏙️} evening {🌙} other {️☀️}} Good {time, select, morning {morning} afternoon {afternoon} evening {evening} other {night}}, {name}!',
    id: 'pAyItI',
  },
});

export const Greeting: FC<GreetingProps> = ({ firstName, hour }) => {
  const { formatMessage } = useIntl();

  return (
    <TextStyle variant="lg-bold">
      {formatMessage(message.greeting, {
        name: firstName,
        time: (() => {
          if (hour >= 3 && hour < 12) {
            return 'morning';
          }
          if (hour >= 12 && hour < 18) {
            return 'afternoon';
          }
          return 'evening';
        })(),
      })}
    </TextStyle>
  );
};
