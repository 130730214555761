import { getRoutePath } from '@assembly-web/services';
import React from 'react';
import type { To } from 'react-router-dom';
import { Link } from 'react-router-dom';

type AssemblyLinkProps = React.ComponentProps<typeof Link> &
  React.RefAttributes<HTMLAnchorElement>;

const AssemblyLink = React.forwardRef<HTMLAnchorElement, AssemblyLinkProps>(
  function AssemblyLink({ to, ...rest }, ref) {
    const modifiedPath: To =
      typeof to === 'string'
        ? getRoutePath(to, {
            directUrl: false,
            takeURLParams: true,
          })
        : {
            ...to,
            pathname: getRoutePath(to.pathname, {
              directUrl: false,
              takeURLParams: true,
            }),
          };

    return <Link to={modifiedPath} ref={ref} {...rest} />;
  }
);

AssemblyLink.displayName = 'AssemblyLink';

export { AssemblyLink };
