import type { ShippingDetailsForm } from '@assembly-web/services';
import { defineMessage, useIntl } from 'react-intl';

import { TextStyle } from '../../../DesignSystem/Feedback/TextStyle';
import { Heading } from '../../../DesignSystem/Layout/Heading';

const header = defineMessage({
  defaultMessage: 'Ship to',
  id: '+JsDiH',
});

export function ShipToDetails({ details }: { details: ShippingDetailsForm }) {
  const { formatMessage } = useIntl();

  return (
    <div
      className="rounded-lg bg-gray-3 p-4"
      data-testid="swag-shipping-details"
    >
      <div>
        <Heading as="h6">{formatMessage(header)}:</Heading>
        <div>
          <TextStyle variant="xs-regular">
            <span>
              <p>
                {details.firstName} {details.lastName},{' '}
              </p>
              <p>
                {Boolean(details.company) && <span>{details.company}, </span>}
              </p>
              <p>
                {Boolean(details.address) && <span>{details.address}, </span>}
              </p>
              <p>
                {Boolean(details.secondaryAddress) && (
                  <span>{details.secondaryAddress}, </span>
                )}
              </p>
              <p>
                {Boolean(details.city) && <span>{details.city}, </span>}
                {details.state?.name ? (
                  <span>{details.state.name}, </span>
                ) : null}
                {Boolean(details.country.name) && (
                  <span>{details.country.name}, </span>
                )}{' '}
                {Boolean(details.zip) && <span>{details.zip}</span>}
              </p>
            </span>
          </TextStyle>
        </div>
      </div>
    </div>
  );
}
