export type Replacement = {
  key: string;
  value: string;
};

export function replaceContentPlaceholders(
  text: string,
  replacements: Replacement[]
): string {
  const everyoneTagReplaceText = text.replace(
    /@__tag:everyone__@/g,
    '@everyone'
  );

  const taskReplacedText = replacements.reduce((acc, { key, value }) => {
    const pattern = `@__task:${key}__@`;
    return acc.split(pattern).join(`${value}`);
  }, everyoneTagReplaceText);

  return replacements.reduce((acc, { key, value }) => {
    const pattern = `@__${key}__@`;
    return acc.split(pattern).join(`@${value}`);
  }, taskReplacedText);
}
