import type { ReactNode } from 'react';

import { TextStyle } from '../../../../DesignSystem/Feedback/TextStyle';

type ModalTitleProps = {
  title: string;
  icon: ReactNode;
};

export function ModalTitle({ icon, title }: ModalTitleProps) {
  return (
    <span className="flex items-center">
      <span className="mr-2 flex items-center justify-center rounded-full bg-gray-3 p-1">
        {icon}
      </span>
      <TextStyle as="span" variant="lg-bold">
        {title}
      </TextStyle>
    </span>
  );
}
