import type {
  AssemblyCurrency,
  Member,
  ReactionDetails,
} from '@assembly-web/services';
import { AnimatePresence, motion } from 'framer-motion';
import { type MouseEvent, useEffect, useRef } from 'react';
import { useIntl } from 'react-intl';

import { TextStyle } from '../../../DesignSystem/Feedback/TextStyle';
import type { ToolbarItem } from '../../../DesignSystem/Feedback/Toolbar/Toolbar';
import { NoReplies, PostOrRepliesError } from '../../assets/images';
import type { MemberDetailsForViewProfile } from '../../Shared/AuthorButton';
import type { BoostOptions } from '../Editors/RepliesEditor/RepliesEditor';
import {
  ConversationCard,
  type ConversationCardMemberDetails,
  type ConversationCardProps,
} from './ConversationCard';
import { EmptyOrErrorStateTemplate } from './EmptyOrErrorStateTemplate';
import { messages } from './messages';
import { RepliesLoader } from './RepliesLoader';
import { getConversionCardVariant, getReplyCardToolbarItems } from './utils';

export type Reply = Pick<
  ConversationCardProps,
  'cardId' | 'messageContent' | 'isEdited' | 'reactions'
> & {
  version?: number;
  gifURL?: string;
  canEdit?: boolean;
  pointsEach?: number;
  taggedUsers?: Member[];
  messageNode?: React.ReactNode;
  boost?: { member: Member; points: number }[];
  memberDetails: ConversationCardMemberDetails<
    MemberDetailsForViewProfile & {
      isAnonymous: false;
      name: string;
    }
  >;
};

export type RepliesProps = {
  replies: Reply[];
  isError: boolean;
  isFetchingNextPage: boolean;
  isFetchingPreviousPage: boolean;
  postAuthor: string;
  isInitialLoading: boolean;
  hasNextPage: boolean;
  currentUserId: string;
  canDeleteReplies: boolean;
  fetchNextPage: () => void;
  hasPreviousPage: boolean;
  fetchPreviousPage: () => void;
  onMenuItemClick: (
    args: ToolbarItem & {
      cardId: string;
      isLegacyReply?: boolean;
    }
  ) => void;
  selectedReplyToDelete: string | null;
  editingReplyId: string | null;
  onMemberClick: (memberId: string) => void;
  activeCardId: string | null;
  totalReplyCount: number;
  handleReactionClick: (emoji: ReactionDetails, reply: Reply) => void;
  handleResetScroll?: () => void;
  containerRef: React.MutableRefObject<HTMLElement | null>;
  currencyDetails: AssemblyCurrency;
  handleInternalLinkClick?: (e: MouseEvent<HTMLAnchorElement>) => void;
  boostOptions: BoostOptions;
};

function FetchMoreReplies({
  hasMore,
  fetchMore,
  isFetching,
  fetchedReplyCount,
  totalReplyCount,
}: {
  hasMore: boolean;
  isFetching: boolean;
  fetchMore: () => void;
  fetchedReplyCount: number;
  totalReplyCount: number;
}) {
  const { formatMessage } = useIntl();

  if (isFetching) {
    return <RepliesLoader />;
  }

  if (hasMore) {
    return (
      <div className="flex w-full justify-between py-4 align-middle">
        <button
          onClick={fetchMore}
          className="ml-1 text-sm font-medium text-gray-8 underline"
        >
          {formatMessage(messages.viewMoreReplies)}
        </button>
        <TextStyle className="text-gray-8" variant="sm-regular">
          {formatMessage(messages.replyCountStatus, {
            fetchedReplyCount,
            totalReplyCount,
          })}
        </TextStyle>
      </div>
    );
  }
}

export function Replies(props: RepliesProps) {
  const {
    replies,
    isError,
    postAuthor,
    hasNextPage,
    fetchNextPage,
    currentUserId,
    boostOptions,
    onMemberClick,
    onMenuItemClick,
    isInitialLoading,
    canDeleteReplies,
    isFetchingNextPage,
    editingReplyId,
    selectedReplyToDelete,
    activeCardId,
    hasPreviousPage,
    fetchPreviousPage,
    isFetchingPreviousPage,
    totalReplyCount,
    handleReactionClick,
    handleResetScroll,
    containerRef,
    currencyDetails,
    handleInternalLinkClick,
  } = props;
  const { formatMessage } = useIntl();

  const effectRunRef = useRef(false);

  useEffect(() => {
    if (!activeCardId || activeCardId !== 'viewLastReply') {
      // if there is a focused comment, we don't want to scroll the list to the bottom
      effectRunRef.current = true;
      return;
    }

    if (containerRef.current && !effectRunRef.current) {
      effectRunRef.current = true;
      containerRef.current.scrollTo({
        behavior: 'instant',
        top: containerRef.current.scrollHeight,
      });
    }
  }, [activeCardId, containerRef]);

  if (isError && !isInitialLoading) {
    return (
      <EmptyOrErrorStateTemplate
        image={PostOrRepliesError}
        altText="replies-loading-error"
        heading={formatMessage(messages.repliesLoadingError)}
        subHeading={formatMessage(messages.refreshToTryAgain)}
      />
    );
  }

  if (!isError && !isInitialLoading && replies.length === 0) {
    return (
      <EmptyOrErrorStateTemplate
        image={NoReplies}
        altText="no-replies-found"
        heading={formatMessage(messages.beFirstToReply)}
        subHeading={formatMessage(messages.writeReplyBelow, {
          author: postAuthor,
        })}
      />
    );
  }

  const canShowReplies = !isError && !isInitialLoading && replies.length > 0;

  const getPreviousReplyUserId = (
    filteredReplies: Reply[],
    currentIndex: number
  ) => {
    if (currentIndex === 0) {
      return '';
    }
    const previousItem = filteredReplies[currentIndex - 1];

    return previousItem.memberDetails.memberID;
  };

  return (
    <motion.div className="flex flex-col items-start">
      {Boolean(isInitialLoading) && <RepliesLoader />}
      <FetchMoreReplies
        fetchMore={fetchNextPage}
        hasMore={hasNextPage}
        isFetching={isFetchingNextPage}
        fetchedReplyCount={replies.length}
        totalReplyCount={totalReplyCount}
      />
      <AnimatePresence>
        {Boolean(canShowReplies) &&
          replies.map((reply, idx) => {
            return (
              <ConversationCard
                index={idx}
                boost={reply.boost}
                boostOptions={boostOptions}
                variant={getConversionCardVariant({
                  currentReplyMemberId: reply.memberDetails.memberID,
                  previousReplyMemberId: getPreviousReplyUserId(replies, idx),
                })}
                key={reply.cardId}
                cardId={reply.cardId}
                canShowToolbar={!reply.cardId.includes('comment')}
                onMemberClick={onMemberClick}
                memberDetails={reply.memberDetails}
                messageContent={reply.messageContent}
                reactions={reply.reactions}
                onMenuItemClick={(args) => {
                  onMenuItemClick({
                    ...args,
                    cardId: reply.cardId,
                    isLegacyReply: reply.version !== 2,
                  });
                }}
                toolbarItems={getReplyCardToolbarItems({
                  formatMessage,
                  canDeleteReply:
                    currentUserId ===
                      (!reply.memberDetails.isAnonymous &&
                        reply.memberDetails.memberID) || canDeleteReplies,
                  canEditReply:
                    (currentUserId ===
                      (!reply.memberDetails.isAnonymous &&
                        reply.memberDetails.memberID) ||
                      Boolean(reply.canEdit)) &&
                    reply.version === 2,
                  hasPoints: Boolean(reply.boost?.length),
                })}
                onReactionClick={(emoji: ReactionDetails) => {
                  handleReactionClick(emoji, reply);
                }}
                currentMemberId={currentUserId}
                isDeleting={selectedReplyToDelete === reply.cardId}
                isEditing={editingReplyId === reply.cardId}
                isEdited={reply.isEdited}
                isActiveCard={reply.cardId === activeCardId}
                gifURL={reply.gifURL}
                messageNode={reply.messageNode}
                resetScroll={handleResetScroll}
                containerRef={containerRef}
                taggedUsers={reply.taggedUsers}
                currencyDetails={currencyDetails}
                pointsEach={{
                  icon: currencyDetails,
                  points: reply.pointsEach,
                }}
                handleInternalLinkClick={handleInternalLinkClick}
              />
            );
          })}
      </AnimatePresence>
      <FetchMoreReplies
        hasMore={hasPreviousPage}
        fetchMore={fetchPreviousPage}
        totalReplyCount={totalReplyCount}
        fetchedReplyCount={replies.length}
        isFetching={isFetchingPreviousPage}
      />
    </motion.div>
  );
}
