import type { ReactNode } from 'react';

import type {
  AmazonOrderResponse,
  AmazonRewardDetailsForm,
  ConfirmDetailsData,
  SearchableSelectOptionType,
  Variation,
} from './types/rewards';
import type { Denomination } from './types/searchIndex';

/**
 * Gets minimum and maximum currency values for a reward card wrt admin selected range.
 * @param exchangeRates country specific exachange rates
 * @param denominations possible denominations for the reward
 * @range the cost range selected by admin
 * @returns {{min: number, max: number} | null}
 */
export function getMinMaxInAdminRange(
  exchangeRates: Record<string, number>,
  isCharity: boolean,
  denominations?: Denomination[],
  range?: {
    min: number | null;
    max: number | null;
  }
): { min: number; max: number } | null {
  let initialCost = {
    min: Infinity,
    max: 0,
  };

  if (!isCharity) {
    denominations = denominations?.filter((deno) => {
      const usConvertedDenomination =
        deno.currencyCode === 'USD'
          ? deno
          : {
              ...deno,
              faceValue: deno.faceValue / exchangeRates[deno.currencyCode],
            };
      const rangeMin = range?.min ?? 0;
      const rangeMax = range?.max ?? 0;
      //Filtering items that fall under the given range
      if (rangeMin && rangeMax) {
        return (
          usConvertedDenomination.faceValue >= rangeMin &&
          usConvertedDenomination.faceValue <= rangeMax
        );
      } else if (rangeMin && !rangeMax) {
        return usConvertedDenomination.faceValue >= rangeMin;
      } else if (rangeMax && !rangeMin) {
        return usConvertedDenomination.faceValue <= rangeMax;
      } else {
        return true;
      }
    });
  }

  if (denominations?.length === 0) return null;

  //This is an edge case. A reward had no denominations in the payload.
  if (!denominations) denominations = [];
  denominations.map((deno) => {
    if (range?.min && range.max) {
      if (
        deno.faceValue / exchangeRates[deno.currencyCode] >= range.min &&
        deno.faceValue / exchangeRates[deno.currencyCode] <= range.max
      )
        initialCost.min = Math.min(initialCost.min, deno.faceValue);
      initialCost.max = Math.max(initialCost.max, deno.faceValue);
    } else {
      initialCost.min = Math.min(initialCost.min, deno.faceValue);
      initialCost.max = Math.max(initialCost.max, deno.faceValue);
    }
  });
  return initialCost;
}

export const getCarrotsForCurrencyExchange = (
  faceValue: number,
  exchangeRate: number,
  currencyExchangeRate: number
): number => {
  const carrotValue = Number(
    (faceValue * exchangeRate) / currencyExchangeRate
  ).toFixed(2);
  return Math.ceil(parseFloat(carrotValue));
};

export const getCurrencyExchangeRate = (
  currencyCode: string,
  exchangeRates: Record<string, number>
): number => {
  return currencyCode === 'USD' ? 1 : exchangeRates[currencyCode];
};

function convertOptionsToVariations(
  selectedOptions: Record<string, string | undefined>[]
) {
  const sortedMap: Record<string, string> = {};

  selectedOptions.forEach((obj) => {
    const { index, value } = parseIndexAndValue(obj);

    sortedMap[index] = value.toString();
  });

  const sortedArray = Object.keys(sortedMap)
    .sort((a, b) => parseInt(a) - parseInt(b))
    .map((index) => ({
      index: parseInt(index),
      value: parseInt(sortedMap[index]),
    }));

  return sortedArray;
}

function parseIndexAndValue(obj: Record<string, string | undefined>) {
  const key = Object.keys(obj)[0];
  const pair = obj[key];
  const [index, value] = pair
    ? pair.split(',').map((pair) => parseInt(pair.split(':')[1]))
    : ['', ''];
  return { index, value };
}

export function getSelectedVariationId(
  data: AmazonRewardDetailsForm,
  variations: Variation[]
) {
  const updatedValues = Object.entries(data)
    .filter((item) => item[0] !== 'quantity')
    .map((val) => {
      const value = val as [string, SearchableSelectOptionType | undefined];
      return { [value[0]]: value[1]?.id };
    });
  const selectedVariation = convertOptionsToVariations(updatedValues);
  const match = variations.find(
    (v) =>
      JSON.stringify(v.variationValues) === JSON.stringify(selectedVariation)
  )?.asin;
  return match;
}

export function getConfirmDataDetails(
  amazonOrderResponse: AmazonOrderResponse | undefined,
  exchangeRate: number | undefined,
  exchangeRates: Record<string, number> | undefined
): ConfirmDetailsData {
  const totalCostForAmazonProductDetails = {
    shippingCost: 0,
    tax: 0,
    totalCost: 0,
    principalCost: 0,
    currencyCode: '',
  };

  if (amazonOrderResponse) {
    const amazonOrderResponseCharges = amazonOrderResponse.charge;
    amazonOrderResponseCharges.map((charge) => {
      totalCostForAmazonProductDetails.currencyCode =
        charge.amount.currencyCode;
      if (charge.type === 'SHIPPING') {
        totalCostForAmazonProductDetails.shippingCost = charge.amount.amount;
      }
      if (charge.type === 'TAX') {
        totalCostForAmazonProductDetails.tax = charge.amount.amount;
      }
      if (charge.type === 'PRINCIPAL') {
        totalCostForAmazonProductDetails.principalCost = charge.amount.amount;
      }
    });

    totalCostForAmazonProductDetails.totalCost =
      totalCostForAmazonProductDetails.shippingCost +
      totalCostForAmazonProductDetails.tax +
      totalCostForAmazonProductDetails.principalCost;
  }

  const principalCostInCarrots = getCarrotsForCurrencyExchange(
    totalCostForAmazonProductDetails.principalCost,
    exchangeRate ?? 1,
    getCurrencyExchangeRate(
      totalCostForAmazonProductDetails.currencyCode,
      exchangeRates ?? {}
    )
  );

  const shippingCostInCarrots = getCarrotsForCurrencyExchange(
    totalCostForAmazonProductDetails.shippingCost,
    exchangeRate ?? 1,
    getCurrencyExchangeRate(
      totalCostForAmazonProductDetails.currencyCode,
      exchangeRates ?? {}
    )
  );

  const taxCostInCarrots = getCarrotsForCurrencyExchange(
    totalCostForAmazonProductDetails.tax,
    exchangeRate ?? 1,
    getCurrencyExchangeRate(
      totalCostForAmazonProductDetails.currencyCode,
      exchangeRates ?? {}
    )
  );

  const totalCostInCarrots =
    principalCostInCarrots + shippingCostInCarrots + taxCostInCarrots;

  const confirmDetailsData = {
    totalCostInCarrots,
    principalCostInCarrots,
    shippingCostInCarrots,
    taxCostInCarrots,
    ...totalCostForAmazonProductDetails,
  };

  return confirmDetailsData;
}

export function getShippingAndTaxDetails(
  currencyIcon: ReactNode,
  confirmDetailsData: ConfirmDetailsData | undefined
) {
  return confirmDetailsData?.shippingCost
    ? `(${currencyIcon} ${confirmDetailsData.principalCostInCarrots} + ${currencyIcon} ${confirmDetailsData.shippingCostInCarrots} shipping ,${currencyIcon} ${confirmDetailsData.taxCostInCarrots} tax)`
    : `(${currencyIcon} ${confirmDetailsData?.principalCostInCarrots} ,${currencyIcon} ${confirmDetailsData?.taxCostInCarrots} tax, free shipping with Prime)`;
}

export function generateDescriptionString(
  amazonFormData: AmazonRewardDetailsForm
) {
  let description = '';
  for (const key in amazonFormData) {
    const value = amazonFormData[key] as SearchableSelectOptionType;
    if (key !== 'quantity') {
      description += `${key}: ${value.name}, `;
    } else {
      description += `Quantity: ${value.name}`;
    }
  }
  return description;
}
