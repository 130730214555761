import type { ReactNode } from 'react';

import type { AssemblyCurrency } from './currency';
import type { Member } from './domain';
import type { MemberDetails, ReactionDetails } from './flowTypes';
import type { ShareCriteria } from './shareSheet';

export enum ClaimStatus {
  Pending = 'PENDING',
  Approved = 'APPROVED',
  Denied = 'DENIED',
}

export type ChallengeState =
  | 'ACTIVE'
  | 'INACTIVE'
  | 'CLOSED'
  | 'QUEUED'
  | 'ARCHIVED';

export type ClaimState = 'PENDING' | 'APPROVED' | 'DENIED';

export type SelfClaimDetails = {
  claimLimit: number;
  totalEarnedPoints: number;
  claims: Claim[];
};

export type ChallengeCardClaimState = 'Claimed' | 'Pending';

export type ChallengeAPIResponse = {
  title: string;
  points: number;
  canEdit: boolean;
  type: 'STANDALONE';
  launchedAt: string;
  endedAt?: string;
  createdAt?: string;
  challengeId: string;
  state: ChallengeState;
  image?: FileDetails;
  claimsRemaining: number;
  claimButtonText: string;
  createdBy: MemberDetails;
  reactions: ReactionDetails[];
  selfClaimDetails: SelfClaimDetails | null;
  description?: {
    messageHtml: string;
  };
  interactionSettings?: {
    hideReplies: boolean;
    hideReactions: boolean;
  };
  proof: ChallengeProof;
  isMuted?: boolean;
  hasReplyDraft?: boolean;
  isBoostAllowed: boolean;
};

export type UserAvatar = {
  name: string;
  memberID: string;
  image?: string | undefined;
};

export type ChallengeDetails = {
  id: string;
  title: string;
  description?: string;
  state: ChallengeState;
  points: number;
  launchedAt?: string;
  currency?: AssemblyCurrency;
  image?: FileDetails;
  proof: ChallengeProof;
  endedAt?: string;
};

export type ChallengeProof = {
  isRequired: boolean;
  content?: {
    type: 'TEXT' | 'FILE';
    promptText: string;
  }[];
};

export type SubmitClaimFormType =
  | 'onlyOpenEnded'
  | 'onlyFileUpload'
  | 'fileUploadAndOpenEnded';

export type SubmitClaimFormDetails = {
  formType: SubmitClaimFormType;
  textFieldLabel?: string;
  fileFieldLabel?: string;
};

export type ClaimFilter = {
  state?: ClaimState;
  dateStart?: string;
  dateEnd?: string;
  from?: string;
  challengeState?: string;
  challengeIds?: string;
  claimBy?: {
    departments?: string;
  };
};

export type ClaimsRequestParams = {
  limit?: number;
  cursor?: string;
  filter: ClaimFilter;
};

export type Proof = {
  text: {
    plainText: string;
    messageTokens: string;
    messageHtml: string;
  };
  files: FileDetails[];
};

export type Challenge = {
  challengeId: string;
  title: string;
  state?: ChallengeState;
  proof?: ChallengeProof;
};

export type Claim = {
  claimId: string;
  claimDate?: string;
  points: number;
  state?: ClaimState;
  claimedBy?: MemberDetails & SelfClaimDetails;
  proof?: Proof;
  challenge?: Challenge;
  approvers?: MemberDetails[];
};

export type ClaimsResponse = {
  data: Claim[];
  metadata: {
    pagination: {
      cursor: {
        previous: string | null;
        next: string | null;
      };
    };
  };
};

export type BaseClaimMutationPayload = {
  claimId: string;
};

export type ClaimFilterType = 'claimedBy' | 'department';

export type Pagination = {
  cursor: {
    previous: string | null;
    next: string | null;
  };
};

export type Metadata = {
  pagination: Pagination;
};

export type ClaimedByFilterResponse = {
  filterBy: 'claimedBy';
  metadata: Metadata;
  data: MemberDetails[];
};

export type DepartmentFilterResponse = {
  filterBy: 'department';
  metadata: Metadata;
  data: string[];
};

export type ClaimsFilterResponse =
  | ClaimedByFilterResponse
  | DepartmentFilterResponse;

export type ClaimedChallenge = {
  challengeId: string;
  title: string;
  points: number;
  launchedAt: string;
  createdBy: MemberDetails;
  claimButtonText: string;
  showTurnOffSettings: boolean;
  interactionSettings: {
    hideReplies: boolean;
    hideReactions: boolean;
  };
  claimsRemaining: number;
  canEdit: boolean;
  proof: Proof;
  state: ChallengeState;
};

export type ClaimedChallengeAPIResponse = {
  totalCount: number;
  metadata: Metadata;
  data: ClaimedChallenge[];
};

export type FileDetails = {
  name: string;
  size: number;
  type: string;
  location: string;
  createdAt: string;
  originalName: string;
  thumbnails: {
    '32': string;
    '256': string;
    '512': string;
  };
  original?: {
    name: string;
    size: number;
    location: string;
  };
};

export type ChallengeDetailsAPIResponse = {
  title: string;
  description?: {
    plainText: string;
    messageTokens: string;
    messageHtml: string;
  };
  points: number;
  totalClaimLimit: number;
  individualClaimLimit: number;
  proof: ChallengeProof;
  createdBy: Member;
  claimButtonText: string;
  interactionSettings?: {
    hideReplies: boolean;
    hideReactions: boolean;
  };
  notificationSettings: {
    channels: string[];
  };
  launchNow: boolean;
  sharingRules: ShareCriteria;
  state: ChallengeState;
  image?: FileDetails;
};

export type ProofModalClaimData = {
  title: string;
  id: string;
  participantName: string;
  points: number;
  icon: ReactNode;
  currencyName: string;
  currencyPluralName: string;
  claimedAt: string;
  participant: MemberDetails;
  challengeId: string;
  handleApprove: (claimId: string) => void;
  handleReject: (claimId: string) => void;
  proof: Proof;
  isApproveLoading?: boolean;
  isDenyLoading?: boolean;
  challengeState: ChallengeState;
  onHeaderClick?: (action: ProofModalHeaderAction, id: string) => void;
  promptTexts?: PrompTextsData | undefined;
  claimStatus: ClaimState;
  currentUserId?: string;
};

export type ClaimTableRow = {
  claimDate: string;
  points: number;
  status: ClaimState;
  name: string;
  proof: Proof;
  challengeTitle: string;
  challengeId: string;
  department: string;
  jobTitle: string;
  location: string;
  approvers: MemberDetails[];
  claimId: string;
  userStatus?: string;
  challengeState: ChallengeState;
  participant: MemberDetails;
  promptTexts?: PrompTextsData | undefined;
  workLocation: string;
};

export type PrompTextsData = {
  isFileProofRequired: boolean;
  isTextProofRequired: boolean;
  textProofLabel?: string;
  fileProofLabel?: string;
};

export type ProofModalHeaderAction = 'PersonClicked' | 'ChallengeClicked';

export type ChallengePusherEvents =
  | 'CHALLENGE_CLAIM_APPROVED'
  | 'CHALLENGE_CLAIM_DENIED'
  | 'CHALLENGE_CLAIM_CREATED'
  | 'CHALLENGE_CREATED'
  | 'CHALLENGE_LAUNCHED'
  | 'CHALLENGE_UPDATED'
  | 'CHALLENGE_ENDED'
  | 'CHALLENGE_SETTINGS_UPDATED';

export type ChallengeSecondaryFilterParams =
  | 'challengeStatusType'
  | 'tableFilterExpanded'
  | 'claimStatus'
  | 'tableChallenge'
  | 'tableDateCreated'
  | 'tableDateStart'
  | 'tablePostedBy'
  | 'tableDateEnd'
  | 'tableChallengeStatusType'
  | 'tableDepartment';

export type TableFilters = {
  dateStart?: string;
  dateEnd?: string;
  from?: string;
  challengeState?: string;
  challengeIds?: string;
  claimBy?: {
    departments: string;
  };
};

export type PendingInsightsData = {
  claimId: string;
  claimDate: string;
  points: number;
  state: string;
  claimedBy: MemberDetails;
  proof: {
    text: {
      plainText: string;
      messageTokens: string;
      messageHtml: string;
    };
    files: [];
  };
};

export type ApprovedInsightsData = {
  claimDate: string;
  claimedBy: MemberDetails;
  claimCount: number;
};

export type UnClaimedInsightsData = MemberDetails;

export type ClaimInsightsFilterType = 'PENDING' | 'APPROVED' | 'UNCLAIMED';

export type InsightsTableRow = {
  claimDate: string;
  participant: MemberDetails;
  promptTexts?: PrompTextsData | undefined;
  lastClaimed: string;
  claimCount: number;
  lastClaimedDate: string;
  claimId: string;
  userStatus?: string;
  challengeState: ChallengeState;
  status: ClaimState;
  name: string;
  challengeTitle: string;
  points: number;
  proof: Proof;
  challengeId: string;
};

export type EditChallengeInteractionSettingsPayload = {
  challengeId: string;
  hideReplies: boolean;
  hideReactions: boolean;
};

export type ChallengeInteractionSettings = {
  id: string;
  title: string;
  hideReplies: boolean;
  hideReactions: boolean;
};
