import {
  DocumentIcon,
  PresentationChartBarIcon,
  TableCellsIcon,
} from '@heroicons/react/24/outline';

const FileTypeIcons = {
  other: <DocumentIcon className="h-7 w-7 text-gray-1" />,
  document: <DocumentIcon className="h-7 w-7 text-gray-1" />,
  spreadsheet: <TableCellsIcon className="h-7 w-7 text-gray-1" />,
  presentation: <PresentationChartBarIcon className="h-7 w-7 text-gray-1" />,
} as const;

type FileUploadType = keyof typeof FileTypeIcons;

const FileTypeBackgrounds: Record<FileUploadType, string> = {
  other: 'bg-error-7',
  document: 'bg-error-7',
  spreadsheet: 'bg-success-8',
  presentation: 'bg-success-8',
};

export function getFilePreview(mimeType: string) {
  const FileTypeMappings: Record<FileUploadType, string[]> = {
    document: ['application/pdf', 'text/csv'],
    spreadsheet: [
      'application/vnd.ms-excel',
      'application/vnd.apple.numbers',
      'application/vnd.oasis.opendocument.spreadsheet',
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    ],
    presentation: [
      'application/vnd.apple.keynote',
      'application/vnd.ms-powerpoint',
      'application/vnd.oasis.opendocument.presentation',
      'application/vnd.openxmlformats-officedocument.presentationml.presentation',
    ],
    other: [],
  };

  let type: FileUploadType = 'other';

  for (const [fileType, mimeTypes] of Object.entries(FileTypeMappings)) {
    if (mimeTypes.includes(mimeType)) {
      type = fileType as FileUploadType;
    }
  }

  return {
    icon: FileTypeIcons[type],
    background: FileTypeBackgrounds[type],
  };
}
