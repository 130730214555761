import type { ReactNode } from 'react';
import { twMerge } from 'tailwind-merge';

type ChildrenWithClassName = { children: ReactNode } & { className?: string };

const Root = ({ children, className }: ChildrenWithClassName) => (
  <div
    className={twMerge(
      'flex h-full flex-col overflow-hidden bg-gray-1 @container/body',
      className
    )}
  >
    {children}
  </div>
);

const Header = ({ children, className }: ChildrenWithClassName) => (
  <header
    className={twMerge(
      'flex items-start justify-center border-b border-gray-5 px-6 py-4',
      className
    )}
  >
    {children}
  </header>
);

const EditorContainer = ({ children, className }: ChildrenWithClassName) => (
  <section
    className={twMerge(
      'flex flex-1 flex-col overflow-auto pb-20 @[700px]/body:gap-6',
      className
    )}
  >
    {children}
  </section>
);

const EditorHeaderContainer = ({
  children,
  className,
}: ChildrenWithClassName) => (
  <div
    className={twMerge(
      'flex flex-col p-4 @[700px]/body:px-12 @[700px]/body:pt-6',
      className
    )}
  >
    {children}
  </div>
);

const Footer = ({ children, className }: ChildrenWithClassName) => (
  <footer
    className={twMerge(
      'flex justify-end gap-2 border-t border-gray-5 px-2 py-2 @[700px]/body:px-12',
      className
    )}
  >
    {children}
  </footer>
);

export const FlowEditorContainers = {
  EditorContainer,
  EditorHeaderContainer,
  Footer,
  Header,
  Root,
};
