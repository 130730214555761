import type { UseQueryOptions } from '@tanstack/react-query';

import { APIEndpoints } from '../APIEndpoints';
import { assemblyAPI } from '../assemblyAPI';
import type { MemberAPIResponse } from '../types/domain';

export function getUserDetailsQuery(
  options?: UseQueryOptions<MemberAPIResponse>
): UseQueryOptions<MemberAPIResponse> {
  return {
    ...options,
    queryKey: ['userDetails'],
    queryFn: async () => {
      const response = await assemblyAPI.get<MemberAPIResponse>(
        APIEndpoints.userDetails
      );
      return response.data;
    },
  };
}
