import { defineMessages, useIntl } from 'react-intl';

import { EmptyAmazonRewardsImage } from '../../assets/images';

const messages = defineMessages({
  emptyRewardsTitle: {
    defaultMessage: 'Search for an item above to shop from Amazon',
    id: '4DypnE',
  },
  emptyRewardsDescription: {
    defaultMessage:
      'Use the search bar in the header above to find items to purchase with {currencies}.',
    id: 'RQLSQL',
  },
});

export function EmptyAmazonRewards({ currencies }: { currencies: string }) {
  const { formatMessage } = useIntl();
  return (
    <div className="flex w-full flex-col items-center justify-center rounded-md border-2 border-dashed border-gray-5 bg-gray-1 bg-opacity-50 p-8">
      <h3 className="mb-1 text-center font-medium text-gray-9">
        {formatMessage(messages.emptyRewardsTitle)}
      </h3>
      <p className="mb-4 w-full max-w-[768px] text-center text-sm text-gray-8">
        {formatMessage(messages.emptyRewardsDescription, {
          currencies: currencies,
        })}
      </p>
      <img
        src={EmptyAmazonRewardsImage}
        className="mb-4"
        alt="No rewards found"
      />
    </div>
  );
}
