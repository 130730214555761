import { useEffect, useState } from 'react';
import { twMerge } from 'tailwind-merge';

import { Challenge } from '../../../assets/images';

type ImageDisplayProps = {
  image: string;
  locationUrl?: string;
  variant?: 'preview' | 'default' | 'drawer' | 'carousal';
};
export const ImageDisplay = (props: ImageDisplayProps) => {
  const { image, variant, locationUrl } = props;

  const [src, setSrc] = useState('');

  useEffect(() => {
    setSrc(image);
  }, [image]);

  return (
    <div
      className={twMerge(
        'relative flex h-auto w-full shrink-0 items-center overflow-hidden pt-4 @md/challenge-card:m-0 @md/challenge-card:min-h-[182px] @md/challenge-card:w-[236px] @md/challenge-card:border-r @md/challenge-card:border-gray-5 @md/challenge-card:p-0',
        variant !== 'drawer' && 'justify-center p-4'
      )}
    >
      {src ? (
        <img
          alt=""
          src={src}
          onError={() => {
            if (locationUrl) {
              setSrc(locationUrl);
            }
          }}
          className={twMerge(
            'w-236px h-[182px] rounded-lg border border-gray-5 object-contain @md/challenge-card:h-full @md/challenge-card:w-full @md/challenge-card:rounded-none @md/challenge-card:border-none'
          )}
        />
      ) : (
        <div
          className={
            'flex h-[182px] w-[236px] items-center justify-center rounded-lg border border-gray-5 bg-upgrade-3 @md/challenge-card:h-full @md/challenge-card:w-full @md/challenge-card:rounded-none @md/challenge-card:border-none'
          }
        >
          <img src={Challenge} className="object-contain" alt="empty state" />
        </div>
      )}
    </div>
  );
};
