import { defineMessage, useIntl } from 'react-intl';

const message = defineMessage({
  defaultMessage: 'Loading content',
  id: '3Pyhwf',
});

type CardSkeletonLoaderProps = {
  expanded?: boolean;
};

export function CardSkeletonLoader(props: CardSkeletonLoaderProps) {
  const { formatMessage } = useIntl();

  if (props.expanded) {
    return (
      <div
        className="inline-flex w-full items-start justify-start gap-2 rounded-lg border border-gray-5 bg-gray-1"
        role="status"
      >
        <div className="inline-flex shrink grow basis-0 flex-col items-start justify-start gap-1">
          <div className="inline-flex items-start justify-between self-stretch px-4 pb-2 pt-4">
            <div className="flex h-10 shrink grow basis-0 items-center justify-start gap-2">
              <div className="relative h-10 w-10 rounded-full">
                <div className="absolute left-[4px] top-[4px] h-10 w-10 rounded-full bg-gradient-to-r from-gray-3 to-gray-5" />
              </div>
              <div className="inline-flex flex-col items-start justify-start">
                <div className="flex flex-col items-start justify-start gap-2 py-0.5">
                  <div className="h-4 w-96 rounded bg-gradient-to-r from-gray-3 to-gray-5" />
                </div>
                <div className="flex h-5 w-24 flex-col items-start justify-start gap-2 py-1">
                  <div className="h-3 self-stretch rounded bg-gradient-to-r from-gray-3 to-gray-5" />
                </div>
              </div>
            </div>
          </div>
          <div className="flex h-14 flex-col items-start justify-start gap-3 self-stretch px-4 pb-4">
            <div className="flex h-11 flex-col items-start justify-start self-stretch">
              <div className="flex h-5 w-60 flex-col items-start justify-start gap-2 py-0.5">
                <div className="h-3.5 self-stretch rounded bg-gradient-to-r from-gray-3 to-gray-5" />
              </div>
              <div className="flex h-6 w-3/4 flex-col items-start justify-start gap-2 py-1">
                <div className="h-4 self-stretch rounded bg-gradient-to-r from-gray-3 to-gray-5" />
              </div>
            </div>
          </div>
        </div>
        <span className="sr-only">{formatMessage(message)}</span>
      </div>
    );
  }

  return (
    <div
      className="inline-flex w-full flex-col items-center justify-start gap-2 rounded-lg border border-gray-5 bg-gray-1 px-3 pb-4 pt-3"
      role="status"
    >
      <div className="inline-flex items-center justify-start gap-2 self-stretch">
        <div className="flex h-10 shrink grow basis-0 items-start justify-start gap-2">
          <div className="relative h-6 w-6 rounded">
            <div className="absolute left-[4px] top-[4px] h-4 w-4 rounded bg-gradient-to-r from-gray-3 to-gray-5" />
          </div>
          <div className="inline-flex w-full flex-col items-start justify-center gap-1">
            <div className="flex w-full flex-col items-start justify-center gap-2 py-1">
              <div className="h-4 w-full rounded bg-gradient-to-r from-gray-3 to-gray-5 md:w-96" />
            </div>
            <div className="h-3 w-2/4 rounded bg-gradient-to-r from-gray-3 to-gray-5 lg:w-80" />
          </div>
        </div>
      </div>

      <span className="sr-only">{formatMessage(message)}</span>
    </div>
  );
}
