import ContentLoader from 'react-content-loader';

export function RedemptionModalSkeletonLoader() {
  const totalHeight = 40 * 4 + 20;

  return (
    <div className="mt-1 max-w-3xl rounded-lg border border-gray-5 bg-gray-1 p-4">
      <ContentLoader
        speed={2}
        width="100%"
        height={totalHeight}
        viewBox={`0 0 400 ${totalHeight}`}
        backgroundColor="#f3f3f3"
        foregroundColor="#ecebeb"
      >
        <defs>
          <linearGradient id="gradient-bg" x1="0%" y1="0%" x2="100%" y2="0%">
            <stop offset="0%" stopColor="#f3f3f3" />
            <stop offset="50%" stopColor="#ecebeb" />
            <stop offset="100%" stopColor="#f3f3f3" />
          </linearGradient>
        </defs>

        <rect
          x="0"
          y="0"
          rx="3"
          ry="3"
          width="100%"
          height="40"
          fill="url(#gradient-bg)"
        />
        <rect
          x="0"
          y="50"
          rx="3"
          ry="3"
          width="100%"
          height="40"
          fill="url(#gradient-bg)"
        />
        <rect
          x="0"
          y="100"
          rx="3"
          ry="3"
          width="100%"
          height="40"
          fill="url(#gradient-bg)"
        />
        <rect
          x="0"
          y="150"
          rx="3"
          ry="3"
          width="100%"
          height="40"
          fill="url(#gradient-bg)"
        />
        <rect
          x="0"
          y="200"
          rx="3"
          ry="3"
          width="100%"
          height="20"
          fill="url(#gradient-bg)"
        />
      </ContentLoader>
    </div>
  );
}
