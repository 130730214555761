import type {
  ChallengeCardClaimState,
  ChallengeState,
  Claim,
  SelfClaimDetails,
  UserAvatar,
} from '@assembly-web/services';

export type ChallengeProps = {
  selfClaimDetails: SelfClaimDetails | null;
  challengeState: ChallengeState;
  isUserAdmin: boolean;
  claimsRemaining: number;
  hideReactions?: boolean;
  hideReplies?: boolean;
  currentUserId?: string;
  memberID?: string;
  launchedAt?: string;
  userAvatarDetails: UserAvatar;
  claimButtonText: string;
  challengeId?: string;
};

export const useChallengeStates = ({
  challengeState,
}: {
  challengeState?: ChallengeState;
}) => {
  const isChallengeArchived = challengeState === 'ARCHIVED';
  const isChallengeActive = challengeState === 'ACTIVE';
  const isChallengeInactive = challengeState === 'QUEUED';
  const isChallengeClosed = challengeState === 'CLOSED';

  return {
    isChallengeArchived,
    isChallengeActive,
    isChallengeInactive,
    isChallengeClosed,
  };
};

export const useChallengeLogic = ({
  selfClaimDetails,
  currentUserId,
  memberId,
}: {
  selfClaimDetails: SelfClaimDetails | null;
  currentUserId?: string;
  memberId?: string;
}) => {
  const isCurrentUserOwner = currentUserId === memberId;
  let isIndividualClaimLimitExhausted = false;
  let hasPendingClaims;
  let noValidClaimsSubmitted;

  const isAllApproved =
    selfClaimDetails?.claimLimit === selfClaimDetails?.claims.length &&
    selfClaimDetails?.claims.every((claim) => claim.state === 'APPROVED');

  const validClaims = selfClaimDetails?.claims.filter(
    (claim) => claim.state !== 'DENIED'
  );

  if (validClaims && selfClaimDetails) {
    isIndividualClaimLimitExhausted =
      validClaims.length >= selfClaimDetails.claimLimit;

    noValidClaimsSubmitted =
      selfClaimDetails.claims.length === 0 || validClaims.length === 0;
  }

  if (validClaims) {
    hasPendingClaims = validClaims.some((claim) => claim.state === 'PENDING');
  }

  const claimCount = selfClaimDetails?.claims.filter(
    (claim) => claim.state === 'PENDING'
  ).length;

  const approvedClaims = selfClaimDetails?.claims.filter(
    (claim) => claim.state === 'APPROVED'
  );

  const totalCount = selfClaimDetails?.claimLimit;
  const totalEarnedPoints = selfClaimDetails?.totalEarnedPoints;

  return {
    isAllApproved,
    validClaims,
    isIndividualClaimLimitExhausted,
    hasPendingClaims,
    claimCount,
    approvedClaims,
    isCurrentUserOwner,
    totalCount,
    totalEarnedPoints,
    noValidClaimsSubmitted,
    isNotParticipant: selfClaimDetails === null,
  };
};

export const useChallengeVisibility = ({
  challengeState,
  isUserAdmin,
  selfClaimDetails,
  currentUserId,
  memberId,
  claimsRemaining,
}: {
  challengeState: ChallengeState;
  hideReactions?: boolean;
  hideReplies?: boolean;
  isUserAdmin: boolean;
  selfClaimDetails: SelfClaimDetails | null;
  currentUserId?: string;
  memberId?: string;
  claimsRemaining: number;
}) => {
  const {
    isChallengeArchived,
    isChallengeClosed,
    isChallengeInactive,
    isChallengeActive,
  } = useChallengeStates({ challengeState });

  const {
    isAllApproved,
    validClaims,
    isIndividualClaimLimitExhausted,
    hasPendingClaims,
    noValidClaimsSubmitted,
    isNotParticipant,
  } = useChallengeLogic({
    selfClaimDetails,
    currentUserId,
    memberId,
  });

  const showHeaderBanner =
    isUserAdmin || isChallengeArchived || isChallengeClosed;

  const showFooter = !isChallengeArchived;
  const showViewClaimsButton = isUserAdmin && isChallengeActive;
  const showClaimButton =
    (isChallengeActive || isChallengeInactive) &&
    (!isAllApproved || noValidClaimsSubmitted);

  const isClaimUnlimited = claimsRemaining === -1;
  const isClaimButtonDisabled =
    isChallengeInactive ||
    (!isClaimUnlimited && claimsRemaining === 0) ||
    isIndividualClaimLimitExhausted ||
    !selfClaimDetails;

  const showClaimBadge =
    validClaims &&
    validClaims.length > 0 &&
    !isChallengeArchived &&
    !isChallengeInactive &&
    (hasPendingClaims || isAllApproved);

  return {
    showViewClaimsButton,
    showClaimButton,
    isClaimButtonDisabled,
    showClaimBadge,
    showFooter,
    showHeaderBanner,
    isNotParticipant,
  };
};

export const useChallenge = (props: ChallengeProps): ChallengeValues => {
  const {
    selfClaimDetails,
    challengeState,
    isUserAdmin,
    claimsRemaining,
    currentUserId,
    memberID,
    hideReactions,
    hideReplies,
    launchedAt,
    userAvatarDetails,
    claimButtonText,
  } = props;

  const {
    isChallengeArchived,
    isChallengeActive,
    isChallengeClosed,
    isChallengeInactive,
  } = useChallengeStates({ challengeState });

  const isCurrentUserOwner = currentUserId === memberID;

  const {
    isAllApproved,
    validClaims,
    isIndividualClaimLimitExhausted,
    hasPendingClaims,
    claimCount,
    approvedClaims,
  } = useChallengeLogic({
    selfClaimDetails,
    currentUserId,
    memberId: memberID,
  });
  const {
    showViewClaimsButton,
    showClaimButton,
    isClaimButtonDisabled,
    showClaimBadge,
    showFooter,
    showHeaderBanner,
    isNotParticipant,
  } = useChallengeVisibility({
    challengeState,
    hideReactions,
    hideReplies,
    isUserAdmin,
    selfClaimDetails,
    currentUserId,
    memberId: memberID,
    claimsRemaining,
  });

  return {
    showViewClaimsButton,
    showClaimButton,
    isClaimButtonDisabled,
    showClaimBadge,
    claimCount,
    isChallengeArchived,
    isChallengeActive,
    isChallengeInactive,
    isChallengeClosed,
    showHeaderBanner,
    showFooter,
    isCurrentUserOwner,
    isAllApproved,
    isIndividualClaimLimitExhausted,
    validClaims,
    hasPendingClaims,
    launchedAt,
    selfClaimDetails,
    approvedClaims,
    userAvatarDetails,
    challengeState,
    claimButtonText,
    isUserAdmin,
    claimsRemaining,
    isNotParticipant,
  };
};

export type ChallengeValues = {
  showViewClaimsButton: boolean;
  showClaimButton?: boolean;
  isClaimButtonDisabled?: boolean;
  showClaimBadge?: boolean;
  claimState?: ChallengeCardClaimState;
  claimCount?: number;
  isChallengeArchived: boolean;
  isChallengeActive: boolean;
  isChallengeInactive: boolean;
  isChallengeClosed: boolean;
  showHeaderBanner: boolean;
  showFooter: boolean;
  isCurrentUserOwner: boolean;
  isAllApproved?: boolean;
  isIndividualClaimLimitExhausted: boolean;
  validClaims?: Claim[];
  hasPendingClaims?: boolean;
  isViewClaimsButtonDisabled?: boolean;
  launchedAt?: string;
  selfClaimDetails: SelfClaimDetails | null;
  approvedClaims?: Claim[];
  userAvatarDetails: UserAvatar;
  challengeState: ChallengeState;
  claimButtonText: string;
  isUserAdmin: boolean;
  claimsRemaining: number;
  isNotParticipant: boolean;
};
