import {
  type AssemblyCurrency,
  AssemblyCurrencyType,
  mapHexCodeToEmoticon,
} from '@assembly-web/services';
import { XMarkIcon } from '@heroicons/react/24/outline';
import parse from 'html-react-parser';
import {
  type ChangeEvent,
  type FormEvent,
  forwardRef,
  type LabelHTMLAttributes,
  type MouseEvent,
  useCallback,
  useRef,
  useState,
} from 'react';
import { defineMessages, useIntl } from 'react-intl';
import { twMerge } from 'tailwind-merge';

import { Tooltip } from '../../../DesignSystem/Feedback/Tooltip';
import { Button } from '../../../DesignSystem/Inputs/Button';
import { Checkbox } from '../../../DesignSystem/Inputs/Checkbox';
import { IconButton } from '../../../DesignSystem/Inputs/IconButton';
import { TextField } from '../../../DesignSystem/Inputs/TextField';
import type { MentionedUser } from '../Editors/base/plugins/MentionsPlugin';

const messages = defineMessages({
  giveBoostSingle: {
    defaultMessage: 'Give {name} a boost',
    id: '0ip7Jh',
  },
  giveBoostMultiple: {
    defaultMessage: 'Give {count} people a boost',
    id: '3MzguG',
  },
  chooseAnAmount: {
    defaultMessage: 'Choose an amount to give',
    id: 'SCESqN',
  },
  addUpto: {
    defaultMessage:
      'Add up to {points} {currency} {multiple} when you send this reply',
    id: 'xNACTn',
  },
  applyBoostToAll: {
    defaultMessage: 'Apply boost to all tagged people',
    id: '3e85XW',
  },
  hidePointsInReplies: {
    defaultMessage: 'Hide {currency} amount in reply',
    id: 'VpHfDS',
  },
  addBoost: {
    defaultMessage: 'Add boost',
    id: '5hrjaR',
  },
  saveChanges: {
    defaultMessage: 'Save changes',
    id: 'X0ha1a',
  },
  removeBoost: {
    defaultMessage: 'Remove boost',
    id: 'rjsoAA',
  },
  youHaveBalance: {
    defaultMessage:
      'You have a balance of {points} {currency} left to give this month',
    id: 'Eoax8i',
  },
  youDoNotHaveEnough: {
    defaultMessage: 'You do not have enough {currency}',
    id: 'WZgzJn',
  },
  cantGiveBoostToYourself: {
    defaultMessage: "You can't give to yourself",
    id: 'x0MMBv',
  },
  notAvailableDuringEdit: {
    defaultMessage: 'Apply to all not available while editing',
    id: 'qPcq1J',
  },
});

export type BoostManagerProps = {
  users: MentionedUser[];
  mentionedUser: MentionedUser | null;
  points: string | null;
  onAddClick: (args: {
    memberId: string;
    points: string;
    applyBoostToAll: boolean;
    hideBoost: boolean;
  }) => void;
  onRemoveClick: (args: { memberId: string }) => void;
  canGiveBoost: boolean;
  currency: AssemblyCurrency;
  mode: 'ADD' | 'UPDATE';
  onClose?: (ev: MouseEvent<HTMLButtonElement>) => void;
  canReceiveBoost: boolean;
  pointsLimit: {
    remainingWhenAll: number;
    remaining: number;
  };
  isEditing: boolean;
};

const Label = forwardRef<
  HTMLLabelElement,
  LabelHTMLAttributes<HTMLLabelElement>
>(({ children, htmlFor, ...props }, ref) => {
  return (
    <label
      ref={ref}
      htmlFor={htmlFor}
      {...props}
      className="flex w-max cursor-pointer items-center gap-2 pt-1 text-sm text-gray-9 [&:has(input:disabled)]:cursor-not-allowed [&:has(input:disabled)]:text-gray-6 [&:has(input:disabled)_input]:cursor-not-allowed [&:has(input:not([aria-disabled]):disabled)]:hidden"
    >
      {children}
    </label>
  );
});

Label.displayName = 'Label';

export function BoostManager({
  users,
  mentionedUser,
  points,
  currency,
  canGiveBoost,
  canReceiveBoost,
  mode,
  onClose,
  onAddClick,
  pointsLimit,
  onRemoveClick,
  isEditing,
}: BoostManagerProps) {
  const { formatMessage } = useIntl();

  const pointsToYourself =
    mentionedUser?.currentUserID === mentionedUser?.memberID;

  const [applyBoostToAll, setApplyBoostToAll] = useState(() => {
    if (isEditing) {
      return false;
    }
    return users[0]?.applyBoostToAll ?? false;
  });

  const [hideBoost, setHideBoost] = useState(() => {
    return users[0]?.pointsHidden ?? false;
  });

  const [pointsValue, setPointsValue] = useState(() => points ?? '');

  const boostManagerRef = useRef<HTMLDivElement | null>(null);

  const getLabel = useCallback(() => {
    if (pointsToYourself) {
      return formatMessage(messages.cantGiveBoostToYourself);
    }

    if (applyBoostToAll && users.length > 1) {
      return formatMessage(messages.giveBoostMultiple, {
        count: users.length,
      });
    }

    return formatMessage(messages.giveBoostSingle, {
      name: mentionedUser?.firstName,
    });
  }, [
    pointsToYourself,
    applyBoostToAll,
    users.length,
    formatMessage,
    mentionedUser,
  ]);

  const currencyIcon =
    currency.type === AssemblyCurrencyType.Custom
      ? `<img alt=${currency.name} class="mr-0.5 h-3 w-3 inline-block" src=${currency.value} />`
      : mapHexCodeToEmoticon(currency.value);

  const getInfoMessage = () => {
    const limit = applyBoostToAll
      ? pointsLimit.remainingWhenAll
      : pointsLimit.remaining;
    const computedLimit = applyBoostToAll
      ? limit
      : parseInt(points ?? '0') + limit;

    if (pointsToYourself) {
      return '';
    }

    if (applyBoostToAll) {
      return formatMessage(messages.addUpto, {
        points: `${currencyIcon}${computedLimit}`,
        currency: currency.pluralName,
        multiple: 'each',
      });
    }

    return formatMessage(messages.addUpto, {
      points: `${currencyIcon}${computedLimit}`,
      currency: currency.pluralName,
      multiple: '',
    });
  };

  const handleAddBoost = () => {
    if (!mentionedUser) {
      return;
    }

    onAddClick({
      applyBoostToAll,
      hideBoost,
      memberId: mentionedUser.memberID,
      points: pointsValue,
    });
  };

  const handleApplyBoostToAll = (e: ChangeEvent<HTMLInputElement>) => {
    const checked = e.target.checked;
    setApplyBoostToAll(checked);
    if (checked) {
      if (Number(pointsValue) > pointsLimit.remainingWhenAll) {
        setPointsValue(pointsLimit.remainingWhenAll.toString());
      }
    }
  };

  const handleBoostFormSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    handleAddBoost();
  };

  return (
    <div
      className={twMerge('relative flex w-[298px] flex-col gap-3 px-2 py-3')}
      ref={boostManagerRef}
    >
      <IconButton
        size="xSmall"
        variation="tertiaryLite"
        className="absolute right-2 top-3 z-10"
        onClick={onClose}
      >
        <XMarkIcon />
      </IconButton>
      <fieldset
        disabled={pointsToYourself}
        className="flex flex-col gap-1 px-2"
      >
        <form id="add-boost" onSubmit={handleBoostFormSubmit}>
          <TextField
            name="pointsAmount"
            label={getLabel()}
            value={pointsToYourself ? '' : pointsValue}
            connectedLeft={parse(currencyIcon, {
              transform(reactNode) {
                if (!pointsToYourself) {
                  return <>{reactNode}</>;
                }
                return <div className="grayscale">{reactNode}</div>;
              },
            })}
            placeholder={
              pointsToYourself
                ? formatMessage(messages.cantGiveBoostToYourself)
                : canGiveBoost
                  ? formatMessage(messages.chooseAnAmount)
                  : formatMessage(messages.youDoNotHaveEnough, {
                      currency: currency.pluralName,
                    })
            }
            helpText={getInfoMessage()}
            onChange={(ev: ChangeEvent<HTMLInputElement>) => {
              const value = ev.target.value.replace(/^0(\d+)/g, '$1');
              const limit = applyBoostToAll
                ? pointsLimit.remainingWhenAll
                : pointsLimit.remaining;
              const computedLimit = applyBoostToAll
                ? limit
                : parseInt(points ?? '0') + limit;

              if (Number(value) < 0) {
                setPointsValue('0');
                return;
              }

              if (Number(value) > computedLimit) {
                setPointsValue(computedLimit.toString());
              } else {
                setPointsValue(value);
              }
            }}
            type="number"
            min={0}
            required
            isInvalid={false}
            invalidText={''}
            className="flex flex-col gap-1"
            labelClassName="text-sm font-medium text-gray-9"
          />
        </form>

        <Tooltip
          tooltipText={
            isEditing ? formatMessage(messages.notAvailableDuringEdit) : ''
          }
        >
          <Label htmlFor="applyBoostToAll">
            <Checkbox
              disabled={isEditing}
              id="applyBoostToAll"
              aria-disabled={isEditing}
              checked={applyBoostToAll}
              onChange={handleApplyBoostToAll}
            />
            {formatMessage(messages.applyBoostToAll)}
          </Label>
        </Tooltip>

        <Label htmlFor="hideBoost">
          <Checkbox
            checked={hideBoost}
            onChange={(e: ChangeEvent<HTMLInputElement>) => {
              setHideBoost(e.target.checked);
            }}
            id="hideBoost"
          />
          {formatMessage(messages.hidePointsInReplies, {
            currency: currency.pluralName,
          })}
        </Label>
      </fieldset>
      <div className="px-1 pb-1">
        {mode === 'ADD' ? (
          !pointsToYourself ? (
            <Button
              size="small"
              form="add-boost"
              className="w-full"
              disabled={
                ['', '0'].includes(pointsValue) ||
                !canGiveBoost ||
                !canReceiveBoost
              }
            >
              {formatMessage(messages.addBoost)}
            </Button>
          ) : null
        ) : (
          <div className="flex gap-2">
            <Button
              className="w-full"
              size="small"
              form="add-boost"
              disabled={['', '0'].includes(pointsValue)}
            >
              {formatMessage(messages.saveChanges)}
            </Button>
            <Button
              className="w-full"
              size="small"
              variation="tertiaryLite"
              type="button"
              onClick={() => {
                onRemoveClick({ memberId: mentionedUser?.memberID ?? '' });
              }}
            >
              {formatMessage(messages.removeBoost)}
            </Button>
          </div>
        )}
      </div>
    </div>
  );
}
