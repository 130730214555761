import { $createTextNode, $getSelection, $isRangeSelection } from 'lexical';

import { getSelectedNode } from './getSelectedNode';

export const getSelectedNodes = () => {
  const selection = $getSelection();
  const textNode = $createTextNode(`@`);
  const nodes = [textNode];

  if ($isRangeSelection(selection)) {
    const textContent = getSelectedNode(selection).getTextContent();
    const textLength = textContent.length;

    const insertSpaceNode =
      textContent[textLength - 1] !== ' ' && textLength !== 0;

    const spaceNode = $createTextNode(' ');

    if (insertSpaceNode) {
      return [spaceNode, ...nodes];
    }
  }

  return nodes;
};
