export const AppAction = {
  FileDownload: 'fileDownload',
  CloseModalStatus: 'CloseModalStatus',
  FeedLoaded: 'feedLoaded',
} as const;

export const AppTargetEvent = {
  Mobile: 'Mobile',
} as const;

export type AppMessage =
  | {
      action: typeof AppAction.FileDownload;
      targetEvent?: typeof AppTargetEvent.Mobile;
      payload: {
        id: string;
        linkURL: string;
      };
    }
  | {
      action: typeof AppAction.CloseModalStatus;
      targetEvent?: typeof AppTargetEvent.Mobile;
    }
  | {
      action: typeof AppAction.FeedLoaded;
    };
