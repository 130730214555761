import axios from 'axios';

import { config } from './config';
import { userAuthStore } from './stores/userAuthStore';

export const assemblyAPI = axios.create({
  baseURL: `${config.domains.api}/api`,
  withCredentials: true,
});

assemblyAPI.interceptors.request.use((axiosConfig) => {
  if (!config.isMobileDevMode) {
    return axiosConfig;
  }

  const mobileAuthToken = userAuthStore.getState().jwtToken;
  if (mobileAuthToken) {
    axiosConfig.headers.Authorization = `JWT ${mobileAuthToken}`;
  }

  return axiosConfig;
});

export const doraAPI = axios.create({
  baseURL: `${config.domains.doraApi}`,
  withCredentials: true,
});

export const unauthenticatedAssemblyAPI = axios.create({
  baseURL: `${config.domains.api}/api`,
  withCredentials: true,
  headers: {
    clientAPIVersion: '1.5.0',
    XClient: window.location.host,
  },
});

export function getAPIErrorCode(error: unknown) {
  if (axios.isAxiosError(error)) {
    return (error.response?.data as { message: string }).message.toLowerCase();
  }
  return 'unknown';
}
