import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  stepLabel: {
    defaultMessage: `Step {step}`,
    id: 'EJ8mg/',
  },
  editLabel: {
    defaultMessage: 'Edit',
    id: 'wEQDC6',
  },
  closeLabel: {
    defaultMessage: 'Close',
    id: 'rbrahO',
  },
  savingLabel: {
    defaultMessage: 'Saving...',
    id: 'TiR/Hq',
  },
  cancelLabel: {
    defaultMessage: 'Cancel',
    id: '47FYwb',
  },
  nextLabel: {
    defaultMessage: 'Next',
    id: '9+Ddtu',
  },
  backLabel: {
    defaultMessage: 'Back',
    id: 'cyR7Kh',
  },
  getHelpLabel: {
    defaultMessage: 'Get help',
    id: '9OuUCL',
  },
});
