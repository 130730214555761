import type { FileDetails } from '@assembly-web/services';
import { XMarkIcon } from '@heroicons/react/24/outline';
import * as Dialog from '@radix-ui/react-dialog';
import type { ReactNode } from 'react';
import { useIntl } from 'react-intl';

import { TextStyle, Tooltip } from '../../../..';
import { DownloadButton } from '../../../Shared/FileUpload/FilePreview';
import { messages } from '../FilePreviewer';

export const Header = ({
  file,
  fileName,
  onClose,
  primaryActions,
}: {
  fileName: string;
  onClose: () => void;
  file?: FileDetails;
  primaryActions?: ReactNode | ReactNode[];
}) => {
  const { formatMessage } = useIntl();
  return (
    <header className="flex h-10 w-full justify-between border-b border-gray-5 bg-gray-1 p-2">
      <div>
        <Dialog.Title asChild className="leading-6">
          <TextStyle variant="base-medium">{fileName}</TextStyle>
        </Dialog.Title>
      </div>
      <div className="flex gap-4">
        {file ? (
          <>
            {primaryActions}
            <Tooltip tooltipText={formatMessage(messages.download)}>
              <DownloadButton
                url={file.location}
                name={fileName}
                absolutePositioning={false}
              />
            </Tooltip>
          </>
        ) : null}
        <Tooltip tooltipText={formatMessage(messages.close)}>
          <button
            onClick={() => {
              onClose();
            }}
            className="group cursor-pointer rounded-[8px] p-1 hover:bg-gray-3 focus:outline-none focus:ring-2 focus:ring-primary-6 focus:ring-offset-2 focus-visible:outline-none"
          >
            <XMarkIcon className="h-4 w-4 text-gray-9" />
          </button>
        </Tooltip>
      </div>
    </header>
  );
};
