import dayjs from 'dayjs';
import relativeTimePlugin from 'dayjs/plugin/relativeTime';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import { defineMessages, useIntl } from 'react-intl';
import { twMerge } from 'tailwind-merge';

import { TextStyle } from '../../../../../DesignSystem/Feedback/TextStyle';
import { FlowIcon } from '../../../../assets/icons';
import { OverflowCard } from '.';

dayjs.extend(relativeTimePlugin);
dayjs.extend(utc);
dayjs.extend(timezone);

export type FlowCreationOverflowCardProps = {
  /** Determines if the card is in chat view to update icon styling */
  isChatView?: boolean;

  /** Determines if data is loading in */
  isLoading: boolean;

  /** Timestamp of last edit */
  lastEditedTS?: number;

  /** Timestamp of last message */
  lastMessageTS?: number;

  /** Title shown as first line in card */
  title: string;

  /** Event handler called when the drawer gets closed out */
  onClose: () => void;

  /** Event handler called when the drawer gets clicked on */
  onClick: () => void;
};

const messages = defineMessages({
  lastMessage: {
    defaultMessage: 'Last message {timeAgo}',
    id: 'wAV5Nq',
  },
  lastEdit: {
    defaultMessage: 'Last edited {timeAgo}',
    id: '6ttiK8',
  },
  defaultContentText: {
    defaultMessage: 'Choose how you want to create',
    id: 'Zkkn+V',
  },
});

export function FlowCreationOverflowCard(props: FlowCreationOverflowCardProps) {
  const {
    isChatView,
    isLoading,
    title,
    lastEditedTS,
    lastMessageTS,
    onClose,
    onClick,
  } = props;
  const { formatMessage } = useIntl();

  const avatar = (
    <div
      className={twMerge(
        'rounded border border-gray-5 bg-gray-1 p-2',
        isChatView &&
          'bg-gradient-to-r from-[#1d3fc8] via-purple-7 to-[#a309ca]'
      )}
    >
      <FlowIcon
        className={twMerge('h-6 w-6 text-gray-9', isChatView && 'text-gray-1')}
      />
    </div>
  );

  const content = isLoading ? (
    <div className="h-5 w-full max-w-[180px] animate-pulse rounded bg-gray-5" />
  ) : (
    <>
      {Boolean(lastEditedTS) || Boolean(lastMessageTS) ? (
        <>
          {lastEditedTS ? (
            <TextStyle as="span" variant="xs-regular" className="text-gray-8">
              {formatMessage(messages.lastEdit, {
                timeAgo: dayjs(lastEditedTS).tz(dayjs.tz.guess()).fromNow(),
              })}
            </TextStyle>
          ) : (
            <TextStyle as="span" variant="xs-regular" className="text-gray-8">
              {formatMessage(messages.lastMessage, {
                timeAgo: dayjs(lastMessageTS).tz(dayjs.tz.guess()).fromNow(),
              })}
            </TextStyle>
          )}
        </>
      ) : (
        <TextStyle as="span" variant="xs-regular" className="text-gray-8">
          {formatMessage(messages.defaultContentText)}
        </TextStyle>
      )}
    </>
  );

  return (
    <OverflowCard
      title={title}
      content={content}
      avatar={avatar}
      onClose={onClose}
      onClick={onClick}
    />
  );
}
