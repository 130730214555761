import {
  type ReactionDetails,
  ReactionTypes,
  withoutDefaultEventBehavior,
} from '@assembly-web/services';
import {
  EllipsisHorizontalIcon,
  FaceSmileIcon,
} from '@heroicons/react/24/outline';
import {
  Content,
  Item,
  Menu,
  Portal,
  Root as MenuRoot,
  Trigger,
} from '@radix-ui/react-menubar';
import { useIntl } from 'react-intl';
import { twMerge } from 'tailwind-merge';

import type { ToolbarProps } from '../../../DesignSystem/Feedback/Toolbar/Toolbar';
import { Tooltip } from '../../../DesignSystem/Feedback/Tooltip';
import { DummyCurrentMember } from '../../Shared/PostCard/data/flowsData';
import { ReactionPicker } from '../../Shared/Reactions/ReactionPicker';
import { messages } from './messages';

export type ConversationCardToolbarProps = ToolbarProps & {
  currentMemberId: string;
  onReactionClick: (emoji: ReactionDetails) => void;
};

export function ConversationCardToolbar({
  className,
  hideBorder = false,
  compact = false,
  onClosed,
  onToggle,
  hideToolbar,
  onMenuItemClick,
  hideWhenDetached,
  onReactionClick,
  currentMemberId,
  secondaryToolbarItems: toolbarItems,
}: ConversationCardToolbarProps) {
  const { formatMessage } = useIntl();

  if (hideToolbar) {
    return null;
  }

  return (
    <div
      className={twMerge(
        'flex h-8 w-full rounded-lg bg-gray-1',
        !hideBorder && 'border border-gray-5',
        'p-[3px]',
        compact ? 'h-6 w-6 items-center justify-center hover:bg-gray-3' : 'h-8',
        className
      )}
    >
      <ReactionPicker
        triggerButton={
          <Tooltip tooltipText={formatMessage(messages.addReaction)}>
            <div className="no-blur flex h-6 w-6 items-center justify-center rounded hover:bg-gray-3">
              <FaceSmileIcon className="h-4 w-4" />
            </div>
          </Tooltip>
        }
        onReactionClick={(emoji) => {
          onReactionClick({
            name: emoji.colons,
            value: emoji.id,
            type: ReactionTypes.Regular,
            displayName: emoji.id,
            members: [
              {
                ...DummyCurrentMember,
                memberID: currentMemberId,
              },
            ],
          });
        }}
        disabled={false}
      />
      {toolbarItems.length > 0 && (
        <MenuRoot
          className="flex"
          onValueChange={(event) => {
            onToggle?.(Boolean(event));
          }}
        >
          <Menu>
            <Trigger
              className="relative inline-block text-left"
              aria-label="ellipsis"
              asChild
            >
              <button
                title={formatMessage(messages.moreLabel)}
                aria-label={formatMessage(messages.moreLabel)}
                className="flex items-center justify-center rounded hover:bg-gray-3"
              >
                <EllipsisHorizontalIcon
                  className={twMerge(
                    'fill-gray-10 stroke-2',
                    compact ? 'h-4 w-4' : 'h-6 w-6'
                  )}
                />
              </button>
            </Trigger>
            <Portal>
              <>
                <div className="fixed bottom-0 left-0 right-0 z-[12] h-full w-full"></div>
                <Content
                  data-testid="conversation-toolbar"
                  align="end"
                  alignOffset={-2}
                  sideOffset={6}
                  side="bottom"
                  className={twMerge(
                    'z-[12] max-h-[32rem] w-max origin-top overflow-auto rounded-md border border-gray-5 bg-gray-1 py-1 shadow-lg-down ring-opacity-5 focus:outline-none'
                  )}
                  onCloseAutoFocus={() => {
                    onClosed?.();
                  }}
                  hideWhenDetached={hideWhenDetached}
                >
                  {toolbarItems.map((action) => {
                    const item = (
                      <div className="bg-gray-0 flex w-full items-center px-3 py-[9px] text-sm text-gray-8 hover:bg-gray-3 focus:outline-none">
                        <action.icon
                          className={twMerge(
                            'h-4 w-4 stroke-2',
                            action.isAlertStyle && 'text-error-6',
                            action.disabled && 'text-gray-7'
                          )}
                        />
                        <div
                          className={twMerge(
                            'ml-2 line-clamp-1 text-left',
                            action.isAlertStyle && 'text-error-6',
                            action.disabled && 'text-gray-7'
                          )}
                        >
                          {action.text}
                        </div>
                      </div>
                    );
                    return (
                      <Item
                        key={action.id}
                        onClick={
                          action.disabled
                            ? () => {}
                            : withoutDefaultEventBehavior(() => {
                                onMenuItemClick(action);
                              })
                        }
                        className={twMerge(
                          'cursor-pointer focus:bg-primary-2 focus:outline-none',
                          action.disabled && 'cursor-default bg-gray-2'
                        )}
                        disabled={action.disabled}
                      >
                        {action.tooltipText ? (
                          <Tooltip
                            tooltipText={action.tooltipText}
                            contentClassName="max-w-[232px]"
                            side="left"
                          >
                            {item}
                          </Tooltip>
                        ) : (
                          item
                        )}
                      </Item>
                    );
                  })}
                </Content>
              </>
            </Portal>
          </Menu>
        </MenuRoot>
      )}
    </div>
  );
}
