import { $getRoot, type LexicalEditor } from 'lexical';

/**
 * Reset the provided LexicalEditor instance by clearing its root node.
 *
 * @param {LexicalEditor} editor - The LexicalEditor instance to be reset.
 * @return {void}
 */
export function resetEditor(editor: LexicalEditor): void {
  editor.update(() => {
    const root = $getRoot();
    root.clear();
  });
}
