import { withoutDefaultEventBehavior } from '@assembly-web/services';
import { EllipsisHorizontalIcon } from '@heroicons/react/24/outline';
import {
  Content,
  Item,
  Portal,
  Root as MenuRoot,
  Trigger,
} from '@radix-ui/react-dropdown-menu';
import type {
  ForwardRefExoticComponent,
  FunctionComponent,
  PropsWithoutRef,
  RefAttributes,
  SVGProps,
} from 'react';
import { defineMessages, useIntl } from 'react-intl';
import { twMerge } from 'tailwind-merge';

import { Tooltip } from '../Tooltip';

const messages = defineMessages({
  moreLabel: {
    defaultMessage: 'more',
    id: 'gk3x/E',
  },
});

export type ToolbarItem = {
  id: string;
  text: string;
  icon:
    | ForwardRefExoticComponent<
        PropsWithoutRef<SVGProps<SVGSVGElement>> & {
          title?: string;
          titleId?: string;
        } & RefAttributes<SVGSVGElement>
      >
    | FunctionComponent<
        SVGProps<SVGSVGElement> & { title?: string | undefined }
      >;
  disabled?: boolean;
  isAlertStyle?: boolean;
  tooltipText?: string;
  hasPoints?: boolean;
};

export type ToolbarProps = {
  className?: string;
  hideBorder?: boolean;
  isMenuOpen?: boolean;
  compact?: boolean;
  hideToolbar: boolean;
  onClosed?: () => void;
  hideWhenDetached?: boolean;
  primaryToolbarItems?: ToolbarItem[];
  secondaryToolbarItems: ToolbarItem[];
  onToggle?: (isOpen: boolean) => void;
  onMenuItemClick: (args: ToolbarItem) => void;
};

export function Toolbar({
  className,
  hideBorder = false,
  compact = false,
  onClosed,
  onToggle,
  hideToolbar,
  isMenuOpen,
  onMenuItemClick,
  hideWhenDetached,
  primaryToolbarItems,
  secondaryToolbarItems,
}: ToolbarProps) {
  const { formatMessage } = useIntl();

  const wrapperPadding = primaryToolbarItems?.length === 0 ? 'p-[3px]' : 'p-1';

  if (hideToolbar) {
    return null;
  }

  return (
    <div
      className={twMerge(
        'pointer-events-auto flex rounded-lg bg-gray-1',
        !hideBorder && 'border border-gray-5',
        wrapperPadding,
        compact
          ? 'h-6 w-fit min-w-[24px] items-center justify-center hover:bg-gray-3'
          : 'h-fit w-full',
        className
      )}
    >
      {primaryToolbarItems?.map((item) => (
        <Tooltip key={item.id} tooltipText={item.text}>
          <button
            key={item.id}
            aria-label={item.text}
            className={twMerge(
              'flex items-center justify-center rounded hover:bg-gray-3',
              compact ? 'h-4 w-4' : 'h-6 w-6'
            )}
            onClick={withoutDefaultEventBehavior(() => {
              onMenuItemClick(item);
            })}
          >
            <item.icon
              strokeWidth={2}
              className={compact ? 'h-3 w-3' : 'h-4 w-4'}
            />
          </button>
        </Tooltip>
      ))}
      {secondaryToolbarItems.length > 0 && (
        <MenuRoot
          open={isMenuOpen}
          onOpenChange={(state) => {
            onToggle?.(state);
            if (!state) {
              onClosed?.();
            }
          }}
        >
          <Trigger
            className="relative inline-block text-left"
            aria-label="ellipsis"
            asChild
            onClick={withoutDefaultEventBehavior(() => {})}
          >
            <button
              title={formatMessage(messages.moreLabel)}
              aria-label={formatMessage(messages.moreLabel)}
              className={twMerge(
                'flex items-center justify-center rounded hover:bg-gray-3',
                compact ? 'h-4 w-4' : 'h-6 w-6'
              )}
            >
              <EllipsisHorizontalIcon
                className={twMerge(
                  'pointer-events-none fill-gray-10 stroke-2',
                  compact ? 'h-4 w-4' : 'h-6 w-6'
                )}
              />
            </button>
          </Trigger>
          <Portal>
            <Content
              align="end"
              alignOffset={-2}
              sideOffset={6}
              side="bottom"
              className={twMerge(
                'z-[12] max-h-[32rem] w-max origin-top overflow-auto rounded-md border border-gray-5 bg-gray-1 py-1 shadow-lg-down ring-opacity-5 focus:outline-none'
              )}
              hideWhenDetached={hideWhenDetached}
            >
              {secondaryToolbarItems.map((action) => {
                const item = (
                  <div className="bg-gray-0 flex w-full items-center px-3 py-[9px] text-sm text-gray-8 hover:bg-gray-3 focus:outline-none">
                    <action.icon
                      className={twMerge(
                        'h-4 w-4 stroke-2',
                        action.isAlertStyle && 'text-error-6',
                        action.disabled && 'text-gray-7'
                      )}
                    />
                    <div
                      className={twMerge(
                        'ml-2 line-clamp-1 text-left',
                        action.isAlertStyle && 'text-error-6',
                        action.disabled && 'text-gray-7'
                      )}
                    >
                      {action.text}
                    </div>
                  </div>
                );
                return (
                  <Item
                    key={action.id}
                    onClick={
                      action.disabled
                        ? () => {}
                        : withoutDefaultEventBehavior(() => {
                            onMenuItemClick(action);
                          })
                    }
                    className={twMerge(
                      'cursor-pointer focus:bg-primary-2 focus:outline-none',
                      action.disabled && 'cursor-default bg-gray-2'
                    )}
                    disabled={action.disabled}
                  >
                    {action.tooltipText ? (
                      <Tooltip
                        tooltipText={action.tooltipText}
                        contentClassName="max-w-[232px]"
                        side="left"
                      >
                        {item}
                      </Tooltip>
                    ) : (
                      item
                    )}
                  </Item>
                );
              })}
            </Content>
          </Portal>
        </MenuRoot>
      )}
    </div>
  );
}
