import { ArrowRightIcon } from '@heroicons/react/24/outline';
import type { ComponentProps, ReactElement, ReactNode } from 'react';
import { twMerge } from 'tailwind-merge';

import { CategoryOption } from '.';

export const Icon = (props: ComponentProps<typeof CategoryOption.Icon>) => {
  const { children, className } = props;

  return (
    <CategoryOption.Icon
      className={twMerge('w-9 rounded-lg bg-gradient-to-bl p-2', className)}
    >
      {children}
    </CategoryOption.Icon>
  );
};

export const Title = (props: ComponentProps<typeof CategoryOption.Title>) => {
  const { children, className, variant } = props;

  return (
    <CategoryOption.Title className={className} variant={variant}>
      {children}
    </CategoryOption.Title>
  );
};

export const CTA = (props: ComponentProps<typeof CategoryOption.CTA>) => {
  const { children, className } = props;

  return (
    <CategoryOption.CTA
      className={twMerge(
        'mt-3 flex flex-row items-center text-primary-6',
        className
      )}
    >
      {children}
      <ArrowRightIcon className="ml-1 h-4 w-4 stroke-2" />
    </CategoryOption.CTA>
  );
};

const Root = (props: {
  className?: string;
  cta?: ReactElement<typeof CategoryOption.Root>;
  description: string;
  icon: ReactElement<typeof Icon>;
  onClick?: () => void;
  tag?: ReactNode;
  title: ReactElement<typeof Title>;
}) => {
  const { className, cta, description, icon, onClick, tag, title } = props;

  return (
    <CategoryOption.Root
      className={twMerge('rounded-2xl bg-gradient-to-bl px-6 py-5', className)}
      onClick={onClick}
    >
      <div className="flex w-full items-center justify-between">
        {icon}
        {tag}
      </div>
      <div className="mt-2 flex-grow">
        {title}
        <CategoryOption.Description variant="sm-regular">
          {description}
        </CategoryOption.Description>
      </div>
      {cta}
    </CategoryOption.Root>
  );
};

export const DrawerCategoryOption = {
  Icon,
  Root,
  Title,
  CTA,
};
