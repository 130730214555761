import { ChevronDownIcon } from '@heroicons/react/24/outline';
import * as Accordion from '@radix-ui/react-accordion';
import type { ReactNode } from 'react';
import { defineMessages, useIntl } from 'react-intl';
import { twMerge } from 'tailwind-merge';

import { TextStyle } from '../../../DesignSystem/Feedback/TextStyle';

const messages = defineMessages({
  blockSectionTitle: {
    defaultMessage: 'Create and edit the content of this flow',
    id: 'GBlFp/',
  },
  distributionSectionTitle: {
    defaultMessage: `Set up this flow's distribution and reminders`,
    id: 'o82eyD',
  },
});

function Content({ className, ...tail }: Accordion.AccordionContentProps) {
  return (
    <Accordion.Content
      {...tail}
      className={twMerge('flex flex-col', className)}
    />
  );
}

function Trigger({
  className,
  index,
  title,
  subTitle,
  ...tail
}: Omit<Accordion.AccordionTriggerProps, 'asChild' | 'children'> & {
  index: number;
  title: ReactNode;
  subTitle?: ReactNode;
}) {
  return (
    <Accordion.Header className="w-full">
      <Accordion.Trigger
        className={twMerge(
          'group flex w-full items-center gap-2 p-4 data-[state=open]:border-b data-[state=open]:border-gray-5 data-[state=open]:bg-gray-9',
          className
        )}
        {...tail}
      >
        <ChevronDownIcon className="h-6 w-6 stroke-current text-gray-9 will-change-transform group-data-[state=open]:rotate-180 group-data-[state=open]:text-gray-1" />
        <TextStyle
          as="span"
          className="flex h-6 w-6 items-center justify-center rounded-full bg-gray-4 group-data-[state=open]:bg-primary-6 group-data-[state=open]:text-gray-1"
          variant="base-medium"
        >
          {index}
        </TextStyle>
        <div className="flex flex-1 flex-col items-start justify-start text-left group-data-[state=open]:text-gray-1">
          <TextStyle as="span" variant="lg-medium">
            {title}
          </TextStyle>
          {Boolean(subTitle) && (
            <TextStyle as="span" variant="base-regular">
              {subTitle}
            </TextStyle>
          )}
        </div>
      </Accordion.Trigger>
    </Accordion.Header>
  );
}

function Item({ className, ...tail }: Accordion.AccordionItemProps) {
  return (
    <Accordion.Item
      {...tail}
      className={twMerge(
        'box-content overflow-hidden rounded-lg border-gray-5 data-[state=closed]:border @[700px]/body:border',
        className
      )}
    />
  );
}

function Root({
  className,
  ...tail
}: Omit<Accordion.AccordionSingleProps, 'type'>) {
  return (
    <Accordion.Root
      {...tail}
      className={twMerge('flex flex-col gap-4', className)}
      type="single"
      collapsible
    />
  );
}

function BlocksItem({ children }: { children: ReactNode }) {
  const { formatMessage } = useIntl();

  return (
    <Item value="blocks">
      <Trigger index={1} title={formatMessage(messages.blockSectionTitle)} />
      <Content className="bg-gray-3 data-[state=open]:px-2 data-[state=open]:pb-4 data-[state=open]:pt-3 @[700px]/body:data-[state=open]:px-8">
        {children}
      </Content>
    </Item>
  );
}

function DistributionsItem({ children }: { children: ReactNode }) {
  const { formatMessage } = useIntl();

  return (
    <Item value="distributions">
      <Trigger
        index={2}
        title={formatMessage(messages.distributionSectionTitle)}
      />
      <Content className="bg-gray-3 data-[state=open]:px-2 data-[state=open]:pb-4 data-[state=open]:pt-3 @[700px]/body:data-[state=open]:px-8">
        {children}
      </Content>
    </Item>
  );
}

export const SectionAccordion = {
  Item,
  Root,
  Trigger,
  Content,
  BlocksItem,
  DistributionsItem,
};
