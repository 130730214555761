import { lazy, type ReactNode, Suspense, useEffect, useState } from 'react';
import { defineMessages, useIntl } from 'react-intl';

import { Banner } from '../../../DesignSystem/Feedback/Banner';
import { LoadingSpinner } from '../../../DesignSystem/Feedback/Icons/LoadingSpinner';
import { Modal } from '../../../DesignSystem/Feedback/Modal';
import { TextStyle } from '../../../DesignSystem/Feedback/TextStyle';
import { Button } from '../../../DesignSystem/Inputs/Button';
import { HorizontalRule } from '../../../DesignSystem/Layout/HorizontalRule';
import { RewardPlaceholderImage } from '../../assets/images';
import {
  G2ReviewModal,
  type G2ReviewModalProps,
} from '../Modals/G2ReviewModal/G2ReviewModal';
import { RedemptionDetailsModalSkeletonLoader } from './RedemptionDetailsModalSkeletonLoader';
import {
  RedemptionModalState,
  type RewardRedemptionErrorProps,
} from './RedemptionModal';

const CultureRewardRedemptionSuccessful = lazy(() =>
  import('./CultureRewardRedemptionSuccessModal').then((module) => ({
    default: module.CultureRewardRedemptionSuccessful,
  }))
);

const RewardRedemptionModalError = lazy(() =>
  import('./RewardRedemptionErrorModal').then((module) => ({
    default: module.RewardRedemptionModalError,
  }))
);

const messages = defineMessages({
  redeemReward: {
    defaultMessage: 'Redeem Reward',
    id: 'tTouHd',
  },
  selectGiftCardValue: {
    defaultMessage: 'Select gift card value',
    id: '0KEoIN',
  },
  cultureCardRedemptionPolicy: {
    defaultMessage: 'Culture card redemption policy',
    id: 'cTuUzu',
  },
  redeem: {
    defaultMessage: 'Redeem',
    id: 'XSdWHA',
  },
  close: {
    defaultMessage: 'Close',
    id: 'rbrahO',
  },

  rewardSecurityCode: {
    defaultMessage: 'Reward security code:',
    id: 'CgZiZK',
  },
  coordinateWithAdmin: {
    defaultMessage:
      'Your administrator, will contact you to coordinate your reward.',
    id: 'KR609M',
  },

  giftCard: {
    defaultMessage: 'Gift card',
    id: '4X0ZI8',
  },

  redemptionInformation: {
    defaultMessage:
      'Once you click ‘Redeem’, your reward will be sent to {email}.',
    id: 'kniKwh',
  },
  redemptionRefundWarning: {
    defaultMessage:
      'IMPORTANT: After clicking ‘Redeem’, Assembly cannot issue a refund for this reward.',
    id: 'aF+zN4',
  },
  insufficientBalance: {
    defaultMessage: 'Insufficient balance to redeem.',
    id: '45Qnq3',
  },

  cost: {
    defaultMessage: 'Cost',
    id: 'fBG/Ge',
  },
});

export type CultureRewardRedemptionInProgressProps = {
  rewardCardImage?: string;
  rewardName: string;
  rewardDescription: string;
  redemptionWarningBannerText?: string;
  onRedeemRewardClick: (id: string) => void;
  email: string;
  isCashOutRewardInProgress: boolean;
  cost: string;
  currencyIcon: ReactNode;
  id: string;
  setTitle?: (title: string) => void;
};

export type CultureRewardRedemptionSuccessfulProps = {
  rewardCardImage?: string;
  rewardName: string;
  rewardDescription: string;
  email: string;
  cost: string;
  currencyIcon: ReactNode;
  id: string;
  setTitle?: (title: string) => void;
};

type G2ReviewProps = {
  showG2Review: boolean;
} & G2ReviewModalProps;

export type CultureRewardRedemptionModalProps = {
  isOpen: boolean;
  onClose: () => void;
} & (
  | ({
      state: RedemptionModalState.Redeeming;
    } & CultureRewardRedemptionInProgressProps)
  | ({
      state: RedemptionModalState.Redeemed;
      g2Review: G2ReviewProps;
    } & CultureRewardRedemptionSuccessfulProps)
  | ({
      state: RedemptionModalState.Error;
    } & RewardRedemptionErrorProps)
);

export function CultureRewardRedemptionModal({
  isOpen,
  onClose,
  ...props
}: CultureRewardRedemptionModalProps) {
  const [title, setTitle] = useState<string | null>(null);

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      title={title}
      className="flex flex-col"
      headerClassName="pb-4 border-b border-gray-4 z-50"
      bodyClassName="p-0 flex flex-col flex-1"
    >
      {props.state === RedemptionModalState.Redeeming && (
        <CultureRewardRedemptionInProgress {...props} setTitle={setTitle} />
      )}
      {props.state === RedemptionModalState.Redeemed && (
        <Suspense fallback={<RedemptionDetailsModalSkeletonLoader />}>
          <CultureRewardRedemptionSuccessful
            {...props}
            handleRedemptionModalClose={onClose}
            setTitle={setTitle}
          />
          {Boolean(props.g2Review.showG2Review) && (
            <G2ReviewModal {...props.g2Review} />
          )}
        </Suspense>
      )}
      {props.state === RedemptionModalState.Error && (
        <Suspense fallback={<RedemptionDetailsModalSkeletonLoader />}>
          <RewardRedemptionModalError {...props} setTitle={setTitle} />
        </Suspense>
      )}
    </Modal>
  );
}

function CultureRewardRedemptionInProgress({
  rewardCardImage,
  rewardName,
  rewardDescription,
  cost,
  currencyIcon,
  redemptionWarningBannerText,
  onRedeemRewardClick,
  email,
  isCashOutRewardInProgress,
  id,
  setTitle,
}: CultureRewardRedemptionInProgressProps) {
  const { formatMessage } = useIntl();

  useEffect(() => {
    setTitle?.(formatMessage(messages.redeemReward));
  }, [formatMessage, setTitle]);

  return (
    <div className="flex flex-1 flex-col">
      <div className="flex-1 px-6">
        <div className="flex w-full flex-col items-center gap-2 p-4">
          <div className="flex w-full justify-center rounded-md">
            <img
              alt="Default placeholder for a Reward card"
              className="h-[125px] w-fit"
              src={rewardCardImage ? rewardCardImage : RewardPlaceholderImage}
            />
          </div>
          <TextStyle variant="base-medium">{rewardName}</TextStyle>
          <TextStyle variant="xs-regular" className="flex items-center gap-1">
            {formatMessage(messages.cost)}:
            <TextStyle
              as="span"
              className="flex items-center"
              variant="xs-bold"
            >
              {currencyIcon} {cost}
            </TextStyle>
          </TextStyle>
        </div>
        <div className="rounded-lg bg-gray-3 p-4">
          <TextStyle variant="xs-regular">{rewardDescription}</TextStyle>
        </div>
        <HorizontalRule className="py-4" />
        <TextStyle variant="sm-medium">
          {formatMessage(messages.cultureCardRedemptionPolicy)}
        </TextStyle>
        <div className="max-h-[200px] overflow-scroll">
          <TextStyle className="pb-4" variant="xs-regular">
            {formatMessage(messages.redemptionInformation, { email })}
          </TextStyle>
          <TextStyle className="pb-4" variant="xs-regular">
            {formatMessage(messages.coordinateWithAdmin)}
          </TextStyle>
          <TextStyle className="pb-4" variant="xs-regular">
            {formatMessage(messages.redemptionRefundWarning)}
          </TextStyle>
        </div>
      </div>
      <HorizontalRule />
      <footer className="flex flex-col gap-2 px-6 py-4">
        {Boolean(redemptionWarningBannerText) && (
          <Banner className="rounded-xl" status="info">
            <TextStyle variant="xs-regular">
              {redemptionWarningBannerText}
            </TextStyle>
          </Banner>
        )}
        <Button
          onClick={() => {
            onRedeemRewardClick(id);
          }}
          isLoading={isCashOutRewardInProgress}
          className="w-full"
          variation="primary"
        >
          {formatMessage(messages.redeem)}
          {Boolean(isCashOutRewardInProgress) && (
            <LoadingSpinner className="mr-2" />
          )}
        </Button>
      </footer>
    </div>
  );
}
