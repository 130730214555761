import { CheckIcon, ChevronUpIcon } from '@heroicons/react/24/outline';
import {
  Content,
  Item,
  Portal,
  Root,
  Trigger,
} from '@radix-ui/react-dropdown-menu';
import { type ReactNode, useState } from 'react';
import { twMerge } from 'tailwind-merge';
import type { MergeExclusive } from 'type-fest';

import { TextStyle } from '../../../DesignSystem/Feedback/TextStyle';
import type { ButtonProps } from '../../../DesignSystem/Inputs/Button';
import { Button } from '../../../DesignSystem/Inputs/Button';
import { classNames } from '../../../DesignSystem/Utils/classNames';

export type PermissionOption = {
  id: string;
  label: string;
  subText?: string;
  displayLabel: string;
  isDelete?: boolean;
  icon?: ReactNode;
};

export type GroupedDropdownOptions = {
  id: string;
  options: PermissionOption[];
}[];

type PermissionDropdownProps = MergeExclusive<
  {
    selectedOption: PermissionOption;
    groupedDropdownOptions: GroupedDropdownOptions;
    onSelected: (value: PermissionOption) => void;
    triggerClassName?: string;
    triggerButtonVariant?: ButtonProps['size'];
    contentClassName?: string;
    disabled?: boolean;
    tooltipText?: string;
  },
  {
    triggerButtonLabel: string;
    children: ReactNode;
  }
>;

export function PermissionDropdown({
  selectedOption,
  children,
  groupedDropdownOptions,
  onSelected,
  triggerButtonLabel,
  triggerClassName,
  triggerButtonVariant = 'small',
  contentClassName,
  disabled,
  tooltipText,
}: PermissionDropdownProps) {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div className="relative inline-block w-full text-left">
      <Root open={!disabled && isOpen} onOpenChange={setIsOpen}>
        <Trigger asChild>
          <Button
            disabled={disabled}
            tooltipText={tooltipText}
            className={twMerge(
              'pointer-events-auto w-full justify-between',
              isOpen && 'bg-gray-3 ring-2 ring-primary-6 ring-offset-2',
              triggerClassName
            )}
            size={triggerButtonVariant}
            variation="tertiaryLite"
            aria-label={`permissionDropdown-${groupedDropdownOptions?.[0].id}`}
          >
            {selectedOption?.icon}
            {selectedOption?.displayLabel}
            {triggerButtonLabel}
            {!disabled && (
              <ChevronUpIcon
                className={twMerge(
                  'h-4 w-4 text-gray-8',
                  !isOpen && '-rotate-180'
                )}
              />
            )}
          </Button>
        </Trigger>
        <Portal>
          <Content
            align="end"
            className={twMerge(
              'z-50 rounded-md border-gray-5 bg-gray-1 py-1 shadow-md-down',
              contentClassName
            )}
            sideOffset={5}
            aria-label="permissionDropdownOptions"
          >
            {children}
            {groupedDropdownOptions?.map((group, index) => (
              <>
                {group.options.map((option) => (
                  <Item
                    key={option.id}
                    onClick={() => {
                      onSelected(option);
                    }}
                    tabIndex={0}
                    className="cursor-pointer bg-gray-1 hover:bg-gray-3 focus:bg-gray-3 focus:outline-none"
                    aria-label={`dropdownOption-${option.id}`}
                  >
                    <div className="inline-flex w-full items-center justify-start gap-2 px-3 py-2">
                      <div
                        className={classNames(
                          'shrink grow basis-0 text-sm leading-snug',
                          {
                            'font-normal text-error-6': option.isDelete,
                            'text-gray-8': !option.isDelete,
                          }
                        )}
                      >
                        {option.label}
                        {Boolean(option.subText) && (
                          <TextStyle variant="xs-regular" className="w-[210px]">
                            {option.subText}
                          </TextStyle>
                        )}
                      </div>
                      {selectedOption.id === option.id && (
                        <CheckIcon className="h-4 w-4 text-primary-5" />
                      )}
                    </div>
                  </Item>
                ))}
                {index !== groupedDropdownOptions.length - 1 && (
                  <div className="border-b border-solid border-b-gray-5" />
                )}
              </>
            ))}
          </Content>
        </Portal>
      </Root>
    </div>
  );
}
