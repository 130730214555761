import { InformationCircleIcon } from '@heroicons/react/20/solid';
import { useEffect, useMemo, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useIntl } from 'react-intl';
import type { z } from 'zod';

import { getSchema, TextField, Tooltip } from '../../../../..';
import { TextStyle } from '../../../../../DesignSystem/Feedback/TextStyle';
import { NestedDropdownMenu } from '../../../../../DesignSystem/Inputs/NestedDropdownMenu';
import { messages } from '../message';
import { inputClassName, labelClassName } from './ProofSettings';

const maxLimitForTotalClaim = 10000;

export function ClaimsSettings({
  disabled,
  tooltipText,
}: {
  disabled: boolean;
  tooltipText?: string;
}) {
  const { formatMessage } = useIntl();

  const schema = getSchema(formatMessage);
  type FormFields = z.infer<typeof schema>;
  const {
    register,
    getValues,
    watch,
    formState: { errors },
    setValue,
  } = useFormContext<FormFields>();

  const totalClaimLimitOptions = useMemo(
    () => [
      {
        value: 'unlimited',
        label: formatMessage(messages.unlimited),
      },
      {
        value: 'limited',
        label: formatMessage(messages.limited),
      },
    ],
    [formatMessage]
  );
  const watchIndividualClaimLimit = watch('individualClaimLimit');
  const { onChange, name, ref } = register('individualClaimLimit');

  const [claimLimit, setClaimLimit] = useState(
    getValues('totalClaimLimit') > -1
      ? totalClaimLimitOptions[1]
      : totalClaimLimitOptions[0]
  );

  useEffect(() => {
    if (getValues('totalClaimLimit') > -1) {
      setClaimLimit(totalClaimLimitOptions[1]);
    } else {
      setClaimLimit(totalClaimLimitOptions[0]);
    }
  }, [getValues, totalClaimLimitOptions]);

  const updateClaimLimit = (option: { value: string; label: string }) => {
    setClaimLimit(option);
    if (option.value === 'limited') {
      setValue('totalClaimLimit', 1);
    } else {
      setValue('totalClaimLimit', -1);
    }
  };
  return (
    <div className="flex flex-col gap-3">
      <div className="flex flex-col gap-1">
        <div className="flex items-center gap-1">
          <TextStyle as="span" variant="sm-medium" className="text-gray-9">
            {formatMessage(messages.totalClaimLimitTitle)}
          </TextStyle>
          <Tooltip
            tooltipText={formatMessage(messages.totalLimitToolTip)}
            contentClassName="relative z-[200]"
            side="top"
          >
            <InformationCircleIcon className="h-4 w-4" tabIndex={0} />
          </Tooltip>
        </div>

        <div className="flex items-baseline gap-2">
          <NestedDropdownMenu
            className="w-52 grow"
            triggerClassName="text-gray-9"
            contentClassName={
              claimLimit.value === 'unlimited' ? 'w-[520px]' : ''
            }
            alignmentOptions={{
              align: 'start',
            }}
            selectedValue={claimLimit}
            options={totalClaimLimitOptions}
            onSelected={updateClaimLimit}
            placeholder={'Select claim Limit'}
            disabled={disabled}
            tooltipText={tooltipText}
          />
          {claimLimit.value === 'limited' && (
            <>
              <TextField
                {...register('totalClaimLimit')}
                type="number"
                className="w-52"
                step="1"
                min="1"
                inputClassName={inputClassName}
                labelClassName={labelClassName}
                aria-label="totalClaimLimit"
                onBlur={(e) => {
                  if (Number(e.target.value) < 1) {
                    setValue('totalClaimLimit', 1);
                  }
                  if (Number(e.target.value) > maxLimitForTotalClaim) {
                    setValue('totalClaimLimit', maxLimitForTotalClaim);
                  }
                }}
                disabled={disabled}
                tooltipText={tooltipText}
                isInvalid={Boolean(errors.totalClaimLimit?.message)}
                invalidText={errors.totalClaimLimit?.message ?? ''}
              />
              <TextStyle
                as="span"
                variant="sm-regular"
                className="text-primary"
              >
                {formatMessage(messages.totalClaims)}
              </TextStyle>
            </>
          )}
        </div>
        <TextStyle as="span" className="text-gray-8" variant="xs-regular">
          {formatMessage(messages.totalClaimLimitHintLabel, {
            limit: claimLimit.value,
          })}
        </TextStyle>
      </div>
      <div className="flex flex-col gap-1">
        <div className="flex items-center gap-1">
          <TextStyle as="span" variant="sm-medium">
            {formatMessage(messages.individualClaimLimitTitle)}
          </TextStyle>
          <Tooltip
            tooltipText={formatMessage(messages.individualClaimLimitTooltip)}
            contentClassName="relative z-[200]"
            side="top"
          >
            <InformationCircleIcon className="h-4 w-4" tabIndex={0} />
          </Tooltip>
        </div>

        <div className="flex items-baseline gap-3">
          <TextField
            onChange={onChange}
            onBlur={(e) => {
              if (Number(e.target.value) > 500) {
                setValue('individualClaimLimit', 500);
              } else if (Number(e.target.value) < 1) {
                setValue('individualClaimLimit', 1);
              }
            }}
            name={name}
            ref={ref}
            type="number"
            className="w-[70%]"
            isInvalid={
              watchIndividualClaimLimit > 500 ||
              Boolean(errors.individualClaimLimit?.message)
            }
            step="1"
            min="1"
            max="500"
            inputClassName={inputClassName}
            labelClassName={labelClassName}
            aria-label="individualClaimLimit"
            disabled={disabled}
            invalidText={errors.individualClaimLimit?.message ?? ''}
            helpText={formatMessage(messages.individualClaimLimitHintLabel)}
            tooltipText={tooltipText}
          />
          <TextStyle as="span" variant="sm-regular">
            {formatMessage(messages.numberOfTimes)}
          </TextStyle>
        </div>
      </div>
    </div>
  );
}
