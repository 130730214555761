import type { FC, ReactNode } from 'react';
import { createContext, useContext, useId } from 'react';

const IdContext = createContext<string | null>(null);

export const IdContextProvider: FC<{ children: ReactNode }> = ({
  children,
}) => {
  return <IdContext.Provider value={useId()}>{children}</IdContext.Provider>;
};

export const useIdContext = () => {
  const context = useContext(IdContext);
  if (context === null) {
    throw new Error('useIdContext must be used within a IdContextProvider');
  }
  return context;
};
