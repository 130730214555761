import parse from 'html-react-parser';
import { useMemo } from 'react';

import { mapHexCodeToEmoticon } from '../flowUtils';
import { AssemblyCurrencyType } from '../types/currency';
import { useUserDetails } from './useUserDetails';

export const useDisplayCurrency = () => {
  const { data: currentUserData } = useUserDetails();

  const currency = currentUserData?.assembly.currency;

  const icon = useMemo(() => {
    if (currency) {
      const { value, type, name } = currency;
      return type === AssemblyCurrencyType.Custom
        ? `<img alt=${name} class="mr-0.5 h-3 w-3 inline-block" src=${value} />`
        : mapHexCodeToEmoticon(value);
    }

    return mapHexCodeToEmoticon('1f3c6');
  }, [currency]);

  return parse(icon);
};

export const useCurrency = () => {
  const { data: currentUserData } = useUserDetails();

  return (
    currentUserData?.assembly.currency ?? {
      value: '1f3c6',
      type: AssemblyCurrencyType.Regular,
      name: 'coin',
      pluralName: 'Points',
    }
  );
};
