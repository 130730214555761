import {
  AssemblyCurrencyType,
  mapHexCodeToEmoticon,
} from '@assembly-web/services';
import { ChevronDownIcon } from '@heroicons/react/20/solid';
import { Content, Portal, Root, Trigger } from '@radix-ui/react-popover';
import parse from 'html-react-parser';
import { type ChangeEvent, type KeyboardEvent, useState } from 'react';
import { Separator } from 'react-aria-components';
import { defineMessages, useIntl } from 'react-intl';
import { twMerge } from 'tailwind-merge';

import { Button } from '../../../DesignSystem/Inputs/Button';
import { TextField } from '../../../DesignSystem/Inputs/TextField';

const messages = defineMessages({
  clearAll: {
    defaultMessage: 'Clear all',
    id: 'QW+Q5N',
  },
  done: {
    defaultMessage: 'Done',
    id: 'JXdbo8',
  },
  over: {
    defaultMessage: 'Over',
    id: 'ZMDaHM',
  },
  under: {
    defaultMessage: 'Under',
    id: 'uhROer',
  },
  range: {
    defaultMessage: 'range',
    id: '7HW2G0',
  },
  minMaxPlaceholder: {
    defaultMessage: `Enter a value`,
    id: 'pndXMi',
  },
  maximum: {
    defaultMessage: 'Maximum',
    id: 'BCzmdv',
  },
  minimum: {
    defaultMessage: 'Minimum',
    id: 'Amt/8b',
  },
  minLowerThanMax: {
    defaultMessage: 'Min must be lower than max',
    id: 'hKkB1F',
  },
});

type RewardsRangeDropdownProps = {
  label: string;
  icon: string;
  iconType: AssemblyCurrencyType;
  minimumValue?: number;
  maximumValue?: number;
  onChange: ({ min, max }: { min: number; max: number }) => void;
  className?: string;
  prefixLabel?: string;
};

export function RewardsRangeDropdown({
  label,
  icon,
  iconType,
  minimumValue,
  maximumValue,
  onChange,
  className,
  prefixLabel,
}: RewardsRangeDropdownProps) {
  const [min, setMin] = useState(minimumValue ?? 0);
  const [max, setMax] = useState(maximumValue ?? 0);
  const [isOpen, setIsOpen] = useState(false);
  const iconVal = (() =>
    iconType === AssemblyCurrencyType.Custom
      ? `<img alt=${label} className="mr-0.5 h-3 w-3" src=${icon} />`
      : mapHexCodeToEmoticon(icon))();

  const valueString =
    min !== 0 && max !== 0 ? `${iconVal}${min} - ${iconVal}${max}` : '';
  const [value, setValue] = useState(valueString);

  const { formatMessage } = useIntl();

  const buildValueMessage = (min: number, max: number) => {
    const formatCurrency = (amount?: number) => `${iconVal}${amount ?? ''}`;
    let valueMessage = '';

    if (Number.isNaN(min) && max > 0) {
      valueMessage = `${formatMessage(messages.under)} ${formatCurrency(max)}`;
    } else if (min > 0 && Number.isNaN(max)) {
      valueMessage = `${formatMessage(messages.over)} ${formatCurrency(min)}`;
    } else if (Number.isNaN(min) && Number.isNaN(max)) {
      valueMessage = '';
    } else {
      valueMessage = `${formatCurrency(min)} - ${formatCurrency(max)}`;
    }
    return valueMessage;
  };

  const handleSetRange = () => {
    setValue(buildValueMessage(min, max));
    onChange({ min, max });
  };

  const handleChange = (
    e: ChangeEvent<HTMLInputElement>,
    type: 'min' | 'max'
  ) =>
    type === 'min'
      ? setMin(parseInt(e.target.value))
      : setMax(parseInt(e.target.value));

  return (
    <div
      className={twMerge(
        'flex w-full items-center justify-between rounded align-middle',
        className
      )}
    >
      <Root open={isOpen} onOpenChange={setIsOpen}>
        <Trigger
          asChild
          className="group flex h-8 w-full justify-between text-sm text-gray-9"
        >
          <button
            className={twMerge(
              'flex items-center gap-2 rounded-lg border border-gray-5 bg-gray-1 px-2 py-1 align-middle hover:bg-gray-3 focus:bg-gray-3',
              value !== '' && 'bg-gray-3 font-medium hover:bg-gray-4'
            )}
          >
            <span className="flex items-center text-gray-9">
              {value !== '' ? (
                prefixLabel ? (
                  parse(`${prefixLabel} : ${value}`)
                ) : (
                  parse(value)
                )
              ) : (
                <>
                  {parse(iconVal)} {label} {formatMessage(messages.range)}
                </>
              )}
            </span>
            <ChevronDownIcon
              className={
                'ms-4 h-5 w-5 rounded-full text-gray-9 group-aria-expanded:rotate-180'
              }
            />
          </button>
        </Trigger>
        <Portal>
          <Content
            align="start"
            className="my-2 min-w-[256px] rounded-lg border border-gray-4 bg-gray-1 p-4 shadow-lg-down max-md:mx-auto max-md:w-[90vw]"
          >
            <div className="mb-6 flex items-center justify-between gap-2">
              <TextField
                isInvalid={max < min}
                invalidText={formatMessage(messages.minLowerThanMax)}
                label={formatMessage(messages.minimum)}
                type="number"
                connectedLeft={parse(iconVal)}
                onChange={(e: ChangeEvent<HTMLInputElement>) =>
                  handleChange(e, 'min')
                }
                placeholder={formatMessage(messages.minMaxPlaceholder)}
                value={min}
                min={0}
                style={{
                  appearance: 'textfield',
                }}
                labelClassName="inline-block mb-1 font-medium text-gray-9 text-sm"
              />
              <Separator
                className={twMerge(
                  'relative top-3 w-8 rounded-lg border-2 text-gray-5',
                  max < min && 'top-1'
                )}
              />
              <TextField
                isInvalid={max < min}
                invalidText={formatMessage(messages.minLowerThanMax)}
                label={formatMessage(messages.maximum)}
                type="number"
                onChange={(e: ChangeEvent<HTMLInputElement>) =>
                  handleChange(e, 'max')
                }
                connectedLeft={parse(iconVal)}
                placeholder={formatMessage(messages.minMaxPlaceholder)}
                value={max}
                min={0}
                style={{
                  appearance: 'textfield',
                }}
                labelClassName="inline-block mb-1 font-medium text-gray-9 text-sm"
              />
            </div>
            <div className="flex gap-2">
              <Button
                onClick={() => {
                  handleSetRange();
                  setIsOpen(false);
                }}
                onKeyDown={(e: KeyboardEvent<HTMLButtonElement>) => {
                  if (e.key === 'Enter') {
                    handleSetRange();
                    setIsOpen(false);
                  }
                }}
                variation="secondaryEmphasized"
                className="h-8 flex-1 rounded border px-2 py-1 align-middle font-medium hover:bg-gray-4 focus:bg-gray-4 disabled:border-gray-5"
                disabled={max < min}
              >
                {formatMessage(messages.done)}
              </Button>
              <Button
                variation="secondaryLite"
                onClick={() => {
                  setMin(NaN);
                  setMax(NaN);
                  setValue('');
                  setIsOpen(false);
                }}
                onKeyDown={(e: KeyboardEvent<HTMLButtonElement>) => {
                  if (e.key === 'Enter') {
                    setMin(NaN);
                    setMax(NaN);
                    setValue('');
                    setIsOpen(false);
                  }
                }}
                className="h-8 rounded border border-gray-5 px-2 py-1 align-middle font-medium hover:bg-gray-4 focus:bg-gray-4"
              >
                {formatMessage(messages.clearAll)}
              </Button>
            </div>
          </Content>
        </Portal>
      </Root>
    </div>
  );
}
