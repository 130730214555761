import {
  AssemblyCurrencyType,
  mapHexCodeToEmoticon,
  type Timeout,
} from '@assembly-web/services';
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import { mergeRegister } from '@lexical/utils';
import parse from 'html-react-parser';
import { COMMAND_PRIORITY_EDITOR, createCommand } from 'lexical';
import { useEffect, useRef } from 'react';
import { twMerge } from 'tailwind-merge';

import { TextStyle } from '../../../../../DesignSystem/Feedback/TextStyle';
import { useMobileOrTouchDevice } from '../../../../hooks/useMobileOrTouchDevice';
import {
  BOOST_POPUP_CLOSE_COMMAND,
  BOOST_POPUP_CONTENT_HOVERED_COMMAND,
  MENTION_NODE_CLICK_COMMAND,
  MENTION_NODE_COMMAND,
  type MentionedUser,
} from '../plugins/MentionsPlugin';

export const EDITING_REPLY = createCommand('EDITING_REPLY');

type MentionComponentProps = {
  id: string;
  name: string;
  nodeKey: string;
  mention: MentionedUser;
  isCurrentUser: boolean;
  type: 'boost-node' | 'mention-node';
};

const CLEAR_BOOST_CLOSE_TIMER = 'CLEAR_BOOST_CLOSE_TIMER';

export const MentionComponentClassName =
  'mb-1 inline-block cursor-pointer select-none whitespace-nowrap rounded bg-primary-6/10 px-1 text-base text-primary-6 hover:bg-primary-6/20';

export const MentionComponent = ({
  id,
  name,
  type,
  mention,
  isCurrentUser,
}: MentionComponentProps) => {
  const ref = useRef<HTMLElement | null>(null);
  const openTimerRef = useRef<Timeout | null>(null);
  const closeTimerRef = useRef<Timeout | null>(null);

  const [editor] = useLexicalComposerContext();

  const isMobileOrTouchDevice = useMobileOrTouchDevice();

  useEffect(() => {
    return mergeRegister(
      editor.registerCommand(
        BOOST_POPUP_CONTENT_HOVERED_COMMAND,
        () => {
          // When the user hovers over the popup content, we want to cancel the close timer
          // which is set when the user leaves the mention node
          if (closeTimerRef.current) {
            clearTimeout(closeTimerRef.current);
            closeTimerRef.current = null;
          }
          return false;
        },
        COMMAND_PRIORITY_EDITOR
      )
    );
  }, [editor]);

  useEffect(() => {
    editor.dispatchCommand(MENTION_NODE_COMMAND, {
      node: ref.current,
    });
  }, [editor]);

  useEffect(() => {
    if (type === 'boost-node') {
      editor.dispatchCommand(MENTION_NODE_CLICK_COMMAND, {
        mention,
        node: ref.current,
      });
    }
  }, [editor, mention, type]);

  useEffect(() => {
    const clearBoostCloseTimer = () => {
      if (closeTimerRef.current) {
        clearTimeout(closeTimerRef.current);
        closeTimerRef.current = null;
      }
    };

    // Custom event lister to clear the close timer
    window.addEventListener(CLEAR_BOOST_CLOSE_TIMER, clearBoostCloseTimer);

    return () => {
      window.removeEventListener(CLEAR_BOOST_CLOSE_TIMER, clearBoostCloseTimer);
    };
  }, []);

  useEffect(() => {
    return () => {
      if (openTimerRef.current) {
        clearTimeout(openTimerRef.current);
      }

      if (closeTimerRef.current) {
        clearTimeout(closeTimerRef.current);
      }
    };
  }, []);

  const currencyIcon =
    mention.currency?.type === AssemblyCurrencyType.Custom
      ? `<img alt=${mention.currency.name} class="h-4 w-4 inline-block" src=${mention.currency.value} />`
      : mention.currency?.value && mapHexCodeToEmoticon(mention.currency.value);

  return (
    <span
      ref={ref}
      onClick={() => {
        editor.dispatchCommand(MENTION_NODE_CLICK_COMMAND, {
          mention,
          node: ref.current,
        });
      }}
      {...(!isMobileOrTouchDevice && {
        onPointerLeave: () => {
          if (openTimerRef.current) {
            // if the user quickly hovers over the mention node and leaves, we want to cancel the open timer
            clearTimeout(openTimerRef.current);
            openTimerRef.current = null;
          }

          closeTimerRef.current = setTimeout(() => {
            editor.dispatchCommand(BOOST_POPUP_CLOSE_COMMAND, null);
          }, 700);
        },
        onPointerEnter: () => {
          if (closeTimerRef.current) {
            clearTimeout(closeTimerRef.current);
            closeTimerRef.current = null;
          }

          // cancel all the timers set on the other mention nodes
          // scenario: when user hovers over a mention node, we want to cancel the close timer of the other mention nodes
          window.dispatchEvent(new Event(CLEAR_BOOST_CLOSE_TIMER));
          openTimerRef.current = setTimeout(() => {
            editor.dispatchCommand(MENTION_NODE_CLICK_COMMAND, {
              mention,
              node: ref.current,
            });
          }, 300);
        },
      })}
      {...(isMobileOrTouchDevice && {
        onTouchStart: () => {
          window.dispatchEvent(new Event(CLEAR_BOOST_CLOSE_TIMER));
        },
      })}
      onKeyDown={(e) => {
        if (e.key === 'Enter') {
          editor.dispatchCommand(MENTION_NODE_CLICK_COMMAND, {
            mention,
            node: ref.current,
          });
        }
      }}
      role="button"
      tabIndex={0}
      className={twMerge(
        MentionComponentClassName,
        mention.isEditing && 'bg-gray-4 text-gray-7'
      )}
      data-lexical-mention="true"
      data-lexical-mention-id={id}
      data-lexical-mention-name={name}
      data-lexical-node-type={mention.points ? 'boost-node' : 'mention-node'}
    >
      {name} {isCurrentUser ? '(you)' : ''}
      {mention.points && !mention.pointsHidden && currencyIcon ? (
        <>
          +
          {parse(currencyIcon, {
            transform(reactNode) {
              return (
                <TextStyle className="inline-block">{reactNode}</TextStyle>
              );
            },
          })}
          {mention.points}
        </>
      ) : null}
    </span>
  );
};
