import type { Options } from 'pusher-js';
import Pusher from 'pusher-js';
import type Ajax from 'pusher-js/types/src/core/http/ajax';
import { createContext, type ReactNode, useEffect, useState } from 'react';

import { ChannelsProvider } from './ChannelsProvider';

type PusherProviderProps = {
  clientKey: string;
  children: ReactNode;
} & Options;

export type PusherContextValue = Pusher | null;

declare global {
  // eslint-disable-next-line @typescript-eslint/consistent-type-definitions
  interface Window {
    PusherClientContext?: PusherContextValue;
  }
}

export const PusherContext = createContext<PusherContextValue>(null);

export function PusherProvider(props: PusherProviderProps) {
  const { clientKey, children, ...options } = props;

  const [pusher, setPusher] = useState<PusherContextValue>(null);

  useEffect(() => {
    // ToDO: This XHR configuration won't work in the assembly mobile app.
    // Refer the implementation in assembly-frontend repo to implement the same.
    Pusher.Runtime.createXHR = () => {
      const xhr = new XMLHttpRequest();
      xhr.withCredentials = true;
      return xhr as Ajax;
    };
  }, []);

  useEffect(() => {
    if (!window.PusherClientContext && !pusher) {
      window.PusherClientContext = new Pusher(clientKey, {
        ...options,
      });

      setPusher(window.PusherClientContext);
    }

    return () => {
      pusher?.disconnect();
    };
  }, [clientKey, options, pusher]);

  return (
    <PusherContext.Provider value={pusher}>
      <ChannelsProvider>{children}</ChannelsProvider>
    </PusherContext.Provider>
  );
}
