import { CheckIcon, ChevronDownIcon } from '@heroicons/react/24/outline';
import {
  Content as CollapsibleContent,
  Root as CollapsibleRoot,
  Trigger as CollapsibleTrigger,
} from '@radix-ui/react-collapsible';
import { Content, Portal, Root, Trigger } from '@radix-ui/react-popover';
import { useState } from 'react';
import { defineMessages, useIntl } from 'react-intl';
import { twMerge } from 'tailwind-merge';

import { Tooltip } from '../Feedback/Tooltip';
import { Button } from './Button';
import type { DropdownMenuAlignmentOptions } from './DropdownMenu';

const messages = defineMessages({
  clear: {
    defaultMessage: 'Clear',
    id: '/GCoTA',
  },
});

export type NestedOption = {
  value: string;
  label: string;
  options?: NestedOption[];
  isCollapsibleTriggerSelectable?: boolean;
};

export type NestedDropdownMenuProps = {
  options: NestedOption[];
  onSelected: (option: NestedOption) => void;
  className?: string;
  placeholder: string;
  selectedValue?: NestedOption;
  alignmentOptions?: DropdownMenuAlignmentOptions;
  triggerClassName?: string;
  contentClassName?: string;
  disabled?: boolean;
  showClearButton?: boolean;
  prefixLabel?: string;
  tooltipText?: string;
};

export function NestedDropdownMenu({
  options,
  onSelected,
  className,
  placeholder,
  selectedValue,
  alignmentOptions,
  triggerClassName,
  contentClassName,
  disabled,
  showClearButton,
  prefixLabel,
  tooltipText,
}: NestedDropdownMenuProps) {
  const { formatMessage } = useIntl();
  const [value, setValue] = useState<string>(() =>
    selectedValue ? selectedValue.label : ''
  );
  const [isOpen, setIsOpen] = useState(false);

  const buttonText =
    value === ''
      ? placeholder
      : prefixLabel
        ? `${prefixLabel}: ${value}`
        : value;

  const handleSelected = (option: NestedOption, isOpen = false) => {
    setValue(option.label);
    onSelected(option);
    setIsOpen(isOpen);
  };

  const handleClearClick = () => {
    setValue('');
    setIsOpen(false);
  };

  return (
    <div
      className={twMerge(
        'flex w-full items-center justify-between rounded-lg border border-gray-5 align-middle',
        className,
        disabled && 'bg-gray-2'
      )}
    >
      <Root open={isOpen} onOpenChange={() => setIsOpen(!isOpen)}>
        <Tooltip tooltipText={tooltipText}>
          <Trigger
            asChild
            disabled={disabled}
            className={twMerge(
              'group flex h-10 w-full items-center justify-between px-2 text-sm text-gray-8',
              triggerClassName,
              disabled && 'cursor-not-allowed text-gray-7'
            )}
          >
            <button className="flex items-center gap-2">
              {buttonText}
              <ChevronDownIcon className="h-4 w-4 text-gray-9 group-aria-expanded:rotate-180" />
            </button>
          </Trigger>
        </Tooltip>

        <Portal>
          <Content
            {...(alignmentOptions ? alignmentOptions : {})}
            className="z-[50]"
          >
            <div
              className={twMerge(
                'mt-2 min-w-[256px] rounded border border-gray-4 bg-gray-1 shadow-lg-down',
                contentClassName
              )}
            >
              <NestedDropdownMenuItems
                options={options}
                onSelected={(option, isOpen = false) => {
                  handleSelected(option, isOpen);
                }}
                depth={0}
                selectedValue={selectedValue}
              />
              {Boolean(showClearButton) && (
                <div className="grid w-full grid-cols-[1fr_auto] justify-between gap-2 rounded-b-lg bg-gray-1 p-2">
                  <Button
                    size="small"
                    variation="secondaryLite"
                    onClick={handleClearClick}
                  >
                    {formatMessage(messages.clear)}
                  </Button>
                </div>
              )}
            </div>
          </Content>
        </Portal>
      </Root>
    </div>
  );
}

type NestedDropdownMenuItemsProps = {
  options: NestedOption[];
  onSelected: (value: NestedOption, isOpen?: boolean) => void;
  depth: number;
  selectedValue?: NestedOption;
};

export function NestedDropdownMenuItems({
  options,
  onSelected,
  depth,
  selectedValue,
}: NestedDropdownMenuItemsProps) {
  const [openCollapsibles, setOpenCollapsibles] = useState<string[]>([]);
  const handleNestedSelected = (option: NestedOption, isOpen = false) => {
    onSelected(option, isOpen);
  };

  const toggleCollapsible = (optionValue: string) => {
    if (openCollapsibles.includes(optionValue)) {
      setOpenCollapsibles(
        openCollapsibles.filter((val) => val !== optionValue)
      );
    } else {
      setOpenCollapsibles([...openCollapsibles, optionValue]);
    }
  };

  return (
    <div
      className={twMerge(
        'my-1 text-sm',
        depth === 0 && 'max-h-[120px] overflow-y-auto'
      )}
    >
      {options.map((option) => (
        <div className="mx-auto rounded" key={option.value}>
          <CollapsibleRoot
            onOpenChange={() => toggleCollapsible(option.value)}
            open={openCollapsibles.includes(option.value)}
          >
            <CollapsibleTrigger
              className="group w-full"
              data-testid="nested-dropdown-menu-item"
              onKeyDown={(e) => {
                if (
                  (!option.options || option.isCollapsibleTriggerSelectable) &&
                  e.key === 'Enter'
                ) {
                  onSelected(
                    option,
                    option.options && option.isCollapsibleTriggerSelectable
                  );
                }
              }}
              onClick={(e) => {
                if (!option.options || option.isCollapsibleTriggerSelectable) {
                  e.stopPropagation();
                  onSelected(
                    option,
                    option.options && option.isCollapsibleTriggerSelectable
                  );
                }
              }}
            >
              <div className="flex items-center justify-between px-4 text-gray-8 hover:bg-gray-3">
                <div
                  role="button"
                  style={{ paddingLeft: `${depth * 12}px` }}
                  tabIndex={0}
                  className="bg-gray-100 hover:bg-gray-300 flex h-10 items-center text-left"
                >
                  {option.label}
                </div>
                {selectedValue?.value === option.value &&
                  !option.isCollapsibleTriggerSelectable && (
                    <CheckIcon className="h-4 w-4 text-primary-6" />
                  )}
                {Array.isArray(option.options) && option.options.length > 0 && (
                  <ChevronDownIcon
                    className="h-4 w-4 text-gray-9 group-aria-expanded:rotate-180"
                    onClick={(event) => {
                      event.stopPropagation();
                      toggleCollapsible(option.value);
                    }}
                  />
                )}
              </div>
            </CollapsibleTrigger>

            {Array.isArray(option.options) && option.options.length > 0 && (
              <CollapsibleContent className="text-gray-500 border-gray-5">
                <NestedDropdownMenuItems
                  options={option.options}
                  onSelected={handleNestedSelected}
                  depth={depth + 1}
                  selectedValue={selectedValue}
                />
              </CollapsibleContent>
            )}
          </CollapsibleRoot>
        </div>
      ))}
    </div>
  );
}
