import {
  type FocusEventHandler,
  type MouseEventHandler,
  useCallback,
  useMemo,
  useRef,
  useState,
} from 'react';
import { flushSync } from 'react-dom';

import { useDeviceInfo } from '../../../UI/hooks/useDeviceInfo';
import { useTouchDevice } from '../../../UI/hooks/useTouchDevice';
import type { ToolbarProps } from './Toolbar';

type ContainerElementEvents<TElement extends HTMLElement> = {
  onMouseOver?: MouseEventHandler<TElement>;
  onMouseLeave?: MouseEventHandler<TElement>;
  onFocus?: FocusEventHandler<TElement>;
  onBlur?: FocusEventHandler<TElement>;
};

type CommonToolbarProps = Pick<
  ToolbarProps,
  'onClosed' | 'onMenuItemClick' | 'onToggle'
>;

type ToolbarVisibilityProp = Pick<ToolbarProps, 'hideToolbar'> &
  Required<Pick<ToolbarProps, 'isMenuOpen'>>;

export type GetContainerProps<TElement extends HTMLElement> = (
  props?: ContainerElementEvents<TElement>
) => Required<ContainerElementEvents<TElement>> & {
  tabIndex: 0;
  role: 'presentation';
};

export type GetToolbarProps = (
  props?: Partial<CommonToolbarProps>
) => Required<CommonToolbarProps & ToolbarVisibilityProp>;

type ToolbarStateProps = {
  supportsTouch?: boolean;
} & Partial<ToolbarVisibilityProp>;

export const useToolbarState = <TElement extends HTMLElement>(
  { supportsTouch, hideToolbar }: ToolbarStateProps = {
    supportsTouch: true,
    hideToolbar: undefined,
  }
) => {
  const [isToolbarActive, setIsToolbarActive] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const isMenuOpenRef = useRef(false);

  const isMobileDevice = useDeviceInfo().deviceType === 'mobile';
  const isTouchScreenDeice = useTouchDevice();
  const touchOrMobileDevice = isMobileDevice || isTouchScreenDeice;

  const toolbarHiddenOnTouchDevice = supportsTouch ? false : !isToolbarActive;
  const toolbarActiveOnTouchDevice = supportsTouch ? true : isToolbarActive;
  const toolbarHidden =
    hideToolbar ??
    (touchOrMobileDevice ? toolbarHiddenOnTouchDevice : !isToolbarActive);
  const toolbarActive =
    hideToolbar ??
    (touchOrMobileDevice ? toolbarActiveOnTouchDevice : isToolbarActive);

  const getContainerProps: GetContainerProps<TElement> = useCallback(
    (props) => ({
      onMouseOver: (e) => {
        flushSync(() => setIsToolbarActive(true));

        props?.onMouseOver?.(e);
      },
      onMouseLeave: (e) => {
        if (isMenuOpenRef.current || !canInteract(e.target as HTMLElement)) {
          return;
        }
        setIsToolbarActive(false);

        props?.onMouseLeave?.(e);
      },
      onFocus: (e) => {
        setIsToolbarActive(true);

        props?.onFocus?.(e);
      },
      onBlur: (e) => {
        if (
          (e.relatedTarget && e.currentTarget.contains(e.relatedTarget)) ||
          !canInteract(e.target)
        ) {
          return;
        }

        setIsToolbarActive(false);

        props?.onBlur?.(e);
      },
      tabIndex: 0,
      role: 'presentation',
    }),
    []
  );

  const getToolbarProps: GetToolbarProps = useCallback(
    (props) => ({
      isMenuOpen,
      hideToolbar: toolbarHidden,
      onClosed: () => {
        props?.onClosed?.();
      },
      onMenuItemClick: (item) => {
        isMenuOpenRef.current = false;
        flushSync(() => setIsMenuOpen(false));
        setIsToolbarActive(false);

        props?.onMenuItemClick?.(item);
      },
      onToggle: (isOpen) => {
        isMenuOpenRef.current = isOpen;
        setIsMenuOpen(isOpen);
        if (!isOpen) {
          queueMicrotask(() => setIsToolbarActive(false));
        }

        props?.onToggle?.(isOpen);
      },
    }),
    [isMenuOpen, toolbarHidden]
  );

  return useMemo(
    () => ({
      getContainerProps,
      getToolbarProps,
      isToolbarActive: toolbarActive,
      isMenuOpen,
    }),
    [getContainerProps, getToolbarProps, isMenuOpen, toolbarActive]
  );
};

const canInteract = (node: HTMLElement): boolean => {
  if (
    node.classList.contains('emoji-mart') ||
    node.classList.contains('no-blur')
  ) {
    return false;
  }

  return node.parentElement ? canInteract(node.parentElement) : true;
};
