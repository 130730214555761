import { defineMessages, useIntl } from 'react-intl';

import { NoReplies, NoRewardsFound as PostNotFound } from '../../assets/images';
import { EmptyOrErrorStateTemplate } from './EmptyOrErrorStateTemplate';

const messages = defineMessages({
  troubleLoadingPost: {
    defaultMessage: 'Whoops! We had trouble loading this post',
    id: 'iIPRbd',
  },
  tryAgain: {
    defaultMessage: 'Please refresh the page or wait a bit to try again.',
    id: 'j2AMOY',
  },
  postNotFound: {
    defaultMessage: 'Post not found',
    id: 'fCHjUH',
  },
  postNotFoundMessage: {
    defaultMessage:
      'It may have been deleted or you may have lost access to it. Please confirm that you are logged into the correct account.',
    id: 'lxLyeY',
  },
});

export function PostError({
  errorType,
}: {
  errorType: 'NotFound' | 'ServerError';
}) {
  const { formatMessage } = useIntl();
  return (
    <section className="flex h-full items-center justify-center self-auto px-6 pb-4">
      <EmptyOrErrorStateTemplate
        image={errorType === 'NotFound' ? PostNotFound : NoReplies}
        altText="flow-response-loading-error"
        heading={formatMessage(
          errorType === 'NotFound'
            ? messages.postNotFound
            : messages.troubleLoadingPost
        )}
        subHeading={formatMessage(
          errorType === 'NotFound'
            ? messages.postNotFoundMessage
            : messages.tryAgain
        )}
      />
    </section>
  );
}
