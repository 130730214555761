import type { FormProps } from 'react-router-dom';
import { Form } from 'react-router-dom';

import { classNames } from '../../DesignSystem/Utils/classNames';

export function RouterForm({
  children,
  method = 'post',
  className,
  ...remainingProps
}: FormProps) {
  return (
    <Form
      className={classNames(
        {
          'space-y-4': !className?.includes('space-y-'),
        },
        className
      )}
      method={method}
      {...remainingProps}
    >
      {children}
    </Form>
  );
}
