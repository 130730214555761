import {
  type AssemblyCurrency,
  AssemblyCurrencyType,
  defaultCurrencyIcon,
  mapHexCodeToEmoticon,
} from '@assembly-web/services';
import parse from 'html-react-parser';
import type { MouseEvent } from 'react';
import { defineMessages, FormattedMessage, useIntl } from 'react-intl';

import { TextStyle } from '../../../DesignSystem/Feedback/TextStyle';
import { Tooltip } from '../../../DesignSystem/Feedback/Tooltip';
import { Button } from '../../../DesignSystem/Inputs/Button';
import type { ProfileCardContentProps } from '../ProfileCard/ProfileCard';
import ProfileCard from '../ProfileCard/ProfileCard';

export type BoostProfileProps = ProfileCardContentProps & {
  currency?: AssemblyCurrency;
  points: number | undefined;
  //This mode will always be ADD when you're creating a Boost node(even the draft ones)
  //It'll only be UPDATE when you're editing a reply that's already sent
  mode: 'ADD' | 'UPDATE';
  email?: string;
  canGiveBoost: boolean;
  canReceiveBoost: boolean;
  hideBoostButtonReason?: 'SELF' | 'HIDE';
  onBoostClick: (ev: MouseEvent<HTMLButtonElement>) => void;
  onClose: () => void;
  memberID: string;
  isAnonymousReply: boolean;
  currentBoostedUsers: string[];
  reasonForCantGiveBoost?: string;
  pointsExhausted?: boolean;
  isEditing: boolean;
  onViewProfileClick: () => void;
};

const messages = defineMessages({
  message: {
    defaultMessage: 'Message',
    id: 'T7Ry38',
  },
  viewProfile: {
    defaultMessage: 'View profile',
    id: 'yGGPTl',
  },
  giveBoost: {
    defaultMessage: 'Give {icon} boost',
    id: 'EAF/dk',
  },
  updateBoost: {
    defaultMessage: 'Update boost',
    id: 'rkhFrp',
  },
  cantEditBoost: {
    defaultMessage: "You can't edit a boost after your reply is posted",
    id: 'PLxhUd',
  },
  cantGiveBoostToYourself: {
    defaultMessage: "You can't give {currency} to yourself.",
    id: 'SesNeF',
  },
  cantGiveBoostWhenEdit: {
    defaultMessage: "You can't update boost when editing a reply",
    id: 'DhceiG',
  },
  cannotGiveBoostAnonymously: {
    defaultMessage: 'You cannot give a boost anonymously',
    id: 'eWcjxh',
  },
  cantReceiveBoost: {
    defaultMessage: 'You cannot give {currency} to {name}',
    id: 'q2enzY',
  },
  pointsExhausted: {
    defaultMessage: 'Max limit of {currency} reached to give in this reply',
    id: 'vy1FNc',
  },
});

export function BoostProfileCard({
  firstName,
  lastName,
  image,
  department,
  pronouns,
  email,
  jobTitle,
  currency,
  points,
  mode,
  canGiveBoost,
  hideBoostButtonReason,
  isAnonymousReply,
  onBoostClick,
  memberID,
  canReceiveBoost,
  currentBoostedUsers,
  reasonForCantGiveBoost,
  pointsExhausted,
  isEditing,
  onViewProfileClick,
  memberState,
}: BoostProfileProps) {
  const { formatMessage } = useIntl();

  const isBoostExistsForReply =
    isEditing && currentBoostedUsers.includes(memberID);

  const handleBoostClick = (ev: MouseEvent<HTMLButtonElement>) => {
    ev.stopPropagation();
    onBoostClick(ev);
  };

  const getToolTipText = () => {
    if (mode === 'UPDATE') {
      return formatMessage(messages.cantEditBoost);
    }

    if (!canGiveBoost && reasonForCantGiveBoost) {
      return reasonForCantGiveBoost;
    }

    if (isAnonymousReply) {
      return formatMessage(messages.cannotGiveBoostAnonymously);
    }

    if (pointsExhausted) {
      return formatMessage(messages.pointsExhausted, {
        currency: currency?.pluralName,
      });
    }

    if (!canReceiveBoost) {
      return formatMessage(messages.cantReceiveBoost, {
        currency: currency?.pluralName,
        name: firstName,
      });
    }

    return null;
  };

  const currencyIcon =
    currency?.type === AssemblyCurrencyType.Custom
      ? `<img alt=${currency.name} class="h-3 w-3" src=${currency.value} />`
      : mapHexCodeToEmoticon(currency?.value ?? defaultCurrencyIcon);

  const disableUpdateBoostButton =
    mode === 'UPDATE' ||
    !canGiveBoost ||
    !canReceiveBoost ||
    isBoostExistsForReply;

  return (
    <div className="relative flex max-w-[310px] flex-col gap-2 truncate px-2 py-3">
      {points && !isBoostExistsForReply ? (
        <FormattedMessage
          defaultMessage="You are giving {icon} {points} total to {name}"
          id="gq+XzC"
          values={{
            icon: parse(currencyIcon),
            points,
            name: firstName,
          }}
        >
          {(text) => (
            <TextStyle
              variant="xs-regular"
              className="flex items-center whitespace-pre rounded-lg bg-upgrade-2 px-2 py-1 text-gray-9"
            >
              {text}
            </TextStyle>
          )}
        </FormattedMessage>
      ) : null}
      {hideBoostButtonReason === 'SELF' && (
        <TextStyle
          variant="xs-regular"
          className="rounded-lg bg-gray-3 px-2 py-1 text-gray-9"
        >
          {formatMessage(messages.cantGiveBoostToYourself, {
            currency: currency?.pluralName,
          })}
        </TextStyle>
      )}
      {Boolean(isBoostExistsForReply) && (
        <TextStyle
          variant="xs-regular"
          className="rounded-lg bg-gray-3 px-2 py-1 text-gray-9"
        >
          {formatMessage(messages.cantGiveBoostWhenEdit, {
            currency: currency?.pluralName,
          })}
        </TextStyle>
      )}
      <ProfileCard.Content
        lastName={lastName}
        firstName={firstName}
        image={image}
        department={department}
        email={email}
        pronouns={pronouns}
        jobTitle={jobTitle}
        memberID={memberID}
        memberState={memberState}
      />
      <ProfileCard.Footer>
        <div className="flex gap-1">
          <Button
            size="small"
            className="w-full"
            variation="secondaryLite"
            onClick={onViewProfileClick}
          >
            {formatMessage(messages.viewProfile)}
          </Button>
          {hideBoostButtonReason ? null : getToolTipText() ? (
            <Tooltip tooltipText={getToolTipText()}>
              <Button
                variation="secondaryLite"
                onClick={handleBoostClick}
                size="small"
                className="w-full"
                disabled={
                  mode === 'UPDATE' ||
                  !canGiveBoost ||
                  !canReceiveBoost ||
                  pointsExhausted
                }
              >
                {points
                  ? formatMessage(messages.updateBoost)
                  : formatMessage(messages.giveBoost, {
                      icon: mapHexCodeToEmoticon(currencyIcon),
                    })}
              </Button>
            </Tooltip>
          ) : (
            <Button
              variation="secondaryLite"
              onClick={handleBoostClick}
              size="small"
              className="w-full"
              disabled={disableUpdateBoostButton}
            >
              {points
                ? formatMessage(messages.updateBoost)
                : formatMessage(messages.giveBoost, {
                    icon: parse(currencyIcon),
                  })}
            </Button>
          )}
        </div>
      </ProfileCard.Footer>
    </div>
  );
}
