import { Combobox, Transition } from '@headlessui/react';
import { CheckIcon, ChevronDownIcon } from '@heroicons/react/20/solid';
import { Fragment, useState } from 'react';
import { defineMessages, useIntl } from 'react-intl';
import { twMerge } from 'tailwind-merge';

import { LoadingSpinner } from '../../../DesignSystem/Feedback/Icons/LoadingSpinner';
import { classNames } from '../../../DesignSystem/Utils/classNames';

type SelectableOption = {
  id: string;
  isDisabled?: boolean;
  [x: string]: unknown;
};

// Known Issue: Select dropdown will not close with an outside click when component is mounted on the shadowDOM(EX: chrome extension search modal)
// https://github.com/tailwindlabs/headlessui/discussions/874

const messages = defineMessages({
  nothingFound: {
    defaultMessage: 'Nothing found',
    id: 'bz4Mm+',
  },
});

export type SearchableSelectProps<T extends SelectableOption> = {
  label?: string;
  selectedOption?: T;
  selectOptions: T[];
  onChange: (value: T) => void;
  displayValue: (value: T) => string;
  getIcon?: (value: T) => string;
  noOptionsAvailCopy?: string;
  placeholder?: string;
  isDisabled?: boolean;
  className?: string;
  name?: string;
  isInvalid?: boolean;
  isLoading?: boolean;
};

export function SearchableSelect<T extends SelectableOption>(
  props: SearchableSelectProps<T>
) {
  const {
    displayValue,
    label,
    selectedOption,
    selectOptions,
    onChange,
    getIcon,
    noOptionsAvailCopy,
    placeholder,
    isDisabled = false,
    className,
    name,
    isInvalid,
    isLoading,
  } = props;

  const { formatMessage } = useIntl();

  const [query, setQuery] = useState('');

  const filteredOptions =
    query === ''
      ? selectOptions
      : selectOptions.filter((option) => {
          return displayValue(option)
            .toLowerCase()
            .includes(query.toLowerCase());
        });

  const displayedOptions =
    filteredOptions.length === 0 ? (
      <div className="relative cursor-default select-none px-4 py-2 text-gray-8">
        {query === '' && noOptionsAvailCopy
          ? noOptionsAvailCopy
          : formatMessage(messages.nothingFound)}
      </div>
    ) : (
      filteredOptions.map((option) => (
        <Combobox.Option
          className={({ active, selected }) =>
            classNames(
              'relative flex cursor-default select-none items-center justify-between px-3 py-2 hover:bg-gray-3',
              active && 'bg-gray-1',
              selected && 'font-medium',
              option.isDisabled && 'cursor-not-allowed mix-blend-luminosity'
            )
          }
          key={option.id}
          value={option}
          disabled={option.isDisabled}
          data-testid={`searchable-select-option`}
        >
          {({ selected }) => (
            <>
              <div className="flex items-center">
                {getIcon ? (
                  <span className="pr-2">{getIcon(option)}</span>
                ) : null}
                <span className="block truncate">{displayValue(option)}</span>
              </div>

              {Boolean(selected) && (
                <CheckIcon
                  className="h-4 w-4 text-primary-6"
                  aria-hidden="true"
                />
              )}
            </>
          )}
        </Combobox.Option>
      ))
    );

  return (
    <Combobox
      disabled={isDisabled}
      value={selectedOption}
      onChange={onChange}
      name={name}
    >
      <div
        className={twMerge('relative', isDisabled && 'opacity-50', className)}
      >
        {Boolean(label) && (
          <Combobox.Label
            className={twMerge(
              'mb-1 block text-sm font-medium',
              isInvalid && 'text-error-6'
            )}
          >
            {label}
          </Combobox.Label>
        )}

        <Combobox.Button
          className={twMerge(
            'group relative flex h-10 w-full cursor-default items-center justify-between rounded-md border border-gray-5 px-3 py-2 text-left shadow-sm-down focus-within:border-2 focus-within:border-primary-6',
            isInvalid &&
              'border-error-6 text-error-6 placeholder-error-6 focus:border-error-6 focus:outline-none focus:ring-error-6'
          )}
        >
          {getIcon && selectedOption ? (
            <span className="mr-2 text-sm leading-6">
              {getIcon(selectedOption)}
            </span>
          ) : null}
          <Combobox.Input
            className={twMerge(
              'w-full border-0 p-0 pr-2 text-sm leading-6 text-gray-8 focus:ring-0',
              isInvalid && 'placeholder-error-6'
            )}
            onChange={(event) => setQuery(event.target.value)}
            displayValue={displayValue}
            placeholder={placeholder ?? 'Search'}
            data-testid={`searchable-select-${name}`}
          />
          {isLoading ? (
            <LoadingSpinner className="h-4 w-4 text-gray-9" />
          ) : (
            <ChevronDownIcon className="h-4 w-4 text-gray-9 group-aria-expanded:rotate-180" />
          )}
        </Combobox.Button>

        <Transition
          as={Fragment}
          leave="transition ease-in duration-100"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
          afterLeave={() => setQuery('')}
        >
          <Combobox.Options className="absolute mt-1 max-h-56 w-full overflow-auto rounded-lg bg-gray-1 py-1 text-sm text-gray-8 shadow-lg-down">
            {displayedOptions}
          </Combobox.Options>
        </Transition>
      </div>
    </Combobox>
  );
}
