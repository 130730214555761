import * as React from 'react';
import { twMerge } from 'tailwind-merge';

// Used shadcn - https://ui.shadcn.com/docs/components/table

export type TableProps = {
  variant?: 'default' | 'custom';
} & React.HTMLAttributes<HTMLTableElement>;

const Table = React.forwardRef<HTMLTableElement, TableProps>(
  ({ className, ...props }, ref) => {
    const { variant = 'default', ...rest } = props;
    return (
      <div
        className={twMerge(
          'relative w-full overflow-auto rounded-lg',
          variant === 'custom' && 'border border-gray-5'
        )}
      >
        <table
          ref={ref}
          className={twMerge(
            'w-full caption-bottom bg-gray-1 text-sm',
            className
          )}
          {...rest}
        />
      </div>
    );
  }
);
Table.displayName = 'Table';

type TableHeaderProps = {
  variant?: 'default' | 'custom';
} & React.HTMLAttributes<HTMLTableSectionElement>;

const TableHeader = React.forwardRef<HTMLTableSectionElement, TableHeaderProps>(
  ({ className, variant = 'default', ...props }, ref) => (
    <thead
      ref={ref}
      className={twMerge(
        '[&>tr]:h-auto [&>tr]:border-b-0',
        variant === 'custom' && 'bg-gray-4 [&>tr]:hover:bg-gray-4'
      )}
      {...props}
    />
  )
);
TableHeader.displayName = 'TableHeader';

const TableBody = React.forwardRef<
  HTMLTableSectionElement,
  React.HTMLAttributes<HTMLTableSectionElement>
>(({ className, ...props }, ref) => (
  <tbody
    ref={ref}
    className={twMerge('bg-gray-1 [&_tr:last-child]:border-b-0', className)}
    {...props}
  />
));
TableBody.displayName = 'TableBody';

const TableFooter = React.forwardRef<
  HTMLTableSectionElement,
  React.HTMLAttributes<HTMLTableSectionElement>
>(({ className, ...props }, ref) => (
  <tfoot
    ref={ref}
    className={twMerge(
      'border-t border-gray-5 font-medium [&>tr]:last:border-b-0',
      className
    )}
    {...props}
  />
));
TableFooter.displayName = 'TableFooter';

type TableRowProps = {
  variant?: 'default' | 'custom';
} & React.HTMLAttributes<HTMLTableRowElement>;

const TableRow = React.forwardRef<HTMLTableRowElement, TableRowProps>(
  ({ className, variant = 'default', ...props }, ref) => (
    <tr
      ref={ref}
      className={twMerge(
        'h-auto transition-colors data-[state=selected]:bg-gray-3',
        className,
        variant === 'custom' && 'border-b border-gray-5'
      )}
      {...props}
    />
  )
);
TableRow.displayName = 'TableRow';

type TableHeadProps = {
  variant?: 'default' | 'custom';
} & React.ThHTMLAttributes<HTMLTableCellElement>;

const TableHead = React.forwardRef<HTMLTableCellElement, TableHeadProps>(
  ({ className, variant = 'default', ...props }, ref) => (
    <th
      ref={ref}
      className={twMerge(
        'max-h-14 px-3 text-left align-bottom font-medium [&:has([role=checkbox])]:pr-0',
        variant === 'custom' && 'bg-gray-4 py-2',
        variant === 'default' && 'pb-1 pt-2',
        className
      )}
      {...props}
    />
  )
);
TableHead.displayName = 'TableHead';

const TableCell = React.forwardRef<
  HTMLTableCellElement,
  React.TdHTMLAttributes<HTMLTableCellElement>
>(({ className, ...props }, ref) => (
  <td
    ref={ref}
    className={twMerge(
      'h-full max-h-14 bg-gray-1 px-3 py-2 align-middle [&:has([role=checkbox])]:pr-0',
      className
    )}
    {...props}
  />
));
TableCell.displayName = 'TableCell';

const TableCaption = React.forwardRef<
  HTMLTableCaptionElement,
  React.HTMLAttributes<HTMLTableCaptionElement>
>(({ className, ...props }, ref) => (
  <caption
    ref={ref}
    className={twMerge('mt-4 text-sm', className)}
    {...props}
  />
));
TableCaption.displayName = 'TableCaption';

export {
  Table,
  TableBody,
  TableCaption,
  TableCell,
  TableFooter,
  TableHead,
  TableHeader,
  TableRow,
};
