import type { ReactNode } from 'react';
import { twMerge } from 'tailwind-merge';

export type QuickActionProps = {
  icon: ReactNode;
  text: ReactNode;
  className?: string;
  onClick: () => void;
};

export function QuickAction({
  icon,
  text,
  className,
  onClick,
}: QuickActionProps) {
  return (
    <button
      className={twMerge(
        navActionItemStyles,
        'inline-flex w-full items-center justify-start gap-2 self-stretch rounded-md px-4 py-1',
        className
      )}
      onClick={onClick}
    >
      <div
        className="flex shrink-0 items-center justify-start gap-2 py-1"
        aria-hidden="true"
      >
        {icon}
      </div>
      <div className="line-clamp-1 h-6 text-left">{text}</div>
    </button>
  );
}

export const navActionItemStyles =
  'focus-visible:bg-gray-3 enabled:hover:bg-gray-3 active:bg-gray-5 rounded-lg';
