import { DevicePhoneMobileIcon } from '@heroicons/react/20/solid';
import { EnvelopeIcon } from '@heroicons/react/24/outline';
import { Content, Root, Trigger } from '@radix-ui/react-collapsible';
import { useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useIntl } from 'react-intl';

import { TextStyle } from '../../../../../DesignSystem/Feedback/TextStyle';
import { Button } from '../../../../../DesignSystem/Inputs/Button';
import { ToggleSwitch } from '../../../../../DesignSystem/Inputs/ToggleSwitch';
import { messages } from '../message';

export function Distribution({
  shareChallengeNode,
  disabled,
  tooltipText,
}: {
  shareChallengeNode: JSX.Element | null;
  disabled?: boolean;
  tooltipText?: string;
}) {
  const { formatMessage } = useIntl();
  const { getValues, control } = useFormContext();
  const [isEmailOptionExpanded, setIsEmailOptionExpanded] = useState(false);
  const [isMobileOptionExpanded, setIsMobileOptionExpanded] = useState(false);

  return (
    <>
      {shareChallengeNode}
      <section>
        <TextStyle variant="base-medium" className="text-gray-9">
          {formatMessage(messages.notificationTitle)}
        </TextStyle>
        <TextStyle variant="xs-regular" className="text-gray-9">
          {formatMessage(messages.notificationDesc)}
        </TextStyle>

        <Root
          open={isEmailOptionExpanded}
          onOpenChange={setIsEmailOptionExpanded}
          className="mt-3 rounded-lg border border-solid border-gray-5"
        >
          <div className="flex items-center justify-between px-3">
            <div className="flex items-center justify-center gap-2">
              <EnvelopeIcon className="h-5 w-5 text-gray-9" />
              <TextStyle variant="base-medium" className="text-gray-9">
                {formatMessage(messages.emailLabel)}
              </TextStyle>
              <TextStyle variant="sm-regular" className="text-gray-9">
                {getValues('notifyOnEmail')
                  ? formatMessage(messages.onLaunchLabel)
                  : formatMessage(messages.noneLabel)}
              </TextStyle>
            </div>
            <Trigger asChild>
              <Button
                disabled={disabled}
                tooltipText={tooltipText}
                className="my-2 h-8"
                variation={
                  isEmailOptionExpanded ? 'tertiaryLite' : 'tertiaryEmphasized'
                }
                aria-label="emailAccordion"
              >
                {isEmailOptionExpanded
                  ? `${formatMessage(messages.closeLabel)}`
                  : `${formatMessage(messages.editLabel)}`}
              </Button>
            </Trigger>
          </div>

          <Content className="px-3 pb-3">
            <div className="flex items-center gap-3">
              <TextStyle variant="sm-medium">
                {formatMessage(messages.notifyOnLaunch)}
              </TextStyle>
              <Controller
                defaultValue={false}
                name="notifyOnEmail"
                control={control}
                render={({ field: { onChange, value } }) => (
                  <ToggleSwitch
                    disabled={disabled}
                    tooltipText={tooltipText}
                    checked={value}
                    onCheckedChange={onChange}
                    aria-label={'notifyEmailOnLaunch'}
                  />
                )}
              />
            </div>
            <TextStyle variant="sm-regular" className="mt-1 flex-1">
              {formatMessage(messages.emailNotifyOnLaunchDescription)}
            </TextStyle>
          </Content>
        </Root>

        <Root
          open={isMobileOptionExpanded}
          onOpenChange={setIsMobileOptionExpanded}
          className="mt-3 rounded-lg border border-solid border-gray-5"
        >
          <div className="flex items-center justify-between px-3">
            <div className="flex items-center justify-center gap-2">
              <DevicePhoneMobileIcon className="h-5 w-5 text-gray-9" />
              <TextStyle variant="base-medium" className="text-gray-9">
                {formatMessage(messages.mobileAppLabel)}
              </TextStyle>
              <TextStyle variant="sm-regular" className="text-gray-9">
                {getValues('notifyOnMobile')
                  ? formatMessage(messages.onLaunchLabel)
                  : formatMessage(messages.noneLabel)}
              </TextStyle>
            </div>
            <Trigger asChild>
              <Button
                disabled={disabled}
                tooltipText={tooltipText}
                className="my-2 h-8"
                variation={
                  isMobileOptionExpanded ? 'tertiaryLite' : 'tertiaryEmphasized'
                }
                aria-label="mobileAccordion"
              >
                {isMobileOptionExpanded
                  ? `${formatMessage(messages.closeLabel)}`
                  : `${formatMessage(messages.editLabel)}`}
              </Button>
            </Trigger>
          </div>

          <Content className="px-3 pb-3">
            <div className="flex items-center gap-3">
              <TextStyle variant="sm-medium">
                {formatMessage(messages.notifyOnLaunch)}
              </TextStyle>
              <Controller
                defaultValue={false}
                name="notifyOnMobile"
                control={control}
                render={({ field: { onChange, value } }) => (
                  <ToggleSwitch
                    disabled={disabled}
                    checked={value}
                    onCheckedChange={onChange}
                    aria-label={'notifyMobileOnLaunch'}
                  />
                )}
              />
            </div>
            <TextStyle variant="sm-regular" className="mt-1 flex-1">
              {formatMessage(messages.mobileAppNotifyOnLaunchDescription)}
            </TextStyle>
          </Content>
        </Root>
      </section>
    </>
  );
}
