import type { ChallengeState } from '@assembly-web/services';
import { PlayIcon, StopIcon } from '@heroicons/react/24/outline';
import type { MouseEvent } from 'react';
import { defineMessages, useIntl } from 'react-intl';

import { TextStyle } from '../../../../DesignSystem/Feedback/TextStyle';
import type { ButtonVariations } from '../../../../DesignSystem/Inputs/Button';
import { Button } from '../../../../DesignSystem/Inputs/Button';

const messages = defineMessages({
  endChallenge: {
    defaultMessage: 'End',
    id: '3JVa6k',
  },
  launchChallenge: {
    defaultMessage: 'Launch',
    id: 'ewx2b7',
  },
});

const showBannerButtonForStates: ChallengeState[] = ['QUEUED', 'ACTIVE'];

type ChallengeHeaderBannerButtonProps = {
  challengeState: ChallengeState;
  onHeaderButtonClick?: (button: string) => void;
};

const getButtonProps = (challengeState: ChallengeState) => {
  switch (challengeState) {
    case 'QUEUED':
      return {
        variation: 'secondaryEmphasized',
        text: 'launchChallenge',
        icon: <PlayIcon className="h-4 w-4 stroke-2" />,
        id: 'launch-challenge',
      };
    case 'ACTIVE':
      return {
        variation: 'secondaryLite',
        text: 'endChallenge',
        icon: <StopIcon className="h-4 w-4 stroke-2" />,
        id: 'end-challenge',
      };
    default:
      return {
        variation: 'secondaryEmphasized',
        text: 'endChallenge',
        icon: <StopIcon className="h-4 w-4 stroke-2" />,
        id: 'end-challenge',
      };
  }
};

export function ChallengeHeaderBannerButton(
  props: ChallengeHeaderBannerButtonProps
) {
  const { formatMessage } = useIntl();
  const { challengeState, onHeaderButtonClick } = props;

  if (!showBannerButtonForStates.includes(challengeState)) {
    return;
  }
  const { variation, text, icon, id } = getButtonProps(challengeState);

  const handleButtonClick = (
    event: MouseEvent<HTMLButtonElement>,
    button: string
  ) => {
    event.stopPropagation();
    if (onHeaderButtonClick) onHeaderButtonClick(button);
    return;
  };

  return (
    <Button
      variation={variation as (typeof ButtonVariations)[number]}
      onClick={(e) => handleButtonClick(e, id)}
      size="small"
      className="flex items-center gap-2"
    >
      <span className="flex-shrink-0">{icon}</span>
      <TextStyle variant="sm-medium" className="truncate">
        {formatMessage(messages[text as keyof typeof messages])}
      </TextStyle>
    </Button>
  );
}
