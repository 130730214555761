import { ChevronDownIcon } from '@heroicons/react/24/outline';
import type { FC, ReactNode } from 'react';
import React from 'react';
import { defineMessages, FormattedMessage, useIntl } from 'react-intl';
import { twMerge } from 'tailwind-merge';

import { TextStyle } from '../../../DesignSystem/Feedback/TextStyle';

type Children = { children: ReactNode };

const message = defineMessages({
  dueSoon: {
    defaultMessage: '{count} due soon',
    id: 'zrLbLa',
  },
});

export const Container: FC<Children & { className?: string }> = ({
  children,
  className,
}) => {
  return (
    <div
      className={twMerge(
        'flex h-full w-full items-center justify-between gap-1 @[600px]/root:gap-4',
        className
      )}
    >
      {children}
    </div>
  );
};

const Text = ({ children }: Children) => (
  <p className="flex-shrink truncate text-left text-base font-medium text-gray-8">
    {children}
  </p>
);

const StartPostContainer: FC<{ children: ReactNode }> = ({ children }) => {
  const childCount = React.Children.count(children);
  const childArray = React.Children.toArray(children);

  if (childCount < 1 || childCount > 2) {
    console.warn('StartPost should have 1 or 2 children');
  }

  return (
    <Container className="py-2.5 pl-4 pr-3">
      {childArray[0]}
      {childArray[1] ? (
        <div className="flex shrink-0 items-center gap-4">{childArray[1]}</div>
      ) : null}
    </Container>
  );
};

const AlternateContainer: FC<Children> = ({ children }) => {
  return (
    <Container className="justify-start gap-2 px-3 py-2.5 @[600px]/root:gap-3">
      {children}
    </Container>
  );
};

const IconContainer: FC<{ children: ReactNode; className: string }> = ({
  children,
  className,
}) => {
  return (
    <span
      className={`flex h-8 w-8 items-center justify-center rounded-md p-2 @[600px]/root:h-9 @[600px]/root:w-9 ${className}`}
    >
      {children}
    </span>
  );
};

const ActivityChip: FC<{ active?: boolean; activeCount: number }> = ({
  activeCount,
  active = true,
}) => {
  const { formatMessage } = useIntl();

  return (
    <div className="flex items-center gap-2 whitespace-nowrap rounded-md bg-gray-1 px-2">
      {Boolean(active) && (
        <span className="h-2 w-2 rounded-full bg-primary-6" />
      )}
      <TextStyle className="@[600px]/root:hidden" subdued variant="sm-regular">
        {formatMessage(message.dueSoon, { count: activeCount })}
      </TextStyle>
      <TextStyle
        className="hidden @[600px]/root:block"
        subdued
        variant="base-regular"
      >
        {formatMessage(message.dueSoon, { count: activeCount })}
      </TextStyle>
    </div>
  );
};

const Arrow = () => (
  <ChevronDownIcon
    className="hidden h-4 w-4 text-gray-8 group-data-[state=open]:rotate-180 @[600px]/root:inline-block"
    data-arrow="true"
    aria-hidden="true"
  />
);

const WritePost = () => (
  <FormattedMessage defaultMessage="Start a post..." id="ZSzCNY" />
);

const GiveRecognition = () => (
  <FormattedMessage defaultMessage="Give recognition..." id="A8R8c8" />
);

const ActionBarHeaderButtonStates = {
  ActivityChip,
  AlternateContainer,
  Arrow,
  GiveRecognition,
  IconContainer,
  StartPostContainer,
  Text,
  WritePost,
};

export { ActionBarHeaderButtonStates };
