import DOMPurify from 'dompurify';

DOMPurify.addHook('afterSanitizeAttributes', function (node) {
  // set all elements owning target to target=_blank
  if ('target' in node) {
    node.setAttribute('target', '_blank');
    node.setAttribute('rel', 'noopener');
  }
});

// This list of allowed HTML tags is synchronized with the backend.
// Any changes made to this list should also be reflected in the backend configuration.
const defaultAllowedTags = [
  'a',
  'img',
  'b',
  'i',
  'em',
  'strong',
  'br',
  'p',
  'div',
  'table',
  'thead',
  'tbody',
  'tr',
  'td',
  'th',
  'tfoot',
  'blockquote',
  'pre',
  'code',
  'section',
  'span',
  'ul',
  'li',
  'ol',
  'h1',
  'h2',
  'h3',
  'h4',
  'h5',
  'h6',
];

export function sanitizeHtml(html: string): string {
  return DOMPurify.sanitize(html, {
    ALLOWED_TAGS: defaultAllowedTags,
    ADD_ATTR: ['target'],
  });
}
