import type {
  ChallengeState,
  ClaimInsightsFilterType,
} from '@assembly-web/services';
import type { ColumnDef, ColumnPinningState } from '@tanstack/react-table';
import { getCoreRowModel, useReactTable } from '@tanstack/react-table';
import { type ReactNode, useCallback, useEffect, useState } from 'react';

import { DataTable } from '../../../../DesignSystem/Datatable/DataTable';

type InsightsTableProps<TData, TValue> = {
  columns: ColumnDef<TData, TValue>[];
  data: TData[];
  claimStatus: ClaimInsightsFilterType;
  isFetchingNextPage?: boolean;
  hasNextPage?: boolean;
  onLoadMore: (startIndex?: number, endIndex?: number) => void | Promise<void>;
  loader?: ReactNode;
  isLoading?: boolean;
  tableId: string;
  challengeStatus: ChallengeState;
  emptyStateText: string;
};

type Visibility = {
  user?: boolean;
  viewProof?: boolean;
  claimDate?: boolean;
  claimActionButtons?: boolean;
  claims?: boolean;
  lastClaimed?: boolean;
  remind?: boolean;
};

const initialVisibilityState = {
  user: true,
  viewProof: true,
  claimDate: true,
  claimActionButtons: true,
  claims: true,
  lastClaimed: true,
  remind: true,
};

const claimStatusMap = {
  PENDING: ['user', 'viewProof', 'claimDate', 'claimActionButtons'],
  APPROVED: ['user', 'claims', 'lastClaimed'],
  UNCLAIMED: ['user', 'remind'],
} satisfies Record<ClaimInsightsFilterType, string[]>;

export function InsightsTable<TData, TValue>({
  columns,
  data,
  claimStatus,
  isFetchingNextPage,
  hasNextPage,
  onLoadMore,
  loader,
  isLoading,
  tableId,
  challengeStatus,
  emptyStateText,
}: InsightsTableProps<TData, TValue>) {
  const [columnVisibility, setColumnVisibility] = useState<Visibility>(
    initialVisibilityState
  );
  const [pinnedColumns, setPinnedColumns] = useState<ColumnPinningState>({
    left: ['user'],
  });

  const handleColumnVisibilityChange = useCallback(() => {
    setColumnVisibility((prev) => {
      let obj: Visibility = initialVisibilityState;
      const isVisibilityKey = (key: string): key is keyof Visibility => {
        return key in prev;
      };
      for (const key in prev) {
        if (isVisibilityKey(key)) {
          obj[key] = claimStatusMap[claimStatus].includes(key);
        }
      }
      return obj;
    });
  }, [claimStatus]);

  useEffect(() => {
    handleColumnVisibilityChange();
    if (claimStatus !== 'UNCLAIMED') {
      setPinnedColumns({
        left: ['user'],
      });
    } else {
      setPinnedColumns({});
    }
  }, [handleColumnVisibilityChange, claimStatus]);

  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
    state: {
      columnVisibility,
      columnPinning: pinnedColumns,
    },
    initialState: {
      columnPinning: {
        left: ['user'],
        right: [],
      },
    },
    onColumnVisibilityChange: handleColumnVisibilityChange,
    onColumnPinningChange: setPinnedColumns,
  });

  const getTableHeight = () => {
    if (claimStatus === 'UNCLAIMED' && challengeStatus === 'CLOSED') {
      return '14.25rem';
    } else if (challengeStatus === 'CLOSED') {
      return '12.125rem';
    }
    return claimStatus === 'UNCLAIMED' ? '13.125rem' : '15.625rem';
  };

  return (
    <div className="px-2">
      <DataTable
        variant="default"
        table={table}
        onLoadMore={onLoadMore}
        loader={loader}
        isLoading={isLoading}
        isFetchingNextPage={isFetchingNextPage}
        hasNextPage={hasNextPage}
        hideTableHeader={claimStatus === 'UNCLAIMED'}
        tableHeaderRowClassName="flex items-end !p-0"
        tableCellClassName={'flex items-center'}
        tableRowClassName={'flex items-center p-0'}
        tableHeight={getTableHeight()}
        tableId={tableId}
        emptyStateText={emptyStateText}
        itemSize={40}
      />
    </div>
  );
}
