import type { CollectionColorEnum } from '@assembly-web/services';
import { cva } from 'class-variance-authority';
import type { ChangeEvent } from 'react';

const colorRadioOptionStyles = cva(
  'mr-4 h-4 w-4 rounded-lg border-0 checked:border checked:before:m-[calc(50%-6px/2)] checked:before:block checked:before:h-1.5 checked:before:w-1.5 checked:before:rounded-lg checked:hover:border-[1px] focus:ring-0 focus:ring-offset-0',
  {
    variants: {
      color: {
        grey: 'bg-gray-4 checked:border-gray-9 checked:text-gray-4 checked:before:bg-gray-9 checked:hover:border-gray-9 checked:focus:border-gray-9',
        blue: 'bg-primary-3 checked:border-primary-9 checked:text-primary-3 checked:before:bg-primary-9 checked:hover:border-primary-9 checked:focus:border-primary-9',
        red: 'bg-error-3 checked:border-error-9 checked:text-error-3 checked:before:bg-error-9 checked:hover:border-error-9 checked:focus:border-error-9',
        yellow:
          'bg-upgrade-3 checked:border-upgrade-9 checked:text-upgrade-3 checked:before:bg-upgrade-9 checked:hover:border-upgrade-9 checked:focus:border-upgrade-9',
        green:
          'bg-success-3 checked:border-success-9 checked:text-success-3 checked:before:bg-success-9 checked:hover:border-success-9 checked:focus:border-success-9',
        pink: 'bg-magenta-3 checked:border-magenta-9 checked:text-magenta-3 checked:before:bg-magenta-9 checked:hover:border-magenta-9 checked:focus:border-magenta-9',
        purple:
          'bg-purple-3 checked:border-purple-9 checked:text-purple-3 checked:before:bg-purple-9 checked:hover:border-purple-9 checked:focus:border-purple-9',
        cyan: 'bg-cyan-3 checked:border-cyan-9 checked:text-cyan-3 checked:before:bg-cyan-9 checked:hover:border-cyan-9 checked:focus:border-cyan-9',
        orange:
          'bg-warning-3 checked:border-warning-9 checked:text-warning-3 checked:before:bg-warning-9 checked:hover:border-warning-9 checked:focus:border-warning-9',
      },
    },
  }
);

type ColorRadioOptionProps = {
  checked: boolean;
  color: CollectionColorEnum;
  onChange: (ev: ChangeEvent<HTMLInputElement>) => void;
};

export function ColorRadioOption({
  checked,
  color,
  onChange,
}: ColorRadioOptionProps) {
  return (
    <input
      aria-label={color}
      checked={checked}
      className={colorRadioOptionStyles({ color })}
      name="colorRadioOption"
      onChange={onChange}
      type="radio"
      value={color}
    />
  );
}
