import type { UseQueryOptions } from '@tanstack/react-query';
import { useQuery } from '@tanstack/react-query';

import { getUserDetailsQuery } from '../queries/getUserDetailsQuery';
import type { MemberAPIResponse, UserDetails } from '../types/domain';
import {
  getCanUserCreateFlows,
  getCanUserInviteNewUsers,
  getCanUserPostAnnouncements,
  getHasProfanityTurnedOn,
  getWorkspaceSlugPath,
} from '../workspace';

export function useUserDetails(options?: UseQueryOptions<MemberAPIResponse>) {
  return useQuery({
    ...getUserDetailsQuery({ suspense: true, ...options }),
    select: (data: MemberAPIResponse): UserDetails => {
      return {
        member: {
          ...data.member,
          profile: {
            ...data.member.profile,
            fullName:
              `${data.member.profile.firstName} ${data.member.profile.lastName}`.trim(),
          },
          permissions: {
            canPostAnnouncements: getCanUserPostAnnouncements(data),
            canCreateFlow: getCanUserCreateFlows(data),
            canInviteNewUsers: getCanUserInviteNewUsers(data),
            profanitySetting: getHasProfanityTurnedOn(data),
          },
        },
        assembly: {
          ...data.assembly,
          workspaceSlugPath: getWorkspaceSlugPath(data),
        },
      };
    },
  });
}
