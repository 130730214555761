import type { Member, WorkspaceSettings } from './types/domain';
import { MemberRole } from './types/flowTypes';

export enum AssemblyCurrencyType {
  REGULAR = 'REGULAR',
  CUSTOM = 'CUSTOM',
}

export const isUserAdmin = ({ role = [] }: { role: MemberRole[] }) =>
  role.some((currentRole) => {
    return currentRole === MemberRole.Admin || currentRole === MemberRole.Owner;
  });

export const isUserManager = (user: Member) => user.profile.isManager;

export const canInviteMembers = (
  member: Member | undefined,
  settings: WorkspaceSettings | undefined
) => {
  if (member) {
    if (isUserAdmin(member)) {
      return true;
    } else if (
      isUserManager(member) &&
      settings?.userManagement.inviteAccess.value.includes('MANAGER')
    ) {
      return true;
    } else if (
      settings?.userManagement.inviteAccess.value.includes('EVERYONE')
    ) {
      return true;
    }
    return false;
  }
  return false;
};
