import { SparklesIcon } from '@heroicons/react/24/outline';
import dayjs from 'dayjs';
import relativeTimePlugin from 'dayjs/plugin/relativeTime';
import { defineMessages, useIntl } from 'react-intl';

import { TextStyle } from '../../../../../DesignSystem/Feedback/TextStyle';
import { OverflowCard } from '.';

dayjs.extend(relativeTimePlugin);

export type FlowSummaryCardProps = {
  /** Flow summary status */
  status: 'complete' | 'inprogress';

  /** Timestamp of last message */
  lastMessageTS: number;

  /** Title shown as first line in card */
  title: string;

  /** Event handler called when the drawer gets closed out */
  onClose: () => void;

  /** Event handler called when the drawer gets clicked on */
  onClick: () => void;
};

const messages = defineMessages({
  lastMessage: {
    defaultMessage: 'Last message {timeAgo}',
    id: 'wAV5Nq',
  },
  summaryComplete: {
    defaultMessage: 'Summary complete',
    id: 'ZDeeoT',
  },
  summaryInProg: {
    defaultMessage: 'Summary in progress...',
    id: 'eS2BkM',
  },
});

export function FlowSummaryCard(props: FlowSummaryCardProps) {
  const { status, title, lastMessageTS, onClose, onClick } = props;
  const { formatMessage } = useIntl();

  const avatar = (
    <div className="rounded bg-gradient-to-r from-[#1d3fc8] via-purple-7 to-[#a309ca] p-2">
      <SparklesIcon className="h-6 w-6 text-gray-1" />
    </div>
  );

  const content = (
    <div>
      <TextStyle as="span" variant="xs-medium" className="mr-1.5">
        {formatMessage(
          status === 'complete'
            ? messages.summaryComplete
            : messages.summaryInProg
        )}
      </TextStyle>
      <TextStyle as="span" variant="xs-regular" className="text-gray-8">
        {formatMessage(messages.lastMessage, {
          timeAgo: dayjs(lastMessageTS).fromNow(),
        })}
      </TextStyle>
    </div>
  );

  return (
    <OverflowCard
      title={title}
      content={content}
      avatar={avatar}
      onClose={onClose}
      onClick={onClick}
    />
  );
}
