import {
  type AssemblyCurrency,
  formatToLocalDateTime,
  type MemberDetails,
  MemberState,
  type ReactionDetails,
} from '@assembly-web/services';
import { ChatBubbleLeftRightIcon } from '@heroicons/react/24/outline';
import dayjs from 'dayjs';
import type { ReactNode } from 'react';
import { defineMessages, useIntl } from 'react-intl';

import {
  AnniversaryIcon,
  AnonymousAvatarIcon,
  BirthdayIcon,
  Toolbar,
  type ToolbarItem,
  Tooltip,
  useDeviceInfo,
  useToolbarState,
} from '../../..';
import { Avatar, AvatarSize } from '../../../DesignSystem/Feedback/Avatar';
import { TextStyle } from '../../../DesignSystem/Feedback/TextStyle';
import { Button } from '../../../DesignSystem/Inputs/Button';
import { LineIcon } from '../../../Icons';
import {
  AuthorButton,
  type MemberDetailsForViewProfile,
} from '../../Shared/AuthorButton';
import { cardParentDataAttributes, handleParentClick } from '../Cards/utils';
import {
  ConversationCard,
  type ConversationCardMemberDetails,
} from '../PostAndReplies/ConversationCard';
import { messages } from '../PostAndReplies/messages';

export type ReplyCardProps = {
  content: string;
  commentId: string;
  postOwner: {
    firstName?: string;
    lastName?: string;
    profileStatus: MemberState;
    entityId: string;
    profileImageUrl?: string;
    fullName: string;
    type?: string;
    pronouns?: string;
    email?: string;
    department?: string;
    jobTitle?: string;
    image?: string;
  };
  title?: string;
  handleTitleClick?: () => void;
  flow?: string;
  conversationMemberDetails: ConversationCardMemberDetails<
    MemberDetailsForViewProfile & {
      isAnonymous: false;
      name: string;
    }
  >;
  handleMenuItem: (menu: ToolbarItem) => void;
  handlePostOwnerClick?: () => void;
  toolbarMenuItems: ToolbarItem[];
  handleViewRepliesClick: () => void;
  handleFlowClick?: () => void;
  handleReactionClick: (emoji: ReactionDetails) => void;
  reactions?: ReactionDetails[];
  currentUserId: string;
  commentCount?: number;
  buttonText: string;
  gifURL?: string;
  messageNode?: React.ReactNode;
  postCreatedAt?: string;
  isEdited?: boolean;
  pointsEach?: {
    icon: AssemblyCurrency;
    points?: number;
  };
  notification?: {
    icon: ReactNode;
    text: string;
    cardToolBar?: ToolbarItem[];
    markAsSeen?: () => void;
  };
  onCardClicked: () => void;
  onViewThreadClicked: () => void;
  onSeePostClicked: () => void;
  currencyDetails?: AssemblyCurrency;
  taggedUsers?: MemberDetails[];
  boostedUsers?: { member: MemberDetails; points: number }[];
  onMemberClick: (memberId: string) => void;
};

const labels = defineMessages({
  posted: {
    defaultMessage: 'posted in',
    id: '19BOio',
  },
  seePost: {
    defaultMessage: 'See post',
    id: 'TRGq26',
  },
  seeChallenge: {
    defaultMessage: 'See challenge',
    id: 'aQCM2D',
  },
  moreReplies: {
    defaultMessage: `Open thread to see {replyCount} more replies`,
    id: 'x6GBUT',
  },
  oneMoreReply: {
    defaultMessage: `Open thread to see {replyCount} more reply`,
    id: 'Zks2pM',
  },
});

type OwnerType = 'anonymous' | 'anniversary' | 'birthday';

const avatarIconMap = {
  anonymous: AnonymousAvatarIcon,
  anniversary: AnniversaryIcon,
  birthday: BirthdayIcon,
};

export function ReplyCard({
  content,
  postOwner,
  commentId,
  flow,
  conversationMemberDetails,
  handleMenuItem,
  handlePostOwnerClick = () => {},
  toolbarMenuItems,
  handleViewRepliesClick,
  handleFlowClick,
  handleReactionClick,
  reactions,
  currentUserId,
  commentCount,
  buttonText,
  notification,
  gifURL,
  messageNode,
  pointsEach,
  postCreatedAt,
  isEdited,
  onCardClicked,
  onViewThreadClicked,
  onSeePostClicked,
  currencyDetails,
  taggedUsers,
  boostedUsers,
  onMemberClick,
  title,
  handleTitleClick,
}: ReplyCardProps) {
  const { formatMessage } = useIntl();
  const { getContainerProps, getToolbarProps } = useToolbarState();
  const { deviceType } = useDeviceInfo();
  const isMobile = deviceType === 'mobile';
  let postCreationTime;
  if (postCreatedAt) {
    const createdTimestamp = dayjs(new Date(postCreatedAt)).fromNow();
    const label = messages.ago;
    postCreationTime = formatMessage(label, {
      timeCreatedAt: createdTimestamp,
    });
  }

  return (
    // eslint-disable-next-line jsx-a11y/no-static-element-interactions
    <div
      onClick={handleParentClick(() => onCardClicked())}
      onKeyDown={handleParentClick((e) => {
        if (e.key === 'Enter' || e.key === 'Space') {
          onCardClicked();
        }
      })}
      className="hover:shadow-gray-100 relative flex cursor-pointer flex-col rounded-lg border border-gray-5 bg-gray-1 p-3 hover:border-gray-6"
      {...cardParentDataAttributes}
      {...getContainerProps()}
    >
      {notification?.cardToolBar?.length ? (
        <div className="absolute right-1 top-1 w-auto max-md:hidden">
          <Toolbar
            primaryToolbarItems={notification.cardToolBar}
            {...getToolbarProps({
              onMenuItemClick() {
                notification.markAsSeen?.();
              },
            })}
            secondaryToolbarItems={[]}
          />
        </div>
      ) : null}
      {notification?.text ? (
        <div className="mb-2 flex flex-row items-center justify-start gap-2">
          {notification.icon}
          <TextStyle>{notification.text}</TextStyle>
        </div>
      ) : null}
      <div className="flex items-center gap-2">
        {!postOwner.type ? (
          <Avatar
            memberID={postOwner.entityId}
            size={AvatarSize.Large}
            name={postOwner.fullName}
            image={postOwner.profileImageUrl}
            className={
              postOwner.profileStatus === MemberState.Deactivated
                ? 'grayscale'
                : ''
            }
          />
        ) : (
          <Avatar
            memberID={postOwner.entityId}
            size={AvatarSize.Large}
            name={postOwner.fullName}
            image={avatarIconMap[postOwner.type as OwnerType]}
          />
        )}
        <div>
          {flow ? (
            <div className="flex flex-wrap items-baseline">
              <AuthorButton
                respondent={{
                  firstName:
                    postOwner.firstName ?? postOwner.fullName.split(' ')[0],
                  lastName:
                    postOwner.lastName ??
                    (postOwner.fullName.split(' ')[1] || ''),
                  memberID: postOwner.entityId,
                  memberState: postOwner.profileStatus,
                  pronouns: postOwner.pronouns,
                  email: postOwner.email,
                  department: postOwner.department,
                  jobTitle: postOwner.jobTitle,
                  image: postOwner.profileImageUrl,
                }}
                onClick={handlePostOwnerClick}
              />
              <TextStyle variant="sm-regular">
                {formatMessage(labels.posted)}
              </TextStyle>
              &nbsp;
              <TextStyle
                variant="sm-medium"
                data-testid={`flow-for-${commentId}`}
                className="cursor-pointer text-primary-6 hover:text-primary-5"
                onClick={handleFlowClick}
                html={flow}
              />
            </div>
          ) : (
            <TextStyle
              variant="sm-medium"
              data-testid={`flow-for-${commentId}`}
              className="cursor-pointer text-primary-6 hover:text-primary-5"
              onClick={handleTitleClick}
            >
              {title}
            </TextStyle>
          )}

          {!isMobile && (
            <div className="flex gap-1">
              {postCreatedAt ? (
                <>
                  <Tooltip tooltipText={formatToLocalDateTime(postCreatedAt)}>
                    <TextStyle variant="xs-regular" className="text-gray-8">
                      {postCreationTime}
                    </TextStyle>
                  </Tooltip>
                  <TextStyle variant="xs-regular" className="text-gray-8">
                    •
                  </TextStyle>
                </>
              ) : null}

              <TextStyle
                variant="xs-regular"
                className="cursor-pointer text-gray-8 underline"
                onClick={onSeePostClicked}
              >
                {formatMessage(flow ? labels.seePost : labels.seeChallenge)}
              </TextStyle>
            </div>
          )}
        </div>
      </div>
      {commentCount && commentCount > 0 ? (
        <div className="ml-2">
          <LineIcon className="m-1 h-2" />
          <div className="align-center flex gap-3">
            <div className="h-7 w-7 rounded-full border-primary-6 bg-primary-6 p-1">
              <ChatBubbleLeftRightIcon className="stroke-2 text-primary-1" />
            </div>
            <TextStyle
              className="cursor-pointer text-sm text-primary-6 underline"
              onClick={onViewThreadClicked}
              variant="base-medium"
            >
              {formatMessage(
                commentCount > 1 ? labels.moreReplies : labels.oneMoreReply,
                {
                  replyCount: commentCount,
                }
              )}
            </TextStyle>
          </div>
          <LineIcon className="m-1 h-2" />
        </div>
      ) : (
        <LineIcon className="m-1 ml-3 h-2" />
      )}
      <ConversationCard
        messageNode={messageNode}
        gifURL={gifURL}
        key={commentId}
        cardId={commentId}
        variant="single"
        canShowToolbar={true}
        messageContent={content}
        currentMemberId={currentUserId}
        onReactionClick={handleReactionClick}
        toolbarItems={toolbarMenuItems}
        onMemberClick={onMemberClick}
        memberDetails={conversationMemberDetails}
        onMenuItemClick={handleMenuItem}
        reactions={reactions}
        isGroupedReplyCard={true}
        pointsEach={pointsEach}
        isEdited={isEdited}
        taggedUsers={taggedUsers}
        boost={boostedUsers}
        currencyDetails={currencyDetails}
      />
      <Button
        size="small"
        className="mx-12 my-2 w-fit p-3"
        variation="secondaryLite"
        onClick={handleViewRepliesClick}
      >
        {buttonText}
      </Button>
    </div>
  );
}
