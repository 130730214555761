import type { QueryClientConfig } from '@tanstack/react-query';
import type { AxiosError } from 'axios';

import { HttpStatusCodes } from '../HttpStatusCodes';
import { routeConstants } from './routes';

export const queryClientOptions: QueryClientConfig = {
  defaultOptions: {
    queries: {
      staleTime: 1000 * 60 * 5,
      networkMode: 'always',
      refetchOnWindowFocus: () => {
        return navigator.onLine;
      },
      retry: (failureCount: number, e: unknown) => {
        const excludedRetryStatusCodes = [
          HttpStatusCodes.Conflict,
          HttpStatusCodes.Forbidden,
          HttpStatusCodes.BadRequest,
          HttpStatusCodes.ServerError,
          HttpStatusCodes.Unauthorized,
          HttpStatusCodes.PreconditionFailed,
        ];
        const error = e as AxiosError;
        const statusCode = Number(error.response?.status);

        if (!statusCode || excludedRetryStatusCodes.includes(statusCode)) {
          return false;
        }

        return failureCount < 3;
      },
      onError: (e: unknown) => {
        const error = e as AxiosError;

        if (
          !error.response ||
          error.response.status !== HttpStatusCodes.Unauthorized
        ) {
          return;
        }

        const currentPath = window.location.pathname;

        const isOnboardingPath = routeConstants.onboardingPaths.some((path) =>
          currentPath.includes(path)
        );

        const isNewExperiencePath = routeConstants.newExperiencePaths.some(
          (path) => currentPath.includes(path)
        );

        const isPublicPath = routeConstants.publicPaths.some((path) =>
          currentPath.includes(path)
        );

        if (isNewExperiencePath && !isPublicPath) {
          window.location.href = '/workspaces';
        } else if (!isOnboardingPath && !isPublicPath) {
          window.location.href = '/login';
        }
      },
    },
  },
};
