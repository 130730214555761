import { XMarkIcon } from '@heroicons/react/24/outline';
import type { KeyboardEvent, ReactElement } from 'react';
import { defineMessages, useIntl } from 'react-intl';
import { twMerge } from 'tailwind-merge';

import { TextStyle } from '../../../../../DesignSystem/Feedback/TextStyle';
import { IconButton } from '../../../../../DesignSystem/Inputs/IconButton';

export type OverflowCardProps = {
  /** Avatar or Icon for overflow card */
  avatar: ReactElement;

  /** Secondary text */
  content: ReactElement;

  /** Whether there is new activity that should be highlighted */
  hasNewActivity?: boolean;

  /** Title of the overflow card */
  title: string;

  /** Event handler called when the card gets closed out */
  onClose: () => void;

  /** Event handler called when the card gets clicked on */
  onClick: () => void;
};

const messages = defineMessages({
  close: {
    defaultMessage: 'Close',
    id: 'rbrahO',
  },
});

export function OverflowCard(props: OverflowCardProps) {
  const { title, avatar, hasNewActivity, content, onClose, onClick } = props;
  const { formatMessage } = useIntl();

  const onKeyDown = (ev: KeyboardEvent<HTMLElement>) => {
    if (ev.key === 'Enter') {
      onClick();
    }
  };

  return (
    <section className="group relative w-full">
      {Boolean(hasNewActivity) && (
        <div className="absolute -left-[5px] -top-[5px] h-3 w-3 rounded-full border border-gray-1 bg-primary-6" />
      )}

      <div
        className={twMerge(
          'flex w-full items-start rounded-lg p-3 group-hover:shadow-base-down',
          hasNewActivity
            ? 'border-2 border-primary-6'
            : 'border border-gray-5 group-hover:border-gray-6'
        )}
        onClick={onClick}
        onKeyDown={onKeyDown}
        tabIndex={0}
        role="button"
      >
        <div className="py-[1px]">{avatar}</div>
        <div className="grow pl-3">
          <TextStyle
            variant="sm-regular"
            className="line-clamp-2 pr-6 text-gray-9"
          >
            {title}
          </TextStyle>
          <div className="line-clamp-1 text-xs">{content}</div>
        </div>
      </div>
      <IconButton
        onClick={onClose}
        variation="tertiaryLite"
        size="xSmall"
        className="absolute right-0 top-0 m-3"
      >
        <span className="sr-only">{formatMessage(messages.close)}</span>
        <XMarkIcon className="h-4 w-4" aria-hidden="true" />
      </IconButton>
    </section>
  );
}
