import { mapHexCodeToEmoticon } from '@assembly-web/services';
import { ChevronDownIcon } from '@heroicons/react/24/outline';
import {
  Content,
  DropdownMenu,
  Portal,
  Root,
  Trigger,
} from '@radix-ui/react-dropdown-menu';
import { useState } from 'react';
import { twMerge } from 'tailwind-merge';

import { TextStyle } from '../../../DesignSystem/Feedback/TextStyle';
import { useDropdownHandler } from '../../hooks/useDropdownState';
import type {
  SelectableListProps,
  SelectableOptionProps,
} from '../SelectableList';
import { SelectableList } from '../SelectableList';

export type SelectableListDropdownProps = {
  selectableListOptions: SelectableOptionProps[];
  label: string;
  postFixLabel: string;
  onDoneClick?: ({
    label,
    currentSelectedOptions,
  }: {
    label: string;
    currentSelectedOptions: SelectableOptionProps[];
  }) => void;
  selectedOptions: SelectableOptionProps[];
  showPostFixLabelOnly?: boolean;
  onSearchChange?: ({ label, value }: { label: string; value: string }) => void;
  limit?: number;
  isSingleSelect?: boolean;
  onSelect?: ({
    label,
    option,
  }: {
    label: string;
    option: SelectableOptionProps;
  }) => void;
} & Pick<
  SelectableListProps,
  | 'isLoading'
  | 'showSearch'
  | 'onLoadMore'
  | 'renderOption'
  | 'isFetchingNextPage'
  | 'hasNextPage'
  | 'loader'
  | 'onClearAllClick'
  | 'onSelect'
  | 'isAsync'
>;

export function SelectableListDropdown({
  isLoading,
  showSearch,
  onLoadMore,
  renderOption,
  onDoneClick,
  onSearchChange,
  isFetchingNextPage,
  selectableListOptions,
  label,
  postFixLabel,
  hasNextPage,
  selectedOptions,
  limit = 1,
  loader,
  onClearAllClick,
  isSingleSelect = false,
  onSelect,
  isAsync = true,
}: SelectableListDropdownProps) {
  const [isOpen, setIsOpen] = useState(false);
  const { getContentProps, getTriggerProps } = useDropdownHandler();

  const onOpenChange = () => {
    if (isOpen) {
      onSearchChange?.({ label, value: '' });
    }
    setIsOpen(!isOpen);
  };

  const generateFilterLabel = () => {
    if (selectedOptions.length === 0) {
      return label;
    } else if (selectedOptions.length <= limit) {
      return `${label}: ${selectedOptions
        .map(
          (option) =>
            `${
              option.emoticon && typeof option.emoticon === 'string'
                ? mapHexCodeToEmoticon(option.emoticon)
                : ''
            }${option.value}`
        )
        .join(', ')}`;
    } else {
      return `${label}: ${selectedOptions.length} ${postFixLabel}`;
    }
  };

  return (
    <Root>
      <DropdownMenu modal={false} open={isOpen}>
        <Trigger
          className="group focus-visible:rounded-lg"
          {...getTriggerProps({
            onClick: () => {
              onOpenChange();
            },
          })}
        >
          <div
            className={twMerge(
              'flex max-w-[280px] items-center gap-2 rounded-lg border border-gray-5 bg-gray-1 px-2 py-1 align-middle hover:bg-gray-3 focus:bg-gray-3',
              selectedOptions.length &&
                'bg-gray-3 hover:bg-gray-4 focus:bg-gray-4'
            )}
          >
            <div className="flex flex-wrap items-center truncate">
              <TextStyle
                as="p"
                variant={selectedOptions.length ? 'sm-medium' : 'sm-regular'}
                className="truncate text-gray-9"
              >
                {generateFilterLabel()}
              </TextStyle>
            </div>
            <ChevronDownIcon
              color="gray-9"
              className="h-4 w-4 text-gray-9 group-aria-expanded:rotate-180"
            />
          </div>
        </Trigger>
        <Portal>
          <Content
            align="start"
            sideOffset={5}
            alignOffset={-3}
            className="w-[280px] rounded-lg border border-gray-5 bg-gray-1 shadow-md-down"
            {...getContentProps({
              onInteractOutside: () => {
                onOpenChange();
              },
            })}
          >
            <SelectableList
              isLoading={isLoading}
              onLoadMore={onLoadMore}
              onDoneClick={(currentSelectedOptions) => {
                setIsOpen(false);
                onDoneClick?.({ label, currentSelectedOptions });
              }}
              onClearAllClick={() => {
                onClearAllClick?.();
                setIsOpen(false);
              }}
              options={selectableListOptions}
              selectedOptions={selectedOptions}
              onSearchChange={(val) => onSearchChange?.({ label, value: val })}
              renderOption={renderOption}
              className="max-w-screen"
              showSearch={showSearch}
              placeholder="Search"
              isFetchingNextPage={isFetchingNextPage}
              hasNextPage={hasNextPage}
              loader={loader}
              isSingleSelect={isSingleSelect}
              onSelect={(option) => {
                if (isSingleSelect) {
                  setIsOpen(false);
                  onSelect?.({ label, option });
                }
              }}
              isAsync={isAsync}
            />
          </Content>
        </Portal>
      </DropdownMenu>
    </Root>
  );
}
