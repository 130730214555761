import type {
  BlockResponseDetails,
  File,
  FlowPostResponse,
} from '@assembly-web/services';
import {
  AllowedMedia,
  BlockResponseState,
  BlockTypes,
  LegacyPostTypes,
  MemberRole,
  MemberState,
  MultiChoiceBlockOptionType,
  PersonSelectorSelectType,
  ReactionTypes,
  VisibilityTypes,
} from '@assembly-web/services';

import {
  getRespondentForAnonymousFlowPost,
  getRespondentForLegacyFlowPost,
} from '../utils';

const flowResponseDataWithAllBlocksAnswered: BlockResponseDetails[] = [
  {
    type: BlockTypes.OpenEnded,
    blockId: '642bbd9dcb4f78ad2f4ffa49',
    state: BlockResponseState.Responded,
    content: {
      title: 'This is a question?',
      type: BlockTypes.OpenEnded,
      rules: {
        required: false,
        allowedMedia: [
          AllowedMedia.Files,
          AllowedMedia.Emoji,
          AllowedMedia.Gif,
          AllowedMedia.Mention,
        ],
        fileType: ['jpg', 'jpeg'],
      },
    },
    response: {
      value:
        '@__60fa5cf3c6866fcbc88d0667__@ Test😀 \n@__task:642bbde8cb4f78ad2f4ffec9__@',
      files: [
        {
          type: 'mov',
          size: 7126347,
          location:
            'https://duploservices-dev02-assembly-flows-333387423585.s3-accelerate.amazonaws.com/5ae639b425ca241b3e2d4b07/646e272f58b74f340b9b826a/64822074154778d2b42bc5cd/646e2535741b2f4c6731f10e/screen_recording_2023_06_07_at_5_37_10_pm.mov',
          createdAt: '2023-06-08T18:40:00.204Z',
          name: 'screen_recording_2023_06_07_at_5_37_10_pm.mov',
          _id: '648220d5154778d2b42bccc1',
          thumbnails: {
            '360':
              'https://dev-assets.infra.joinassembly.com/duploservices-dev02-assembly-flow-thumbnails-333387423585/5ae639b425ca241b3e2d4b07/646e272f58b74f340b9b826a/64822074154778d2b42bc5cd/646e2535741b2f4c6731f10e/screen_recording_2023_06_07_at_5_37_10_pm_360.mov?Expires=1686348740&Key-Pair-Id=K1UJCHCTXH6IMI&Signature=QC0LpXHRSO0QyMPfukC~8xtYOEPFbx8MIBGyL52I9UQidTUNefahDp3Jpyh8tICUUpFkxG7b8znxRUZlgjKt0zxVZbH1E~4fBWMSvBNN94tt7pR70NrvqTE0jW1mAsso~0se1RDXI9SYXIPpOmW609QJdOm~mSe4JZPJZIOaDD5PJusEsPHhaviMJPsuO2gLsbFNp307qap1l5IvNvDvmSEBXZ9FoPmeBGkHMkaDccEbDYhWIeYWT~QSkf3~ipOmRCMk5rh7jJIQIA~egoy-BueECfIfKjQMYGmE8ESGPimUnXjNIg~3aCJ-AOe6IjeIYJKGI9gq1g672vc6nPmZ4A__',
          },
        },
      ] as File[],
      gifUrl:
        'https://media4.giphy.com/media/G11qQvwA0XVK5rPKef/giphy-downsized-medium.gif',
      mentions: [
        {
          memberId: '60fa5cf3c6866fcbc88d0667',
          firstName: 'Sumedha',
          lastName: 'Kulkarni',
          image: 'https://i.pravatar.cc/150?u=sumedha.r.kulkarni@pravatar.com',
          memberState: MemberState.Active,
        },
      ],
      tags: [],
    },
    edited: false,
  },
  {
    type: BlockTypes.Dropdown,
    blockId: '642bbd9dcb4f78ad2f4ffa4a',
    state: BlockResponseState.Responded,
    content: {
      title: 'This is a question?',
      type: BlockTypes.Dropdown,
      options: [
        { id: '337ddc5b-10f6-408b-bc62-9032e7e0be86', value: 'Option 1' },
        { id: '08e44725-b1f5-405d-9c87-4448f0d30886', value: 'Option 2' },
      ],
      rules: { required: false, limit: { exact: 1 } },
    },
    response: {
      value: [
        { id: '337ddc5b-10f6-408b-bc62-9032e7e0be86', value: 'Option 1' },
        { id: '337ddc5b-10f6-408b-bc62-9032e7e0be87', value: 'Option 2' },
      ],
    },
    edited: false,
  },
  {
    type: BlockTypes.Scale,
    blockId: '642bbd9dcb4f78ad2f4ffa4b',
    state: BlockResponseState.Responded,
    content: {
      title: 'This is a question?',
      type: BlockTypes.Scale,
      min: 0,
      max: 5,
      rules: { required: false },
      labels: { low: 'left', middle: 'middle', high: 'right' },
      isNPSEnabled: false,
    },
    response: { value: 4 },
    edited: false,
  },
  {
    type: BlockTypes.MultiChoice,
    blockId: '642bbd9dcb4f78ad2f4ffa4c',
    state: BlockResponseState.Responded,
    content: {
      title: 'This is a question?',
      type: BlockTypes.MultiChoice,
      options: [
        { id: '1a5896b2-4abb-4ef0-a3bb-520d8e9481a0', value: 'Option 1' },
      ],
      rules: { required: false, limit: { exact: 1 }, allowOther: false },
      optionType: MultiChoiceBlockOptionType.Single,
    },
    response: {
      value: [
        { id: '1a5896b2-4abb-4ef0-a3bb-520d8e9481a0', value: 'Option 1' },
      ],
    },
    edited: false,
  },
  {
    type: BlockTypes.FileUpload,
    blockId: '642bbd9dcb4f78ad2f4ffa4d',
    state: BlockResponseState.Responded,
    content: {
      title: 'This is a question?',
      type: BlockTypes.FileUpload,
      rules: { required: false },
    },
    response: {
      files: [
        {
          type: 'pdf',
          size: 3782924,
          location:
            'https://duploservices-dev02-assembly-flows-333387423585.s3-accelerate.amazonaws.com/5ae639b425ca241b3e2d4b07/642bbd9dcb4f78ad2f4ffa46/643e72bed4a4dd2d16d55081/642bbd9dcb4f78ad2f4ffa4d/clean_code_a_handbook_of_agile_software_craftsmanship.pdf',
          createdAt: '2023-06-05T06:15:19.581Z',
          name: 'clean_code_a_handbook_of_agile_software_craftsmanship.pdf',
          _id: '647d7d80af439cb2e3377b69',
          thumbnails: {
            '360':
              'https://dev-assets.infra.joinassembly.com/duploservices-dev02-assembly-flow-thumbnails-333387423585/5ae639b425ca241b3e2d4b07/642bbd9dcb4f78ad2f4ffa46/643e72bed4a4dd2d16d55081/642bbd9dcb4f78ad2f4ffa4d/clean_code_a_handbook_of_agile_software_craftsmanship_360.pdf?Expires=1686032866&Key-Pair-Id=K1UJCHCTXH6IMI&Signature=BnmRhiA0eFx6p~gBDaLfeQAPyGKdlByyaoO3pZj93SZNZa7lLwJAaj~ZQFImYYAcOymbGnpRFXAnBCXAsRD8ui3s-l67daV~7DmV8Q6FvmGWikYq8GAwmbJUFWmLMCA4cf4Kcm8bcFlw7TRvtJNamnUE0R~uvcNDqSxNMvy~BiLMzeGV23QDtblwZ7EXGyMP6LW7Bl4VN3Ce9sMi1bH3rfUyTz24DFylo4DQavkfTiogqw6z~KWzXlfLGpjxc1lf63obv36Fl5RhF9i13EVjCwvwvcVNgYZPUGoCNn5JeEVTn1PyzoCnfgLUlUlslzjp-YGi1nrRcMx4sU819x5cxg__',
          },
        },
      ] as File[],
    },
    edited: false,
  },
  {
    type: BlockTypes.PersonSelector,
    blockId: '642bbd9dcb4f78ad2f4ffa4e',
    state: BlockResponseState.Responded,
    content: {
      title: 'This is a question?',
      type: BlockTypes.PersonSelector,
      selectType: PersonSelectorSelectType.SinglePerson,
      rules: { required: false, select: 'EVERYONE' },
    },
    response: {
      persons: [
        {
          memberId: '60fa5cf3c6866fcbc88d0667',
          name: { firstName: 'Sumedha', lastName: 'Kulkarni' },
          memberState: MemberState.Active,
          image: 'https://i.pravatar.cc/150?u=sumedha.r.kulkarni@pravatar.com',
        },
      ],
    },
    edited: false,
  },
  {
    type: BlockTypes.Gif,
    blockId: '642bbd9dcb4f78ad2f4ffa4f',
    state: BlockResponseState.Responded,
    content: {
      title: 'This is a question?',
      type: BlockTypes.Gif,
      rules: { required: false },
    },
    response: {
      value:
        'https://media4.giphy.com/media/G11qQvwA0XVK5rPKef/giphy-downsized-medium.gif',
    },
    edited: false,
  },
  {
    type: BlockTypes.PersonSelector,
    blockId: '642bbd9dcb4f78ad2f4ffa50',
    state: BlockResponseState.Responded,
    content: {
      title: 'This is a question?',
      type: BlockTypes.PersonSelector,
      selectType: PersonSelectorSelectType.SinglePerson,
      key: '272385149',
      rules: { required: false, select: 'EVERYONE' },
    },
    response: {
      persons: [
        {
          memberId: '621f45a884cd3b8067fb785e',
          name: {
            firstName: 'Abraham Benjamin',
            lastName: 'de Villiers',
          },
          memberState: MemberState.Active,
        },
      ],
    },
    edited: false,
  },
  {
    type: BlockTypes.GivePointsStack,
    blockId: '642bbd9dcb4f78ad2f4ffa51',
    state: BlockResponseState.Responded,
    content: {
      title: 'This is a question?',
      type: BlockTypes.GivePointsStack,
      dependentKeys: ['272385149'],
      rules: {
        required: false,
        hidePoints: false,
        points: { noLimit: true },
      },
    },
    response: { value: 2 },
    edited: false,
  },
  {
    type: BlockTypes.Scale,
    blockId: '642bbd9dcb4f78ad2f4ffa52',
    state: BlockResponseState.Responded,
    content: {
      title:
        'How likely are you to recommend [Company/Product/Service] to a friend or colleague?',
      type: BlockTypes.Scale,
      min: 0,
      max: 10,
      rules: { required: false },
      isNPSEnabled: true,
      labels: { low: 'Not likely at all', high: 'Very likely' },
    },
    response: { value: 8 },
    edited: false,
  },
];

const flowResponseDataWithNoBlocksAnswered: BlockResponseDetails[] = [
  {
    type: BlockTypes.OpenEnded,
    blockId: '642bbd9dcb4f78ad2f4ffa49',
    state: BlockResponseState.Skipped,
    content: {
      title: 'This is a question?',
      type: BlockTypes.OpenEnded,
      rules: {
        required: false,
        allowedMedia: [
          AllowedMedia.Files,
          AllowedMedia.Emoji,
          AllowedMedia.Gif,
          AllowedMedia.Mention,
        ],
        fileType: ['jpg', 'jpeg'],
      },
    },
    response: {
      files: [],
    },
    edited: false,
  },
  {
    type: BlockTypes.Dropdown,
    blockId: '642bbd9dcb4f78ad2f4ffa4a',
    state: BlockResponseState.Skipped,
    content: {
      title: 'This is a question?',
      type: BlockTypes.Dropdown,
      options: [
        {
          id: '337ddc5b-10f6-408b-bc62-9032e7e0be86',
          value: 'Option 1',
        },
        {
          id: '08e44725-b1f5-405d-9c87-4448f0d30886',
          value: 'Option 2',
        },
      ],
      rules: {
        required: false,
        limit: {
          exact: 1,
        },
      },
    },
    response: null,
    edited: false,
  },
  {
    type: BlockTypes.Scale,
    blockId: '642bbd9dcb4f78ad2f4ffa4b',
    state: BlockResponseState.Skipped,
    content: {
      title: 'This is a question?',
      type: BlockTypes.Scale,
      min: 0,
      max: 5,
      rules: {
        required: false,
      },
      labels: {
        low: 'left',
        middle: 'middle',
        high: 'right',
      },
      isNPSEnabled: false,
    },
    response: null,
    edited: false,
  },
  {
    type: BlockTypes.MultiChoice,
    blockId: '642bbd9dcb4f78ad2f4ffa4c',
    state: BlockResponseState.Skipped,
    content: {
      title: 'This is a question?',
      type: BlockTypes.MultiChoice,
      options: [
        {
          id: '1a5896b2-4abb-4ef0-a3bb-520d8e9481a0',
          value: 'Option 1',
        },
      ],
      rules: {
        required: false,
        limit: {
          exact: 1,
        },
        allowOther: false,
      },
      optionType: MultiChoiceBlockOptionType.Single,
    },
    response: null,
    edited: false,
  },
  {
    type: BlockTypes.FileUpload,
    blockId: '642bbd9dcb4f78ad2f4ffa4d',
    state: BlockResponseState.Skipped,
    content: {
      title: 'This is a question?',
      type: BlockTypes.FileUpload,
      rules: {
        required: false,
      },
    },
    response: {
      files: [],
    },
    edited: false,
  },
  {
    type: BlockTypes.PersonSelector,
    blockId: '642bbd9dcb4f78ad2f4ffa4e',
    state: BlockResponseState.Skipped,
    content: {
      title: 'This is a question?',
      type: BlockTypes.PersonSelector,
      selectType: PersonSelectorSelectType.SinglePerson,
      rules: {
        required: false,
        select: 'EVERYONE',
      },
    },
    response: null,
    edited: false,
  },
  {
    type: BlockTypes.Gif,
    blockId: '642bbd9dcb4f78ad2f4ffa4f',
    state: BlockResponseState.Skipped,
    content: {
      title: 'This is a question?',
      type: BlockTypes.Gif,
      rules: {
        required: false,
      },
    },
    response: null,
    edited: false,
  },
  {
    type: BlockTypes.PersonSelector,
    blockId: '642bbd9dcb4f78ad2f4ffa50',
    state: BlockResponseState.Skipped,
    content: {
      title: 'This is a question?',
      type: BlockTypes.PersonSelector,
      selectType: PersonSelectorSelectType.SinglePerson,
      key: '272385149',
      rules: {
        required: false,
        select: 'EVERYONE',
      },
    },
    response: null,
    edited: false,
  },
  {
    type: BlockTypes.GivePointsStack,
    blockId: '642bbd9dcb4f78ad2f4ffa51',
    state: BlockResponseState.Skipped,
    content: {
      title: 'This is a question?',
      type: BlockTypes.GivePointsStack,
      dependentKeys: ['272385149'],
      rules: {
        required: false,
        hidePoints: false,
        points: {
          noLimit: true,
        },
      },
    },
    response: null,
    edited: false,
  },
  {
    type: BlockTypes.Scale,
    blockId: '642bbd9dcb4f78ad2f4ffa52',
    state: BlockResponseState.Skipped,
    content: {
      title:
        'How likely are you to recommend [Company/Product/Service] to a friend or colleague?',
      type: BlockTypes.Scale,
      min: 0,
      max: 10,
      rules: {
        required: false,
      },
      isNPSEnabled: true,
      labels: {
        low: 'Not likely at all',
        high: 'Very likely',
      },
    },
    response: null,
    edited: false,
  },
];

const baseFlowResponse = {
  responseId: '642bbe52cb4f78ad2f4fff4b',
  instanceId: '642bbdb0cb4f78ad2f4ffe9b',
  respondent: {
    name: 'Sumedha Kulkarni',
    firstName: 'Sumedha',
    lastName: 'Kulkarni',
    username: 'sumedhakulkarni',
    email: 'sumedha.r.kulkarni@joinassembly.com',
    memberID: '60fa5cf3c6866fcbc88d0667',
    image: 'https://i.pravatar.cc/150?u=sumedha.r.kulkarni@pravatar.com',
    pointsGiven: 206,
    totalPointsGiven: 206,
    memberState: MemberState.Active,
    role: [MemberRole.Employee, MemberRole.Admin],
  },
  flow: {
    flowId: '642bbd9dcb4f78ad2f4ffa46',
    version: 1,
    name: "Sumedha's flow with all blocks",
    kind: 'NO_TRIGGER',
    icon: { kind: 'HEX_CODE', value: '0030-fe0f-20e3' },
  },
  commentsCount: 0,
  reactions: [
    {
      name: ':laughing:',
      value: '',
      type: ReactionTypes.Regular,
      displayName: '',
      members: [
        {
          name: 'Arun Selvakumar',
          firstName: 'Arun',
          lastName: 'Selvakumar',
          username: 'arunselvakumar',
          email: 'arun@joinassembly.com',
          memberID: '6050cee28d63ea0593615538',
          image: 'https://i.pravatar.cc/150?u=arun@pravatar.com',
          pointsGiven: 490,
          totalPointsGiven: 490,
          memberState: MemberState.Active,
          role: [MemberRole.Employee, MemberRole.Admin],
          pronouns: 'He/Him/His',
        },
        {
          // Current Member
          name: '',
          firstName: '',
          lastName: '',
          username: '',
          email: '',
          memberID: '',
          image: 'https://i.pravatar.cc/150?u=victor@pravatar.com',
          pointsGiven: 100,
          totalPointsGiven: 900,
          memberState: MemberState.Active,
          role: [MemberRole.Employee],
          pronouns: 'He/Him/His',
        },
      ],
    },
    {
      name: ':one-piece_swimsuit:',
      value: '',
      type: ReactionTypes.Regular,
      displayName: 'one-piece_swimsuit',
      members: [
        {
          name: 'Jayasurya Seetharaman',
          firstName: 'Jayasurya',
          lastName: 'Seetharaman',
          username: 'surya',
          email: 'surya@joinassembly.com',
          memberID: '6050cee28d63ea0593615538',
          image: 'https://i.pravatar.cc/150?u=surya@pravatar.com',
          pointsGiven: 490,
          totalPointsGiven: 490,
          memberState: MemberState.Active,
          role: [MemberRole.Employee, MemberRole.Admin],
          pronouns: 'He/Him/His',
        },
      ],
    },
    {
      name: ':flag-ad:',
      value: '',
      type: ReactionTypes.Regular,
      displayName: 'flag-ad',
      members: [
        {
          name: 'Sumedha Kulkarni',
          firstName: 'Sumedha',
          lastName: 'Kulkarni',
          username: 'sumedhakulkarni',
          email: 'sumedha.r.kulkarni@joinassembly.com',
          memberID: '60fa5cf3c6866fcbc88d0667',
          image: 'https://i.pravatar.cc/150?u=sumedha.r.kulkarni@pravatar.com',
          pointsGiven: 206,
          totalPointsGiven: 206,
          memberState: MemberState.Active,
          role: [MemberRole.Employee, MemberRole.Admin],
        },
      ],
    },
  ],
  visibility: VisibilityTypes.OnlyOwners,
  isPrivate: false,
  createdAt: '2023-04-04T06:06:13.618Z',
  canDelete: true,
  canEdit: true,
  kind: 'INTERNAL',
  isBoostAllowed: true,
};

export const flowResponseWithAllBlocks: FlowPostResponse = {
  ...baseFlowResponse,
  responses: flowResponseDataWithAllBlocksAnswered,
};

export const flowResponseWithNoBlocksAnswered: FlowPostResponse = {
  ...baseFlowResponse,
  responses: flowResponseDataWithNoBlocksAnswered,
};

export const flowResponseWithAnnouncement: FlowPostResponse = {
  ...baseFlowResponse,
  activeAnnouncement: {
    viewsCount: 0,
    color: 'green',
    showInsights: true,
    title: 'PTO Policy Update',
    announcementId: '64f02604829da3e24da20aad',
    description: 'Please review the updated guidelines as soon as possible.',
    notificationSettings: {
      push: true,
      email: true,
    },
  },
  responses: flowResponseDataWithNoBlocksAnswered,
};

export const repliesDataWithMembers = {
  count: 14,
  respondentsCount: 6,
  lastRepliedAt: '2023-04-12T06:13:59.744Z',
  initialRespondents: [
    {
      name: 'Michael Scott',
      memberID: '5f763ea75d84aa1507315c5c',
      image: 'https://i.pravatar.cc/150?u=scott@pravatar.com',
    },
    {
      name: 'Dwight Schrute',
      memberID: '640987298cef2a872a37f3eb',
    },
    {
      name: 'Jim Halpert',
      memberID: '5d4a73cbf716a00a7487bcee',
    },
    {
      name: 'Pam Beesly',
      memberID: '61e9f5ff46a1da510e8ad564',
    },
    {
      name: 'Andy Bernard',
      memberID: '5e6dd104cf9ac100113cd43f',
      image: 'https://i.pravatar.cc/150?u=andy@pravatar.com',
    },
  ],
};

export const repliesDataWithoutMembers = {
  count: 0,
  respondentsCount: 0,
  lastRepliedAt: '2023-04-12T06:13:59.744Z',
  initialRespondents: [],
};

export const anonymousFlowPostResponseData: FlowPostResponse = {
  instanceId: '',
  responseId: '63935e814b73119ea3e510a5',
  respondent: getRespondentForAnonymousFlowPost(),
  responses: [
    {
      type: BlockTypes.OpenEnded,
      blockId: '63935c2c2092f8e165e6ec9c',
      state: BlockResponseState.Responded,
      content: {
        title: 'Answer',
        type: BlockTypes.OpenEnded,
        rules: {
          required: false,
          allowedMedia: [
            AllowedMedia.Files,
            AllowedMedia.Emoji,
            AllowedMedia.Gif,
            AllowedMedia.Mention,
          ],
          fileType: [
            'jpg',
            'jpeg',
            'png',
            'svg',
            'gif',
            'pdf',
            'doc',
            'docx',
            'ppt',
            'pptx',
            'pps',
            'ppsx',
            'xls',
            'xlsx',
            'csv',
            'txt',
            'odt',
            'zip',
            'mp3',
            'm4a',
            'ogg',
            'wav',
            'mp4',
            'm4v',
            'mov',
            'wmv',
            'avi',
            'mpg',
            'ogv',
            '3gp',
            '3g2',
          ],
        },
      },
      response: {
        value: 'Hello there',
        files: [],
        gifUrl: '',
        mentions: [],
        tags: [],
      },
      edited: false,
    },
  ],
  flow: {
    flowId: '63935a232092f8e165e6dd96',
    version: 2,
    name: 'External prod-eng-help',
    kind: 'NO_TRIGGER',
    icon: { kind: 'HEX_CODE', value: '2620-fe0f' },
  },
  commentsCount: 0,
  reactions: [],
  visibility: VisibilityTypes.OnlyOwners,
  isPrivate: false,
  createdAt: '2022-12-09T16:12:49.243Z',
  canDelete: true,
  canEdit: false,
  kind: 'EXTERNAL_ANONYMOUS',
  isBoostAllowed: false,
};

export const DummyCurrentMember = {
  ...getRespondentForLegacyFlowPost(LegacyPostTypes.Birthday),
  image: 'https://i.pravatar.cc/150?u=dummy@pravatar.com',
};
