import { withoutDefaultEventBehavior } from '@assembly-web/services';
import { Content, List, Root } from '@radix-ui/react-tabs';
import { AnimatePresence, motion } from 'framer-motion';
import { forwardRef, type ReactNode, useEffect, useRef, useState } from 'react';
import { useIntl } from 'react-intl';

import { Modal } from '../../../../DesignSystem/Feedback/Modal';
import { Button } from '../../../../DesignSystem/Inputs/Button';
import { messages } from './message';
import { TabItem } from './TabItem';

const PageReloadAlert = () => {
  useEffect(() => {
    const handleBeforeUnload = (event: {
      preventDefault: () => void;
      returnValue: string;
    }) => {
      event.preventDefault();
      event.returnValue = '';
    };
    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, []);
  return null;
};

export type MultiStepperModalProps = {
  tabs: {
    value: string;
    label: string;
    component: ReactNode;
    disabled?: boolean;
  }[];
  onGetHelpButtonClick: () => void;
  isOpen: boolean;
  header: ReactNode;
  customSubmitButton: ReactNode;
  onNextPage: (selectedTab: string) => Promise<boolean>;
  onClose: () => void;
};

export const MultiStepperModal = forwardRef<
  HTMLDivElement,
  MultiStepperModalProps
>(
  (
    {
      tabs,
      onGetHelpButtonClick,
      isOpen,
      header,
      customSubmitButton,
      onNextPage,
      onClose,
    },
    ref
  ) => {
    const [prevTabIndex, setPrevTabIndex] = useState(0);
    const [selectedTab, setSelectedTab] = useState(tabs[0].value);
    const [isNavigatedInTabs, setIsNavigatedInTabs] = useState(false);
    const [visitedTabs, setVisitedTabs] = useState([tabs[0].value]);

    const { formatMessage } = useIntl();
    const tabValues = tabs.map((item) => item.value);
    const modalRef = useRef<HTMLDivElement>(null);

    const navigateTab = (step: number) => {
      setIsNavigatedInTabs(true);
      const currentIndex = tabValues.indexOf(selectedTab);
      const newIndex = currentIndex + step;

      if (newIndex >= 0 && newIndex < tabValues.length) {
        setPrevTabIndex(currentIndex);
        setSelectedTab(tabValues[newIndex]);
        setVisitedTabs((prevTabs) => [...prevTabs, tabValues[newIndex]]);
      }
    };

    const onNextButtonClick = async () => {
      const isValid = await onNextPage(selectedTab);
      if (isValid) {
        navigateTab(1);
        if (modalRef.current) modalRef.current.scrollTo(0, 0);
      }
    };

    const onBackButtonClick = () => navigateTab(-1);

    const handleTabChange = (value: string) => {
      if (!visitedTabs.includes(value)) {
        setVisitedTabs((prevTabs) => [...prevTabs, value]);
      }
      setSelectedTab(value);
    };

    const nextButton = selectedTab !== tabs[tabs.length - 1].value && (
      <Button
        variation="primary"
        onClick={withoutDefaultEventBehavior(onNextButtonClick)}
        aria-label="next-button"
      >
        {formatMessage(messages.nextLabel)}
      </Button>
    );

    const backButton = selectedTab !== tabs[0].value && (
      <Button
        variation="tertiaryLite"
        onClick={withoutDefaultEventBehavior(onBackButtonClick)}
      >
        {formatMessage(messages.backLabel)}
      </Button>
    );

    const isMovingForward = prevTabIndex < tabValues.indexOf(selectedTab);
    const isLastTab = selectedTab === tabs[tabs.length - 1].value;

    const slideVariants = isNavigatedInTabs
      ? {
          animate: {
            x: 0,
            opacity: 1,
            transition: { type: 'tween', ease: 'easeInOut', duration: 0.1 },
          },
          exit: {
            opacity: 0,
            x: isMovingForward ? -50 : 50,
            transition: { type: 'tween', ease: 'easeInOut', duration: 0.1 },
          },
          initial: {
            opacity: 0,
            x: isMovingForward ? 50 : -50,
            transition: { type: 'tween', ease: 'easeInOut', duration: 0.1 },
          },
        }
      : undefined;

    return (
      <Modal
        isOpen={isOpen}
        onClose={onClose}
        className="flex flex-col overflow-scroll sm:w-full sm:max-w-none md:w-[632px]"
        headerClassName="border-b border-gray-4 relative z-[11]"
        title={header}
        bodyClassName="p-0"
        closeButtonSize="large"
      >
        <PageReloadAlert />
        <Root
          value={selectedTab}
          className="px-5"
          ref={ref}
          onValueChange={(value) => handleTabChange(value)}
        >
          <List className="relative z-[11] flex gap-6 bg-gray-1 py-4">
            {tabs.map((item, index) => (
              <TabItem
                key={index}
                step={index + 1}
                progress={100}
                value={item.value}
                isActive={selectedTab === item.value}
                visited={visitedTabs.includes(item.value)}
                disabled={
                  item.disabled ?? (index !== 0 && visitedTabs.length === 1)
                }
                label={item.label}
              />
            ))}
          </List>
          <div
            className="h-[min(calc(100vh-16px),390px)] overflow-y-scroll p-1"
            ref={modalRef}
          >
            {tabs.map((context, index) => (
              <Content
                className="TabsContent"
                value={context.value}
                key={index}
              >
                <AnimatePresence>
                  <motion.div
                    key={context.value}
                    variants={slideVariants}
                    initial="initial"
                    animate="animate"
                    exit="exit"
                  >
                    {context.component}
                  </motion.div>
                </AnimatePresence>
              </Content>
            ))}
          </div>
        </Root>
        <footer className="flex flex-shrink-0 justify-between border-t border-gray-4 p-4">
          <Button
            type="button"
            variation="secondaryLite"
            className="text-gray-8"
            onClick={withoutDefaultEventBehavior(onGetHelpButtonClick)}
          >
            {formatMessage(messages.getHelpLabel)}
          </Button>
          <div className="flex gap-2">
            {backButton}
            {nextButton}
            {Boolean(isLastTab) && customSubmitButton}
          </div>
        </footer>
      </Modal>
    );
  }
);

MultiStepperModal.displayName = 'MultiStepperModal';
