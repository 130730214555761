import type { ChallengeDetails } from '@assembly-web/services';
import {
  Arrow as HoverCardArrow,
  Content,
  Root as HoverCardRoot,
  Trigger,
} from '@radix-ui/react-hover-card';
import parse from 'html-react-parser';
import { useEffect, useRef, useState } from 'react';
import { twMerge } from 'tailwind-merge';

import { OverflowText } from '../../../DesignSystem/Feedback/OverflowText';
import { TextStyle } from '../../../DesignSystem/Feedback/TextStyle';
import { Challenge } from '../../assets/images';
import { Portal } from '../Portal';

type CardPreviewProps = {
  challengeDetails: ChallengeDetails;
  containerClassname?: string;
};

export function CardPreview(props: CardPreviewProps) {
  const { challengeDetails, containerClassname } = props;
  const [src, setSrc] = useState(
    challengeDetails.image &&
      (challengeDetails.image.thumbnails['256'] ||
        challengeDetails.image.location)
  );

  const descriptionRef = useRef<HTMLParagraphElement>(null);

  const [descriptionOverflow, setDescriptionOverflow] = useState(false);

  useEffect(() => {
    const onResize = () => {
      const el = descriptionRef.current;
      if (el) {
        setDescriptionOverflow(el.scrollHeight > el.clientHeight);
      }
    };

    window.addEventListener('resize', onResize);
    onResize();

    return () => window.removeEventListener('resize', onResize);
  }, [challengeDetails.description?.length]);

  return (
    <HoverCardRoot openDelay={200} closeDelay={100}>
      <div
        className={twMerge(
          'mt-3 flex w-full items-center gap-4',
          containerClassname
        )}
      >
        <div
          className={twMerge(
            'relative flex h-[89px] w-[115px] shrink-0 items-center justify-center overflow-hidden rounded-lg border border-gray-5'
          )}
        >
          {src ? (
            <img
              src={src}
              onError={() => {
                setSrc(challengeDetails.image?.location);
              }}
              className="object-cover"
              alt="challenge-submission-modal-preview"
            />
          ) : (
            <div
              className={
                'flex h-full w-full items-center justify-center bg-upgrade-3'
              }
            >
              <img
                src={Challenge}
                alt="default-challenge-card-preview"
                className="max-w-[50%] object-cover"
              />
            </div>
          )}
        </div>
        <div className="overflow-hidden break-all">
          <OverflowText variant="base-medium" className="truncate">
            {challengeDetails.title}
          </OverflowText>
          <Trigger>
            {challengeDetails.description ? (
              <TextStyle
                variant="xs-regular"
                className={twMerge(
                  'line-clamp-2 text-ellipsis text-gray-8',
                  !descriptionOverflow &&
                    challengeDetails.description &&
                    'h-[44px]'
                )}
                ref={descriptionRef}
              >
                {parse(challengeDetails.description)}
              </TextStyle>
            ) : null}
          </Trigger>
        </div>
      </div>
      {Boolean(descriptionOverflow) && (
        <Portal className="relative z-50">
          <Content className="flex w-[calc(32px+var(--radix-hover-card-trigger-width))] break-words rounded-lg border-b border-t-0 border-gray-5 bg-gray-1 px-4 py-3 shadow-lg-up">
            <div className="w-full overflow-auto">
              <HoverCardArrow className="fill-gray-1" />
              {challengeDetails.description ? (
                <TextStyle
                  variant="xs-regular"
                  className="break-all text-gray-8"
                >
                  {parse(challengeDetails.description)}
                </TextStyle>
              ) : null}
            </div>
          </Content>
        </Portal>
      )}
    </HoverCardRoot>
  );
}
