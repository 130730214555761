import {
  Children,
  isValidElement,
  type ReactElement,
  type ReactNode,
} from 'react';

import { TextStyle } from '../../../DesignSystem/Feedback/TextStyle';
import { ChoiceBox } from '../../../DesignSystem/Inputs/ChoiceBox';

type Choice = {
  id: string;
  label: string;
  description: string;
};

type ChoicesProps = {
  choices: Choice[];
  defaultValue: string;
  onValueChange: (value: string) => void;
};

function Prompt({ children }: { children: ReactNode }) {
  return (
    <TextStyle variant="base-medium" className="flex items-center text-gray-9">
      {children}
    </TextStyle>
  );
}

function Choices({ choices, onValueChange, defaultValue }: ChoicesProps) {
  return (
    <ChoiceBox.Root defaultValue={defaultValue} onValueChange={onValueChange}>
      {choices.map((choice) => (
        <ChoiceBox.Item key={choice.id} value={choice.id}>
          <ChoiceBox.Title>{choice.label}</ChoiceBox.Title>
          <ChoiceBox.Subtitle>{choice.description}</ChoiceBox.Subtitle>
        </ChoiceBox.Item>
      ))}
    </ChoiceBox.Root>
  );
}

function Root({ children }: { children: ReactNode }) {
  return <div className="flex flex-col gap-6">{children}</div>;
}

function Row({ children }: { children: ReactNode }) {
  let promptElement: ReactElement | null = null;
  let choicesElement: ReactElement | null = null;

  Children.forEach(children, (child) => {
    if (isValidElement(child)) {
      if (child.type === Prompt) {
        promptElement = child;
      } else if (child.type === Choices) {
        choicesElement = child;
      }
    }
  });

  return (
    <section className="flex w-full justify-between">
      {promptElement}
      {choicesElement}
    </section>
  );
}

export const PromptWithChoiceBox = {
  Row,
  Root,
  Prompt,
  Choices,
};
