import { SVGReactComponents } from '@assembly-web/assets';
import type { FileDetails } from '@assembly-web/services';
import { createContext, useState } from 'react';
import { defineMessages, useIntl } from 'react-intl';

import { Tooltip } from '../../../DesignSystem/Feedback/Tooltip';
import { IconButton } from '../../../DesignSystem/Inputs/IconButton';
import { FilePreviewer } from './FilePreviewer';

type ModalProps = {
  file: FileDetails;
  claimId?: string;
  handleGoToPost?: () => void;
};

export const FilePreviewerV3Context = createContext<{
  openModal: (props: ModalProps) => void;
}>({
  openModal: () => {},
});

const messages = defineMessages({
  goToPost: {
    defaultMessage: 'View challenge claim',
    id: 'erUW9r',
  },
});

export const FilePreviewerV3Provider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const { formatMessage } = useIntl();

  const [modalProps, setModalProps] = useState<ModalProps | undefined>();

  const closeModal = () => setModalProps(undefined);

  const handleGoToPost = () => {
    modalProps?.handleGoToPost?.();
    closeModal();
  };

  return (
    <FilePreviewerV3Context.Provider value={{ openModal: setModalProps }}>
      {modalProps?.file ? (
        <FilePreviewer
          file={modalProps.file}
          isOpen
          onClose={closeModal}
          {...(modalProps.claimId && {
            primaryActions: (
              <Tooltip tooltipText={formatMessage(messages.goToPost)}>
                <IconButton
                  variation="tertiaryLite"
                  size="xSmall"
                  onClick={handleGoToPost}
                >
                  <SVGReactComponents.GoToPostIcon />
                </IconButton>
              </Tooltip>
            ),
          })}
        />
      ) : null}
      {children}
    </FilePreviewerV3Context.Provider>
  );
};
