import type { Nullable } from '@assembly-web/services';
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import { $createParagraphNode, $insertNodes } from 'lexical';
import { useCallback, useEffect, useRef } from 'react';

import { resetEditor } from '../utils/resetEditor';

type UpdateEditorPluginProps = {
  editState?: string | null;
  draftState?: string | null;
  createNewDraft?: boolean;
};

export function UpdateEditorPlugin({
  editState,
  draftState,
  createNewDraft = true,
}: UpdateEditorPluginProps) {
  const [editor] = useLexicalComposerContext();
  const runDraftSyncOnce = useRef(false);

  const updateEditorState = useCallback(
    (state: Nullable<string>) => {
      if (!state) {
        resetEditor(editor);
        return;
      }

      const editorState = editor.parseEditorState(state);
      editor.setEditorState(editorState);
      editor.focus();
    },
    [editor]
  );

  useEffect(() => {
    if (editState) {
      updateEditorState(editState);
    }
  }, [editState, updateEditorState]);

  useEffect(() => {
    if (runDraftSyncOnce.current) {
      return;
    }

    if (draftState) {
      updateEditorState(draftState);
    } else {
      if (createNewDraft) {
        editor.update(() => {
          $insertNodes([$createParagraphNode()]);
        });
      }
    }

    runDraftSyncOnce.current = true;
  }, [createNewDraft, draftState, editState, editor, updateEditorState]);

  useEffect(() => {
    const focusHandler = () => {
      runDraftSyncOnce.current = false;
    };

    document.addEventListener('focus', focusHandler);

    return () => {
      document.removeEventListener('focus', focusHandler);
    };
  });

  return null;
}
