import type { ReactNode } from 'react';
import ContentLoader from 'react-content-loader';
import { twMerge } from 'tailwind-merge';

import { useDeviceInfo } from '../../..';
import { TextStyle } from '../../../DesignSystem/Feedback/TextStyle';
import type { ButtonVariations } from '../../../DesignSystem/Inputs/Button';
import { Button } from '../../../DesignSystem/Inputs/Button';

type EmptyStateCardProps = {
  title: string;
  description: string | ReactNode;
  image?: string | ReactNode;
  imageBackgroundClass?: string;
  imageClassName?: string;
  ctaText?: string;
  onCtaClick?: () => void;
  buttonIcon?: ReactNode;
  buttonVariant?: (typeof ButtonVariations)[number];
  containerClassName?: string;
  type: 'feed' | 'containerPending' | 'containerApproved' | 'containerDenied';
  isLoading?: boolean;
};

export const EmptyStateCard = (props: EmptyStateCardProps) => {
  const {
    title,
    description,
    image,
    imageBackgroundClass = '',
    imageClassName,
    ctaText,
    onCtaClick,
    buttonIcon,
    buttonVariant,
    containerClassName,
    type,
    isLoading,
  } = props;

  const isMobileView = useDeviceInfo().deviceType === 'mobile';

  if (isLoading) {
    return <EmptyStateCardLoader containerClassName={containerClassName} />;
  }

  return (
    <div
      className={twMerge(
        'flex w-full flex-col items-center gap-6 self-stretch rounded-md border-2 border-dashed border-gray-5 bg-gray-1 bg-opacity-50 p-6 sm:flex-row',
        containerClassName
      )}
      data-testid={`empty-state-card-container-${type}`}
    >
      {image ? (
        <div>
          {typeof image === 'string' || typeof image == 'undefined' ? (
            <div className={twMerge('rounded-lg', imageBackgroundClass)}>
              <img
                src={image}
                className={twMerge('m-auto', imageClassName)}
                alt="empty state"
              />
            </div>
          ) : (
            image
          )}
        </div>
      ) : null}
      <div className="flex flex-col items-center gap-1 sm:items-start">
        <TextStyle variant="base-medium" data-testid="empty-state-title">
          {title}
        </TextStyle>
        <TextStyle
          variant="sm-regular"
          subdued={true}
          data-testid="empty-state-description"
        >
          {description}
        </TextStyle>
        {Boolean(ctaText && onCtaClick && !isMobileView) && (
          <Button
            title={ctaText}
            variation={buttonVariant ?? 'primary'}
            onClick={onCtaClick}
            size="small"
            data-testid={`empty-state-cta-${type}`}
          >
            {buttonIcon}
            {ctaText}
          </Button>
        )}
      </div>
    </div>
  );
};

export function EmptyStateCardLoader({
  containerClassName,
}: {
  containerClassName?: string;
}) {
  return (
    <div
      className={`flex w-full flex-col items-center gap-6 self-stretch rounded-md border-2 border-dashed border-gray-5 bg-gray-1 bg-opacity-50 p-6 sm:flex-row ${containerClassName}`}
      data-testid="empty-state-card-container"
    >
      <ContentLoader
        speed={2}
        width="100%"
        height={160}
        viewBox="0 0 100% 160"
        backgroundColor="#f3f3f3"
        foregroundColor="#ecebeb"
      >
        {/* Image or placeholder */}
        <rect x="15" y="15" rx="5" ry="5" width="150" height="120" />
        {/* Title */}
        <rect x="180" y="30" rx="4" ry="4" width="65%" height="20" />
        {/* Description */}
        <rect x="180" y="60" rx="3" ry="3" width="100%" height="10" />
        <rect x="180" y="75" rx="3" ry="3" width="100%" height="10" />
        {/* Button */}
        <rect x="180" y="95" rx="5" ry="5" width="25%" height="30" />
      </ContentLoader>
    </div>
  );
}
