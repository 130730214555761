import { getRoutePath } from '@assembly-web/services';
import { useCallback } from 'react';
import type { NavigateFunction, NavigateOptions, To } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';

export function useAssemblyNavigate(): NavigateFunction {
  const navigate = useNavigate();

  const assemblyNavigate = useCallback(
    (toOrDelta: To | number, options?: NavigateOptions) => {
      if (typeof toOrDelta === 'number') {
        navigate(toOrDelta);
      } else {
        const modifiedTo =
          typeof toOrDelta === 'string'
            ? getRoutePath(toOrDelta, {
                directUrl: false,
                takeURLParams: true,
              })
            : {
                ...toOrDelta,
                pathname: getRoutePath(toOrDelta.pathname, {
                  directUrl: false,
                  takeURLParams: true,
                }),
              };
        navigate(modifiedTo, options);
      }
    },
    [navigate]
  );

  return assemblyNavigate;
}
