import { ChevronDownIcon } from '@heroicons/react/24/solid';
import type { BaseEmoji } from 'emoji-mart';

import { ReactionPicker } from '../../Shared/Reactions/ReactionPicker';

export const EmojiPicker = ({
  disabled,
  emoji,
  onChange,
  showDisabledTooltip,
}: {
  disabled?: boolean;
  emoji: BaseEmoji;
  onChange: (emoji: BaseEmoji) => void;
  showDisabledTooltip?: boolean;
}) => {
  return (
    <ReactionPicker
      disabled={disabled}
      onReactionClick={onChange}
      showDisabledTooltip={showDisabledTooltip}
      triggerButton={
        <span className="flex items-center gap-2 rounded-lg bg-gray-4 px-3 py-2.5">
          <span
            {...(emoji.name.length > 0 && { 'aria-label': emoji.name })}
            className="flex h-4 w-4 items-center"
          >
            {emoji.native}
          </span>
          <ChevronDownIcon className="h-4 w-4 stroke-current text-gray-9" />
        </span>
      }
    />
  );
};
