import './styles.css';

import type {
  AssemblyCurrency,
  GifRatingsProps,
  MemberAPIResponse,
  Nullable,
  ReplyData,
} from '@assembly-web/services';
import { AutoLinkNode, LinkNode } from '@lexical/link';
import { ListItemNode, ListNode } from '@lexical/list';
import { ORDERED_LIST, QUOTE, UNORDERED_LIST } from '@lexical/markdown';
import { AutoLinkPlugin } from '@lexical/react/LexicalAutoLinkPlugin';
import {
  type InitialConfigType,
  LexicalComposer,
} from '@lexical/react/LexicalComposer';
import { ContentEditable } from '@lexical/react/LexicalContentEditable';
import LexicalErrorBoundary from '@lexical/react/LexicalErrorBoundary';
import { HistoryPlugin } from '@lexical/react/LexicalHistoryPlugin';
import { LinkPlugin } from '@lexical/react/LexicalLinkPlugin';
import { ListPlugin } from '@lexical/react/LexicalListPlugin';
import { MarkdownShortcutPlugin } from '@lexical/react/LexicalMarkdownShortcutPlugin';
import { RichTextPlugin } from '@lexical/react/LexicalRichTextPlugin';
import { TabIndentationPlugin } from '@lexical/react/LexicalTabIndentationPlugin';
import { HeadingNode, QuoteNode } from '@lexical/rich-text';
import { motion } from 'framer-motion';
import type { LexicalEditor } from 'lexical';
import { useState } from 'react';
import { defineMessages, useIntl } from 'react-intl';
import { twMerge } from 'tailwind-merge';

import { TextStyle } from '../../../../DesignSystem/Feedback/TextStyle';
import type { DropdownOption } from '../../../../DesignSystem/Inputs/DropdownMenu';
import type { ToastKind } from '../../../Shared/Toast/Toast';
import { ImageNode } from '../base/nodes/ImageNode';
import { MentionNode } from '../base/nodes/MentionNode';
import {
  AutoSavePlugin,
  type EditorStateChangeArgs,
} from '../base/plugins/AutoSavePlugin';
import { FormatPlugin } from '../base/plugins/FormatPlugin';
import { FormattingFloatingMenuPlugin } from '../base/plugins/FormattingFloatingMenuPlugin';
import { ImagesPlugin } from '../base/plugins/ImagePlugin';
import { LinkFloatingToolbarPlugin } from '../base/plugins/LinkFloatingToolbarPlugin';
import {
  type MentionedUser,
  MentionsPlugin,
} from '../base/plugins/MentionsPlugin';
import { UpdateEditorPlugin } from '../base/plugins/UpdateEditorPlugin';
import { EditorTheme } from '../base/theme';
import { MATCHERS, validateUrl } from '../base/utils/regexUtils';
import { AnonymousHeaderPlugin } from './plugins/AnonymousHeaderPlugin';
import { HandleEnterKeyPressPlugin } from './plugins/HandleEnterKeyPressPlugin';
import { ListMaxIndentLevelPlugin } from './plugins/ListMaxIndentLevelPlugin';
import {
  type EditorData,
  RepliesFooterPlugin,
  type RepliesValidatorError,
} from './plugins/RepliesFooterPlugin';
import { RepliesHeaderPlugin } from './plugins/RepliesHeaderPlugin';

const config = {
  nodes: [
    LinkNode,
    ListNode,
    ImageNode,
    QuoteNode,
    MentionNode,
    HeadingNode,
    AutoLinkNode,
    ListItemNode,
  ],
  theme: EditorTheme,
  namespace: 'replies-editor',
  onError: () => {
    // noop, will be overridden by the component props
  },
} satisfies InitialConfigType;

export type BoostOptions = {
  hideBoost: boolean;
  disableBoost: boolean;
  disableBoostReason?: string;
  maxGivingPointsPerPost: number;
  currentUser?: MemberAPIResponse;
};

export type RepliesEditorProps = {
  disabled?: boolean;
  hasError?: boolean;
  giphyAPIKey: string;
  placeholder?: string;
  isAnonymousPost: boolean;
  editState?: string | null;
  draftState?: string | null;
  onCancelClick: () => void;
  gifRating: GifRatingsProps;
  currency: AssemblyCurrency;
  disablePostButton?: boolean;
  hideToolbarButtons: boolean;
  postButtonToolTipText?: string;
  mentionedUsers: MentionedUser[];
  selectedReply: Nullable<ReplyData>;
  isFormattingToolbarVisible: boolean;
  isFetchingNextPageForMentions: boolean;
  onReplyClick: (data: EditorData) => void;
  onViewProfileClick: (memberId: string) => void;
  currentUserDetails: {
    id: string;
    name: string;
    image?: string;
    allowance: number;
    currency: AssemblyCurrency;
  };
  mentionedUsersInPost: MentionedUser[] | null;
  onChange: (args: EditorStateChangeArgs) => void;
  onNextPageScrolledInMentionsDropdown: () => void;
  onMentionsMenuOpenChange: (open: boolean) => void;
  setIsFormattingToolbarVisible: (show: boolean) => void;
  onError?: (error: Error, editor: LexicalEditor) => void;
  onMentionsSearchQueryChange?: (query: Nullable<string>) => void;
  replyType: 'anonymous' | 'user';
  onReplyTypeChange?: (option: DropdownOption) => void;
  disableAnonymousReplyOption?: boolean;
  validator: (text: string) => RepliesValidatorError[];
  boostOptions: BoostOptions;
  showToast?: (kind: ToastKind, message: string) => void;
};

const messages = defineMessages({
  repliesEditorTitle: {
    defaultMessage: 'Replies Editor',
    id: 'LV/W90',
  },
});

export function RepliesEditor({
  onError,
  onChange,
  disabled,
  hasError,
  currency,
  editState,
  gifRating,
  replyType,
  showToast,
  validator,
  draftState,
  placeholder,
  giphyAPIKey,
  onReplyClick,
  boostOptions,
  selectedReply,
  onCancelClick,
  mentionedUsers,
  isAnonymousPost,
  disablePostButton,
  onReplyTypeChange,
  hideToolbarButtons,
  onViewProfileClick,
  currentUserDetails,
  mentionedUsersInPost,
  postButtonToolTipText,
  onMentionsMenuOpenChange,
  isFormattingToolbarVisible,
  onMentionsSearchQueryChange,
  disableAnonymousReplyOption,
  isFetchingNextPageForMentions,
  setIsFormattingToolbarVisible,
  onNextPageScrolledInMentionsDropdown,
}: RepliesEditorProps) {
  const { formatMessage } = useIntl();

  const [isFocused, setIsFocused] = useState(false);

  return (
    <div
      className={twMerge(
        'relative flex flex-1 flex-col overflow-hidden rounded-md border border-gray-6',
        hasError && 'flex-shrink-0 border-error-6 ring-1 ring-error-6',
        isFocused && !hasError && 'border-primary-5 ring-1 ring-primary-5'
      )}
    >
      <LexicalComposer
        initialConfig={{
          ...config,
          onError: (error, editor) => onError?.(error, editor),
        }}
      >
        <MarkdownShortcutPlugin
          transformers={[ORDERED_LIST, UNORDERED_LIST, QUOTE]}
        />
        <AnonymousHeaderPlugin
          replyType={replyType}
          isVisible={isAnonymousPost}
          onReplyTypeChange={onReplyTypeChange}
          currentUserDetails={currentUserDetails}
          disableAnonymousReplyOption={disableAnonymousReplyOption}
        />
        <RepliesHeaderPlugin isVisible={isFormattingToolbarVisible} />
        <RichTextPlugin
          ErrorBoundary={LexicalErrorBoundary}
          placeholder={
            <motion.div
              className={twMerge(
                'pointer-events-none absolute left-2 top-2',
                (isFormattingToolbarVisible || isAnonymousPost) && 'top-[48px]',
                isFormattingToolbarVisible && isAnonymousPost && 'top-[88px]'
              )}
              layout="position"
              transition={{ layout: { duration: 0.2 } }}
            >
              <TextStyle
                as="span"
                variant="base-regular"
                className={disabled ? 'text-gray-7' : 'text-gray-8'}
                disabled={disabled}
              >
                {placeholder}
              </TextStyle>
            </motion.div>
          }
          contentEditable={
            <div
              className={twMerge(
                'flex-1 overflow-hidden rounded-md border-0 outline-[0] focus:outline-none focus:ring-0 focus:ring-transparent focus:ring-offset-0 focus-visible:outline-none focus-visible:ring-0 focus-visible:ring-transparent focus-visible:ring-offset-0',
                disabled && 'cursor-not-allowed bg-gray-2'
              )}
            >
              <ContentEditable
                disabled={disabled}
                onBlur={() => {
                  setIsFocused(false);
                }}
                onFocus={() => {
                  setIsFocused(true);
                }}
                className="z-0 flex h-full min-h-[36px] flex-col gap-1 overflow-y-auto p-2 text-base text-gray-9 focus:outline-none focus:ring-0 focus:ring-transparent focus:ring-offset-0 focus-visible:outline-none focus-visible:ring-0 focus-visible:ring-transparent focus-visible:ring-offset-0"
                ariaLabel={formatMessage(messages.repliesEditorTitle)}
              />
            </div>
          }
        />
        <RepliesFooterPlugin
          currency={currency}
          disabled={disabled}
          gifRating={gifRating}
          validator={validator}
          giphyAPIKey={giphyAPIKey}
          onReplyClick={onReplyClick}
          boostOptions={boostOptions}
          onCancelClick={onCancelClick}
          disablePostButton={disablePostButton}
          hideToolbarButtons={hideToolbarButtons}
          isEditing={Boolean(editState) && !disabled}
          isAnonymousReply={replyType === 'anonymous'}
          postButtonTooltipText={postButtonToolTipText}
          isFormattingToolbarVisible={isFormattingToolbarVisible}
          setIsFormattingToolbarVisible={setIsFormattingToolbarVisible}
        />
        <MentionsPlugin
          currency={currency}
          members={mentionedUsers}
          boostOptions={boostOptions}
          selectedReply={selectedReply}
          onViewProfileClick={onViewProfileClick}
          isEditing={Boolean(editState) && !disabled}
          onMenuOpenChange={onMentionsMenuOpenChange}
          mentionedUsersInPost={mentionedUsersInPost}
          isAnonymousReply={replyType === 'anonymous'}
          onSearchQueryChange={onMentionsSearchQueryChange}
          isFetchingNextPage={isFetchingNextPageForMentions}
          onNextPageScrolled={onNextPageScrolledInMentionsDropdown}
        />
        <AutoSavePlugin onChange={onChange} validator={validator} />
        <HandleEnterKeyPressPlugin
          validator={validator}
          onPressEnter={onReplyClick}
          disabled={disablePostButton}
        />
        <ImagesPlugin />
        <ListPlugin />
        <LinkPlugin validateUrl={validateUrl} />
        <AutoLinkPlugin matchers={MATCHERS} />
        <UpdateEditorPlugin draftState={draftState} editState={editState} />
        <FormattingFloatingMenuPlugin
          isFormattingToolbarVisible={isFormattingToolbarVisible}
        />
        <FormatPlugin />
        <TabIndentationPlugin />
        <ListMaxIndentLevelPlugin maxDepth={5} />
        <LinkFloatingToolbarPlugin showToast={showToast} />
        <HistoryPlugin />
      </LexicalComposer>
    </div>
  );
}
