import type { MemberState } from '@assembly-web/services';
import { isValidEmail, PermissionType, useRect } from '@assembly-web/services';
import { EyeIcon } from '@heroicons/react/24/outline';
import { type ReactNode, useMemo, useRef } from 'react';
import { defineMessages, type MessageDescriptor, useIntl } from 'react-intl';
import { twMerge } from 'tailwind-merge';

import { TextStyle } from '../../../DesignSystem/Feedback/TextStyle';
import { Button } from '../../../DesignSystem/Inputs/Button';
import { useDeviceInfo } from '../../hooks/useDeviceInfo';
import type {
  CriteriaItemProps as Criteria,
  CriteriaItemProps,
} from './CriteriaItem';
import { CriteriaItem, CriteriaItemLoader } from './CriteriaItem';
import { NonMemberRuleSelector } from './NonMemberRuleSelector';
import type { Option } from './SearchableDropdownWithChips';
import {
  type CustomRule,
  type Rule,
  SearchableRulesDropdown,
} from './SearchableRulesDropdown';
import type { CustomRuleWithType } from './ShareModal';

const messages = defineMessages({
  addPeopleOrRulesPlaceholder: {
    defaultMessage: 'Add people or rules placeholder',
    id: '/d1RNY',
  },
  previewList: {
    defaultMessage: 'Preview list',
    id: 'y9y6sj',
  },
});

type HeaderProps = {
  className?: string;
  subTitle?: ReactNode;
  title: ReactNode;
  onPreviewButtonClick: (view: 'peopleWithAccess' | 'previewMembers') => void;
  hidePreviewButton?: boolean;
  disablePreviewButton?: boolean;
};

type CustomRuleSelectorProps = {
  includeManagerOption?: boolean;
  customRuleOptions: Option[];
  selectedCustomRule: CustomRuleWithType | null;
  ruleOptions: Option[];
  onCustomRulesAction: (args: {
    selectedRules?: Option[];
    ruleType: CustomRule;
    condition: string;
  }) => void;
  onCustomRuleSelect: (data: CustomRuleWithType | null) => void;
};

type RulesListProps = {
  rules: CriteriaItemProps[];
  hasNextPageForRules: boolean;
  ownerDetails?: {
    memberState: MemberState;
    memberID: string;
  };
  onRulesPermissionChange: (
    rule: CriteriaItemProps,
    updatedPermission: CriteriaItemProps['permission'],
    ownerDetails?: {
      memberState: MemberState;
      memberID: string;
    }
  ) => void;
};

type SearchableRulesDropdownProps = {
  members: Rule<CriteriaItemProps>[];
  isLoadingMembers?: boolean;
  isFetchingMoreMembers?: boolean;
  onRulesSelectionChange: (rule: CriteriaItemProps) => void;
  customRules: CustomRuleWithType[];
  canInviteEmail: boolean;
  searchTerm: string;
  onMemberSearch: (searchTerm: string) => void;
  hasNextPageForMembers: boolean;
  onLoadMore: () => void;
  handleOnEmailInvite: (email: string) => void;
  hasUserInvitePermission: boolean;
  addEmailLabel?: MessageDescriptor;
  placeholder?: MessageDescriptor;
};

export type PeopleWithAccessProps = {
  view: 'peopleWithAccess';
  customRuleSelectorProps: CustomRuleSelectorProps;
  rulesListProps: RulesListProps;
  searchableRulesDropdownProps: SearchableRulesDropdownProps;
  isLoading?: boolean;
  peopleWithAccessHeaderProps: HeaderProps;
  disabled?: boolean;
  tooltipText?: string;
  className?: string;
  fullHeight?: boolean;
};

function PeopleWithAccessHeader(props: HeaderProps & { isLoading?: boolean }) {
  const { formatMessage } = useIntl();

  const {
    onPreviewButtonClick,
    title,
    className,
    subTitle,
    isLoading,
    hidePreviewButton,
    disablePreviewButton,
  } = props;
  const device = useDeviceInfo().deviceType;

  return (
    <div className="flex flex-col gap-2">
      <div
        className={twMerge(
          'inline-flex h-fit max-h-12 w-full items-end justify-between gap-11 bg-gray-1 pt-4 max-sm:items-center',
          className
        )}
      >
        <TextStyle
          variant={device === 'mobile' ? 'base-medium' : 'lg-medium'}
          className="flex-shrink truncate"
        >
          {title}
        </TextStyle>
        {hidePreviewButton ? null : (
          <Button
            onClick={() => {
              onPreviewButtonClick('previewMembers');
            }}
            variation="tertiaryEmphasized"
            size="small"
            disabled={disablePreviewButton || isLoading}
            className="flex-shrink-0"
          >
            <EyeIcon className="h-4 w-4" />
            {formatMessage(messages.previewList)}
          </Button>
        )}
      </div>
      {Boolean(subTitle) && (
        <div className="flex items-center gap-2 rounded-md bg-primary-2 p-2 text-primary-8">
          {subTitle}
        </div>
      )}
    </div>
  );
}

export function PeopleWithAccess(props: Omit<PeopleWithAccessProps, 'view'>) {
  const {
    customRuleSelectorProps,
    rulesListProps,
    isLoading,
    searchableRulesDropdownProps,
    peopleWithAccessHeaderProps,
    disabled,
    tooltipText,
    className,
    fullHeight,
  } = props;

  const containerRef = useRef<HTMLDivElement | null>(null);
  const rect = useRect({ ref: containerRef, enabled: fullHeight });

  const device = useDeviceInfo().deviceType;

  const itemCount = useMemo(
    () =>
      rulesListProps.hasNextPageForRules && rulesListProps.rules.length
        ? rulesListProps.rules.length + 1
        : rulesListProps.rules.length,
    [rulesListProps]
  );

  const containerHeight = useMemo(() => {
    if (rect && containerRef.current) {
      const childNodes = Array.from(containerRef.current.childNodes);
      const listContainerIndex = childNodes.findIndex(
        (node) => (node as HTMLElement).dataset.listContainer === 'true'
      );
      const listContainerHeight =
        rect.height -
        childNodes
          .slice(0, listContainerIndex - 1)
          .reduce((acc, node) => acc + (node as HTMLElement).offsetHeight, 0);
      return `${listContainerHeight}px`;
    }

    return device === 'mobile'
      ? `${itemCount < 3 ? 60 * itemCount - 1 : 180}px`
      : `${itemCount < 5 ? 60 * itemCount - 1 : 280}px`;
  }, [device, itemCount, rect]);

  const customRulesInSearchableRulesDropdown = useMemo(
    () =>
      searchableRulesDropdownProps.customRules.map(
        (customRule) => customRule.rule
      ),
    [searchableRulesDropdownProps.customRules]
  );

  return (
    <div
      className={twMerge('flex flex-col gap-2 px-2 pb-4', className)}
      ref={containerRef}
    >
      <PeopleWithAccessHeader
        isLoading={isLoading}
        {...peopleWithAccessHeaderProps}
      />
      <div className="flex w-full items-center">
        {customRuleSelectorProps.selectedCustomRule ? (
          <NonMemberRuleSelector
            disabled={disabled}
            tooltipText={tooltipText}
            key={customRuleSelectorProps.customRuleOptions
              .map((option) => option.id)
              .join('')}
            includeManagerOption={customRuleSelectorProps.includeManagerOption}
            ruleType={customRuleSelectorProps.selectedCustomRule.rule}
            ruleOptions={customRuleSelectorProps.customRuleOptions}
            onRuleConfirm={({ selectedRules, ruleType, condition }) => {
              customRuleSelectorProps.onCustomRulesAction({
                selectedRules,
                ruleType,
                condition,
              });
              customRuleSelectorProps.onCustomRuleSelect(null);
            }}
            onRuleDelete={() => {
              customRuleSelectorProps.onCustomRuleSelect(null);
            }}
            displayType={customRuleSelectorProps.selectedCustomRule.type}
          />
        ) : (
          <SearchableRulesDropdown<Criteria>
            disabled={disabled}
            tooltipText={tooltipText}
            rules={searchableRulesDropdownProps.members}
            renderRule={(option: Rule<Criteria>) => {
              return <CriteriaItem {...option.data} />;
            }}
            renderRulesLoader={() => (
              <section className="mt-2 px-2">
                <CriteriaItemLoader showDropdownLoader={false} />
              </section>
            )}
            isLoading={searchableRulesDropdownProps.isLoadingMembers}
            isFetchingMoreMembers={
              searchableRulesDropdownProps.isFetchingMoreMembers
            }
            onRuleSelect={(option: Rule<Criteria>) => {
              searchableRulesDropdownProps.onRulesSelectionChange(option.data);
            }}
            customRules={customRulesInSearchableRulesDropdown}
            onCustomRuleSelect={(rule: CustomRule) => {
              const type = searchableRulesDropdownProps.customRules.find(
                (customRule) => customRule.rule.id === rule.id
              )?.type;
              if (type) {
                customRuleSelectorProps.onCustomRuleSelect({ rule, type });
              }
            }}
            hasInvitePermission={
              searchableRulesDropdownProps.hasUserInvitePermission
            }
            checkIfCurrentEmailCanBeInvited={async (email: string) =>
              isValidEmail(email) || searchableRulesDropdownProps.canInviteEmail
            }
            searchTerm={searchableRulesDropdownProps.searchTerm}
            onMemberSearch={searchableRulesDropdownProps.onMemberSearch}
            hasMoreMembersToFetch={
              searchableRulesDropdownProps.hasNextPageForMembers
            }
            fetchMoreMembers={searchableRulesDropdownProps.onLoadMore}
            handleOnEmailInvite={
              searchableRulesDropdownProps.handleOnEmailInvite
            }
            hasUserInvitePermission={
              searchableRulesDropdownProps.hasUserInvitePermission
            }
            addEmailLabel={searchableRulesDropdownProps.addEmailLabel}
            placeholder={searchableRulesDropdownProps.placeholder}
          />
        )}
      </div>
      {isLoading ? (
        <section>
          <CriteriaItemLoader />
          <CriteriaItemLoader />
        </section>
      ) : (
        <section
          className="isolate flex min-h-[180px] flex-col gap-2 overflow-y-scroll"
          data-list-container
          style={{
            height: containerHeight,
          }}
        >
          {rulesListProps.rules.map((item) => (
            <CriteriaItem
              key={item.id}
              {...item}
              ownerDetails={rulesListProps.ownerDetails}
              onPermissionChange={(value) => {
                rulesListProps.onRulesPermissionChange(
                  item,
                  value,
                  rulesListProps.ownerDetails
                );
              }}
              isDisabled={
                disabled ?? item.permission?.id === PermissionType.Excluded
              }
              disabledTooltipText={tooltipText}
            />
          ))}
        </section>
      )}
    </div>
  );
}
