import type { BaseEmoji } from 'emoji-mart';

import { mapHexCodeToEmoticon } from './flowUtils';

export const getBaseEmojiFromIcon = (icon: string) => {
  return {
    id: icon,
    skin: 1,
    name: '',
    colons: '',
    unified: icon,
    emoticons: [],
    native: mapHexCodeToEmoticon(icon),
  } as BaseEmoji;
};
