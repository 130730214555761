import type { Primitive } from 'type-fest';

/**
 * Sometimes we need to derive an enum value from a string value.
 * This function will return the enum value if it exists, otherwise it will return undefined.
 */
export function deriveEnumForValue<T extends Record<string, Primitive>>(
  value: string | null | undefined,
  enumObject: T
): T[keyof T] | undefined {
  if (!value) {
    return undefined;
  }

  const enumValues = Object.values(enumObject);

  if (enumValues.includes(value)) {
    return value as T[keyof T];
  }

  return undefined;
}
