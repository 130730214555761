import {
  InformationCircleIcon,
  MegaphoneIcon,
} from '@heroicons/react/24/outline';
import { type ReactElement, useState } from 'react';
import { twMerge } from 'tailwind-merge';

import { TextStyle } from '../../../DesignSystem/Feedback/TextStyle';
import { Tooltip } from '../../../DesignSystem/Feedback/Tooltip';
import { useDeviceInfo } from '../../hooks/useDeviceInfo';
import { useTouchDevice } from '../../hooks/useTouchDevice';
import { getAnnouncementGradients } from '../../Web/Modals/AnnouncementCenterModal/gradientColors';
import { Popover } from '../Popover';

export type AnnouncementBannerProps = {
  title: string;
  color: string;
  className?: string;
  description?: string;
  onAnnouncementDescriptionHover?: (args: AnnouncementBannerProps) => void;
};

function InfoWrapper({
  children,
  description,
}: Required<Pick<AnnouncementBannerProps, 'description'>> & {
  children: ReactElement;
}) {
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);
  const touchDevice = useTouchDevice();
  const device = useDeviceInfo().deviceType;

  const isTouchDevice = touchDevice || device === 'mobile';

  if (isTouchDevice) {
    return (
      <Popover
        open={isPopoverOpen}
        onOpenChange={setIsPopoverOpen}
        onTriggerButtonClick={() => {
          setIsPopoverOpen((prev) => !prev);
        }}
        trigger={children}
        modal={false}
        align="end"
        side="bottom"
        contentClassName="rounded-md bg-gray-9 px-4 py-2 text-xs text-gray-1 shadow-2xl-down"
      >
        {description}
      </Popover>
    );
  }

  return (
    <Tooltip
      tooltipText={description}
      textClassName="break-all"
      side="bottom"
      contentClassName="z-10"
    >
      {children}
    </Tooltip>
  );
}

export function AnnouncementBanner({
  color,
  title,
  className,
  description,
  onAnnouncementDescriptionHover,
}: AnnouncementBannerProps) {
  const gradients = getAnnouncementGradients();

  return (
    <div className={twMerge('flex h-10 w-full')}>
      {Boolean(color) && (
        <div
          className={twMerge(
            'flex h-full w-full items-center rounded-tl-lg rounded-tr-lg px-4',
            gradients.find((x) => x.name === color)?.className,
            className
          )}
        >
          <MegaphoneIcon
            strokeWidth={1.5}
            strokeLinecap="round"
            strokeLinejoin="round"
            className="mr-2 h-5 w-5"
          />
          <TextStyle
            variant="base-medium"
            className="line-clamp-1 flex-1 break-words text-gray-9"
          >
            {title}
          </TextStyle>

          {description ? (
            <InfoWrapper description={description}>
              <InformationCircleIcon
                strokeWidth={1.5}
                strokeLinecap="round"
                strokeLinejoin="round"
                className="ml-2 h-5 w-5"
                onMouseEnter={() =>
                  onAnnouncementDescriptionHover?.({ color, title })
                }
              />
            </InfoWrapper>
          ) : null}
        </div>
      )}
    </div>
  );
}
