import type { PermissionType } from './criteriaTypes';
import type { MemberRole, MemberState } from './flowTypes';

export enum ShareSheetRuleAccessTypes {
  Simple = 'SIMPLE',
  Advance = 'ADVANCE',
}

export enum CriteriaRuleType {
  Department = 'department',
  JobTitle = 'jobTitle',
  HomeLocation = 'homeLocation',
  WorkLocation = 'workLocation',
  ManagerStatus = 'manager',
  Everyone = 'everyone',
  Member = 'member',
  Email = 'email',
}

export enum CustomRuleType {
  Department = 'department',
  JobTitle = 'jobTitle',
  HomeLocation = 'homeLocation',
  WorkLocation = 'workLocation',
  ManagerStatus = 'manager',
  Everyone = 'everyone',
}

export enum Operator {
  Is = 'is',
  IsNot = 'isNot',
  IsManager = 'isManager',
}

export type MemberCriteriaAPIResponse = {
  meta: {
    email: string;
    image: string;
    memberId: string;
    name: string;
    state: MemberState;
    role?: MemberRole[];
  };
  operator: Operator;
  perm:
    | PermissionType.Participant
    | PermissionType.Approver
    | PermissionType.ParticipantApprover
    | PermissionType.Owner
    | PermissionType.Collaborator
    | PermissionType.Custom;
  value: string;
};

export type NonMemberCriteriaAPIResponse = {
  operator: Operator;
  perm:
    | PermissionType.Participant
    | PermissionType.Approver
    | PermissionType.ParticipantApprover
    | PermissionType.Owner
    | PermissionType.Collaborator
    | PermissionType.Custom;
  value: string;
};

export type ManagerCriteriaAPIResponse = {
  operator: Operator;
  perm: PermissionType.Approver;
  value: boolean;
};

export type ShareCriteria = {
  include: (
    | {
        field: CriteriaRuleType.Member | CriteriaRuleType.Email;
        values: MemberCriteriaAPIResponse[];
      }
    | {
        field: CriteriaRuleType.Everyone;
        value: boolean;
        perm: string;
        operator: string;
      }
    | {
        field:
          | CriteriaRuleType.Department
          | CriteriaRuleType.JobTitle
          | CriteriaRuleType.WorkLocation
          | CriteriaRuleType.HomeLocation;
        values: NonMemberCriteriaAPIResponse[];
      }
    | {
        field: CriteriaRuleType.ManagerStatus;
        values: ManagerCriteriaAPIResponse[];
      }
  )[];
  exclude: (
    | {
        field: CriteriaRuleType.Member | CriteriaRuleType.Email;
        values: Omit<MemberCriteriaAPIResponse, 'perm'>[];
      }
    | {
        field:
          | CriteriaRuleType.Department
          | CriteriaRuleType.JobTitle
          | CriteriaRuleType.WorkLocation
          | CriteriaRuleType.HomeLocation;
        values: Omit<NonMemberCriteriaAPIResponse, 'perm'>[];
      }
    | {
        field: CriteriaRuleType.ManagerStatus;
        values: Omit<ManagerCriteriaAPIResponse, 'perm'>[];
      }
  )[];
};
