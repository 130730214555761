import type { RewardType } from '@assembly-web/services';
import parse from 'html-react-parser';
import { lazy, Suspense, useEffect, useState } from 'react';
import { defineMessages, useIntl } from 'react-intl';

import { Banner } from '../../../DesignSystem/Feedback/Banner';
import { LoadingSpinner } from '../../../DesignSystem/Feedback/Icons/LoadingSpinner';
import { Modal } from '../../../DesignSystem/Feedback/Modal';
import { TextStyle } from '../../../DesignSystem/Feedback/TextStyle';
import { Button } from '../../../DesignSystem/Inputs/Button';
import { HorizontalRule } from '../../../DesignSystem/Layout/HorizontalRule';
import { SearchableSelect } from '../../Shared/SearchableSelect';
import {
  G2ReviewModal,
  type G2ReviewModalProps,
} from '../Modals/G2ReviewModal/G2ReviewModal';
import { RedemptionDetailsModalSkeletonLoader } from './RedemptionDetailsModalSkeletonLoader';

const RedemptionSuccessful = lazy(() =>
  import('./RedemptionSuccessfulModal').then((module) => ({
    default: module.RedemptionSuccessful,
  }))
);

const RewardRedemptionModalError = lazy(() =>
  import('./RewardRedemptionErrorModal').then((module) => ({
    default: module.RewardRedemptionModalError,
  }))
);

const messages = defineMessages({
  redeemReward: {
    defaultMessage: 'Redeem reward',
    id: 'Yo4bLe',
  },
  selectGiftCardValue: {
    defaultMessage: 'Select gift card value',
    id: '0KEoIN',
  },
  redemptionPolicy: {
    defaultMessage: '{rewardTypeTitle} policy',
    id: 'ZLALGa',
  },
  redeem: {
    defaultMessage: 'Redeem',
    id: 'XSdWHA',
  },
  redeemAnyway: {
    defaultMessage: 'I understand, redeem anyway',
    id: 'MFNSpP',
  },
  close: {
    defaultMessage: 'Close',
    id: 'rbrahO',
  },

  giftCard: {
    defaultMessage: 'Gift card',
    id: '4X0ZI8',
  },

  redemptionInformation: {
    defaultMessage:
      'Once you click ‘Redeem’, your reward will be sent to {email}.',
    id: 'kniKwh',
  },
  redemptionRefundWarning: {
    defaultMessage:
      'IMPORTANT: After clicking ‘Redeem’, Assembly cannot issue a refund for this reward.',
    id: 'aF+zN4',
  },
  insufficientBalance: {
    defaultMessage: 'Insufficient balance to redeem.',
    id: '45Qnq3',
  },

  loadingDenominations: {
    defaultMessage: 'Loading denominations...',
    id: 'mhyCWr',
  },
  cashEquivalentInfoBannerBeforeRedeem: {
    defaultMessage:
      'Even if a store accepts USD, they may not accept {rewardName}. Please check with the place you intend to use this card before redeeming it.',
    id: 'N4Mtms',
  },
});

export type RedemptionInProgressProps = {
  options: {
    id: string;
    value: string;
    displayValue: string;
    isDisabled: boolean;
    carrotsValue: string;
  }[];
  rewardCardImage: string;
  rewardName: string;
  rewardTypeTitle: string | null;
  rewardCardContent: string;
  redemptionWarningBannerText?: string;
  onRedeemRewardClick: (data: {
    faceValue: number;
    id: string;
    carrotsValue: string;
  }) => void;
  isValidInCurrentCountry: boolean;
  email: string;
  isLoadingDenominations: boolean;
  isCashOutRewardInProgress: boolean;
  rewardType: RewardType;
  showRedemptionCashEquivalentInfoBanner?: boolean;
  setTitle?: (title: string) => void;
};

export type RedemptionSuccessfulRewardDataType = {
  redemptionInstructions: string | undefined;
  redemptionSteps?: [{ type: string; code: string; label: string }];
  type?: string;
  code?: string;
  label?: string;
};

export type RedemptionSuccessfulProps = {
  rewardCardImage?: string;
  rewardName: string;
  rewardCardRedemptionData?: RedemptionSuccessfulRewardDataType;
  rewardDenomination: string;
  email: string;
  donationAmount?: string;
  rewardType: RewardType;
  showRedeemedCashEquivalentInfoBanner?: boolean;
  setTitle?: (title: string) => void;
};

export type RewardRedemptionErrorProps = {
  errorText?: string;
  errorDescription?: string;
  rewardType?: string;
  setTitle?: (title: string) => void;
};

export enum RedemptionModalState {
  Redeeming = 'redeeming',
  Redeemed = 'redeemed',
  Error = 'error',
}

type G2ReviewProps = {
  showG2Review: boolean;
} & G2ReviewModalProps;

export type RedemptionModalProps = {
  isOpen: boolean;
  onClose: () => void;
} & (
  | ({
      state: RedemptionModalState.Redeeming;
      rewardType:
        | RewardType.GiftCards
        | RewardType.Charities
        | RewardType.CashEquivalent;
    } & RedemptionInProgressProps)
  | ({
      state: RedemptionModalState.Redeemed;
      rewardType:
        | RewardType.GiftCards
        | RewardType.Charities
        | RewardType.CashEquivalent;
      g2Review: G2ReviewProps;
    } & RedemptionSuccessfulProps)
  | ({
      state: RedemptionModalState.Error;
    } & RewardRedemptionErrorProps)
);

// TODO: Try and refactor all redemption modals to use slot based approach
export function RedemptionModal({
  isOpen,
  onClose,
  ...props
}: RedemptionModalProps) {
  const [title, setTitle] = useState<string | null>(null);

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      title={title}
      className="flex flex-col"
      headerClassName="pb-4 border-b border-gray-4 z-50"
      bodyClassName="p-0 flex flex-col flex-1"
    >
      {props.state === RedemptionModalState.Redeeming && (
        <RedemptionInProgress {...props} setTitle={setTitle} />
      )}
      {props.state === RedemptionModalState.Redeemed && (
        <Suspense fallback={<RedemptionDetailsModalSkeletonLoader />}>
          <RedemptionSuccessful
            {...props}
            setTitle={setTitle}
            handleRedemptionModalClose={onClose}
          />
          {Boolean(props.g2Review.showG2Review) && (
            <G2ReviewModal {...props.g2Review} />
          )}
        </Suspense>
      )}
      {props.state === RedemptionModalState.Error && (
        <Suspense fallback={<RedemptionDetailsModalSkeletonLoader />}>
          <RewardRedemptionModalError setTitle={setTitle} {...props} />
        </Suspense>
      )}
    </Modal>
  );
}

function RedemptionInProgress({
  options,
  rewardCardImage,
  rewardName,
  rewardCardContent,
  redemptionWarningBannerText,
  onRedeemRewardClick,
  isValidInCurrentCountry,
  email,
  isLoadingDenominations,
  isCashOutRewardInProgress,
  rewardTypeTitle,
  showRedemptionCashEquivalentInfoBanner,
  setTitle,
}: RedemptionInProgressProps) {
  const { formatMessage } = useIntl();

  const [selectedOption, setSelectedOption] = useState<
    | { id: string; value: string; displayValue: string; carrotsValue: string }
    | undefined
  >(undefined);

  useEffect(() => {
    setTitle?.(formatMessage(messages.redeemReward));
  }, [formatMessage, setTitle]);

  return (
    <div
      className="flex flex-1 flex-col"
      data-testid="redemption-in-progress-modal"
    >
      <div className="flex-1 px-6">
        <div className="flex w-full flex-col items-center gap-2 p-4">
          <div className="flex w-full justify-center rounded-md">
            <img alt="" className="h-[125px] w-fit" src={rewardCardImage} />
          </div>
          <TextStyle variant="base-medium" className="">
            {rewardName}
          </TextStyle>
        </div>
        {Boolean(showRedemptionCashEquivalentInfoBanner) && (
          <Banner className="mb-4 rounded-xl" status={'info'}>
            <TextStyle variant="xs-regular">
              {formatMessage(messages.cashEquivalentInfoBannerBeforeRedeem, {
                rewardName,
              })}
            </TextStyle>
          </Banner>
        )}
        <div className="rounded-lg bg-gray-3 p-4">
          <div className="z-20 rounded bg-gray-1">
            <SearchableSelect
              selectOptions={options}
              onChange={(currentSelectedOption) => {
                setSelectedOption(currentSelectedOption);
              }}
              selectedOption={selectedOption}
              displayValue={(item: {
                id: string;
                value: string;
                displayValue: string;
              }) => item.displayValue}
              placeholder={
                isLoadingDenominations
                  ? formatMessage(messages.loadingDenominations)
                  : options.every((x) => x.isDisabled)
                    ? formatMessage(messages.insufficientBalance)
                    : formatMessage(messages.selectGiftCardValue)
              }
              name="denominations"
              isDisabled={
                options.every((x) => x.isDisabled) || isLoadingDenominations
              }
            />
          </div>
        </div>
        <HorizontalRule className="py-4" />
        <TextStyle variant="sm-medium">
          {formatMessage(messages.redemptionPolicy, {
            rewardTypeTitle: rewardTypeTitle,
          })}
        </TextStyle>
        <div className="max-h-[200px] overflow-auto">
          <TextStyle className="pb-4" variant="xs-regular">
            {formatMessage(messages.redemptionInformation, { email })}
          </TextStyle>
          <TextStyle className="pb-4" variant="xs-regular">
            {formatMessage(messages.redemptionRefundWarning)}
          </TextStyle>
          <TextStyle className="break-all pb-4" variant="xs-regular">
            {parse(rewardCardContent)}
          </TextStyle>
        </div>
      </div>
      <div className="flex-shrink-0">
        <HorizontalRule />
        <footer className="flex flex-col gap-3 px-6 py-4">
          {Boolean(redemptionWarningBannerText) && (
            <Banner
              className="rounded-xl"
              status={isValidInCurrentCountry ? 'info' : 'error'}
            >
              <TextStyle variant="xs-regular">
                {redemptionWarningBannerText}
              </TextStyle>
            </Banner>
          )}
          <div className="py-2">
            <Button
              disabled={!selectedOption}
              onClick={() => {
                onRedeemRewardClick({
                  faceValue: parseFloat(selectedOption?.value ?? '0'),
                  id: selectedOption?.id ?? '',
                  carrotsValue: selectedOption?.carrotsValue ?? '0',
                });
              }}
              isLoading={isCashOutRewardInProgress}
              className="w-full"
              variation={
                isValidInCurrentCountry ? 'primary' : 'secondaryEmphasized'
              }
              data-testid="redeem-reward-button"
            >
              {isValidInCurrentCountry
                ? formatMessage(messages.redeem)
                : formatMessage(messages.redeemAnyway)}
              {Boolean(isCashOutRewardInProgress) && (
                <LoadingSpinner className="mr-2" />
              )}
            </Button>
          </div>
        </footer>
      </div>
    </div>
  );
}
