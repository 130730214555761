import type { Nullable } from '@assembly-web/services';
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import { useCallback, useEffect } from 'react';

import { resetEditor } from '../utils/resetEditor';

export function UpdateEditorValuePlugin({
  value,
}: {
  value: string | undefined;
}) {
  const [editor] = useLexicalComposerContext();

  const updateEditorState = useCallback(
    (state: Nullable<string>) => {
      if (!state) {
        resetEditor(editor);
        return;
      }

      const editorState = editor.parseEditorState(state);
      editor.setEditorState(editorState);
      editor.focus();
    },
    [editor]
  );

  useEffect(() => {
    if (value) {
      updateEditorState(value);
    }
  }, []);

  return null;
}
