import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  addQuestion: {
    defaultMessage: 'Add a question',
    id: 'PmnsLm',
  },
  questionTypes: {
    defaultMessage: 'Question types',
    id: 'Q2w0Bf',
  },
  advancedQuestion: {
    defaultMessage: 'Advanced questions',
    id: 'v1nS3E',
  },
  openEnded: {
    defaultMessage: 'Open Ended',
    id: 'JaxyG6',
  },
  dropdown: {
    defaultMessage: 'Dropdown',
    id: 'mART5Z',
  },
  scale: {
    defaultMessage: 'Scale',
    id: 'qFTxb3',
  },
  multipleChoice: {
    defaultMessage: 'Multiple Choice',
    id: '+7BzYr',
  },
  fileUpload: {
    defaultMessage: 'File Upload',
    id: 'XKyo5X',
  },
  personSelect: {
    defaultMessage: 'Select Person',
    id: 'XiAy7M',
  },
  gifSelect: {
    defaultMessage: 'GIF Select',
    id: 'kN1iAE',
  },
  givePoints: {
    defaultMessage: 'Give Points',
    id: 'k3gro/',
  },
  nps: {
    defaultMessage: 'NPS ®',
    id: 'Qiz0lu',
  },
  blockTypeSelector: {
    defaultMessage: 'Block type selector',
    id: 'CYlMr2',
  },
  disabledGivePoints: {
    defaultMessage: 'Give Points • Limit 1 per flow.',
    id: '7izn3D',
  },
});
