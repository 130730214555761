import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import { defineMessages, useIntl } from 'react-intl';

import { Tooltip } from '../../../DesignSystem/Feedback/Tooltip';

dayjs.extend(relativeTime);

export type ChallengeMetaInfoProps = {
  launchedAt: string | undefined;
  totalToClaim: number;
  variant?: 'preview' | 'default' | 'drawer' | 'carousal';
  endedAt: string | undefined;
};

const messages = defineMessages({
  launched: {
    defaultMessage: `Launched {timeAgo}`,
    id: 'EmKQ2d',
  },
  totalToClaim: {
    defaultMessage: `{totalToClaim} to claim`,
    id: 'Lv9CFh',
  },
  ended: {
    defaultMessage: 'Ended {timeAgo}',
    id: 'WQ0zA4',
  },
});
export const ChallengeMetaInfo = (props: ChallengeMetaInfoProps) => {
  const { launchedAt, totalToClaim, variant, endedAt } = props;

  const { formatMessage } = useIntl();

  const listItems = [];

  if (launchedAt && variant !== 'preview') {
    listItems.push({
      message: messages.launched,
      value: dayjs(launchedAt).fromNow(),
      tooltip: dayjs(launchedAt).format('MMMM D, YYYY h:mm A'),
    });
  }

  if (endedAt && variant !== 'preview') {
    listItems.push({
      message: messages.ended,
      value: dayjs(endedAt).fromNow(),
      tooltip: dayjs(endedAt).format('MMMM D, YYYY h:mm A'),
    });
  }

  if (!endedAt && totalToClaim > 0) {
    listItems.push({
      message: messages.totalToClaim,
      value: totalToClaim,
    });
  }

  return (
    <ul className="m-0 flex w-full list-none items-center gap-x-1 p-0 text-xs text-gray-8">
      {listItems.map((item, index) => (
        <li key={index} className={`flex w-fit gap-x-1 whitespace-nowrap`}>
          {index > 0 ? <>&#x2022;</> : null}
          {item.tooltip ? (
            <Tooltip tooltipText={item.tooltip}>
              <span>
                {formatMessage(item.message, { timeAgo: item.value })}
              </span>
            </Tooltip>
          ) : (
            <span>
              {formatMessage(item.message, { totalToClaim: item.value })}
            </span>
          )}
        </li>
      ))}
    </ul>
  );
};
