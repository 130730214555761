import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import { Anchor, Content, Portal, Root } from '@radix-ui/react-popover';
import { $getSelection, $isRangeSelection } from 'lexical';
import { useCallback, useEffect, useState } from 'react';

import { useRefContainer } from '../../../../../context/RefContext';
import { usePointerInteractions } from '../../../../hooks/usePointerInteractions';
import { RepliesHeaderPlugin } from '../../RepliesEditor/plugins/RepliesHeaderPlugin';
import { getDOMRangeRect } from '../utils/getDOMRect';

type FormattingFloatingMenuPluginProps = {
  isFormattingToolbarVisible: boolean;
};

export function FormattingFloatingMenuPluginImpl() {
  const [domRect, setDomRect] = useState<DOMRect | null>(null);
  const [editor] = useLexicalComposerContext();
  const { isPointerReleased } = usePointerInteractions();

  const containerRef = useRefContainer();

  const calculatePosition = useCallback(() => {
    const selection = $getSelection();
    const nativeSelection = window.getSelection();
    const rootElement = editor.getRootElement();
    if (
      selection !== null &&
      nativeSelection !== null &&
      !nativeSelection.isCollapsed &&
      rootElement?.contains(nativeSelection.anchorNode)
    ) {
      setDomRect(getDOMRangeRect(nativeSelection, rootElement));
    }
  }, [editor]);

  const $handleSelectionChange = useCallback(() => {
    if (editor.isComposing()) {
      setDomRect(null);
      return;
    }

    const selection = $getSelection();

    if ($isRangeSelection(selection) && !selection.anchor.is(selection.focus)) {
      calculatePosition();
    } else {
      setDomRect(null);
    }
  }, [editor, calculatePosition]);

  useEffect(() => {
    return editor.registerUpdateListener(({ editorState }) => {
      editorState.read(() => $handleSelectionChange());
    });
  }, [editor, $handleSelectionChange]);

  useEffect(() => {
    if (isPointerReleased) {
      editor.getEditorState().read(() => {
        $handleSelectionChange();
      });
    }
  }, [isPointerReleased, $handleSelectionChange, editor]);

  return (
    <Root open={Boolean(domRect)}>
      <Portal>
        <Anchor asChild>
          <div
            style={{
              position: 'fixed',
              top: domRect?.top,
              left: domRect?.left,
              width: domRect?.width,
            }}
          />
        </Anchor>
      </Portal>
      <Portal>
        <Content
          collisionBoundary={containerRef}
          align="center"
          side="top"
          collisionPadding={{ left: 32, right: 32, bottom: 24 }}
          className="z-50"
          sideOffset={8}
          onOpenAutoFocus={(e) => e.preventDefault()}
        >
          <RepliesHeaderPlugin isVisible type="compact" />
        </Content>
      </Portal>
    </Root>
  );
}

export function FormattingFloatingMenuPlugin({
  isFormattingToolbarVisible,
}: FormattingFloatingMenuPluginProps) {
  if (isFormattingToolbarVisible) {
    return null;
  }

  return <FormattingFloatingMenuPluginImpl />;
}
