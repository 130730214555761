import type { SearchableSelectOptionType } from '@assembly-web/services';
import { useCallback } from 'react';
import { defineMessages, useIntl } from 'react-intl';

import { SearchableSelect } from '../../..';

const messages = defineMessages({
  placeholder: {
    defaultMessage: `Choose {placeholderName}`,
    id: 'poSUD7',
  },
});

export const RewardDropdown = ({
  options = [],
  onChange,
  name,
  index,
  selectedOption,
  isDisabled,
}: {
  options: SearchableSelectOptionType[];
  onChange: (value: SearchableSelectOptionType) => void;
  name: string;
  index: number;
  formError?: string;
  selectedOption?: SearchableSelectOptionType;
  isDisabled?: boolean;
}) => {
  const { formatMessage } = useIntl();

  const handleChange = useCallback(
    (currentSelectedOption: SearchableSelectOptionType) => {
      onChange(currentSelectedOption);
    },
    [onChange]
  );

  const placeholderValue = formatMessage(messages.placeholder, {
    placeholderName: name,
  });

  return (
    <div
      className="rounded bg-gray-1"
      style={{ zIndex: 22 - index }}
      data-testid={`${name}-field`}
    >
      <SearchableSelect
        selectOptions={options}
        onChange={handleChange}
        selectedOption={selectedOption}
        displayValue={(item) => item.name}
        name={name}
        placeholder={placeholderValue}
        isDisabled={isDisabled}
      />
    </div>
  );
};
