import { $createListItemNode, $isListItemNode } from '@lexical/list';
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import {
  $getSelection,
  $isRangeSelection,
  COMMAND_PRIORITY_CRITICAL,
  KEY_ENTER_COMMAND,
} from 'lexical';
import { useCallback, useEffect } from 'react';

import { useDeviceInfo } from '../../../../hooks/useDeviceInfo';
import { useTouchDevice } from '../../../../hooks/useTouchDevice';
import { getSerializedDataFromEditor } from '../../base/utils';
import { getSelectedNode } from '../../base/utils/getSelectedNode';
import { resetEditor } from '../../base/utils/resetEditor';
import type { EditorData, RepliesValidatorError } from './RepliesFooterPlugin';

type HandleEnterKeyPressPluginProps = {
  disabled?: boolean;
  onPressEnter?: (data: EditorData) => void;
  validator?: (text: string) => RepliesValidatorError[];
};

export function HandleEnterKeyPressPlugin({
  disabled,
  onPressEnter,
  validator,
}: HandleEnterKeyPressPluginProps) {
  const [editor] = useLexicalComposerContext();
  const isMobile = useDeviceInfo().deviceType === 'mobile';
  const isTablet = useDeviceInfo().deviceType === 'tablet';
  const isTouchDevice = useTouchDevice();

  const onEnterKeyPressed = useCallback(() => {
    editor.update(() => {
      const {
        html,
        json,
        mentionIds,
        plainText,
        gifUrls,
        linkUrls,
        boostedUsers,
      } = getSerializedDataFromEditor(editor);

      const errors = validator?.(plainText) ?? [];
      if (errors.length === 0) {
        resetEditor(editor);
      }

      onPressEnter?.({
        json,
        html,
        plainText,
        mentionIds,
        errors,
        gifUrls,
        linkUrls,
        boost: boostedUsers,
      });
    });
  }, [editor, onPressEnter, validator]);

  useEffect(() => {
    return editor.registerCommand(
      KEY_ENTER_COMMAND,
      (event: KeyboardEvent) => {
        if (disabled) {
          return true;
        }

        const selection = $getSelection();
        if ($isRangeSelection(selection)) {
          const node = getSelectedNode(selection);
          if (
            node.getType() === 'text' &&
            node.getTextContent().includes('@')
          ) {
            return false;
          }

          if (event.shiftKey) {
            editor.update(() => {
              // If the current node is a list item, we should create a new list item
              // and for knowing that we should check if the parent node is a list item
              const selection = $getSelection();
              const parentNode = node.getParent();
              if (parentNode) {
                if ($isListItemNode(parentNode)) {
                  if ($isRangeSelection(selection)) {
                    const listItemNode = $createListItemNode();
                    selection.insertNodes([listItemNode]);
                  }
                  event.preventDefault();
                  return;
                }
              }

              if ($isRangeSelection(selection)) {
                selection.insertParagraph();
              }

              event.preventDefault();
            });
            return true;
          }

          const isEnterKeyPressed =
            !event.metaKey && !event.ctrlKey && event.key === 'Enter';

          if (
            !isMobile &&
            onPressEnter &&
            isEnterKeyPressed &&
            !(isTablet && isTouchDevice)
          ) {
            onEnterKeyPressed();
            return true;
          }

          return false;
        }
        return false;
      },
      COMMAND_PRIORITY_CRITICAL
    );
  }, [disabled, editor, isMobile, isTablet, isTouchDevice, onEnterKeyPressed]);

  return null;
}
