import type {
  RedemptionFormDetails,
  RewardDetailsForm,
  RewardDetailsOption,
  ShippingCountry,
  ShippingDetailsForm as ShippingDetailsFormType,
  ShippingState,
} from '@assembly-web/services';
import { useCallback, useState } from 'react';

import { RewardFormDetails } from './RewardDetailsForm';
import { ShippingDetailsForm } from './ShippingDetailsForm';

const initialSwagFormData = {
  size: undefined,
  color: undefined,
  quantity: {
    id: '1',
    name: '1',
    value: 1,
  },
};

const initialShippingFormData = {
  firstName: '',
  lastName: '',
  country: {
    id: 'US',
    name: 'United States',
    value: 'US',
  },
  state: undefined,
  company: '',
  address: '',
  secondaryAddress: '',
  city: '',
  zip: '',
  phoneNumber: '',
};

export const AxomoRedemptionForm = ({
  formStep,
  swagDetails,
  onSubmitForm,
  shippableCountries,
  stateList,
  onCountryChange,
  onSwagFormUpdate,
  onShippingFormUpdate,
  isStateListLoading,
}: {
  formStep: string;
  swagDetails: RewardDetailsOption[];
  onSubmitForm: (shippingDetails: RedemptionFormDetails) => void;
  shippableCountries: ShippingCountry[] | undefined;
  stateList: ShippingState[] | undefined;
  onCountryChange: (country: string) => void;
  onSwagFormUpdate: (swagInfo: RewardDetailsForm, hasErrors: boolean) => void;
  onShippingFormUpdate: (
    data: ShippingDetailsFormType,
    hasErrors: boolean
  ) => void;
  isStateListLoading: boolean;
}) => {
  const [swagFormData, setSwagFormData] =
    useState<RewardDetailsForm>(initialSwagFormData);
  const [shippingFormData, setShippingFormData] =
    useState<ShippingDetailsFormType>(initialShippingFormData);
  const [formErrors, setFormErrors] = useState({
    swagErrors: {},
    shippingErrors: {},
  });

  const handleSwagFormUpdate = useCallback(
    (data: RewardDetailsForm, errors: Record<string, unknown>) => {
      setSwagFormData(data);
      setFormErrors((prevErrors) => ({ ...prevErrors, swagErrors: errors }));
      onSwagFormUpdate(data, Object.keys(errors).length !== 0);
    },
    [onSwagFormUpdate]
  );

  const handleShippingFormUpdate = useCallback(
    (
      data: ShippingDetailsFormType,
      errors: Record<string, unknown>,
      markFormInvalid = false
    ) => {
      setShippingFormData(data);
      setFormErrors((prevErrors) => ({
        ...prevErrors,
        shippingErrors: errors,
      }));
      const isFormInvalid = Object.keys(errors).length !== 0 || markFormInvalid;
      onShippingFormUpdate(data, isFormInvalid);
    },
    [onShippingFormUpdate]
  );

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    const combinedData = { ...swagFormData, ...shippingFormData };
    onSubmitForm(combinedData);
  };

  const handleCountryChange = (country: ShippingCountry) => {
    onCountryChange(country.value as string);
    const { firstName, lastName } = shippingFormData;
    handleShippingFormUpdate(
      {
        ...initialShippingFormData,
        firstName,
        lastName,
        country: {
          id: country.id,
          name: country.name,
          value: country.value,
        },
        state: {
          id: '',
          name: '',
          value: '',
        },
      },
      {},
      true
    );
  };

  return (
    <form
      onSubmit={handleSubmit}
      id="axomo-form"
      className="flex flex-col gap-4"
      data-testid="axomo-form-component"
    >
      {formStep === 'swag-details' && (
        <RewardFormDetails
          rewardDetails={swagDetails}
          onUpdate={handleSwagFormUpdate}
          formErrors={formErrors.swagErrors}
          formData={swagFormData}
        />
      )}
      {formStep === 'shipping-details' && (
        <ShippingDetailsForm
          shippableCountries={shippableCountries}
          stateList={stateList}
          onCountryChange={handleCountryChange}
          onUpdate={handleShippingFormUpdate}
          formErrors={formErrors.shippingErrors}
          formData={shippingFormData}
          isStateListLoading={isStateListLoading}
          isAmazonForm={false}
        />
      )}
    </form>
  );
};
