import * as Sentry from '@sentry/react';
import { AxiosError } from 'axios';

export const logger = {
  error: (message: string, messageContext?: object, error?: Error) => {
    Sentry.withScope(function (scope) {
      scope.setContext(message, {
        ...messageContext,
        ...(error instanceof AxiosError && {
          serverResponse: error.response?.data.body,
        }),
      });
      scope.setTag('custom', 'error');
      Sentry.captureMessage(message, {
        ...messageContext,
        ...error,
        level: 'error',
      });
    });
  },
  warn: (message: string, messageContext?: object, error?: Error) => {
    Sentry.withScope(function (scope) {
      scope.setContext(message, {
        ...messageContext,
      });
      scope.setTag('custom', 'warning');
      Sentry.captureMessage(message, {
        ...messageContext,
        ...error,
        level: 'warning',
      });
    });
  },
};
