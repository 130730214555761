import { useEffect, useRef, useState } from 'react';

import { TextStyle, type TextStyleProps } from './TextStyle';
import { Tooltip } from './Tooltip';

export function OverflowText(
  props: TextStyleProps & { tooltipText?: string; tooltipClassname?: string }
) {
  const { tooltipText, tooltipClassname, ...propsWithContent } = props;

  const [textOverflow, setTextOverflow] = useState(false);
  const textRef = useRef<HTMLParagraphElement>(null);

  useEffect(() => {
    const checkOverflow = () => {
      const current = textRef.current;

      if (current) {
        const isWidthOverflowing = current.scrollWidth > current.clientWidth;
        const isHeightOverflowing = current.scrollHeight > current.clientHeight;
        const isOverflowing = isWidthOverflowing || isHeightOverflowing;
        setTextOverflow(isOverflowing);
      }
    };

    checkOverflow();

    window.addEventListener('resize', checkOverflow);
    return () => window.removeEventListener('resize', checkOverflow);
  }, []);

  return (
    <Tooltip
      tooltipText={Boolean(textOverflow) && tooltipText}
      contentClassName={tooltipClassname}
    >
      <TextStyle {...propsWithContent} ref={textRef} />
    </Tooltip>
  );
}
