import type {
  ButtonHTMLAttributes,
  ForwardRefExoticComponent,
  FunctionComponent,
  ReactElement,
  ReactNode,
  RefAttributes,
  SVGProps,
} from 'react';
import { twMerge } from 'tailwind-merge';

import {
  TextStyle,
  type TextVariant,
} from '../../../DesignSystem/Feedback/TextStyle';

type SVGComponentProps = SVGProps<SVGSVGElement> & {
  title?: string;
  titleId?: string;
};

type SVGComponent =
  | FunctionComponent<SVGComponentProps>
  | ForwardRefExoticComponent<SVGComponentProps & RefAttributes<SVGSVGElement>>;

const Root = (props: ButtonHTMLAttributes<HTMLButtonElement>) => {
  return (
    <button
      {...props}
      className={twMerge(
        'flex flex-col border bg-gray-1 text-left text-gray-9',
        props.className
      )}
    />
  );
};

const Icon = (props: {
  children: ReactElement<SVGComponent>;
  className?: string;
}) => {
  const { children, className } = props;

  return <div className={className}>{children}</div>;
};

const CategoryOptionTextStyle = (props: {
  children: ReactNode;
  className?: string;
  variant: TextVariant;
}) => {
  const { children, className, variant } = props;

  return (
    <TextStyle className={className} variant={variant}>
      {children}
    </TextStyle>
  );
};

const CTATextStyle = (props: { children: ReactNode; className?: string }) => {
  const { className, children } = props;
  return (
    <TextStyle className={className} variant="sm-regular">
      {children}
    </TextStyle>
  );
};

export const CategoryOption = {
  CTA: CTATextStyle,
  Description: CategoryOptionTextStyle,
  Icon,
  Root,
  Title: CategoryOptionTextStyle,
};
