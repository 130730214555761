import { InformationCircleIcon } from '@heroicons/react/24/outline';
import type { ChangeEvent } from 'react';
import { defineMessages, useIntl } from 'react-intl';

import { TextStyle } from '../../../DesignSystem/Feedback/TextStyle';
import { Tooltip } from '../../../DesignSystem/Feedback/Tooltip';
import { Checkbox } from '../../../DesignSystem/Inputs/Checkbox';

const messages = defineMessages({
  pinCollectionTitle: {
    defaultMessage: 'Pin this collection for people',
    id: 'InTrg9',
  },
  pinCollectionDesc: {
    defaultMessage:
      'Pin this collection to the navigation menu for everyone with access',
    id: 'SrH0CM',
  },
  tooltipText: {
    defaultMessage:
      'Do you have a super important collection you want highlighted? Pinning a collection for everyone with access will allow this collection to show up on everyone’s navigation menu. People will be able to unpin this collection if they want to.',
    id: 'dz3eTB',
  },
});

function SoftPinCollectionLoader() {
  return (
    <div className="flex animate-pulse flex-col gap-y-3 p-4">
      <div className="h-4 w-1/2 rounded bg-gray-3"></div>
      <div className="h-2 w-3/4 rounded bg-gray-3"></div>
    </div>
  );
}

export function SoftPinCollection({
  isPinned = false,
  onIsPinnedChange,
  isLoading,
}: {
  isPinned?: boolean;
  onIsPinnedChange?: (isPinned: boolean) => void;
  isLoading: boolean | undefined;
}) {
  const { formatMessage } = useIntl();
  return isLoading ? (
    <SoftPinCollectionLoader />
  ) : (
    <div className="mx-2 my-3 flex flex-col gap-y-3 rounded-lg bg-gray-3 p-4">
      <div className="flex items-center">
        <TextStyle variant="base-medium" className="mr-2">
          {formatMessage(messages.pinCollectionTitle)}
        </TextStyle>
        <Tooltip
          tooltipText={formatMessage(messages.tooltipText)}
          contentClassName="relative z-20"
          side="top"
        >
          <InformationCircleIcon className="h-4 w-4" tabIndex={0} />
        </Tooltip>
      </div>
      <div className="flex items-center gap-2">
        <Checkbox
          id="pinCollection"
          checked={isPinned}
          onChange={(e: ChangeEvent<HTMLInputElement>) => {
            onIsPinnedChange?.(e.target.checked);
          }}
        />
        <label
          className="cursor-pointer text-sm text-gray-9"
          htmlFor="pinCollection"
        >
          {formatMessage(messages.pinCollectionDesc)}
        </label>
      </div>
    </div>
  );
}
