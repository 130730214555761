import { disassembledImage } from '@assembly-web/assets';
import { useCallback } from 'react';
import { defineMessages, useIntl } from 'react-intl';

import { TextStyle } from '../../DesignSystem/Feedback/TextStyle';
import { Button } from '../../DesignSystem/Inputs/Button';

const messages = defineMessages({
  heading: {
    defaultMessage: 'Oops! Something was disassembled.',
    id: 'uELsLC',
  },
  body: {
    defaultMessage: 'Head back home while we re-assemble.',
    id: 'D1xFAE',
  },
  cta: {
    defaultMessage: 'Take me home',
    id: 'BwBvS4',
  },
});

export function ErrorPage() {
  const { formatMessage } = useIntl();

  const goHome = useCallback(() => window.location.replace('/'), []);

  return (
    <section className="container mx-auto flex w-fit flex-col items-center justify-center space-y-6 px-4">
      <img src={disassembledImage} role="presentation" alt="" />

      <TextStyle variant="2xl-bold">
        {formatMessage(messages.heading)}
      </TextStyle>
      <p className="text-lg">{formatMessage(messages.body)}</p>
      <Button onClick={goHome}>{formatMessage(messages.cta)}</Button>
    </section>
  );
}
