import type { BlockType } from '@assembly-web/services';
import {
  ArrowUpTrayIcon,
  CheckCircleIcon,
  ChevronDownIcon,
  GifIcon,
  TrophyIcon,
  UserIcon,
} from '@heroicons/react/24/outline';
import { twJoin } from 'tailwind-merge';

import { GaugeIcon, LinkedScale, TextAlignLeft } from '../../../Icons';

export function Icon({
  className,
  dark,
  type,
}: {
  type: BlockType;
  dark?: boolean;
  className?: string;
}) {
  switch (type) {
    case 'DROPDOWN':
      return (
        <ChevronDownIcon
          className={twJoin(
            dark ? 'stroke-gray-9' : 'stroke-gray-8',
            className
          )}
        />
      );
    case 'SCALE':
      return (
        <LinkedScale
          className={twJoin(dark ? 'text-gray-9' : 'text-gray-8', className)}
        />
      );
    case 'OPEN_ENDED':
      return (
        <TextAlignLeft
          className={twJoin(dark ? 'fill-gray-9' : 'fill-gray-8', className)}
        />
      );
    case 'TROPHIES':
      return (
        <TrophyIcon
          className={twJoin(
            dark ? 'stroke-gray-9' : 'stroke-gray-8',
            className
          )}
        />
      );
    case 'PERSON_SELECTOR':
      return (
        <UserIcon
          className={twJoin(
            dark ? 'stroke-gray-9' : 'stroke-gray-8',
            className
          )}
        />
      );
    case 'GIF':
      return (
        <GifIcon
          className={twJoin(
            dark ? 'stroke-gray-9' : 'stroke-gray-8',
            className
          )}
        />
      );
    case 'FILE_UPLOAD':
      return (
        <ArrowUpTrayIcon
          className={twJoin(
            dark ? 'stroke-gray-9' : 'stroke-gray-8',
            className
          )}
        />
      );
    case 'MULTI_CHOICE':
      return (
        <CheckCircleIcon
          className={twJoin(
            dark ? 'stroke-gray-9' : 'stroke-gray-8',
            className
          )}
        />
      );
    case 'NPS':
      return <GaugeIcon />;
    case 'GIVE_POINTS_STACK':
      return (
        <TrophyIcon
          className={twJoin(
            dark ? 'stroke-gray-9' : 'stroke-gray-8',
            className
          )}
        />
      );
    default:
      return null;
  }
}
