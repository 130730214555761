import type { Channel } from 'pusher-js';
import { createContext, type ReactNode, useCallback, useContext } from 'react';

import type { PusherContextValue } from './PusherProvider';
import { PusherContext } from './PusherProvider';

export type ChannelsContextValue = {
  subscribe?: (channelName: string) => Channel | undefined;
  unsubscribe?: (channelName: string) => void;
};

export const ChannelsContext = createContext<ChannelsContextValue>({});

export function ChannelsProvider({ children }: { children: ReactNode }) {
  const context = useContext<PusherContextValue>(PusherContext);

  const subscribe = useCallback(
    (channelName: string) => {
      if (!context || !channelName) {
        return;
      }

      return context.subscribe(channelName);
    },
    [context]
  );

  const unsubscribe = useCallback(
    (channelName: string) => {
      if (!context || !channelName) {
        return;
      }

      return context.subscribe(channelName);
    },
    [context]
  );

  return (
    <ChannelsContext.Provider value={{ subscribe, unsubscribe }}>
      {children}
    </ChannelsContext.Provider>
  );
}
