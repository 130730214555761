import bowser from 'bowser';

const browser = bowser.getParser(window.navigator.userAgent);

export const isMobile = browser.getPlatformType() === 'mobile';

export const isMac = browser.getOSName(true) === 'macos';

export const isSafari = browser.getBrowserName() === 'Safari';

export const isiPhone = browser.parseOS().name === 'iOS';

export const isAndroid = browser.parseOS().name === 'android';

export const isChrome = browser.getBrowserName() === 'Chrome';

export const isEdge = browser.getBrowserName() === 'Microsoft Edge';
