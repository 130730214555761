import type { BlockTypes } from './flowTypes';

type DropdownOption = {
  id: string;
  value: string;
};

type BaseBlockContent = {
  title: string;
  type: BlockTypes;
};

type BaseBlockSettingsRules = {
  required: boolean;
};

export enum AllowedMedia {
  Files = 'FILES',
  Emoji = 'EMOJI',
  Gif = 'GIF',
  Mention = 'MENTION',
  Tasks = 'TASKS',
}

type OpenEndedBlockContentRules = BaseBlockSettingsRules & {
  allowedMedia: AllowedMedia[];
  fileType: string[];
};

export type OpenEndedBlockSettings = BaseBlockContent & {
  type: BlockTypes.OpenEnded;
  rules: OpenEndedBlockContentRules;
};

export enum ScaleBlockLabelType {
  Low = 'low',
  Middle = 'middle',
  High = 'high',
}

export type ScaleBlockLabels = {
  [key in ScaleBlockLabelType]?: string;
};

export type ScaleBlockSettings = BaseBlockContent & {
  type: BlockTypes.Scale;
  min: number;
  max: number;
  rules: BaseBlockSettingsRules;
  labels: ScaleBlockLabels;
  isNPSEnabled?: boolean;
};

type RuleLimit = {
  exact?: number;
  noLimit?: boolean;
  range?: {
    min: number;
    max: number;
  };
};

type DropdownBlockSettingsRules = BaseBlockSettingsRules & {
  limit?: RuleLimit;
};

type MultiChoiceBlockSettingsRules = BaseBlockSettingsRules & {
  limit?: RuleLimit;
  allowOther?: boolean;
};

export enum MultiChoiceBlockOptionType {
  Single = 'SINGLE',
  Multi = 'MULTI',
}

export type MultiChoiceBlockSettings = BaseBlockContent & {
  type: BlockTypes.MultiChoice;
  options: DropdownOption[];
  rules: MultiChoiceBlockSettingsRules;
  optionType: MultiChoiceBlockOptionType;
};

export type DropdownBlockSettings = BaseBlockContent & {
  type: BlockTypes.Dropdown;
  options: DropdownOption[];
  rules: DropdownBlockSettingsRules;
};

export type FileUploadBlockSettings = BaseBlockContent & {
  type: BlockTypes.FileUpload;
  rules: BaseBlockSettingsRules;
};

export enum PersonSelectorSelectType {
  SinglePerson = 'SINGLE_PERSON',
  MultiPerson = 'MULTI_PERSON',
}

export type PersonSelectorBlockSettings = BaseBlockContent & {
  type: BlockTypes.PersonSelector;
  selectType: PersonSelectorSelectType;
  // If the dependentKeys of GivePointsBlockSettingsType includes this key,
  // They are inter-connected (Person selector block + Points block = Give recognition block)
  key?: string;
  rules: BaseBlockSettingsRules & {
    select: string;
  };
};

export type GifBlockSettings = BaseBlockContent & {
  type: BlockTypes.Gif;
  rules: BaseBlockSettingsRules;
};

export type GivePointsBlockSettings = BaseBlockContent & {
  type: BlockTypes.GivePointsStack;
  dependentKeys?: string[];
  rules: BaseBlockSettingsRules & {
    hidePoints?: boolean;
    points?: { noLimit: boolean };
  };
};
