import { defineMessages, useIntl } from 'react-intl';

import { Button } from '../../../DesignSystem/Inputs/Button';
import {
  AssemblyAmazon,
  AssemblyAXOMO,
  NoRewardsFound,
  SetUpRewards,
} from '../../assets/images';

type NoRewardDisclaimerProps = {
  description: string | JSX.Element;
  title?: string;
  rewardType?:
    | 'gift cards'
    | 'swag'
    | 'charities'
    | 'culture rewards'
    | 'discounts'
    | 'amazon';
  swagCollabMessage?: string;
  adminUrl?: string;
  emptyStateImage?: string | JSX.Element;
  showUpgradeLink?: boolean;
  ctaText?: string;
};

const messages = defineMessages({
  setUpLabel: {
    defaultMessage: 'Set up {rewardType}',
    id: 'ZlFgtf',
  },
  noRewardsSetUpTitle: {
    defaultMessage:
      'You haven’t set up any {rewardType}, yet. This page is only visible to you as an admin.',
    id: 'p3mDsB',
  },
  upgradeHere: {
    defaultMessage: 'Upgrade here',
    id: 'IqbAkA',
  },
});

export function NoRewardDisclaimer({
  title,
  rewardType,
  description,
  swagCollabMessage,
  adminUrl,
  emptyStateImage,
  showUpgradeLink,
  ctaText,
}: NoRewardDisclaimerProps) {
  const { formatMessage } = useIntl();

  const handleOpenAdminRewards = () => {
    if (rewardType === 'gift cards')
      window.open(`${adminUrl}/rewards/gifts`, '_self', 'noopener,noreferrer');
    if (rewardType === 'charities')
      window.open(
        `${adminUrl}/rewards/charities`,
        '_self',
        'noopener,noreferrer'
      );
    if (rewardType === 'swag')
      window.open(`${adminUrl}/rewards/swag`, '_self', 'noopener,noreferrer');
    if (rewardType === 'culture rewards')
      window.open(
        `${adminUrl}/rewards/culture`,
        '_self',
        'noopener,noreferrer'
      );
    if (rewardType === 'discounts')
      window.open(
        `${adminUrl}/rewards/discounts`,
        '_self',
        'noopener,noreferrer'
      );
    if (rewardType === 'amazon')
      window.open(`${adminUrl}/rewards/amazon`, '_self', 'noopener,noreferrer');
  };

  return (
    <div className="flex w-full flex-col items-center justify-center rounded-md border-2 border-dashed border-gray-5 bg-gray-1 bg-opacity-50 p-8">
      {rewardType ? (
        rewardType === 'swag' || rewardType === 'amazon' ? (
          <div className="mb-6 w-3/5 rounded-lg border border-gray-5 bg-gray-1 px-6 py-4">
            <img
              src={rewardType === 'amazon' ? AssemblyAmazon : AssemblyAXOMO}
              alt="Assembly SWAG Collaboration"
              className="mx-auto mb-2 h-[75px]"
            />
            <p className="mx-auto text-center text-gray-8">
              {swagCollabMessage}
            </p>
          </div>
        ) : typeof emptyStateImage === 'string' ||
          typeof emptyStateImage == 'undefined' ? (
          <img
            src={emptyStateImage ?? SetUpRewards}
            className="mb-4 h-[200px]"
            alt="Rewards not set up"
          />
        ) : (
          emptyStateImage
        )
      ) : (
        <img src={NoRewardsFound} className="mb-4" alt="No rewards found" />
      )}

      <h3 className="mb-1 text-center font-medium text-gray-9">
        {title ?? formatMessage(messages.noRewardsSetUpTitle, { rewardType })}
      </h3>
      <p className="mb-4 w-full max-w-[768px] text-center text-sm text-gray-8">
        {description}{' '}
        {Boolean(showUpgradeLink) && (
          <a href={`${adminUrl}/billing/account/`} className="underline">
            {formatMessage(messages.upgradeHere)}
          </a>
        )}
      </p>

      {Boolean(rewardType) && (
        <Button
          onClick={handleOpenAdminRewards}
          data-testid="rewards-admin-view-cta"
        >
          {ctaText ?? formatMessage(messages.setUpLabel, { rewardType })}
        </Button>
      )}
    </div>
  );
}
