import { defineMessages, useIntl } from 'react-intl';

import { Modal } from '../../../../DesignSystem/Feedback/Modal';
import { TextStyle } from '../../../../DesignSystem/Feedback/TextStyle';
import { G2Review } from '../../../assets/images';

const messages = defineMessages({
  title: {
    defaultMessage: 'Thank you!',
    id: 'aYIUar',
  },
});

export type SuccessModalProps = {
  isOpen: boolean;
  onClose: () => void;
};

export function SuccessModal({ isOpen, onClose }: SuccessModalProps) {
  const { formatMessage } = useIntl();
  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      className="flex h-44 w-72 flex-col sm:w-72"
      headerClassName="px-3 pb-1 pt-[6px] capitalize"
      bodyClassName="flex flex-col gap-4 flex-1 px-3 pb-[6px]"
      title={''}
    >
      <div className="flex flex-col items-center gap-4">
        <img
          alt="review"
          className="h-[35px] w-[44px] origin-top-left"
          src={G2Review}
        />
        <TextStyle className="text-center" variant="lg-medium">
          {formatMessage(messages.title)}
        </TextStyle>
      </div>
    </Modal>
  );
}
