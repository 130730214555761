export enum ReactionTypes {
  Regular = 'REGULAR',
  Custom = 'CUSTOM',
}

export type Reaction = {
  name: string;
  value: string;
  type: ReactionTypes;
  displayName: string;
};
