import type { StateStorage } from 'zustand/middleware';

import { logger } from '../logger';

export const zustandLocalStorage: StateStorage = {
  getItem: (key: string) => {
    try {
      return localStorage.getItem(key);
    } catch (error) {
      logger.error(
        'Failed to get Zustand state from localStorage',
        { key },
        error instanceof Error ? error : undefined
      );

      return null;
    }
  },
  removeItem: (key: string) => {
    try {
      localStorage.removeItem(key);
    } catch (error) {
      logger.error(
        'Failed to remove Zustand state from localStorage',
        { key },
        error instanceof Error ? error : undefined
      );
    }
  },
  setItem: (key: string, value: string) => {
    try {
      localStorage.setItem(key, value);
    } catch (error) {
      logger.error(
        'Failed to set Zustand state in localStorage',
        { key, value },
        error instanceof Error ? error : undefined
      );
    }
  },
};
