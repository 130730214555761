import type { CropperOptions } from '@assembly-web/services';

export const defaultUploadOptions = {
  autoUpload: true,
  maxNumberOfFiles: 25,
  allowedFileTypes: [],
  showImageEditor: false,
  allowMultipleFiles: true,
  maxFileSize: 4 * 1024 * 1024 * 1024,
  maxTotalFileSize: 4 * 1024 * 1024 * 1024,
};

export const defaultCroppedCanvasOptions = {
  minWidth: 100,
  minHeight: 100,
  maxWidth: 4096,
  maxHeight: 4096,
};

export const defaultCropperOptions: CropperOptions = {
  viewMode: 1,
  autoCropArea: 1,
  responsive: true,
  background: false,
};

export const defaultCropperActions = {
  flip: true,
  revert: true,
  rotate: true,
  zoomIn: true,
  zoomOut: true,
  cropSquare: true,
  cropWidescreen: true,
  granularRotate: true,
  cropWidescreenVertical: true,
};
