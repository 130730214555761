// eslint-disable-next-line @eslint-community/eslint-comments/disable-enable-pair
/* eslint-disable jsx-a11y/no-autofocus */

import {
  type AssemblyCurrency,
  AssemblyCurrencyType,
  mapHexCodeToEmoticon,
} from '@assembly-web/services';
import { Controller, useFormContext } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { twJoin } from 'tailwind-merge';
import type { z } from 'zod';

import { InlineError } from '../../../../../DesignSystem/Feedback/InlineError';
import { TextStyle } from '../../../../../DesignSystem/Feedback/TextStyle';
import { TextField } from '../../../../../DesignSystem/Inputs/TextField';
import { RichTextEditor } from '../../../Editors/RichTextEditor/RichTextEditor';
import { capitalizeString } from '../../../utils';
import { messages } from '../message';
import { getSchema } from '../schema';

const maxPointsLimit = 10000;
export function Details({
  currency,
  disabled = false,
  tooltipText,
}: {
  currency?: AssemblyCurrency;
  disabled?: boolean;
  tooltipText?: string;
}) {
  const { formatMessage } = useIntl();
  const schema = getSchema(formatMessage);
  type FormFields = z.infer<typeof schema>;
  const {
    register,
    control,
    watch,
    getValues,
    setValue,
    formState: { errors },
  } = useFormContext<FormFields>();

  const {
    onChange: onPointsChange,
    name: points,
    ref: pointsRef,
  } = register('points');

  const classForTitle = 'm-1';
  const classForInput = 'mr-0.5';

  const getCurrencyIcon = (className: string) => {
    return currency ? (
      currency.type === AssemblyCurrencyType.Custom ? (
        <img
          alt={currency.name}
          className={twJoin('h-3 w-3', className)}
          src={currency.value}
        />
      ) : (
        mapHexCodeToEmoticon(currency.value)
      )
    ) : (
      mapHexCodeToEmoticon('1f3c6')
    );
  };

  const inputClassName = 'text-sm text-gray-9';
  return (
    <div>
      <div className="flex flex-col gap-4">
        <div>
          <TextStyle
            variant="sm-medium"
            className="pb-1 text-sm font-medium text-gray-9"
          >
            {formatMessage(messages.titleLabel)}
          </TextStyle>
          <TextField
            autoFocus={true}
            tooltipText={tooltipText}
            disabled={disabled}
            className="pointer-events-auto"
            {...register('title')}
            type="text"
            placeholder={formatMessage(messages.placeholderLabelForTitle)}
            invalidText={errors['title']?.message ?? ''}
            isInvalid={Boolean(errors['title']?.message)}
            inputClassName={inputClassName}
            aria-label="title"
            helpText={formatMessage(messages.titleHintLabel)}
            connectedRight={
              watch('title') &&
              watch('title').length > 0 && (
                <TextStyle
                  variant="sm-medium"
                  className="-mr-2 mt-16 text-gray-9"
                >
                  {getValues('title').length}
                  /45
                </TextStyle>
              )
            }
          />
        </div>
        <div>
          <TextStyle variant="sm-medium" className="pb-1 text-gray-9">
            {formatMessage(messages.descriptionLabel)}
          </TextStyle>
          <Controller
            control={control}
            name="description"
            render={({ field: { onChange } }) => (
              <RichTextEditor
                disabled={disabled}
                tooltipText={tooltipText}
                editorClassName="text-sm text-gray-9"
                value={getValues('description')?.messageTokens}
                onChange={(editorContent) => {
                  onChange({
                    messageHtml: editorContent.html,
                    messageTokens: editorContent.json,
                    plainText: editorContent.plainText,
                  });
                }}
                hasError={Boolean(errors.description?.plainText?.message)}
                placeholder={formatMessage(
                  messages.placeholderLabelForDescription
                )}
              />
            )}
          />
          {errors.description?.plainText?.message ? (
            <InlineError id="description" size="xs" as="span">
              {errors.description.plainText.message}
            </InlineError>
          ) : (
            <span className="text-xs text-gray-8">
              {formatMessage(messages.descriptionHintLabel)}
            </span>
          )}
        </div>

        <div>
          <TextStyle
            variant="sm-medium"
            className="flex pb-1 text-sm font-medium text-gray-9"
          >
            {formatMessage(messages.currencyTitle, {
              currency:
                currency?.pluralName && capitalizeString(currency.pluralName),
              icon: getCurrencyIcon(classForTitle),
            })}
          </TextStyle>
          <TextField
            tooltipText={tooltipText}
            disabled={disabled}
            className="pointer-events-auto"
            onChange={onPointsChange}
            onBlur={(e) => {
              if (Number(e.target.value) <= maxPointsLimit) {
                setValue('points', Number(e.target.value));
              } else {
                setValue('points', maxPointsLimit);
              }
            }}
            name={points}
            ref={pointsRef}
            type="number"
            placeholder={formatMessage(messages.placeHolderForCurrency)}
            invalidText={errors['points']?.message ?? ''}
            isInvalid={Boolean(errors['points']?.message)}
            inputClassName={inputClassName}
            connectedLeft={getCurrencyIcon(classForInput)}
            aria-label={'points'}
          />
        </div>
      </div>
    </div>
  );
}
