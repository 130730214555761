import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  stepLabel: {
    defaultMessage: `Step {step}`,
    id: 'EJ8mg/',
  },
  customizeAndStyleLabel: {
    defaultMessage: 'Customize & style',
    id: 'DwMp0X',
  },
  settingsAndControlsLabel: {
    defaultMessage: 'Settings & controls',
    id: '2SZ33x',
  },
  distributionLabel: {
    defaultMessage: 'Distribution',
    id: 'Orc8Zk',
  },
  announcementCenterLabel: {
    defaultMessage: 'Announcement Center',
    id: 'VCGquG',
  },
  titleLabel: {
    defaultMessage: 'Title',
    id: '9a9+ww',
  },
  titleRequiredLabel: {
    defaultMessage: 'Title is required',
    id: 'Lp3DO2',
  },
  titleTooLongLabel: {
    defaultMessage: 'Title must be 45 characters or less',
    id: 'nWEAnX',
  },
  titleHintLabel: {
    defaultMessage: '45 character limit',
    id: 'THIneq',
  },
  placeholderLabelForTitle: {
    defaultMessage: 'eg. New PTO Policy',
    id: 'Zkho4l',
  },
  descriptionLabel: {
    defaultMessage: 'Description (optional)',
    id: 'bZenNC',
  },
  placeholderLabelForDescription: {
    defaultMessage:
      'eg. We’ve released the annual update to our policy. Please check out this post to learn more',
    id: 'v2DLxV',
  },
  announcementThumbnailLabel: {
    defaultMessage: 'Announcement thumbnail',
    id: '+ROPwm',
  },
  chooseBackgroundColorLabel: {
    defaultMessage: 'Choose background color',
    id: '/NajSx',
  },
  getHelpLabel: {
    defaultMessage: 'Get help',
    id: '9OuUCL',
  },
  nextLabel: {
    defaultMessage: 'Next',
    id: '9+Ddtu',
  },
  titleRequiredErrorLabel: {
    defaultMessage: 'Title is required',
    id: 'Lp3DO2',
  },
  backLabel: {
    defaultMessage: 'Back',
    id: 'cyR7Kh',
  },
  postInteractionSettingsLabel: {
    defaultMessage: 'Post interaction settings',
    id: 'qB/sCv',
  },
  postInteractionSettingsDescriptionLabel: {
    defaultMessage:
      'When turned off, existing replies and reactions will be hidden.',
    id: 'uQBwfk',
  },
  allowRepliesLabel: {
    defaultMessage: 'Allow replies on this post',
    id: 'J456i4',
  },
  allowReactionsLabel: {
    defaultMessage: 'Allow reactions on this post',
    id: 'lplhM5',
  },
  distributionAudienceSettingsLabel: {
    defaultMessage: 'Where do you want notify your audience?',
    id: 'VOp7ky',
  },
  distributionAudienceSettingsDescriptionLabel: {
    defaultMessage: 'Send notifications to the places your audience works.',
    id: 'Md9HC+',
  },
  editLabel: {
    defaultMessage: 'Edit',
    id: 'wEQDC6',
  },
  closeLabel: {
    defaultMessage: 'Close',
    id: 'rbrahO',
  },
  emailLabel: {
    defaultMessage: 'Email',
    id: 'sy+pv5',
  },
  onLaunchLabel: {
    defaultMessage: 'On launch',
    id: 'bYfB9p',
  },
  notifyOnLaunch: {
    defaultMessage: 'Notify on launch',
    id: '9e1C77',
  },
  emailNotifyOnLaunchDescription: {
    defaultMessage: 'Email your audience when this announcement is created.',
    id: 'SSt/6k',
  },
  mobileAppLabel: {
    defaultMessage: 'Mobile app',
    id: 'i/O7gT',
  },
  mobileAppNotifyOnLaunchDescription: {
    defaultMessage:
      'Send a push notification to your audience when this announcement is created.',
    id: '0eXHcQ',
  },
  createAnnouncementLabel: {
    defaultMessage: 'Create announcement',
    id: 'ctE5Aj',
  },
  saveAnnouncementLabel: {
    defaultMessage: 'Save announcement',
    id: 'O8Kfqk',
  },
  noneLabel: {
    defaultMessage: 'None',
    id: '450Fty',
  },
  savingLabel: {
    defaultMessage: 'Saving...',
    id: 'TiR/Hq',
  },
  confirmationModalTitle: {
    defaultMessage:
      'Are you sure you want to leave without finishing this announcement?',
    id: '4D5HRd',
  },
  confirmationModalDescription: {
    defaultMessage:
      'Your work will not be saved. This post will still exist, but it won’t be pinned as announcement to your audience’s feed.',
    id: 'AWiTDV',
  },
  confirmationModalLabel: {
    defaultMessage: 'Exit announcement dialog',
    id: 'bB9VTu',
  },
  cancelLabel: {
    defaultMessage: 'Cancel',
    id: '47FYwb',
  },
  leaveAnnouncementLabel: {
    defaultMessage: 'Leave announcement',
    id: 'MKLJP8',
  },
});
