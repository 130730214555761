import {
  type AssemblyCurrency,
  AssemblyCurrencyType,
  type GifRatingsProps,
  mapHexCodeToEmoticon,
} from '@assembly-web/services';
import type { IGif } from '@giphy/js-types';
import {
  AtSymbolIcon,
  CheckIcon,
  FaceSmileIcon,
  GifIcon,
  PaperAirplaneIcon,
  XMarkIcon,
} from '@heroicons/react/24/outline';
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import { mergeRegister } from '@lexical/utils';
import { type BaseEmoji, Picker } from 'emoji-mart';
import {
  $createTextNode,
  $getRoot,
  $insertNodes,
  COMMAND_PRIORITY_EDITOR,
} from 'lexical';
import { useEffect, useMemo, useState } from 'react';
import { defineMessages, useIntl } from 'react-intl';
import { twJoin, twMerge } from 'tailwind-merge';

import { TextStyle } from '../../../../../DesignSystem/Feedback/TextStyle';
import { Tooltip } from '../../../../../DesignSystem/Feedback/Tooltip';
import { Button } from '../../../../../DesignSystem/Inputs/Button';
import { BoostPlusIcon, FormattingIcon } from '../../../../assets/icons';
import { useDeviceInfo } from '../../../../hooks/useDeviceInfo';
import { Popover } from '../../../../Shared/Popover';
import { GiphySelect } from '../../../GiphySelect/GiphySelect';
import { ToggleButton } from '../../base/components/ToggleButton';
import { INSERT_IMAGE_COMMAND } from '../../base/plugins/ImagePlugin';
import {
  BOOST_CLICK_COMMAND,
  POINTS_EXHAUSTED_COMMAND,
} from '../../base/plugins/MentionsPlugin';
import { getSerializedDataFromEditor } from '../../base/utils';
import { getSelectedNodes } from '../../base/utils/getSelectedNodes';
import { resetEditor } from '../../base/utils/resetEditor';
import type { BoostOptions } from '../RepliesEditor';

export type RepliesValidatorError = 'HAS_PROFANITY' | 'MAX_LENGTH_REACHED';

export type Boost = {
  points: number;
  memberId: string;
};

export type EditorData = {
  json: string;
  html: string;
  boost?: Boost[];
  plainText: string;
  gifUrls?: string[];
  linkUrls?: string[];
  mentionIds?: string[];
  errors?: RepliesValidatorError[];
};

type RepliesFooterPluginProps = {
  value?: string;
  disabled?: boolean;
  giphyAPIKey: string;
  isEditing?: boolean;
  onCancelClick: () => void;
  gifRating: GifRatingsProps;
  currency?: AssemblyCurrency;
  disablePostButton?: boolean;
  isAnonymousReply?: boolean;
  hideToolbarButtons: boolean;
  postButtonTooltipText?: string;
  isFormattingToolbarVisible: boolean;
  onReplyClick: (data: EditorData) => void;
  validator: (text: string) => RepliesValidatorError[];
  setIsFormattingToolbarVisible: (show: boolean) => void;
  boostOptions: BoostOptions;
};

const messages = defineMessages({
  mentionsButtonLabel: {
    defaultMessage: 'Mention someone',
    id: 'VUJxrl',
  },
  boostButtonLabel: {
    defaultMessage: 'Give someone a {currency} boost',
    id: '7b+q7o',
  },
  addEmoticonButtonLabel: {
    defaultMessage: 'Emoji',
    id: '34NuzG',
  },
  addGifButtonLabel: {
    defaultMessage: 'Add a GIF',
    id: '3SDxZ9',
  },
  toggleFormattingToolbarLabel: {
    defaultMessage:
      '{formatting, select, true {Hide} false {Show} other {Show}} formatting toolbar',
    id: 'rhwxFZ',
  },
  replyButtonLabel: {
    defaultMessage: 'Reply Button',
    id: '9xyhVm',
  },
  shiftEnterLabel: {
    defaultMessage: 'Shift + Return for a line break',
    id: 'uXBahx',
  },
  cannotGiveBoostAnonymously: {
    defaultMessage: 'You cannot give a boost anonymously',
    id: 'eWcjxh',
  },
  boostNotAllowed: {
    defaultMessage: 'You are out of {currency} to give for this month',
    id: 'lMQcko',
  },
  pointsExhausted: {
    defaultMessage: 'Max limit of {currency} reached to give in this reply',
    id: 'vy1FNc',
  },
});

function PostReplyButton({
  postButtonTooltipText,
  disablePostButton,
  disabled,
  handleReplyClick,
  hasText,
}: Pick<
  RepliesFooterPluginProps,
  'postButtonTooltipText' | 'disablePostButton' | 'disabled'
> & {
  hasText: boolean;
  handleReplyClick: () => void;
}) {
  const { formatMessage } = useIntl();
  if (postButtonTooltipText) {
    return (
      <Tooltip tooltipText={postButtonTooltipText}>
        <Button
          variation="primary"
          onClick={handleReplyClick}
          className="h-10 w-10 !p-2"
          disabled={true}
          aria-label={postButtonTooltipText}
        >
          <PaperAirplaneIcon className="h-6 w-6" />
        </Button>
      </Tooltip>
    );
  }
  return (
    <Button
      variation="primary"
      onClick={handleReplyClick}
      className="h-10 w-10 !p-2"
      disabled={disablePostButton ?? disabled ?? !hasText}
      aria-label={formatMessage(messages.replyButtonLabel)}
    >
      <PaperAirplaneIcon className="h-6 w-6" />
    </Button>
  );
}

function EditPostButtons({
  handleCancelClick,
  handleReplyClick,
}: {
  handleReplyClick: () => void;
  handleCancelClick: () => void;
}) {
  const { formatMessage } = useIntl();
  return (
    <div className="flex gap-2">
      <Button
        variation="danger"
        onClick={handleCancelClick}
        className="h-10 w-10 !p-2"
        aria-label={formatMessage(messages.replyButtonLabel)}
      >
        <XMarkIcon className="h-6 w-6" />
      </Button>
      <Button
        variation="success"
        onClick={handleReplyClick}
        className="h-10 w-10 bg-success-7 !p-2 focus:bg-success-8 enabled:hover:bg-success-8"
        aria-label={formatMessage(messages.replyButtonLabel)}
      >
        <CheckIcon className="h-6 w-6 stroke-gray-1" />
      </Button>
    </div>
  );
}

export function RepliesFooterPlugin({
  disabled,
  currency,
  gifRating,
  isEditing,
  validator,
  giphyAPIKey,
  onReplyClick,
  onCancelClick,
  isAnonymousReply,
  disablePostButton,
  hideToolbarButtons,
  postButtonTooltipText,
  isFormattingToolbarVisible,
  setIsFormattingToolbarVisible,
  boostOptions,
}: Readonly<RepliesFooterPluginProps>) {
  const [editor] = useLexicalComposerContext();
  const { formatMessage } = useIntl();

  const [hasText, setHasText] = useState(false);
  const [showEmojiPicker, setShowEmojiPicker] = useState(false);
  const [showGIFPicker, setShowGIFPicker] = useState(false);
  const [boostExhausted, setBoostExhausted] = useState(false);

  const { deviceType } = useDeviceInfo();

  const handleOnMentionsButtonClick = () => {
    editor.update(() => {
      $insertNodes(getSelectedNodes());
    });
  };

  const handleOnBoostMemberButtonClick = () => {
    editor.update(() => {
      $insertNodes(getSelectedNodes());
      editor.dispatchCommand(BOOST_CLICK_COMMAND, null);
    });
  };

  const handleReplyClick = () => {
    editor.update(() => {
      const {
        html,
        json,
        mentionIds,
        plainText,
        gifUrls,
        linkUrls,
        boostedUsers,
      } = getSerializedDataFromEditor(editor);

      const errors = validator(plainText);
      if (errors.length === 0) {
        resetEditor(editor);
      }
      onReplyClick({
        json,
        html,
        plainText,
        mentionIds,
        errors,
        gifUrls,
        linkUrls,
        boost: boostedUsers,
      });
    });
  };

  const handleOnEmojiSelect = (emoji: BaseEmoji) => {
    setShowEmojiPicker(false);
    editor.update(() => {
      const textNode = $createTextNode(emoji.native);
      $insertNodes([textNode]);
    });
  };

  const handleOnGifClick = (gif: IGif) => {
    editor.dispatchCommand(INSERT_IMAGE_COMMAND, {
      altText: gif.title || gif.id.toString(),
      src: gif.images.fixed_height.url,
      height: gif.images.fixed_height.height,
      width: gif.images.fixed_height.width,
      maxWidth: 300,
    });
    setShowGIFPicker(false);
  };

  const handleCancelClick = () => {
    resetEditor(editor);
    onCancelClick();
  };

  const isBoostButtonDisabled = useMemo(() => {
    return (
      Boolean(isAnonymousReply) ||
      Boolean(disabled) ||
      boostOptions.disableBoost ||
      boostExhausted
    );
  }, [boostExhausted, boostOptions.disableBoost, disabled, isAnonymousReply]);

  const disabledBoostButtonTooltip = useMemo(() => {
    if (boostOptions.disableBoost && boostOptions.disableBoostReason) {
      return boostOptions.disableBoostReason;
    }

    if (isAnonymousReply) {
      return formatMessage(messages.cannotGiveBoostAnonymously);
    }

    if (boostExhausted) {
      return formatMessage(messages.pointsExhausted, {
        currency: currency?.pluralName,
      });
    }

    return formatMessage(messages.boostNotAllowed, {
      currency: currency?.pluralName,
    });
  }, [
    boostExhausted,
    boostOptions.disableBoost,
    boostOptions.disableBoostReason,
    currency,
    formatMessage,
    isAnonymousReply,
  ]);

  useEffect(() => {
    return mergeRegister(
      editor.registerUpdateListener(({ editorState }) => {
        const stringifiedEditorState = JSON.stringify(editorState.toJSON());
        const parsedEditorState = editor.parseEditorState(
          stringifiedEditorState
        );
        const editorStateTextString = parsedEditorState.read(() =>
          $getRoot().getTextContent()
        );

        setHasText(editorStateTextString.length > 0);
      }),
      editor.registerCommand(
        POINTS_EXHAUSTED_COMMAND,
        ({ exhausted }) => {
          setBoostExhausted(exhausted);

          return true;
        },
        COMMAND_PRIORITY_EDITOR
      )
    );
  }, [editor]);

  const currencyIcon = currency ? (
    currency.type === AssemblyCurrencyType.Custom ? (
      <img alt={currency.name} className="h-4 w-4" src={currency.value} />
    ) : (
      mapHexCodeToEmoticon(currency.value)
    )
  ) : null;

  const mentionButtonLabel = formatMessage(messages.mentionsButtonLabel);
  const boostButtonLabel = formatMessage(messages.boostButtonLabel, {
    currency: currency?.name,
  });
  const emojiButtonLabel = formatMessage(messages.addEmoticonButtonLabel);
  const gifButtonLabel = formatMessage(messages.addGifButtonLabel);
  const formattingToolbarLabel = formatMessage(
    messages.toggleFormattingToolbarLabel,
    {
      formatting: isFormattingToolbarVisible,
    }
  );

  const canShowShiftEnterLabel =
    !disabled && !hideToolbarButtons && !isEditing && deviceType !== 'mobile';

  return (
    <div
      className={twJoin(
        'flex flex-shrink-0 items-center justify-between',
        disabled && 'bg-gray-2'
      )}
    >
      <div>
        {Boolean(canShowShiftEnterLabel) && (
          <TextStyle
            variant="xs-regular"
            className="pointer-events-none ml-2 mt-3 select-none text-gray-8"
          >
            {formatMessage(messages.shiftEnterLabel)}
          </TextStyle>
        )}
      </div>
      <div className="flex justify-end gap-x-2 px-1 py-1">
        {!hideToolbarButtons && (
          <>
            <Tooltip tooltipText={mentionButtonLabel}>
              <Button
                disabled={disabled}
                variation="tertiaryLite"
                className="h-10 w-10 !p-2"
                onClick={handleOnMentionsButtonClick}
                aria-label={mentionButtonLabel}
              >
                <AtSymbolIcon className="h-6 w-6 text-gray-8" />
              </Button>
            </Tooltip>
            {!boostOptions.hideBoost && (
              <Tooltip
                tooltipText={
                  isBoostButtonDisabled
                    ? disabledBoostButtonTooltip
                    : boostButtonLabel
                }
              >
                <Button
                  disabled={isBoostButtonDisabled}
                  variation="tertiaryLite"
                  className="h-10 w-10 !p-2"
                  onClick={handleOnBoostMemberButtonClick}
                  aria-label={boostButtonLabel}
                >
                  <div className="relative h-full w-full">
                    <TextStyle
                      className={
                        isBoostButtonDisabled ? 'grayscale' : undefined
                      }
                    >
                      {currencyIcon}
                    </TextStyle>
                    <img
                      className="absolute bottom-0.5 right-0.5 h-2.5 w-2.5"
                      src={BoostPlusIcon}
                      alt=""
                    />
                  </div>
                </Button>
              </Tooltip>
            )}
            <Popover
              disabled={disabled}
              aria-label={emojiButtonLabel}
              onTriggerButtonClick={() => {
                setShowEmojiPicker(!showEmojiPicker);
              }}
              trigger={
                <Tooltip tooltipText={emojiButtonLabel}>
                  <div
                    role="presentation"
                    className="flex h-10 w-10 items-center justify-center rounded-lg hover:bg-gray-3"
                  >
                    <FaceSmileIcon className="h-6 w-6 text-gray-8" />
                  </div>
                </Tooltip>
              }
              open={showEmojiPicker}
              onOpenChange={setShowEmojiPicker}
            >
              <Picker onClick={handleOnEmojiSelect} />
            </Popover>
            <Popover
              aria-label={gifButtonLabel}
              onTriggerButtonClick={() => {
                setShowGIFPicker(!showGIFPicker);
              }}
              trigger={
                <Tooltip tooltipText={gifButtonLabel}>
                  <div
                    role="presentation"
                    className="flex h-10 w-10 items-center justify-center rounded-lg hover:bg-gray-3"
                  >
                    <GifIcon className="h-6 w-6 text-gray-8" />
                  </div>
                </Tooltip>
              }
              open={showGIFPicker}
              onOpenChange={setShowGIFPicker}
            >
              <GiphySelect
                onGifClick={handleOnGifClick}
                rating={gifRating}
                apiKey={giphyAPIKey}
              />
            </Popover>
            <Tooltip tooltipText={formattingToolbarLabel}>
              <ToggleButton
                aria-label={formatMessage(
                  messages.toggleFormattingToolbarLabel,
                  {
                    formatting: isFormattingToolbarVisible,
                  }
                )}
                disabled={disabled ?? editor.getEditorState().isEmpty()}
                onPressedChange={(pressed) => {
                  setIsFormattingToolbarVisible(pressed);
                }}
                className={twMerge(
                  'h-10 w-10 rounded-lg !p-2 hover:bg-gray-3',
                  isFormattingToolbarVisible && 'bg-gray-4'
                )}
              >
                <img className="h-6 w-6" src={FormattingIcon} alt="" />
              </ToggleButton>
            </Tooltip>
          </>
        )}
        {isEditing ? (
          <EditPostButtons
            handleReplyClick={handleReplyClick}
            handleCancelClick={handleCancelClick}
          />
        ) : (
          <PostReplyButton
            hasText={hasText}
            disabled={hideToolbarButtons}
            handleReplyClick={handleReplyClick}
            disablePostButton={disablePostButton}
            postButtonTooltipText={postButtonTooltipText}
          />
        )}
      </div>
    </div>
  );
}
