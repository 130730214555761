import type { ReactNode } from 'react';
import { Link } from 'react-router-dom';

export type AppLinkProps = {
  to: string;
  external?: boolean;
  testId?: string;

  children: ReactNode;
  onClick?: () => void;
  openInNewTab?: boolean;
};

export const AppLink = (props: AppLinkProps) => {
  if (props.external) {
    return (
      <a
        href={props.to}
        className="text-primary-6 underline hover:text-primary-5"
        data-test-id={props.testId}
        {...(props.onClick ? { onClick: props.onClick } : {})}
        target={props.openInNewTab ? '_blank' : '_self'}
        rel="noopener noreferrer"
      >
        {props.children}
      </a>
    );
  }

  return (
    <Link
      className="text-primary-6 underline hover:text-primary-5"
      to={props.to}
      data-testid={props.testId}
      {...(props.onClick ? { onClick: props.onClick } : {})}
    >
      {props.children}
    </Link>
  );
};
