import { type AppMessage, AppTargetEvent } from './types/mobile';

export const getMobilePlatformFromURL = () => {
  const url = new URL(window.location.href);

  if (url.searchParams.has('mobilePlatform')) {
    const provider = url.searchParams.get('mobilePlatform');
    if (provider === 'ios' || provider === 'android') {
      return provider;
    }
  }

  return null;
};

function postToMobile(payload: string) {
  if (window.ReactNativeWebView) {
    window.ReactNativeWebView.postMessage(payload);
  }
}

export function postMessageToMobileApp(payload: AppMessage | string): void;

export function postMessageToMobileApp(payload: AppMessage | string) {
  if (typeof payload === 'string') {
    postToMobile(payload);
    return;
  }

  const message = JSON.stringify({
    ...payload,
    targetEvent: AppTargetEvent.Mobile,
  });

  postToMobile(message);
}

export type MobilePlatform = ReturnType<typeof getMobilePlatformFromURL>;
