import {
  CheckCircleIcon,
  InformationCircleIcon,
  XMarkIcon,
} from '@heroicons/react/20/solid';
import { Root as Portal } from '@radix-ui/react-portal';
import { Action, Provider, Root, Title, Viewport } from '@radix-ui/react-toast';
import { twMerge } from 'tailwind-merge';

import { useToastStore } from './useToastStore';

export type ToastKind = 'info' | 'success' | 'error';

type ToastProps = {
  message: string;
  kind: ToastKind;
  onCloseToastClick: () => void;
};

export function ToastContainer() {
  const { kind, message, isToastOpen, hideToast } = useToastStore();

  return isToastOpen ? (
    <Toast kind={kind} message={message} onCloseToastClick={hideToast} />
  ) : null;
}

export const Toast = (props: ToastProps) => {
  const { onCloseToastClick, kind, message } = props;

  if (!message) return null;

  return (
    <Provider swipeDirection="left">
      <Root
        className={twMerge(
          "grid grid-cols-[auto_max-content] items-center gap-x-[15px] rounded-md px-[8px] pb-[16px] pt-[12px] shadow-md-down [grid-template-areas:_'title_action'_'description_action'] data-[swipe=cancel]:translate-x-0 data-[swipe=move]:translate-x-[var(--radix-toast-swipe-move-x)] data-[state=closed]:animate-hide data-[state=open]:animate-slideIn data-[swipe=end]:animate-swipeOut data-[swipe=cancel]:transition-[transform_200ms_ease-out]",

          kind === 'info'
            ? 'bg-blue-7'
            : kind === 'success'
              ? 'bg-success-7'
              : 'bg-error-7'
        )}
        open
      >
        <Title className="flex align-middle text-[15px] font-normal text-gray-1 [grid-area:_title]">
          {kind === 'success' ? (
            <CheckCircleIcon
              className="mr-[8px] h-5 w-5 text-gray-1"
              aria-hidden="true"
            />
          ) : (
            <InformationCircleIcon
              className="mr-[8px] h-5 w-5 text-gray-1"
              aria-hidden="true"
            />
          )}
          {message}
        </Title>
        <Action className="[grid-area:_action]" asChild altText="Close">
          <button className="ml-auto text-gray-9" onClick={onCloseToastClick}>
            <XMarkIcon className="h-5 w-5 text-gray-1" aria-hidden="true" />
          </button>
        </Action>
      </Root>
      <Portal>
        <Viewport className="fixed bottom-0 left-0 z-[2147483647] m-0 flex w-auto list-none flex-col gap-[8px] p-[var(--viewport-padding)] outline-none [--viewport-padding:_25px]" />
      </Portal>
    </Provider>
  );
};
