import { Transition } from '@headlessui/react';
import { XMarkIcon } from '@heroicons/react/24/outline';
import {
  Content,
  Description,
  Overlay,
  Portal,
  Root,
  Title,
} from '@radix-ui/react-dialog';
import { Fragment, type ReactNode } from 'react';
import { defineMessages, useIntl } from 'react-intl';
import { twMerge } from 'tailwind-merge';

import { LoadingSpinner } from '../../../DesignSystem/Feedback/Icons/LoadingSpinner';
import type { ModalProps } from '../../../DesignSystem/Feedback/Modal';
import { TextStyle } from '../../../DesignSystem/Feedback/TextStyle';
import { Button } from '../../../DesignSystem/Inputs/Button';
import { IconButton } from '../../../DesignSystem/Inputs/IconButton';
import type { CustomRule } from './SearchableRulesDropdown';

const messages = defineMessages({
  getHelp: {
    defaultMessage: 'Get help',
    id: '9OuUCL',
  },
  copyLinkToShare: {
    defaultMessage: 'Copy link to share',
    id: 'gln82z',
  },
  done: {
    defaultMessage: 'Done',
    id: 'JXdbo8',
  },
  shareCollection: {
    defaultMessage: 'Share Collection',
    id: 'xj+IYa',
  },
  close: {
    defaultMessage: 'Close',
    id: 'rbrahO',
  },
});

export type CustomRuleWithType = {
  rule: CustomRule;
  type: 'searchableDropdown' | 'booleanDropdown';
};

export function ShareModal({
  children,
  isOpen,
  onClose,
  onDoneAction,
  isSaveInProgress,
  onGetHelpClick,
  isSubmitDisabled,
  showCTASection,
}: {
  isOpen: boolean;
  onClose: () => void;
  onDoneAction: () => void;
  onGetHelpClick: () => void;
  isSaveInProgress?: boolean;
  children: ReactNode;
  isSubmitDisabled?: boolean;
  showCTASection: boolean;
}) {
  const { formatMessage } = useIntl();

  return (
    <RadixModal
      isOpen={isOpen}
      onClose={onClose}
      className="pb-0 max-sm:w-[90%] sm:max-w-[640px]"
      title=""
      ctaSection={
        showCTASection ? (
          <div className="inline-flex w-full items-center justify-between bg-gray-1">
            <Button onClick={onGetHelpClick} variation="tertiaryLite">
              {formatMessage(messages.getHelp)}
            </Button>
            <div className="flex items-start justify-start gap-2">
              <Button
                isLoading={isSaveInProgress}
                onClick={onDoneAction}
                variation="primary"
                disabled={isSubmitDisabled}
              >
                {Boolean(isSaveInProgress) && (
                  <LoadingSpinner className="text-gray-1 dark:text-primary-9" />
                )}
                {formatMessage(messages.done)}
              </Button>
            </div>
          </div>
        ) : undefined
      }
      ctaSectionClassName="p-4 border-t border-gray-4"
    >
      {children}
    </RadixModal>
  );
}

function RadixModal(props: ModalProps) {
  const { formatMessage } = useIntl();

  return (
    <Root
      open={props.isOpen}
      onOpenChange={(val) => {
        if (!val) props.onClose();
      }}
    >
      <Portal>
        <Transition.Root show={props.isOpen}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Overlay
              forceMount
              className="fixed inset-0 z-20 bg-neutral-secondary bg-opacity-75 transition-opacity"
            />
          </Transition.Child>

          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
          >
            <Content
              forceMount
              className={twMerge(
                'fixed z-20',
                'left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2',
                'rounded-md bg-gray-1 text-left shadow-xl-down transition-all sm:my-8 sm:w-full sm:max-w-lg',
                props.className
              )}
              aria-label={formatMessage(messages.shareCollection)}
            >
              <Title className="pt-6">
                <div className="sr-only">
                  {formatMessage(messages.shareCollection)}
                </div>
              </Title>
              <Description className="sr-only" />

              <div className="absolute right-0 top-0 pr-6 pt-6">
                <IconButton
                  onClick={props.onClose}
                  variation="tertiaryLite"
                  size="xSmall"
                  data-testid="modalCloseButton"
                >
                  <span className="sr-only">
                    {formatMessage(messages.close)}
                  </span>
                  <XMarkIcon className="h-4 w-4" aria-hidden="true" />
                </IconButton>
              </div>
              <TextStyle variant="lg-medium" className="pr-8 text-gray-9">
                {props.title}
                <div className="sr-only">
                  {formatMessage(messages.shareCollection)}
                </div>
              </TextStyle>

              <div
                className={twMerge(
                  'px-6 pt-1',
                  !props.ctaSection && 'py-6',
                  props.ctaSection && 'pt-2'
                )}
              >
                <div className="text-gray-9">{props.children}</div>
              </div>
              {Boolean(props.ctaSection) && (
                <div className={twMerge('p-6', props.ctaSectionClassName)}>
                  {props.ctaSection}
                </div>
              )}
            </Content>
          </Transition.Child>
        </Transition.Root>
      </Portal>
    </Root>
  );
}
