import { twMerge } from 'tailwind-merge';

import { NoRewardsFound as NoResultsFound } from '../../assets/images';

type EmptySearchStateProps = {
  title: string;
  description: string;
  containerClassName?: string;
  textBodyClassName?: string;
};
export const EmptySearchState = (props: EmptySearchStateProps) => {
  const { title, description, containerClassName, textBodyClassName } = props;
  return (
    <div
      className={twMerge(
        'flex w-full flex-col items-center justify-center rounded-md border-2 border-dashed border-gray-5 bg-gray-1 bg-opacity-50 p-8',
        containerClassName
      )}
    >
      <div>
        <img src={NoResultsFound} className="mb-4" alt="No results found" />
      </div>
      <div className={twMerge('text-center', textBodyClassName)}>
        <h2 className="mb-1 text-base font-medium text-gray-9">{title}</h2>
        <p className="mb-4 w-full max-w-[768px] text-sm text-gray-8">
          {description}
        </p>
      </div>
    </div>
  );
};
