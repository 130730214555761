import { copyToClipboard } from '@assembly-web/services';
import {
  ArrowTopRightOnSquareIcon,
  PencilIcon,
  Square2StackIcon,
  TrashIcon,
} from '@heroicons/react/24/outline';
import type React from 'react';
import { forwardRef, useState } from 'react';
import { defineMessages, useIntl } from 'react-intl';

import { useRefContainer } from '../../../../../context/RefContext';
import { TextStyle } from '../../../../../DesignSystem/Feedback/TextStyle';
import { Tooltip } from '../../../../../DesignSystem/Feedback/Tooltip';
import { LinkBrokenIcon } from '../../../../assets/icons';
import { Popover } from '../../../../Shared/Popover';
import type { ToastKind } from '../../../../Shared/Toast/Toast';
import { AddLinkModal } from './AddLinkModal';
import { ToggleButton } from './ToggleButton';

type LinkFloatingToolbarProps = {
  link: string;
  title: string;
  canRemoveLink: boolean;
  onDelete: () => void;
  onRemoveLink: () => void;
  onEdit: (args: { url: string; title: string }) => void;
  showToast?: (kind: ToastKind, message: string) => void;
};

type Nullable<T> = {
  [K in keyof T]: T[K] | null;
};

const messages = defineMessages({
  goToLink: {
    defaultMessage: 'Go to link',
    id: '+ve/6Q',
  },
  copyLink: {
    defaultMessage: 'Copy',
    id: '4l6vz1',
  },
  copySuccess: {
    defaultMessage: 'Link copied to clipboard',
    id: '2yCGR2',
  },
  editLink: {
    defaultMessage: 'Edit',
    id: 'wEQDC6',
  },
  unlink: {
    defaultMessage: 'Unlink',
    id: '4PKxPs',
  },
  delete: {
    defaultMessage: 'Delete',
    id: 'K3r6DQ',
  },
});

const LinkFloatingToolbarImpl = ({
  canRemoveLink,
  link,
  onEdit,
  onDelete,
  onRemoveLink,
  title,
  showToast,
}: LinkFloatingToolbarProps) => {
  const [showEditLinkPopover, setShowEditLinkPopover] =
    useState<boolean>(false);

  const containerRef = useRefContainer();

  const { formatMessage } = useIntl();

  const onOpenLinkButtonClick = () => {
    window.open(link, '_blank', 'noopener,noreferrer');
  };

  const copyLinkToClipboardButtonClick = async () => {
    await copyToClipboard(link);
    showToast?.('success', formatMessage(messages.copySuccess));
  };

  return (
    <>
      <TextStyle
        variant="xs-regular"
        className="min-w-0 flex-1 truncate text-ellipsis pl-1 text-gray-8"
      >
        {link}
      </TextStyle>
      <Tooltip tooltipText={formatMessage(messages.goToLink)}>
        <ToggleButton
          onClick={onOpenLinkButtonClick}
          className="h-6 w-6 rounded-lg !p-0 hover:bg-gray-3"
        >
          <ArrowTopRightOnSquareIcon className="h-4 w-4 stroke-2 text-gray-8" />
        </ToggleButton>
      </Tooltip>
      <Tooltip tooltipText={formatMessage(messages.copyLink)}>
        <ToggleButton
          onClick={copyLinkToClipboardButtonClick}
          className="h-6 w-6 rounded-lg !p-0 hover:bg-gray-3"
        >
          <Square2StackIcon className="h-4 w-4 stroke-2 text-gray-8" />
        </ToggleButton>
      </Tooltip>
      <Popover
        open={showEditLinkPopover}
        onOpenChange={setShowEditLinkPopover}
        onTriggerButtonClick={() => {
          setShowEditLinkPopover((prev) => !prev);
        }}
        tooltipTextClassName="text-center"
        aria-label={formatMessage(messages.editLink)}
        trigger={
          <Tooltip tooltipText={formatMessage(messages.editLink)}>
            <ToggleButton className="h-6 w-6 rounded-lg !p-0 hover:bg-gray-3">
              <PencilIcon className="h-4 w-4 stroke-2 text-gray-8" />
            </ToggleButton>
          </Tooltip>
        }
        container={containerRef}
      >
        <AddLinkModal
          onSubmit={(args) => {
            onEdit(args);
            setShowEditLinkPopover(false);
          }}
          selectedURL={link}
          selectedText={title}
        />
      </Popover>
      <Tooltip tooltipText={formatMessage(messages.unlink)}>
        <ToggleButton
          disabled={!canRemoveLink}
          onClick={onRemoveLink}
          className="h-6 w-6 rounded-lg !p-0 hover:bg-gray-3"
        >
          <img className="h-4 w-4" src={LinkBrokenIcon} alt="" />
        </ToggleButton>
      </Tooltip>
      <div className="h-6 border border-l-0 border-gray-5" />
      <Tooltip tooltipText={formatMessage(messages.delete)}>
        <ToggleButton
          onClick={onDelete}
          className="h-6 w-6 rounded-lg !p-0 hover:bg-gray-3"
        >
          <TrashIcon className="h-4 w-4 stroke-2 text-error-5" />
        </ToggleButton>
      </Tooltip>
    </>
  );
};

export const LinkFloatingToolbar = forwardRef<
  HTMLDivElement,
  Omit<LinkFloatingToolbarProps, 'link' | 'title'> &
    Pick<Nullable<LinkFloatingToolbarProps>, 'link' | 'title'> & {
      style?: React.CSSProperties;
    }
>(function LinkFloatingToolbar({ link, title, style, ...tail }, ref) {
  if (!link || !title) {
    return null;
  }

  return (
    <section
      ref={ref}
      style={style}
      className="flex w-[316px] items-center gap-1 rounded-lg border border-gray-5 bg-gray-1 p-1 shadow-elevation-2dp"
      data-floating-toolbar="link"
    >
      <LinkFloatingToolbarImpl link={link} title={title} {...tail} />
    </section>
  );
});
