import { defineMessages, useIntl } from 'react-intl';

import { Tooltip } from '../../../DesignSystem/Feedback/Tooltip';
import { Checkbox } from '../../../DesignSystem/Inputs/Checkbox';
import { PrimeImage } from '../../assets/images';

const messages = defineMessages({
  prime: {
    defaultMessage: 'Prime',
    id: 'tikV7e',
  },
  primeNotAvailable: {
    defaultMessage:
      'These items are not Prime-eligible. Try searching for a different item.',
    id: 'T1rdKP',
  },
});

export function PrimeFilter({
  checked,
  isDisabled,
  onPrimeFilterChange,
}: {
  checked: boolean;
  isDisabled: boolean;
  onPrimeFilterChange: (checked: boolean) => void;
}) {
  const { formatMessage } = useIntl();
  return (
    <Tooltip
      tooltipText={
        isDisabled ? formatMessage(messages.primeNotAvailable) : undefined
      }
    >
      <div className="flex items-center gap-2 rounded-lg border-[0.5px] border-gray-7 bg-gray-3 px-2 py-2">
        <Checkbox
          checked={checked}
          aria-label={formatMessage(messages.prime)}
          onChange={(e) => {
            onPrimeFilterChange(e.target.checked);
          }}
          disabled={isDisabled}
        />
        <img src={PrimeImage} alt={formatMessage(messages.prime)} />
      </div>
    </Tooltip>
  );
}
