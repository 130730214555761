import type { SlotProps } from '@radix-ui/react-slot';
import { Slot } from '@radix-ui/react-slot';
import { forwardRef } from 'react';
import { twJoin } from 'tailwind-merge';

export const Loader = forwardRef<
  HTMLDivElement,
  SlotProps & { asChild?: boolean }
>(function Loader({ asChild, className = 'w-full h-full ', ...rest }, ref) {
  const Comp = asChild ? Slot : 'div';
  return (
    <Comp
      ref={ref}
      className={twJoin(
        'animate-pulse rounded-lg bg-skeleton-gradient',
        className
      )}
      {...rest}
    />
  );
});
