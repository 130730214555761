import type { Timeout } from '@assembly-web/services';
import type { DropdownMenuContentProps } from '@radix-ui/react-dropdown-menu';
import { useCallback, useEffect, useMemo, useRef } from 'react';

type Handlers = {
  onClick?: (e: React.MouseEvent<HTMLButtonElement>) => void;
  onPointerDown?: (e: React.PointerEvent<HTMLButtonElement>) => void;
};

export type GetTriggerProps = (args?: Partial<Handlers>) => Required<Handlers>;

type RadixProps = Pick<DropdownMenuContentProps, 'onInteractOutside'>;
export type GetContentProps = (
  args?: Partial<RadixProps>
) => Required<RadixProps>;

export const useDropdownHandler = () => {
  const isClosed = useRef(false);
  const timerRef = useRef<Timeout | undefined>(undefined);

  useEffect(() => {
    return () => {
      clearTimeout(timerRef.current);
    };
  }, []);

  const getTriggerProps: GetTriggerProps = useCallback((args) => {
    return {
      onPointerDown: (e) => {
        e.preventDefault();
        args?.onPointerDown?.(e);
      },
      onClick: (e) => {
        if (isClosed.current) {
          isClosed.current = false;
          return;
        }
        args?.onClick?.(e);
      },
    };
  }, []);

  const getContentProps: GetContentProps = useCallback((args) => {
    return {
      onInteractOutside: (e) => {
        isClosed.current = true;
        timerRef.current = setTimeout(() => {
          isClosed.current = false;
        }, 500);

        args?.onInteractOutside?.(e);
      },
    };
  }, []);

  return useMemo(
    () => ({
      getTriggerProps,
      getContentProps,
    }),
    [getContentProps, getTriggerProps]
  );
};
