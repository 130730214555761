import { type ReactNode, useState } from 'react';
import { defineMessages, useIntl } from 'react-intl';

import { Modal } from '../../../../DesignSystem/Feedback/Modal';
import { TextStyle } from '../../../../DesignSystem/Feedback/TextStyle';
import { Button } from '../../../../DesignSystem/Inputs/Button';
import { Checkbox } from '../../../../DesignSystem/Inputs/Checkbox';
import { G2Review } from '../../../assets/images';

const messages = defineMessages({
  title: {
    defaultMessage: 'Thank you!',
    id: 'aYIUar',
  },
  desc: {
    defaultMessage:
      'We’d love to hear more about your experience using Assembly.',
    id: '8N8vGI',
  },
  leaveReview: {
    defaultMessage:
      'Leave us a 5-star review on G2 and {br} receive a $25 Amazon gift card.',
    id: 'gziz3S',
  },
  leaveReviewSubText: {
    defaultMessage: 'Only one review per user.',
    id: 'csslu6',
  },
  confirm: {
    defaultMessage: 'Yes, take me to G2',
    id: '80wQFT',
  },
  no: {
    defaultMessage: 'No thanks',
    id: 'c+JYNI',
  },
  askReview: {
    defaultMessage: 'Don’t ask me again',
    id: '+xRY6y',
  },
  noteTitle: {
    defaultMessage: 'How to Get Your Gift Card:',
    id: 'FrtXrX',
  },
  noteDesc: {
    defaultMessage:
      '<ol><li>After you post your review, send the review link to <a>reviews@joinassembly.com.</a></li> <li>We will verify your review and send your gift card upon confirmation. {br} <i>NOTE: You must use the same email as your email in Assembly. Please allow at least 1 week for delivery</i></li></ol>',
    id: 'bY4qyb',
  },
});

export type RatingSuccessModalProps = {
  isOpen: boolean;
  onClose: (reminderValue: boolean) => void;
  navigateToG2Review: () => void;
};

export function RatingSuccessModal({
  isOpen,
  onClose,
  navigateToG2Review,
}: RatingSuccessModalProps) {
  const { formatMessage } = useIntl();

  const [reviewReminder, setReviewReminder] = useState(false);

  return (
    <Modal
      isOpen={isOpen}
      onClose={() => {
        onClose(reviewReminder);
      }}
      className="flex max-h-[610px] flex-col sm:max-w-[33rem]"
      headerClassName="px-4 pt-4 pb-0 capitalize text-center"
      bodyClassName="flex flex-col gap-4 flex-1 px-4 pb-2"
      title={formatMessage(messages.title)}
    >
      <div className="flex flex-col items-center gap-4 pt-4">
        <TextStyle className="text-center" variant="lg-regular">
          {formatMessage(messages.desc)}
        </TextStyle>
        <div className="flex w-full flex-col items-center gap-2 rounded-lg bg-gray-3 p-2">
          <img
            alt="review"
            className="h-[35px] w-[44px] origin-top-left"
            src={G2Review}
          />
          <div>
            <TextStyle className="text-center" variant="base-bold">
              {formatMessage(messages.leaveReview, {
                br: <br />,
              })}
            </TextStyle>
            <TextStyle className="text-center" variant="sm-regular">
              {formatMessage(messages.leaveReviewSubText)}
            </TextStyle>
          </div>
        </div>

        <div className="flex w-full flex-col items-center gap-4">
          <Button
            variation="primary"
            className="w-full"
            onClick={() => {
              navigateToG2Review();
            }}
          >
            {formatMessage(messages.confirm)}
          </Button>
          <Button
            variation="secondaryEmphasized"
            className="w-full"
            onClick={() => onClose(reviewReminder)}
          >
            {formatMessage(messages.no)}
          </Button>
        </div>
        <div className="flex w-full items-center">
          <Checkbox
            className="mr-2"
            id="reminder"
            checked={reviewReminder}
            onChange={(e) => {
              setReviewReminder(e.target.checked);
            }}
          />
          <label
            htmlFor="reminder"
            className="cursor-pointer text-sm text-gray-9"
          >
            <TextStyle variant="sm-regular">
              {formatMessage(messages.askReview)}
            </TextStyle>
          </label>
        </div>
        <hr className="w-full border-t border-gray-5" />
        <div className="rounded-lg bg-primary-1 px-2 py-1">
          <TextStyle variant="xs-bold" className="w-full">
            {formatMessage(messages.noteTitle)}
          </TextStyle>
          <TextStyle variant="xs-regular">
            {formatMessage(messages.noteDesc, {
              a: (text: ReactNode) => (
                <a
                  className="text-primary-6 underline"
                  href={'mailto:reviews@joinassembly.com'}
                  target="_blank"
                  rel="noreferrer"
                >
                  {text}
                </a>
              ),
              br: <br />,
              ol: (chunks: ReactNode) => (
                <ol className="list-inside list-decimal">{chunks}</ol>
              ),
              li: (text: ReactNode) => <li>{text}</li>,
              i: (text: ReactNode) => (
                <span className="inline-block pl-3">
                  <i>{text}</i>
                </span>
              ),
            })}
          </TextStyle>
        </div>
      </div>
    </Modal>
  );
}
