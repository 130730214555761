import { create } from 'zustand';

import type { ToastKind } from './Toast';

type ToastStore = {
  message: string;
  kind: ToastKind;
  isToastOpen: boolean;
  hideToast: () => void;
  showInfoToast: (message: string) => void;
  showErrorToast: (message: string) => void;
  showSuccessToast: (message: string) => void;
  showToast: (kind: ToastKind, message: string) => void;
};

export const useToastStore = create<ToastStore>((set, get) => ({
  message: '',
  kind: 'success',
  isToastOpen: false,
  hideToast: () => {
    set(() => ({ isToastOpen: false }));
  },
  showToast: (kind: ToastKind, message: string) =>
    set(() => {
      setTimeout(() => {
        get().hideToast();
      }, 5000);
      return { isToastOpen: true, kind, message };
    }),
  showSuccessToast: (message: string) => {
    get().showToast('success', message);
  },
  showErrorToast: (message: string) => {
    get().showToast('error', message);
  },
  showInfoToast: (message: string) => {
    get().showToast('info', message);
  },
}));
