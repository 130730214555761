import { forwardRef, type Key, type ReactNode } from 'react';
import {
  Collection,
  ComboBox,
  Header,
  ListBox,
  ListBoxItem,
  Popover,
  Section,
} from 'react-aria-components';
import { RemoveScroll } from 'react-remove-scroll';
import { twMerge } from 'tailwind-merge';

import type { TextFieldProps } from '../../../DesignSystem/Inputs/TextField';
import { TextField } from '../../../DesignSystem/Inputs/TextField';
import { classNames } from '../../../DesignSystem/Utils/classNames';

type SuggestionOption = {
  displayValue: ReactNode;
  id: string;
  textValue: string;
};

type SuggestionSection = {
  id: string;
  header?: ReactNode;
  options: SuggestionOption[];
};

export type AutocompleteSearchFieldProps = TextFieldProps & {
  isOpen: boolean;
  onSelectionChange: (key: Key | null) => void;
  suggestions: SuggestionSection[];
  popoverProps?: {
    className?: string;
    lockBodyScroll?: boolean;
    ref?: React.ForwardedRef<HTMLDivElement>;
  };
};

export const AutocompleteSearchField = forwardRef<
  HTMLInputElement,
  AutocompleteSearchFieldProps
>(function AutocompleteSearchFieldImpl(props, ref) {
  const {
    isOpen,
    onSelectionChange,
    suggestions,
    popoverProps: {
      className: popoverClassName,
      lockBodyScroll,
      ref: popoverRef,
    } = {},
    ...inputProps
  } = props;

  return (
    <ComboBox
      allowsCustomValue
      aria-label={inputProps.label}
      items={suggestions}
      menuTrigger="focus"
      onSelectionChange={onSelectionChange}
    >
      <TextField shouldUseReactAriaInput ref={ref} {...inputProps} />
      <Popover
        ref={popoverRef}
        className={twMerge(
          'w-[var(--trigger-width)] rounded-lg bg-gray-1 shadow-lg-down ring-1 ring-gray-10 ring-opacity-5',
          popoverClassName
        )}
        containerPadding={0}
        isOpen={isOpen}
        shouldFlip={false}
      >
        <RemoveScroll
          forwardProps
          allowPinchZoom
          enabled={Boolean(lockBodyScroll) && isOpen}
        >
          <div
            className={twMerge(
              RemoveScroll.classNames.fullWidth,
              'max-md:h-full max-md:overflow-hidden'
            )}
          >
            <ListBox className="py-2 max-md:h-full max-md:overflow-auto">
              {(section: SuggestionSection) => (
                <Section key={section.id}>
                  {Boolean(section.header) && (
                    <Header className="mx-4 my-0.5">{section.header}</Header>
                  )}
                  <Collection items={section.options}>
                    {(item) => (
                      <ListBoxItem
                        className={({ isFocused, isHovered, isPressed }) =>
                          classNames(
                            'flex cursor-pointer items-center px-4 py-2',
                            {
                              'bg-gray-3': isFocused || isHovered || isPressed,
                            }
                          )
                        }
                        id={item.id}
                        key={item.id}
                        textValue={item.textValue}
                      >
                        {item.displayValue}
                      </ListBoxItem>
                    )}
                  </Collection>
                </Section>
              )}
            </ListBox>
          </div>
        </RemoveScroll>
      </Popover>
    </ComboBox>
  );
});
