import type { ReactNode } from 'react';

import { classNames } from '../Utils/classNames';

type InlineErrorProps = {
  id: string;
  children: ReactNode;
  size?: 'xs' | 'sm';
  as?: 'span' | 'p';
};

export function InlineError({
  children,
  id,
  size = 'sm',
  as: Tag = 'p',
}: InlineErrorProps) {
  return (
    <Tag
      className={classNames('text-error-6', {
        'text-sm': size === 'sm',
        'text-xs': size === 'xs',
      })}
      id={id}
      data-testid={id}
    >
      {children}
    </Tag>
  );
}
