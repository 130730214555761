import { routeConstants } from './constants/routes';

type RoutePathOptions = {
  directUrl?: boolean;
  takeURLParams?: boolean;
};

const prefix = 'a';

const getSearchParams = (url: URL, takeURLParams: boolean) => {
  if (takeURLParams) {
    return decodeURIComponent(url.search);
  }
};

const getPathname = (url: string) => {
  const questionMarkIndex = url.indexOf('?');

  let path = url;

  if (questionMarkIndex !== -1) {
    path = url.substring(0, questionMarkIndex);
  }

  return path ? path : url;
};

const filteredPaths = routeConstants.newExperiencePaths.filter(
  (path) => path !== '/home'
);

export function getRoutePath(
  path?: string | undefined,
  options?: RoutePathOptions
) {
  const { directUrl = false, takeURLParams = true } = options ?? {};

  if (path?.startsWith('http') && !directUrl) {
    return path;
  }

  const pathURL = new URL(path ?? '', window.location.origin);
  const searchParams = getSearchParams(pathURL, takeURLParams);

  const currentPath = path ? getPathname(path) : window.location.pathname;
  const pathnameArray = currentPath.split('/');
  const routesWithPrefix = pathnameArray.some((path) =>
    filteredPaths.includes(`/${path}` as never)
  );

  const baseName = routesWithPrefix ? `/${prefix}` : '';
  let finalPath = currentPath;

  if (!directUrl && pathnameArray.includes(prefix)) {
    return `${currentPath}${searchParams ?? ''}`;
  }

  if (
    routesWithPrefix &&
    ((directUrl && !pathnameArray.includes(baseName)) || !directUrl)
  ) {
    finalPath = `${baseName}${currentPath}`;
  }
  if (searchParams) {
    finalPath = `${finalPath}${searchParams}`;
  }
  return finalPath;
}

export function getModifiedAssemblyLinkURL(url: string | undefined) {
  if (url?.startsWith('http')) {
    const prefixedRouteDomains = ['qa-app', 'app', 'dev-app'];
    const assemblyDomains = ['joinassembly'];
    const urlObj = new URL(url);
    const hostNameArr = urlObj.hostname.split('.');
    const hasPrefixedRouteDomain = hostNameArr.some((hostName) =>
      prefixedRouteDomains.includes(hostName)
    );
    const hasAssemblyDomain = hostNameArr.some((hostName) =>
      assemblyDomains.includes(hostName)
    );
    const isLocalhost = hostNameArr.includes('localhost');
    const shouldAppendPrefix =
      (hasPrefixedRouteDomain && hasAssemblyDomain) || isLocalhost;
    if (shouldAppendPrefix) {
      if (urlObj.pathname.startsWith('/a/')) {
        const modifiedPathname = urlObj.pathname.replace(/\/a\b(?=\/)/g, '');
        return url.replace(urlObj.pathname, `/a${modifiedPathname}`);
      } else {
        return url.replace(urlObj.pathname, `/a${urlObj.pathname}`);
      }
    } else {
      return url;
    }
  } else {
    return url;
  }
}
