export enum SplitNames {
  DoraUI = 'DORA_UI',
  AddMergeApps = 'ADD_MERGE_APPS',
  EnableUserActivitiesTracking = 'ENABLE_USER_ACTIVITIES_TRACKING',
  DisableRecentlyViewedDropdownOption = 'V3_DISABLE_RECENTLY_VIEWED_OPTION',
  FlowSummaries = 'FLOW_SUMMARIES_V0',
  AskDoraPopout = 'ASK_DORA_POPOUT',
  MultiPopoutDrawer = 'MULTI_POPOUT_DRAWER',
  GoogleTranslate = 'GOOGLE_TRANSLATE',
  GoogleRecaptcha = 'RECAPTCHA',
  DisableRewards = 'DISABLE_TANGO_REDEMPTION',
  AllowAssemblyCreation = 'ALLOW_ASSEMBLY_CREATION',
  RewardsV3 = 'REWARDS_V3',

  CollectionApiPaginationChanges = 'COLLECTION_API_PAGINATION_CONVERSION',

  CustomizeWorkspaceTheme = 'CUSTOMIZE_WORKSPACE_THEME',
  EnableAssemblyMagicLink = 'ASSEMBLY_MAGIC_LINK',
  DoraReportingInsightsV0 = 'DORA_REPORTING_INSIGHTS_V0',
  DoraReportingInsightsManagers = 'DORA_REPORTING_INSIGHTS_MANAGERS',
  AmazonRewards = 'AMAZON_REWARDS',
  ActionBarV3 = 'ACTION_BAR_V3',
  Challenges = 'CHALLENGES',
  ChallengesV2 = 'CHALLENGES_V2',
  FlowCreation = 'FLOW_CREATION',
  FlowResponse = 'DORA_ENABLE_FLOW_RESPONSE_REPORTS',
  FlowDistribution = 'FLOW_DISTRIBUTION',
  FlowSimplifiedSelectionCriteria = 'FLOW_SIMPLIFIED_SELECTION_CRITERIA',
  ShowFlowsInV3Nav = 'SHOW_FLOWS_IN_V3_NAV',
  DisableNavPinning = 'DISABLE_NAV_PINNING',
  SentrySessionReplay = 'SENTRY_SESSION_REPLAY',
  ChallengesFileUpload = 'CHALLENGE_FILE_UPLOAD',
  MSTeamsAppSubmission = 'MS_TEAMS_TESTING',
  G2Review = 'G2_REVIEW',
  FilePreviewerV3 = 'V3_FILE_PREVIEWER',
}
