import { type RefObject, useEffect, useState } from 'react';

export const useIntersecting = <T extends HTMLElement>(
  el: RefObject<T>,
  options: IntersectionObserverInit = { threshold: 0.5 }
) => {
  const [isIntersecting, setIsIntersecting] = useState(false);

  useEffect(() => {
    if (!el.current) {
      return;
    }

    const observer = new IntersectionObserver(([entry]) => {
      setIsIntersecting(entry.isIntersecting);
    }, options);

    observer.observe(el.current);

    return () => {
      observer.disconnect();
    };
  }, [el, options]);

  return isIntersecting;
};
