import {
  MemberState,
  type OpenEndedBlockResponse,
  type TaskContent,
  TaskState,
} from '@assembly-web/services';
import { CheckCircleIcon as CheckOutlinedCircleIcon } from '@heroicons/react/24/outline';
import { CheckCircleIcon as CheckSolidCircleIcon } from '@heroicons/react/24/solid';
import type { ReactNode, SyntheticEvent } from 'react';
import reactStringReplace from 'react-string-replace';
import { twMerge } from 'tailwind-merge';

import { PostCardActions, TextStyle } from '../..';
import type { BoostOptions } from '../Web/Editors/RepliesEditor/RepliesEditor';
import { ProfileViewer } from '../Web/ProfileViewer/ProfileViewer';

const MENTION_REGEX = new RegExp(/@__(.*?)__@/);
const URL_REGEX = new RegExp(
  /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/gi
);

const convertTokenizedObjectToString = (content: TaskContent[]) =>
  content.reduce((acc, obj) => {
    if (obj.type === 'text') {
      return acc + obj.value || '';
    }
    return `${acc}\n`;
  }, '');

export const getFormattedMessage = (
  openEndedBlockResponse: OpenEndedBlockResponse,
  onClick: (
    type: PostCardActions,
    id: string,
    event?: SyntheticEvent<HTMLElement>
  ) => void,
  labels: Record<string, string>,
  onMentionClick?: (memberId: string) => void,
  boostOptions?: BoostOptions
) => {
  const {
    value,
    mentions = [],
    tags = [],
    tasks = [],
  } = openEndedBlockResponse;

  let messageText: ReactNode[] = [value];

  messageText = reactStringReplace(messageText, '\n', (_, index) => (
    <br key={index} />
  ));

  messageText = reactStringReplace(messageText, URL_REGEX, (match) => {
    return (
      <a
        className="mt-1 h-fit w-fit font-normal text-primary-6"
        href={match}
        target="_blank"
        rel="noopener noreferrer"
      >
        {match}
      </a>
    );
  });
  if (mentions.length || tags.length || tasks.length) {
    messageText = reactStringReplace(messageText, MENTION_REGEX, (match) => {
      if (match.startsWith('tag:')) {
        const matchingTag = tags.find(
          ({ tag }) => match.split('tag:').pop() === tag
        );
        if (matchingTag) {
          return (
            <button
              onClick={(event) =>
                onClick(PostCardActions.PersonClicked, matchingTag.tag, event)
              }
              className="h-fit w-fit text-primary-6"
            >
              {matchingTag.displayText}
            </button>
          );
        }
      } else if (match.startsWith('task:')) {
        const matchingTask = tasks.find(
          ({ id }) => match.split('task:').pop() === id
        );
        if (matchingTask) {
          const title = convertTokenizedObjectToString(matchingTask.title);

          return (
            <button
              onClick={() =>
                onClick(PostCardActions.TaskClicked, matchingTask.id)
              }
              className={twMerge(
                'pointer-events-none mt-1 inline-flex h-fit w-fit items-center rounded bg-gray-3 px-1 text-left align-middle text-sm font-normal opacity-50',
                matchingTask.state === TaskState.Completed && 'line-through',
                matchingTask.state !== TaskState.Deleted && 'hover:bg-gray-4',
                (matchingTask.state === TaskState.Completed ||
                  matchingTask.state === TaskState.Active) &&
                  'text-gray-9',
                (matchingTask.state === TaskState.Archived ||
                  matchingTask.state === TaskState.Deleted) &&
                  'text-gray-7'
              )}
              disabled={matchingTask.state === TaskState.Deleted}
            >
              {matchingTask.state === TaskState.Completed && (
                <CheckSolidCircleIcon className="mr-1 h-4 fill-success-7" />
              )}
              {matchingTask.state === TaskState.Active && (
                <CheckOutlinedCircleIcon className="mr-1 h-4 text-gray-7" />
              )}
              {matchingTask.state === TaskState.Deleted
                ? labels.deletedTask
                : title}
            </button>
          );
        }
      }
      return mentions
        .filter((member) => (member.memberId || member.memberID) === match)
        .map((member) => {
          if (member.memberState === MemberState.Deactivated) {
            return (
              <TextStyle
                as="span"
                variant="sm-regular"
                className="text-gray-6"
                key={member.memberId || member.memberID}
              >
                {`${member.firstName} ${member.lastName} (deactivated)`}
              </TextStyle>
            );
          } else {
            return (
              <ProfileViewer
                key={member.memberId}
                boostOptions={boostOptions}
                onViewProfileClick={(mentionId) => {
                  onMentionClick?.(mentionId);
                }}
                userDetails={{ ...member, memberID: member.memberId }}
              >
                <button
                  onClick={(event) =>
                    onClick(
                      PostCardActions.PersonClicked,
                      (member.memberId || member.memberID) ?? '',
                      event
                    )
                  }
                  className="h-fit w-fit rounded bg-primary-1 px-1 font-normal text-primary-6 hover:bg-primary-2"
                  key={member.memberId || member.memberID}
                >
                  {`${member.firstName} ${member.lastName}`}
                </button>
              </ProfileViewer>
            );
          }
        });
    });
  }
  return messageText;
};
