import type { UIEvent } from 'react';

/**
 * Creates a function to handle an event. The created function will stop the event from its default
 * behavior and propagation, and then executes the provided callback function.
 *
 * @param { (e: UIEvent) => void } callback - Function to be executed after preventing default event behavior and stopping propagation.
 * @returns { (e: UIEvent) => void } - Function that prevents default event behavior, stops propagation and then executes the callback.
 */
export function withoutDefaultEventBehavior(
  callback: (e: UIEvent) => void
): (e: UIEvent) => void {
  return (e: UIEvent) => {
    e.preventDefault();
    e.stopPropagation();

    return callback(e);
  };
}
