/**
 * Sanitizes a given URL by checking if it uses a supported protocol.
 *
 * @param {string} url - The URL to be sanitized.
 * @return {string} The sanitized URL. If the URL uses an unsupported protocol, it will be replaced with 'about:blank'. If the URL is invalid, it will be returned as is.
 */
export function sanitizeUrlForEditor(url: string): string {
  const SUPPORTED_URL_PROTOCOLS = new Set([
    'http:',
    'https:',
    'mailto:',
    'sms:',
    'tel:',
  ]);

  try {
    const parsedUrl = new URL(url);

    if (!SUPPORTED_URL_PROTOCOLS.has(parsedUrl.protocol)) {
      return 'about:blank';
    }
  } catch {
    return url;
  }
  return url;
}
