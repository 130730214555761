import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  todayLabel: {
    defaultMessage: 'Today',
    id: 'zWgbGg',
  },
  yesterdayLabel: {
    defaultMessage: 'Yesterday',
    id: '6dIxDP',
  },
  last7DaysLabel: {
    defaultMessage: 'Last 7 days',
    id: 'irFBKn',
  },
  last30DaysLabel: {
    defaultMessage: 'Last 30 days',
    id: 'Rfvi9/',
  },
  customLabel: {
    defaultMessage: 'Custom',
    id: 'Sjo1P4',
  },
  doneButtonLabel: {
    defaultMessage: 'Done',
    id: 'JXdbo8',
  },
  clearAllButtonLabel: {
    defaultMessage: 'Clear all',
    id: 'QW+Q5N',
  },
  customDateRange: {
    defaultMessage: 'Custom date range',
    id: 'rTHJFy',
  },
});
