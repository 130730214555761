import { useLabel } from '@react-aria/label';
import parse from 'html-react-parser';
import { forwardRef, type InputHTMLAttributes, type ReactNode } from 'react';
import { Input } from 'react-aria-components';
import { twMerge } from 'tailwind-merge';

import { InlineError } from '../Feedback/InlineError';
import { Tooltip } from '../Feedback/Tooltip';
import type { InvalidFieldProps } from '../Utils/commonTypes';

export type TextFieldProps = InputHTMLAttributes<HTMLInputElement> &
  InvalidFieldProps & {
    label?: string;
    helpText?: string;
    hideLabel?: boolean;
    inputClassName?: string;
    labelClassName?: string;
    /** A component to be rendered on the left edge of the `TextField`. */
    connectedLeft?: ReactNode;

    /** A component to be rendered on the right edge of the `TextField`. */
    connectedRight?: ReactNode;

    inputSize?: 'md' | 'lg';

    shouldUseReactAriaInput?: boolean;

    tooltipText?: string;
  };

export const TextField = forwardRef<HTMLInputElement, TextFieldProps>(
  (props, inputRef) => {
    let {
      label: labelText,
      hideLabel,
      helpText,
      isInvalid,
      invalidText,
      invalidTextTestId,
      className,
      connectedLeft,
      connectedRight,
      type = 'text',
      inputSize = 'md',
      disabled,
      inputClassName,
      labelClassName,
      shouldUseReactAriaInput = false,
      placeholder,
      tooltipText,
      ...inputProps
    } = props;

    const fallBackErrorTestId = `${inputProps.name}Error`;

    if (!invalidTextTestId) {
      invalidTextTestId = fallBackErrorTestId;
    }

    let computedProps: InputHTMLAttributes<HTMLInputElement> = {};
    const helpTextElementID = `${inputProps.name}-help-text`;

    if (helpText) {
      computedProps['aria-describedby'] = helpTextElementID;
    }

    const { labelProps, fieldProps } = useLabel(props);

    const InputComponent = shouldUseReactAriaInput ? Input : 'input';

    return (
      <div className={twMerge('h-fit w-full', className)}>
        <label
          className={twMerge(
            hideLabel && 'sr-only',
            isInvalid && 'text-error-6',
            labelClassName
          )}
          {...labelProps}
        >
          {labelText}
        </label>
        <div className="relative w-full">
          {Boolean(connectedLeft) && (
            <div className="absolute inset-y-0 left-[14px] flex items-center">
              {connectedLeft}
            </div>
          )}

          <Tooltip tooltipText={tooltipText}>
            <InputComponent
              type={type}
              className={twMerge(
                'w-full rounded-md py-2 placeholder:text-gray-7 disabled:cursor-not-allowed disabled:border disabled:border-gray-5 disabled:bg-gray-2 disabled:text-gray-7',
                !isInvalid && !disabled && 'border-gray-5',
                isInvalid &&
                  'border-error-6 text-error-6 placeholder-error-6 focus:border-error-6 focus:outline-none focus:ring-error-6',
                connectedLeft && 'pl-9',
                connectedRight && 'pr-9',
                inputSize === 'md' && 'h-10',
                inputSize === 'lg' && 'h-12',
                inputClassName
              )}
              ref={inputRef}
              {...(labelText ? fieldProps : {})}
              {...computedProps}
              {...inputProps}
              {...(isInvalid
                ? {
                    'aria-invalid': true,
                    'aria-describedby': invalidTextTestId,
                  }
                : {})}
              disabled={disabled}
              placeholder={placeholder}
            />
          </Tooltip>
          {Boolean(connectedRight) && (
            <div className="absolute inset-y-0 right-[14px] my-[1px] flex items-center pl-3">
              {connectedRight}
            </div>
          )}
        </div>
        {helpText && !isInvalid ? (
          <span className="text-xs text-gray-8" id={helpTextElementID}>
            {parse(helpText)}
          </span>
        ) : null}
        {Boolean(isInvalid) && (
          <InlineError id={invalidTextTestId} size="xs" as="span">
            {invalidText}
          </InlineError>
        )}
      </div>
    );
  }
);

TextField.displayName = 'TextField';
