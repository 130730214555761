import { Transition } from '@headlessui/react';
import { XMarkIcon } from '@heroicons/react/20/solid';
import {
  Content,
  Description,
  Overlay,
  Root,
  Title,
} from '@radix-ui/react-dialog';
import type { ReactElement, ReactNode } from 'react';
import { Fragment } from 'react';
import { defineMessages, useIntl } from 'react-intl';
import { twMerge } from 'tailwind-merge';

import { TextStyle } from '../../../DesignSystem/Feedback/TextStyle';
import { IconButton } from '../../../DesignSystem/Inputs/IconButton';

export type ConfirmationModalProps = {
  description: ReactNode;
  leftButton: ReactElement<HTMLButtonElement>;
  onClose: () => void;
  open: boolean;
  rightButton: ReactElement<HTMLButtonElement>;
  title: ReactNode;
};

const messages = defineMessages({
  close: {
    defaultMessage: 'Close',
    id: 'rbrahO',
  },
});

export function ShareConfirmationModal(props: ConfirmationModalProps) {
  const { description, leftButton, onClose, open, rightButton, title } = props;

  const { formatMessage } = useIntl();

  return (
    <Root
      onOpenChange={(isOpen) => {
        if (!isOpen) onClose();
      }}
      open={open}
    >
      <Transition.Root show={open}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <Overlay
            forceMount
            className="fixed inset-0 z-30 bg-neutral-secondary bg-opacity-75 transition-opacity"
          />
        </Transition.Child>

        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0 -translate-y-[calc(50%-16px)] sm:scale-95"
          enterTo="opacity-100 -translate-y-1/2 sm:scale-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100 -translate-y-1/2 sm:scale-100"
          leaveTo="opacity-0 -translate-y-[calc(50%-16px)] sm:scale-95"
        >
          <Content
            className={twMerge(
              'fixed z-30',
              'left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2',
              'w-[90%] rounded-md bg-gray-1 text-left shadow-xl-down transition-all sm:w-full sm:max-w-lg'
            )}
          >
            <Title className="p-6 pb-0 text-gray-9">
              <TextStyle
                as="span"
                className="text-gray-9"
                variant="base-medium"
              >
                {title}
              </TextStyle>
            </Title>

            <Description className="mt-2 px-6 py-1 text-gray-9">
              {description}
            </Description>
            <div className="absolute right-0 top-0 pr-6 pt-6">
              <IconButton
                onClick={props.onClose}
                variation="tertiaryLite"
                size="xSmall"
                data-testid="modalCloseButton"
              >
                <span className="sr-only">{formatMessage(messages.close)}</span>
                <XMarkIcon className="h-4 w-4" aria-hidden="true" />
              </IconButton>
            </div>
            <div className="mt-6 flex gap-x-2 px-4 pb-6 md:px-6">
              {leftButton}
              {rightButton}
            </div>
          </Content>
        </Transition.Child>
      </Transition.Root>
    </Root>
  );
}
