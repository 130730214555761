import type { PopoverContentProps } from '@radix-ui/react-popover';
import {
  Anchor,
  Content,
  Portal,
  Root,
  Trigger,
} from '@radix-ui/react-popover';
import { forwardRef } from 'react';
import { twMerge } from 'tailwind-merge';

export const Popover = Root;

export const PopoverTrigger = Trigger;

export const PopoverPortal = Portal;

export const PopoverAnchor = Anchor;

export const PopoverContent = forwardRef<HTMLDivElement, PopoverContentProps>(
  function PopoverContent({ className, ...tail }, ref) {
    return (
      <Content
        className={twMerge(
          'rounded-lg bg-gray-1 p-4 shadow-md-down',
          className
        )}
        align="center"
        side="bottom"
        sideOffset={4}
        {...tail}
        ref={ref}
      />
    );
  }
);
