import type { ReactNode } from 'react';

import { TextStyle } from '../../../DesignSystem/Feedback/TextStyle';

export function BlockLabel({ children }: { children: ReactNode }) {
  return (
    <TextStyle as="span" variant="sm-medium">
      {children}
    </TextStyle>
  );
}
