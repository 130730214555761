import { AutoLinkNode, LinkNode } from '@lexical/link';
import { AutoLinkPlugin } from '@lexical/react/LexicalAutoLinkPlugin';
import LexicalClickableLinkPlugin from '@lexical/react/LexicalClickableLinkPlugin';
import {
  type InitialConfigType,
  LexicalComposer,
} from '@lexical/react/LexicalComposer';
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import { ContentEditable } from '@lexical/react/LexicalContentEditable';
import LexicalErrorBoundary from '@lexical/react/LexicalErrorBoundary';
import { HistoryPlugin } from '@lexical/react/LexicalHistoryPlugin';
import { LinkPlugin } from '@lexical/react/LexicalLinkPlugin';
import { PlainTextPlugin } from '@lexical/react/LexicalPlainTextPlugin';
import { motion } from 'framer-motion';
import { $getRoot, type LexicalEditor } from 'lexical';
import {
  type CSSProperties,
  type Dispatch,
  type SetStateAction,
  useEffect,
  useId,
  useRef,
  useState,
} from 'react';
import { twMerge } from 'tailwind-merge';

import { InlineError } from '../../../../DesignSystem/Feedback/InlineError';
import { TextStyle } from '../../../../DesignSystem/Feedback/TextStyle';
import { ExternalOnChangePlugin } from '../base/plugins/ExternalOnChangePlugin';
import { UpdateEditorPlugin } from '../base/plugins/UpdateEditorPlugin';
import { EditorTheme } from '../base/theme';
import { MATCHERS, validateUrl } from '../base/utils/regexUtils';
import { HandleEnterKeyPressPlugin } from './plugins/HandleEnterKeyPressPlugin';

const config = {
  theme: EditorTheme,
  namespace: 'rich-text-editor',
  nodes: [LinkNode, AutoLinkNode],
  onError: () => {},
} satisfies InitialConfigType;

export type TextInputProps = {
  value?: string;
  disabled?: boolean;
  placeholder: string;
  required?: boolean;
  invalid?: boolean;
  invalidText?: string | null;
  onError?: (error: Error, editor: LexicalEditor) => void;
  onChange: (data: { plainText: string; html: string; json: string }) => void;
  onClick?: () => void;
  onPressEnter?: () => void;
  onFocus?: () => void;
  onBlur?: () => void;
};

const TextPlugin = ({
  disabled,
  placeholder,
  required,
  setIsFocused,
  onBlur,
  onClick,
  onFocus,
  invalid,
  invalidTextId,
}: Pick<
  TextInputProps,
  | 'disabled'
  | 'placeholder'
  | 'required'
  | 'invalid'
  | 'onBlur'
  | 'onFocus'
  | 'onClick'
> & {
  isFocused: boolean;
  setIsFocused: Dispatch<SetStateAction<boolean>>;
  invalidTextId?: string;
}) => {
  const ref = useRef<HTMLDivElement>(null);
  const [editor] = useLexicalComposerContext();

  const [isEmpty, setIsEmpty] = useState(true);
  const [top, setTop] = useState(0);

  useEffect(() => {
    // TODO: Remove this when moving to RichTextPlugin
    // the manual positioning is required because the PlainTextPlugin
    // is not creating a new paragraph when the user presses enter
    // instead it creates a new line via `<br />` tag
    const el = ref.current;
    if (!el) return;

    function calculateTop(childNodes: NodeListOf<ChildNode>) {
      const nodes = Array.from(childNodes);
      let i = 0;
      let j = 1;
      while (j >= 0 && j < nodes.length && i < nodes.length) {
        if (nodes[j].nodeName === 'BR' && nodes[j - 1].nodeName === 'P') {
          i++;
          j++;
        } else if (nodes[j].nodeName === 'BR') {
          i++;
        }
        j++;
      }
      setTop(Math.max(i - 1, 0) * 22);
    }

    setTimeout(() => {
      calculateTop(el.childNodes[0].childNodes);
    }, 0);
    const mutationObserver = new MutationObserver((mutation) => {
      if (mutation[0].target.nodeName === 'P') {
        calculateTop(mutation[0].target.childNodes);
      }
    });

    mutationObserver.observe(el, { subtree: true, childList: true });

    return () => {
      mutationObserver.disconnect();
    };
  }, []);

  useEffect(() => {
    return editor.registerUpdateListener(({ editorState }) => {
      setIsEmpty(editorState.read(() => $getRoot().getTextContent() === ''));
    });
  }, [editor]);

  return (
    <PlainTextPlugin
      ErrorBoundary={LexicalErrorBoundary}
      placeholder={
        <motion.div
          className="pointer-events-none absolute left-3 top-2"
          layout="position"
          transition={{ layout: { duration: 0 } }}
        >
          <TextStyle
            as="span"
            disabled={disabled}
            variant="sm-regular"
            className="text-gray-7"
          >
            {placeholder}
            {required && isEmpty ? (
              <span className="text-error-6">{' *'}</span>
            ) : (
              ''
            )}
          </TextStyle>
        </motion.div>
      }
      contentEditable={
        <div
          className={twMerge(
            'flex-1 overflow-hidden rounded-lg border-0 bg-gray-1 outline-[0] focus:outline-none focus:ring-0 focus:ring-transparent focus:ring-offset-0 focus-visible:outline-none focus-visible:ring-0 focus-visible:ring-transparent focus-visible:ring-offset-0',
            disabled && 'cursor-not-allowed bg-gray-2'
          )}
          style={{ '--top': `${top}px` } as CSSProperties}
          ref={ref}
        >
          <ContentEditable
            disabled={disabled}
            onClick={onClick}
            onBlur={() => {
              setIsFocused(false);
              onBlur?.();
            }}
            onFocus={() => {
              setIsFocused(true);
              onFocus?.();
            }}
            className={twMerge(
              'flex h-full min-h-[40px] flex-col gap-1 overflow-y-auto px-3 py-[9px] text-sm text-gray-9 focus:outline-none focus:ring-0 focus:ring-transparent focus:ring-offset-0 focus-visible:outline-none focus-visible:ring-0 focus-visible:ring-transparent focus-visible:ring-offset-0',
              required &&
                !isEmpty &&
                '[&_p:last-child:has(br:last-child)]:relative [&_p:last-child:has(br:last-child)]:after:absolute [&_p:last-child:has(br:last-child)]:after:top-[var(--top,_0px)] [&_p:last-child]:after:text-error-6 [&_p:last-child]:after:content-["*"]'
            )}
            ariaLabel="Rich text editor"
            {...(invalid && {
              ariaDescribedBy: invalidTextId,
              'aria-invalid': true,
            })}
          />
        </div>
      }
    />
  );
};

export const TextInput = ({
  disabled,
  invalid,
  invalidText,
  onChange,
  onClick,
  onError,
  onPressEnter,
  placeholder,
  required,
  value,
  onBlur,
  onFocus,
}: TextInputProps) => {
  const [isFocused, setIsFocused] = useState(false);
  const invalidTextId = useId();

  return (
    <div className="flex flex-col gap-2">
      <div
        className={twMerge(
          'relative box-content flex flex-1 flex-col overflow-hidden rounded-lg ring-1 ring-gray-5',
          isFocused && 'ring-2 ring-primary-6',
          invalid && 'ring-2 ring-error-6'
        )}
        data-focused={isFocused}
      >
        <LexicalComposer
          initialConfig={{
            ...config,
            editable: !disabled,
            onError: (error, editor) => {
              onError?.(error, editor);
            },
          }}
        >
          <TextPlugin
            placeholder={placeholder}
            disabled={disabled}
            required={required}
            invalid={invalid}
            invalidTextId={invalidTextId}
            isFocused={isFocused}
            setIsFocused={setIsFocused}
            onBlur={onBlur}
            onClick={onClick}
            onFocus={onFocus}
          />
          <LinkPlugin validateUrl={validateUrl} />
          <AutoLinkPlugin matchers={MATCHERS} />
          <HistoryPlugin />
          <ExternalOnChangePlugin
            onChange={(args) => onChange(args)}
            ignoreSelectionChange={true}
            ignoreHistoryMergeTagChange={true}
          />
          <UpdateEditorPlugin editState={value} />
          <HandleEnterKeyPressPlugin
            disabled={disabled}
            onPressEnter={onPressEnter}
          />
          <LexicalClickableLinkPlugin />
        </LexicalComposer>
      </div>
      {Boolean(invalidText) && (
        <InlineError id={invalidTextId} size="xs" as="span">
          {invalidText}
        </InlineError>
      )}
    </div>
  );
};
