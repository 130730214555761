import type {
  Quantity,
  RedemptionFormDetails,
  RewardColor,
  RewardDetailsForm,
  RewardDetailsOption,
  RewardSize,
  ShippingCountry,
  ShippingDetailsForm,
  ShippingState,
} from '@assembly-web/services';
import { InformationCircleIcon } from '@heroicons/react/24/outline';
import parse, {
  domToReact,
  Element,
  type HTMLReactParserOptions,
} from 'html-react-parser';
import {
  lazy,
  type ReactNode,
  Suspense,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { defineMessages, useIntl } from 'react-intl';

import { Banner } from '../../../DesignSystem/Feedback/Banner';
import { LoadingSpinner } from '../../../DesignSystem/Feedback/Icons/LoadingSpinner';
import { Modal } from '../../../DesignSystem/Feedback/Modal';
import { TextStyle } from '../../../DesignSystem/Feedback/TextStyle';
import { Tooltip } from '../../../DesignSystem/Feedback/Tooltip';
import { Button } from '../../../DesignSystem/Inputs/Button';
import { HorizontalRule } from '../../../DesignSystem/Layout/HorizontalRule';
import { RewardPlaceholderImage } from '../../assets/images';
import {
  G2ReviewModal,
  type G2ReviewModalProps,
} from '../Modals/G2ReviewModal/G2ReviewModal';
import { AxomoRedemptionForm } from './AxomoForm';
import { RedemptionDetailsModalSkeletonLoader } from './RedemptionDetailsModalSkeletonLoader';
import { RedemptionModalState } from './RedemptionModal';
import { RedemptionModalSkeletonLoader } from './RedemptionModalSkeletonLoader';
import { ShipToDetails } from './ShipToDetails';

const SwagRewardRedemptionSuccessful = lazy(() =>
  import('./SwagRewardRedemptionSuccessModal').then((module) => ({
    default: module.SwagRewardRedemptionSuccessful,
  }))
);

const RewardRedemptionModalError = lazy(() =>
  import('./RewardRedemptionErrorModal').then((module) => ({
    default: module.RewardRedemptionModalError,
  }))
);

const messages = defineMessages({
  redeemReward: {
    defaultMessage: 'Redeem Reward',
    id: 'tTouHd',
  },
  cost: {
    defaultMessage: 'Cost',
    id: 'fBG/Ge',
  },
  redeem: {
    defaultMessage: 'Redeem',
    id: 'XSdWHA',
  },
  continueToShipping: {
    defaultMessage: 'Continue to shipping',
    id: 'DgnS8R',
  },
  finalizeDetails: {
    defaultMessage: 'Finalize details',
    id: 'G+LU2+',
  },
  back: {
    defaultMessage: 'Back',
    id: 'cyR7Kh',
  },
  placeOrder: {
    defaultMessage: 'Place order',
    id: 'JItzoH',
  },
  description: {
    defaultMessage: 'Description',
    id: 'Q8Qw5B',
  },
  shippingDisclaimer: {
    defaultMessage: `We automatically choose the best shipping method for you, so you don’t have to worry about that.
Please confirm that your order and address are correct, and then click “Place order” to finalize your purchase.
Orders cannot be cancelled or modified once placed.`,
    id: 'bhnJJV',
  },
  aboutShipping: {
    defaultMessage: 'About shipping',
    id: 'mK1cLi',
  },
  details: {
    defaultMessage: 'Details',
    id: 'Lv0zJu',
  },
  swagTotalCost: {
    defaultMessage:
      '{icon} {swagCost} (Swag) + {icon} {shippingCost} (Shipping)',
    id: 'y+L6+B',
  },
  shippingDetailsHeader: {
    defaultMessage: 'Shipping details',
    id: 'iEDa4y',
  },
  confirmDetailsHeader: {
    defaultMessage: 'Confirm details',
    id: 'Qb3uSJ',
  },
});

export type SwagDetailsProps = {
  rewardDetails?: string;
  sizes?: RewardSize[];
  colors?: RewardColor[];
  availableQuantities?: Quantity[];
  shippableCountries?: ShippingCountry[];
  stateList?: ShippingState[];
  onCountryChange?: (country: string) => void;
};

export type SwagRewardRedemptionInProgressProps = {
  rewardCardImage?: string;
  rewardName: string;
  rewardDescription: string;
  redemptionInfoBannerText?: string;
  onPlaceOrderClick: (details: RedemptionFormDetails) => void;
  email: string;
  isAxomoPlaceOrderInProgress?: boolean;
  cost: string;
  currencyIcon: ReactNode;
  id: string;
  swagId: string;
  storeUID: string;
  isSwagDetailsLoading?: boolean;
  isError?: boolean;
  isSwagDetailsSuccess?: boolean;
  isStateListLoading?: boolean;
  shippingCost?: number;
  handleStepChange?: (newStep: AxomoRedemptionFormStep) => void;
  setTitle?: (title: string) => void;
};

export type SwagRewardRedemptionSuccessfulProps = {
  rewardCardImage?: string;
  rewardName: string;
  rewardDescription: string;
  email: string;
  cost: string;
  currencyIcon: ReactNode;
  id: string;
  swagId: string;
  storeUID: string;
  redemptionInfoBannerText?: string | ReactNode | undefined;
  orderDetails?: RedemptionFormDetails;
  shippingCost?: number;
  setTitle?: (title: string) => void;
};

export type SwagRewardRedemptionErrorProps = {
  swagId: string;
  storeUID?: string;
  errorText?: string;
  errorDescription?: string;
  setTitle?: (title: string) => void;
};

export enum AxomoRedemptionFormStep {
  SwagDetails = 'swag-details',
  ShippingDetails = 'shipping-details',
  ConfirmDetails = 'confirm-details',
}

type G2ReviewProps = {
  showG2Review: boolean;
} & G2ReviewModalProps;

export type SwagRewardRedemptionModalProps = {
  isOpen: boolean;
  onClose: () => void;
  isLoading?: boolean;
  error?: unknown;
  handleStepChange?: (newStep: AxomoRedemptionFormStep) => void;
} & (
  | ({
      state: RedemptionModalState.Redeeming;
    } & SwagRewardRedemptionInProgressProps &
      SwagDetailsProps)
  | ({
      state: RedemptionModalState.Redeemed;
      g2Review: G2ReviewProps;
    } & SwagRewardRedemptionSuccessfulProps)
  | ({
      state: RedemptionModalState.Error;
    } & SwagRewardRedemptionErrorProps)
);

export function SwagRewardRedemptionModal({
  isOpen,
  onClose,
  isLoading,
  ...props
}: SwagRewardRedemptionModalProps) {
  const [title, setTitle] = useState<string | null>(null);

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      title={title}
      className="flex flex-col"
      headerClassName="pb-4 border-b border-gray-4 z-50"
      bodyClassName="p-0 flex flex-col flex-1"
    >
      {props.state === RedemptionModalState.Redeeming && (
        <SwagRewardRedemptionInProgress setTitle={setTitle} {...props} />
      )}
      {props.state === RedemptionModalState.Redeemed && (
        <Suspense fallback={<RedemptionDetailsModalSkeletonLoader />}>
          <SwagRewardRedemptionSuccessful
            {...props}
            setTitle={setTitle}
            handleRedemptionModalClose={onClose}
          />
          {Boolean(props.g2Review.showG2Review) && (
            <G2ReviewModal {...props.g2Review} />
          )}
        </Suspense>
      )}
      {props.state === RedemptionModalState.Error && (
        <Suspense fallback={<RedemptionDetailsModalSkeletonLoader />}>
          <RewardRedemptionModalError {...props} setTitle={setTitle} />
        </Suspense>
      )}
    </Modal>
  );
}

const SwagDetailsList = ({ rewardDetails }: { rewardDetails: string }) => {
  const parseOptions: HTMLReactParserOptions = {
    replace: (domNode) => {
      if (domNode instanceof Element && domNode.name === 'ul') {
        return (
          <ul
            style={{
              listStyleType: 'disc',
              paddingLeft: '20px',
              fontSize: '12px',
            }}
          >
            {domToReact(domNode.children, parseOptions)}
          </ul>
        );
      }
    },
  };

  return (
    <div className="mb-4 break-all text-sm">
      {parse(rewardDetails, parseOptions)}
    </div>
  );
};

function SwagRewardRedemptionInProgress({
  rewardCardImage,
  rewardName,
  rewardDescription,
  cost,
  currencyIcon,
  redemptionInfoBannerText,
  rewardDetails,
  sizes,
  colors,
  availableQuantities,
  shippableCountries,
  stateList,
  onCountryChange,
  onPlaceOrderClick,
  isAxomoPlaceOrderInProgress,
  isSwagDetailsLoading,
  isStateListLoading,
  shippingCost,
  handleStepChange: onStepChange,
  setTitle,
}: SwagRewardRedemptionInProgressProps & SwagDetailsProps) {
  const { formatMessage } = useIntl();
  const [step, setStep] = useState(AxomoRedemptionFormStep.SwagDetails);
  const [shippingDetails, setShippingDetails] =
    useState<ShippingDetailsForm | null>(null);
  const [selectedSwagInfo, setSelectedSwagInfo] =
    useState<RewardDetailsForm | null>(null);

  const titleMap = useMemo(
    () => ({
      [AxomoRedemptionFormStep.SwagDetails]: formatMessage(
        messages.redeemReward
      ),
      [AxomoRedemptionFormStep.ShippingDetails]: formatMessage(
        messages.shippingDetailsHeader
      ),
      [AxomoRedemptionFormStep.ConfirmDetails]: formatMessage(
        messages.confirmDetailsHeader
      ),
    }),
    [formatMessage]
  );

  useEffect(() => {
    setTitle?.(titleMap[AxomoRedemptionFormStep.SwagDetails]);
  }, [setTitle, titleMap]);

  const [isContinueToShippingDisabled, setIsContinueToShippingDisabled] =
    useState<boolean>(true);
  const [isConfirmDetailsDisabled, setIsConfirmDetailsDisabled] =
    useState<boolean>(true);

  const handleStepChange = (newStep: AxomoRedemptionFormStep) => {
    setStep(newStep);
    onStepChange?.(newStep);
    setTitle?.(titleMap[newStep]);
  };

  const handleCountryChange = (country: string) => {
    onCountryChange?.(country);
  };

  const swagDetailsOptions: RewardDetailsOption[] = useMemo(() => {
    return [
      { name: 'size', options: sizes },
      { name: 'color', options: colors },
      { name: 'quantity', options: availableQuantities },
    ] as RewardDetailsOption[];
  }, [sizes, colors, availableQuantities]);

  const onSwagFormUpdate = (data: RewardDetailsForm, hasErrors: boolean) => {
    setSelectedSwagInfo(data);
    if (!hasErrors) {
      setIsContinueToShippingDisabled(false);
    } else {
      setIsContinueToShippingDisabled(true);
    }
  };

  const onShippingFormUpdate = (
    data: ShippingDetailsForm,
    hasErrors: boolean
  ) => {
    setShippingDetails(data);
    if (!hasErrors) {
      setIsConfirmDetailsDisabled(false);
    } else {
      setIsConfirmDetailsDisabled(true);
    }
  };

  const onContinueToShippingClick = () => {
    if (!isContinueToShippingDisabled) {
      handleStepChange(AxomoRedemptionFormStep.ShippingDetails);
    }
  };

  const handlePlaceOrderClick = (details: RedemptionFormDetails) => {
    onPlaceOrderClick(details);
  };

  const onConfirmDetailsClick = () => {
    handleStepChange(AxomoRedemptionFormStep.ConfirmDetails);
  };

  const shippingDisclaimer = formatMessage(messages.shippingDisclaimer);
  const shippingDisclaimerMessageParts = shippingDisclaimer
    .split('.')
    .filter(Boolean);

  const totalCost = cost
    ? (
        parseInt(cost, 10) *
        ((selectedSwagInfo?.quantity?.value as number) || 1)
      ).toString()
    : cost;

  const calculatedCostWithShipping =
    parseInt(totalCost, 10) + (shippingCost ?? 0);

  return (
    <div
      className="flex flex-1 flex-col"
      data-testid="swag-redemption-in-progress-modal"
    >
      <div className="flex-1 px-6">
        <div className="flex w-full flex-col items-center gap-2 p-4">
          <div className="flex w-full justify-center rounded-md">
            <img
              alt="Default placeholder for a Reward card"
              className="h-[125px] w-fit"
              src={rewardCardImage ? rewardCardImage : RewardPlaceholderImage}
            />
          </div>
          <TextStyle
            variant="base-medium"
            data-testid="swag-selected-values-info"
          >
            {rewardName}
            {selectedSwagInfo ? (
              <>
                <span>, {selectedSwagInfo.size?.name}</span>
                {selectedSwagInfo.color ? (
                  <span>, {selectedSwagInfo.color.name}</span>
                ) : null}
                {selectedSwagInfo.quantity ? (
                  <span>, {selectedSwagInfo.quantity.name}x</span>
                ) : null}
              </>
            ) : null}
          </TextStyle>
          <div className="flex items-center gap-1">
            <TextStyle variant="xs-regular" className="flex items-center gap-1">
              {formatMessage(messages.cost)}:
              <TextStyle
                as="span"
                className="flex items-center"
                variant="xs-bold"
              >
                {currencyIcon} {calculatedCostWithShipping}
              </TextStyle>
            </TextStyle>
            <Tooltip
              tooltipText={formatMessage(messages.swagTotalCost, {
                icon: currencyIcon,
                swagCost: totalCost,
                shippingCost: shippingCost,
              })}
              textClassName="flex items-center gap-1"
              contentClassName="z-[50]"
            >
              <InformationCircleIcon className="h-4 w-4 cursor-pointer text-gray-8" />
            </Tooltip>
          </div>
        </div>
        {!isSwagDetailsLoading ? (
          <>
            <div
              className="mb-4 rounded-lg bg-gray-3 p-4"
              data-testid="axomo-form-container"
              style={{
                display:
                  step === AxomoRedemptionFormStep.SwagDetails || // Dont unmount the form when switching steps
                  step === AxomoRedemptionFormStep.ShippingDetails
                    ? 'block'
                    : 'none',
              }}
            >
              <AxomoRedemptionForm // Axomo form
                formStep={step}
                swagDetails={swagDetailsOptions}
                onSubmitForm={handlePlaceOrderClick}
                shippableCountries={shippableCountries}
                stateList={stateList}
                onCountryChange={handleCountryChange}
                onSwagFormUpdate={onSwagFormUpdate}
                onShippingFormUpdate={onShippingFormUpdate}
                isStateListLoading={isStateListLoading ?? false}
              />
            </div>
            {step === AxomoRedemptionFormStep.SwagDetails && ( // Swag Details Content
              <>
                <HorizontalRule />
                <div className="max-h-[200px] overflow-y-auto pb-4">
                  <div>
                    <TextStyle variant="sm-bold" className="py-2">
                      {formatMessage(messages.description)}
                    </TextStyle>
                    <TextStyle variant="xs-regular">
                      {rewardDescription}
                    </TextStyle>
                  </div>
                  {rewardDetails ? (
                    <div>
                      <TextStyle variant="sm-bold" className="py-2">
                        {formatMessage(messages.details)}
                      </TextStyle>
                      <SwagDetailsList rewardDetails={rewardDetails} />
                    </div>
                  ) : null}
                </div>
              </>
            )}
          </>
        ) : (
          <div className="pb-4">
            <RedemptionModalSkeletonLoader />
          </div>
        )}
        {step === AxomoRedemptionFormStep.ConfirmDetails && ( // Confirm Details Content
          <>
            {shippingDetails ? (
              <ShipToDetails details={shippingDetails} />
            ) : null}
            <HorizontalRule className="py-4" />
            <div className="pb-4">
              <TextStyle variant="sm-bold" className="pb-2">
                {formatMessage(messages.aboutShipping)}
              </TextStyle>
              <div className="flex flex-col gap-2">
                {shippingDisclaimerMessageParts.map((part, index) => (
                  <TextStyle variant="xs-regular" key={index}>
                    {part}.
                  </TextStyle>
                ))}
              </div>
            </div>
          </>
        )}
      </div>
      <div className="flex-shrink-0">
        <HorizontalRule />
        <footer className="flex flex-col gap-3 px-6 py-4">
          {Boolean(redemptionInfoBannerText) && (
            <Banner className="rounded-xl text-primary-8" status="info">
              <TextStyle
                variant="xs-regular"
                data-testid="swag-redemption-modal-banner"
              >
                {redemptionInfoBannerText}
              </TextStyle>
            </Banner>
          )}
          <div>
            {step === AxomoRedemptionFormStep.SwagDetails && ( // Swag Details Footer
              <Button
                onClick={onContinueToShippingClick}
                disabled={isContinueToShippingDisabled}
                className="w-full"
                variation="primary"
                data-testid="continue-to-shipping-button"
              >
                {formatMessage(messages.continueToShipping)}
              </Button>
            )}
            {step === AxomoRedemptionFormStep.ShippingDetails && ( // Shipping Details Footer
              <div className="flex items-center gap-2">
                <Button
                  onClick={() => {
                    handleStepChange(AxomoRedemptionFormStep.SwagDetails);
                  }}
                  variation="secondaryEmphasized"
                  className="w-full"
                  data-testid="back-to-swag-details-button"
                >
                  {formatMessage(messages.back)}
                </Button>
                <Button
                  className="w-full"
                  variation="primary"
                  onClick={onConfirmDetailsClick}
                  disabled={isConfirmDetailsDisabled}
                  data-testid="finalize-details-button"
                >
                  {formatMessage(messages.finalizeDetails)}
                </Button>
              </div>
            )}
            {step === AxomoRedemptionFormStep.ConfirmDetails && ( // Confirm Details Footer
              <div className="flex items-center gap-2">
                <Button
                  onClick={() => {
                    handleStepChange(AxomoRedemptionFormStep.ShippingDetails);
                  }}
                  variation="secondaryEmphasized"
                  className="w-full"
                  disabled={isAxomoPlaceOrderInProgress}
                  data-testid="back-to-shipping-details-button"
                >
                  {formatMessage(messages.back)}
                </Button>
                <Button
                  className="w-full"
                  variation="primary"
                  disabled={isAxomoPlaceOrderInProgress}
                  type="submit"
                  form="axomo-form"
                  data-testid="place-order-button"
                >
                  {formatMessage(messages.placeOrder)}
                  {Boolean(isAxomoPlaceOrderInProgress) && (
                    <LoadingSpinner className="mr-2" />
                  )}
                </Button>
              </div>
            )}
          </div>
        </footer>
      </div>
    </div>
  );
}
