import type { SelectablePeopleTypes } from '@assembly-web/services';
import { type ReactNode, useMemo } from 'react';
import { defineMessages, useIntl } from 'react-intl';

import { RadioGroup } from '../../../DesignSystem/Inputs/RadioGroup';
import { BlockLabel } from './BlockLabel';

const messages = defineMessages({
  label: {
    defaultMessage: 'Choose who can be selected',
    id: 'GoSXyx',
  },
  everyone: {
    defaultMessage: 'Anyone in the workspace',
    id: 'VrYA4j',
  },
  sameGroupAsPost: {
    defaultMessage: 'Same as the group of people who can post',
    id: '1fE/AV',
  },
  sameGroupAsView: {
    defaultMessage: 'Same as the group of people who can view posts',
    id: 'FYdp0P',
  },
  custom: {
    defaultMessage: `Custom{selectedCount, select,
        0 {}
        other { • <button>{selectedCount, number} people</button>}}`,
    id: '6QRJw6',
  },
});

type Option = {
  value: SelectablePeopleTypes;
  label: ReactNode;
  disabled?: boolean;
  onClick?: () => void;
};

export type BlockParticipantsProps = {
  disabled?: boolean;
  value: string;
  onValueChange: (value: string) => void;
  selectedCount: number;
  onCustomPeopleSelectorClick: () => void;
  onClickCustomSelection: () => void;
  simplifiedSelectionCriteria?: boolean;
};

export function BlockParticipants({
  disabled,
  onValueChange,
  value,
  selectedCount,
  simplifiedSelectionCriteria,
  onCustomPeopleSelectorClick,
  onClickCustomSelection,
}: BlockParticipantsProps) {
  const { formatMessage } = useIntl();
  console.log({ simplifiedSelectionCriteria });

  const options = useMemo(
    () =>
      [
        { label: formatMessage(messages.everyone), value: 'EVERYONE' },
        ...(!simplifiedSelectionCriteria
          ? ([
              {
                label: formatMessage(messages.sameGroupAsPost),
                value: 'PARTICIPANTS',
              },
              {
                label: formatMessage(messages.sameGroupAsView),
                value: 'VIEWERS',
              },
            ] satisfies Option[])
          : []),
        {
          label: formatMessage(messages.custom, {
            selectedCount,
            button: (chunks: ReactNode) => (
              <button
                className="text-primary-6"
                onClick={(e) => {
                  e.stopPropagation();
                  onCustomPeopleSelectorClick();
                }}
              >
                {chunks}
              </button>
            ),
          }),
          ...(selectedCount > 0 && {
            onClick: onClickCustomSelection,
          }),
          value: 'CUSTOM',
        },
      ] satisfies Option[],
    [
      formatMessage,
      onClickCustomSelection,
      onCustomPeopleSelectorClick,
      selectedCount,
      simplifiedSelectionCriteria,
    ]
  );

  return (
    <div className="flex flex-col gap-2">
      <BlockLabel>{formatMessage(messages.label)}</BlockLabel>
      <RadioGroup.Root
        value={value}
        onValueChange={onValueChange}
        disabled={disabled}
      >
        {options.map(({ label, value, ...tail }) => (
          <RadioGroup.Item key={value} value={value} {...tail}>
            {label}
          </RadioGroup.Item>
        ))}
      </RadioGroup.Root>
    </div>
  );
}
