import type {
  AppFilterOption,
  CollectionsOption,
  FlowFilterOption,
  PrimaryOptions,
} from '../NestedFilterList';
import { NestedFilterList } from '../NestedFilterList';
import type { SelectableOptionProps } from '../SelectableList';

export type MenuItem = 'Flows' | 'Collections' | 'Apps';

export type InFilterSelectableListProps = {
  isLoading: boolean;
  onLoadMore: () => void;
  isFetchingNextPage?: boolean;
  onSearchChange: (value: string) => void;
  onMenuSelected: (menuItem: MenuItem | '') => void;
  flowsOptions: {
    options: FlowFilterOption[];
    selectedOptions: FlowFilterOption[];
  };
  collectionsOptions: {
    options: CollectionsOption[];
    selectedOptions: CollectionsOption[];
  };
  appsOptions: {
    options: AppFilterOption[];
    selectedOptions: AppFilterOption[];
  };
  onDoneClick: (currentSelectedOptions: SelectableOptionProps[]) => void;
  hasNextPage?: boolean;
  onClearAllClick: () => void;
  menuWithSelectedOption: MenuItem;
};

export function InFilterSelectableList({
  isLoading,
  onLoadMore,
  onDoneClick,
  onClearAllClick,
  onSearchChange,
  onMenuSelected,
  appsOptions,
  isFetchingNextPage,
  flowsOptions,
  collectionsOptions,
  hasNextPage,
  menuWithSelectedOption,
}: InFilterSelectableListProps) {
  const primaryOptions: PrimaryOptions = {
    Flows: {
      value: 'Flows',
      options: flowsOptions.options,
      selectedOptions: flowsOptions.selectedOptions,
    },
    Collections: {
      value: 'Collections',
      options: collectionsOptions.options,
      selectedOptions: collectionsOptions.selectedOptions,
    },
    Apps: {
      value: 'Apps',
      options: appsOptions.options,
      selectedOptions: appsOptions.selectedOptions,
    },
  };

  return (
    <NestedFilterList
      showCTAs
      showSearch={menuWithSelectedOption !== 'Apps'}
      isLoading={isLoading}
      onLoadMore={onLoadMore}
      onSearchChange={onSearchChange}
      isFetchingNextPage={isFetchingNextPage}
      hasNextPage={hasNextPage}
      primaryOptions={primaryOptions}
      onMenuSelected={(selected: string) =>
        onMenuSelected(selected as MenuItem)
      }
      onDoneClick={onDoneClick}
      onClearAllClick={onClearAllClick}
      className={{ container: 'w-[280px]' }}
    />
  );
}
