import { errorImage } from '@assembly-web/assets';
import { defineMessages, useIntl } from 'react-intl';

import { TextStyle } from '../../../DesignSystem/Feedback/TextStyle';

export type ErrorProps = {
  type: 'template' | 'edit' | 'scratch' | 'duplicate';
};

const messages = defineMessages({
  alt: { defaultMessage: 'Error in loading data', id: '/I+j7E' },
  title: {
    defaultMessage: `{type, select,
        template {Whoops! We couldn’t find this template.}
        edit {Whoops! We couldn’t find this flow.}
        duplicate {Whoops! We couldn’t find this flow.}
        other {Whoops! Something went wrong.}
      }`,
    id: 'l7YIA0',
  },
  description: {
    defaultMessage: `{type, select,
      template {Please try another template.}
      edit {Please try editing a different flow or create a new flow.}
      duplicate {Please try duplicating a different flow or create a new flow.}
      other {Please try again later or select a different flow.}
    }`,
    id: 'Yfk8EO',
  },
});

export const Error = ({ type }: ErrorProps) => {
  const { formatMessage } = useIntl();

  return (
    <section className="flex flex-1 flex-col items-center justify-center gap-8">
      <img
        className="h-[125px] w-[140px]"
        src={errorImage}
        alt={formatMessage(messages.alt)}
      />
      <div className="flex flex-col items-center gap-1">
        <TextStyle variant="base-medium">
          {formatMessage(messages.title, { type })}
        </TextStyle>
        <TextStyle variant="sm-regular">
          {formatMessage(messages.description, { type })}
        </TextStyle>
      </div>
    </section>
  );
};
