export function inverseMap<T extends string, U extends string>(
  map: Record<T, U>
): Record<U, T> {
  const inverseMap: Record<U, T> = {} as Record<U, T>;

  for (const key in map) {
    const value = map[key];
    inverseMap[value] = key;
  }

  return inverseMap;
}
