import { SparklesIcon } from '@heroicons/react/24/outline';
import dayjs from 'dayjs';
import relativeTimePlugin from 'dayjs/plugin/relativeTime';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import { defineMessages, useIntl } from 'react-intl';

import { TextStyle } from '../../../../../DesignSystem/Feedback/TextStyle';
import { OverflowCard } from '.';

dayjs.extend(relativeTimePlugin);
dayjs.extend(utc);
dayjs.extend(timezone);

export type AskDoraOverflowCardProps = {
  /** Whether there is new activity that should be highlighted */
  hasNewActivity?: boolean;

  /** Determines if data is loading in */
  isLoading: boolean;

  /** Timestamp of last message */
  lastMessageTS?: string;

  /** Number of messages */
  numMessages: number;

  /** Title shown as first line in card */
  title: string;

  /** Event handler called when the drawer gets closed out */
  onClose: () => void;

  /** Event handler called when the drawer gets clicked on */
  onClick: () => void;
};

const messages = defineMessages({
  numMessages: {
    defaultMessage:
      '{numMessages, plural, =1 {1 message} other {{numMessages} messages}}',
    id: 'seXdl3',
  },
  lastMessage: {
    defaultMessage: 'Last message {timeAgo}',
    id: 'wAV5Nq',
  },
  newActivity: {
    defaultMessage: 'Your report is ready!',
    id: 'gEC9ZY',
  },
});

export function AskDoraOverflowCard(props: AskDoraOverflowCardProps) {
  const {
    hasNewActivity,
    isLoading,
    numMessages,
    title,
    lastMessageTS,
    onClose,
    onClick,
  } = props;

  const { formatMessage } = useIntl();

  const avatar = (
    <div className="rounded bg-gradient-to-r from-[#1d3fc8] via-purple-7 to-[#a309ca] p-2">
      <SparklesIcon className="h-6 w-6 text-gray-1" />
    </div>
  );

  const content = isLoading ? (
    <div className="h-5 w-full max-w-[180px] animate-pulse rounded bg-gray-5" />
  ) : (
    <>
      <TextStyle
        as="span"
        variant="xs-medium"
        className="mr-1.5 text-primary-6"
      >
        {hasNewActivity
          ? formatMessage(messages.newActivity)
          : formatMessage(messages.numMessages, {
              numMessages,
            })}
      </TextStyle>
      {Boolean(lastMessageTS) && (
        <TextStyle as="span" variant="xs-regular" className="text-gray-8">
          {formatMessage(messages.lastMessage, {
            timeAgo: dayjs(lastMessageTS).tz(dayjs.tz.guess()).fromNow(),
          })}
        </TextStyle>
      )}
    </>
  );

  return (
    <OverflowCard
      hasNewActivity={hasNewActivity}
      title={title}
      content={content}
      avatar={avatar}
      onClose={onClose}
      onClick={onClick}
    />
  );
}
