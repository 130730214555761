import {
  DevicePhoneMobileIcon,
  EnvelopeIcon,
} from '@heroicons/react/24/outline';
import { Content, Root, Trigger } from '@radix-ui/react-collapsible';
import { useState } from 'react';
import { useIntl } from 'react-intl';

import { TextStyle } from '../../../../../DesignSystem/Feedback/TextStyle';
import { Button } from '../../../../../DesignSystem/Inputs/Button';
import { ToggleSwitch } from '../../../../../DesignSystem/Inputs/ToggleSwitch';
import { messages } from '../messages';
import { useAnnouncementForm } from '../useAnnouncementForm';

export function DistributionTabContent() {
  const { formValues, setFormValue } = useAnnouncementForm();
  const { formatMessage } = useIntl();

  const [isEmailOptionExpanded, setIsEmailOptionExpanded] = useState(false);
  const [isMobileOptionExpanded, setIsMobileOptionExpanded] = useState(false);

  return (
    <section>
      <TextStyle variant="base-medium" className="text-gray-9">
        {formatMessage(messages.distributionAudienceSettingsLabel)}
      </TextStyle>
      <TextStyle variant="xs-regular" className="text-gray-9">
        {formatMessage(messages.distributionAudienceSettingsDescriptionLabel)}
      </TextStyle>

      <Root
        open={isEmailOptionExpanded}
        onOpenChange={setIsEmailOptionExpanded}
        disabled={Boolean(formValues.announcementId)}
        className="mt-3 rounded-lg border border-solid border-gray-5"
      >
        <div className="flex items-center justify-between px-3">
          <div className="flex items-center justify-center gap-2">
            <EnvelopeIcon className="h-5 w-5 text-gray-9" />
            <TextStyle variant="base-medium" className="text-gray-9">
              {formatMessage(messages.emailLabel)}
            </TextStyle>
            <TextStyle variant="sm-regular" className="text-gray-9">
              {formValues.isEmailNotificationEnabled
                ? formatMessage(messages.onLaunchLabel)
                : formatMessage(messages.noneLabel)}
            </TextStyle>
          </div>
          <Trigger asChild>
            <Button
              className="my-2 h-8"
              variation={
                isEmailOptionExpanded ? 'tertiaryLite' : 'tertiaryEmphasized'
              }
            >
              {isEmailOptionExpanded
                ? `${formatMessage(messages.closeLabel)}`
                : `${formatMessage(messages.editLabel)}`}
            </Button>
          </Trigger>
        </div>

        <Content className="px-3 pb-3">
          <div className="flex items-center gap-3">
            <TextStyle variant="sm-medium">
              {formatMessage(messages.notifyOnLaunch)}
            </TextStyle>
            <ToggleSwitch
              checked={formValues.isEmailNotificationEnabled}
              onCheckedChange={(checked) => {
                setFormValue('isEmailNotificationEnabled', checked);
              }}
              aria-label={formatMessage(messages.notifyOnLaunch)}
            />
          </div>
          <TextStyle variant="sm-regular" className="mt-1 flex-1">
            {formatMessage(messages.emailNotifyOnLaunchDescription)}
          </TextStyle>
        </Content>
      </Root>

      <Root
        open={isMobileOptionExpanded}
        onOpenChange={setIsMobileOptionExpanded}
        disabled={Boolean(formValues.announcementId)}
        className="mt-3 rounded-lg border border-solid border-gray-5"
      >
        <div className="flex items-center justify-between px-3">
          <div className="flex items-center justify-center gap-2">
            <DevicePhoneMobileIcon className="h-5 w-5 text-gray-9" />
            <TextStyle variant="base-medium" className="text-gray-9">
              {formatMessage(messages.mobileAppLabel)}
            </TextStyle>
            <TextStyle variant="sm-regular" className="text-gray-9">
              {formValues.isPushNotificationEnabled
                ? formatMessage(messages.onLaunchLabel)
                : formatMessage(messages.noneLabel)}
            </TextStyle>
          </div>
          <Trigger asChild>
            <Button
              className="my-2 h-8"
              variation={
                isMobileOptionExpanded ? 'tertiaryLite' : 'tertiaryEmphasized'
              }
            >
              {isMobileOptionExpanded
                ? `${formatMessage(messages.closeLabel)}`
                : `${formatMessage(messages.editLabel)}`}
            </Button>
          </Trigger>
        </div>

        <Content className="px-3 pb-3">
          <div className="flex items-center gap-3">
            <TextStyle variant="sm-medium">
              {formatMessage(messages.notifyOnLaunch)}
            </TextStyle>
            <ToggleSwitch
              checked={formValues.isPushNotificationEnabled}
              onCheckedChange={(checked) => {
                setFormValue('isPushNotificationEnabled', checked);
              }}
              aria-label={formatMessage(messages.notifyOnLaunch)}
            />
          </div>
          <TextStyle variant="sm-regular" className="mt-1 flex-1">
            {formatMessage(messages.mobileAppNotifyOnLaunchDescription)}
          </TextStyle>
        </Content>
      </Root>
    </section>
  );
}
