import { useIntl } from 'react-intl';
import ReactPlayer from 'react-player/lazy';

import { messages } from '../FilePreviewer';

export const VideoPreviewer = ({
  src,
  downloadEnabled = false,
}: {
  src: string;
  downloadEnabled?: boolean;
}) => {
  const { formatMessage } = useIntl();
  return (
    <ReactPlayer controls url={src} width="100%" height="100%">
      {formatMessage(messages.videoNotSupported, {
        downloadEnabled,
      })}
    </ReactPlayer>
  );
};
