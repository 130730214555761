import { Square2StackIcon, XMarkIcon } from '@heroicons/react/24/outline';
import { Slot } from '@radix-ui/react-slot';
import { forwardRef, type ReactNode, useEffect, useRef } from 'react';
import { defineMessages, useIntl } from 'react-intl';
import { twJoin } from 'tailwind-merge';

import { TextStyle } from '../../../DesignSystem/Feedback/TextStyle';
import { Button } from '../../../DesignSystem/Inputs/Button';
import { IconButton } from '../../../DesignSystem/Inputs/IconButton';
import {
  TextField,
  type TextFieldProps,
} from '../../../DesignSystem/Inputs/TextField';
import { BlockLabel } from './BlockLabel';

const messages = defineMessages({
  optionPlaceholder: {
    defaultMessage: 'Option {index}',
    id: 'NxWYm3',
  },
  index: { defaultMessage: '{index}.', id: 'blMj2j' },
  duplicateOption: {
    defaultMessage: 'Duplicate option',
    id: '7p3TPI',
  },
  removeOption: {
    defaultMessage: 'Remove option',
    id: 'UOYm/I',
  },
  newOption: {
    defaultMessage: 'Add an option',
    id: 'XCQWW7',
  },
  addOtherOption: {
    defaultMessage: 'Add an ‘Other’ option',
    id: 'wnRjfx',
  },
  other: {
    defaultMessage: 'Other:',
    id: '8HC469',
  },
  otherOptionPlaceholder: {
    defaultMessage: 'Respondents can write in an answer here',
    id: '+iStdx',
  },
});

type ActionProps = {
  disabled?: boolean;
  onClick: () => void;
};

type OptionDescriptionProps = Pick<
  TextFieldProps,
  'disabled' | 'onChange' | 'value'
> & {
  index: number;
  onPressEnter?: () => void;
};

const Item = forwardRef<
  HTMLLIElement,
  {
    asChild?: boolean;
    children: ReactNode;
  }
>(function Root({ asChild, children }, ref) {
  const Comp = asChild ? Slot : 'li';

  return (
    <Comp ref={ref} className="relative pr-8">
      {children}
    </Comp>
  );
});

function Container({
  children,
  highlighted = true,
}: {
  children: ReactNode;
  highlighted?: boolean;
}) {
  return (
    <div
      className={twJoin(
        'grid grid-cols-[24px,1fr,24px,24px] items-center gap-2 p-1',
        highlighted &&
          'hover:rounded-lg hover:bg-gray-3 [&:has(:focus)]:rounded-lg [&:has(:focus)]:bg-gray-3 [&:has([data-focused=true])]:rounded-lg [&:has([data-focused=true])]:bg-gray-3'
      )}
    >
      {children}
    </div>
  );
}

function OptionNumber({ children }: { children: number }) {
  const { formatMessage } = useIntl();

  return (
    <TextStyle as="span" variant="sm-regular" className="justify-self-end">
      {formatMessage(messages.index, { index: children })}
    </TextStyle>
  );
}

function OptionDescription({
  disabled,
  index,
  onChange,
  onPressEnter,
  value,
}: OptionDescriptionProps) {
  const { formatMessage } = useIntl();
  const ref = useRef<HTMLInputElement | null>(null);

  useEffect(() => {
    if (ref.current) {
      ref.current.focus();
    }
  }, []);

  return (
    <TextField
      ref={ref}
      value={value}
      onChange={onChange}
      placeholder={formatMessage(messages.optionPlaceholder, { index })}
      onKeyDown={(e) => {
        if (e.key === 'Enter') {
          onPressEnter?.();
        }
      }}
      disabled={disabled}
      inputClassName="px-4 py-2.5 text-gray-9 text-sm font-normal rounded-lg"
    />
  );
}

function OptionDuplicate({ disabled, onClick }: ActionProps) {
  const { formatMessage } = useIntl();

  return (
    <IconButton
      onClick={onClick}
      variation="tertiaryLite"
      size="xSmall"
      className="bg-transparent"
      aria-label={formatMessage(messages.duplicateOption)}
      disabled={disabled}
    >
      <Square2StackIcon aria-hidden />
    </IconButton>
  );
}

function OptionRemove({ disabled, onClick }: ActionProps) {
  const { formatMessage } = useIntl();

  return (
    <IconButton
      onClick={onClick}
      variation="tertiaryLite"
      size="xSmall"
      className="bg-transparent"
      aria-label={formatMessage(messages.removeOption)}
      disabled={disabled}
    >
      <XMarkIcon aria-hidden />
    </IconButton>
  );
}

function AddNewOption({ disabled, onClick }: ActionProps) {
  const { formatMessage } = useIntl();

  return (
    <Button
      size="small"
      variation="secondaryLite"
      className="w-fit"
      onClick={(e) => {
        e.currentTarget.blur();

        onClick();
      }}
      disabled={disabled}
    >
      {formatMessage(messages.newOption)}
    </Button>
  );
}

function AddOtherOption({ disabled, onClick }: ActionProps) {
  const { formatMessage } = useIntl();

  return (
    <button
      className="w-fit text-sm font-normal text-primary-6 underline"
      onClick={(e) => {
        e.currentTarget.blur();

        onClick();
      }}
      disabled={disabled}
    >
      {formatMessage(messages.addOtherOption)}
    </button>
  );
}

function OtherOption() {
  const { formatMessage } = useIntl();

  return (
    <div className="flex items-center gap-2">
      <TextStyle as="span" variant="sm-regular">
        {formatMessage(messages.other)}
      </TextStyle>
      <TextStyle
        as="span"
        variant="sm-regular"
        className="w-full rounded-lg border border-gray-5 px-3 py-2"
        subdued
      >
        {formatMessage(messages.otherOptionPlaceholder)}
      </TextStyle>
    </div>
  );
}

function Root({
  asChild,
  children,
  blockLabel,
}: {
  asChild?: boolean;
  children: ReactNode;
  blockLabel: string;
}) {
  const Comp = asChild ? Slot : 'ol';

  return (
    <section className="flex flex-col gap-1">
      <BlockLabel>{blockLabel}</BlockLabel>
      <Comp className="relative flex flex-col">{children}</Comp>
    </section>
  );
}

export const BlockMultiOption = {
  AddNewOption,
  AddOtherOption,
  Container,
  Item,
  OptionDescription,
  OptionDuplicate,
  OptionNumber,
  OptionRemove,
  OtherOption,
  Root,
};
