import { Avatar, AvatarSize } from '../../../DesignSystem/Feedback/Avatar';

export function PostCardFooterHiddenState({
  image,
  name,
  currentMemberId,
  text,
}: {
  name: string;
  image?: string;
  currentMemberId: string;
  text: string;
}) {
  return (
    <div>
      <div className="flex gap-2 py-2 align-middle">
        <Avatar
          size={AvatarSize.Medium}
          image={image}
          name={name}
          memberID={currentMemberId}
          className="mix-blend-luminosity"
        />
        <div className="flex items-center">
          <span className="text-gray-8">{text}</span>
        </div>
      </div>
    </div>
  );
}
