import { useEffect } from 'react';

import { postMessageToMobileApp } from '../mobile';
import { AppAction } from '../types/mobile';

export const useMobileLoaded = () => {
  useEffect(() => {
    postMessageToMobileApp({
      action: AppAction.FeedLoaded,
    });
  }, []);
};
