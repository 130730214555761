import type { PortalProps as RadixPortalProps } from '@radix-ui/react-portal';
import { Root as PortalRoot } from '@radix-ui/react-portal';
import type { ReactNode } from 'react';

type PortalComponentProps = {
  children: ReactNode;
} & RadixPortalProps;

export const Portal = (props: PortalComponentProps) => {
  const { children, ...rest } = props;
  return <PortalRoot {...rest}>{children}</PortalRoot>;
};
