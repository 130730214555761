import type { ReactNode } from 'react';
import AutoSizer from 'react-virtualized-auto-sizer';
import { FixedSizeList, VariableSizeList } from 'react-window';
import InfiniteLoader from 'react-window-infinite-loader';

export function FixedVirtualizedList<T>({
  itemCount,
  items,
  onLoadMore,
  renderItem,
  fixedItemSize,
}: {
  itemCount: number;
  fixedItemSize: number;
  items: T[];
  onLoadMore: () => void;
  renderItem: (item: T, index?: number) => ReactNode;
}) {
  return (
    <AutoSizer className="overflow-hidden">
      {({ height, width }) => (
        <InfiniteLoader
          itemCount={itemCount}
          loadMoreItems={onLoadMore}
          isItemLoaded={(index) => index < items.length}
        >
          {({ onItemsRendered, ref }) => (
            <FixedSizeList
              ref={ref}
              width={width}
              itemSize={fixedItemSize}
              height={height}
              itemCount={itemCount}
              onItemsRendered={onItemsRendered}
            >
              {({ index, style }) => {
                if (index >= items.length) {
                  return null;
                }
                const item = items[index];
                return (
                  <div style={style} className="w-full">
                    {renderItem(item, index)}
                  </div>
                );
              }}
            </FixedSizeList>
          )}
        </InfiniteLoader>
      )}
    </AutoSizer>
  );
}

export function VariableSizeVirtualizedList<T>({
  itemCount,
  items,
  onLoadMore,
  renderItem,
  itemSize,
  estimatedItemSize,
}: {
  itemCount: number;
  items: T[];
  onLoadMore: () => void;
  renderItem: (item: T, index?: number) => ReactNode;
  itemSize: (index: number) => number;
  estimatedItemSize: number;
}) {
  return (
    <AutoSizer className="overflow-hidden">
      {({ height, width }) => (
        <InfiniteLoader
          itemCount={itemCount}
          loadMoreItems={onLoadMore}
          isItemLoaded={(index) => index < items.length}
        >
          {({ onItemsRendered, ref }) => (
            <VariableSizeList
              estimatedItemSize={estimatedItemSize}
              itemSize={itemSize}
              ref={ref}
              width={width}
              height={height}
              itemCount={itemCount}
              onItemsRendered={onItemsRendered}
            >
              {({ index, style }) => {
                const item = items[index];
                return (
                  <div style={style} className="w-full">
                    {renderItem(item, index)}
                  </div>
                );
              }}
            </VariableSizeList>
          )}
        </InfiniteLoader>
      )}
    </AutoSizer>
  );
}
