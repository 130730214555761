import { parseColor } from '@react-stately/color';
import type { CSSProperties } from 'react';

import type { Assembly } from '../types/domain';

type RGB = {
  r: number;
  g: number;
  b: number;
};

function hexToRgb(hex: string, defaultValue: string): RGB {
  try {
    const color = parseColor(hex).toFormat('rgb');
    return {
      r: color.getChannelValue('red'),
      g: color.getChannelValue('green'),
      b: color.getChannelValue('blue'),
    };
  } catch {
    const color = parseColor(defaultValue).toFormat('rgb');
    return {
      r: color.getChannelValue('red'),
      g: color.getChannelValue('green'),
      b: color.getChannelValue('blue'),
    };
  }
}

export const defaultWorkspaceBackground = {
  light: '#E8E8E8',
  dark: '#262626',
} as const;

export function useThemeStyle({
  theme,
  appMode = 'light',
  applyOpacity = true,
}: {
  theme: Assembly['settings']['theme'];
  appMode?: 'light' | 'dark';
  applyOpacity?: boolean;
}) {
  const defaultBackgroundColor = defaultWorkspaceBackground[appMode];
  const backgroundColor = theme?.backgroundColor ?? defaultBackgroundColor;

  const { r, g, b } = hexToRgb(backgroundColor, defaultBackgroundColor);

  const background = `rgba(${r},${g},${b},${applyOpacity ? 0.3 : 1})`;

  return { background } satisfies CSSProperties;
}
