import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import { useEffect } from 'react';

import { getSerializedDataFromEditor } from '../utils';

export function ExternalOnChangePlugin({
  onChange,
  ignoreSelectionChange = true,
  ignoreHistoryMergeTagChange = true,
}: {
  onChange: ({
    html,
    json,
    plainText,
  }: {
    html: string;
    json: string;
    plainText: string;
  }) => void;
  ignoreSelectionChange?: boolean;
  ignoreHistoryMergeTagChange?: boolean;
}) {
  const [editor] = useLexicalComposerContext();

  useEffect(() => {
    return editor.registerUpdateListener(({ editorState, tags }) => {
      if (ignoreSelectionChange && tags.has('isSelectionChange')) {
        return;
      }

      if (ignoreHistoryMergeTagChange && tags.has('history-merge')) {
        return;
      }

      editorState.read(() => {
        const { html, json, plainText } = getSerializedDataFromEditor(editor);

        onChange({
          html,
          json,
          plainText,
        });
      });
    });
  }, [editor, ignoreHistoryMergeTagChange, ignoreSelectionChange, onChange]);

  return null;
}
