import { browserUtils } from '@assembly-web/services';
import { ListBulletIcon } from '@heroicons/react/24/outline';
import {
  $createLinkNode,
  $isLinkNode,
  TOGGLE_LINK_COMMAND,
} from '@lexical/link';
import {
  $isListNode,
  INSERT_ORDERED_LIST_COMMAND,
  INSERT_UNORDERED_LIST_COMMAND,
  ListNode,
  REMOVE_LIST_COMMAND,
} from '@lexical/list';
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import { $createQuoteNode, $isHeadingNode } from '@lexical/rich-text';
import {
  $getSelectionStyleValueForProperty,
  $setBlocksType,
} from '@lexical/selection';
import {
  $findMatchingParent,
  $getNearestNodeOfType,
  mergeRegister,
} from '@lexical/utils';
import {
  $createParagraphNode,
  $createTextNode,
  $getSelection,
  $insertNodes,
  $isElementNode,
  $isRangeSelection,
  $isRootOrShadowRoot,
  CAN_REDO_COMMAND,
  CAN_UNDO_COMMAND,
  COMMAND_PRIORITY_CRITICAL,
  COMMAND_PRIORITY_NORMAL,
  type ElementFormatType,
  FORMAT_TEXT_COMMAND,
  KEY_MODIFIER_COMMAND,
  type NodeKey,
  SELECTION_CHANGE_COMMAND,
} from 'lexical';
import {
  forwardRef,
  type ForwardRefExoticComponent,
  Fragment,
  type FunctionComponent,
  type ReactNode,
  type RefAttributes,
  type SVGProps,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { defineMessages, FormattedMessage, useIntl } from 'react-intl';
import { twMerge } from 'tailwind-merge';

import { useRefContainer } from '../../../../../context/RefContext';
import { Tooltip } from '../../../../../DesignSystem/Feedback/Tooltip';
import {
  BlockQuoteIcon,
  FontBoldIcon,
  FontItalicIcon,
  FontUnderlineIcon,
  LinkIcon,
  NumberedListIcon,
  StrikethroughIcon,
} from '../../../../../Icons';
import { useDeviceInfo } from '../../../../hooks/useDeviceInfo';
import { Popover } from '../../../../Shared/Popover';
import { AddLinkModal } from '../../base/components/AddLinkModal';
import { FormatDropdown } from '../../base/components/FormatDropdown';
import { Shortcut } from '../../base/components/Shortcut';
import { ToggleButton } from '../../base/components/ToggleButton';
import { getSelectedNode } from '../../base/utils/getSelectedNode';
import { sanitizeUrlForEditor } from '../../base/utils/sanitizeUrlForEditor';

const messages = defineMessages({
  quoteLabel: {
    defaultMessage: 'Blockquote. Shortcut: {shortcut}',
    id: 'zVnJ4c',
  },
  headingLevelLabel: {
    defaultMessage: 'Heading {level}',
    id: '/seaK6',
  },
  codeBlockLabel: {
    defaultMessage: 'Code Block',
    id: 'fJFvPm',
  },
  normalLabel: {
    defaultMessage: 'Normal',
    id: 'myq2ZL',
  },
  checkListLabel: {
    defaultMessage: 'Check List',
    id: 'D7jtXb',
  },
  bulletListLabel: {
    defaultMessage: 'Bulleted List. Shortcut: {shortcut}',
    id: 'KHwgfK',
  },
  numberedListLabel: {
    defaultMessage: 'Numbered List. Shortcut: {shortcut}',
    id: 'VCc8yg',
  },
  formatBoldLabel: {
    defaultMessage: 'Bold. Shortcut: {shortcut}',
    id: 'K007XB',
  },
  formatItalicLabel: {
    defaultMessage: 'Italic. Shortcut: {shortcut}',
    id: 'B0e03J',
  },
  formatUnderlineLabel: {
    defaultMessage: 'Underline. Shortcut: {shortcut}',
    id: 'PHRRyJ',
  },
  formatStrikethroughLabel: {
    defaultMessage: 'Strikethrough. Shortcut: {shortcut}',
    id: 'SaaQxn',
  },
  link: {
    defaultMessage: 'Link',
    id: 'JBWS0c',
  },
});
const { isMac, isMobile } = browserUtils;

const shortCutMap = {
  bold: isMac ? '⌘ cmd + B' : 'ctrl + B',
  italic: isMac ? '⌘ cmd + I' : 'ctrl + I',
  underline: isMac ? '⌘ cmd + U' : 'ctrl + U',
  strikethrough: isMac ? '⌘ cmd + shift + X' : 'ctrl + shift + X',
  bulletList: isMac ? '⌘ cmd + shift + 8' : 'ctrl + shift + 8',
  numberedList: isMac ? '⌘ cmd + shift + 7' : 'ctrl + shift + 7',
  quote: isMac ? '⌘ cmd + shift + 9' : 'ctrl + shift + 9',
  link: isMac ? '⌘ cmd + L' : 'ctrl + L',
};

type HeaderDisplayType = 'default' | 'compact';

type RepliesHeaderPluginImplProps = {
  type?: HeaderDisplayType;
  style?: React.CSSProperties;
  hideBottomBorder?: boolean;
  hideHeadingDropdown?: boolean;
  className?: string;
};

type RepliesHeaderPluginProps = {
  isVisible: boolean;
} & RepliesHeaderPluginImplProps;

type Option =
  | {
      type: 'component';
      Component: JSX.Element;
      visible: boolean;
    }
  | { type: 'divider'; visible: boolean }
  | {
      type: 'button';
      disabled: boolean;
      onClick: () => void;
      label: string;
      tooltip?: JSX.Element;
      enabled: boolean;
      icon:
        | string
        | FunctionComponent<
            SVGProps<SVGSVGElement> & {
              title?: string | undefined;
            }
          >
        | ForwardRefExoticComponent<
            Omit<SVGProps<SVGSVGElement>, 'ref'> & {
              title?: string | undefined;
              titleId?: string | undefined;
            } & RefAttributes<SVGSVGElement>
          >;
      visible: boolean;
    };

const containerStyle = {
  compact: 'h-8 shadow-elevation-2dp gap-x-1 bg-gray-1 rounded-lg',
  default: 'h-10 border-b border-gray-5 gap-x-2',
} satisfies Record<HeaderDisplayType, string>;

const TooltipShortcutWrapper = ({ children }: { children: ReactNode }) => (
  <>
    <br />
    <span className="flex items-center justify-center gap-1">{children}</span>
  </>
);

const RepliesHeaderPluginImpl = forwardRef<
  HTMLDivElement,
  RepliesHeaderPluginImplProps
>(function RepliesHeaderPluginImpl(
  {
    type = 'default',
    style,
    hideHeadingDropdown = false,
    hideBottomBorder = false,
    className,
  },
  ref
) {
  const { formatMessage } = useIntl();

  const blockTypeToBlockName = useMemo(() => {
    // TODO: Add shortcuts
    return {
      quote: formatMessage(messages.quoteLabel, { shortcut: '' }),
      h1: formatMessage(messages.headingLevelLabel, { level: 1 }),
      h2: formatMessage(messages.headingLevelLabel, { level: 2 }),
      h3: formatMessage(messages.headingLevelLabel, { level: 3 }),
      h4: formatMessage(messages.headingLevelLabel, { level: 4 }),
      h5: formatMessage(messages.headingLevelLabel, { level: 5 }),
      h6: formatMessage(messages.headingLevelLabel, { level: 6 }),
      code: formatMessage(messages.codeBlockLabel),
      check: formatMessage(messages.checkListLabel),
      paragraph: formatMessage(messages.normalLabel),
      bullet: formatMessage(messages.bulletListLabel, { shortcut: '' }),
      number: formatMessage(messages.numberedListLabel, { shortcut: '' }),
    };
  }, [formatMessage]);

  const { deviceType } = useDeviceInfo();
  const isMobileDevice = deviceType === 'mobile' || isMobile;

  const [editor] = useLexicalComposerContext();
  const [activeEditor, setActiveEditor] = useState(editor);

  const [selectedText, setSelectedText] = useState<string>('');
  const [selectedURL, setSelectedURL] = useState('');

  const [blockType, setBlockType] =
    useState<keyof typeof blockTypeToBlockName>('paragraph');

  const [, setSelectedElementKey] = useState<NodeKey | null>(null);

  const [, setFontSize] = useState<string>('1rem');
  const [, setFontColor] = useState<string>('#000');
  const [, setBgColor] = useState<string>('#fff');
  const [, setFontFamily] = useState<string>('Roboto');
  const [, setElementFormat] = useState<ElementFormatType>('left');
  const [isLink, setIsLink] = useState(false);
  const [isBold, setIsBold] = useState(false);
  const [isItalic, setIsItalic] = useState(false);
  const [isUnderline, setIsUnderline] = useState(false);
  const [isStrikethrough, setIsStrikethrough] = useState(false);
  const [, setCanUndo] = useState(false);
  const [, setCanRedo] = useState(false);
  const [showURLPicker, setShowURLPicker] = useState<boolean>(false);
  const [isEditable, setIsEditable] = useState(() => editor.isEditable());

  const containerRef = useRefContainer();

  const formatQuote = () => {
    editor.update(() => {
      const selection = $getSelection();
      if (!$isRangeSelection(selection)) return;
      if (blockType !== 'quote') {
        $setBlocksType(selection, () => $createQuoteNode());
      } else {
        $setBlocksType(selection, () => $createParagraphNode());
      }
    });
  };

  const $updateToolbar = useCallback(() => {
    const selection = $getSelection();
    if ($isRangeSelection(selection)) {
      const anchorNode = selection.anchor.getNode();
      let element =
        anchorNode.getKey() === 'root'
          ? anchorNode
          : $findMatchingParent(anchorNode, (e) => {
              const parent = e.getParent();
              return parent !== null && $isRootOrShadowRoot(parent);
            });

      if (element === null) {
        element = anchorNode.getTopLevelElementOrThrow();
      }

      const elementKey = element.getKey();
      const elementDOM = activeEditor.getElementByKey(elementKey);

      setSelectedText($getSelection()?.getTextContent() ?? '');

      if (!$getSelection()?.getTextContent()) {
        setSelectedURL('');
      }

      // Update text format
      setIsBold(selection.hasFormat('bold'));
      setIsItalic(selection.hasFormat('italic'));
      setIsUnderline(selection.hasFormat('underline'));
      setIsStrikethrough(selection.hasFormat('strikethrough'));

      // Update links
      const node = getSelectedNode(selection);
      const parent = node.getParent();
      if ($isLinkNode(parent) || $isLinkNode(node)) {
        setIsLink(true);
      } else {
        setIsLink(false);
      }

      if (elementDOM !== null) {
        setSelectedElementKey(elementKey);
        if ($isListNode(element)) {
          const parentList = $getNearestNodeOfType<ListNode>(
            anchorNode,
            ListNode
          );
          const type = parentList
            ? parentList.getListType()
            : element.getListType();
          setBlockType(type);
        } else {
          const type = $isHeadingNode(element)
            ? element.getTag()
            : element.getType();
          if (type in blockTypeToBlockName) {
            setBlockType(type as keyof typeof blockTypeToBlockName);
          }
        }
      }
      // Handle buttons
      setFontSize(
        $getSelectionStyleValueForProperty(selection, 'font-size', '1rem')
      );
      setFontColor(
        $getSelectionStyleValueForProperty(selection, 'color', '#000')
      );
      setBgColor(
        $getSelectionStyleValueForProperty(
          selection,
          'background-color',
          '#fff'
        )
      );
      setFontFamily(
        $getSelectionStyleValueForProperty(selection, 'font-family', 'Roboto')
      );

      let matchingParent;
      const parentNode = getSelectedNode(selection).getParent();
      if ($isLinkNode(parentNode)) {
        setSelectedURL(parentNode.__url);
      }
      if ($isLinkNode(parent)) {
        // If node is a link, we need to fetch the parent paragraph node to set format
        matchingParent = $findMatchingParent(
          node,
          (parentNode) => $isElementNode(parentNode) && !parentNode.isInline()
        );
      }

      // If matchingParent is a valid node, pass its format type
      setElementFormat(
        $isElementNode(matchingParent)
          ? matchingParent.getFormatType()
          : $isElementNode(node)
            ? node.getFormatType()
            : (parent?.getFormatType() ?? 'left')
      );
    }
  }, [activeEditor, blockTypeToBlockName]);

  useEffect(() => {
    return editor.registerCommand(
      SELECTION_CHANGE_COMMAND,
      (_payload, newEditor) => {
        $updateToolbar();
        setActiveEditor(newEditor);
        return false;
      },
      COMMAND_PRIORITY_CRITICAL
    );
  }, [editor, $updateToolbar]);

  useEffect(() => {
    return mergeRegister(
      editor.registerEditableListener((editable) => {
        setIsEditable(editable);
      }),
      activeEditor.registerUpdateListener(({ editorState }) => {
        editorState.read(() => {
          $updateToolbar();
        });
      }),
      activeEditor.registerCommand<boolean>(
        CAN_UNDO_COMMAND,
        (payload) => {
          setCanUndo(payload);
          return false;
        },
        COMMAND_PRIORITY_CRITICAL
      ),
      activeEditor.registerCommand<boolean>(
        CAN_REDO_COMMAND,
        (payload) => {
          setCanRedo(payload);
          return false;
        },
        COMMAND_PRIORITY_CRITICAL
      )
    );
  }, [$updateToolbar, activeEditor, editor]);

  useEffect(() => {
    return activeEditor.registerCommand(
      KEY_MODIFIER_COMMAND,
      (payload) => {
        const event: KeyboardEvent = payload;
        const { code, ctrlKey, metaKey } = event;

        if (code === 'KeyL' && (ctrlKey || metaKey)) {
          event.preventDefault();
          if (!isLink) {
            setShowURLPicker(true);
          } else {
            setShowURLPicker(false);
          }
          return activeEditor.dispatchCommand(
            TOGGLE_LINK_COMMAND,
            sanitizeUrlForEditor('https://')
          );
        }
        return false;
      },
      COMMAND_PRIORITY_NORMAL
    );
  }, [activeEditor, isLink]);

  const closeLinkEditMode = () => {
    setShowURLPicker(false);
    setSelectedURL('');
  };

  const handleAddLink = ({ url, title }: { url: string; title?: string }) => {
    if (!url.startsWith('https://') && !url.startsWith('http://')) {
      url = `https://${url}`;
    }
    if (title === '') {
      title = url;
    }
    if (!selectedText) {
      editor.update(
        () => {
          const linkNode = $createLinkNode(url);
          const textNode = $createTextNode(title);
          linkNode.append(textNode);

          const spacer = $createTextNode(' ');

          $insertNodes([linkNode, spacer]);
          closeLinkEditMode();
        },
        {
          onUpdate: () => {
            setTimeout(() => editor.focus(), 0);
          },
        }
      );
      return;
    } else if (url === '') {
      editor.dispatchCommand(TOGGLE_LINK_COMMAND, null);
    } else {
      editor.dispatchCommand(TOGGLE_LINK_COMMAND, url);
    }

    closeLinkEditMode();
  };

  const options: Option[] = [
    {
      type: 'component',
      Component: (
        <FormatDropdown
          blockType={blockType}
          editor={activeEditor}
          disabled={!isEditable}
        />
      ),
      visible: type === 'default' && !hideHeadingDropdown,
    },
    {
      type: 'divider',
      visible: type === 'default' && !hideHeadingDropdown,
    },
    {
      type: 'button',
      disabled: !isEditable,
      onClick: () => {
        activeEditor.dispatchCommand(FORMAT_TEXT_COMMAND, 'bold');
      },
      label: formatMessage(messages.formatBoldLabel, {
        shortcut: shortCutMap.bold,
      }),
      tooltip: (
        <FormattedMessage
          defaultMessage="Bold<html>{shortcut}</html>"
          id="BFWh8u"
          values={{
            html: (text) => {
              return <TooltipShortcutWrapper>{text}</TooltipShortcutWrapper>;
            },
            shortcut: <Shortcut variant="dark">{shortCutMap.bold}</Shortcut>,
          }}
        />
      ),
      enabled: isBold,
      icon: FontBoldIcon,
      visible: true,
    },
    {
      type: 'button',
      disabled: !isEditable,
      onClick: () => {
        activeEditor.dispatchCommand(FORMAT_TEXT_COMMAND, 'italic');
      },
      label: formatMessage(messages.formatItalicLabel, {
        shortcut: shortCutMap.italic,
      }),
      tooltip: (
        <FormattedMessage
          defaultMessage="Italic<html>{shortcut}</html>"
          id="dZbUDA"
          values={{
            html: (text) => {
              return <TooltipShortcutWrapper>{text}</TooltipShortcutWrapper>;
            },
            shortcut: <Shortcut variant="dark">{shortCutMap.italic}</Shortcut>,
          }}
        />
      ),
      enabled: isItalic,
      icon: FontItalicIcon,
      visible: true,
    },
    {
      type: 'button',
      disabled: !isEditable,
      onClick: () => {
        activeEditor.dispatchCommand(FORMAT_TEXT_COMMAND, 'underline');
      },
      label: formatMessage(messages.formatUnderlineLabel, {
        shortcut: shortCutMap.underline,
      }),
      tooltip: (
        <FormattedMessage
          defaultMessage="Underline<html>{shortcut}</html>"
          id="RmQwjk"
          values={{
            html: (text) => {
              return <TooltipShortcutWrapper>{text}</TooltipShortcutWrapper>;
            },
            shortcut: (
              <Shortcut variant="dark">{shortCutMap.underline}</Shortcut>
            ),
          }}
        />
      ),
      enabled: isUnderline,
      icon: FontUnderlineIcon,
      visible: true,
    },
    {
      type: 'button',
      disabled: !isEditable,
      onClick: () => {
        activeEditor.dispatchCommand(FORMAT_TEXT_COMMAND, 'strikethrough');
      },
      label: formatMessage(messages.formatStrikethroughLabel, {
        shortcut: shortCutMap.strikethrough,
      }),
      tooltip: (
        <FormattedMessage
          defaultMessage="Strikethrough<html>{shortcut}</html>"
          id="0esCu+"
          values={{
            html: (text) => {
              return <TooltipShortcutWrapper>{text}</TooltipShortcutWrapper>;
            },
            shortcut: (
              <Shortcut variant="dark">{shortCutMap.strikethrough}</Shortcut>
            ),
          }}
        />
      ),
      enabled: isStrikethrough,
      icon: StrikethroughIcon,
      visible: true,
    },
    {
      type: 'divider',
      visible: true,
    },
    {
      type: 'button',
      disabled: !isEditable,
      onClick: () => {
        if (blockType !== 'bullet') {
          editor.dispatchCommand(INSERT_UNORDERED_LIST_COMMAND, undefined);
        } else {
          editor.dispatchCommand(REMOVE_LIST_COMMAND, undefined);
        }
      },
      label: formatMessage(messages.bulletListLabel, {
        shortcut: shortCutMap.bulletList,
      }),
      tooltip: (
        <FormattedMessage
          defaultMessage="Bulleted List<html>{shortcut}</html>"
          id="KjJHPn"
          values={{
            html: (text) => {
              return <TooltipShortcutWrapper>{text}</TooltipShortcutWrapper>;
            },
            shortcut: (
              <Shortcut variant="dark">{shortCutMap.bulletList}</Shortcut>
            ),
          }}
        />
      ),
      enabled: blockType === 'bullet',
      icon: ListBulletIcon,
      visible: true,
    },
    {
      type: 'button',
      disabled: !isEditable,
      onClick: () => {
        if (blockType !== 'number') {
          editor.dispatchCommand(INSERT_ORDERED_LIST_COMMAND, undefined);
        } else {
          editor.dispatchCommand(REMOVE_LIST_COMMAND, undefined);
        }
      },
      label: formatMessage(messages.numberedListLabel, {
        shortcut: shortCutMap.numberedList,
      }),
      tooltip: (
        <FormattedMessage
          defaultMessage="Numbered List<html>{shortcut}</html>"
          id="YI/9Wc"
          values={{
            html: (text) => {
              return <TooltipShortcutWrapper>{text}</TooltipShortcutWrapper>;
            },
            shortcut: (
              <Shortcut variant="dark">{shortCutMap.numberedList}</Shortcut>
            ),
          }}
        />
      ),
      enabled: blockType === 'number',
      icon: NumberedListIcon,
      visible: true,
    },
    {
      type: 'button',
      onClick: formatQuote,
      disabled: !isEditable,
      label: formatMessage(messages.quoteLabel, {
        shortcut: shortCutMap.quote,
      }),
      tooltip: (
        <FormattedMessage
          defaultMessage="Blockquote<html>{shortcut}</html>"
          id="ZdGZAE"
          values={{
            html: (text) => {
              return <TooltipShortcutWrapper>{text}</TooltipShortcutWrapper>;
            },
            shortcut: <Shortcut variant="dark">{shortCutMap.quote}</Shortcut>,
          }}
        />
      ),
      enabled: blockType === 'quote',
      icon: BlockQuoteIcon,
      visible: true,
    },
    {
      type: 'divider',
      visible: true,
    },
    {
      type: 'component',
      Component: (
        <Popover
          disabled={false}
          container={containerRef}
          aria-label={formatMessage(messages.link, {
            shortcut: shortCutMap.link,
          })}
          onTriggerButtonClick={() => {
            setShowURLPicker((prev) => !prev);
          }}
          tabIndex={-1}
          tooltipTextClassName="text-center"
          tooltipText={
            <FormattedMessage
              defaultMessage="Link<html>{shortcut}</html>"
              id="T0v3Fn"
              values={{
                html: (text) => {
                  return (
                    <TooltipShortcutWrapper>{text}</TooltipShortcutWrapper>
                  );
                },
                shortcut: (
                  <Shortcut variant="dark">{shortCutMap.link}</Shortcut>
                ),
              }}
            />
          }
          trigger={
            <div
              role="presentation"
              className="flex h-6 w-6 items-center justify-center rounded-lg text-gray-9 hover:bg-gray-3"
            >
              <LinkIcon />
            </div>
          }
          open={showURLPicker}
          onOpenChange={setShowURLPicker}
        >
          <AddLinkModal
            onSubmit={handleAddLink}
            selectedText={selectedText}
            selectedURL={selectedURL}
          />
        </Popover>
      ),
      visible: true,
    },
  ];
  return (
    <div
      className={twMerge(
        'flex items-center p-1',
        containerStyle[type],
        isMobileDevice && `justify-start overflow-y-scroll`,
        hideBottomBorder && 'border-0',
        className
      )}
      ref={ref}
      style={style}
    >
      {options
        .filter((option) => option.visible)
        .map((option, idx) => {
          if (option.type === 'divider') {
            return (
              <div key={idx} className="h-6 border border-l-0 border-gray-5" />
            );
          }

          if (option.type === 'component') {
            return <Fragment key={idx}>{option.Component}</Fragment>;
          }

          return (
            <Tooltip
              key={idx}
              textClassName="text-center"
              tooltipText={option.tooltip ?? option.label}
            >
              <ToggleButton
                tabIndex={-1}
                disabled={option.disabled}
                onClick={option.onClick}
                className={twMerge(
                  'h-6 w-6 !p-1 hover:bg-gray-3',
                  option.enabled && 'bg-gray-4'
                )}
                aria-label={option.label}
              >
                {typeof option.icon === 'string' ? (
                  <img src={option.icon} alt="" className="h-4 w-4" />
                ) : (
                  <option.icon className="h-4 w-4 text-gray-8" />
                )}
              </ToggleButton>
            </Tooltip>
          );
        })}
    </div>
  );
});

export const RepliesHeaderPlugin = forwardRef<
  HTMLDivElement,
  RepliesHeaderPluginProps
>(function RepliesFloatingMenu({ isVisible = true, ...tail }, ref) {
  if (!isVisible) {
    return null;
  }

  return <RepliesHeaderPluginImpl {...tail} ref={ref} />;
});
