import type {
  AmazonRewardDetailsForm,
  AmazonRewardDetailsOption,
  SearchableSelectOptionType,
} from '@assembly-web/services';
import { useCallback, useMemo } from 'react';
import { defineMessages, useIntl } from 'react-intl';
import { z } from 'zod';

import { Banner } from '../../../DesignSystem/Feedback/Banner';
import { TextStyle } from '../../../DesignSystem/Feedback/TextStyle';
import { RewardDropdown } from './RewardDropdown';

const messages = defineMessages({
  chooseDifferentOption: {
    defaultMessage:
      'This combination doesn’t exist - please choose a different option.',
    id: 'IpPpH7',
  },
});

const selectableOptionSchema = z.object({
  id: z.string().min(1),
  name: z.string().min(1),
  value: z.union([z.string().min(1), z.number()]).optional(),
});

export const RewardDetailsFormSchema = z.record(selectableOptionSchema);

export const AmazonRewardFormDetails = ({
  rewardDetails,
  onUpdate,
  formErrors,
  formData,
  hasMatchingVariation,
  isDetailsLoading,
}: {
  rewardDetails: AmazonRewardDetailsOption[];
  onUpdate: (
    data: AmazonRewardDetailsForm,
    errors: Record<string, unknown>
  ) => void;
  formErrors: Record<string, unknown>;
  formData: AmazonRewardDetailsForm;
  hasMatchingVariation: boolean;
  isDetailsLoading: boolean;
}) => {
  const { formatMessage } = useIntl();
  const validateFormData = useCallback(
    (data: typeof formData) => {
      const selectedOptions = Object.values(data);

      const requiredKeys = rewardDetails.map(({ name }) => name);

      const isAllSelected = requiredKeys.length === selectedOptions.length;

      if (!isAllSelected) {
        return { '': 'Please select values for all fields' };
      }

      try {
        RewardDetailsFormSchema.parse(data);
        return {};
      } catch (error) {
        if (error instanceof z.ZodError) {
          const fieldErrors = error.flatten().fieldErrors;
          return fieldErrors;
        }
        return {};
      }
    },
    [rewardDetails]
  );

  const getIsItemDisabled = useCallback(
    (name: string) => {
      if (name === 'quantity') {
        const isOtherFieldsFilled =
          Object.keys(formData).filter((name) => name !== 'quantity').length ===
            rewardDetails.filter((item) => item.name !== 'quantity').length &&
          hasMatchingVariation;

        return rewardDetails.length === 1 ? false : !isOtherFieldsFilled;
      } else {
        return false;
      }
    },
    [formData, hasMatchingVariation, rewardDetails]
  );

  const shouldDisplayAlert = useMemo(() => {
    const availableDimensions = rewardDetails.filter(
      (item) => item.name !== 'quantity'
    );
    const isOtherFieldsFilled =
      Object.keys(formData).filter((name) => name !== 'quantity').length ===
      availableDimensions.length;

    if (availableDimensions.length === 0) {
      return false;
    }

    return isOtherFieldsFilled && !hasMatchingVariation;
  }, [formData, hasMatchingVariation, rewardDetails]);

  const handleDropdownChange = useCallback(
    (name: string, selectedOption: SearchableSelectOptionType) => {
      const newData = { ...formData, [name]: selectedOption };
      const errors = validateFormData(newData);
      onUpdate(newData, errors);
    },
    [onUpdate, validateFormData, formData]
  );

  return (
    <div className="flex flex-col gap-2" data-testid="swag-details-form">
      {Boolean(shouldDisplayAlert) && (
        <Banner status="error" className="rounded-lg">
          <TextStyle variant="xs-regular" className="text-error-6">
            {formatMessage(messages.chooseDifferentOption)}
          </TextStyle>
        </Banner>
      )}
      {rewardDetails.map(({ name, options }, index) => (
        <RewardDropdown
          key={name}
          options={options}
          onChange={(option) => handleDropdownChange(name, option)}
          name={name}
          index={index}
          formError={formErrors[name] as string}
          selectedOption={formData[name] as SearchableSelectOptionType}
          isDisabled={getIsItemDisabled(name) || isDetailsLoading}
        />
      ))}
    </div>
  );
};
