import type { z, ZodRawShape } from 'zod';

export function validateFormSafely(
  formSchema: z.ZodObject<ZodRawShape>,
  formData: FormData | URLSearchParams
) {
  return formSchema.safeParse(Object.fromEntries(formData.entries()));
}

export function validateForm(
  formSchema: z.ZodObject<ZodRawShape>,
  formData: FormData | URLSearchParams
) {
  return formSchema.parse(Object.fromEntries(formData.entries()));
}
