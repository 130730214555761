import { createContext, useContext } from 'react';

export type AnnouncementFormValues = {
  title: string;
  description: string;
  announcementId?: string;
  backgroundColor: string;
  allowPostReplies: boolean;
  isMentionsEnabled: boolean;
  allowPostReactions: boolean;
  isPushNotificationEnabled: boolean;
  isEmailNotificationEnabled: boolean;
};

export type AnnouncementFormErrors = Partial<AnnouncementFormValues>;

export type AnnouncementFormContextType = {
  formValues: AnnouncementFormValues;
  formErrors: AnnouncementFormErrors;
  setFormErrors: (errors: AnnouncementFormErrors) => void;
  setFormValue: (
    name: keyof AnnouncementFormValues,
    value: string | boolean
  ) => void;
};

export const AnnouncementFormContext = createContext<
  AnnouncementFormContextType | undefined
>(undefined);

export function useAnnouncementForm() {
  const context = useContext(AnnouncementFormContext);

  if (!context) {
    throw new Error(
      'useAnnouncementForm must be used within an AnnouncementFormProvider'
    );
  }

  return context;
}
