import { loadingLogoImage } from '@assembly-web/assets';
import type { ReactNode } from 'react';
import { twMerge } from 'tailwind-merge';

export function PulseLoadingIndicator({
  children,
  size = 'base',
}: {
  children: ReactNode;
  size?: 'sm' | 'base';
}) {
  const isBaseSize = size === 'base';
  const isSmallSize = size === 'sm';

  const classNames = twMerge(
    'animate-bounce rounded-full bg-brand-6',
    isSmallSize && 'h-4 w-4',
    isBaseSize && 'h-6 w-6'
  );

  return (
    <section className="mt-8 flex flex-col items-center justify-center space-y-6">
      {children}
      <div className="flex items-center justify-center space-x-2">
        <div className={classNames}></div>
        <div className={twMerge('animation-delay-100', classNames)}></div>
        <div className={twMerge('animation-delay-200', classNames)}></div>
      </div>
    </section>
  );
}

export function AssemblyLoadingIndicator() {
  return (
    <PulseLoadingIndicator>
      <img
        src={loadingLogoImage}
        alt=""
        role="presentation"
        className="h-fit w-fit"
      />
    </PulseLoadingIndicator>
  );
}
