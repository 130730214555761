import { twMerge } from 'tailwind-merge';

import { TextStyle } from '../../DesignSystem/Feedback/TextStyle';

function Star({ filled, size }: { filled: boolean; size: 'sm' | 'md' }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className={twMerge(
        'stroke-upgrade-8',
        filled && 'fill-upgrade-6',
        size === 'sm' ? 'h-4 w-4' : 'h-6 w-6'
      )}
      fill="none"
      viewBox="0 0 24 24"
      strokeWidth="1.25"
    >
      <path d="M11.049 2.927c.3-.921 1.603-.921 1.902 0l1.519 4.674a1 1 0 00.95.69h4.915c.969 0 1.371 1.24.588 1.81l-3.976 2.888a1 1 0 00-.363 1.118l1.518 4.674c.3.922-.755 1.688-1.538 1.118l-3.976-2.888a1 1 0 00-1.176 0l-3.976 2.888c-.783.57-1.838-.197-1.538-1.118l1.518-4.674a1 1 0 00-.363-1.118l-3.976-2.888c-.784-.57-.38-1.81.588-1.81h4.914a1 1 0 00.951-.69l1.519-4.674z" />
    </svg>
  );
}

export type StarRatingProps = {
  filled: number;
  size: 'sm' | 'md';
  maxStars?: number;
};

export function StarRating(props: StarRatingProps) {
  const { filled, maxStars = 5, size } = props;
  return (
    <TextStyle className="flex w-max gap-0.5" as="span">
      {Array.from({ length: maxStars }).map((_, idx) => {
        return (
          <span key={idx}>
            <Star filled={idx < filled} size={size} />
          </span>
        );
      })}
    </TextStyle>
  );
}
