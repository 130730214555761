import type { ClaimStatus } from '@assembly-web/services';
import { ChevronDownIcon } from '@heroicons/react/24/outline';
import * as MenuBar from '@radix-ui/react-menubar';
import * as ToggleGroup from '@radix-ui/react-toggle-group';
import { motion } from 'framer-motion';
import {
  type ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import ContentLoader from 'react-content-loader';
import { defineMessages, useIntl } from 'react-intl';
import { twMerge } from 'tailwind-merge';

type ClaimsFilterProps = {
  filter: ClaimStatus;
  isTabsLoading?: boolean;
  onFilterChange: (filter: ClaimStatus) => void;
  filterOptions: { label: ReactNode; value: ClaimStatus }[];
};

const messages = defineMessages({
  moreFilter: {
    defaultMessage: 'More',
    id: 'I5NMJ8',
  },
});

export function ClaimsFilter({
  filter,
  onFilterChange,
  filterOptions,
  isTabsLoading = false,
}: ClaimsFilterProps) {
  const { formatMessage } = useIntl();

  const [isCondensed, setIsCondensed] = useState(false);

  const handleResize = useCallback(() => {
    setIsCondensed(
      Boolean(
        stickyFiltersRef.current && stickyFiltersRef.current.clientWidth < 274
      )
    );
  }, []);

  const stickyFiltersRef = useRef<HTMLDivElement>(null);
  const prevPosition = useRef(0);
  const [showGradient, setShowGradient] = useState(false);

  const handleScroll = useCallback(() => {
    if (stickyFiltersRef.current) {
      const currentPosition = window.scrollY;

      if (currentPosition > prevPosition.current) {
        // scroll down, hide filters
        stickyFiltersRef.current.style.top = '-100px';
        setShowGradient(false);
      } else {
        const filtersRect = stickyFiltersRef.current.getBoundingClientRect();
        setShowGradient(filtersRect.y === 56);
        if (currentPosition > 400) {
          // scroll up, show filters
          stickyFiltersRef.current.style.top = '56px'; // header height - filter top padding
        }
      }
      prevPosition.current = currentPosition;
    }
  }, []);

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    window.addEventListener('resize', handleResize);
    handleResize();
    return () => {
      window.removeEventListener('scroll', handleScroll);
      window.removeEventListener('resize', handleResize);
    };
  }, [handleResize, handleScroll]);

  const filters = useMemo(() => {
    const allFilters = filterOptions;

    const selectedFilter = allFilters.find(
      (current) => current.value === filter
    );

    const condensedFilters = allFilters.filter(
      (current) => current.value !== selectedFilter?.value
    );

    return {
      displayFilters: isCondensed
        ? selectedFilter
          ? [selectedFilter]
          : []
        : allFilters,
      condensedFilters,
    };
  }, [filter, isCondensed, filterOptions]);

  const gradientClasses = showGradient
    ? 'from-gray-1 to-transparent after:absolute after:-bottom-4 after:left-4 after:h-4 after:w-[calc(100%-2rem)] after:bg-gradient-to-b lg:after:left-[88px] lg:after:w-[calc(100%-11rem)]'
    : 'rounded-lg';

  return (
    <motion.section
      ref={stickyFiltersRef}
      className="top-0 z-[1] overflow-hidden"
    >
      <div className={twMerge(gradientClasses)}>
        <div className="inline-block" data-step={2}>
          {isTabsLoading ? (
            <div className="flex h-[32px] w-fit animate-pulse items-center rounded-lg border-[1px] border-gray-5 bg-gray-1 px-3 py-2">
              <ContentLoader
                speed={2}
                width={72}
                height={12}
                viewBox="0 0 72 12"
                backgroundColor="#d9d9d9"
              >
                <rect x="0" y="0" rx="3" ry="3" width="72" height="12" />
              </ContentLoader>
            </div>
          ) : (
            <ToggleGroup.Root
              className="mb-1 flex text-base font-medium"
              value={filter}
              onValueChange={(value) => {
                // Toggle group allows us to deselect an item, so we need to prevent that
                if (value) {
                  onFilterChange(value as ClaimStatus);
                }
              }}
              orientation="horizontal"
              type="single"
            >
              {filters.displayFilters.map(({ value, label }) => (
                <ToggleGroup.Item
                  data-testid={`discover-filter-button-${value}`}
                  id={`discover-filter-button-${value}`}
                  key={value}
                  value={value}
                  className={twMerge(
                    'mx-1 rounded-lg border-[1px] bg-gray-1 px-3 py-1 text-gray-9 first:ml-0',
                    'focus-visible:border-gray-9 focus-visible:outline-none focus-visible:ring focus-visible:ring-primary-6 focus-visible:ring-offset-1',
                    'data-[state=off]:border-gray-5 data-[state=off]:hover:bg-gray-3',
                    'data-[state=on]:border-gray-9 data-[state=on]:bg-gray-9 data-[state=on]:text-gray-1'
                  )}
                >
                  {label}
                </ToggleGroup.Item>
              ))}

              {Boolean(isCondensed) && (
                // TODO: Refactor this to use the DropdownMenu component "after" refactoring the DropdownMenu component (https://joinassembly.atlassian.net/browse/APP-11434)
                <MenuBar.Root>
                  <MenuBar.Menu>
                    <MenuBar.Trigger
                      className={twMerge(
                        'mx-1 ml-2 flex items-center gap-2 rounded-lg border-[1px] bg-gray-1 px-3 py-1 text-gray-9',
                        'focus-visible:border-gray-9 focus-visible:outline-none focus-visible:ring focus-visible:ring-primary-6 focus-visible:ring-offset-1',
                        'data-[state=closed]:border-gray-5 data-[state=closed]:hover:bg-gray-3',
                        'data-[state=open]:border-gray-7 data-[state=open]:bg-gray-9 data-[state=open]:text-gray-1'
                      )}
                    >
                      {formatMessage(messages.moreFilter)}
                      <ChevronDownIcon className="h-4 w-4 text-gray-9 group-aria-expanded:rotate-180" />
                    </MenuBar.Trigger>
                    <MenuBar.Content
                      className="space-b shadow-md z-1 absolute left-0 z-10 mt-1 max-h-[32rem] w-32 origin-top transform overflow-auto rounded-md border border-gray-5 bg-gray-1 py-1 shadow-lg-down ring-opacity-5 focus:outline-none"
                      align="start"
                      sideOffset={5}
                      alignOffset={-3}
                    >
                      {filters.condensedFilters.map(({ value, label }) => (
                        <MenuBar.Item
                          data-testid={`discover-condensed-filter-menu-item-${value}`}
                          key={value}
                          onSelect={() => {
                            onFilterChange(value);
                          }}
                          className="cursor-pointer focus:bg-primary-2 focus:outline-none"
                        >
                          <button className="bg-gray-0 flex w-full cursor-pointer items-center justify-between p-[9px] pr-2 text-sm text-gray-8 hover:bg-gray-3 focus:outline-none">
                            {label}
                          </button>
                        </MenuBar.Item>
                      ))}
                    </MenuBar.Content>
                  </MenuBar.Menu>
                </MenuBar.Root>
              )}
            </ToggleGroup.Root>
          )}
        </div>
      </div>
    </motion.section>
  );
}
