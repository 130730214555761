import type { SelfClaimDetails } from '@assembly-web/services';
import {
  Arrow as HoverCardArrow,
  Content,
  Trigger,
} from '@radix-ui/react-hover-card';
import parse from 'html-react-parser';
import { useEffect, useRef, useState } from 'react';
import { twMerge } from 'tailwind-merge';

import { useRefContainer } from '../../../../context/RefContext';
import { TextStyle } from '../../../../DesignSystem/Feedback/TextStyle';
import { useDeviceInfo } from '../../../hooks/useDeviceInfo';
import { Portal } from '../../Portal';
import { ChallengeMetaInfo } from '../ChallengeMetaInfo';
import { useChallengeLogic } from '../useChallenge';
import { ChallengeCardButtons } from './ChallengeCardButton';
import { ClaimInfoBanner } from './ClaimInfoBanner';

type ChallengeCardProps = {
  description: string;
  icon: string;
  points: number;
  selfClaimDetails: SelfClaimDetails | null;
  showClaimBadge?: boolean;
  launchedAt: string | undefined;
  claimButtonText: string;
  claimsRemaining: number;
  isClaimButtonDisabled?: boolean;
  showClaimsButton?: boolean;
  isNotParticipant: boolean;
  showViewClaimsButton: boolean;
  onClaim?: () => void;
  isClaimButtonLoading?: boolean;
  variant?: 'preview' | 'default' | 'drawer' | 'carousal';
  endedAt?: string;
  onViewClaims?: () => void;
  isChallengeArchived?: boolean;
};

export const ChallengeCardContent = (props: ChallengeCardProps) => {
  const {
    icon,
    points,
    variant,
    launchedAt,
    description,
    claimButtonText,
    claimsRemaining,
    showClaimBadge,
    selfClaimDetails = {
      claimLimit: 0,
      totalEarnedPoints: 0,
      claims: [],
    },
    isClaimButtonDisabled,
    showClaimsButton,
    showViewClaimsButton,
    onClaim,
    isClaimButtonLoading,
    endedAt,
    onViewClaims,
    isChallengeArchived,
    isNotParticipant,
  } = props;
  const isMobileView = useDeviceInfo().deviceType === 'mobile';
  const [descriptionOverflow, setDescriptionOverflow] = useState(false);
  const descriptionRef = useRef<HTMLParagraphElement>(null);
  const containerRef = useRefContainer();

  const {
    isAllApproved,
    claimCount,
    approvedClaims,
    totalCount,
    totalEarnedPoints,
  } = useChallengeLogic({
    selfClaimDetails,
  });

  useEffect(() => {
    const onResize = () => {
      const el = descriptionRef.current;
      if (el) {
        setDescriptionOverflow(el.scrollHeight > el.clientHeight);
      }
    };

    window.addEventListener('resize', onResize);
    onResize();

    return () => window.removeEventListener('resize', onResize);
  }, [description.length]);

  return (
    <>
      <div className="flex flex-col gap-2">
        {variant !== 'drawer' && (
          <Trigger
            className="w-full cursor-pointer"
            aria-disabled={isChallengeArchived}
            asChild
          >
            <TextStyle
              variant="sm-regular"
              className={twMerge(
                'line-clamp-2 break-words text-gray-8',
                ((!descriptionOverflow && description) ||
                  variant === 'carousal') &&
                  'h-[44px]'
              )}
              ref={descriptionRef}
            >
              {parse(description)}
            </TextStyle>
          </Trigger>
        )}
        <ClaimInfoBanner
          icon={parse(icon)}
          points={points}
          approvedClaims={approvedClaims}
          isAllApproved={isAllApproved}
          totalCount={totalCount}
          totalEarnedPoints={totalEarnedPoints}
        />
        {variant !== 'drawer' && (
          <ChallengeMetaInfo
            launchedAt={launchedAt}
            totalToClaim={claimsRemaining}
            variant={variant}
            endedAt={endedAt}
          />
        )}
        {Boolean(isMobileView || variant === 'carousal') && (
          <div className="flex w-full items-end gap-2">
            <ChallengeCardButtons
              isClaimButtonDisabled={isClaimButtonDisabled}
              showViewClaimsButton={showViewClaimsButton}
              showClaimButton={showClaimsButton}
              claimButtonText={claimButtonText}
              onClaim={onClaim}
              onViewClaims={onViewClaims}
              isClaimButtonLoading={isClaimButtonLoading}
              showClaimBadge={showClaimBadge}
              isAllApproved={isAllApproved}
              claimCount={claimCount}
              isNotParticipant={isNotParticipant}
              layout="carousal"
            />
          </div>
        )}
      </div>
      {Boolean(descriptionOverflow) && (
        <Portal
          className="relative z-50 p-6"
          {...(containerRef ? { container: containerRef } : {})}
        >
          <Content className="flex w-[calc(32px+var(--radix-hover-card-trigger-width))] break-words rounded-lg border-b border-t-0 border-gray-5 bg-gray-1 px-4 py-3 shadow-lg-up">
            <div className="max-h-[200px] w-full overflow-auto">
              <HoverCardArrow className="fill-gray-1" />
              {description ? (
                <TextStyle
                  variant="sm-regular"
                  className="break-all text-gray-8"
                >
                  {parse(description)}
                </TextStyle>
              ) : null}
            </div>
          </Content>
        </Portal>
      )}
    </>
  );
};
