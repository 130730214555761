import { defineMessages, useIntl } from 'react-intl';

import { Modal } from '../../../../DesignSystem/Feedback/Modal';
import { TextStyle } from '../../../../DesignSystem/Feedback/TextStyle';
import { StarRating } from '../../../../DesignSystem/Inputs/StarRating';

const messages = defineMessages({
  title: {
    defaultMessage: 'Hey, {name}! {br} How are we doing?',
    id: 'lojJ0r',
  },
});

export type RatingModalProps = {
  isOpen: boolean;
  onClose: () => void;
  name: string;
  rating: number;
  setRating: (val: number) => void;
};

export function RatingModal({
  isOpen,
  onClose,
  name,
  setRating,
  rating,
}: RatingModalProps) {
  const { formatMessage } = useIntl();
  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      className="flex h-44 w-72 flex-col sm:w-72"
      headerClassName="px-3 pb-1 pt-[6px] capitalize"
      bodyClassName="flex flex-col gap-4 flex-1 px-6"
      title={''}
    >
      <div className="flex flex-col items-center gap-4">
        <TextStyle className="text-center" variant="lg-medium">
          {formatMessage(messages.title, {
            name,
            br: <br />,
          })}
        </TextStyle>
        <StarRating
          totalStars={5}
          selectedStar={rating}
          setSelectedStar={setRating}
        />
      </div>
    </Modal>
  );
}
