import { createLinkMatcherWithRegExp } from '@lexical/react/LexicalAutoLinkPlugin';
import { z } from 'zod';

// https://github.com/facebook/lexical/blob/8d3764552e352991e61dc1f43abb0f0fa1386a6c/packages/lexical-playground/src/utils/url.ts#L31-L33
export const URL_REGEX =
  /((https?:\/\/(www\.)?)|(www\.))[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&//=]*)/;

const EMAIL_REGEX =
  /(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))/;

export const validateUrl = (url: string): boolean => {
  const urlSchema = z.string().url();
  return urlSchema.safeParse(url).success;
};

export const MATCHERS = [
  createLinkMatcherWithRegExp(URL_REGEX, (text) => {
    if (!text.startsWith('https://') && !text.startsWith('http://')) {
      return `https://${text}`;
    }
    return text;
  }),
  createLinkMatcherWithRegExp(EMAIL_REGEX, (text) => {
    return `mailto:${text}`;
  }),
];
