import { useEffect, useState } from 'react';
import { twMerge } from 'tailwind-merge';

type InlineLoadingSpinnerProps = {
  className?: string;
  displayDelay?: number;
  loadedContent?: JSX.Element;
  isLoading?: boolean;
};

export function LoadingSpinner(props: InlineLoadingSpinnerProps) {
  const { isLoading = false, displayDelay = 0, loadedContent = null } = props;
  const [showSpinner, setShowSpinner] = useState(displayDelay === 0);

  useEffect(() => {
    if (!displayDelay) return;

    if (!isLoading) return setShowSpinner(false);
    const timer = setTimeout(() => setShowSpinner(isLoading), displayDelay);

    return () => clearTimeout(timer);
  }, [isLoading, displayDelay]);

  return showSpinner ? (
    <svg
      className={twMerge(
        'h-5 w-5 animate-spin',
        !props.className?.includes('text-') && 'text-black',
        props.className
      )}
      data-chromatic="ignore"
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      aria-hidden="true"
    >
      <circle
        className="opacity-25"
        cx="12"
        cy="12"
        r="10"
        stroke="currentColor"
        strokeWidth="4"
      ></circle>
      <path
        className="opacity-75"
        fill="currentColor"
        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
      ></path>
    </svg>
  ) : (
    loadedContent
  );
}
