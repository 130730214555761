import type {
  AriaAttributes,
  DetailedHTMLProps,
  InputHTMLAttributes,
} from 'react';
import { useEffect, useRef } from 'react';

import { Tooltip } from '../..';
import { classNames } from '../Utils/classNames';

export type CheckboxProps = DetailedHTMLProps<
  InputHTMLAttributes<HTMLInputElement>,
  HTMLInputElement
> &
  AriaAttributes & {
    dataTestId?: string;
    isIndeterminate?: boolean;
    tooltipText?: string;
  };

export function Checkbox(props: CheckboxProps) {
  const {
    id,
    name,
    value,
    disabled,
    dataTestId,
    defaultChecked,
    isIndeterminate,
    className,
    tooltipText,
    ...rest
  } = props;
  const checkboxRef = useRef<HTMLInputElement>(null);
  useEffect(() => {
    if (checkboxRef.current) {
      checkboxRef.current.indeterminate = isIndeterminate ?? false;
    }
  }, [isIndeterminate]);

  return (
    <Tooltip tooltipText={tooltipText}>
      <input
        id={id}
        name={name}
        value={value}
        type="checkbox"
        ref={checkboxRef}
        aria-label={name}
        disabled={disabled}
        data-test-id={dataTestId}
        className={classNames(
          'rounded border-gray-6 bg-gray-1 ring-primary-6 before:block before:content-[""] checked:bg-none checked:before:mb-1 checked:before:ml-[5px] checked:before:mt-[2px] checked:before:h-2 checked:before:w-1 checked:before:rotate-45 checked:before:border-b-2 checked:before:border-r-2 checked:before:border-gray-1 indeterminate:bg-none indeterminate:before:ml-[6px] indeterminate:before:mt-[2px] indeterminate:before:w-[2px] indeterminate:before:rotate-90 indeterminate:before:border-t-[9px] indeterminate:before:border-gray-1 dark:bg-gray-9 dark:ring-primary-3 dark:checked:bg-primary-3 dark:checked:outline-primary-3 dark:checked:ring-offset-primary-3 dark:checked:before:border-gray-9 dark:indeterminate:bg-primary-3 dark:indeterminate:before:border-gray-9 dark:focus:border-gray-9 dark:focus:shadow-primary-3 dark:focus:ring-offset-gray-9',
          {
            'cursor-not-allowed bg-gray-3 text-gray-6 dark:bg-gray-7 dark:checked:bg-gray-7 dark:indeterminate:bg-gray-7 dark:hover:bg-gray-7':
              disabled,
          },
          className
        )}
        checked={defaultChecked ?? props.checked}
        {...rest}
      />
    </Tooltip>
  );
}
