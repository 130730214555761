import type { ButtonHTMLAttributes, DetailedHTMLProps, ReactNode } from 'react';
import { twMerge } from 'tailwind-merge';

import { TextStyle } from '../Feedback/TextStyle';

export function FilterChip({
  onClick,
  children,
  className,
  ...props
}: DetailedHTMLProps<
  ButtonHTMLAttributes<HTMLButtonElement>,
  HTMLButtonElement
> & {
  children: ReactNode;
  className?: string;
}) {
  return (
    <button
      onClick={onClick}
      className={twMerge(
        'group inline-flex h-8 items-center justify-start gap-2.5 rounded-lg border border-gray-5 px-3 py-1 hover:cursor-pointer hover:bg-gray-4 active:bg-gray-10 active:text-gray-1',
        className
      )}
      {...props}
    >
      <div className="w-max text-center text-base font-medium leading-normal text-gray-9 group-active:text-gray-1">
        {children}
      </div>
    </button>
  );
}

export function InputChip({
  children,
  onClick,
  className,
  connectedLeft,
  connectedRight,
  connectedRightLabel,
  isDisabled,
  ...buttonProps
}: DetailedHTMLProps<
  ButtonHTMLAttributes<HTMLButtonElement>,
  HTMLButtonElement
> & {
  children: ReactNode;
  connectedLeft?: ReactNode;
  connectedRight: ReactNode;
  className?: string;
  connectedRightLabel?: string;
  isDisabled?: boolean;
}) {
  return (
    <div
      className={twMerge(
        'inline-flex h-6 w-fit max-w-full cursor-default items-center justify-start gap-1 truncate rounded-md border-[0.5px] border-gray-7 bg-gray-3',
        isDisabled && 'border-gray-5 bg-gray-4 hover:cursor-not-allowed',
        !isDisabled && 'hover:bg-gray-4',
        className
      )}
    >
      <div
        className={twMerge(
          'flex cursor-default items-center overflow-hidden text-ellipsis text-sm font-normal leading-snug text-gray-9',
          isDisabled && 'hover:cursor-not-allowed'
        )}
      >
        {Boolean(connectedLeft) && (
          <div
            className={twMerge(
              'min-w-fit p-1 pr-0',
              isDisabled && 'mix-blend-luminosity'
            )}
          >
            {connectedLeft}
          </div>
        )}
        <TextStyle
          variant="sm-regular"
          className={twMerge(
            'overflow-hidden text-ellipsis leading-snug text-gray-9',
            connectedLeft && 'pl-1',
            !connectedLeft && !isDisabled && 'pl-2',
            isDisabled && 'px-2 text-gray-8'
          )}
        >
          {children}
        </TextStyle>
      </div>
      {!isDisabled && Boolean(connectedRightLabel) && (
        <button
          aria-label={connectedRightLabel}
          {...buttonProps}
          onClick={onClick}
          className="flex h-full cursor-pointer items-center justify-start gap-1 rounded-br-md rounded-tr-md border-gray-7 py-[6px] pl-1 pr-2"
        >
          {connectedRight}
        </button>
      )}
    </div>
  );
}
