import * as ToggleGroup from '@radix-ui/react-toggle-group';
import type { ReactNode } from 'react';

import { TextStyle } from '../../../DesignSystem/Feedback/TextStyle';

type ButtonProps = {
  value: string;
  children: ReactNode;
};

export type ScaleProps = {
  start: number;
  end: number;
  disabled?: boolean;
  value?: string;
  defaultValue?: string;
  onValueChange?: (value: string) => void;
};

function Button({ children, value }: ButtonProps) {
  return (
    <ToggleGroup.Item value={value} className="flex-1 rounded bg-gray-3 py-2">
      <TextStyle variant="sm-medium" as="span">
        {children}
      </TextStyle>
    </ToggleGroup.Item>
  );
}

export function Scale({
  end,
  start,
  defaultValue,
  disabled,
  onValueChange,
  value,
}: ScaleProps) {
  return (
    <ToggleGroup.Root
      type="single"
      disabled={disabled}
      defaultValue={defaultValue}
      onValueChange={onValueChange}
      value={value}
      orientation="horizontal"
      className="flex items-center justify-stretch gap-1 pb-2"
    >
      {Array.from({ length: end - start + 1 }, (_, i) => (
        <Button key={i} value={String(i + start)}>
          {i + start}
        </Button>
      ))}
    </ToggleGroup.Root>
  );
}
