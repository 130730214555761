import type { ChallengeState, UserAvatar } from '@assembly-web/services';
import { ArchiveBoxXMarkIcon } from '@heroicons/react/24/outline';
import dayjs from 'dayjs';
import { defineMessages, useIntl } from 'react-intl';
import { twMerge } from 'tailwind-merge';

import { Avatar, AvatarSize } from '../../../../DesignSystem/Feedback/Avatar';
import { TextStyle } from '../../../../DesignSystem/Feedback/TextStyle';
import { Tooltip } from '../../../../DesignSystem/Feedback/Tooltip';
import { useChallengeStates } from '../useChallenge';
import { ChallengeHeaderBannerButton } from './ChallengeHeaderBannerButton';

type ChallengeCardHeaderBannerProps = {
  userDetails: UserAvatar;
  challengeState: ChallengeState;
  isCurrentUserOwner: boolean;
  isUserAdmin: boolean;
  onHeaderButtonClick?: (button: string) => void;
  variant?: 'preview' | 'default' | 'drawer' | 'carousal';
  endedAt?: string;
};

const messages = defineMessages({
  owner: {
    defaultMessage: `Created by {name}`,
    id: '6SSpmN',
  },
  challengeStatePrefix: {
    defaultMessage: 'is',
    id: 'fXdkiI',
  },
  challengeActive: {
    defaultMessage: 'Active',
    id: '3a5wL8',
  },
  challengeInactive: {
    defaultMessage: 'Inactive',
    id: '6Tps09',
  },
  you: {
    defaultMessage: 'you',
    id: 'Jjrc4P',
  },
  challengeEnded: {
    defaultMessage: 'This challenge has ended and can no longer be claimed',
    id: 'lu/QOq',
  },
  challengeArchived: {
    defaultMessage: 'This challenge has been archived',
    id: 'QUWUuV',
  },
  challengeStatus: {
    defaultMessage: 'Challenge is {state}',
    id: 'DkC7eA',
  },
  ended: {
    defaultMessage: 'ended {timeAgo}',
    id: '5MV3TU',
  },
});

export const ChallengeCardHeaderBanner = (
  props: ChallengeCardHeaderBannerProps
) => {
  const { challengeState, isUserAdmin, variant } = props;
  const {
    isChallengeActive,
    isChallengeArchived,
    isChallengeClosed,
    isChallengeInactive,
  } = useChallengeStates({ challengeState });

  const defaultHeader =
    isChallengeActive ||
    isChallengeInactive ||
    (isUserAdmin && isChallengeClosed);
  const closedHeader = isChallengeClosed && !isUserAdmin;
  const archivedHeader = isChallengeArchived;

  return (
    <div className={twMerge('flex h-10 w-full overflow-hidden')}>
      <div
        className={twMerge(
          'flex h-full w-full items-center justify-between rounded-tl-lg rounded-tr-lg pl-4',
          defaultHeader && 'bg-gray-9',
          closedHeader && 'bg-gray-8',
          archivedHeader && 'bg-gray-3',
          variant === 'drawer' && 'rounded-lg'
        )}
      >
        {Boolean(defaultHeader) && <DefaultBanner {...props} />}
        {Boolean(closedHeader) && <ChallengeEndedBanner />}
        {Boolean(archivedHeader) && <ChallengeArchivedBanner {...props} />}
      </div>
    </div>
  );
};

function DefaultBanner(props: ChallengeCardHeaderBannerProps) {
  const { formatMessage } = useIntl();
  const {
    userDetails,
    challengeState,
    isCurrentUserOwner,
    onHeaderButtonClick,
    endedAt,
  } = props;

  return (
    <>
      <div className="flex gap-1 align-baseline">
        <div className="flex items-center space-x-2">
          <Avatar
            size={AvatarSize.Small}
            name={userDetails.name}
            memberID={userDetails.memberID}
            image={userDetails.image}
            isRounded={true}
          />
          <Tooltip
            tooltipText={formatMessage(messages.owner, {
              name: isCurrentUserOwner
                ? formatMessage(messages.you)
                : userDetails.name,
            })}
          >
            <TextStyle variant="sm-medium" className="line-clamp-1 text-gray-1">
              {formatMessage(messages.owner, {
                name: isCurrentUserOwner
                  ? formatMessage(messages.you)
                  : userDetails.name,
              })}
            </TextStyle>
          </Tooltip>
        </div>
        {Boolean(endedAt) && (
          <>
            <span className="text-gray-1">•</span>
            <Tooltip tooltipText={dayjs(endedAt).format('MMMM D, YYYY h:mm A')}>
              <TextStyle variant="sm-medium" className="text-gray-1">
                {formatMessage(messages.ended, {
                  timeAgo: dayjs(endedAt).fromNow(),
                })}
              </TextStyle>
            </Tooltip>
          </>
        )}
      </div>
      <div className="p-1">
        <ChallengeHeaderBannerButton
          challengeState={challengeState}
          onHeaderButtonClick={onHeaderButtonClick}
        />
      </div>
    </>
  );
}

function ChallengeEndedBanner() {
  const { formatMessage } = useIntl();

  return (
    <div className="inline-flex items-center space-x-2">
      <Tooltip tooltipText={formatMessage(messages.challengeEnded)}>
        <TextStyle variant="sm-medium" className="text-gray-1">
          {formatMessage(messages.challengeEnded)}
        </TextStyle>
      </Tooltip>
    </div>
  );
}

function ChallengeArchivedBanner(props: ChallengeCardHeaderBannerProps) {
  const { formatMessage } = useIntl();
  const { userDetails, isCurrentUserOwner } = props;

  return (
    <>
      <div className="inline-flex items-center space-x-2">
        <Avatar
          size={AvatarSize.Small}
          name={userDetails.name}
          memberID={userDetails.memberID}
          image={userDetails.image}
          isRounded={true}
        />
        <Tooltip
          tooltipText={formatMessage(messages.owner, {
            name: isCurrentUserOwner
              ? formatMessage(messages.you)
              : userDetails.name,
          })}
        >
          <TextStyle variant="sm-medium" className="line-clamp-1 text-gray-8">
            {formatMessage(messages.owner, {
              name: isCurrentUserOwner
                ? formatMessage(messages.you)
                : userDetails.name,
            })}
          </TextStyle>
        </Tooltip>
      </div>
      <div className="inline-flex items-center space-x-2 p-2">
        <ArchiveBoxXMarkIcon className="block h-4 w-4 shrink-0 text-gray-8" />
        <Tooltip tooltipText={formatMessage(messages.challengeArchived)}>
          <TextStyle
            variant="sm-regular"
            data-testid="challenge-archived-text"
            className="line-clamp-1 text-gray-8"
          >
            {formatMessage(messages.challengeArchived)}
          </TextStyle>
        </Tooltip>
      </div>
    </>
  );
}
