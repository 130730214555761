import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/24/outline';
import { useComposedRefs } from '@radix-ui/react-compose-refs';
import {
  forwardRef,
  type ReactNode,
  useCallback,
  useEffect,
  useRef,
} from 'react';
import type {
  ButtonProps as AriaButtonProps,
  NumberFieldProps as AriaNumberFieldProps,
} from 'react-aria-components';
import {
  Button as ButtonImpl,
  Group,
  Input,
  Label,
  NumberField,
} from 'react-aria-components';
import { twJoin, twMerge } from 'tailwind-merge';

import { TextStyle } from '../Feedback/TextStyle';

export type NumberInputProps = AriaNumberFieldProps & {
  label: string;
  labelHidden?: boolean;
  placeholder?: string;
  leadingIcon?: ReactNode;
};

type ButtonProps = Omit<AriaButtonProps, 'className'> & {
  className?: string;
};

const Button = forwardRef<HTMLButtonElement, ButtonProps>(function Button(
  { className, ...tail },
  ref
) {
  return (
    <ButtonImpl
      className={twJoin(
        'group absolute right-3 top-1/2 h-3 w-4 [&>svg]:h-full [&>svg]:w-full [&[slot=increment]]:-translate-y-full',
        className
      )}
      {...tail}
      ref={ref}
    />
  );
});

export const NumberInput = forwardRef<HTMLInputElement, NumberInputProps>(
  function NumberInput(
    { label, labelHidden = true, leadingIcon, placeholder, ...tail },
    ref
  ) {
    const internalRef = useRef<HTMLInputElement>(null);
    const composedRef = useComposedRefs(internalRef, ref);

    const hasLeadingIcon = Boolean(leadingIcon);

    const getWidth = useCallback(
      (value: number) => {
        return (
          Math.max(88, value * 10 + 36 + (hasLeadingIcon ? 36 : 12)) + 'px'
        );
      },
      [hasLeadingIcon]
    );

    useEffect(() => {
      if (!internalRef.current) {
        return;
      }

      const value = internalRef.current.value.length;
      internalRef.current.style.setProperty('width', getWidth(value));
    }, [getWidth]);

    return (
      <NumberField {...tail}>
        <Label className={labelHidden ? 'sr-only' : undefined}>
          <TextStyle as="span" variant="base-regular">
            {label}
          </TextStyle>
        </Label>
        <Group className="relative w-min">
          {Boolean(leadingIcon) && (
            <span className="absolute left-0 top-1/2 -translate-y-1/2 translate-x-1/2">
              {leadingIcon}
            </span>
          )}
          <Input
            ref={composedRef}
            className={twMerge(
              'min-w-[88px] rounded-lg border-0 py-2 pl-3 pr-9 text-sm font-normal text-gray-9 ring-1 ring-gray-5 placeholder:text-gray-8 focus:border-0 focus:outline-none disabled:cursor-not-allowed disabled:border-gray-5 disabled:bg-gray-2 disabled:text-gray-7',
              leadingIcon ? 'pl-9' : 'pl-3'
            )}
            placeholder={placeholder}
            onChange={(e) => {
              const value = e.target.value.length;
              internalRef.current?.style.setProperty('width', getWidth(value));
            }}
          />
          <Button slot="increment">
            <ChevronUpIcon className="stroke-current stroke-2 text-gray-9 group-disabled:cursor-not-allowed group-disabled:text-gray-6" />
          </Button>
          <Button slot="decrement">
            <ChevronDownIcon className="stroke-current stroke-2 text-gray-9 group-disabled:cursor-not-allowed group-disabled:text-gray-6" />
          </Button>
        </Group>
      </NumberField>
    );
  }
);
