import type { Timeout } from '@assembly-web/services';
import {
  Content,
  Overlay,
  Portal,
  Root,
  Trigger,
} from '@radix-ui/react-dialog';
import { AnimatePresence, motion } from 'framer-motion';
import {
  type CSSProperties,
  forwardRef,
  type ReactElement,
  type ReactNode,
  type Ref,
  useEffect,
  useRef,
  useState,
} from 'react';
import { RemoveScroll } from 'react-remove-scroll';
import { twMerge } from 'tailwind-merge';

import { useDeviceInfo } from '../../hooks/useDeviceInfo';

export type PopoutDrawerProps = {
  /** Inner contents of the drawer when it is expanded */
  children: ReactNode;

  /** Contents shown at the top of the drawer next to the toolbar */
  header: ReactElement;

  /** Label assigned to the expanded container for accessibility purposes */
  label: string;

  /** Content to display in the left side of the header */
  leftHeaderContent?: ReactElement;

  /** Event handler called when the open state of the drawer changes */
  onOpenChange: (isOpen: boolean) => void;

  /** Whether an indicator should be shown on top of the header to signify new activity */
  hasNewActivity?: boolean;

  newActivityType?: 'info' | 'warning';

  isFullScreen?: boolean;

  isWide?: boolean;

  /** Open state used to control whether the drawer is collapsed or expanded */
  isOpen: boolean;

  /** Buttons to display in the right side of the header */
  toolbar: ReactElement;

  transparentHeader?: boolean;
};

function HeaderWithToolbar({
  className,
  hasNewActivity,
  header,
  isOpen,
  leftHeaderContent,
  toolbar,
  newActivityType,
  transparentHeader = false,
}: {
  className?: string;
} & Pick<
  PopoutDrawerProps,
  | 'hasNewActivity'
  | 'header'
  | 'isOpen'
  | 'leftHeaderContent'
  | 'toolbar'
  | 'newActivityType'
  | 'transparentHeader'
>) {
  const headerContainerRef = useRef<HTMLDivElement>(null);
  const toolbarContainerRef = useRef<HTMLDivElement>(null);
  const leftHeaderContentRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const updateWidthOfHeader = () => {
      if (
        headerContainerRef.current &&
        toolbarContainerRef.current &&
        leftHeaderContentRef.current
      ) {
        headerContainerRef.current.style.width = `calc(100% - ${
          toolbarContainerRef.current.offsetWidth +
          4 + // Additional pixel value is to separate the toolbar from the header text
          leftHeaderContentRef.current.offsetWidth
        }px)`;
        headerContainerRef.current.style.marginLeft = `${leftHeaderContentRef.current.offsetWidth}px`;
      }
    };

    updateWidthOfHeader();

    window.addEventListener('resize', updateWidthOfHeader);

    return () => window.removeEventListener('resize', updateWidthOfHeader);
  }, [leftHeaderContent]);

  const [shouldHighlightHeader, setShouldHighlightHeader] = useState(false);
  const highlightHeaderTimeout = useRef<Timeout>();

  useEffect(() => {
    if (hasNewActivity && !isOpen) {
      setShouldHighlightHeader(true);

      highlightHeaderTimeout.current = setTimeout(() => {
        setShouldHighlightHeader(false);
      }, 2000);
    } else {
      setShouldHighlightHeader(false);
      clearTimeout(highlightHeaderTimeout.current);
    }

    return () => {
      clearTimeout(highlightHeaderTimeout.current);
    };
  }, [hasNewActivity, isOpen]);

  return (
    <div
      className={twMerge('relative', className)}
      style={
        {
          '--foreground': transparentHeader ? '#262626' : '#ffffff',
          '--foreground-alt': transparentHeader ? '#262626' : '#fafafa',
          '--foreground-hover': transparentHeader ? '#fafafa' : '#595959',
        } as CSSProperties
      }
    >
      {Boolean(hasNewActivity) && (
        <div
          className={twMerge(
            'absolute -right-2 -top-2 h-4 w-4 rounded-full border border-gray-1 bg-primary-6',
            newActivityType === 'warning' && 'bg-warning-6'
          )}
        />
      )}

      <div className="absolute left-2 top-2" ref={leftHeaderContentRef}>
        {leftHeaderContent}
      </div>

      <motion.div
        animate={{
          backgroundColor: transparentHeader
            ? '#ffffff'
            : shouldHighlightHeader
              ? newActivityType === 'info'
                ? '#2F54EB'
                : '#FA8C16'
              : '#262626',
        }}
        className="w-full rounded-t-lg"
        initial={{ backgroundColor: transparentHeader ? '#ffffff' : '#262626' }}
        transition={{ duration: 0.5 }}
      >
        <Trigger className="w-full rounded-t-lg border-x border-t border-gray-5 p-2">
          <div
            className="overflow-hidden whitespace-nowrap"
            ref={headerContainerRef}
          >
            {header}
          </div>
        </Trigger>
      </motion.div>

      <div className="absolute right-2 top-2 flex" ref={toolbarContainerRef}>
        {toolbar}
      </div>
    </div>
  );
}

function canInteractOutside(node: HTMLElement): boolean {
  //If any new element is added with usage of portal, add its unique attribute to the base case here
  if (node.getAttribute('data-modal') === 'true') {
    return false;
  }
  return node.parentElement ? canInteractOutside(node.parentElement) : true;
}

export const PopoutDrawer = forwardRef(
  (props: PopoutDrawerProps, ref: Ref<HTMLDivElement>) => {
    const {
      children,
      hasNewActivity,
      header,
      label,
      leftHeaderContent,
      isFullScreen = false,
      isWide = false,
      isOpen,
      onOpenChange,
      toolbar,
      newActivityType = 'info',
      transparentHeader,
    } = props;

    const { deviceType } = useDeviceInfo();
    const isMobile = deviceType === 'mobile';

    const drawerHeader = (
      <HeaderWithToolbar
        className={twMerge(
          'w-full',
          !isMobile && !isOpen && 'min-w-[180px] max-w-[272px]'
        )}
        hasNewActivity={hasNewActivity}
        header={header}
        isOpen={isOpen}
        leftHeaderContent={leftHeaderContent}
        toolbar={toolbar}
        newActivityType={newActivityType}
        transparentHeader={transparentHeader}
      />
    );

    const content = (
      <Content
        forceMount={isMobile || undefined}
        aria-label={label}
        className={twMerge(
          'flex h-[80vh] min-h-[314px] w-[512px] shrink-0 flex-col rounded-t-lg shadow-lg-down',
          !isMobile && !isFullScreen && 'max-h-[800px]',
          isFullScreen &&
            'fixed left-1/2 top-1/2 z-[11] h-[calc(100vh-48px)] w-[calc(100vw-48px)] -translate-x-1/2 -translate-y-1/2 transform rounded-b-lg',
          isFullScreen &&
            (isWide ? 'max-w-[calc(100vw-100px)]' : 'max-w-[896px]'),
          isMobile && 'mt-4 h-[calc(100dvh-16px)] w-screen shadow-md-up'
        )}
        onInteractOutside={(e) => {
          if (isFullScreen) {
            // When not in full screen, prevent the dialog from closing when outside is clicked
            if (!canInteractOutside(e.target as HTMLElement)) {
              e.preventDefault();
            }
          } else {
            e.preventDefault();
          }
        }}
        ref={ref}
      >
        {drawerHeader}
        <div
          className={twMerge(
            'relative flex-1 overflow-auto border-x border-gray-5 bg-gray-1',
            isFullScreen && 'rounded-b-lg'
          )}
        >
          {children}
        </div>
      </Content>
    );

    return (
      <Root
        modal={isMobile || isFullScreen}
        onOpenChange={onOpenChange}
        open={isOpen}
      >
        {!isOpen ? drawerHeader : null}
        {isMobile ? (
          <AnimatePresence>
            {Boolean(isOpen) && (
              <motion.div
                key="popout-content"
                animate={{ y: '-100dvh' }}
                exit={{ y: '55px' }}
                initial={{ y: '55px' }}
                transition={{
                  duration: 0.4,
                }}
                className="absolute h-full w-full"
              >
                <RemoveScroll forwardProps>{content}</RemoveScroll>
              </motion.div>
            )}
          </AnimatePresence>
        ) : isFullScreen ? (
          <Portal>
            <Overlay className="fixed inset-0 z-[11] bg-neutral-secondary bg-opacity-75 transition-opacity" />
            {content}
          </Portal>
        ) : (
          content
        )}
      </Root>
    );
  }
);

PopoutDrawer.displayName = 'PopoutDrawer';
