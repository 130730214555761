import type { GifRatingsProps } from '@assembly-web/services';
import { GiphyFetch } from '@giphy/js-fetch-api';
import type { IGif } from '@giphy/js-types';
import { Grid } from '@giphy/react-components';
import { type SyntheticEvent, useCallback, useMemo, useState } from 'react';
import { defineMessages, useIntl } from 'react-intl';
import { twMerge } from 'tailwind-merge';

import { GifLoader } from './GifLoader';

const messages = defineMessages({
  noGifsFound: {
    defaultMessage: 'No GIFs found.',
    id: 'kCVl2W',
  },
});

type GiphyListProps = {
  onGifClick: (gif: IGif, event: SyntheticEvent<HTMLElement>) => void;
  searchTerm: string;
  rating?: GifRatingsProps;
  classes?: string;
  apiKey: string;
};

export function GiphyList({
  searchTerm,
  rating,
  classes,
  apiKey,
  onGifClick,
}: GiphyListProps) {
  const giphyFetch = useMemo(() => new GiphyFetch(apiKey), [apiKey]);
  const { formatMessage } = useIntl();
  const [loading, setLoading] = useState(false);

  const fetchGifs = useCallback(
    (offset = 0) => {
      if (offset === 0) setLoading(true);
      const giphyPromise = searchTerm
        ? giphyFetch.search(searchTerm, { offset, limit: 10, rating })
        : giphyFetch.trending({ offset, limit: 10, rating });

      giphyPromise
        .then(() => {
          //TODO: Add logger events on success
        })
        .catch(() => {
          //TODO: Add logger events on failure
        })
        .finally(() => {
          setLoading(false);
        });
      return giphyPromise;
    },
    [giphyFetch, rating, searchTerm]
  );
  const NoResults = (
    <div className="no-results flex items-center justify-center">
      {formatMessage(messages.noGifsFound)}
    </div>
  );

  if (loading) {
    return <GifLoader />;
  }
  return (
    <div>
      {/* <SuggestionBar /> */}
      <Grid
        width={320}
        columns={3}
        gutter={6}
        fetchGifs={fetchGifs}
        key={searchTerm}
        noResultsMessage={NoResults}
        onGifClick={onGifClick}
        className={twMerge('gif-grid inset-0 h-[320px] w-full', classes)}
        hideAttribution={true}
      />
    </div>
  );
}
