import {
  ArrowsPointingInIcon,
  MagnifyingGlassMinusIcon,
  MagnifyingGlassPlusIcon,
} from '@heroicons/react/20/solid';
import * as Slider from '@radix-ui/react-slider';
import { motion, useMotionValue } from 'framer-motion';
import {
  useCallback,
  useEffect,
  useRef,
  useState,
  type WheelEventHandler,
} from 'react';
import { defineMessages, useIntl } from 'react-intl';

import { Tooltip } from '../../../../DesignSystem/Feedback/Tooltip';
import { IconButton } from '../../../../DesignSystem/Inputs/IconButton';

const messages = defineMessages({
  adjustZoomLevel: {
    defaultMessage: 'Adjust zoom level',
    id: 'hEhE3v',
  },
  resetZoom: {
    defaultMessage: 'Reset zoom',
    id: 'nQhPfW',
  },
  zoomIn: {
    defaultMessage: 'Zoom in',
    id: 'xbi38c',
  },
  zoomLevel: {
    defaultMessage: 'Zoom level',
    id: '6GminY',
  },
  zoomOut: {
    defaultMessage: 'Zoom out',
    id: '/UnJ3S',
  },
});

export const ImagePreviewer = ({
  imageURL,
  isOpen,
}: {
  imageURL: string;
  isOpen: boolean;
}) => {
  const { formatMessage } = useIntl();

  const defaultButtonRef = useRef<HTMLButtonElement>(null);

  const scale = useMotionValue(1);
  const x = useMotionValue(0);
  const y = useMotionValue(0);

  const [zoomLevel, setZoomLevel] = useState(100);

  const zoomStep = 5;
  const minZoomLevel = 50;
  const maxZoomLevel = 200;

  useEffect(() => {
    scale.set(zoomLevel / 100);
  }, [zoomLevel, scale]);

  const handleZoomReset = useCallback(() => {
    scale.set(1);
    x.set(0);
    y.set(0);
    setZoomLevel(100);
  }, [scale, x, y]);

  const handleZoomIn = () => {
    const newZoomLevel = zoomLevel + zoomStep;
    if (newZoomLevel <= maxZoomLevel) {
      setZoomLevel(newZoomLevel);
    }
  };

  const handleZoomOut = () => {
    const newZoomLevel = zoomLevel - zoomStep;
    if (newZoomLevel >= minZoomLevel) {
      setZoomLevel(newZoomLevel);
    }
  };

  const handleWheel: WheelEventHandler<HTMLImageElement> = (event) => {
    event.preventDefault();
    const newScale = scale.get() - event.deltaY * 0.001;
    if (newScale >= 1) {
      setZoomLevel(newScale * 100);
    }
  };

  useEffect(() => defaultButtonRef.current?.focus(), [isOpen]);

  useEffect(() => {
    handleZoomReset();
  }, [handleZoomReset]);

  return (
    <div className="relative flex h-[calc(100%-2.5rem)] w-full justify-center overflow-auto bg-gray-3">
      <motion.img
        src={imageURL}
        alt=""
        className="absolute bottom-0 left-0 right-0 top-0 m-auto h-full object-contain"
        style={{ x, y, scale }}
        drag
        dragConstraints={{
          left: -500,
          right: 500,
          top: -500,
          bottom: 500,
        }}
        onWheel={handleWheel}
      />
      <div className="absolute bottom-2 flex h-8 w-[210px] items-center justify-center space-x-2 overflow-hidden rounded-lg border-gray-5 bg-gray-3 shadow-base-down">
        <IconButton
          variation="secondaryLite"
          size="xSmall"
          className="border-none bg-transparent p-2 hover:bg-gray-1 focus:bg-gray-1"
          tooltip={formatMessage(messages.zoomOut)}
          onClick={handleZoomOut}
        >
          <MagnifyingGlassMinusIcon className="h-4 w-4" />
        </IconButton>

        <Slider.Root
          className="relative flex h-5 w-[100px] touch-none select-none items-center"
          min={minZoomLevel}
          max={maxZoomLevel}
          step={zoomStep}
          value={[zoomLevel]}
          onValueChange={(values) => setZoomLevel(values[0])}
        >
          <Slider.Track className="relative h-[3px] grow rounded-full bg-gray-5">
            <Slider.Range className="absolute h-full rounded-full bg-gray-1" />
          </Slider.Track>
          <Tooltip tooltipText={formatMessage(messages.adjustZoomLevel)}>
            <Slider.Thumb
              className="block h-4 w-4 rounded-full bg-gray-1 shadow-[0_1px_8px] shadow-gray-8 focus:shadow-[0_0_0_3px] focus:shadow-primary-5 focus:outline-none"
              aria-label={formatMessage(messages.zoomLevel)}
            />
          </Tooltip>
        </Slider.Root>
        <IconButton
          variation="secondaryLite"
          size="xSmall"
          className="border-none bg-transparent p-2 hover:bg-gray-1 focus:bg-gray-1"
          tooltip={formatMessage(messages.zoomIn)}
          onClick={handleZoomIn}
          ref={defaultButtonRef}
        >
          <MagnifyingGlassPlusIcon className="h-4 w-4" />
        </IconButton>
        <div className="h-8 w-[1px] bg-gray-5 px-[0.5px]" />
        <IconButton
          variation="secondaryLite"
          size="xSmall"
          className="border-none bg-transparent p-2 hover:bg-gray-1 focus:bg-gray-1"
          tooltip={formatMessage(messages.resetZoom)}
          onClick={handleZoomReset}
        >
          <ArrowsPointingInIcon className="h-4 w-4" />
        </IconButton>
      </div>
    </div>
  );
};
