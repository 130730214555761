import { sanitizeHtml } from '@assembly-web/services';
import type { VariantProps } from 'class-variance-authority';
import { cva } from 'class-variance-authority';
import type { ForwardedRef, HTMLProps, ReactNode } from 'react';
import { forwardRef } from 'react';
import type { MergeExclusive } from 'type-fest';

const textStyles = cva([], {
  variants: {
    size: {
      xs: 'text-xs',
      sm: 'text-sm',
      base: 'text-base',
      lg: 'text-lg',
      xl: 'text-xl',
      '2xl': 'text-2xl',
      '3xl': 'text-3xl',
      '4xl': 'text-4xl',
      '5xl': 'text-5xl',
    },
    weight: {
      bold: 'font-bold',
      medium: 'font-medium',
      regular: 'font-normal',
      italic: 'italic font-normal',
    },
    subdued: {
      true: 'text-gray-8',
    },
  },
  defaultVariants: {
    size: 'base',
    subdued: false,
    weight: 'regular',
  },
});

type Size = NonNullable<VariantProps<typeof textStyles>['size']>;
type Weight = NonNullable<VariantProps<typeof textStyles>['weight']>;
export type TextVariant = `${Size}-${Weight}`;

export type TextStyleProps = {
  /** @default 'p' */
  as?: 'span' | 'p';

  className?: string;

  /** @default 'base-regular' */
  variant?: TextVariant;

  /** @default false */
  subdued?: boolean;

  /** text to be displayed on hover over(similar to html title attribute) */
  title?: string;

  ref?: ForwardedRef<HTMLParagraphElement>;
} & MergeExclusive<
  {
    html: string | TrustedHTML;
  },
  {
    children: ReactNode;
  }
> &
  (HTMLProps<HTMLParagraphElement> | HTMLProps<HTMLSpanElement>);

export const TextStyle = forwardRef(
  (props: TextStyleProps, ref: ForwardedRef<HTMLParagraphElement>) => {
    const {
      title,
      children,
      className,
      subdued = false,
      as: Tag = 'p',
      variant = 'base-regular',
      html,
      ...rest
    } = props;
    const [size, weight] = variant.split('-') as [Size, Weight];

    if (html) {
      const sanitizedHtml =
        typeof html === 'string' ? sanitizeHtml(html) : html;

      return (
        <Tag
          className={textStyles({ size, weight, subdued, className })}
          title={title}
          dangerouslySetInnerHTML={{ __html: sanitizedHtml }}
          ref={ref}
          {...rest}
        />
      );
    }

    return (
      <Tag
        className={textStyles({ size, weight, subdued, className })}
        title={title}
        ref={ref}
        {...rest}
      >
        {children}
      </Tag>
    );
  }
);

TextStyle.displayName = 'TextStyle';
