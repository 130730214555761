export enum CreditState {
  Unverified = 'UNVERIFIED',
  UnverifiedByAdmin = 'UNVERIFIED_BY_ADMIN',
  SoftVerified = 'SOFTVERIFIED',
  SoftVerifiedByAdmin = 'SOFTVERIFIED_BY_ADMIN',
  VerifiedByAdmin = 'VERIFIED_BY_ADMIN',
  DeniedByAdmin = 'DENIED_BY_ADMIN',
  Verified = 'VERIFIED',
}

export type AssemblyAccounts = {
  creditState: CreditState;
  rewards: {
    isChargeable: boolean;
  };
};
