import type { ClaimState } from '@assembly-web/services';
import { isSafari } from '@assembly-web/services';
import type { ColumnDef, ColumnPinningState } from '@tanstack/react-table';
import { getCoreRowModel, useReactTable } from '@tanstack/react-table';
import { type ReactNode, useEffect, useState } from 'react';

import { DataTable } from '../../../../DesignSystem/Datatable/DataTable';

type ApprovalTableProps<TData, TValue> = {
  columns: ColumnDef<TData, TValue>[];
  data: TData[];
  claimStatus: ClaimState;
  isFetchingNextPage?: boolean;
  hasNextPage?: boolean;
  onLoadMore: (startIndex?: number, endIndex?: number) => void | Promise<void>;
  loader?: ReactNode;
  isLoading?: boolean;
  tableId: string;
};

export function ApprovalTable<TData, TValue>({
  columns,
  data,
  claimStatus,
  isFetchingNextPage,
  hasNextPage,
  onLoadMore,
  loader,
  isLoading,
  tableId,
}: ApprovalTableProps<TData, TValue>) {
  const [columnVisibility, setColumnVisibility] = useState<{
    claimActionButtons?: boolean;
    status?: boolean;
  }>({
    claimActionButtons: claimStatus === 'PENDING',
    status: claimStatus !== 'PENDING',
  });

  const [pinnedColumns, setPinnedColumns] = useState<ColumnPinningState>({
    left: ['claimActionButtons'],
  });

  useEffect(() => {
    setColumnVisibility({
      claimActionButtons: claimStatus === 'PENDING',
      status: claimStatus !== 'PENDING',
    });
    setPinnedColumns({
      left: ['claimActionButtons'],
    });
  }, [claimStatus]);

  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
    defaultColumn: {
      size: 96,
      minSize: 96,
      maxSize: 182,
    },
    state: {
      columnVisibility,
      columnPinning: pinnedColumns,
    },
    initialState: {
      columnPinning: {
        left: ['claimActionButtons'],
        right: [],
      },
    },
    onColumnVisibilityChange: setColumnVisibility,
    onColumnPinningChange: setPinnedColumns,
  });

  return (
    <DataTable
      variant="custom"
      {...(isSafari
        ? {
            height: '15rem',
          }
        : {})}
      table={table}
      onLoadMore={onLoadMore}
      loader={loader}
      isLoading={isLoading}
      isFetchingNextPage={isFetchingNextPage}
      hasNextPage={hasNextPage}
      tableHeaderRowClassName="flex items-end"
      tableCellClassName={'flex items-center'}
      tableRowClassName={'flex items-center p-0'}
      tableId={tableId}
      itemSize={56}
    />
  );
}
