import { cva } from 'class-variance-authority';
import type { ReactNode } from 'react';

const headingStyles = cva(['font-medium', 'text-gray-9'], {
  variants: {
    as: {
      h1: 'text-5xl',
      h2: 'text-4xl',
      h3: 'text-3xl',
      h4: 'text-2xl',
      h5: 'text-xl',
      h6: 'text-lg',
    },
  },
  defaultVariants: {
    as: 'h2',
  },
});

type HeadingProps = {
  className?: string;
  children: ReactNode;
  as?: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6';
};

export function Heading({ children, as: Tag = 'h2', className }: HeadingProps) {
  return (
    <Tag className={headingStyles({ as: Tag, className })}>{children}</Tag>
  );
}
