import type { Channel } from 'pusher-js';
import { useEffect } from 'react';

type CallbackArgs<T> = {
  data?: T;
  eventName: string;
  metaData?: unknown;
};

/**
 * A React hook that subscribes to events on a Pusher channel and calls a callback when those events occur.
 *
 * @template T - The type of the data expected to be received from the Pusher events.
 *
 * @param {Channel | undefined} channel - The Pusher channel to bind or unbind events to. If undefined, the hook will do nothing.
 * @param {string[]} eventNames - An array of event names to subscribe to on the given channel.
 * @param {function(CallbackArgs<T>):void} callback - The callback function to be invoked when any of the specified events occur on the channel.
 *
 * @example
 * usePusherEvents(someChannel, ['event1', 'event2'],
 * ({ eventName, data, metaData }) => {
 *    console.log(`Received ${eventName} with data:`, data);
 *  });
 */
export function usePusherEvents<T>(
  channel: Channel | undefined,
  eventNames: string[],
  callback: (args: CallbackArgs<T>) => void
) {
  useEffect(() => {
    const boundEvents: Record<string, (data: T, metaData?: unknown) => void> =
      {};

    const bindEvent = (eventName: string) => {
      const handler = (data: T, metaData?: unknown) => {
        callback({ eventName, data, metaData });
      };
      channel?.bind(eventName, handler);
      boundEvents[eventName] = handler;
    };

    const unbindEvent = (eventName: string) => {
      channel?.unbind(eventName, boundEvents[eventName]);
    };

    eventNames.forEach(bindEvent);

    return () => {
      eventNames.forEach(unbindEvent);
    };
  }, [channel, eventNames, callback]);
}
