import { useLayoutEffect, useState } from 'react';

export const useRect = ({
  enabled = false,
  ref,
}: {
  ref: React.MutableRefObject<HTMLElement | null>;
  enabled?: boolean;
}) => {
  const [domRect, setDomRect] = useState<DOMRect | null>(null);

  useLayoutEffect(() => {
    if (!enabled) return;

    const el = ref.current;

    const handleResize = () => {
      if (el) {
        setDomRect(el.getBoundingClientRect());
      }
    };

    handleResize();
    el?.addEventListener('resize', handleResize);

    return () => {
      el?.removeEventListener('resize', handleResize);
    };
  }, [enabled, ref]);

  return domRect;
};
