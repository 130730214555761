import type { ReactNode } from 'react';

import { Tabs } from '../../Shared/Tabs';
import {
  PeopleWithAccess,
  type PeopleWithAccessProps,
} from './PeopleWithAccess';
import { PreviewMembers, type PreviewMembersProps } from './PreviewMembers';

export type ShareModalContentProps = {
  tab?: { title: string; content?: ReactNode };
} & (
  | ({
      view: 'previewMembers';
    } & PreviewMembersProps)
  | ({
      view: 'peopleWithAccess';
      disabled?: boolean;
      tooltipText?: string;
    } & PeopleWithAccessProps)
);

export function ShareModalContent({ tab, ...tail }: ShareModalContentProps) {
  if (tab && tail.view === 'peopleWithAccess') {
    return (
      <Tabs
        tabs={[
          {
            id: 'defaultTab',
            title: tab.title,
            content: (
              <>
                <PeopleWithAccess {...tail} />
                {tab.content}
              </>
            ),
          },
        ]}
        selectedTab="defaultTab"
        onTabChange={() => {}}
      />
    );
  }

  if (tail.view === 'peopleWithAccess') {
    return <PeopleWithAccess {...tail} />;
  }

  return <PreviewMembers {...tail} />;
}
