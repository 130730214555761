import type {
  AssemblyCurrency,
  FlowPostResponse,
  MemberDetails,
  ReactionDetails,
  RepliesResponse,
} from '@assembly-web/services';
import { twMerge } from 'tailwind-merge';

import type { ToolbarItem } from '../../../DesignSystem/Feedback/Toolbar/Toolbar';
import { Toolbar } from '../../../DesignSystem/Feedback/Toolbar/Toolbar';
import { useToolbarState } from '../../../DesignSystem/Feedback/Toolbar/useToolbarState';
import { HorizontalRule } from '../../../DesignSystem/Layout/HorizontalRule';
import type { AnnouncementBannerProps } from './AnnouncementBanner';
import { AnnouncementBanner } from './AnnouncementBanner';
import { PostCardBody } from './PostCardBody';
import { PostCardFooter } from './PostCardFooter';
import { PostCardHeader } from './PostCardHeader';

export enum PostCardActions {
  PersonClicked = 'person',
  FlowNameClicked = 'flowName',
  RepliesBarClicked = 'replies',
  TaskClicked = 'task',
}

type AnnouncementResponse = {
  color: string;
  title: string;
  viewsCount: number;
  description: string;
  showInsights: boolean;
  announcementId: string;
};

export type PostCardToolbarProps = Partial<{
  primaryToolbarItems: ToolbarItem[];
  secondaryToolbarItems: ToolbarItem[];
  onToolbarMenuItemClick: (args: ToolbarItem) => void;
}>;

export type PostCardProps = {
  additionalClassName?: string;
  hasReplyDraft: boolean;
  canShowAnnouncements: boolean;
  repliesData: RepliesResponse;
  flowResponse: FlowPostResponse;
  reactions: ReactionDetails[];
  announcementData?: AnnouncementResponse;
  onReactionClick: (emoji: ReactionDetails) => void;
  onReplyButtonClick: () => void;
  currentMember: MemberDetails;
  onPostCardHeaderClick: (action: PostCardActions, id: string) => void;
  onPostCardBodyClick: (action: PostCardActions, id: string) => void;
  currency: AssemblyCurrency;
  onFileClick: (responseBlock: { blockId: string; fileName: string }) => void;
  onBlockToolbarItemClick?: (responseBlock: {
    blockId: string;
    fileName: string;
    itemId: string;
  }) => void;
  openAnnouncementsInsightsModal?: (announcementId: string) => void;
  onAnnouncementDescriptionHover?: (args: AnnouncementBannerProps) => void;
  onMemberClick: (memberId: string) => void;
} & PostCardToolbarProps;

export function PostCard(props: PostCardProps) {
  const {
    additionalClassName,
    repliesData,
    flowResponse,
    reactions,
    currentMember,
    hasReplyDraft,
    onReactionClick,
    onReplyButtonClick,
    onPostCardHeaderClick,
    onPostCardBodyClick,
    currency,
    onFileClick,
    onMemberClick,
    onBlockToolbarItemClick,
    openAnnouncementsInsightsModal,
    canShowAnnouncements,
    onAnnouncementDescriptionHover,
    primaryToolbarItems,
    secondaryToolbarItems,
    onToolbarMenuItemClick,
  } = props;

  const {
    respondent,
    createdAt,
    flow: {
      name: flowName,
      icon: { value: flowIcon },
      flowId,
    },
    visibility,
    responses,
  } = flowResponse;

  const isRecognition = flowId === 'recognition';

  const { getContainerProps, getToolbarProps } = useToolbarState();

  return (
    <div
      className={twMerge(
        'rounded-lg border border-gray-5 bg-gray-1 @container',
        additionalClassName
      )}
      {...getContainerProps()}
    >
      {Boolean(canShowAnnouncements) && (
        <>
          {flowResponse.activeAnnouncement?.color ? (
            <AnnouncementBanner
              color={flowResponse.activeAnnouncement.color}
              title={flowResponse.activeAnnouncement.title}
              description={flowResponse.activeAnnouncement.description}
              onAnnouncementDescriptionHover={onAnnouncementDescriptionHover}
            />
          ) : null}
        </>
      )}
      <div className="px-4 pb-2 pt-4">
        <PostCardHeader
          respondent={respondent}
          createdAt={createdAt}
          flowName={flowName}
          visibility={visibility}
          flowIcon={flowIcon}
          flowId={flowId}
          onPostCardHeaderClick={onPostCardHeaderClick}
          isEdited={Boolean(isRecognition) && responses[0].edited} // only show for recognition
          toolbar={
            Boolean(secondaryToolbarItems ?? primaryToolbarItems) &&
            Boolean(onToolbarMenuItemClick) && (
              <Toolbar
                className="w-fit"
                secondaryToolbarItems={secondaryToolbarItems ?? []}
                primaryToolbarItems={primaryToolbarItems}
                {...getToolbarProps({
                  onMenuItemClick: onToolbarMenuItemClick,
                })}
              />
            )
          }
          {...(canShowAnnouncements &&
            flowResponse.activeAnnouncement?.showInsights && {
              openViewInsightsModal: () =>
                openAnnouncementsInsightsModal?.(
                  flowResponse.activeAnnouncement?.announcementId ?? ''
                ),
            })}
        />
        <PostCardBody
          currency={currency}
          onFileClick={onFileClick}
          flowResponse={flowResponse}
          onMemberClick={onMemberClick}
          onPostCardBodyClick={onPostCardBodyClick}
          onBlockToolbarItemClick={onBlockToolbarItemClick}
        />
        <HorizontalRule />
        <PostCardFooter
          reactions={reactions}
          showDraft={hasReplyDraft}
          repliesData={repliesData}
          currentMember={currentMember}
          onReactionClick={onReactionClick}
          onReplyButtonClick={onReplyButtonClick}
          hideReplies={flowResponse.hideReplies ?? false}
          hideReactions={flowResponse.hideReactions ?? false}
        />
      </div>
    </div>
  );
}
