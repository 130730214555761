import { logger } from './logger';
import type { BlockResponseDetails } from './types/blockTypes';
import type { MemberDetails, ReactionDetails } from './types/flowTypes';
import { BlockTypes } from './types/flowTypes';
import type { Reaction } from './types/reactions';

export const defaultCurrencyIcon = '1f3c6';
export function mapHexCodeToEmoticon(code: string) {
  // This logic, to map the code returned by the API for a flow's emoticon, is copied from the web app's codebase.
  // https://github.com/joinassembly/assembly_frontend/blob/332531ba9e3330252d95e9b75ec51f0e101cd3f8/src/Utils/mappers/index.ts#L4-L20
  try {
    if (code.indexOf('-') > 0) {
      return String.fromCodePoint(
        ...code.split('-').map((item) => Number(`0x${item}`))
      );
    }

    return String.fromCodePoint(Number(`0x${code}`));
  } catch (error) {
    logger.error(
      'Error in mapHexCodeToEmoticon function',
      {
        code,
      },
      error as Error
    );

    return '';
  }
}

export function deserializeReactions({
  payload,
  currentUser,
  reactions,
  action,
}: {
  payload: Reaction;
  currentUser: MemberDetails | undefined;
  reactions: ReactionDetails[];
  action: string;
}) {
  const index = reactions.findIndex(
    (reaction) => reaction.name === payload.name
  );
  if (action === 'set' && currentUser) {
    if (index < 0) {
      reactions.push({
        ...payload,
        members: [{ ...currentUser }],
      });
    } else {
      reactions[index].members.push({
        ...currentUser,
      });
    }
  } else {
    const currentReaction = reactions[index];
    if (currentReaction.members.length > 1) {
      currentReaction.members = currentReaction.members.filter(
        (member) => member.memberID !== currentUser?.memberID
      );
    } else {
      reactions.splice(index, 1);
    }
  }
}

export function checkIfFlowPostHasTrophies(
  flowResponses: BlockResponseDetails[]
) {
  let hasTrophies = false;
  flowResponses.forEach((block) => {
    if (block.type === BlockTypes.GivePointsStack) {
      hasTrophies = Boolean(block.response?.value);
    }
  });
  return hasTrophies;
}
