import { defineMessages, useIntl } from 'react-intl';

import { Banner } from '../../../DesignSystem/Feedback/Banner';
import { TextStyle } from '../../../DesignSystem/Feedback/TextStyle';

const messages = defineMessages({
  tipsForSuccessfulRedemption: {
    id: 'wL/2O8',
    defaultMessage: 'Tips for successful redemption:',
  },
  tipNumberOne: {
    id: '28pdsx',
    defaultMessage:
      '1. Prepaid digital solutions will ask you for personal identifying information like your',
  },
  email: {
    id: 'yUVl6+',
    defaultMessage: 'email',
  },
  and: {
    id: '3mvL2Q',
    defaultMessage: 'and',
  },
  address: {
    id: 'Oa/XSP',
    defaultMessage: 'address',
  },
  tipNumberTwo: {
    id: 'ARnOs/',
    defaultMessage: '2. Please make sure they are entered',
  },
  correctly: {
    id: 'wLuWx7',
    defaultMessage: 'correctly',
  },
  tipTwoContinuation: {
    id: 'YN9xkv',
    defaultMessage:
      '(you will not be able to edit them once you submit them to the gift card provider)',
  },
});

export function RedemptionInstructionsBanner() {
  const { formatMessage } = useIntl();
  return (
    <Banner className="mt-2 rounded-xl" status="info">
      <TextStyle variant="xs-regular" as="span">
        {formatMessage(messages.tipsForSuccessfulRedemption)}
        <br />
      </TextStyle>
      <TextStyle variant="xs-regular" as="span">
        {formatMessage(messages.tipNumberOne)}&nbsp;
        <TextStyle variant="xs-bold" as="span">
          {formatMessage(messages.email)}&nbsp;
        </TextStyle>
        {formatMessage(messages.and)}&nbsp;
        <TextStyle variant="xs-bold" as="span">
          {formatMessage(messages.address)}
        </TextStyle>
      </TextStyle>
      <br />
      <TextStyle variant="xs-regular" as="span">
        {formatMessage(messages.tipNumberTwo)}&nbsp;
        <TextStyle variant="xs-bold" as="span">
          {formatMessage(messages.correctly)}{' '}
        </TextStyle>
        <TextStyle variant="xs-regular" as="span">
          {formatMessage(messages.tipTwoContinuation)}.
        </TextStyle>
      </TextStyle>
    </Banner>
  );
}
