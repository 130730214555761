import { SVGReactComponents } from '@assembly-web/assets';
import { DocumentDuplicateIcon, TrashIcon } from '@heroicons/react/24/outline';
import { ArrowDownIcon, ArrowUpIcon } from '@heroicons/react/24/solid';
import type { AriaAttributes, ReactNode } from 'react';
import { defineMessages, useIntl } from 'react-intl';
import type { MergeExclusive } from 'type-fest';

import { Tooltip } from '../../../DesignSystem/Feedback/Tooltip';
import { IconButton } from '../../../DesignSystem/Inputs/IconButton';
import { BlockOptionsDropdown } from './BlockOptionsDropdown';

type JustButtonProps = MergeExclusive<
  Pick<AriaAttributes, 'aria-label'>,
  Pick<AriaAttributes, 'aria-describedby'>
> & {
  tooltip?: string;
  onClick?: () => void;
  disabled?: boolean;
};

export type ButtonProps =
  | {
      type: 'menu';
      onSelect: () => void;
      label: ReactNode;
      value: string;
      disabled?: boolean;
    }
  | (JustButtonProps & {
      type: 'button';
    });

const messages = defineMessages({
  moveUp: {
    id: 'OZ0AxZ',
    defaultMessage: 'Move question up one',
  },
  moveDown: {
    id: 'f5LPwJ',
    defaultMessage: 'Move question down one',
  },
  drag: {
    id: 'zdV/OJ',
    defaultMessage: 'Hold and drag to rearrange',
  },
  duplicate: {
    id: 'Bj19lA',
    defaultMessage: 'Duplicate question',
  },
  delete: {
    id: 'FatYKS',
    defaultMessage: 'Delete question',
  },
});

function MoveUp(props: ButtonProps) {
  const { formatMessage } = useIntl();

  if (props.type === 'menu') {
    return <BlockOptionsDropdown.Item icon={<ArrowUpIcon />} {...props} />;
  }

  return (
    <Tooltip tooltipText={formatMessage(messages.moveUp)}>
      <IconButton variation="secondaryLite" size="xSmall" {...props}>
        <ArrowUpIcon />
      </IconButton>
    </Tooltip>
  );
}

function DragHandle(props: JustButtonProps) {
  const { formatMessage } = useIntl();

  return (
    <Tooltip tooltipText={formatMessage(messages.drag)}>
      <IconButton variation="secondaryLite" size="xSmall" {...props}>
        <SVGReactComponents.DragMove />
      </IconButton>
    </Tooltip>
  );
}

function MoveDown(props: ButtonProps) {
  const { formatMessage } = useIntl();

  if (props.type === 'menu') {
    return <BlockOptionsDropdown.Item icon={<ArrowDownIcon />} {...props} />;
  }

  return (
    <Tooltip tooltipText={formatMessage(messages.moveDown)}>
      <IconButton variation="secondaryLite" size="xSmall" {...props}>
        <ArrowDownIcon />
      </IconButton>
    </Tooltip>
  );
}

function Duplicate(props: ButtonProps) {
  const { formatMessage } = useIntl();

  if (props.type === 'menu') {
    return (
      <BlockOptionsDropdown.Item icon={<DocumentDuplicateIcon />} {...props} />
    );
  }

  return (
    <Tooltip tooltipText={formatMessage(messages.duplicate)}>
      <IconButton variation="secondaryLite" size="xSmall" {...props}>
        <DocumentDuplicateIcon />
      </IconButton>
    </Tooltip>
  );
}

function Delete(props: ButtonProps) {
  const { formatMessage } = useIntl();

  if (props.type === 'menu') {
    return (
      <BlockOptionsDropdown.Item
        icon={<TrashIcon className="stroke-error-6" />}
        {...props}
      />
    );
  }

  return (
    <Tooltip tooltipText={formatMessage(messages.delete)}>
      <IconButton variation="secondaryLite" size="xSmall" {...props}>
        <TrashIcon className="stroke-error-6" />
      </IconButton>
    </Tooltip>
  );
}

export const BlockActions = {
  Delete,
  DragHandle,
  Duplicate,
  MoveDown,
  MoveUp,
};
