import { useCallback, useState } from 'react';

type DirtyFields = Record<string, boolean>;

export const useDirtyFields = (initialState: DirtyFields) => {
  const [dirtyFields, setDirtyFields] = useState<DirtyFields>(initialState);

  const markFieldAsDirty = useCallback((fieldName: string) => {
    setDirtyFields((prev) => ({ ...prev, [fieldName]: true }));
  }, []);

  const resetDirtyFields = useCallback(
    (fields?: DirtyFields) => {
      setDirtyFields(fields ?? initialState);
    },
    [initialState]
  );

  return { dirtyFields, markFieldAsDirty, resetDirtyFields };
};
