import type { BlockType } from '@assembly-web/services';

import { TextStyle } from '../../../DesignSystem/Feedback/TextStyle';
import { Icon } from './BlockIcon';

export type Props = {
  type: BlockType;
  index: number;
  showIcon?: boolean;
};

export function BlockIndicator({ index, type, showIcon }: Props) {
  return (
    <div className="flex items-center [&_svg]:h-4 [&_svg]:w-4">
      {Boolean(showIcon) && <Icon dark type={type} />}
      <TextStyle
        className="block min-w-[20px] text-center"
        variant="sm-bold"
        as="span"
      >
        {index}.
      </TextStyle>
    </div>
  );
}
