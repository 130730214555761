import { useColorField } from '@react-aria/color';
import { type ColorFieldProps, useColorFieldState } from '@react-stately/color';
import { useRef } from 'react';

export function ColorPicker(props: ColorFieldProps & { 'aria-label': string }) {
  const state = useColorFieldState(props);
  const inputRef = useRef<HTMLInputElement>(null);
  const { labelProps, inputProps } = useColorField(props, state, inputRef);

  return (
    <div className="w-full">
      <label
        {...labelProps}
        aria-label={props['aria-label']}
        className="relative w-full"
      >
        <input
          {...inputProps}
          ref={inputRef}
          className="h-10 w-full rounded-md border-gray-5 py-2 pr-9"
        />
        <div className="absolute inset-y-0 right-[14px] my-[1px] flex items-center pl-3">
          <input
            {...inputProps}
            id={`${inputProps.id}-native-input`}
            type="color"
            className="h-6 w-6 rounded bg-transparent"
          />
        </div>
      </label>
    </div>
  );
}
