import type { AssemblyAccounts } from './accounts';
import type { AssemblyCurrency } from './currency';
import type { MemberRole, MemberState } from './flowTypes';

export enum PrivateMessageAndImpactLevelValues {
  ManagerOnly = 'MANAGER_ONLY',
  AdminsOnly = 'ADMINS_ONLY',
  ManagerAndCustomAllowancesOnly = 'MANAGER_AND_CUSTOM_ALLOWANCES_ONLY',
  Everyone = 'EVERYONE',
}

enum CurrencyLevel {
  Small = 'small',
  Medium = 'medium',
  Large = 'large',
  Huge = 'huge',
  Above = 'above',
}

export enum GifRatingsProps {
  Y = 'y',
  G = 'g',
  PG = 'pg',
  PG13 = 'pg-13',
  R = 'r',
}

export type PermissionAccessLevel = 'ADMINS_AND_OWNER' | 'MANAGER' | 'EVERYONE';

export type WorkspaceSettings = {
  userManagement: {
    approvalAccess: {
      enabled: boolean;
      value: string[];
    };
    inviteAccess: {
      enabled: boolean;
      value: PermissionAccessLevel[];
    };
    canLoginUsingLink: {
      enabled: boolean;
    };
  };
  coreValues: {
    enabled: boolean;
    value: string[];
  };
  postImpactLevel: {
    enabled: boolean;
    value: {
      customType: PrivateMessageAndImpactLevelValues;
      levels: { percentage: number; name: CurrencyLevel }[];
    };
  };
  privateMessage: {
    enabled: boolean;
    value: PrivateMessageAndImpactLevelValues;
  };
  minMessageChar: {
    enabled: boolean;
    value: number;
  };
  gifAccessibility: {
    enabled: boolean;
    value: GifRatingsProps;
  };
  blockWordsMessage: {
    enabled: boolean;
    value: string[];
  };
  canDeletePosts: {
    enabled: boolean;
    value: string;
  };
  activeSpecialMention: string;
  badgeAttachability: { enabled: boolean };
  imageAttachability: { enabled: boolean };

  theme?: {
    backgroundColor?: string | undefined | null;
  };
  challenge: {
    creation: {
      enabled: boolean;
      value: PermissionAccessLevel[];
    };
  };
};

export type WorkspaceSlug = {
  shortCode: string;
  name: string;
};

export type Assembly = {
  assemblyId: string;
  createdAt: string;
  accounts: AssemblyAccounts;
  name: string;
  logo: string;
  domain: string;
  timeZone: string;
  flow: {
    creation: {
      enabled: boolean;
      value: PermissionAccessLevel;
    };
    announcementPermission: {
      enabled: boolean;
      value: PermissionAccessLevel;
    };
  };
  workspaceSlug: WorkspaceSlug;
  whitelistedDomains: string[];
  currency: AssemblyCurrency;
  settings: WorkspaceSettings;
  exchangeRate: number;
  tangoDenominationRange?: {
    min: number | null;
    max: number | null;
  };
};

export type AssemblyBasicInfo = Pick<
  Assembly,
  'assemblyId' | 'logo' | 'name' | 'workspaceSlug'
>;

export type Workspace = {
  userId: string;
  inviteToken?: string;
  totalMembers: number;
} & AssemblyBasicInfo;

export type AssemblyInfoAPIResponse = {
  assemblyId: string;
  logo: string;
  name: string;
  workspaceSlug: WorkspaceSlug;
};

export type MemberAPIResponse = {
  member: Member;
  assembly: Assembly;
};

export enum AllowanceType {
  Regular = 'regular',
  Custom = 'custom',
  NoAllowance = 'no',
}

export enum AllowanceCycle {
  Monthly = 'monthly',
}

export enum MemberStatus {
  Normal = 'normal',
  Receiver = 'receiver',
  Observer = 'observer',
  Giver = 'giver',
}

export type MemberPermissions = {
  canPostAnnouncements: boolean;
  canCreateFlow: boolean;
  canInviteNewUsers: boolean;
  profanitySetting: { enabled: boolean; wordsToCheck: string[] };
};

export type Member = {
  memberId: string;
  email: string;
  status: MemberStatus;
  state: MemberState;
  profile: Profile;
  managerIds: string[];
  reportIds: string[];
  timeZone: string;
  activatedAt: string;
  name: string;
  firstName: string;
  lastName: string;
  username: string;
  isFreeEmail: boolean;
  anonymousIdentifier?: string;
  department?: string;
  jobTitle?: string;

  /** @deprecated avoid using this since some APIs don't return this value. use `memberId` instead */
  memberID: string;

  image?: string;
  pointsGiven: number;
  totalPointsGiven: number;
  memberState: MemberState;
  role: MemberRole[];
  pronouns?: string;
  profileThumbnails?: Record<number, string>;
  pointsEarned: number;
  pointsLeftThisCycle: number;
  allowance: {
    points: number;
    type: AllowanceType;
    cycle: AllowanceCycle;
  };
  permissions: MemberPermissions;
};

export type Profile = {
  firstName: string;
  lastName: string;
  fullName: string;
  jobTitle?: string;
  location: string;
  department?: string;
  image: string;
  username: string;
  isManager: boolean;
  birthday: Birthday;
  hiredday: HiredDay;
  pronouns?: string;
  isFirstLogin?: boolean;
  email?: string;
};

export type Birthday = {
  day?: number;
  month?: number;
  isVisibleAndCelebrated?: boolean;
};

export type HiredDay = {
  day?: number;
  month?: number;
  year?: number;
};

export type LegacyUserDetailsAPIResponse = {
  jwtToken: string;
  refreshToken?: string;
  user: LegacyUserRepresentation;
};

export type LegacyUserRepresentation = {
  firstName: string;
  lastName: string;
  isFirstLogin: boolean;
  assemblyId: string;
  // we don't need these fields for now
  _id: string;
  email: string;
  // role: string[];
  // isFreeEmail: boolean;
};

export type ListWorkspacesAPIResponse = {
  email: string;
  canCreate: boolean;
  isLegacySession: boolean;
  allowList: Workspace[];
  invited: Workspace[];
  partOf: Workspace[];
};

export type UserDetails = {
  member: Member;
  assembly: Assembly & { workspaceSlugPath: string };
};

export enum AssemblyPlanType {
  Lite = 'Lite',
  Starter = 'Starter',
  Premium = 'Premium',
  Standard = 'Standard',
}

enum PlanStatus {
  Enabled = 'ENABLED',
  Disabled = 'DISABLED',
}

export type EntityPermissionApiResponse = {
  data: {
    entityId: string;
    canEndAnnouncement?: boolean;
    canEditAnnouncement?: boolean;
    canShareAsAnnouncement: boolean;
  }[];
};

export type PlanId = 'lite' | 'free' | 'premium' | 'standard';

export type PlanFeaturesAPIResponse = {
  name: AssemblyPlanType;
  features: {
    id: string;
    value: number;
    category: string;
    status: PlanStatus;
    isPaymentRequired: boolean;
  }[];
  uniqueId?: PlanId;
};

type NullableCursor = string | null;

export type MetadataProps = {
  pagination: {
    cursor: {
      next: NullableCursor;
      previous: NullableCursor;
    };
  };
};
