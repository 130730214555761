import { type MouseEvent, useEffect, useRef, useState } from 'react';
import { defineMessages, useIntl } from 'react-intl';
import { twJoin, twMerge } from 'tailwind-merge';

import { LoadingSpinner } from '../../../../DesignSystem/Feedback/Icons/LoadingSpinner';
import { Tooltip } from '../../../../DesignSystem/Feedback/Tooltip';
import { Button } from '../../../../DesignSystem/Inputs/Button';
import { ClaimStatusBadge } from '../ClaimStatusBadge';

type ChallengeCardButtonsProps = {
  claimButtonText: string;
  onClaim?: () => void;
  onViewClaims?: () => void;
  showClaimButton?: boolean;
  showViewClaimsButton: boolean;
  isClaimButtonDisabled?: boolean;
  isViewClaimsButtonDisabled?: boolean;
  isClaimButtonLoading?: boolean;
  variant?: 'preview' | 'default' | 'drawer';
  showClaimBadge: boolean | undefined;
  isAllApproved?: boolean;
  claimCount?: number;
  isNotParticipant: boolean;
  layout: 'header' | 'content' | 'carousal' | 'mobile';
};

const messages = defineMessages({
  viewClaims: {
    defaultMessage: 'View claims',
    id: 'JcBLFP',
  },
  notParticipant: {
    defaultMessage:
      'You are not a participant. You can change this by editing this challenge.',
    id: '0S4Jxv',
  },
});

export const ChallengeCardButtons = (props: ChallengeCardButtonsProps) => {
  const { formatMessage } = useIntl();
  const {
    variant,
    claimButtonText,
    onClaim = () => {},
    onViewClaims = () => {},
    showClaimButton,
    showViewClaimsButton,
    isClaimButtonDisabled,
    isViewClaimsButtonDisabled = false,
    isClaimButtonLoading,
    showClaimBadge,
    isAllApproved,
    claimCount,
    layout,
    isNotParticipant,
  } = props;

  const claimButtonTextRef = useRef<HTMLSpanElement>(null);

  const [claimButtonTextOverflow, setClaimButtonTextOverflow] = useState(false);

  useEffect(() => {
    const onResize = () => {
      const claimButtonEl = claimButtonTextRef.current;
      if (claimButtonEl) {
        setClaimButtonTextOverflow(
          claimButtonEl.scrollWidth > claimButtonEl.clientWidth
        );
      }
    };

    window.addEventListener('resize', onResize);
    onResize();

    return () => window.removeEventListener('resize', onResize);
  }, [claimButtonText.length]);

  if (
    !showClaimButton &&
    !showViewClaimsButton &&
    !(showClaimBadge && isAllApproved)
  )
    return null;

  const handleOnClaim = (event: MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    onClaim();
  };

  const handleViewClaims = (event: MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    onViewClaims();
  };

  const showNotParticipantMessage = isNotParticipant && isClaimButtonDisabled;

  return (
    <div
      className={twJoin(
        'flex w-full min-w-[44px] flex-grow gap-2',
        variant !== 'drawer' &&
          'flex-col @md/challenge-card:w-auto @md/challenge-card:flex-row',
        (layout === 'mobile' || layout === 'carousal') && 'flex-wrap'
      )}
    >
      {Boolean(showClaimBadge && layout === 'header') && (
        <ClaimStatusBadge
          isAllApproved={isAllApproved}
          claimCount={claimCount}
        />
      )}
      {Boolean(showViewClaimsButton) && (
        <Tooltip tooltipText={formatMessage(messages.viewClaims)}>
          <Button
            size="small"
            variation="secondaryLite"
            onClick={handleViewClaims}
            className={
              variant === 'drawer' ||
              layout === 'mobile' ||
              layout === 'carousal'
                ? 'w-full'
                : '@md/challenge-card:flex-grow'
            }
            disabled={isViewClaimsButtonDisabled}
            data-testid="challenge-view-claims-button"
          >
            <span className="truncate">
              {formatMessage(messages.viewClaims)}
            </span>
          </Button>
        </Tooltip>
      )}
      {Boolean(showClaimButton) && (
        <div className={twMerge('flex w-full gap-2')}>
          <Tooltip
            tooltipText={
              (showNotParticipantMessage &&
                formatMessage(messages.notParticipant)) ??
              (Boolean(claimButtonTextOverflow) && claimButtonText)
            }
          >
            <Button
              size="small"
              variation="primary"
              onClick={handleOnClaim}
              disabled={isClaimButtonDisabled}
              className={
                variant === 'drawer' ||
                layout === 'mobile' ||
                layout === 'carousal'
                  ? 'w-full'
                  : '@md/challenge-card:flex-grow'
              }
              data-testid="challenge-button"
              isLoading={isClaimButtonLoading}
            >
              {Boolean(isClaimButtonLoading) && <LoadingSpinner />}
              <span className="truncate" ref={claimButtonTextRef}>
                {claimButtonText}
              </span>
            </Button>
          </Tooltip>
          {Boolean(
            showClaimBadge &&
              (layout === 'content' ||
                layout === 'carousal' ||
                layout === 'mobile')
          ) && (
            <ClaimStatusBadge
              isAllApproved={isAllApproved}
              claimCount={claimCount}
            />
          )}
        </div>
      )}
    </div>
  );
};
