import type { ReactNode } from 'react';
import { twJoin } from 'tailwind-merge';

type TextFieldProps = {
  alignment: 'start' | 'center' | 'end';
  className?: string;
  defaultValue?: string;
  disabled?: boolean;
  placeholder: string;
  onChange?: (value: string) => void;
  value?: string;
};

function TextField({
  alignment,
  className,
  defaultValue,
  disabled,
  onChange,
  placeholder,
  value,
}: TextFieldProps) {
  return (
    <input
      className={twJoin(
        'w-full max-w-[195px] rounded-lg border border-gray-5 px-3 py-2 placeholder:text-gray-8 disabled:bg-gray-2',
        alignment === 'start' && 'text-left',
        alignment === 'center' && 'text-center',
        alignment === 'end' && 'text-right',
        className
      )}
      defaultValue={defaultValue}
      disabled={disabled}
      type="text"
      onChange={(e) => onChange?.(e.target.value)}
      placeholder={placeholder}
      value={value}
    />
  );
}

function Row({ children }: { children: ReactNode }) {
  return (
    <div className="flex items-center justify-between gap-1 @container">
      {children}
    </div>
  );
}

export const ScaleLabels = {
  TextField,
  Row,
};
