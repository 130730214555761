import type {
  ChallengeState,
  ClaimInsightsFilterType,
} from '@assembly-web/services';
import { twJoin } from 'tailwind-merge';

const columnMap = {
  PENDING: 4,
  APPROVED: 3,
  UNCLAIMED: 2,
} satisfies Record<ClaimInsightsFilterType, number>;

export const TableLoader = ({
  filterType,
  challengeStatus,
}: {
  filterType: ClaimInsightsFilterType;
  challengeStatus: ChallengeState;
}) => {
  const columnCount = columnMap[filterType];
  return (
    <div className="h-full w-full max-w-lg rounded-md p-4">
      <div className="flex animate-pulse">
        <div className="flex-1 space-y-4">
          {[...Array(challengeStatus === 'CLOSED' ? 5 : 6)].map((row, i) => {
            return (
              <div key={i} className="flex items-center gap-4">
                {[...Array(columnCount)].map((col, index) => {
                  return (
                    <div
                      key={index}
                      className={twJoin(
                        index === 0
                          ? 'flex w-full items-center gap-3'
                          : 'bg-slate-200 h-2 w-[120px] rounded'
                      )}
                    >
                      {index === 0 && (
                        <div className="bg-slate-200 h-6 w-6 rounded-full bg-gray-4"></div>
                      )}
                      <div
                        className={twJoin(
                          'bg-slate-200 h-2 rounded bg-gray-4',
                          filterType === 'UNCLAIMED' && index === 1
                            ? 'float-right w-[32px]'
                            : 'w-full'
                        )}
                      ></div>
                    </div>
                  );
                })}
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};
