import { MagnifyingGlassIcon } from '@heroicons/react/20/solid';
import { FunnelIcon } from '@heroicons/react/24/outline';
import { type ChangeEvent, useState } from 'react';
import { useIntl } from 'react-intl';
import AutoSizer from 'react-virtualized-auto-sizer';
import { FixedSizeList } from 'react-window';
import InfiniteLoader from 'react-window-infinite-loader';

import { Avatar, AvatarSize } from '../../../../DesignSystem/Feedback/Avatar';
import { Modal } from '../../../../DesignSystem/Feedback/Modal';
import { TextStyle } from '../../../../DesignSystem/Feedback/TextStyle';
import { DropdownMenu } from '../../../../DesignSystem/Inputs/DropdownMenu';
import { TextField } from '../../../../DesignSystem/Inputs/TextField';
import { StatBox } from '../StatBox';
import { Loader } from './Loader';
import { messages } from './messages';

export type FilterType = 'seen' | 'unseen';

export type Viewer = {
  name: string;
  memberID: string;
  imageUrl?: string;
  cannotViewAnymore: boolean;
};

export type AnnouncementInsightsModalProps = {
  isOpen: boolean;
  viewers: Viewer[];
  totalViews: number | null;
  isLoading: boolean;
  onClose: () => void;
  hasNextPage?: boolean;
  uniqueViewers: number | null;
  onLoadMore: () => void;
  isFetchingNextPage?: boolean;
  onSearchChange: (props: {
    searchValue: string;
    filterType: FilterType;
  }) => void;
};

export function AnnouncementInsightsModal({
  isOpen,
  viewers,
  onClose,
  isLoading,
  onLoadMore,
  totalViews,
  hasNextPage,
  uniqueViewers,
  onSearchChange,
  isFetchingNextPage,
}: AnnouncementInsightsModalProps) {
  const [searchValue, setSearchValue] = useState('');
  const [filterType, setFilterType] = useState<FilterType>('seen');
  const { formatMessage } = useIntl();

  const filterOptions = [
    {
      value: 'seen',
      label: formatMessage(messages.seen),
    },
    {
      value: 'unseen',
      label: formatMessage(messages.unseen),
    },
  ];

  const itemCount = hasNextPage ? viewers.length + 1 : viewers.length;

  const getEmptyStateText = () => {
    if (searchValue) {
      return formatMessage(messages.noResultsFound);
    }
    return filterType === 'seen'
      ? formatMessage(messages.seenFilterNoResultsFound)
      : formatMessage(messages.unseenFilterNoResultsFound);
  };

  const isItemLoaded = (index: number) => index < viewers.length;

  const Viewers = ({ index }: { index: number }) => {
    if (index >= viewers.length) {
      return null;
    }

    const viewer = viewers[index];

    return (
      <div>
        <div className="flex items-center justify-between gap-2 px-5 py-2">
          <div className="flex items-center gap-2">
            <Avatar
              name={viewer.name}
              size={AvatarSize.Small}
              image={viewer.imageUrl}
              memberID={viewer.memberID}
            />
            <TextStyle variant="sm-regular" className="text-gray-9">
              {viewer.name}
            </TextStyle>
            {Boolean(viewer.cannotViewAnymore) && (
              <TextStyle variant="xs-regular" className="text-gray-8">
                {formatMessage(messages.cannotViewAnymore)}
              </TextStyle>
            )}
          </div>
        </div>
      </div>
    );
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={() => {
        onClose();
        setSearchValue('');
        setFilterType('seen');
      }}
      className="flex h-[85vh] max-h-[604px] flex-col"
      bodyClassName="flex flex-col gap-4 flex-1"
      title={formatMessage(messages.announcementInsightsModalTitle)}
    >
      <section className="flex flex-col gap-4">
        <TextStyle variant="sm-regular" className="text-gray-9">
          {formatMessage(messages.announcementInsightsModalDescription)}
        </TextStyle>
        <div className="flex">
          <StatBox
            value={Number(uniqueViewers)}
            isLoading={uniqueViewers === null && isLoading}
            title={formatMessage(messages.uniqueViewers)}
            tooltipText={formatMessage(messages.uniqueViewersTooltipText)}
          />
          <StatBox
            className="ml-3"
            value={Number(totalViews)}
            isLoading={totalViews === null && isLoading}
            title={formatMessage(messages.totalViews)}
            tooltipText={formatMessage(messages.totalViewersTooltipText)}
          />
        </div>
      </section>

      <section className="flex flex-1 flex-col rounded-lg border border-gray-5">
        <div className="grid grid-cols-[1fr_auto] items-center gap-1 px-2 py-1">
          <TextField
            type="text"
            value={searchValue}
            onChange={(e: ChangeEvent<HTMLInputElement>) => {
              setSearchValue(e.target.value);
              onSearchChange({
                searchValue: e.target.value,
                filterType: filterType,
              });
            }}
            invalidText=""
            isInvalid={false}
            placeholder={formatMessage(messages.searchLabel)}
            connectedLeft={
              <MagnifyingGlassIcon className="pointer-events-none h-4 w-4 text-gray-9" />
            }
          />
          <DropdownMenu
            variant="bordered"
            value={filterType}
            options={filterOptions}
            contentClassName="w-32"
            connectedLeft={<FunnelIcon className="h-4 w-4 text-gray-9" />}
            onSelected={(selectedOption) => {
              setFilterType(
                (selectedOption.value as string) === 'seen' ? 'seen' : 'unseen'
              );
              onSearchChange({
                searchValue,
                filterType:
                  (selectedOption.value as string) === 'seen'
                    ? 'seen'
                    : 'unseen',
              });
            }}
            disabled={isLoading}
            isPortalDisabled
          />
        </div>
        <section className="flex-1">
          <AutoSizer>
            {({ height, width }) => {
              return (
                <InfiniteLoader
                  itemCount={itemCount}
                  loadMoreItems={onLoadMore}
                  isItemLoaded={isItemLoaded}
                >
                  {({ onItemsRendered, ref }) => (
                    <FixedSizeList
                      ref={ref}
                      width={width}
                      itemSize={40}
                      height={height}
                      itemCount={itemCount}
                      onItemsRendered={onItemsRendered}
                    >
                      {({ index, style }) => (
                        <div style={style}>
                          <Viewers index={index} />
                          {index === viewers.length - 1 &&
                            Boolean(isFetchingNextPage) && <Loader />}
                        </div>
                      )}
                    </FixedSizeList>
                  )}
                </InfiniteLoader>
              );
            }}
          </AutoSizer>
          {Boolean(isLoading) && itemCount === 0 && <Loader />}
          {itemCount === 0 && !isLoading && (
            <TextStyle className="px-5 py-2 text-gray-8" variant="sm-regular">
              {getEmptyStateText()}
            </TextStyle>
          )}
        </section>
      </section>
    </Modal>
  );
}
