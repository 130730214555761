import { CheckIcon, ChevronDownIcon } from '@heroicons/react/24/outline';
import {
  Content,
  Item,
  ItemIndicator,
  Portal,
  Root,
  Trigger,
} from '@radix-ui/react-select';
import type React from 'react';
import { useState } from 'react';
import { defineMessages, useIntl } from 'react-intl';
import { twMerge } from 'tailwind-merge';

import { Button, TextStyle } from '../..';

const messages = defineMessages({
  clear: {
    id: '/GCoTA',
    defaultMessage: 'Clear',
  },
});

type SelectOption<T> = {
  id: string;
  value: string;
  [key: string]: T | string;
};

export type SelectDropdownProps = {
  label: string;
  value: string;
  showClearButton?: boolean;
  options: SelectOption<number>[];
  onSelect: (value: string) => void;
  renderOption: (option: SelectOption<number>) => React.ReactNode;
};

const SelectDropdownContent = ({ children }: { children: React.ReactNode }) => {
  return children;
};

SelectDropdownContent.displayName = 'SelectDropdownContent';

SelectDropdown.Content = SelectDropdownContent;

const SelectDropdownFooter = ({ children }: { children: React.ReactNode }) => {
  return children;
};

SelectDropdownFooter.displayName = 'SelectDropdownFooter';

SelectDropdown.Footer = SelectDropdownFooter;

export function SelectDropdown({
  label,
  value,
  options,
  onSelect,
  renderOption,
  showClearButton,
}: SelectDropdownProps) {
  const { formatMessage } = useIntl();
  const [open, setOpen] = useState<boolean>(false);
  return (
    <Root
      value={value}
      onValueChange={(option) => onSelect(option)}
      open={open}
      onOpenChange={setOpen}
    >
      <Trigger className="group focus-visible:rounded-lg" aria-label="trigger">
        <div
          className={twMerge(
            'flex max-w-[280px] items-center gap-2 rounded-lg border border-gray-5 bg-gray-1 px-2 py-1 align-middle hover:bg-gray-3 focus:bg-gray-3',
            value && 'bg-gray-3 hover:bg-gray-4 focus:bg-gray-4'
          )}
        >
          <TextStyle
            as="p"
            variant={value ? 'sm-medium' : 'sm-regular'}
            className="truncate text-gray-9"
          >
            {value ? `${label}: ${value}` : label}
          </TextStyle>
          <ChevronDownIcon
            color="gray-9"
            className="h-4 w-4 text-gray-9 group-aria-expanded:rotate-180"
          />
        </div>
      </Trigger>
      <Portal>
        <Content
          align="start"
          sideOffset={4}
          className="rounded-lg border border-gray-4 bg-gray-1 p-0 shadow-lg-down"
          position="popper"
        >
          {options.map((option) => {
            return (
              <Item
                className={twMerge(
                  'flex h-[40px] min-w-[222px] cursor-pointer items-center justify-between gap-2 pl-4 pr-3 outline-none hover:bg-gray-3 focus:bg-gray-3'
                )}
                key={option.id}
                value={option.value}
              >
                <TextStyle className="flex w-full items-center gap-1 truncate align-middle text-gray-8">
                  {renderOption(option)}
                </TextStyle>
                <ItemIndicator className="absolute right-2 inline-flex w-[25px] items-center justify-center">
                  <CheckIcon
                    className="h-4 w-4 text-primary-6"
                    aria-hidden="true"
                  />
                </ItemIndicator>
              </Item>
            );
          })}
          {showClearButton ? (
            <Button
              size="small"
              variation="secondaryLite"
              disabled={value === ''}
              className={twMerge(
                'm-2 grid grid-cols-[1fr_auto] justify-between gap-2 rounded-b-lg bg-gray-1'
              )}
              onClick={() => {
                onSelect('');
                setOpen(false);
              }}
            >
              {formatMessage(messages.clear)}
            </Button>
          ) : null}
        </Content>
      </Portal>
    </Root>
  );
}
