import { Indicator, Root as ProgressRoot } from '@radix-ui/react-progress';
import { Trigger } from '@radix-ui/react-tabs';
import { cva } from 'class-variance-authority';
import { useIntl } from 'react-intl';
import { twMerge } from 'tailwind-merge';

import { TextStyle } from '../../../../DesignSystem/Feedback/TextStyle';
import { messages } from './message';

type TabItemProps = {
  step: number;
  label: string;
  value: string;
  progress: number;
  visited: boolean;
  isActive: boolean;
  disabled?: boolean;
};

const indicatorStyles = cva('h-full duration-300 ease-in-out bg-gray-4', {
  variants: {
    disabled: {
      true: 'bg-gray-7',
    },
    visited: {
      true: 'bg-primary-6',
    },
  },
});

const textStyles = cva('', {
  variants: {
    disabled: {
      true: 'text-gray-7',
      false: 'text-gray-9',
    },
    visited: {
      true: 'text-primary-6',
    },
  },
});

export function TabItem({
  step,
  value,
  label,
  visited,
  progress,
  isActive,
  disabled = false,
}: TabItemProps) {
  const { formatMessage } = useIntl();
  return (
    <Trigger
      value={value}
      disabled={disabled}
      className={twMerge(disabled && 'cursor-not-allowed', 'flex-1')}
    >
      <div className="flex h-full flex-col items-start">
        <ProgressRoot
          value={progress}
          className={twMerge(
            'mb-2 h-1 w-full overflow-hidden rounded-full bg-primary-2',
            isActive && 'ring-2 ring-primary-6 ring-offset-2'
          )}
        >
          <Indicator
            className={indicatorStyles({
              visited,
              disabled,
            })}
          />
        </ProgressRoot>
        <div className="flex flex-col items-start">
          <TextStyle
            variant="xs-medium"
            className={textStyles({
              visited,
              disabled,
            })}
          >
            {formatMessage(messages.stepLabel, { step })}
          </TextStyle>
          <TextStyle
            variant="sm-medium"
            className={twMerge(
              'text-left',
              disabled ? 'text-gray-7' : 'text-gray-9'
            )}
          >
            {label}
          </TextStyle>
        </div>
      </div>
    </Trigger>
  );
}
