import {
  ActivityCardTypeEnum,
  type ActivityEntity,
  type ChallengeLaunchActivityType,
} from '@assembly-web/services';
import { defineMessages, useIntl } from 'react-intl';

import type { ToolbarItem } from '../../..';
import { Button } from '../../..';
import { trophyIcon } from '../../assets/icons';
import { ActivityCard } from './ActivityCard';

const messages = defineMessages({
  viewAllChallenge: {
    defaultMessage: 'View all challenges',
    id: 'oQ57Pl',
  },
  browseChallenge: {
    defaultMessage: 'Browse challenges',
    id: 'C5/wQT',
  },
});

type ToolbarMenuClickEventArgs = ToolbarItem & {
  url?: string;
  cardId: string;
  card: ActivityEntity | null;
  cardPosition: number;
  directReport?: boolean;
};

type ChallengeCarouselActivityWrapperProps = {
  titleText: string;
  url?: string;
  cardId: string;
  cardPosition: number;
  onViewAllChallengeClick: (e: React.MouseEvent) => void;
  onClick?: (cardId: string) => void;
  onToolbarItemClick?: (args: ToolbarMenuClickEventArgs) => void;
  primaryToolbarItems?: ToolbarItem[] | undefined;
  secondaryToolbarItems?: ToolbarItem[] | undefined;
  cardWrapper?: React.RefObject<HTMLAnchorElement>;
  preview?: React.ReactNode;
  count: number;
} & Omit<ChallengeLaunchActivityType, 'type'>;

export function ChallengeCarouselActivityWrapper({
  titleText,
  entity,
  cardId,
  url,
  onClick,
  preview,
  cardWrapper,
  cardPosition,
  onViewAllChallengeClick,
  onToolbarItemClick,
  primaryToolbarItems,
  secondaryToolbarItems,
  count,
}: ChallengeCarouselActivityWrapperProps) {
  const { formatMessage } = useIntl();

  return (
    <ActivityCard
      wrapperClassName="flex flex-col align-middle"
      icon={
        <img
          src={trophyIcon}
          alt="trophyIcon"
          className="h-6 w-6 rounded-lg border-upgrade-2 bg-upgrade-2 p-1"
        />
      }
      text={titleText}
      onToolbarItemClick={(args) => {
        onToolbarItemClick?.({
          ...args,
          cardId,
          card: { type: ActivityCardTypeEnum.ChallengeLaunchActivity, entity },
          cardPosition,
        });
      }}
      onClick={() => onClick?.(cardId)}
      url={url}
      connectedRight={
        <Button
          size="small"
          variation="secondaryLite"
          onClick={onViewAllChallengeClick}
        >
          {formatMessage(
            count >= 2 ? messages.viewAllChallenge : messages.browseChallenge
          )}
        </Button>
      }
      primaryToolbarItems={primaryToolbarItems}
      secondaryToolbarItems={secondaryToolbarItems}
      wrapperRef={cardWrapper}
      preview={preview}
    />
  );
}
