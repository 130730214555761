import 'dayjs/locale/en';

import dayjs from 'dayjs';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import durationPlugin from 'dayjs/plugin/duration';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import relativeTimePlugin from 'dayjs/plugin/relativeTime';
import timezone from 'dayjs/plugin/timezone';
import utcPlugin from 'dayjs/plugin/utc';

dayjs.extend(timezone);
dayjs.extend(advancedFormat);
dayjs.extend(utcPlugin);
dayjs.extend(durationPlugin);
dayjs.extend(relativeTimePlugin);
dayjs.extend(localizedFormat);

export function getRemainingTime(utcDueDate: Date): string {
  const dueDate = dayjs(dayjs.utc(utcDueDate).local());
  const diffDuration = dayjs.duration(dueDate.diff(dayjs()));

  const diffAsMinutes = diffDuration.asMinutes();
  const diffAsHours = diffDuration.asHours();
  const diffAsDays = diffDuration.asDays();

  const diffMinutes = diffDuration.minutes();
  const diffHours = diffDuration.hours();
  const diffDays = diffDuration.days();
  const diffWeeks = diffDuration.weeks();

  const remainingDiffDays = diffDays % 7;

  if (diffAsMinutes < 1) {
    return `<1min`;
  }

  if (diffAsMinutes >= 1 && diffAsHours < 1) {
    return `${diffMinutes}min`;
  }

  if (diffAsHours >= 1 && diffAsDays < 1) {
    return diffMinutes === 0
      ? `${diffHours}h`
      : `${diffHours}h ${diffMinutes}min`;
  }

  if (diffAsDays >= 1 && diffAsDays < 7) {
    return diffHours === 0 ? `${diffDays}d` : `${diffDays}d ${diffHours}h`;
  }

  return remainingDiffDays === 0
    ? `${diffWeeks}w`
    : `${diffWeeks}w ${remainingDiffDays}d`;
}

export function formatDate(date: Date | string | null, locale = 'en') {
  const dateToFormat = date ?? new Date();
  return dayjs(dateToFormat).locale(locale).format('L [at] LT');
}

/** Used to display timestamps in actual local time in  tooltips across UIs */
export function formatToLocalDateTime(
  dateToFormat: Date | string,
  timeZone?: string
) {
  const defaultTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

  const date = dayjs(dateToFormat).tz(timeZone ?? defaultTimezone);
  return date.format(`MMMM Do, YYYY [at] h:mma`);
}

export const reduceMinutesFromDeadline = (deadlineInMinutes: number) =>
  deadlineInMinutes - 5;
