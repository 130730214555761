import { twMerge } from 'tailwind-merge';

import { TextStyle } from '../../../../DesignSystem/Feedback/TextStyle';
import { ToggleSwitch } from '../../../../DesignSystem/Inputs/ToggleSwitch';

type PostInteractionSettingItemProps = {
  label: string;
  isChecked: boolean;
  disabled: boolean;
  className?: string;
  onCheckedChange: (checked: boolean) => void;
  tooltipText?: string;
};

export function PostInteractionSettingItem({
  label,
  disabled,
  className,
  isChecked,
  onCheckedChange,
  tooltipText,
}: PostInteractionSettingItemProps) {
  return (
    <div
      className={twMerge(
        'flex gap-2 rounded-lg border border-solid border-gray-5 px-3 py-2',
        className
      )}
    >
      <TextStyle variant="sm-medium" className="flex-1">
        {label}
      </TextStyle>
      <ToggleSwitch
        aria-label={label}
        checked={isChecked}
        disabled={disabled}
        onCheckedChange={onCheckedChange}
        tooltipText={tooltipText}
      />
    </div>
  );
}
