import 'emoji-mart/css/emoji-mart.css';
import './styles.css';

import type { BaseEmoji } from 'emoji-mart';
import { Picker } from 'emoji-mart';
import type React from 'react';
import { useCallback, useState } from 'react';
import { defineMessages, useIntl } from 'react-intl';
import { twMerge } from 'tailwind-merge';

import { Tooltip } from '../../../DesignSystem/Feedback/Tooltip';
import { Popover } from '../Popover';

export type ReactionPickerProps = {
  disabled?: boolean;
  contentType?: string;
  triggerButton: React.ReactNode;
  onReactionClick: (reaction: BaseEmoji) => void;
  showDisabledTooltip?: boolean;
};

const messages = defineMessages({
  maxReactionLimitReached: {
    defaultMessage:
      'This {contentType} has reached its reaction limit. You’ve got plenty to choose from!',
    id: 'LUQVLo',
  },
});

function TriggerButton({
  disabled,
  triggerButton,
  contentType,
  showTooltip = true,
}: {
  disabled: boolean;
  contentType?: string;
  triggerButton: React.ReactNode;
  showTooltip?: boolean;
}) {
  const { formatMessage } = useIntl();
  if (disabled && showTooltip) {
    return (
      <Tooltip
        key="disabled-reaction-button"
        tooltipText={formatMessage(messages.maxReactionLimitReached, {
          contentType,
        })}
      >
        {triggerButton}
      </Tooltip>
    );
  }
  return triggerButton;
}

export function ReactionPicker(props: ReactionPickerProps) {
  const {
    triggerButton,
    onReactionClick,
    disabled = false,
    contentType,
    showDisabledTooltip,
  } = props;
  const [showEmojiPicker, setShowEmojiPicker] = useState(false);

  const toggleEmojiPicker = () => {
    setShowEmojiPicker(!showEmojiPicker);
  };

  const onEmojiSelect = useCallback(
    (emoji: BaseEmoji) => {
      onReactionClick(emoji);
      setShowEmojiPicker(false);
    },
    [onReactionClick]
  );

  return (
    <Popover
      onTriggerButtonClick={toggleEmojiPicker}
      trigger={
        <TriggerButton
          triggerButton={triggerButton}
          disabled={disabled}
          contentType={contentType}
          showTooltip={showDisabledTooltip}
        />
      }
      open={showEmojiPicker}
      onOpenChange={setShowEmojiPicker}
      aria-label="Reaction Picker"
      disabled={disabled}
      className={twMerge(disabled && 'cursor-not-allowed bg-gray-2')}
    >
      <Picker
        onClick={onEmojiSelect}
        native
        sheetSize={16}
        set="apple"
        title="Pick your emoji"
        emoji="point_up"
      />
    </Popover>
  );
}
