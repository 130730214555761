import { MemberState } from '@assembly-web/services';
import {
  ArchiveBoxXMarkIcon,
  BriefcaseIcon,
  IdentificationIcon,
} from '@heroicons/react/24/outline';
import type { ReactNode } from 'react';
import { defineMessages, useIntl } from 'react-intl';

import { Avatar, AvatarSize } from '../../../DesignSystem/Feedback/Avatar';
import { TextStyle } from '../../../DesignSystem/Feedback/TextStyle';
import { HorizontalRule } from '../../../DesignSystem/Layout/HorizontalRule';

export type ProfileCardContentProps = {
  image?: string;
  firstName: string;
  lastName: string;
  department?: string;
  email?: string;
  pronouns?: string;
  jobTitle?: string;
  memberID: string;
  memberState?: MemberState;
};

const messages = defineMessages({
  userDeactivated: {
    defaultMessage:
      'This user has been deactivated and is no longer in the platform.',
    id: '3WT/dP',
  },
});

const ProfileCard = ({ children }: { children: ReactNode }) => {
  return <>{children}</>;
};

const Content = ({
  lastName,
  image,
  department,
  pronouns,
  email,
  jobTitle,
  firstName,
  memberID,
  memberState,
}: ProfileCardContentProps) => {
  const { formatMessage } = useIntl();
  return (
    <div className="flex flex-col gap-2">
      <div className="flex items-center gap-4 px-2">
        <Avatar
          image={image}
          name={firstName}
          memberID={memberID}
          size={AvatarSize.Medium}
        />
        <div className="flex flex-col overflow-hidden">
          <p className="flex items-center gap-1">
            <span className="text-md min-w-0 truncate font-medium text-gray-9">
              {firstName} {lastName}
            </span>
            {Boolean(pronouns) && (
              <span className="flex-shrink-0 text-xs text-gray-8">
                ({pronouns})
              </span>
            )}
          </p>
          <p className="truncate text-sm text-gray-8">{email}</p>
        </div>
      </div>
      <HorizontalRule />
      {memberState === MemberState.Deactivated ? (
        <div className="flex gap-2 rounded-md bg-gray-3 px-2 py-1">
          <ArchiveBoxXMarkIcon width={18} height={18} />
          <TextStyle
            variant="xs-regular"
            className="whitespace-normal break-words"
          >
            {formatMessage(messages.userDeactivated)}
          </TextStyle>
        </div>
      ) : (
        <div className="flex flex-col gap-1 px-2">
          {Boolean(jobTitle) && (
            <p className="flex items-center gap-1 text-xs text-gray-9">
              <IdentificationIcon width={16} height={16} />
              {jobTitle}
            </p>
          )}
          {Boolean(department) && (
            <p className="flex items-center gap-1 text-xs text-gray-9">
              <BriefcaseIcon width={16} height={16} />
              {department}
            </p>
          )}
        </div>
      )}
    </div>
  );
};

const Footer = ({ children }: { children: ReactNode }) => {
  return <footer>{children}</footer>;
};

ProfileCard.Content = Content;
ProfileCard.Footer = Footer;

// eslint-disable-next-line import-x/no-default-export
export default ProfileCard;
