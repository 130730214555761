import { OnChangePlugin } from '@lexical/react/LexicalOnChangePlugin';
import { $getRoot, type EditorState, type LexicalEditor } from 'lexical';
import { useCallback } from 'react';

import type { RepliesValidatorError } from '../../RepliesEditor/plugins/RepliesFooterPlugin';
import { $getAllGifsUrls } from '../utils';

export type EditorStateChangeArgs = {
  plainText: string;
  editorState: string;
  errors: RepliesValidatorError[];
  gifUrls: string[];
};

type AutoSavePluginProps = {
  onChange: (args: EditorStateChangeArgs) => void;
  validator: (text: string) => RepliesValidatorError[];
};

export function AutoSavePlugin({ onChange, validator }: AutoSavePluginProps) {
  const handleOnChange = useCallback(
    (editorState: EditorState, editor: LexicalEditor) => {
      editorState.read(() => {
        const stringifiedEditorState = JSON.stringify(editorState.toJSON());
        const parsedEditorState = editor.parseEditorState(
          stringifiedEditorState
        );
        const editorStateTextString = parsedEditorState.read(() =>
          $getRoot().getTextContent()
        );
        const editorStateGifUrls = $getAllGifsUrls();
        const errors = validator(editorStateTextString);

        if (
          editorStateTextString.length === 0 &&
          editorStateGifUrls.length === 0
        ) {
          onChange({
            plainText: '',
            editorState: '',
            errors,
            gifUrls: [],
          });
        } else {
          onChange({
            plainText: editorStateTextString,
            editorState: stringifiedEditorState,
            gifUrls: editorStateGifUrls,
            errors,
          });
        }
      });
    },
    [onChange, validator]
  );

  return (
    <OnChangePlugin
      onChange={handleOnChange}
      ignoreSelectionChange={true}
      ignoreHistoryMergeTagChange={true}
    />
  );
}
