const backgroundMap = {
  dark: 'bg-gray-8/40',
  light: 'bg-gray-8/60',
};

export const Shortcut = ({
  children,
  variant,
}: {
  children: string;
  variant: 'dark' | 'light';
}) => {
  return (
    <>
      {children.split(' + ').map((el, idx) => (
        <span
          key={idx}
          className={`flex-shrink-0 rounded px-1 py-0.5 text-gray-1 ${backgroundMap[variant]}`}
        >
          {el}
        </span>
      ))}
    </>
  );
};
