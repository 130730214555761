import type { Channel } from 'pusher-js';
import { useEffect } from 'react';

/**
 * A React hook that subscribes to a specific event on a Pusher channel and invokes a callback when the event is triggered.
 *
 * @template T - The type of the data expected to be received from the Pusher event.
 *
 * @param {Channel | undefined} channel - The Pusher channel to bind or unbind the event to. If undefined, the hook will do nothing.
 * @param {string} eventName - The name of the event to subscribe to on the given channel.
 * @param {(data?: T, metaData?: unknown) => void} callback - The callback function to be invoked when the specified event occurs on the channel. The callback receives the event data as its first argument, and additional metadata (if any) as its second argument.
 *
 * @example
 * usePusherEvent(channel, 'my-event', (data) => {
 *   console.log(data);
 * });
 */
export function usePusherEvent<T>(
  channel: Channel | undefined,
  eventName: string,
  callback: (data?: T, metaData?: unknown) => void
) {
  useEffect(() => {
    channel?.bind(eventName, callback);
    return () => {
      channel?.unbind(eventName, callback);
    };
  }, [channel, eventName, callback]);
}
