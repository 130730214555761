import { flexRender, type Table as TanstackTable } from '@tanstack/react-table';
import { type CSSProperties, type ReactNode, useRef } from 'react';
import { useCallback } from 'react';
import { defineMessages, useIntl } from 'react-intl';
import AutoSizer from 'react-virtualized-auto-sizer';
import { FixedSizeList as List } from 'react-window';
import InfiniteLoader from 'react-window-infinite-loader';
import { twMerge } from 'tailwind-merge';

import { EmptySearchState } from '../../UI/Web/EmptyStates';
import { LoadingSpinner } from '../Feedback/Icons/LoadingSpinner';
import { TextStyle } from '../Feedback/TextStyle';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from '../Table/Table';

export type DataTableProps<TData> = {
  table: TanstackTable<TData>;
  isFetchingNextPage?: boolean;
  hasNextPage?: boolean;
  onLoadMore: (startIndex: number, endIndex: number) => void | Promise<void>;
  loader?: ReactNode;
  isLoading?: boolean;
  tableHeaderRowClassName?: string;
  tableRowClassName?: string;
  tableCellClassName?: string;
  tableId?: string;
  hideTableHeader?: boolean;
  variant: 'default' | 'custom';
  emptyStateText?: string;
  itemSize: number;
  height?: string;
  tableHeight?: string;
};

const messages = defineMessages({
  noResultsTitle: {
    defaultMessage: 'No matches found',
    id: 'KeJh7y',
  },
  noResultsSubText: {
    defaultMessage:
      'Try adjusting your filters to find what you’re looking for.',
    id: 'sDPcQe',
  },
});

export function DataTable<TData>(props: DataTableProps<TData>) {
  const { formatMessage } = useIntl();
  const {
    table,
    isFetchingNextPage,
    hasNextPage,
    onLoadMore,
    loader = <LoadingSpinner />,
    tableHeaderRowClassName,
    tableRowClassName,
    tableCellClassName,
    tableId,
    hideTableHeader,
    variant = 'default',
    emptyStateText,
    itemSize,
    height,
    tableHeight = '16rem',
  } = props;

  const itemCount = hasNextPage
    ? table.getFilteredRowModel().rows.length + 1
    : table.getFilteredRowModel().rows.length;

  const isItemLoaded = useCallback(
    (index: number) => !hasNextPage || index < table.getRowModel().rows.length,
    [hasNextPage, table]
  );

  const tableBodyHeight = itemCount < 5 ? itemCount * itemSize : tableHeight;

  const loadMoreItems = isFetchingNextPage ? () => {} : onLoadMore;
  const tableBodyRef = useRef<HTMLTableElement>(null);

  const renderRow = useCallback(
    ({ index, style }: { index: number; style: CSSProperties }) => {
      if (index >= table.getRowModel().rows.length) {
        return null;
      }
      if (!isItemLoaded(index)) {
        return (
          <div style={style} className="flex items-center justify-center">
            <TableRow>
              <TableCell colSpan={table.getAllColumns().length}>
                {loader}
              </TableCell>
            </TableRow>
          </div>
        );
      }
      const row = table.getRowModel().rows[index];
      return (
        <TableRow
          variant={variant}
          key={row.id}
          style={{
            ...style,
          }}
          className={twMerge(tableRowClassName)}
          data-state={row.getIsSelected() && 'selected'}
        >
          {row.getVisibleCells().map((cell) => {
            return (
              <TableCell
                key={cell.id}
                className={twMerge(tableCellClassName)}
                style={{
                  width: cell.column.getSize() || 'auto',
                }}
              >
                {flexRender(cell.column.columnDef.cell, cell.getContext())}
              </TableCell>
            );
          })}
        </TableRow>
      );
    },
    [
      isItemLoaded,
      loader,
      table,
      tableCellClassName,
      tableRowClassName,
      variant,
    ]
  );

  return (
    <Table
      variant={variant}
      style={{
        width: table.getCenterTotalSize(),
        boxSizing: 'border-box',
        overflow: 'hidden',
        height,
      }}
      ref={tableBodyRef}
    >
      {!hideTableHeader && (
        <TableHeader variant={variant}>
          {table.getHeaderGroups().map((headerGroup) => (
            <TableRow
              key={headerGroup.id}
              variant={variant}
              className={twMerge(
                tableHeaderRowClassName,
                variant === 'custom' && 'bg-gray-4 [&>tr]:hover:bg-gray-4'
              )}
            >
              {headerGroup.headers.map((header) => {
                return (
                  <TableHead
                    key={header.id}
                    style={{
                      width: header.getSize() || 'auto',
                    }}
                    variant={variant}
                  >
                    {header.isPlaceholder
                      ? null
                      : flexRender(
                          header.column.columnDef.header,
                          header.getContext()
                        )}
                  </TableHead>
                );
              })}
            </TableRow>
          ))}
        </TableHeader>
      )}
      <TableBody
        style={{ height: tableBodyHeight, overflow: 'scroll' }}
        key={tableId}
      >
        <AutoSizer>
          {({ height, width }) => (
            <InfiniteLoader
              isItemLoaded={isItemLoaded}
              loadMoreItems={loadMoreItems}
              itemCount={itemCount}
            >
              {({ onItemsRendered, ref }) => (
                <List
                  height={height}
                  itemCount={itemCount}
                  onItemsRendered={onItemsRendered}
                  ref={ref}
                  width={width}
                  itemSize={itemSize}
                >
                  {renderRow}
                </List>
              )}
            </InfiniteLoader>
          )}
        </AutoSizer>
      </TableBody>
      {!table.getRowModel().rows.length && (
        <TableRow>
          <TableCell
            colSpan={table.getAllColumns().length}
            className="text-center"
          >
            {emptyStateText ? (
              <TextStyle variant="sm-regular" className="text-left text-gray-8">
                {emptyStateText}
              </TextStyle>
            ) : (
              <EmptySearchState
                title={formatMessage(messages.noResultsTitle)}
                description={formatMessage(messages.noResultsSubText)}
                containerClassName={'flex-row justify-start border-0'}
                textBodyClassName={'text-left'}
              />
            )}
          </TableCell>
        </TableRow>
      )}
    </Table>
  );
}
