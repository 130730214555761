import {
  ChevronDoubleLeftIcon,
  ChevronDoubleRightIcon,
  ChevronDownIcon,
  ChevronUpIcon,
} from '@heroicons/react/24/solid';
import type { CalendarDate } from '@internationalized/date';
import {
  Button,
  Calendar,
  CalendarCell,
  CalendarGrid,
  CalendarGridBody,
  CalendarGridHeader,
  CalendarHeaderCell,
  DateInput,
  DatePicker as ReactAriaDatePicker,
  DateSegment,
  Dialog,
  Group,
  Heading,
  Popover,
  type PopoverProps,
} from 'react-aria-components';
import { twMerge } from 'tailwind-merge';

import { LoadingSpinner } from '../Feedback/Icons/LoadingSpinner';
import { TextStyle } from '../Feedback/TextStyle';

export type DatePickerProps = {
  /** Currently focused date within the calendar */
  focusedValue: CalendarDate;

  /** Whether the selected date should be marked as invalid */
  isInvalid?: boolean;

  /** Whether to disable the date picker and show a loading indicator */
  isLoading?: boolean;

  /** Whether the calendar popover is open */
  isOpen: boolean;

  /** Label to show above the input box */
  label: string;

  /** Upper bound (inclusive) that can be selected from the calendar */
  maxValue?: CalendarDate;

  /** Lower bound (inclusive) that can be selected from the calendar */
  minValue?: CalendarDate;

  /** Event handler that is called when the focused date within the calendar changes */
  onFocusedValueChange: (value: CalendarDate) => void;

  /** Event handler that is called when the open state of the calendar popover changes */
  onOpenChange: (isOpen: boolean) => void;

  /** Event handler that is called when the selected date value changes */
  onSelectedValueChange: (value: CalendarDate) => void;

  /** Placement of the calendar relative to the input box */
  popoverPlacement?: PopoverProps['placement'];

  /** The container element in which the portal is expected to be placed for the calendar popover */
  portalContainer?: Element;

  /** Current date value selected  */
  selectedValue: CalendarDate | null;
};

export function DatePicker({
  focusedValue,
  isInvalid,
  isLoading,
  isOpen,
  label,
  maxValue,
  minValue,
  onFocusedValueChange,
  onOpenChange,
  onSelectedValueChange,
  popoverPlacement,
  portalContainer,
  selectedValue,
}: DatePickerProps) {
  const TriggerIcon = isOpen ? ChevronUpIcon : ChevronDownIcon;

  return (
    <ReactAriaDatePicker
      aria-label={label}
      className="flex flex-col"
      isDisabled={isLoading}
      isOpen={isOpen}
      maxValue={maxValue}
      minValue={minValue}
      onChange={onSelectedValueChange}
      onOpenChange={onOpenChange}
      value={selectedValue}
    >
      <TextStyle
        as="span"
        className={twMerge('mb-1', isInvalid ? 'text-error-7' : 'text-gray-9')}
        variant="sm-medium"
      >
        {label}
      </TextStyle>
      <Group
        className={twMerge(
          'flex h-10 flex-row items-center gap-x-2 rounded-lg border border-gray-5 px-3',
          isOpen && 'border border-primary-6',
          isInvalid && 'border border-error-7'
        )}
      >
        <DateInput
          className={({ isDisabled }) =>
            twMerge(
              'flex flex-1 text-gray-8',
              isDisabled && 'cursor-not-allowed text-gray-7'
            )
          }
        >
          {(segment) => (
            <DateSegment
              className="text-sm font-normal text-gray-9"
              segment={segment}
            />
          )}
        </DateInput>
        {isLoading ? (
          <LoadingSpinner className="h-4 w-4" />
        ) : (
          <Button isDisabled={isLoading}>
            <TriggerIcon className="h-4 w-4 stroke-gray-9 text-gray-9" />
          </Button>
        )}
      </Group>
      <Popover
        UNSTABLE_portalContainer={portalContainer}
        placement={popoverPlacement}
      >
        <Dialog>
          <Calendar
            className="flex flex-1 flex-col rounded-lg border border-gray-5 bg-gray-1 px-3 py-2 shadow-base-down"
            focusedValue={focusedValue}
            onFocusChange={onFocusedValueChange}
          >
            <div className="flex items-center py-[5px]">
              <Button
                className={({ isDisabled }) =>
                  twMerge(isDisabled && 'invisible')
                }
                slot="previous"
              >
                <ChevronDoubleLeftIcon className="h-4 w-4 stroke-gray-9" />
              </Button>
              <Heading className="mx-2 flex w-full justify-center text-sm font-medium text-gray-9" />
              <Button
                className={({ isDisabled }) =>
                  twMerge(isDisabled && 'invisible')
                }
                slot="next"
              >
                <ChevronDoubleRightIcon className="h-4 w-4 stroke-gray-9" />
              </Button>
            </div>
            <CalendarGrid weekdayStyle="short">
              <CalendarGridHeader>
                {(day) => (
                  <CalendarHeaderCell className="p-2.5 text-gray-9">
                    <TextStyle as="span" variant="sm-medium">
                      {day}
                    </TextStyle>
                  </CalendarHeaderCell>
                )}
              </CalendarGridHeader>
              <CalendarGridBody>
                {(date) => (
                  <CalendarCell
                    date={date}
                    className={({ isDisabled, isOutsideMonth, isSelected }) =>
                      twMerge(
                        'flex items-center justify-center p-[9px] text-xs font-medium text-gray-9',
                        !isSelected &&
                          !isDisabled &&
                          'hover:rounded-lg hover:bg-gray-3',
                        isDisabled && 'cursor-not-allowed text-gray-7',
                        isOutsideMonth && 'invisible',
                        isSelected && 'rounded-lg bg-primary-6 text-gray-1',
                        isInvalid && isSelected && 'bg-error-7'
                      )
                    }
                  />
                )}
              </CalendarGridBody>
            </CalendarGrid>
          </Calendar>
        </Dialog>
      </Popover>
    </ReactAriaDatePicker>
  );
}
