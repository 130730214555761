import type { Timeout } from '@assembly-web/services';
import { useEffect, useState } from 'react';

import { RatingModal } from './RatingModal';
import { RatingSuccessModal } from './RatingSuccessModal';
import { SuccessModal } from './SuccessModal';

const MODAL_TYPES = {
  RATING: 'RATING',
  REVIEW: 'REVIEW',
  SUCCESS: 'SUCCESS',
} as const;
type ModalType = keyof typeof MODAL_TYPES;

export type G2ReviewModalProps = {
  username: string;
  setShowModalForG2Review: (value: boolean) => void;
  onRewardRatingClicked: (ratingValue: number) => void;
  onRewardRatingSkipped: () => void;
  onGoToG2Clicked: () => void;
};

export function G2ReviewModal({
  username,
  setShowModalForG2Review,
  onRewardRatingClicked,
  onRewardRatingSkipped,
  onGoToG2Clicked,
}: G2ReviewModalProps) {
  const [rating, setRating] = useState(0);
  const [modalType, setModalType] = useState<ModalType | null>(null);

  const handleRating = (value: number) => {
    setRating(value);
    onRewardRatingClicked(value);
    if (value === 5) {
      setModalType(MODAL_TYPES.REVIEW);
    } else {
      setModalType(MODAL_TYPES.SUCCESS);
    }
  };

  useEffect(() => {
    const timer: Timeout = setTimeout(() => {
      setModalType(MODAL_TYPES.RATING);
    }, 500);
    return () => clearTimeout(timer);
  }, []);

  useEffect(() => {
    let timer: Timeout;
    if (modalType === MODAL_TYPES.SUCCESS) {
      timer = setTimeout(() => {
        setModalType(null);
      }, 5000);
    }
    return () => clearTimeout(timer);
  }, [modalType]);

  return (
    <>
      <RatingModal
        isOpen={modalType === MODAL_TYPES.RATING}
        rating={rating}
        setRating={handleRating}
        onClose={() => {
          setModalType(null);
          if (!rating) {
            onRewardRatingSkipped();
          }
        }}
        name={username}
      />
      <RatingSuccessModal
        isOpen={modalType === MODAL_TYPES.REVIEW}
        onClose={(reminderValue: boolean) => {
          setShowModalForG2Review(!reminderValue);
          setModalType(null);
        }}
        navigateToG2Review={() => {
          window.open(
            'https://www.g2.com/products/assembly-2021-12-09/reviews/start?return_to=https%3A%2F%2Fwww.g2.com%2Fproducts%2Fassembly-2021-12-09%2Ftake_survey',
            '_blank',
            'noopener,noreferrer'
          );
          onGoToG2Clicked();
        }}
      />
      <SuccessModal
        isOpen={modalType === MODAL_TYPES.SUCCESS}
        onClose={() => setModalType(null)}
      />
    </>
  );
}
