import { TextStyle } from '../../../DesignSystem/Feedback/TextStyle';
import { ToggleSwitch } from '../../../DesignSystem/Inputs/ToggleSwitch';

export function InteractionSettingItem({
  text,
  checked,
  disabled,
  setChecked,
}: {
  text: string;
  checked: boolean;
  disabled: boolean;
  setChecked: (checked: boolean) => void;
}) {
  return (
    <div className="flex gap-2 rounded-lg border border-solid border-gray-5 px-3 py-2">
      <TextStyle variant="sm-medium" className="flex-1">
        {text}
      </TextStyle>
      <ToggleSwitch
        checked={checked}
        onCheckedChange={setChecked}
        aria-label={text}
        disabled={disabled}
      />
    </div>
  );
}
