import type { ReactNode } from 'react';

import { Button } from '../../../DesignSystem/Inputs/Button';
import { SetUpRewards } from '../../assets/images';

type FeatureNotEnabledAdminViewProps = {
  title: string;
  description: string | ReactNode;
  ctaText: string;
  onCtaClick: () => void;
  emptyStateImage?: string | ReactNode;
};
export const FeatureNotEnabledAdminView = (
  props: FeatureNotEnabledAdminViewProps
) => {
  const { title, description, ctaText, onCtaClick, emptyStateImage } = props;
  return (
    <div
      className="flex w-full flex-col items-center justify-center rounded-md border-2 border-dashed border-gray-5 bg-gray-1 bg-opacity-50 p-8"
      data-testid="feature-not-enabled-container"
    >
      {typeof emptyStateImage === 'string' ||
      typeof emptyStateImage == 'undefined' ? (
        <img
          src={emptyStateImage ?? SetUpRewards}
          className="mb-4 h-[200px]"
          alt="Feature not enabled"
        />
      ) : (
        emptyStateImage
      )}
      <h3 className="mb-1 text-center font-medium text-gray-9">{title}</h3>
      <p className="mb-4 w-full max-w-[768px] text-center text-sm text-gray-8">
        {description}
      </p>
      <Button onClick={onCtaClick} data-testid="feature-not-enabled-cta">
        {ctaText}
      </Button>
    </div>
  );
};
