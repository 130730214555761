import { mapHexCodeToEmoticon } from '@assembly-web/services';
import * as DropdownMenu from '@radix-ui/react-dropdown-menu';
import type { ReactNode } from 'react';
import { defineMessages, useIntl } from 'react-intl';
import { twMerge } from 'tailwind-merge';

import { TextStyle } from '../../../DesignSystem/Feedback/TextStyle';
import { classNames } from '../../../DesignSystem/Utils/classNames';

type Flow = {
  id: string;
  name: string;
  icon: { kind: string; value: string };
};

type FlowsToDoMenuProps = {
  alignOffset?: number;
  answerNowFlows: Flow[];
  children: ReactNode;
  answerAnytimeFlows: Flow[];
  onMenuItemClick: (flowId: string, flowName: string) => void;
  defaultOpen?: boolean;
  handleOnClose?: () => void;
  fullWidth?: boolean;
  onOpenChange?: (isOpen: boolean) => void;
};

const messages = defineMessages({
  answerNow: {
    defaultMessage: 'Answer now',
    id: 'Bpz0xZ',
  },
  answerAnytime: {
    defaultMessage: 'Answer anytime',
    id: '4euen7',
  },
});

export function FlowsToDoMenu(props: FlowsToDoMenuProps) {
  const {
    alignOffset = 0,
    answerAnytimeFlows,
    answerNowFlows,
    onMenuItemClick,
    children,
    defaultOpen = false,
    handleOnClose,
    fullWidth = false,
    onOpenChange,
  } = props;

  const { formatMessage } = useIntl();

  return (
    <DropdownMenu.Root
      defaultOpen={defaultOpen}
      onOpenChange={(isOpen) => {
        onOpenChange?.(isOpen);
        if (!isOpen && handleOnClose) {
          handleOnClose();
        }
      }}
    >
      <DropdownMenu.Trigger asChild>{children}</DropdownMenu.Trigger>
      <DropdownMenu.Portal>
        <DropdownMenu.Content
          className={twMerge(
            'z-[4] max-h-[32rem] overflow-auto rounded-md border border-gray-5 bg-gray-1 shadow-lg-down ring-opacity-5 focus:outline-none',
            fullWidth ? 'w-[var(--radix-dropdown-menu-trigger-width)]' : 'w-56'
          )}
          align="start"
          sideOffset={5}
          alignOffset={alignOffset}
          hideWhenDetached
        >
          {answerNowFlows.length > 0 && (
            <DropdownMenu.Label asChild>
              <TextStyle variant="sm-regular" className="px-3 py-2 text-gray-7">
                {formatMessage(messages.answerNow)}
              </TextStyle>
            </DropdownMenu.Label>
          )}
          <DropdownMenu.Group>
            {answerNowFlows.map((flow) => (
              <DropdownMenu.Item
                key={flow.id}
                className="hover:cursor-pointer focus:bg-primary-2 focus:outline-none"
                onClick={() => onMenuItemClick(flow.id, flow.name)}
                asChild
              >
                <div
                  className={classNames(
                    'flex w-full items-center rounded-md px-3 py-2 text-sm text-gray-8 hover:bg-primary-2'
                  )}
                >
                  <div className="flex gap-2">
                    <div>{mapHexCodeToEmoticon(flow.icon.value)}</div>
                    <div className="line-clamp-1 text-left">{flow.name}</div>
                  </div>
                </div>
              </DropdownMenu.Item>
            ))}
          </DropdownMenu.Group>
          <DropdownMenu.Separator className="MenubarSeparator" />
          <DropdownMenu.Label asChild>
            <TextStyle variant="sm-regular" className="px-3 py-2 text-gray-7">
              {formatMessage(messages.answerAnytime)}
            </TextStyle>
          </DropdownMenu.Label>
          <DropdownMenu.Group>
            {answerAnytimeFlows.map((flow) => (
              <DropdownMenu.Item
                key={flow.id}
                className="focus:bg-primary-2 focus:outline-none"
                onClick={() => onMenuItemClick(flow.id, flow.name)}
                asChild
              >
                <div
                  className={classNames(
                    'flex w-full items-center rounded-md px-3 py-2 text-sm text-gray-8 hover:bg-primary-2'
                  )}
                >
                  <div className="flex gap-2">
                    <div>{mapHexCodeToEmoticon(flow.icon.value)}</div>
                    <div className="line-clamp-1 text-left">{flow.name}</div>
                  </div>
                </div>
              </DropdownMenu.Item>
            ))}
          </DropdownMenu.Group>
        </DropdownMenu.Content>
      </DropdownMenu.Portal>
    </DropdownMenu.Root>
  );
}
