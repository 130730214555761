import { useMemo } from 'react';

import { useMedia } from './useMedia';

const smallMobileQuery = '(max-width: 399px)';
// const mobileQuery = '(min-width:400px) and (max-width: 767px)';
const mobileQuery = '(max-width: 767px)';
const laptopQuery = '(min-width: 1024px)';
const tabletQuery = '(min-width: 768px) and (max-width: 1023px)';

export function useDeviceInfo() {
  const laptop = useMedia(laptopQuery);
  const tablet = useMedia(tabletQuery);
  const mobile = useMedia(mobileQuery);
  const smallMobile = useMedia(smallMobileQuery);

  const deviceType = useMemo(() => {
    if (laptop) {
      return 'laptop';
    }
    if (tablet) {
      return 'tablet';
    }
    if (mobile) {
      return 'mobile';
    }
    if (smallMobile) {
      return 'smallMobile';
    }
    return 'mobile';
  }, [laptop, mobile, tablet, smallMobile]);

  return {
    deviceType,
  } as const;
}
