import { fromUrl, NO_HOSTNAME } from 'parse-domain';

export function checkValidUrl(newValue: string) {
  const trimmedValue = newValue.trim();

  return (
    trimmedValue.startsWith('http://') || trimmedValue.startsWith('https://')
  );
}

export function buildSafeUrl(
  baseUrl: string,
  paramsToBeEncoded?: Record<string, string> | URLSearchParams
) {
  const encodedBaseUrl = encodeURI(baseUrl);
  const encodedParams = paramsToBeEncoded
    ? Object.entries(paramsToBeEncoded)
        .map(
          ([key, value]) =>
            `${encodeURIComponent(key)}=${encodeURIComponent(value)}`
        )
        .join('&')
    : '';

  return `${encodedBaseUrl}${encodedParams ? `?${encodedParams}` : ''}`;
}

/**
 * Retrieves the image source for the specified website's favicon.
 *
 *
 * @param url Web URL
 * @returns {string}
 */
export function getFaviconSource(url: string) {
  let domain = fromUrl(url);

  if (domain === NO_HOSTNAME) {
    domain = encodeURIComponent(url);
  }

  return `https://www.google.com/s2/favicons?domain=${domain}&sz=32`;
}
