import {
  $createLinkNode,
  $isAutoLinkNode,
  $isLinkNode,
  TOGGLE_LINK_COMMAND,
} from '@lexical/link';
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import { $findMatchingParent, mergeRegister } from '@lexical/utils';
import { Anchor, Content, Portal, Root } from '@radix-ui/react-popover';
import {
  $getSelection,
  $isLineBreakNode,
  $isRangeSelection,
  type BaseSelection,
  CLICK_COMMAND,
  COMMAND_PRIORITY_CRITICAL,
  COMMAND_PRIORITY_HIGH,
  KEY_ESCAPE_COMMAND,
  SELECTION_CHANGE_COMMAND,
} from 'lexical';
import { useCallback, useEffect, useState } from 'react';

import { useRefContainer } from '../../../../../context/RefContext';
import type { ToastKind } from '../../../../Shared/Toast/Toast';
import { LinkFloatingToolbar } from '../components/LinkFloatingToolbar';
import { getSelectedNode } from '../utils/getSelectedNode';
import { MATCHERS } from '../utils/regexUtils';

type LinkFloatingToolbarPluginProps = {
  showToast?: (kind: ToastKind, message: string) => void;
};

const canClick = (el: HTMLElement): boolean => {
  if (el.getAttribute('data-floating-modal') === 'link') {
    return true;
  }

  if (el.getAttribute('data-floating-toolbar') === 'link') {
    return true;
  }

  return el.parentElement ? canClick(el.parentElement) : false;
};

export const LinkFloatingToolbarPlugin = ({
  showToast,
}: LinkFloatingToolbarPluginProps) => {
  const [editor] = useLexicalComposerContext();

  const [activeEditor, setActiveEditor] = useState(editor);
  const [link, setLink] = useState<null | string>(null);
  const [title, setTitle] = useState<null | string>(null);
  const [lastSelection, setLastSelection] = useState<BaseSelection | null>(
    null
  );
  const [domRect, setDomRect] = useState<DOMRect | null>(null);

  const containerRef = useRefContainer();

  useEffect(() => {
    return editor.registerCommand(
      CLICK_COMMAND,
      (event) => {
        const selection = $getSelection();
        if ($isRangeSelection(selection)) {
          const node = getSelectedNode(selection);
          const linkNode = $findMatchingParent(node, $isLinkNode);
          if ($isLinkNode(linkNode)) {
            if (event.metaKey || event.ctrlKey) {
              window.open(linkNode.getURL(), '_blank', 'noopener,noreferrer');
              return true;
            }
          }
        }
        return true;
      },
      COMMAND_PRIORITY_CRITICAL
    );
  }, [editor]);

  const updateToolbar = useCallback(async () => {
    const selection = $getSelection();
    if ($isRangeSelection(selection)) {
      const focusNode = getSelectedNode(selection);
      const focusLinkNode = $findMatchingParent(focusNode, $isLinkNode);
      const focusAutoLinkNode = $findMatchingParent(focusNode, $isAutoLinkNode);

      if (!(focusLinkNode || focusAutoLinkNode)) {
        setLink(null);
        return;
      }

      const badNode = selection.getNodes().find((node) => {
        const linkNode = $findMatchingParent(node, $isLinkNode);
        const autoLinkNode = $findMatchingParent(node, $isAutoLinkNode);
        if (
          !linkNode?.is(focusLinkNode) &&
          !autoLinkNode?.is(focusAutoLinkNode) &&
          !linkNode &&
          !autoLinkNode &&
          !$isLineBreakNode(node)
        ) {
          return node;
        }
      });

      if (!badNode) {
        const node = getSelectedNode(selection);
        const linkParent = $findMatchingParent(node, $isLinkNode);
        if (linkParent) {
          setLink(linkParent.getURL());
          setTitle(linkParent.getTextContent());
        } else if ($isLinkNode(node)) {
          setLink(node.getURL());
        } else {
          setLink(null);
        }
      } else {
        setLink(null);
      }

      const nativeSelection = window.getSelection();
      const activeElement = document.activeElement;

      const rootElement = editor.getRootElement();

      if (
        nativeSelection !== null &&
        rootElement !== null &&
        rootElement.contains(nativeSelection.anchorNode) &&
        editor.isEditable()
      ) {
        if (nativeSelection.focusNode?.parentElement) {
          setDomRect(
            nativeSelection.anchorNode?.parentElement?.getBoundingClientRect() ??
              null
          );
        }
        setLastSelection(selection);
      } else if (!activeElement || activeElement.className !== 'link-input') {
        setLastSelection(null);
        setLink(null);
      }
    }
  }, [editor]);

  useEffect(() => {
    return mergeRegister(
      editor.registerCommand(
        SELECTION_CHANGE_COMMAND,
        (_payload, newEditor) => {
          updateToolbar();
          setActiveEditor(newEditor);
          return false;
        },
        COMMAND_PRIORITY_CRITICAL
      )
    );
  }, [editor, updateToolbar]);

  useEffect(() => {
    return editor.registerCommand(
      KEY_ESCAPE_COMMAND,
      (event) => {
        if (link) {
          event.stopPropagation();

          setLink(null);

          return true;
        }

        return false;
      },
      COMMAND_PRIORITY_HIGH
    );
  }, [editor, link]);

  useEffect(() => {
    const editorRoot = editor.getRootElement();

    const handleClick = (e: MouseEvent) => {
      if (
        editorRoot?.contains(e.target as Node) ||
        canClick(e.target as HTMLElement)
      ) {
        return;
      }

      setLink(null);
    };

    document.addEventListener('click', handleClick);

    return () => {
      document.removeEventListener('click', handleClick);
    };
  }, [editor]);

  useEffect(() => {
    const update = () => {
      editor.getEditorState().read(() => {
        updateToolbar();
      });
    };

    window.addEventListener('resize', update);

    return () => {
      window.removeEventListener('resize', update);
    };
  }, [editor, updateToolbar]);

  const handleLinkEdit = useCallback(
    (args: { url: string; title: string }) => {
      activeEditor.update(() => {
        const selection = $getSelection();
        if ($isRangeSelection(selection)) {
          const parent = getSelectedNode(selection).getParent();
          if ($isLinkNode(parent)) {
            const linkNode = $createLinkNode(args.url, {
              rel: parent.__rel,
              title: parent.__title,
              target: parent.__target,
            });
            parent.replace(linkNode, true);
          }
        }
      });
    },
    [activeEditor]
  );

  const handleLinkDelete = useCallback(() => {
    activeEditor.update(() => {
      const selection = $getSelection();
      if ($isRangeSelection(selection)) {
        const parent = getSelectedNode(selection).getParent();
        if ($isLinkNode(parent)) {
          parent.remove();
        }
      }
    });
  }, [activeEditor]);

  const handleRemoveLink = () => {
    if (lastSelection) {
      activeEditor.dispatchCommand(TOGGLE_LINK_COMMAND, null);
    }

    setLink(null);
    setLastSelection(null);
  };

  return (
    <Root open={Boolean(domRect)}>
      <Portal>
        <Anchor asChild>
          <div
            style={{
              position: 'fixed',
              top: domRect?.top,
              left: domRect?.left,
              width: domRect?.width,
            }}
          />
        </Anchor>
      </Portal>
      <Portal>
        <Content
          collisionBoundary={containerRef}
          align="center"
          side="bottom"
          collisionPadding={{ left: 32, right: 32, bottom: 24 }}
          className="z-50"
          sideOffset={24}
          onOpenAutoFocus={(e) => e.preventDefault()}
        >
          <LinkFloatingToolbar
            title={title}
            link={link}
            canRemoveLink={MATCHERS.every((matcher) => !matcher(title ?? ''))}
            onEdit={handleLinkEdit}
            onRemoveLink={handleRemoveLink}
            onDelete={handleLinkDelete}
            showToast={showToast}
          />
        </Content>
      </Portal>
    </Root>
  );
};
