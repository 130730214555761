import { ExclamationCircleIcon } from '@heroicons/react/24/solid';

export function ErrorIcon() {
  return (
    <ExclamationCircleIcon
      className="h-5 w-5 text-error-5"
      aria-hidden="true"
    />
  );
}
