import { defineMessages, useIntl } from 'react-intl';

import {
  TextField,
  type TextFieldProps,
} from '../../../DesignSystem/Inputs/TextField';
import { BlockLabel } from './BlockLabel';

const messages = defineMessages({
  placeholder: {
    defaultMessage: 'Add a description (optional)',
    id: 'JcefuP',
  },
  label: {
    defaultMessage: 'Description (optional)',
    id: 'bZenNC',
  },
});

type BlockDescriptionProps = Pick<
  TextFieldProps,
  'onChange' | 'value' | 'onFocus' | 'onClick' | 'disabled'
>;

export function BlockDescription({
  onChange,
  value,
  ...tail
}: BlockDescriptionProps) {
  const { formatMessage } = useIntl();

  return (
    <div className="flex flex-col gap-1">
      <BlockLabel>{formatMessage(messages.label)}</BlockLabel>
      <TextField
        onChange={onChange}
        placeholder={formatMessage(messages.placeholder)}
        value={value}
        inputClassName="px-4 py-2.5 text-gray-9 text-sm font-normal rounded-lg"
        {...tail}
      />
    </div>
  );
}
