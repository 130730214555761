import type { FileDetails, Meta } from '@assembly-web/services';
import type { UppyFile } from '@uppy/core';
import { useEffect, useState } from 'react';

import { getFilePreview } from '../../../Shared/FileUpload/utils';

export const useFile = (
  props:
    | {
        file: FileDetails;
      }
    | { uppyFile: UppyFile<Meta, Record<string, never>> }
) => {
  const isUppyFile = 'uppyFile' in props;

  const [src, setSrc] = useState<string | null>(null);

  const fileType = isUppyFile ? props.uppyFile.type : props.file.type;
  const fileName = isUppyFile ? props.uppyFile.name : props.file.name;
  const fileSize = isUppyFile ? props.uppyFile.size || 0 : props.file.size || 0;
  const uppyFileData = isUppyFile ? props.uppyFile.data : null;

  const filePreview = getFilePreview(fileType);
  const isImageUrl = /\.(jpe?g|png|gif|bmp|webp)$/i.test(fileName ?? '');
  const isVideo = /\.(mp4|m4v|mov|wmv|avi|mpg|ogv|3gp|3g2|ogg|webm)$/i.test(
    fileName ?? ''
  );
  const isPDF = /\.(pdf)$/i.test(fileName ?? '');

  useEffect(() => {
    if (
      isUppyFile &&
      uppyFileData &&
      (fileType.startsWith('image/') || fileType.startsWith('video/'))
    ) {
      const url = URL.createObjectURL(uppyFileData);
      setSrc(url);
      return () => {
        URL.revokeObjectURL(url);
      };
    }
  }, [fileType, isUppyFile, uppyFileData]);

  return {
    src: 'file' in props ? props.file.location : (src ?? ''),
    fileType,
    fileName,
    uppyFileData,
    isImageUrl,
    setSrc,
    fileSize,
    filePreview,
    isUppyFile,
    isVideo,
    isPDF,
  };
};
