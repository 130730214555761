import {
  APIEndpoints,
  assemblyAPI,
  type ChallengeDetailsAPIResponse,
  type MemberAPIResponse,
} from '@assembly-web/services';
import { TrophyIcon } from '@heroicons/react/24/outline';
import { zodResolver } from '@hookform/resolvers/zod';
import { useEffect, useMemo, useRef, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useIntl } from 'react-intl';
import type { z } from 'zod';

import { RefProvider } from '../../../../context/RefContext';
import { LoadingSpinner } from '../../../../DesignSystem/Feedback/Icons/LoadingSpinner';
import { TextStyle } from '../../../../DesignSystem/Feedback/TextStyle';
import { Button } from '../../../../DesignSystem/Inputs/Button';
import { useFileUpload } from '../../../Shared/FileUpload/useFileUpload';
import { MultiStepperModal } from '../../../Shared/Modal/MultiStepper/MultiStepperModal';
import { useToastStore } from '../../../Shared/Toast/useToastStore';
import { ConfirmationModal } from '../ConfirmationModal';
import { messages } from './message';
import { getDefaultValues, getSchema, getTabData } from './schema';

export const MODES = {
  VIEW: 'VIEW',
  CREATE: 'CREATE',
  DUPLICATE: 'DUPLICATE',
  EDIT_ACTIVE: 'EDIT_ACTIVE',
  EDIT_QUEUED: 'EDIT_QUEUED',
} as const;

export type ChallengeDefinitionModalProps = {
  handleSubmit: (formFields: unknown) => void;
  isOpen: boolean;
  onGetHelpClicked: () => void;
  onCloseModal: (val: boolean) => void;
  userDetails: MemberAPIResponse;
  isSavingChallenge: boolean;
  shareChallengeNode: JSX.Element | null;
  challengeDetails?: ChallengeDetailsAPIResponse;
  tooltipText?: string;
  mode: keyof typeof MODES;
};

const assemblyHelpURL =
  'https://help.joinassembly.com/en/articles/9367321-challenges';

export function ChallengeDefinitionModal({
  handleSubmit,
  isOpen,
  onCloseModal,
  userDetails,
  isSavingChallenge,
  shareChallengeNode,
  challengeDetails,
  onGetHelpClicked,
  tooltipText = '',
  mode,
}: ChallengeDefinitionModalProps) {
  const isInitialMount = useRef(true);

  const showButtons = {
    showSaveAndLaunchLater:
      mode === MODES.EDIT_QUEUED ||
      mode === MODES.CREATE ||
      mode === MODES.DUPLICATE,
    showCloseModal: mode === MODES.VIEW,
    showSaveAndLaunch: mode !== MODES.VIEW,
  };

  const { formatMessage } = useIntl();
  const { showErrorToast } = useToastStore();

  const fileUploadOptions = useFileUpload({
    key: 'create-challenge',
    title: formatMessage(messages.clickToUploadTitle),
    subTitle: formatMessage(messages.challengeAcceptedFileTypes),
    uploadOptions: {
      autoUpload: false,
      showImageEditor: true,
      allowMultipleFiles: false,
      allowedFileTypes: ['imagesWithoutGif'],
      maxFileSize: 30 * 1024 * 1024,
    },
    cropperOptions: {
      aspectRatio: 59 / 45,
      cropperActions: {
        cropSquare: false,
        cropWidescreen: false,
        cropWidescreenVertical: false,
      },
    },
    getUploadUrlForFile: async (file: string) => {
      const {
        data: { url: uploadUrl, location },
      } = await assemblyAPI.get(APIEndpoints.fetchUploadURI, {
        params: {
          fileName: file,
        },
      });

      return {
        uploadUrl,
        location,
      };
    },
    onRestrictionsError: (error: Error) => {
      showErrorToast(error.message);
    },
  });

  const { clear, files } = fileUploadOptions;

  const tabs = getTabData({
    formatMessage,
    userDetails,
    shareChallengeNode,
    mode,
    tooltipText,
    fileUploadOptions,
    image: challengeDetails?.image,
  });

  const [ref, setRef] = useState<HTMLDivElement | null>(null);

  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false);

  useEffect(() => {
    return () => {
      clear();
    };
  }, [clear]);

  const schema = useMemo(() => {
    return getSchema(formatMessage, userDetails.assembly.currency);
  }, [formatMessage, userDetails.assembly.currency]);

  type FormFields = z.infer<typeof schema>;
  type FieldName = keyof FormFields;

  const methods = useForm<FormFields>({
    resolver: zodResolver(schema),
    defaultValues: getDefaultValues(formatMessage, challengeDetails),
    mode: 'onChange',
  });

  const onSubmit = ({ launchNow }: { launchNow: boolean }) => {
    methods.setValue('launchNow', launchNow);
    if (!isSavingChallenge) handleSubmit({ ...methods.getValues() });
  };

  const customProps = {
    onSubmit,
  };

  const isUploadComplete = useMemo(() => {
    return (
      fileUploadOptions.files.filter((file) => file.progress.uploadComplete)
        .length === fileUploadOptions.files.length
    );
  }, [fileUploadOptions.files]);

  const getPrimaryButtonText = () => {
    if (mode === MODES.EDIT_ACTIVE) {
      return messages.saveChanges;
    } else if (mode === MODES.CREATE || mode === MODES.DUPLICATE) {
      return messages.createAndLaunch;
    }
    return messages.saveAndLaunch;
  };

  const submitFooter = (
    <>
      {showButtons.showSaveAndLaunchLater ? (
        <Button
          variation="secondaryEmphasized"
          onClick={() =>
            onSubmit({
              launchNow: false,
            })
          }
        >
          {isSavingChallenge && !methods.getValues('launchNow') ? (
            <div className="flex text-primary-6">
              <LoadingSpinner className="mr-2" />
              {formatMessage(messages.savingLabel)}
            </div>
          ) : (
            formatMessage(messages.saveAndLaunchLater)
          )}
        </Button>
      ) : null}

      {showButtons.showCloseModal ? (
        <Button variation="primary" onClick={() => onCloseModal(true)}>
          {formatMessage(messages.closeModal)}
        </Button>
      ) : null}

      {showButtons.showSaveAndLaunch ? (
        <Button
          disabled={!isUploadComplete}
          variation="primary"
          onClick={() =>
            onSubmit({
              launchNow: true,
            })
          }
        >
          {isSavingChallenge && methods.getValues('launchNow') ? (
            <div className="flex text-gray-1">
              <LoadingSpinner className="mr-2" />
              {formatMessage(messages.savingLabel)}
            </div>
          ) : (
            formatMessage(getPrimaryButtonText())
          )}
        </Button>
      ) : null}
    </>
  );

  const handleNextPage = async (selectedTab: string) => {
    // next page click validation
    const selectedPageData = tabs.find((tab) => tab.value === selectedTab);
    const schemaToVerify = selectedPageData?.schema;
    const fields = selectedPageData?.fields;
    const result = schemaToVerify?.safeParse(methods.getValues());
    await methods.trigger(fields as FieldName[]);

    return Boolean(result?.success);
  };

  const confirmationModal = {
    title: formatMessage(messages.confirmationModalLabel),
    desc: formatMessage(messages.confirmationModalDesc),
    button: formatMessage(messages.confirmationModalButton),
    onClose: () => {
      onCloseModal(false);
      setIsConfirmationModalOpen(false);
    },
  };

  const { setValue } = methods;

  useEffect(() => {
    if (files.length > 0) {
      setValue('image', {
        name: files[0].name || '',
        size: files[0].size || 0,
        location: files[0].meta.location,
      });
      setValue('imageUrl', URL.createObjectURL(files[0].data));
    }
  }, [files, setValue]);

  useEffect(() => {
    if (challengeDetails?.image && isInitialMount.current) {
      isInitialMount.current = false;

      const { image } = challengeDetails;

      setValue('image', {
        name: image.name,
        size: image.size,
        location: image.location,
      });

      setValue('imageUrl', image.thumbnails['256'] || image.location);
    }
  }, [challengeDetails, setValue]);

  return (
    <FormProvider {...methods} {...customProps}>
      <RefProvider value={ref}>
        <MultiStepperModal
          tabs={tabs}
          ref={setRef}
          onGetHelpButtonClick={() => {
            window.open(assemblyHelpURL, '_blank', 'noopener,noreferrer');
            onGetHelpClicked();
          }}
          isOpen={isOpen}
          header={
            <div className="flex items-center gap-2 p-1">
              <div className="rounded-full bg-gray-3 p-2">
                <TrophyIcon className="h-6 w-6" />
              </div>
              <TextStyle variant="lg-bold">
                {formatMessage(messages.header)}
              </TextStyle>
            </div>
          }
          customSubmitButton={submitFooter}
          onNextPage={handleNextPage}
          onClose={
            mode === MODES.VIEW
              ? () => onCloseModal(false)
              : () => setIsConfirmationModalOpen(true)
          }
        />
        <ConfirmationModal
          open={isConfirmationModalOpen}
          onClose={() => setIsConfirmationModalOpen(false)}
          title={formatMessage(messages.confirmationModalLabel)}
          description={formatMessage(messages.confirmationModalDesc)}
          leftButton={
            <Button
              isFullWidth
              variation="secondaryLite"
              onClick={() => setIsConfirmationModalOpen(false)}
            >
              {formatMessage(messages.cancelLabel)}
            </Button>
          }
          rightButton={
            <Button
              isFullWidth
              variation="danger"
              onClick={confirmationModal.onClose}
            >
              {confirmationModal.button}
            </Button>
          }
        />
      </RefProvider>
    </FormProvider>
  );
}
